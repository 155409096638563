import React, { Component } from "react";
import {
  Grid,
  Form,
  Divider,
  Header,
  Button,
  Table,
  Icon,
  Checkbox,
} from "semantic-ui-react";
import AddNewTaxModal from "../modal/add-new-tax-modal";
import {
  connect,
  bindActionCreators,
  actions,
  Notifications,
  commonFunctions,
} from "../../../functional/global-import";

class CommonTaxes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      generalTaxList: [],
      taxStatus:false,
      taxTitle: "",
      price: "",
      taxId: "",
      percentage: "",
    };
  }
  AddNewTaxModal = () => {
    this.setState({
      taxStatus: !this.state.taxStatus,
    });
  };
  AddClosedModal = () => {
    this.setState({
      taxStatus: false,
    });
    this.getGeneralTax();
  };
  closeDeleteModal = () =>
    this.setState({
      deleteModalOpen: false,
    });
  backStep = (e) => {
    e.preventDefault();
    this.props.onPrev(this.props.back);
  };
  //======= Retail pricing GET api to get list of General Tax=====//
  getGeneralTax = () => {
    this.props.actions.apiCall({
      urls: ["GETGENERALTAX"],
      method: "GET",
      data: {
        generalTaxId: -1,
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId: this.props.global.locationSelected
          .BusinessLocationId,
        pageNo: 1,
        pageSize: 100,
      },
      onSuccess: (response) => {
        
        const generalTaxList = response.map((tax) => {
          return {
            value: tax.generalTaxId,
            text: tax.title,
            price: tax.value,
            percentage: tax.percentage,
          };
        });
        this.setState({ generalTaxList: generalTaxList });
      },
    });
  };
  componentDidMount() {
    this.getGeneralTax();
  }
  onHandletaxes = (e, data) => {
    const { value } = data;
    const key = data.options.find((tax) => tax.value === value);
    this.setState({
      taxTitle: key.text,
      taxId: key.value,
      price: key.price,
      percentage: key.percentage,
    });
  };
  onHandleAddTax = (e) => {
    
    const { taxTitle,taxId, price, percentage} = this.state;
    const { fullState, stateForm, allFunction, variableName } = this.props;
    if (taxTitle !== "" && taxId !== "") {
      
      const matchValue = fullState[stateForm][variableName];
      if (matchValue.indexOf(taxId) === -1) {
        
        const rows = fullState.TaxRow.TaxNew;
        rows.push({ taxTitle: taxTitle, taxId: taxId, price: price, percentage: percentage});
        
        const taxRow = fullState[stateForm][variableName];
        taxRow.push(taxId);
        allFunction.setState({ ...fullState });
        commonFunctions.onFormChange(allFunction);
      }
      else {
        allFunction.props.actions.showNotification({
            title: "Warning",
            message: "This Tax  is already selected.", position: 'br', autoDismiss: commonFunctions.notificationTime()
        }, "error");
    }
    }
    else{
      allFunction.props.actions.showNotification({
          title: "Warning",
          message: "Please select any  Tax .", position: 'br', autoDismiss: commonFunctions.notificationTime()
      }, "error");
  }
    this.setState({ taxId: "" })
  }
  // onHandleAddTax = () => {
  //   
  //   const { taxTitle, price, percentage, taxList } = this.state;
  //   taxList.push({ taxTitle: taxTitle,price: price,percentage: percentage});
  //   this.setState({ taxList });
  // };
  // deleteTaxes = (i) => {
  //   const { taxList } = this.state;
  //   taxList.splice(i, 1); 
  //   this.setState({taxList})
  // };

  deleteTaxes = (i) => {
    
    const { fullState,stateForm, allFunction, variableName } = this.props;
    const rows = fullState.TaxRow.TaxNew;
    rows.splice(i, 1);
    const tax = fullState[stateForm][variableName];
    tax.splice(i, 1);
    allFunction.setState({ ...fullState });
    commonFunctions.onFormChange(allFunction);
}
  render() {
    const{taxStatus} = this.state;
    const { fullState, allFunction } = this.props;
    return (
      <React.Fragment>
        <Grid.Column width={"16"}>
          <Header as="h4">Tax</Header>
          <Checkbox
            label="Tax is included in prices"
            className="custome-checkbox"
            data="taxIncludedPrice"
            value={fullState.addRetailPrice.taxIncludedPrice}
            checked={fullState.addRetailPrice.taxIncludedPrice ? true : false}
            onChange={allFunction.onHandleChangePrice}
          />
        </Grid.Column>
        <Grid.Column width={"8"}>
          <Form.Dropdown
            placeholder="Tax"
            options={this.state.generalTaxList}
            value={this.state.taxId}
            data="taxId"
            type="select"
            className="custom-select"
            search
            selection
            fluid
            onChange={this.onHandletaxes}
            // error={allFunction.validator.message("Tax",fullState.taxId, "required")}
          />
        </Grid.Column>
        <Grid.Column width={"8"}>
          <Button className="orange-btn" onClick={this.onHandleAddTax}>
            Add
          </Button>
          <Button className="orange-btn" onClick={this.AddNewTaxModal}>
            Add New Tax{" "}
          </Button>
        </Grid.Column>
        <Checkbox
          label="Tax for all orders"
          className="custome-checkbox"
          data="taxForOrders"
          value={fullState.addRetailPrice.taxForOrders}
          checked={fullState.addRetailPrice.taxForOrders ? true : false}
          onChange={allFunction.onHandleChangePrice}
        />
       <Grid.Column width={16}> 
          { fullState.TaxRow.TaxNew.length > 0 && (
            <Table fixed>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Title</Table.HeaderCell>
                  <Table.HeaderCell>Tax Amount</Table.HeaderCell>
                  <Table.HeaderCell>Tax Percentage</Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">Action</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {fullState.TaxRow.TaxNew && fullState.TaxRow.TaxNew.map((singleDate, i) => {
                    
                    return (
                      <Table.Row>
                        <Table.Cell title={singleDate.taxTitle} className="textOverflowHidden">{singleDate.taxTitle}</Table.Cell>
                        <Table.Cell>{!singleDate.price || singleDate.price === null ? "-" :`$ ${singleDate.price}`}</Table.Cell>
                        <Table.Cell>{!singleDate.percentage  || singleDate.percentage == 0 ? "-" :`${singleDate.percentage} %`}</Table.Cell>
                        <Table.Cell textAlign="right">
                          <Icon
                            name="trash alternate"
                            size="large"
                            className="orange-color"
                            title="Delete"
                            link
                            onClick={() => this.deleteTaxes(i)}
                          />
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
              </Table.Body>
            </Table>
          )}
        </Grid.Column>
    {(taxStatus)&&
        <AddNewTaxModal
          openModal={this.state.taxStatus}
          closeModal={this.AddClosedModal}
          getGeneralTax={this.getGeneralTax}
          hideButton={true}
        />
      } 
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CommonTaxes);
