import React, { Component } from "react";
import { Button, Grid, Modal, Select } from "semantic-ui-react";

const CustomTimefirst = [
  { key: "2", value: "2", text: "1" },
  { key: "3", value: "3", text: "2" },
  { key: "4", value: "4", text: "3" },
  { key: "5", value: "5", text: "4" },
  { key: "6", value: "6", text: "5" },
  { key: "7", value: "7", text: "6" },
  { key: "8", value: "8", text: "7" },
  { key: "9", value: "9", text: "8" },
  { key: "10", value: "10", text: "9" },
  { key: "11", value: "11", text: "10" },
  { key: "12", value: "12", text: "11" },
  { key: "1", value: "1", text: "12" },
];
const CustomTimetwo = [
  { key: "m00", value: "m00", text: "00" },
  { key: "m05", value: "m05", text: "05" },
  { key: "m10", value: "m10", text: "10" },
  { key: "m15", value: "m15", text: "15" },
  { key: "m25", value: "m25", text: "25" },
  { key: "m30", value: "m30", text: "30" },
  { key: "m35", value: "m35", text: "35" },
  { key: "m40", value: "m40", text: "40" },
  { key: "m45", value: "m45", text: "45" },
  { key: "m50", value: "m50", text: "50" },
  { key: "m55", value: "m55", text: "55" },
];
const CustomTimethree = [
  { key: "am", value: "am", text: "AM" },
  { key: "pm", value: "pm", text: "PM" },
];

class CustomTime extends Component {
  state = {}
  render() {
    return (
      <Modal open={this.props.openModal} closeIcon onClose={this.props.closeModal} size={"mini"}>
        <Modal.Header>Add Custom Timesss</Modal.Header>
        <Modal.Content>
          <Grid>
            <Grid.Column width={4}>
              <Select options={CustomTimefirst} className="custom-select" defaultValue="9" fluid />
            </Grid.Column>
            <Grid.Column width={5}>
              <Select options={CustomTimetwo} className="custom-select" defaultValue="m00" fluid />
            </Grid.Column>
            <Grid.Column width={5}>
              <Select options={CustomTimethree} className="custom-select" defaultValue="am" fluid />
            </Grid.Column>
            <Grid.Column width={16} textAlign="right">
              <Button className="blue-btn" onClick={this.props.closeModal}>Cancel</Button>
              <Button className="orange-btn" onClick={this.props.closeModal}>Save</Button>
            </Grid.Column>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}

export default CustomTime;