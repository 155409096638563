import React, { Component } from "react";
import { Button, Divider, Form, Grid, Modal, Table, TextArea,} from "semantic-ui-react";

class NotesModal extends Component {
  render() {
    return (
      <Modal open={this.props.openModal} closeIcon onClose={this.props.closeModal} size={"small"}>
        <Modal.Content>
          <Table className="add-comment-table">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>User</Table.HeaderCell>
                <Table.HeaderCell>Date</Table.HeaderCell>
                <Table.HeaderCell>Time</Table.HeaderCell>
                <Table.HeaderCell>Note</Table.HeaderCell>
                <Table.HeaderCell className="deepviolet-color"> Short Surfboard </Table.HeaderCell>
                <Table.HeaderCell className="deepviolet-color"> ID: SUR-123 </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>Julie A.</Table.Cell>
                <Table.Cell>Jan 30, 2021</Table.Cell>
                <Table.Cell>2:00 PM</Table.Cell>
                <Table.Cell colSpan='3'>Came back with small scratch</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Julie A.</Table.Cell>
                <Table.Cell>Jan 20, 2021</Table.Cell>
                <Table.Cell>6:00 PM</Table.Cell>
                <Table.Cell colSpan='3'>fixed back left fin</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>

          <Divider hidden />

          <Divider hidden />

          <Divider hidden />

          <Divider hidden />
          <Grid>
            <Grid.Column width={16}>
              <Form>
                <TextArea rows={3} fluid placeholder="Notes"/>
              </Form>
            </Grid.Column>
            <Grid.Column width={16} textAlign="right">
              <Button className="blue-btn" onClick={this.props.closeModal}>Cancel </Button>
              <Button className="orange-btn" onClick={this.props.closeModal}> Save </Button>
            </Grid.Column>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}
export default NotesModal;
