import React, { Component, Fragment } from "react";
import {
  bindActionCreators,
  actions,
  connect,
  commonFunctions,
} from "../../../../../../functional/global-import";
import ActivitySelectTicket from "../activity/activity-select-ticket";
import ChooseDate from "./choose-date";
import moment from "moment";
import AddOnAllBusinessType from "../../common/addOn-all-business-type";
import CancelationCommonComponent from "../../common/cancelation-common";
import { Dimmer, Loader } from "semantic-ui-react";

class Activity extends Component {
  state = {
    showActivitySelectTicket: this.props.activityEdit.activityEdit
      ? true
      : false,
    showActivityChooseDates: this.props.activityEdit.activityEdit
      ? false
      : this.props.customQuestionEdit.customQuestionEdit
      ? false
      : true,
    showActivityQuestionRequirement: false,
    activity: {
      activityId: this.props.activityEdit.activityEdit
        ? this.props.activityEdit.activityItem.ActivityId
        : this.props.productCategoryDetail.productId,
      startDate: "",
      endDate: "",
      InWebsiteWidget: false,
    },
    monthDate: "",
    activityTimeSlots: null,
    showCancellationComponent: false,
    editShowCancellationComponent:
      this.props.customQuestionEdit.customQuestionEdit,
    showAddon: false,

    timeDetails: null,
    ticketsTable: false,
    ticketsDetails: [],
    productCustomQuestion: this.props.customQuestionEdit.customQuestionEdit
      ? this.props.customQuestionEdit.productCustomQuestion
      : null,

    timeChoosen: null,
    selectedDate: null,
    customfield: null,
    activityId: "",
    activityTimeFrameDetails: null,
  };

  handleActivitySelectTicket = (chooseDateDetail) => {
    this.setState({
      chooseDateDetail: chooseDateDetail,
      showActivitySelectTicket: true,
      showActivityChooseDates: false,
      showActivityQuestionRequirement: false,
      timeChoosen: chooseDateDetail.timeRangeFrom,
      selectedDate: chooseDateDetail.startDate,
    });
  };

  handleActivityQuestionRequirement = (response) => {
    this.setState({
      showActivitySelectTicket: false,
      orderDetailId: response.orderDetailId,
      //  showActivityQuestionRequirement: true,
      showActivityChooseDates: false,
    });
  };

  handleActivityChooseDates = () => {
    this.setState({
      showActivitySelectTicket: false,
      showActivityChooseDates: true,
      showActivityQuestionRequirement: false,
    });
  };

  handleInventory = () => {
    this.setState({
      showActivitySelectTicket: false,
      showActivityChooseDates: false,
      showActivityQuestionRequirement: false,
    });
    this.props.backToInventory();
  };
  getActivityTimeSlots = () => {
    this.props.actions.apiCall({
      urls: ["GETACTIVITYTIMESLOTS"],
      method: "GET",
      data: this.state.activity,
      onSuccess: (response) => {
        var addonDetails = null;
        this.setState({ activityId: response[0]?.timeFrameId });
        if (response[0].addOn) {
          addonDetails =
            response[0].addOn &&
            JSON.parse(response[0].addOn).map(function (el) {
              var addOnObj = Object.assign({}, el);
              addOnObj.qunatity = 0;
              addOnObj.minvalue = el.minAddOns;
              return addOnObj;
            });
        }
        const briefDetails =
          response[0].briefDetails && JSON.parse(response[0].briefDetails)[0];
        const customfield =
          response &&
          response[0] &&
          response[0].customfields &&
          JSON.parse(response[0].customfields);

        const productCustomQuestion =
          briefDetails.CustomQuestion &&
          JSON.parse(briefDetails.CustomQuestion);
        const newRequirement =
          briefDetails && briefDetails.Requirements !== null
            ? JSON.parse(briefDetails.Requirements)
            : null;
        briefDetails.Requirements = newRequirement;

        const questiondetails =
          productCustomQuestion &&
          productCustomQuestion.map((customObj) => {
            if (customObj.associateAddOn) {
              customObj.associateAddOn = JSON.parse(customObj.associateAddOn);
            }
            return { ...customObj };
          });

        const hasToShowCancellationComponent =
          (briefDetails && briefDetails.CancellationPolicy !== null) ||
          briefDetails.CustomQuestion !== null ||
          briefDetails.Requirements !== null
            ? true
            : false;

        // this.props.actions.addMultipleFlatFeeAndOtherDetails(response)

        const isToShowAddon =
          response[0].addOn && JSON.parse(response[0].addOn).length > 0
            ? true
            : false;

        this.setState({
          showChooseDates: true,
          showRentalItems: false,
          isToShowAddon: isToShowAddon,
          addOnDetails: addonDetails,
          hasToShowCancellationComponent: hasToShowCancellationComponent,
          briefDetails: briefDetails,
          productCustomQuestion: questiondetails,
          customfields: customfield,
          //  activityTimeSlots: response[0]
        });
        // this.setState({ activityTimeSlots: response[0] });
        this.getTimeFrameDetails();
      },
    });
  };

  componentDidMount() {
    if (!this.props.customQuestionEdit.customQuestionEdit) {
      this.getActivityTimeSlots();
      this.getNewActivityTimeSlots();
    }
  }

  handleChangeMonth = (event, { name, value }) => {
    if (name === "monthDate") {
      const { activity } = this.state;
      let separateDate = value.split(" - ");
      let selectedDate = {
        startDate: separateDate[0],
        endDate: separateDate[1] ? separateDate[1] : separateDate[0],
      };
      if (value === "") {
        activity["startDate"] = selectedDate.startDate;
        activity["endDate"] = selectedDate.endDate;
        this.setState({ activity, monthDate: "" });
      } else {
        activity["startDate"] = moment(
          selectedDate.startDate,
          "DD-MM-YYYY"
        ).format("YYYY-MM-DD");
        activity["endDate"] = moment(selectedDate.endDate, "DD-MM-YYYY").format(
          "YYYY-MM-DD"
        );
        this.setState(
          {
            activity,
            [name]: separateDate[1]
              ? separateDate[1] + " -"
              : separateDate[0] + " -",
          },
          () => this.getActivityTimeSlots()
        );
      }
    }
  };

  onCountinueBtnClick = (isAddMoreCLicked) => {
    this.props.actions.getOrderItems(
      this.props.orderState.currentOrder.orderId
    );
    {
      isAddMoreCLicked ? this.props.onAddMoreActivity() : this.props.next(2);
    }
  };

  onAddPricingAndQuestion = (dataObj, isAddMoreCLicked) => {
    this.props.actions.apiCall({
      urls: ["POSTADDORDERACTIVITYPRICINGCUSTOMERANSWER"],
      method: "POST",
      data: dataObj,
      showNotification: true,
      onSuccess: (response) => {
        if (this.state.isToShowAddon) {
          this.setState({ showAddon: true });
        } else if (this.state.hasToShowCancellationComponent) {
          this.setState({
            showChooseDates: false,
            showCancellationComponent: true,
          });
        } else if (isAddMoreCLicked) {
          this.props.onAddMoreActivity();
        } else {
          this.props.next(2, false, {
            BusinessCategoryType: dataObj.businessCategoryType,
            ProductId: dataObj.activityId,
          });
        }
        this.props.actions.getOrderItems(
          this.props.orderState.currentOrder.orderId
        );
        this.handleActivityQuestionRequirement(response);
        return response;
      },
    });
  };

  onAddOnContinueClick = (isAddMoreCLicked) => {
    if (isAddMoreCLicked) {
      this.props.onAddMoreActivity();
    } else if (this.state.hasToShowCancellationComponent) {
      this.setState({ showAddon: false, showCancellationComponent: true });
    } else {
      this.props.next(2);
    }
  };

  onCancelationContinue = (isAddMoreClicked) => {
    if (isAddMoreClicked) {
      this.props.onAddMoreActivity();
    } else {
      this.props.next(2);
    }
    this.props.actions.getOrderItems(
      this.props.orderState.currentOrder.orderId
    );
  };

  getNewActivityTimeSlots = (date) => {
    // if (date !== undefined) {
    const today =
      this.props.currentDateFromApi === undefined
        ? new Date()
        : this.props.currentDateFromApi;

    const timeConverted = moment(today, "MMM DD YYYY, hh:mm A").format(
      "YYYY/MM/DD"
    );
    this.props.actions.apiCall({
      urls: ["GETSLOTSAVAILABILITY"],
      method: "GET",
      data: {
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        ProductId: this.props.activityEdit.activityEdit
          ? this.props.activityEdit.activityItem.ProductId
          : this.props.productCategoryDetail.productId,
        startDate: date === undefined ? timeConverted : date[0],
        businessCategoryType: this.props.activityEdit.activityEdit
          ? this.props.activityEdit.activityItem.BusinessCategoryType
          : commonFunctions.getGlobalCodeDetails(
              this.props.global.codes,
              "BusinessCategoryType",
              this.props.productCategoryDetail.businessCategoryType
            ).globalCodeId,
      },
      onSuccess: (response) => {
        this.setState({ ...this.state, activityTimeSlots: response[0] });
      },
    });
    // }
    // else {
    //     return false
    // }
  };


  getActivityGeneralInfo = (editActivityId) => {
    this.setState({ loadingApi: true });
    this.props.actions.apiCall({
      urls: ["GETACTIVITY"],
      method: "GET",
      data: {
        activityId: this.props.productCategoryDetail.productId,
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        businessCategoryType: commonFunctions.getGlobalCodeDetails(
          this.props.global.codes,
          "BusinessCategoryType",
          "Activity"
        ).globalCodeId,
        categoryId: this.props.global.inventoryDetail.categoryId,
        pageNo: 1,
        pageSize: 1000,
      },
      onSuccess: (response) => {
      }

    });
  };


  getSlotsTickets = (timeChoosen, selectedDate) => {
    this.props.actions.apiCall({
      urls: ["GETACTIVITYTICKETDETAILS"],
      method: "GET",
      data: {
        ProductId: this.props.productCategoryDetail.productId,
        TimeRangeFrom: timeChoosen,
        StartDate: selectedDate,
      },
      onSuccess: (response) => {
        this.setState({
          ticketsTable: true,
          ticketsDetails: response,
          timeChoosen,
          selectedDate,
        });
      },
    });
  };

  getTimeFrameDetails = () => {
    const { activityId } = this.state;
    this.props.actions.apiCall({
      urls: ["GETTIMEFRAMEDETAIL"],
      method: "GET",
      data: {
        timeFrameId: -1,
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        PageNo: 1,
        PageSize: 1000,
      },
      onSuccess: (response) => {
        const activityTimeFrame = response?.find((timeFrame) => {
          return timeFrame.timeFrameId === activityId;
        });
        this.setState({ activityTimeFrameDetails: activityTimeFrame });
      },
    });
  };

  render() {
    const {
      showActivitySelectTicket,
      showActivityChooseDates,
      activity,
      activityTimeSlots,
      monthDate,
      showCancellationComponent,
      showAddon,
      addOnDetails,
      orderDetailId,
      briefDetails,
      hasToShowCancellationComponent,
      isToShowAddon,
      chooseDateDetail,
      productCustomQuestion,
      selectedDate,
      timeChoosen,
      editShowCancellationComponent,
      customfields,
      activityId,
      activityTimeFrameDetails,
    } = this.state;
    const {
      productCategoryDetail,
      activityEdit,
      allFunction,
      api,
      currentDateFromApi,
      initialMonthDate,
      initialMonth
    } = this.props;
    return (
      <Fragment>
        {api.isApiLoading && (
          <Dimmer active inverted>
            {" "}
            <Loader size="small"></Loader>{" "}
          </Dimmer>
        )}
        {showActivityChooseDates && (
          <ChooseDate
            currentDateFromApi={currentDateFromApi}
            customfields={customfields}
            stateRefActivity={this}
            getNewActivityTimeSlots={this.getNewActivityTimeSlots}
            productCategoryDetail={productCategoryDetail}
            initialMonthDate={initialMonthDate}
            backBtnInventoryView={this.props.backBtnInventoryView}
            showActivityTicket={this.handleActivitySelectTicket}
            activityTimeSlots={activityTimeSlots}
            activity={activity}
            monthDate={monthDate}
            handleChangeMonth={this.handleChangeMonth}
            activityId={activityId}
            activityTimeFrameDetails={activityTimeFrameDetails}
            initialMonth={initialMonth}
          />
        )}
        {showActivitySelectTicket && (
          <ActivitySelectTicket
            timeChoosen={timeChoosen}
            selectedDate={selectedDate}
            stateRefActivity={this}
            allFunction={allFunction}
            activityEdit={activityEdit}
            next={this.props.next}
            isToShowAddon={isToShowAddon}
            hasToShowCancellationComponent={hasToShowCancellationComponent}
            onAddPricingAndQuestion={this.onAddPricingAndQuestion}
            chooseDateDetail={chooseDateDetail}
            // backToInventory={this.backToInventory}
            backToActivityChooseDate={this.handleActivityChooseDates}
            productCategoryDetail={productCategoryDetail}
            showActivityQuestionRequirement={
              this.handleActivityQuestionRequirement
            }
          />
        )}

        {showAddon && (
          <AddOnAllBusinessType
            onAddMore={this.onCancelationContinue}
            addOnDetails={addOnDetails}
            stateRef={this}
            hasToShowCancellationComponent={hasToShowCancellationComponent}
            orderDetailId={orderDetailId}
            onContinueClick={this.onAddOnContinueClick}
          />
        )}

        {editShowCancellationComponent && (
          <CancelationCommonComponent
            businessTypeName="Activity"
            allFunction={this}
            orderDetailId={this.props.customQuestionEdit.questionOrderDetailId}
            productId={this.props.customQuestionEdit.questionProductId}
            productCustomQuestion={productCustomQuestion}
            showAddon={false}
            briefDetails={false}
            editQuestion={true}
            onEditQuestionNext={() => this.props.next(3)}
          />
        )}

        {showCancellationComponent && (
          <CancelationCommonComponent
            businessTypeName="Activity"
            allFunction={this}
            orderDetailId={orderDetailId}
            productId={this.props.global.inventoryDetail.productId}
            productCustomQuestion={productCustomQuestion}
            showAddon={isToShowAddon}
            briefDetails={briefDetails}
            onNext={this.onCancelationContinue}
          />
        )}
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    orderState: state.orderState,
    global: state.global,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      addSelectedDateRange: bindActionCreators(
        actions.addSelectedDateRange,
        dispatch
      ),
      storeInventoryDetail: bindActionCreators(
        actions.storeInventoryDetail,
        dispatch
      ),
      getOrderItems: bindActionCreators(actions.getOrderItems, dispatch),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Activity);
