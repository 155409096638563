// ****************************************************************************************
// *********************** Choose rental items of New order********************************
// *************************************************************************************

import React, { Component } from "react";
import {
  Header,
  Table,
  Image,
  Input,
  Checkbox,
  Grid,
  Button,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import AddCommentModal from "../../../add-comment-modal";
import {
  connect,
  bindActionCreators,
  actions,
  Notifications,
  commonFunctions,
  env,
} from "../../../../../../functional/global-import";
import InventoryBreadcrumb from "../../../../../../../pages/inventory/breadcrumb";
import { orderCommonFunctions } from "../../order-common-function";

class RentalItems extends Component {
  get initialState() {
    return {
      itemDetailForm: {
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        startDate: "",
        endDate: "",
        pageNo: 1,
        pageSize: 5,
        isActive: true,
        itemStatus: false,
      },
      columnNames: null,
      selectedProducts: [],
      hasMore: true,
      defaultPageNo: 1,
      defaultPageSize: 4,
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      minItemPerOrder: null,
      maxItemPerOrder: null,
      productQuantityAvailable: null,
      ...this.initialState,
      commentStatus: false,
      quantity: "",
      productsList: [],
      selectedProducts: [],
      isLoading: true,
      note: {
        noteRefrenceId: "",
        noteType: "",
      },
    };
  }
  getProductList = () => {
    const { dateTime, rentalEdit, rentalDatesSelected, startTime, endTime } =
      this.props;
    const selectedDateRange =
      this.props.orderState.currentOrder.orderDetails.filter((obj) => {
        return obj.id === this.props.orderState.currentOrderDetails.id;
      });
    this.props.actions.apiCall({
      urls: ["GETPRODUCTDETAILLIST"],
      method: "GET",
      data: {
        ProductId: rentalEdit.rentalEdit
          ? rentalEdit.ProductId
          : this.props.global.inventoryDetail.productId,
        IsActive: true,
        BusinessCategoryType: rentalEdit.rentalEdit
          ? rentalEdit.businessCategoryId
          : this.props.global.inventoryDetail.businessCategoryId,
        StartDate: rentalEdit.rentalEdit
          ? rentalEdit.StartDate
          : `${rentalDatesSelected.startDate} ${startTime}`,
        EndDate: rentalEdit.rentalEdit
          ? rentalEdit.EndDate
          : `${rentalDatesSelected.endDate} ${endTime}`,
        BusinessId: this.props.global.bussinessDetail.businessId,
        BusinessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        PageNo: 1,
        PageSize: 1000,
        sortColumn: "Productstatus1",
        sortOrder: "asc",
        sortingArrow: "sort",
      },
      onSuccess: (response) => {
        const { rentalEdit } = this.props;
        if (rentalEdit.rentalEdit) {
          const alreadySelected = [];
          const demoalreadySelected = JSON.parse(response[0].data).filter(
            (singleitem) => {
              rentalEdit.rentalItem.ProductItemDetails.map((editRentalObj) => {
                if (
                  singleitem.productdetailid === editRentalObj.ProductDetailId
                ) {
                  alreadySelected.push(singleitem);
                }
                return false;
              });
              return false;
            }
          );
          this.setState({ selectedProducts: alreadySelected });
        }
        if (JSON.parse(response[0].data).length === 0) {
          this.setState({ hasMore: false });
        }
        this.setState({
          columnNames: JSON.parse(response[0].columnNames),
          productsList: JSON.parse(response[0].data),
          minItemPerOrder: response && response[0]?.minItemPerOrder,
          maxItemPerOrder: response && response[0]?.maxItemPerOrder,
          productQuantityAvailable: response[0]?.productQuantity,
        });
        this.setState({ isLoading: false });
      },
    });
  };

  componentDidMount() {
    this.getProductList();
  }

  onChangeHandler = (e, { value }) => {
    const { productsList, selectedProducts } = this.state;
    let dataObject = selectedProducts;
    if (parseInt(value) > 0) {
      dataObject = productsList.slice(0, parseInt(value));
      this.setState({ selectedProducts: dataObject });
    } else {
      this.setState({ selectedProducts: [] });
    }
  };

  handleChange = (e, { checked, name }, selectedProduct) => {
    const { selectedProducts } = this.state;
    let data = selectedProducts;
    if (checked) {
      if (name === "CheckAll") {
        data = selectedProduct;
      } else {
        data.push(selectedProduct);
      }
      this.setState({ selectedProducts: data });
    } else {
      if (name === "CheckAll") {
        data = [];
      } else {
        data = selectedProducts.filter(
          (item) => item.productdetailid !== selectedProduct.productdetailid
        );
      }
    }
    this.setState({ selectedProducts: data });
  };
  openNotesModal = (data, singleProduct) => {
    const { note } = this.state;
    this.setState({ commentStatus: !this.state.commentStatus });
    if (data === "ItemDetail") {
      note.noteRefrenceId = singleProduct.productdetailid;
      note.noteType = data;
      this.setState({ note });
    }
  };
  onUpdateRentalDetail = (selectedProducts) => {
    const { orderState, rentalEdit } = this.props;
    const { minItemPerOrder, maxItemPerOrder } = this.state;

    const isAbleToBook = orderCommonFunctions.onValidateMinMaxPerItem(
      selectedProducts?.length,
      minItemPerOrder,
      maxItemPerOrder
    );
    if (isAbleToBook) {
      this.props.actions.apiCall({
        urls: ["UPDATEORDERRENTALDETAIL"],
        method: "PUT",
        data: {
          orderId: orderState.currentOrder.orderId,
          startDate: rentalEdit.StartDate,
          endDate: rentalEdit.EndDate,
          orderDetailId: rentalEdit.rentalItem.OrderDetailId,
          productId: rentalEdit.ProductId,
          productDetailId: selectedProducts.map((item) => item.productdetailid),
          actionPerformedBy: this.props.auth.userDetail.emailId,
        },
        onSuccess: (response) => {
          this.props.next(3);
          this.props.allFunction.handleSelectedBusinessCategoryEmpty();
          this.props.actions.getOrderItems(orderState.currentOrder.orderId);
          return response;
        },
        showNotification: true,
      });
    } else {
      this.props.actions.showNotification(
        {
          title: "Warning",
          message: `Please select minimum ${minItemPerOrder} or maximum ${maxItemPerOrder} item per-order`,
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    }
  };
  onCancelBtnCLick = () => {
    this.props.next(3);
    this.props.allFunction.handleSelectedBusinessCategoryEmpty();
  };
  minMaxValidations = (selectedProducts) => {
    const { minItemPerOrder, maxItemPerOrder } = this.state;
    const isAbleToBook = orderCommonFunctions.onValidateMinMaxPerItem(
      selectedProducts?.length,
      minItemPerOrder,
      maxItemPerOrder
    );
    if (isAbleToBook) {
      this.props.showRentalChooseDates(selectedProducts, true);
    } else {
      this.props.actions.showNotification(
        {
          title: "Warning",
          message: `Please select minimum ${minItemPerOrder} or maximum ${maxItemPerOrder} item per-order`,
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    }
  };
  render() {
    const { selectedProducts, commentStatus, productsList, note, columnNames } =
      this.state;
    const { rentalEdit } = this.props;
    return (
      <>
        <Grid>
          {!rentalEdit.rentalEdit && (
            <InventoryBreadcrumb
              newOrderBack={this.props.backToInventory}
              onShowRentalDate={this.props.onShowRentalDate}
            />
          )}
          <Grid.Column width={16}>
            <Table basic="very" className="borderlessTable chooseDateRental">
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    {!rentalEdit.rentalEdit ? (
                      <>
                        <Image
                          src={commonFunctions.concatenateImageWithAPIUrl(
                            this.props.productCategoryDetail.image
                          )}
                          size="tiny"
                          bordered
                        />
                      </>
                    ) : (
                      <>
                        <Image
                          src={commonFunctions.concatenateImageWithAPIUrl(
                            productsList.length > 0 && productsList[0].Image
                          )}
                          size="tiny"
                          bordered
                        />
                      </>
                    )}
                    {/* Image */}
                  </Table.Cell>
                  {rentalEdit.rentalEdit ? (
                    <Table.Cell
                      title={
                        this.state?.productsList &&
                        this.state?.productsList.length > 0 &&
                        this.state?.productsList[0].productname
                      }
                      className="textOverflowHidden"
                    >
                      <Header as="h3">
                        {this.state?.productsList &&
                          this.state?.productsList.length > 0 &&
                          this.state?.productsList[0].productname}
                      </Header>
                    </Table.Cell>
                  ) : (
                    <Table.Cell
                      title={this.props.productCategoryDetail.name}
                      className="textOverflowHidden"
                    >
                      <Header as="h3">
                        {this.props.productCategoryDetail.name}
                      </Header>
                    </Table.Cell>
                  )}

                  <Table.Cell>
                    <Header as="h3">
                      {this.state.productQuantityAvailable -
                        this.state.selectedProducts.length}
                    </Header>
                  </Table.Cell>

                  <Table.Cell>
                    <Header as="h3" className="deepviolet-color">
                      Inventory
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Input
                      value={this.state.selectedProducts.length}
                      onChange={this.onChangeHandler}
                      maxLength={3}
                      className="selecteed"
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Header as="h3" className="deepviolet-color">
                      Selected
                    </Header>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>

            <div className="analysticTable">
              {this.state.isLoading ? (
                <Dimmer active inverted>
                  {" "}
                  <Loader size="small">Loading</Loader>{" "}
                </Dimmer>
              ) : (
                <Table
                  className="table-scrolling commonTableDropdown"
                  id="AnalyticsGrid"
                  singleLine
                >
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>
                        <Checkbox
                          className="custome-checkbox"
                          name="CheckAll"
                          checked={
                            productsList.length === selectedProducts.length
                              ? true
                              : false
                          }
                          onChange={(e, { value, checked, name }) =>
                            this.handleChange(
                              e,
                              { value, checked, name },
                              productsList
                            )
                          }
                        />
                      </Table.HeaderCell>
                      <Table.HeaderCell>Name</Table.HeaderCell>
                      <Table.HeaderCell>ID</Table.HeaderCell>
                      <Table.HeaderCell>Status</Table.HeaderCell>
                      {columnNames !== null
                        ? columnNames.map((column) => {
                            return (
                              <Table.HeaderCell>
                                {column.traitName}{" "}
                              </Table.HeaderCell>
                            );
                          })
                        : false}
                      <Table.HeaderCell textAlign="right">
                        Notes
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  {/* <InfiniteScroll
                  dataLength={this.state.productsList.length}
                  scrollableTarget={`${"scrollable" + 'AnalyticsGrid'}`}
                  next={this.fetchMoreData}
                  hasMore={hasMore}
                > */}
                  <Table.Body
                    id={`${"scrollable" + "AnalyticsGrid"}`}
                    style={{ maxHeight: "300px" }}
                  >
                    {this.state.productsList.map((singleProduct, index) => {
                      return (
                        <Table.Row>
                          <Table.Cell>
                            <Checkbox
                              className="custome-checkbox"
                              value={selectedProducts.name}
                              checked={
                                selectedProducts.filter(
                                  (selectedProduct) =>
                                    selectedProduct.productdetailid ===
                                    singleProduct.productdetailid
                                ).length > 0
                              }
                              onChange={(e, { value, checked, name }) =>
                                this.handleChange(
                                  e,
                                  { value, checked, name },
                                  singleProduct
                                )
                              }
                            />
                          </Table.Cell>
                          <Table.Cell className="textOverflowHidden">
                            <span
                              title={singleProduct.productname}
                              className="orange-color"
                            >
                              {singleProduct.productname}
                            </span>
                          </Table.Cell>
                          <Table.Cell>
                            <Link
                              className="orange-color"
                              to={`${env.PUBLIC_URL}/item-detail/${singleProduct.productdetailid} ${singleProduct.productid}
                             ${singleProduct.businessid} ${singleProduct.businesslocationid}`}
                              target="_blank"
                            >
                              {singleProduct.id}
                            </Link>
                          </Table.Cell>
                          <Table.Cell>
                            <Button
                              size="mini"
                              className={
                                singleProduct.Productstatus1 === "Checked In"
                                  ? "deepviolet-button"
                                  : singleProduct.Productstatus1 === "Reserved"
                                  ? "purple-button"
                                  : singleProduct.Productstatus1 ===
                                    "Checked Out"
                                  ? "orange-button"
                                  : ""
                              }
                            >
                              {singleProduct.Productstatus1}
                            </Button>

                            {/* {singleProduct.status ? singleProduct.status : "Available"} */}
                          </Table.Cell>

                          {columnNames &&
                            columnNames.map((column) => {
                              return (
                                <Table.Cell className="textBreak">
                                  {
                                    singleProduct[
                                      column.traitName.toLowerCase()
                                    ]
                                  }
                                </Table.Cell>
                              );
                            })}
                          <Table.Cell
                            textAlign="right"
                            onClick={() =>
                              this.openNotesModal("ItemDetail", singleProduct)
                            }
                          >
                            <span
                              class="iconify maroon-color"
                              data-icon="mdi:comment-plus"
                              data-inline="false"
                              data-flip="horizontal"
                              data-width="18px"
                              data-height="18px"
                            ></span>
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>

                  {/* </InfiniteScroll> */}
                </Table>
              )}
            </div>
          </Grid.Column>
          {!rentalEdit.rentalEdit ? (
            <>
              <Grid.Column width={16} textAlign="right">
                <Button
                  className="blue-btn"
                  onClick={this.props.backBtnInventoryView}
                >
                  Back
                </Button>
                <Button
                  className="orange-btn"
                  disabled={selectedProducts.length === 0 ? true : false}
                  onClick={(e) => this.minMaxValidations(selectedProducts)}
                >
                  Continue
                </Button>
              </Grid.Column>
            </>
          ) : rentalEdit.rentalEdit ? (
            <>
              <Grid.Column width={16} textAlign="right">
                <Button className="blue-btn" onClick={this.onCancelBtnCLick}>
                  Cancel
                </Button>
                <Button
                  className="orange-btn"
                  disabled={selectedProducts.length === 0 ? true : false}
                  onClick={() => this.onUpdateRentalDetail(selectedProducts)}
                >
                  Update
                </Button>
              </Grid.Column>
            </>
          ) : null}
        </Grid>
        <AddCommentModal
          openModal={commentStatus}
          noteType={note.noteType}
          noteRefrenceId={note.noteRefrenceId}
          closeModal={this.openNotesModal}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
    orderState: state.orderState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
      storeInventoryDetail: bindActionCreators(
        actions.storeInventoryDetail,
        dispatch
      ),
      getOrderItems: bindActionCreators(actions.getOrderItems, dispatch),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RentalItems);
