import React, { Component } from "react";
import { Button, Grid, Modal, Form ,Input} from "semantic-ui-react";
import { connect, bindActionCreators, actions, commonFunctions} from "../../../../functional/global-import";

class AddCounter extends Component {
    // // Put all key & values that need to reset on form submit and reset form.
    // get initialState() {
    //   return {
    //     // addCounter:{
    //     //   businessId: this.props.global.bussinessDetail.businessId,
    //     //   businessLocationId:this.props.global.locationSelected.BusinessLocationId,
    //     //   counterName: "",
    //     //   beginningAmount: null,
    //     //   actionPerformedBy: this.props.auth.userDetail.emailId
    //     // }
    //   };
    // }
    constructor(props) {
      super(props);
      this.state = { 

      };
      this.validator = commonFunctions.initializeSimpleValidator();
    }
    componentDidMount() {
      const { addCounter } = this.props;
      addCounter.businessId = this.props.auth.loggedIn.businessId;
      addCounter.businessLocationId = this.props.global.locationSelected.BusinessLocationId;
      addCounter.actionPerformedBy = this.props.auth.userDetail.emailId;
      this.setState({ addCounter })
  }
    onsaveAddCounter=(e)=>{
                                                      
      const {oneditButton} = this.props;
      var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
        if(isFormValid && !oneditButton){
        this.props.onAddCounter(isFormValid);
        }
        if(isFormValid && oneditButton){
          this.props.onUpdateCounter(isFormValid);
        }
      }

  render() {
    const {addCounter,onAddCounterHandleChange,oneditButton,api} = this.props;
                                                      
    return (
      <Modal open={this.props.openModal} closeIcon onClose={this.props.closeModal} >
    	 <Modal.Header>{addCounter.counterId > 0 ? "Edit Counter" : "Add Counter"} </Modal.Header> 
        {/* <Modal.Header>Add Counter</Modal.Header> */}
        <Modal.Content>
            <Form loading={api.isApiLoading}>
          <Grid>
            <Grid.Column width={8}>
              <Form.Input fluid textAlign="left" name="txtCounterName"
                  placeholder="Counter Name" 
              error={this.validator.message("counterName", addCounter.counterName, "required")}
              data="counterName" onChange={onAddCounterHandleChange} value={addCounter.counterName} />
            </Grid.Column>
            <Grid.Column width={8}>
              <Input label={{ basic: true, content: '$' }} labelPosition='left'  placeholder="Beginning Amount" readOnly={oneditButton}  fluid name="txtBeginningAmount"
              // error={this.validator.message("beginningAmount", addCounter.beginningAmount, "required|numeric")}
              data="beginningAmount" onChange={onAddCounterHandleChange} value={addCounter.beginningAmount}/>
                
            </Grid.Column>

            <Grid.Column width={16} textAlign="right">
              <Button className="blue-btn" onClick={this.props.closeModal}>Cancel </Button>
              {!oneditButton ?
              <Button className="orange-btn" onClick={this.onsaveAddCounter}>Save</Button>:
              <Button className="orange-btn" onClick={this.onsaveAddCounter}>Update</Button>
              }
            </Grid.Column>
          </Grid>
            </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      // storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      // showNotification: bindActionCreators(Notifications.show, dispatch)
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddCounter);
