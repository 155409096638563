import React, { Component } from "react";
import { DataTable } from "../../../shared/components/organisms";
import { Icon, Checkbox } from "semantic-ui-react";
import { commonFunctions, } from "../../../shared/functional/global-import";

class TimeFrameList extends Component {
    childsChild = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    render() {
        const { fullState, allFunction } = this.props;

        return (
            <>
                <DataTable
                    ref={this.childsChild}
                    getApiName="GETTIMEFRAMEDETAIL"
                    searchOption={{ show: true, placeHolder: "Search time frame" }}
                    gridName="TimeFrameGrid"
                    screenName="time-frame"
                    fullState={fullState}
                    allFunction={allFunction}
                    tableHeight="250px"
                    emptyGrid="No records to show"
                    columns={[
                        {
                            headerName: "Title",
                            fieldName: "timeFrameTitle",
                            className: "textOverflowHidden",
                            isSorting: true,
                            Cell: (props) => { 
                                return (
                                    <span title={props.timeFrameTitle}>{props.timeFrameTitle}</span>
                                  );
                               },
                        },
                        {
                            headerName: "Time Range",
                            fieldName: "timeFrameEventName",
                            isSorting: true,
                            className: "textOverflowHidden",
                            Cell: (props) => { 
                                return (
                                    <span title={props.timeFrameEventName}>{props.timeFrameEventName}</span>
                                  );
                               },
                        },
                        {
                            headerName: "Range",
                            fieldName: "range",
                            className: "textOverflowHidden",
                            isSorting: true,
                            Cell: (props) => { 
                                return (
                                    <span>{props.range}</span>
                                  );
                               },


                        },
                        {
                            headerName: "Timing",
                            fieldName: "timing",
                            className: "textOverflowHidden",
                            isSorting: true,
                        },
                        {
                            headerName: "Active",
                            fieldName: "isActive",
                            isSorting: true,
                            Cell: (props) => {
                                return (
                                    <Checkbox
                                        className="custom-toggle"
                                        checked={props.isActive}
                                        onClick={(data, r) =>
                                            commonFunctions.activateDeactivateToggle(allFunction, props.timeFrameTitle, props.timeFrameId, "timeFrameId", props.isActive)
                                        }
                                    ></Checkbox>
                                );
                            },
                        },
                        {
                            headerName: "Action",
                            fieldName: "action",
                            isSorting: false,
                            Cell: (props) => {
                                return (
                                    <React.Fragment>
                                        <Icon
                                            title="Edit"
                                            name="pencil alternate"
                                            size="large"
                                            className="deepviolet-color pointer"
                                            onClick={() => allFunction.editTimeFrame(props)}
                                        />

                                        <Icon
                                            name="trash alternate"
                                            size="large"
                                            className="orange-color pointer"
                                            title="Delete"
                                            link
                                            disabled={props.timeFrameTitle === "All (Season)"}
                                            onClick={() => commonFunctions.deleteModalMessage(allFunction,
                                                 props.timeFrameTitle,
                                                 props.timeFrameId, "timeFrameId")}
                                        />
                                    </React.Fragment>
                                );
                            },
                        },
                    ]}
                />
            </>
        );
    }
}

export default TimeFrameList;