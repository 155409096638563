import React, { Component } from "react";
import { Button, Grid, Modal, Table, Icon, Checkbox } from "semantic-ui-react";


class ActivityViewQuestionModal extends Component {

    render() {

        return (
            <Modal open={this.props.openModal} closeIcon onClose={this.props.closeModal} size={"small"}>
                <Modal.Header>View Questions</Modal.Header>
                <Modal.Content>
                    <Grid>
                        <Grid.Column width={16}>
                            <Table>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Question Name</Table.HeaderCell>
                                        <Table.HeaderCell>Required</Table.HeaderCell>
                                        <Table.HeaderCell textAlign="right">Action</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell>Email Address</Table.Cell>
                                        <Table.Cell><Checkbox className="custome-checkbox" /></Table.Cell>
                                        <Table.Cell textAlign="right">
                                        <Icon name="pencil alternate" size="large" className="deepviolet-color" title="Edit" link/>
                                        <Icon name="trash alternate" size="large" className="orange-color" title="Delete" link/>
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Weight</Table.Cell>
                                        <Table.Cell><Checkbox className="custome-checkbox" defaultChecked /></Table.Cell>
                                        <Table.Cell textAlign="right">
                                        <Icon name="pencil alternate" size="large" className="deepviolet-color" title="Edit" link/>
                                        <Icon name="trash alternate" size="large" className="orange-color" title="Delete" link/>
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Address</Table.Cell>
                                        <Table.Cell><Checkbox className="custome-checkbox" defaultChecked /></Table.Cell>
                                        <Table.Cell textAlign="right"> 
                                        <Icon name="pencil alternate" size="large" className="deepviolet-color" title="Edit" link/>
                                        <Icon name="trash alternate" size="large" className="orange-color" title="Delete" link/>
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </Grid.Column>
                        <Grid.Column width={16} textAlign="right">
                            <Button className="blue-btn" onClick={this.props.closeModal}>Back</Button>
                            <Button className="orange-btn" onClick={this.props.closeModal}>Save</Button>
                        </Grid.Column>
                    </Grid>
                </Modal.Content>
            </Modal>
        );
    }
}
export default ActivityViewQuestionModal;
