import React from "react";
import PropTypes from "prop-types";
import { Form, Radio, Grid } from "semantic-ui-react";
import { connect } from "../../../functional/global-import";

const GlobalRadioGroup = ({
  showRadioButtons,
  onChange,
  value,
  categoryType,
  global,
  data,
  boolenPricing,
}) => {
  let filteredGlobalRadioButton = [];
  global.codes
    .filter((code) => code.categoryName === categoryType)
    .map((filtercode) => {
      if (showRadioButtons && showRadioButtons.length > 1) {
        showRadioButtons.filter(function (item) {
          if (filtercode.codeName.indexOf(item) > -1) {
            return filteredGlobalRadioButton.push({
              id: filtercode.globalCodeId,
              label: filtercode.codeName,
            });
          }
        });
      } else {
        return filteredGlobalRadioButton.push({
          id: filtercode.globalCodeId,
          label: filtercode.codeName,
        });
      }
    });
  const radioPricng = filteredGlobalRadioButton.reverse();

  return (
    <>
      {radioPricng.map((detail, index) => (
        <Form.Field>
          <Radio
            className="custom-radio-btn pricingRadio "
            data={data}
            label={detail.label}
            name="radioGroup"
            value={detail.id}
            checked={detail.id === value}
            onChange={onChange}
          />
        </Form.Field>
      ))}
    </>
  );
};

GlobalRadioGroup.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  data: PropTypes.string.isRequired,
  categoryType: PropTypes.string.isRequired,
  // boolenPricing: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => {
  return {
    global: state.global,
  };
};
export default connect(mapStateToProps, null)(React.memo(GlobalRadioGroup));
