import React, { Component } from "react";
import { Grid, Modal, Button, Input, Dimmer, Loader } from "semantic-ui-react";
import { Link } from "react-router-dom";
import {
  connect,
  bindActionCreators,
  actions,
  env,
  commonFunctions,
} from "../../../../functional/global-import";
import { withRouter } from "react-router";

class InvoiceModal extends Component {
  constructor(props) {
    super(props);
      
    this.state = {
      noteRefrenceId: "",
      noteType: "",
      emailId: "",
      isEmailSending: false,
      printFunction: {},
      isMobTab: false,
    };
    this.validator = commonFunctions.initializeSimpleValidator();
  }
  getMobileOS = () => {
    const ua = navigator.userAgent;
    if (/android/i.test(ua)) {
      return "Android";
    } else if (
      /iPad|iPhone|iPod/.test(ua) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
    ) {
      return "iOS";
    }
    return "Other";
  };

  componentDidMount = () => {
    this.setState({ emailId: this.props.emailId });

    const os = this.getMobileOS();

    const isTabMob = os === "Android" || os === "iOS";

    if (isTabMob) {
      this.setState({ isMobTab: true });
    }

    this.props.actions.getOrderItems(this.props.orderId);
  };
  onClickSendInvoice = (e, emailId) => {
    const { OrderDetails, invoiceData } = this.props;
    let productName = OrderDetails[0]?.seasonPassDetail
      ? JSON.parse(OrderDetails[0]?.seasonPassDetail)[0]?.ItemName
      : OrderDetails[0]?.productDetails
      ? JSON.parse(OrderDetails[0]?.productDetails)[0]?.ProductName
      : OrderDetails[0]?.activityDetails
      ? JSON.parse(OrderDetails[0]?.activityDetails)[0]?.ActivityName
      : OrderDetails[0]?.retailDetails
      ? JSON.parse(OrderDetails[0]?.retailDetails)[0]?.ItemName
      : "";
    const orderId = JSON.parse(OrderDetails[0].orderId);
    var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
    if (isFormValid) {
      this.setState({ isEmailSending: true });
      this.props.actions.apiCall({
        urls: ["CREATEINVOICE"],
        method: "POST",
        data: {
          applicationId: env.APPLICATION_ID,
          businessId: this.props.global.bussinessDetail.businessId,
          businessLocationId:
            this.props.global.locationSelected.BusinessLocationId,
          emailId: emailId,
          emailTypeId:commonFunctions.getGlobalCodeDetails( this.props.global.codes,
            "EmailType",
            "Invoice Email"
          ).globalCodeId,
          isViewOrPrint: false,
          orderId: orderId,
          amount: invoiceData.InvoiceBalanceDue,
          productName: productName,
          dueUntilDate: 7,
          quantity: 1,
          selectPaymentType: 483,
          paymentMethod: 67,
          actionPerformedBy: this.props.auth.userDetail.emailId,
        },
        onSuccess: () => {
          this.props.closeModal();
          this.props.allFunction.setState({ isEmailSending:true});
          window.location.reload();

        },
        onFinally: () => {
          this.setState({ isEmailSending: false, sendInvoiceStatus: false });
        },

        showNotification: true,
      });
    }
  };
  onEmailChange = (e) => {
    this.setState({ emailId: e.target.value });
  };
  render() {
    const { emailId, isEmailSending } = this.state;
    const { isRemoveOrder, api } = this.props;
    return (
      <>
        <Modal
          open={this.props.openModal}
          closeIcon
          onClose={() => {
            isRemoveOrder && this.props.actions.removeOrder();
            this.props.closeModal();
          }}
          size={"tiny"}
        >
          {api.isApiLoading && (
            <Dimmer active inverted>
              <Loader />
            </Dimmer>
          )}
          <Modal.Content>
            <Grid>
              <Grid.Column width={9} textAlign="right">
                <Link className="deepviolet-color"> Send the Invoice </Link>
              </Grid.Column>
              <Grid.Column width={16} textAlign="center">
                <Input
                  action={
                    <Button
                      loading={isEmailSending}
                      onClick={(e) => this.onClickSendInvoice(e, emailId)}
                      className="orange-btn"
                    >
                      Send the Invoice
                    </Button>
                  }
                  icon="mail"
                  iconPosition="left"
                  onChange={this.onEmailChange}
                  value={emailId}
                  className="receipt-input"
                />
                <Grid.Column width={16} textAlign="center">
                  {this.validator.message("email", emailId, "required|email")}
                </Grid.Column>
              </Grid.Column>
            </Grid>
          </Modal.Content>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    api: state.api,
    auth: state.auth,
    orderState: state.orderState,
    global: state.global,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      removeOrder: bindActionCreators(actions.removeOrder, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      getOrderItems: bindActionCreators(actions.getOrderItems, dispatch),
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InvoiceModal));
