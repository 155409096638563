import React, { Component } from "react";
import { Modal, Grid, Button, Form, Input } from "semantic-ui-react";
class AddCustomTipModal extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { fullState, allFunction } = this.props;
    return (
      <div>
        <Modal
          open={this.props.openModal}
          onClose={this.props.closeModal}
          size={"mini"}
          closeIcon
          closeOnDimmerClick={false}
        >
          <Modal.Header>Custom Tip</Modal.Header>
          <Grid style={{ padding: "40px" }}>
            
              <Grid.Column width={16} verticalAlign="middle">
                <Form.Field>
                  <Input
                    label="$"
                    labelPosition="left"
                    min="0"
                    placeholder="Custom Tip"
                    fluid
                    value={fullState.customtipTotalValue}
                    name="customtipTotalValue"
                    data="customtipTotalValue"
                    onChange={allFunction.onHandleCustomPrice}
                  />
                </Form.Field>
              </Grid.Column>
          
            <Grid.Column  width={8} mobile={16} tablet={16} textAlign="right">
            <Button  className="blue-btn" onClick={this.props.closeModal}>
                Back
              </Button>
              <Button className="orange-btn" onClick={()=>allFunction.onSubmitCustomTip()}>
                Save
              </Button>
            </Grid.Column>
          </Grid>
        </Modal>
      </div>
    );
  }
}

export default AddCustomTipModal;
