import React, { Component } from "react";
import { Grid, Header, Form, Button, Icon, Divider, Input, Dimmer, Loader, Checkbox, Dropdown, GridColumn } from "semantic-ui-react";
import { connect, bindActionCreators, actions, Notifications, commonFunctions } from "../../../../shared/functional/global-import";
import { CommonDeleteUpdateModal, GlobalCodeSelect, CustomTimeFrameModal, SlotBasedDuration } from "../../../../shared/components";
import RentalGrid from "./rental-grid";

const optionDay = [
    { key: 'Min', text: 'Min', value: '1' },
    { key: 'Hour', text: 'Hour', value: '2' },
]
const optionHour = [{ key: 'Min', text: 'Min', value: '3' }]
const option = [{ key: 'Day', text: 'Day', value: '4' }]
class RentalForm extends Component {
    child = React.createRef();
    get initialState() {
        return {
            addItemPricing: {
                businessId: this.props.global.bussinessDetail.businessId, businessLocationId: this.props.global.locationSelected.BusinessLocationId,
                productId: null,
                rentalPricingType: "",
                showSpecialPriceInWidget: false,
                //   for 55 Flat Fee Rental Price and  54 Item with Saved Rate Price
                // rentalPricingType: (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "RentalPricingType" , "Duration based slot price", )).globalCodeId,
                rentalPricingTitle: "", quantity: "", duration: "", timeFrameId: "", itemPrice: "",
                deposit: "", actionPerformedBy: this.props.auth.userDetail.emailId, rentalPricingId: "", valid: false
            },
            displayButton: "Save",
            isRentalAddBtnLoading: false,
        };
    }

    constructor(props) {

        super(props);
        this.state = {
            ...this.initialState,
            gridDetail: [],
            isCommonDeleteUpdateModalOpen: false,
            selectedId: "",
            time: "",
            fieldSelected: "",
            flatFeeTable: true,
            value: "this",
            rentalPriceDropdownList: [],
            flatFeeDropdownList: [],
            updateButton: false,
            timeFrameList: [],
            applyAll: "",
            a: "",
            rentalPricing: {
                businessId: this.props.global.bussinessDetail.businessId,
                productId: "",
                businessLocationId: this.props.global.locationSelected.BusinessLocationId,
                minutePrice: "",
                minuteDeposit: "",
                hourPrice: "",
                hourDeposit: "",
                hourGracePeriodType: "",
                hourGracePeriod: "",
                hourAdditionalPrice: "",
                dayPrice: "",
                dayDeposit: "",
                dayGracePeriodType: "",
                dayGracePeriod: "",
                dayAdditionalPrice: "",
                weekPrice: "",
                weekDeposit: "",
                weekGracePeriodType: "",
                weekGracePeriod: "",
                weekAdditionalPrice: "",
                actionPerformedBy: this.props.auth.userDetail.emailId,
                rentalProductDurationId: "",
            },
            customDurationStatus: false,
            customTimeStatus: false,
            timeFrameForm: {
                customTimeFirst: "",
                customTimeTwo: "",
                customTimeThree: ""
            },
            selectedSlots: [],
            customDurationSlotForm: {
                businessId: this.props.global.bussinessDetail.businessId,
                businessLocationId: this.props.global.locationSelected.BusinessLocationId,
                duration: "",
                durationType: "",
                customTime: "",
                actionPerformedBy: this.props.auth.userDetail.emailId,
            },
            generalRentalPricingId: 0,
            addNewPrice: false,
            isDeleteModalOpen: false,
            newAddedCustomDuration: false,
            newSlotChange: false,
            durationDetails: null,
            customDurationDetails: [],
        };
        this.savedRateValidator = commonFunctions.initializeSimpleValidator();
        this.flatFeeValidator = commonFunctions.initializeSimpleValidator();
        this.validator = commonFunctions.initializeSimpleValidator();
        this.slotValidator = commonFunctions.initializeSimpleValidator();
    }
    componentDidMount = () => {
        if (this.props.fullState !== undefined) {
            const { fullState } = this.props;
            const { addItemPricing } = this.state;
            addItemPricing.rentalPricingType = fullState.rentalPricingType;
            this.setState({ ...addItemPricing })
        }
        //==============  get api call for title dropdown Saved Rate Price ==============
        commonFunctions.getFunction(this, "GETRENTALPRODUCTPRICE", "rentalPricingId", "rentalPricingTitle", "rentalPriceDropdownList",
            false, { "RentalPricingType": (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "RentalPricingType", "Item with Saved Rate Price",)).globalCodeId });

        //==============  get api call for title dropdown Flat Fee Price ==============
        commonFunctions.getFunction(this, "GETRENTALPRODUCTPRICE", "rentalPricingId", "rentalPricingTitle", "flatFeeDropdownList",
            false, { "RentalPricingType": (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "RentalPricingType", "Flat Fee Rental Price",)).globalCodeId });

        //============== TimeFrame get api call ==============
        commonFunctions.getFunction(this, "GETTIMEFRAME", "timeFrameId", "timeFrameTitle", "timeFrameList");
        this.getProductDuration();


        if (this.props.productId !== undefined && this.props.rentalPricingType !== undefined) {
            const { addItemPricing, rentalPricing } = this.state;
            addItemPricing.rentalPricingType = this.props.rentalPricingType;
            addItemPricing.productId = this.props.productId;
            this.setState({ addItemPricing })

            rentalPricing.rentalProductDurationId = this.props.rentalProductDurationId;
            rentalPricing.productId = this.props.productId;
            this.setState({ rentalPricing })
        }
        if (this.props.productId !== undefined && this.props.rentalPricingType === "") {
            const { addItemPricing } = this.state;
            // addItemPricing.rentalPricingType = 54
            //  now by default Duration based slot price radio btn selected 
            addItemPricing.rentalPricingType = (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "RentalPricingType", "Duration based slot price",)).globalCodeId
            addItemPricing.productId = this.props.productId;
            this.setState({ addItemPricing })
        }
        if (this.props.productId === undefined && this.props.rentalPricingType === undefined) {
            const { addItemPricing } = this.state;
            addItemPricing.rentalPricingType = (commonFunctions.getGlobalCodeDetails(this.props.global.codes,
                "RentalPricingType", "Item with Saved Rate Price",)).globalCodeId
            this.setState({ addItemPricing })
        }

        this.props.productId && this.onGetCustomDuration()
    }


    //  saved slot based pricing get
    onGetProductCustomDuration = (customDurationDetails) => {
        this.props.actions.apiCall({
            urls: ["GETPRODUCTCUSTOMDURATIONS"],
            method: "GET",
            data: {
                productId: this.props.productId,
                businessId: this.props.global.bussinessDetail.businessId,
                businessLocationId: this.props.global.locationSelected.BusinessLocationId,
                businessCategoryType: (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "BusinessCategoryType", "Rental")).globalCodeId
            },
            onSuccess: (response) => {
                // const { customDurationDetails } = this.state;


                if (response.length === 0) {
                    customDurationDetails && customDurationDetails.map((singleObj) => {
                        if (singleObj.slots) {
                            singleObj.slots && singleObj.slots.map((newCustomObj) => {
                                newCustomObj.isSelected = true
                                return {
                                    ...newCustomObj,
                                }
                            })
                        }
                        singleObj.isSelected = true
                        singleObj.slotsAdded = "added"
                        return {
                            ...singleObj,
                        }
                    })

                    this.setState({ customDurationDetails: customDurationDetails })
                    this.slotValidator = commonFunctions.initializeSimpleValidator();
                }
                else if (response.length > 0) {

                    customDurationDetails && customDurationDetails.map((singleObj) => {
                        if (singleObj.slots) {
                            singleObj.slots && singleObj.slots.map((newCustomObj) => {
                                newCustomObj.isSelected = true
                                return {
                                    ...newCustomObj,
                                }
                            })
                        }
                        singleObj.isSelected = true
                        return {
                            ...singleObj,
                        }
                    })
                    response && response.map((singleObj) => {
                        if (singleObj.slots) {
                            singleObj.slots = JSON.parse(singleObj.slots)
                            singleObj.slots && singleObj.slots.map((newCustomObj) => {
                                newCustomObj.isSelected = true
                                return {
                                    ...newCustomObj,
                                }
                            })
                        }
                        return {
                            ...singleObj
                        }
                    })

                    customDurationDetails.map((customDurationObj) => {
                        response.map((savedDetails) => {
                            if (customDurationObj.customDurationId === savedDetails.customDurationId) {
                                customDurationObj.isSelected = true
                                customDurationObj.slotsAdded = "added"
                                customDurationObj.slots = savedDetails.slots
                                return { ...customDurationObj }
                            }
                        })
                    })
                    this.setState({ customDurationDetails: customDurationDetails })
                    this.slotValidator = commonFunctions.initializeSimpleValidator();
                }
            },
        });
    }



    onGetCustomDuration = () => {
        this.props.actions.apiCall({
            urls: ["GETCUSTOMDURATION"],
            method: "GET",
            data: {
                productId: this.props.productId,
                businessId: this.props.global.bussinessDetail.businessId,
                businessLocationId: this.props.global.locationSelected.BusinessLocationId,
                businessCategoryType: (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "BusinessCategoryType", "Rental")).globalCodeId
            },
            onSuccess: (response) => {

                const { newAddedCustomDuration, durationDetails, newlyAddedSLot } = this.state;
                response && response.map((singleObj) => {
                    if (singleObj.slots) {
                        singleObj.slots = JSON.parse(singleObj.slots)
                        // singleObj.slots && singleObj.slots.map((newCustomObj) => {
                        //     newCustomObj.isSelected = true
                        //     return {
                        //         ...newCustomObj,
                        //     }
                        // })
                    }
                    singleObj.isSelected = true
                    singleObj.slotsAdded = "added"
                    return {
                        ...singleObj,
                    }
                })

                // newAddedCustomDuration && response && response.length > 0 && response[response.length - 1].slots && response[response.length - 1].slots.map((newCustomObj) => {
                //     newCustomObj.isSelected = true
                //     return {
                //         ...newCustomObj,
                //     }
                // })

                // durationDetails !== null && newlyAddedSLot && response && response.map((singleObjDetail) => {
                //     if (singleObjDetail.customDurationId === durationDetails.customDurationId) {
                //         singleObjDetail.slots.map((newSlotAdded) => {
                //             if (newSlotAdded.CustomDurationSlotId === newlyAddedSLot) {
                //                 newSlotAdded.isSelected = true
                //             }
                //         })
                //     }
                //     return {
                //         ...singleObjDetail,
                //     }
                // })

                // newAddedCustomDuration && response && response.length > 0 && response[response.length - 1].slots !== null &&
                //     this.setState({ newSlotChange: true })

                // durationDetails !== null && newlyAddedSLot && response &&
                //     this.setState({ newSlotChange: true })

                response && this.onGetProductCustomDuration(response)
                // response && response.length > 0 && this.setState({ customDurationDetails: response }, () => this.onGetProductCustomDuration())
                // response && response.length === 0 && this.setState({ customDurationDetails: response }, () => this.onDeleteBtnPress())
            },
        });
    }

    customTimeModal = (objDetails, parentIndex) => {

        // this.state.newSlotChange && this.onAddRentalCustomTimeDurations()
        this.setState({ customTimeStatus: !this.state.customTimeStatus, durationDetails: objDetails, customTimeParentIndex: parentIndex })
    }
    onCloseCustomTimeModal = () => {

        const { timeFrameForm } = this.state;
        timeFrameForm.customTimeFirst = ""
        timeFrameForm.customTimeTwo = ""
        timeFrameForm.customTimeThree = ""
        this.validator = commonFunctions.initializeSimpleValidator();
        this.setState({ timeFrameForm, customTimeStatus: false })
    }
    customDurationModal = (e) => {

        const { customDurationDetails } = this.state;
        customDurationDetails && customDurationDetails.length > 0 && this.onAddRentalCustomTimeDurations(e)
        this.setState({ customDurationStatus: !this.state.customDurationStatus, newAddedCustomDuration: true })
    }
    onCloseSlotDuration = () => {
        this.setState({ customDurationStatus: false }, () => this.onGetCustomDuration())
    }

    getProductDuration = () => {
        this.props.actions.apiCall({
            urls: ["GETPRODUCTPriceDURATION"],
            method: "GET",
            data: { productId: this.props.productId },
            onSuccess: (response) => {
                if (response.length > 0) {
                    this.setState({ rentalProductDurationId: response[0].rentalProductDurationId })
                    this.setState(() => ({
                        rentalPricing: {
                            ...this.state.rentalPricing,
                            // minutePrice: response[0].minutePrice,
                            // minuteDeposit: response[0].minuteDeposit,
                            hourPrice: response[0].hourPrice,
                            hourGracePeriodType: response[0].hourGracePeriod,
                            hourGracePeriod: response[0].hourGracePeriod,
                            hourAdditionalPrice: response[0].hourAdditionalPrice,
                            hourDeposit: response[0].hourDeposit,
                            dayPrice: response[0].dayPrice,
                            dayDeposit: response[0].dayDeposit,
                            dayGracePeriodType: response[0].dayGracePeriodType,
                            dayGracePeriod: response[0].dayGracePeriod,
                            dayAdditionalPrice: response[0].dayAdditionalPrice,
                            weekPrice: response[0].weekPrice,
                            weekDeposit: response[0].weekDeposit,
                            weekGracePeriodType: response[0].weekGracePeriodType,
                            weekGracePeriod: response[0].weekGracePeriod,
                            weekAdditionalPrice: response[0].weekAdditionalPrice,
                            productId: this.props.productId,
                            rentalProductDurationId: response[0].rentalProductDurationId,
                        },
                        dayAdditionalPrice: 11
                    }));
                }
            },
        });
    };

    editProductPrice = (priceDtata) => {
        this.setState({
            updateButton: "true",
            displayButton: "Update"
        });
        this.setState(() => ({
            addItemPricing: {
                ...this.state.addItemPricing,
                rentalPricingTitle: priceDtata.rentalPricingTitle,
                quantity: priceDtata.quantity,
                duration: priceDtata.duration === 0 ? null : priceDtata.duration,
                timeFrameId: priceDtata.timeFrameId === 0 ? null : priceDtata.timeFrameId,
                itemPrice: priceDtata.itemPrice,
                deposit: priceDtata.deposit,
                rentalPricingType: priceDtata.rentalPricingType,
                rentalPricingId: priceDtata.rentalPricingId,
                productId: this.props.productId,
                showSpecialPriceInWidget: priceDtata.showSpecialPriceInWidget, //new param added    

                generalRentalPricingId: priceDtata.generalRentalPricingId
            },
        }));
        this.setState({ generalRentalPricingId: priceDtata.generalRentalPricingId })
    };

    onHandlePriceType = (e, { data, value }) => {

        const { fullState, allFunction } = this.props;
        // const {addItemPricing} = this.state;
        let checkPricingField = this.state.rentalPricing;
        let pricematch;
        let arr = [checkPricingField.dayDeposit, checkPricingField.dayPrice, checkPricingField.hourDeposit, checkPricingField.hourPrice, checkPricingField.minuteDeposit, checkPricingField.minutePrice, checkPricingField.weekDeposit, checkPricingField.weekPrice]
        arr.map((sp) => {
            if (sp > 0) {
                return pricematch = true;
            }
        });

        // addItemPricing.rentalPricingType === (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "RentalPricingType", "Duration based slot price",)).globalCodeId && this.onDeleteBtnPress()

        if (data === "RatePrice") {
            const { gridDetail } = this.state;
            if (!pricematch && ((gridDetail.length > 0 && gridDetail[0].rentalPricingType !== this.state.addItemPricing.rentalPricingType) || gridDetail.length === 0)) {

                if (this.props.productId && this.state.customDurationDetails.length > 0) {
                    this.showErrorMessage()
                }
                else {
                    this.setState(() => ({
                        addItemPricing: { ...this.state.addItemPricing, rentalPricingType: (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "RentalPricingType", "Item with Saved Rate Price",)).globalCodeId },
                    }));
                    allFunction.setState({ rentalPricingType: value })
                    this.flatFeeValidator = commonFunctions.initializeSimpleValidator();
                }
            }
            else {
                this.showErrorMessage()
            }
        }

        else if (data === "FlatFee") {
            const { gridDetail } = this.state;
            if (!pricematch && ((gridDetail.length > 0 && gridDetail[0].rentalPricingType !== this.state.addItemPricing.rentalPricingType) || gridDetail.length === 0)) {
                if (this.props.productId && this.state.customDurationDetails.length > 0) {
                    this.showErrorMessage()
                }
                else {
                    this.setState(() => ({
                        addItemPricing: { ...this.state.addItemPricing, rentalPricingType: (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "RentalPricingType", "Flat Fee Rental Price",)).globalCodeId, quantity: "", timeFrameId: "", duration: "" },
                    }));
                    allFunction.setState({ rentalPricingType: value })
                    this.savedRateValidator = commonFunctions.initializeSimpleValidator();
                }
            }
            else {
                this.showErrorMessage()
            }
        }

        else if (data === "durationBased") {
            const { gridDetail } = this.state;
            if (pricematch === true || gridDetail.length > 0) {
                this.showErrorMessage()
            }
            else {
                this.setState(() => ({
                    addItemPricing: { ...this.state.addItemPricing, rentalPricingType: (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "RentalPricingType", "Duration based slot price",)).globalCodeId, quantity: "", timeFrameId: "", duration: "" },
                }));
                allFunction.setState({ rentalPricingType: value })
                this.savedRateValidator = commonFunctions.initializeSimpleValidator();
                this.flatFeeValidator = commonFunctions.initializeSimpleValidator();
            }
        }
    };

    showErrorMessage = () => {

        this.props.actions.showNotification(
            {
                title: "Warning",
                message: `Only one fee type can be used at a time. Either "Duration based slot price" or "Saved rate Price" or "Flat fee Price" . To switch to other fee type please delete the saved rates first .`,
                position: "br",
                autoDismiss: commonFunctions.notificationTime(),
            },
            "error"
        );
    }

    onHandlePriceTypeMoreSetting = (e, { value }) => {

        this.setState(() => ({
            addItemPricing: { ...this.state.addItemPricing, rentalPricingType: value },
        }));
        this.savedRateValidator = commonFunctions.initializeSimpleValidator();
        this.flatFeeValidator = commonFunctions.initializeSimpleValidator();
    };

    onHandleSubmitRentalDuration = (e) => {
        if (e !== undefined ? true : this.props.warnUserState.isFormChanged) {
            let a = JSON.stringify(this.state.rentalPricing, function (key, value) {
                return value === "" ? null : value;
            });
            this.props.actions.apiCall({
                urls: ["POSTRENTALDURATION"],
                method: "POST",
                data: JSON.parse(a),
                onSuccess: (response) => {
                    this.setState({ applyAll: "" });
                    this.setState(() => ({
                        rentalPricing: { ...this.state.rentalPricing, rentalProductDurationId: response.keyId },
                        isDeleteModalOpen: false
                    }));
                    commonFunctions.onApiSucessFormChangedFalse(this);
                    this.props.productId && this.state.customDurationDetails.length > 0 && this.onGetCustomDuration()
                },
                showNotification: true,
            });
        }
    }


    onCheckBoxHandleChange = (e, { name, value, type, checked, data }) => {
        const { addItemPricing } = this.state
        addItemPricing[data] = checked
        this.setState({ addItemPricing });
    }

    onHandleItemPricing = (e, { name, value, type, checked, data }) => {
        var ex = /^\d*\.?(?:\d{1,2})?$/;

        if (ex.test(value) == true) {
            var addItemPricing = commonFunctions.onHandleChange(e, { name, value, type, checked, data }, this.state.addItemPricing, this);
            this.setState({ addItemPricing });
        }
    };

    handleAddition = (e, { value, name }) => {
        if (name === "savedRate") {
            this.setState((prevState) => ({
                rentalPriceDropdownList: [{ text: value, id: value, value: value }, ...prevState.rentalPriceDropdownList],
                addItemPricing: { ...prevState.addItemPricing, rentalPricingId: value, rentalPricingTitle: value, title: value }
            }))
        }
        if (name === "flatFee") {
            this.setState((prevState) => ({
                flatFeeDropdownList: [{ text: value, id: value, value: value }, ...prevState.flatFeeDropdownList],
                addItemPricing: { ...prevState.addItemPricing, rentalPricingId: value, rentalPricingTitle: value, title: value }
            }))
        }
        commonFunctions.onFormChange(this);
        this.setState({ addNewPrice: true });
    }

    //=============== OnChange of Title dropdown =============== //
    onHandleChangeTitle = (e, { value, data, name }) => {

        this.onhandleClearPriceForm();
        const isNumber = isNaN(value)
        if (!isNumber) {
            this.props.actions.apiCall({
                urls: ["GETRENTALPRODUCTPRICE"],
                method: "GET",
                data: {
                    businessId: this.props.global.bussinessDetail.businessId,
                    businessLocationId: this.props.global.locationSelected.BusinessLocationId,
                    PageNo: 1, PageSize: 100, RentalPricingId: value,
                    RentalPricingType: this.state.addItemPricing.rentalPricingType
                }, onSuccess: (response) => {
                    ;
                    this.state.addItemPricing = response[0];
                    if (this.props.productId === undefined) {
                        this.setState({
                            ...this.state, addItemPricing: {
                                ...this.state.addItemPricing,
                                actionPerformedBy: this.props.auth.userDetail.emailId,
                            }, displayButton: "Update"
                        });
                        commonFunctions.onFormChange(this);
                    } else {
                        if (name === "flatFee") {
                            this.setState({
                                ...this.state, addItemPricing: {
                                    ...this.state.addItemPricing,
                                    productId: this.props.productId, actionPerformedBy: this.props.auth.userDetail.emailId,
                                    duration: null, timeFrameId: null
                                }, displayButton: "Save"
                            });
                            commonFunctions.onFormChange(this);
                        } else {
                            this.setState({
                                ...this.state, addItemPricing: {
                                    ...this.state.addItemPricing,
                                    productId: this.props.productId, actionPerformedBy: this.props.auth.userDetail.emailId,
                                }, displayButton: "Save"
                            });
                            commonFunctions.onFormChange(this);
                        }
                    }
                },
            });
        }
        else {
            this.onhandleClearPriceForm();
        }
    }

    onHandleSubmitButtonAddRental = (e) => {

        const { gridDetail, addItemPricing } = this.state;

        let b = JSON.stringify(this.state.addItemPricing, function (key, value) {
            return value === "" ? null : value;
        });

        if (this[e].allValid() === false) {
            this[e].showMessages();
            this.forceUpdate();
        } else if (this.state.displayButton === "Save") {

            this.props.actions.apiCall({
                urls: ["POSTRENTALPRICE"],
                method: "POST",
                data: JSON.parse(b),
                onSuccess: (response) => {

                    this.child.current.childsChild.current.getDefaultData();
                    commonFunctions.onApiSucessFormChangedFalse(this);
                    if (addItemPricing.rentalPricingType === (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "RentalPricingType", "Item with Saved Rate Price",)).globalCodeId && this.state.addNewPrice) {
                        (this.state.rentalPriceDropdownList[0])["value"] = this.props.productId === undefined ? response.keyId : response.generalRentalPricingId;
                    }
                    if (addItemPricing.rentalPricingType === (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "RentalPricingType", "Flat Fee Rental Price",)).globalCodeId && this.state.addNewPrice) {
                        (this.state.flatFeeDropdownList[0])["value"] = this.props.productId === undefined ? response.keyId : response.generalRentalPricingId;
                    }
                    this.setState({ addNewPrice: false })
                    this.onhandleClearPriceForm();
                    this.props.getProductCategoryDetail();
                    this.props.productId && this.state.customDurationDetails.length > 0 && this.onGetCustomDuration()
                },
                showNotification: true,
            });
            this[e] = commonFunctions.initializeSimpleValidator();
        }
        if (this.state.displayButton === "Update") {
            this.props.actions.apiCall({
                urls: ["UPDATERENTALPRODUCTPRICE"],
                method: "PUT",
                data: JSON.parse(b),
                onSuccess: (response) => {
                    this.child.current.childsChild.current.getDefaultData();
                    this.onhandleClearPriceForm();
                    commonFunctions.onApiSucessFormChangedFalse(this);
                    this.setState({ generalRentalPricingId: "" })
                    this.props.getProductCategoryDetail();
                },
                showNotification: true,
            });
            this[e] = commonFunctions.initializeSimpleValidator();
        }
    }

    onHandleRentalDuration = (e, { name, value, type, checked, data }) => {

        var ex = /^([0-9]+\.?[0-9]*|\.[0-9]+)$/;
        if (e.target.value.match(ex)) {
            this.setState({ rentalPricing: { ...this.state.rentalPricing, [data]: e.target.value } })
        }
        else {
            this.setState({ rentalPricing: { ...this.state.rentalPricing, [data]: null } });
        }
    };
    onHandleRentalSelection = (e, { name, value, type, checked, data }) => {

        var rentalPricing = commonFunctions.onHandleChange(e, { name, value, type, checked, data }, this.state.rentalPricing, this);
        this.setState({ rentalPricing })
    };

    onHandleGracePeroid = (e, { name, value, type, checked, data }) => {
        var ex = /^(?:\d*\d{1,2}|\d+)$/;
        if (ex.test(value) === true) {
            var rentalPricing = commonFunctions.onHandleChange(e, { name, value, type, checked, data }, this.state.rentalPricing, this);
            this.setState({ rentalPricing })
        }
        if (ex.test(value) === false) {
            this.setState({ rentalPricing: { ...this.state.rentalPricing, [data]: null } });
        }
    };

    onHandleApplyToAll = (e, { checked, name, data }) => {
        const { rentalPricing } = this.state;
        this.setState({ [data]: checked });
        // if (checked && name === "minute") {
        //     this.setState(() => ({
        //         rentalPricing: {
        //             ...this.state.rentalPricing,
        //             hourPrice: rentalPricing.minutePrice,
        //             hourDeposit: rentalPricing.minuteDeposit,
        //             dayPrice: rentalPricing.minutePrice,
        //             dayDeposit: rentalPricing.minuteDeposit,
        //             weekPrice: rentalPricing.minutePrice,
        //             weekDeposit: rentalPricing.minuteDeposit,
        //         },
        //     }));
        // }
        if (checked && name === "hour") {
            this.setState(() => ({
                rentalPricing: {
                    ...this.state.rentalPricing,
                    // minutePrice: rentalPricing.hourPrice,
                    // minuteDeposit: rentalPricing.hourDeposit,
                    dayPrice: rentalPricing.hourPrice,
                    dayDeposit: rentalPricing.hourDeposit,
                    weekPrice: rentalPricing.hourPrice,
                    weekDeposit: rentalPricing.hourDeposit,
                },
            }));
        }
        if (checked && name === "day") {
            this.setState(() => ({
                rentalPricing: {
                    ...this.state.rentalPricing,
                    hourPrice: rentalPricing.dayPrice,
                    hourDeposit: rentalPricing.dayDeposit,
                    // minutePrice: rentalPricing.dayPrice,
                    // minuteDeposit: rentalPricing.dayDeposit,
                    weekPrice: rentalPricing.dayPrice,
                    weekDeposit: rentalPricing.dayDeposit,
                },
            }));
        }
        if (checked && name === "7days") {
            this.setState(() => ({
                rentalPricing: {
                    ...this.state.rentalPricing,
                    hourPrice: rentalPricing.weekPrice,
                    hourDeposit: rentalPricing.weekDeposit,
                    dayPrice: rentalPricing.weekPrice,
                    dayDeposit: rentalPricing.weekDeposit,
                    // minutePrice: rentalPricing.weekPrice,
                    // minuteDeposit: rentalPricing.weekDeposit,
                },
            }));
        }
    };

    onhandleClearPriceForm = () => {
        ;
        this.setState({ addItemPricing: { ...this.state.addItemPricing, rentalPricingTitle: "", quantity: "", duration: "", timeFrameId: "", deposit: "", itemPrice: "", generalRentalPricingId: "", rentalPricingId: "", showSpecialPriceInWidget: false } });
        this.setState({ displayButton: "Save", })
    }

    componentDidUpdate = () => {
        const { addItemPricing } = this.state
        if (this.state.timeFrameList.length > 0 && addItemPricing.timeFrameId === "") {

            const defaultSeason = this.state.timeFrameList.filter(code => code.text === 'All (Season)')
            addItemPricing.timeFrameId = defaultSeason[0] && defaultSeason[0].id
            this.setState(addItemPricing)

        }
    }

    onCustomDurationDelete = (singleObjDuration) => {
        this.props.actions.apiCall({
            urls: ["DELETECUSTOMDURATION"],
            method: "DELETE",
            data: {
                businessId: this.props.global.bussinessDetail.businessId,
                businessLocationId: this.props.global.locationSelected.BusinessLocationId,
                ProductId: this.props.productId,
                customDurationId: singleObjDuration.customDurationId,
                actionPerformedBy: this.props.auth.userDetail.emailId,
            },
            onSuccess: (response) => {
                this.onGetCustomDuration()
            },
            showNotification: true,
        });
    }



    // time slots
    onCustomSlotDurationDelete = (singleSlotObjDuration, singleObjDuration, parentIndex, slotIndex) => {

        const { customDurationDetails } = this.state
        customDurationDetails[parentIndex].slots = customDurationDetails[parentIndex].slots.filter((slotObj) => {
            return slotObj.CustomTime !== singleObjDuration.CustomTime
        })

        if (customDurationDetails[parentIndex].slots.length === 0) {
            customDurationDetails[parentIndex].slotsAdded = ""
        }
        else { customDurationDetails[parentIndex].slotsAdded = "added" }

        this.setState({ customDurationDetails })
    }

    onSlotHandleChange = (e, { name, value, type, checked, data }) => {

        var timeFrameForm = commonFunctions.onHandleChange(e, { name, value, type, checked, data }, this.state.timeFrameForm, this);
        this.setState({ timeFrameForm });
    };






    onClickSaveDurationSlot = (e) => {

        var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
        if (isFormValid) {
            const { timeFrameForm, customDurationSlotForm, customTimeParentIndex } = this.state
            customDurationSlotForm.customTime = timeFrameForm.customTimeFirst + ":" + timeFrameForm.customTimeTwo + timeFrameForm.customTimeThree
            const { customDurationDetails } = this.state

            let newSlotObj = {
                CustomTime: customDurationSlotForm.customTime,
                isSelected: true
            }
            customDurationDetails[customTimeParentIndex].slots.push(newSlotObj)

            customDurationDetails[customTimeParentIndex].slotsAdded = "added"
            this.slotValidator = commonFunctions.initializeSimpleValidator();
            timeFrameForm.customTimeFirst = ""
            timeFrameForm.customTimeTwo = ""
            timeFrameForm.customTimeThree = ""
            this.setState({ customDurationDetails, timeFrameForm, customTimeStatus: false, })
        }

    }
    onSelectCustomTimeSlot = (parentIndex, slotIndex) => {

        const { customDurationDetails } = this.state
        customDurationDetails[parentIndex].slots[slotIndex].isSelected = !customDurationDetails[parentIndex].slots[slotIndex].isSelected
        this.setState({ customDurationDetails, newSlotChange: true })
    }
    onSelectCustomDurationSlot = (index) => {

        const { customDurationDetails } = this.state
        customDurationDetails[index].isSelected = !customDurationDetails[index].isSelected
        this.setState({ customDurationDetails })
    }

    onAddRentalCustomTimeDurations = (e) => {

        const { customDurationDetails } = this.state;
        let newData = []
        customDurationDetails && customDurationDetails.length > 0 && customDurationDetails.map((singleCustomDetails) => {
            if (singleCustomDetails.isSelected) {
                let slotsDetail = []
                singleCustomDetails.slots && singleCustomDetails.slots.map((singleObjSlot) => {
                    if (singleObjSlot.isSelected) {
                        slotsDetail.push(singleObjSlot.CustomTime)
                    }
                })
                slotsDetail.length > 0 && newData.push({
                    customDurationId: singleCustomDetails.customDurationId,
                    slots: slotsDetail
                })
            }
        })


        if (newData && newData.length > 0) {

            e.preventDefault();
            if (this.slotValidator.allValid() === false) {
                this.slotValidator.showMessages();
                this.forceUpdate();
                return false;
            }
            else {
                this.props.actions.apiCall({
                    urls: ["ADDRENTALCUSTOMTIMEDURATION"],
                    method: "POST",
                    data: {
                        productId: this.props.productId,
                        businessId: this.props.global.bussinessDetail.businessId,
                        businessLocationId: this.props.global.locationSelected.BusinessLocationId,
                        businessCategoryType: (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "BusinessCategoryType", "Rental")).globalCodeId,
                        actionPerformedBy: this.props.auth.userDetail.emailId,
                        pricingDetails: newData,
                    },
                    onSuccess: (response) => {
                        this.slotValidator = commonFunctions.initializeSimpleValidator();
                    },
                    showNotification: true,
                });
            }
        }

        else {
            this.props.actions.showNotification(
                {
                    title: "Warning",
                    message: `Please select "Duration" and "Slots".`,
                    position: "br",
                    autoDismiss: commonFunctions.notificationTime(),
                },
                "error"
            );
        }
    }

    onOpenDeleteUpdateModal = (pricingType) => {
        const { customDurationDetails, rentalPricing } = this.state;
        customDurationDetails.length > 0 && this.setState({ isDeleteModalOpen: true, pricingType })
        rentalPricing.rentalProductDurationId !== "" && this.setState({ isDeleteModalOpen: true, pricingType })
        // this.setState(() => ({
        //     rentalPricing: {
        //         ...this.state.rentalPricing,
        //         minutePrice: "",
        //         minuteDeposit: "",
        //         hourPrice: "",
        //         hourDeposit: "",
        //         hourGracePeriodType: "",
        //         hourGracePeriod: "",
        //         hourAdditionalPrice: "",
        //         dayPrice: "",
        //         dayDeposit: "",
        //         dayGracePeriodType: "",
        //         dayGracePeriod: "",
        //         dayAdditionalPrice: "",
        //         weekPrice: "",
        //         weekDeposit: "",
        //         weekGracePeriodType: "",
        //         weekGracePeriod: "",
        //         weekAdditionalPrice: "",

        //     },
        // }));
    }

    onDeleteBtnPress = () => {
        const { pricingType } = this.state;
        if (pricingType === "durationBased" || pricingType === undefined) {
            this.props.actions.apiCall({
                urls: ["DELETECUSTOMTIMEDURATIONS"],
                method: "DELETE",
                data: {
                    productId: this.props.productId,
                    businessId: this.props.global.bussinessDetail.businessId,
                    businessLocationId: this.props.global.locationSelected.BusinessLocationId,
                    businessCategoryType: (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "BusinessCategoryType", "Rental")).globalCodeId,
                    actionPerformedBy: this.props.auth.userDetail.emailId,
                },
                onSuccess: (response) => {

                    this.setState({ isDeleteModalOpen: false })
                    this.setState({ customDurationDetails: [] })
                },
                showNotification: true,
            });
        }
        else if (pricingType === "ratePrice") {
            this.onHandleClearDuration()
        }
    }
    onHandleClearDuration = () => {
        this.setState(() => ({
            rentalPricing: {
                ...this.state.rentalPricing, minutePrice: null, minuteDeposit: null, hourPrice: null,
                hourDeposit: null, dayPrice: null, dayDeposit: null, weekPrice: null, weekDeposit: null
            },
        })
            , () => this.onHandleSubmitRentalDuration("e"), () => this.getProductDuration());
        this.setState(() => ({
            rentalPricing: {
                ...this.state.rentalPricing,
                minutePrice: "",
                minuteDeposit: "",
                hourPrice: "",
                hourDeposit: "",
                hourGracePeriodType: "",
                hourGracePeriod: "",
                hourAdditionalPrice: "",
                dayPrice: "",
                dayDeposit: "",
                dayGracePeriodType: "",
                dayGracePeriod: "",
                dayAdditionalPrice: "",
                weekPrice: "",
                weekDeposit: "",
                weekGracePeriodType: "",
                weekGracePeriod: "",
                weekAdditionalPrice: "",

            },
        }));
    }
    render() {
        const { buttonName, messageToShow, addItemPricing, isCommonDeleteUpdateModalOpen, isRentalAddBtnLoading,
            displayButton, timeFrameList, rentalPricing, applyAll, customDurationStatus, customTimeStatus, timeFrameForm,
            customDurationDetails, isDeleteModalOpen } = this.state;
        const fullState = this.state;
        const allFunction = this;
        const { api } = this.props;

        const isFlatFeePricing = addItemPricing.rentalPricingType === (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "RentalPricingType", "Flat Fee Rental Price",)).globalCodeId ? true : false // 55
        const isItemSavedPricing = addItemPricing.rentalPricingType === (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "RentalPricingType", "Item with Saved Rate Price",)).globalCodeId ? true : false  // 54
        const isDurationBasedPricing = addItemPricing.rentalPricingType === (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "RentalPricingType", "Duration based slot price",)).globalCodeId ? true : false  // 54

        return (
            <Grid.Column width={16}>
                <div className="cmn-shad" >
                    <Grid>
                        <Grid.Column width={16}>
                            <Header as="h4">Rental Pricing</Header>
                        </Grid.Column>
                        {/* Duration based slot price */}
                        {this.props.productId !== undefined &&
                            <>
                                <Grid.Column computer={16} tablet={16}>
                                    <Form.Radio label="Duration based slot price" className="custom-radio-btn" data="durationBased" name="radioGroup"
                                        value={(commonFunctions.getGlobalCodeDetails(this.props.global.codes, "RentalPricingType", "Duration based slot price",)).globalCodeId}
                                        checked={addItemPricing.rentalPricingType === (commonFunctions.getGlobalCodeDetails(this.props.global.codes,
                                            "RentalPricingType", "Duration based slot price",)).globalCodeId ? true : false}
                                        onChange={this.onHandlePriceType}
                                    />
                                </Grid.Column>
                                <Grid.Column width={16}>
                                    <Header as="h4">Select Duration</Header>
                                </Grid.Column>
                                <Grid.Column width={16}>
                                    {customDurationDetails && customDurationDetails.length > 0 && customDurationDetails.map((singleObjDuration, index) => {
                                        return (
                                            <div className="timesButton">
                                                <Button
                                                    name="customTime"
                                                    data="customTime"            
                                                    type="select"
                                                    basic
                                                    active={singleObjDuration.isSelected ? true : false}
                                                    onClick={() => this.onSelectCustomDurationSlot(index)}
                                                    className="select-btn mbt 5">
                                                    {singleObjDuration.customDurationTitle}
                                                </Button>
                                                <Icon name="close" onClick={() => this.onCustomDurationDelete(singleObjDuration)} />
                                            </div>
                                        )
                                    })}
                                    <Button className="orange-button mbt" icon
                                        disabled={isFlatFeePricing ? true : isItemSavedPricing ? true : false}
                                        onClick={this.customDurationModal}>
                                        <Icon name="plus"/> Custom Duration</Button>
                                </Grid.Column>
                                {customDurationDetails && customDurationDetails.length > 0 && customDurationDetails.map((singleSlotObjDuration, parentIndex) => {
                                    return (
                                        <>
                                            <Grid.Column width={4}>
                                                <Header as="h5">{singleSlotObjDuration.title}</Header>
                                            </Grid.Column>
                                            <Grid.Column width={12}>
                                                {singleSlotObjDuration.slots && singleSlotObjDuration.slots.length > 0 && singleSlotObjDuration.slots.map((slotObj, slotIndex) => {
                                                    return (
                                                        <div className="timesButton">
                                                            <Button
                                                                name="customTime"
                                                                data="customTime"
                                                                type="select"
                                                                basic
                                                                active={slotObj.isSelected ? true : false}
                                                                onClick={() => this.onSelectCustomTimeSlot(parentIndex, slotIndex)}
                                                                className="select-btn mbt 5">
                                                                {slotObj.CustomTime}
                                                            </Button>
                                                            <Icon name="close" onClick={() => this.onCustomSlotDurationDelete(singleSlotObjDuration, slotObj, parentIndex, slotIndex)} />
                                                        </div>
                                                    )
                                                })}


                                                <Button className="orange-button mbt"
                                                    icon onClick={() => this.customTimeModal(singleSlotObjDuration, parentIndex)}>
                                                    <Icon name="plus" /> Custom Time</Button>

                                                {singleSlotObjDuration.slots && singleSlotObjDuration.slots.length == 0 &&
                                                    <div>
                                                        {this.slotValidator.message("slot", singleSlotObjDuration.slotsAdded, "required")
                                                        }
                                                    </div>
                                                }
                                            </Grid.Column>
                                        </>
                                    )
                                })}

                                <Grid.Column width={16} textAlign="right">
                                    <Button className="blue-btn"
                                        disabled={isFlatFeePricing ? true : isItemSavedPricing ? true : customDurationDetails && customDurationDetails.length === 0 ? true : false}
                                        onClick={() => this.onOpenDeleteUpdateModal("durationBased")}>Clear</Button>
                                    <Button className="orange-btn"
                                        disabled={isFlatFeePricing ? true : isItemSavedPricing ? true : customDurationDetails && customDurationDetails.length === 0 ? true : false}
                                        onClick={this.onAddRentalCustomTimeDurations}>Save</Button>
                                </Grid.Column>

                                {customDurationStatus &&
                                    <SlotBasedDuration
                                        productId={this.props.productId}
                                        openModal={customDurationStatus}
                                        closeModal={this.onCloseSlotDuration}
                                        businessCategoryType={(commonFunctions.getGlobalCodeDetails(this.props.global.codes, "BusinessCategoryType", "Rental")).globalCodeId}
                                    />
                                }
                                {customTimeStatus &&
                                    <CustomTimeFrameModal
                                        openModal={customTimeStatus}
                                        closeModal={() => this.onCloseCustomTimeModal()}
                                        onChange={this.onSlotHandleChange}
                                        customTimeFirst={timeFrameForm.customTimeFirst}
                                        customTimeTwo={timeFrameForm.customTimeTwo}
                                        customTimeThree={timeFrameForm.customTimeThree}
                                        validator={this.validator}
                                        postSpecificTime={(e) => this.onClickSaveDurationSlot(e)}
                                    />
                                }
                            </>}
                        {/* Duration based slot price End */}

                        <Grid.Column width={16}>
                            <Form.Radio label="Rate Price" className="custom-radio-btn" data="RatePrice" name="radioGroup"
                                value={(commonFunctions.getGlobalCodeDetails(this.props.global.codes, "RentalPricingType", "Item with Saved Rate Price",)).globalCodeId}
                                checked={addItemPricing.rentalPricingType === (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "RentalPricingType", "Item with Saved Rate Price",)).globalCodeId ? true : false} onChange={this.props.productId !== undefined ? this.onHandlePriceType : this.onHandlePriceTypeMoreSetting} />
                        </Grid.Column>
                        {this.props.productId !== undefined &&
                            <>
                                <Grid.Column width={16}>
                                    <Grid columns="equal">
                                        <Grid.Column >
                                            <div className="pricing-deposit">
                                                <p>Hour</p>
                                                <Form>
                                                    <Input fluid placeholder="Price" onChange={this.onHandleRentalDuration}
                                                        name="hour" value={rentalPricing.hourPrice} data="hourPrice"
                                                        disabled={isFlatFeePricing ? true : isDurationBasedPricing ?
                                                            true : false} label={{ basic: true, content: '$' }} labelPosition='left' />
                                                    <Grid><Divider hidden></Divider></Grid>
                                                    <Input fluid placeholder="Deposit" onChange={this.onHandleRentalDuration} name="hour"
                                                        value={rentalPricing.hourDeposit} data="hourDeposit"
                                                        disabled={isFlatFeePricing ? true : isDurationBasedPricing ?
                                                            true : false} label={{ basic: true, content: '$' }} labelPosition='left' />
                                                    <Grid>
                                                        <GridColumn computer={11} tablet={8} style={{ paddingRight: "1px" }}>
                                                            <Grid><Divider hidden></Divider></Grid>
                                                            <Input className="additional-time" fluid placeholder="Grace period"
                                                                onChange={this.onHandleGracePeroid} name="7days"
                                                                value={rentalPricing.hourGracePeriod} data="hourGracePeriod"
                                                                disabled={isFlatFeePricing ? true : isDurationBasedPricing ? true : false}
                                                                label={{ basic: true, content: <Icon style={{ margin: 0 }} loading name='clock outline' /> }}
                                                                labelPosition='left' />
                                                            <Grid><Divider hidden></Divider></Grid>


                                                        </GridColumn>
                                                        <GridColumn computer={5} tablet={8} style={{ padding: "0", }} >
                                                            <div className="grace-period" >
                                                                <Form.Select placeholder="Min" onChange={this.onHandleRentalSelection}
                                                                    value={rentalPricing.hourGracePeriodType} data="hourGracePeriodType"
                                                                    disabled={isFlatFeePricing ? true : isDurationBasedPricing ? true : false}
                                                                    fluid type="select-int" options={optionHour} className="custom-select bordered-input"
                                                                />
                                                            </div>
                                                        </GridColumn>
                                                    </Grid>

                                                    <Input fluid placeholder="Additional amount" onChange={this.onHandleRentalDuration} name="7days"
                                                        value={rentalPricing.hourAdditionalPrice} data="hourAdditionalPrice"
                                                        disabled={isFlatFeePricing ? true : isDurationBasedPricing ? true : false}
                                                        label={{ basic: true, content: '$' }} labelPosition='left' />


                                                </Form>
                                                <Grid><Divider hidden></Divider></Grid>
                                                <div>
                                                    <Form.Checkbox
                                                        className="custome-checkbox"
                                                        label="Apply to all" name="hour" data="applyAll" checked={applyAll} value={applyAll} onChange={this.onHandleApplyToAll} disabled={isFlatFeePricing ? true : isDurationBasedPricing ?
                                                            true : false}
                                                    />
                                                </div>
                                            </div>
                                        </Grid.Column>
                                        <Grid.Column >
                                            <div className="pricing-deposit" >
                                                <p>Day</p>
                                                <Form disabled={isFlatFeePricing ? true : isDurationBasedPricing ?
                                                    true : false}>
                                                    <Input fluid placeholder="Price" onChange={this.onHandleRentalDuration} name="day" value={rentalPricing.dayPrice} data="dayPrice" disabled={isFlatFeePricing ? true : isDurationBasedPricing ?
                                                        true : false} label={{ basic: true, content: '$' }} labelPosition='left' />
                                                    <Grid><Divider hidden></Divider></Grid>
                                                    <Input fluid placeholder="Deposit" onChange={this.onHandleRentalDuration} name="day" value={rentalPricing.dayDeposit} data="dayDeposit" disabled={isFlatFeePricing ? true : isDurationBasedPricing ?
                                                        true : false} label={{ basic: true, content: '$' }} labelPosition='left' />
                                                    <Grid>
                                                        <GridColumn computer={11} tablet={8} style={{ paddingRight: "1px" }}>
                                                            <Grid><Divider hidden></Divider></Grid>
                                                            <Input className="additional-time" fluid placeholder="Grace period" onChange={this.onHandleGracePeroid} name="7days"
                                                                value={rentalPricing.dayGracePeriod} data="dayGracePeriod"
                                                                disabled={isFlatFeePricing ? true : isDurationBasedPricing ? true : false}
                                                                label={{ basic: true, content: <Icon style={{ margin: 0 }} loading name='clock outline' /> }} labelPosition='left' />
                                                        </GridColumn>
                                                        <GridColumn computer={5} tablet={8} style={{ padding: "0", }} >
                                                            <div className="grace-period" >
                                                                <Form.Select placeholder="Hour"
                                                                    value={rentalPricing.dayGracePeriodType} data="dayGracePeriodType"
                                                                    disabled={isFlatFeePricing ? true : isDurationBasedPricing ? true : false}
                                                                    fluid type="select-int" options={optionDay} className="custom-select bordered-input"
                                                                    onChange={this.onHandleRentalSelection}
                                                                />
                                                            </div>
                                                        </GridColumn>
                                                    </Grid>
                                                    <Input fluid placeholder="Additional amount" onChange={this.onHandleRentalDuration} name="7days"
                                                        value={rentalPricing.dayAdditionalPrice} data="dayAdditionalPrice"
                                                        disabled={isFlatFeePricing ? true : isDurationBasedPricing ? true : false}
                                                        label={{ basic: true, content: '$' }} labelPosition='left' />                                                </Form>
                                                <Grid><Divider hidden></Divider></Grid>
                                                <div>
                                                    <Form.Checkbox
                                                        className="custome-checkbox"
                                                        label="Apply to all" name="day" data="applyAll" checked={applyAll} value={applyAll}
                                                        onChange={this.onHandleApplyToAll} disabled={isFlatFeePricing ? true : isDurationBasedPricing ?
                                                            true : false} />
                                                </div>
                                            </div>
                                        </Grid.Column>
                                        <Grid.Column >
                                            <div className="pricing-deposit">
                                                <p>7 Days</p>
                                                <Form>
                                                    <Input fluid placeholder="Price" onChange={this.onHandleRentalDuration} name="7days" value={rentalPricing.weekPrice} data="weekPrice" disabled={isFlatFeePricing ? true : isDurationBasedPricing ?
                                                        true : false} label={{ basic: true, content: '$' }} labelPosition='left' />
                                                    <Grid><Divider hidden></Divider></Grid>
                                                    <Input fluid placeholder="Deposit" onChange={this.onHandleRentalDuration} name="7days"
                                                        value={rentalPricing.weekDeposit} data="weekDeposit" disabled={isFlatFeePricing ? true : isDurationBasedPricing ?
                                                            true : false} label={{ basic: true, content: '$' }} labelPosition='left' />
                                                    <Grid>
                                                        <GridColumn computer={11} tablet={8} style={{ paddingRight: "1px" }}>
                                                            <Grid><Divider hidden></Divider></Grid>
                                                            <Input className="additional-time" fluid placeholder="Grace period" onChange={this.onHandleGracePeroid} name="7days"
                                                                value={rentalPricing.weekGracePeriod} data="weekGracePeriod"
                                                                disabled={isFlatFeePricing ? true : isDurationBasedPricing ? true : false}
                                                                label={{ basic: true, content: <Icon style={{ margin: 0 }} loading name='clock outline' /> }} labelPosition='left' />
                                                        </GridColumn>
                                                        <GridColumn computer={5} tablet={8} style={{ padding: "0", }} >
                                                            <div className="grace-period" >
                                                                <Form.Select placeholder="Day" onChange={this.onHandleRentalSelection}
                                                                    disabled={isFlatFeePricing ? true : isDurationBasedPricing ? true : false}
                                                                    value={rentalPricing.weekGracePeriodType} data="weekGracePeriodType"
                                                                    fluid type="select-int" options={option} className="custom-select bordered-input"
                                                                />
                                                            </div>
                                                        </GridColumn>
                                                    </Grid>
                                                    <Input fluid placeholder="Additional amount" onChange={this.onHandleRentalDuration} name="7days"
                                                        value={rentalPricing.weekAdditionalPrice} data="weekAdditionalPrice"
                                                        disabled={isFlatFeePricing ? true : isDurationBasedPricing ? true : false}
                                                        label={{ basic: true, content: '$' }} labelPosition='left' />


                                                </Form>
                                                <Grid><Divider hidden></Divider></Grid>
                                                <div>
                                                    <Form.Checkbox
                                                        className="custome-checkbox"
                                                        label="Apply to all" name="7days" checked={applyAll} data="applyAll" value={applyAll} onChange={this.onHandleApplyToAll} disabled={isFlatFeePricing ? true : isDurationBasedPricing ?
                                                            true : false}
                                                    />
                                                </div>
                                            </div>
                                        </Grid.Column>
                                    </Grid>
                                </Grid.Column>
                                <Grid.Column width={16} textAlign="right">
                                    <Button className="blue-btn" disabled={isFlatFeePricing ? true : isDurationBasedPricing ? true : false}
                                        onClick={() => this.onOpenDeleteUpdateModal("ratePrice")}
                                    // onClick={this.onHandleClearDuration}
                                    >Clear</Button>

                                    <Button className="orange-btn" disabled={isFlatFeePricing ? true : isDurationBasedPricing ?
                                        true : false} onClick={(e) => this.onHandleSubmitRentalDuration(e)} >Save
                                    </Button>
                                </Grid.Column>
                            </>}
                    </Grid>
                    <Grid>


                        {/* special rate price  */}
                        <Grid.Column computer={3} tablet={4}>
                            <Form.Dropdown search selection allowAdditions placeholder='Title'
                                value={addItemPricing.productId === undefined ? addItemPricing.rentalPricingId :
                                    addItemPricing.productId === null ? addItemPricing.rentalPricingId :
                                        addItemPricing.generalRentalPricingId === null ? addItemPricing.rentalPricingId :
                                            addItemPricing.generalRentalPricingId === "" ? addItemPricing.rentalPricingId :
                                                addItemPricing.generalRentalPricingId}

                                options={this.state.rentalPriceDropdownList} disabled={isFlatFeePricing ? true : isDurationBasedPricing ?
                                    true : false}
                                data="rentalPricingTitle" name="savedRate"
                                error={this.savedRateValidator.message("title", addItemPricing.rentalPricingId ? addItemPricing.rentalPricingId : addItemPricing.rentalPricingTitle, "required")}
                                onAddItem={this.handleAddition} onChange={this.onHandleChangeTitle} className="tagger" fluid />
                        </Grid.Column>
                        <Grid.Column tablet={2} only="tablet"></Grid.Column>
                        <Grid.Column computer={4} tablet={10}>
                            <div className="durationwithnumber">
                                <Form.Input placeholder="Qty" value={isItemSavedPricing ? addItemPricing.quantity : ""} data="quantity" fluid
                                    disabled={isFlatFeePricing ? true : isDurationBasedPricing ?
                                        true : false} onChange={this.onHandleItemPricing}
                                    error={this.savedRateValidator.message("quantity", isItemSavedPricing && addItemPricing.quantity, "required|min:0,num")} className="qty" />
                                <GlobalCodeSelect
                                    placeholder="Duration" data="duration" onChange={this.onHandleItemPricing}
                                    categoryType="Duration"
                                    value={isItemSavedPricing ? addItemPricing.duration : ""}
                                    className="custom-select" disabled={isFlatFeePricing ? true : isDurationBasedPricing ?
                                        true : false}
                                    error={this.savedRateValidator.message("duration", isItemSavedPricing && addItemPricing.duration, "required|min:0,num")} />
                            </div>
                        </Grid.Column>

                        <Grid.Column computer={3} tablet={6}>
                            <Form.Select
                                options={timeFrameList} search data="timeFrameId"
                                // defaultValue={12}
                                value={addItemPricing.timeFrameId ? addItemPricing.timeFrameId : 12} type="select" fluid
                                placeholder="Season" disabled={isFlatFeePricing ? true : isDurationBasedPricing ?
                                    true : false} onChange={this.onHandleItemPricing}
                            // error={this.savedRateValidator.message("Season", isItemSavedPricing && addItemPricing.timeFrameId, "required")} 
                            />
                        </Grid.Column>
                        <Grid.Column computer={3} tablet={5}>
                            <Input placeholder=" Price" data="itemPrice" value={isItemSavedPricing ? addItemPricing.itemPrice : ""} fluid disabled={isFlatFeePricing ? true : isDurationBasedPricing ?
                                true : false} onChange={this.onHandleItemPricing}
                                label={{ basic: true, content: '$' }} labelPosition='left' />{this.savedRateValidator.message("price", isItemSavedPricing && addItemPricing.itemPrice, "required")}
                        </Grid.Column>
                        <Grid.Column computer={3} tablet={5}>
                            <Input placeholder="Deposit" data="deposit" value={isItemSavedPricing ? addItemPricing.deposit : null} fluid disabled={isFlatFeePricing ? true : isDurationBasedPricing ?
                                true : false} onChange={this.onHandleItemPricing}
                                label={{ basic: true, content: '$' }} labelPosition='left' />
                        </Grid.Column>

                        {this.props.productId === undefined ?
                            null
                            :
                            <Grid.Column width={8}   >
                                <Checkbox label="Show special price on widget" data="showSpecialPriceInWidget" name="showSpecialPriceInWidget" checked={addItemPricing.showSpecialPriceInWidget}
                                    onChange={this.onCheckBoxHandleChange}
                                    className="custom-toggle" />
                            </Grid.Column>
                        }

                        <Grid.Column mobile={8} tablet={16} computer={16} textAlign="right">
                            <Button className="blue-btn" disabled={isFlatFeePricing ? true : isDurationBasedPricing ?
                                true : false}
                                onClick={this.onhandleClearPriceForm}>Clear</Button>
                            <Button className="orange-btn" loading={isRentalAddBtnLoading} disabled={isFlatFeePricing ? true : isDurationBasedPricing ?
                                true : false}
                                onClick={() => this.onHandleSubmitButtonAddRental("savedRateValidator")}>
                                {displayButton}
                            </Button>
                        </Grid.Column>
                        {/*Rate price End*/}
                        {/*Flat fee*/}
                        <Grid.Column tablet={4} only="tablet"></Grid.Column>
                        <Grid.Column computer={16} tablet={16}>
                            <Form.Radio label="Flat Fee" className="custom-radio-btn" data="FlatFee" name="radioGroup"
                                value={(commonFunctions.getGlobalCodeDetails(this.props.global.codes, "RentalPricingType", "Flat Fee Rental Price",)).globalCodeId}
                                checked={addItemPricing.rentalPricingType === (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "RentalPricingType", "Flat Fee Rental Price",)).globalCodeId ? true : false} onChange={this.props.productId !== undefined ? this.onHandlePriceType : this.onHandlePriceTypeMoreSetting} />
                        </Grid.Column>
                        <Grid.Column computer={4} tablet={4}>
                            <Form.Dropdown search selection allowAdditions placeholder='Title' options={this.state.flatFeeDropdownList} value={addItemPricing.productId === undefined ? addItemPricing.rentalPricingId :
                                addItemPricing.productId === null ? addItemPricing.rentalPricingId :
                                    addItemPricing.generalRentalPricingId === null ? addItemPricing.rentalPricingId :
                                        addItemPricing.generalRentalPricingId === "" ? addItemPricing.rentalPricingId :
                                            addItemPricing.generalRentalPricingId}
                                disabled={isItemSavedPricing ? true : isDurationBasedPricing ? true : false}
                                data="rentalPricingTitle" name="flatFee"
                                error={this.flatFeeValidator.message("title", addItemPricing.rentalPricingId ? addItemPricing.rentalPricingId : addItemPricing.rentalPricingTitle, "required")}
                                onAddItem={this.handleAddition} onChange={this.onHandleChangeTitle}
                                className="tagger" fluid />
                        </Grid.Column>

                        <Grid.Column computer={4} tablet={4}>
                            <Form.Select options={timeFrameList} search data="timeFrameId"
                                value={addItemPricing.timeFrameId ? addItemPricing.timeFrameId : 12}
                                type="select" fluid placeholder="Season" disabled={isItemSavedPricing ? true : isDurationBasedPricing ? true : false} onChange={this.onHandleItemPricing}
                            />
                        </Grid.Column>
                        <Grid.Column computer={4} tablet={4}>
                            <Input placeholder="Price" data="itemPrice" value={isFlatFeePricing ? addItemPricing.itemPrice : ""} fluid disabled={isItemSavedPricing ? true : isDurationBasedPricing ? true : false} onChange={this.onHandleItemPricing} label={{ basic: true, content: '$' }} labelPosition='left' />
                            {this.flatFeeValidator.message("price", isFlatFeePricing ? addItemPricing.itemPrice : "", "required")}
                        </Grid.Column>
                        <Grid.Column computer={4} tablet={4}>
                            <Input placeholder="Deposit" data="deposit" value={isFlatFeePricing ? addItemPricing.deposit : null} fluid disabled={isItemSavedPricing ? true : isDurationBasedPricing ? true : false} onChange={this.onHandleItemPricing} label={{ basic: true, content: '$' }} labelPosition='left' />
                        </Grid.Column>
                    </Grid>

                    <Grid reversed='computer tablet'>
                        <Grid.Column mobile={8} tablet={8} computer={8} textAlign="right">
                            <Button className="blue-btn" disabled={isItemSavedPricing ? true : isDurationBasedPricing ? true : false}
                                onClick={this.onhandleClearPriceForm}>Clear</Button>
                            <Button className="orange-btn" loading={isRentalAddBtnLoading} disabled={isItemSavedPricing ? true : isDurationBasedPricing ? true : false}
                                onClick={() => this.onHandleSubmitButtonAddRental("flatFeeValidator")}>
                                {displayButton}
                            </Button>
                        </Grid.Column>
                    </Grid>
                    {/*flat fee end*/}
                    <Grid.Column>
                        <Divider hidden>

                        </Divider>
                    </Grid.Column>
                    <RentalGrid ref={this.child} fullState={fullState} allFunction={allFunction} productId={this.props.productId} />
                    <CommonDeleteUpdateModal
                        onConfirm={() => commonFunctions.onConfirmFunction(allFunction, "rentalPricingId", "gridDetail",
                            "DELETERENTALPRODUCTPRICE", "UPDATETAGTOGGLE", { "ProductId": this.props.productId })}
                        confirmationButtonText={buttonName}
                        header={messageToShow}
                        isModalOpen={isCommonDeleteUpdateModalOpen}
                        subHeader={"Deleted records can not be reverted back."}
                        onClose={() => commonFunctions.onCloseDeleteUpdateModal(allFunction, "rentalPricingId")}
                        cancelButtonText={'Cancel'} />

                    {isDeleteModalOpen && (
                        <CommonDeleteUpdateModal
                            isModalOpen={isDeleteModalOpen}
                            onClose={() => this.setState({ isDeleteModalOpen: false })}
                            header="Are you sure you want to delete this pricing ?"
                            confirmationButtonText={"Yes"}
                            cancelButtonText={"No"}
                            onConfirm={this.onDeleteBtnPress}
                        />
                    )}

                </div>
            </Grid.Column >
        );
    }
}
const mapStateToProps = (state) => {
    return {
        api: state.api,
        global: state.global,
        auth: state.auth,
        warnUserState: state.warnUserState,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            apiCall: bindActionCreators(actions.apiCall, dispatch),
            storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
            showNotification: bindActionCreators(Notifications.show, dispatch),
            storeWarnUser: bindActionCreators(actions.storeWarnUser, dispatch)
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RentalForm);
