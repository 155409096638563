import React, { Component, Fragment } from "react";
import {
  connect,
  bindActionCreators,
  actions,
  Notifications,
} from "../../../../../../functional/global-import";
import {
  addSelectedDateRange,
  addSelectedItems,
} from "../../../../../../../store/actions/order.actions";
import { LoaderComponent } from "../../../../../../components";
import SeasonList from "./season-list";
import SeasonPassCustomFields from "./season-pass-custom-fields";
import AddOnAllBusinessType from "../../common/addOn-all-business-type";
import CancelationCommonComponent from "../../common/cancelation-common";
import InventoryBreadcrumb from "../../../../../../../pages/inventory/breadcrumb";
// import RentalItems from "../rental/rental-items";
// import moment from "moment";

class SeasonPass extends Component {
  state = {
    hideRentalDate: false,
    dateTime: { startDate: "", endDate: "" },
    hideAddCategory: true,
    productCategoryDetail: "",
    showChooseDates: false,
    showAddon: false,
    //  new
    showSeasonPassList: this.props.customQuestionEdit.customQuestionEdit
      ? false
      : true,
    editShowCancellationComponent:
      this.props.customQuestionEdit.customQuestionEdit,
    showSeasonPassCustomFields: false,
    addOnDetails: [],
    seasonPassDescription: "",
    isToShowAddon: false,
    briefDetails: [],
    hasToShowCancellationComponent: false,
    productCustomQuestion: this.props.customQuestionEdit.customQuestionEdit
      ? this.props.customQuestionEdit.productCustomQuestion
      : null,
    //
    showCancellationComponent: false,
    selectedItemsDetails: null,
    customfields: null,
  };

  getProductDetail = () => {
    // this.setState({ loading: true })
    this.props.actions.apiCall({
      urls: ["GETORDERSEASONPASSDETAIL"],
      method: "GET",
      data: {
        seasonPassId: this.props.global.inventoryDetail.productId,
        inWebsiteWidget: false,
      },
      onSuccess: (response) => {
        var addonDetails = null;
        if (response[0].addOn) {
          addonDetails =
            response[0].addOn &&
            JSON.parse(response[0].addOn).map(function (el) {
              var addOnObj = Object.assign({}, el);
              addOnObj.qunatity = 0;
              addOnObj.minvalue = el.minAddOns;
              return addOnObj;
            });
        }
        const description = response[0].info;
        const briefDetails = JSON.parse(response[0].briefDetails)[0];

        const productCustomQuestion =
          briefDetails.ProductCustomQuestion &&
          JSON.parse(briefDetails.ProductCustomQuestion);

        const questiondetails =
          productCustomQuestion &&
          productCustomQuestion.map((customObj) => {
            if (customObj.associateAddOn) {
              customObj.associateAddOn = JSON.parse(customObj.associateAddOn);
            }
            return { ...customObj };
          });

        const isToShowAddon =
          response[0].addOn && JSON.parse(response[0].addOn).length > 0
            ? true
            : false;
        const hasToShowCancellationComponent =
          briefDetails.CancellationPolicy !== null ||
          briefDetails.ProductCustomQuestion !== null
            ? true
            : false;

        this.setState({
          seasonPassDescription: description,
          isToShowAddon: isToShowAddon,
          addOnDetails: addonDetails,
          briefDetails: briefDetails,
          hasToShowCancellationComponent: hasToShowCancellationComponent,
          productCustomQuestion: questiondetails,
        });
        return response;
      },
    });
  };

  componentDidMount() {
    !this.props.customQuestionEdit.customQuestionEdit &&
      this.getProductDetail();
  }
  onAddOrderDetailId = (orderDetailId, isAddMoreBtnClicked) => {
    const { orderState } = this.props;
    const { isToShowAddon, hasToShowCancellationComponent } = this.state;
    this.setState({ orderDetailId: orderDetailId });

    if (isAddMoreBtnClicked) {
      this.props.onAddmoreBtnClick();
    } else if (isToShowAddon) {
      this.setState({
        showAddon: isToShowAddon,
        showSeasonPassCustomFields: false,
      });
    } else if (hasToShowCancellationComponent) {
      this.setState({
        showSeasonPassCustomFields: false,
        showCancellationComponent: hasToShowCancellationComponent,
      });
    } else {
      this.props.next(2);
    }
    this.props.actions.getOrderItems(orderState.currentOrder.orderId);
  };
  onCancelContinueClick = (isAddMoreClicked) => {
    if (isAddMoreClicked) {
      // this.props.actions.getOrderItems(this.props.orderState.currentOrder.orderId);
      this.props.onAddmoreBtnClick();
    } else {
      this.props.next(2);
    }
    this.props.actions.getOrderItems(
      this.props.orderState.currentOrder.orderId
    );
  };
  onAddOnContinueClick = (isAddMoreCLicked) => {
    if (isAddMoreCLicked) {
      this.props.onAddmoreBtnClick();
      // this.props.actions.getOrderItems(this.props.orderState.currentOrder.orderId);
    } else {
      if (this.state.hasToShowCancellationComponent) {
        this.setState({
          showAddon: false,
          showCancellationComponent: this.state.hasToShowCancellationComponent,
        });
      } else {
        this.props.next(2);
      }
    }
    // this.props.actions.getOrderItems(this.props.orderState.currentOrder.orderId);
  };
  onContinueClick = (selectedProducts, productsList) => {
    this.setState({
      selectedProducts,
      productsList,
      showSeasonPassList: false,
      editShowCancellationComponent: false,
      showCancellationComponent: false,
      showSeasonPassCustomFields: true,
    });
  };
  onBackToSeasonPassList = () => {
    this.setState({
      showSeasonPassList: true,
      showSeasonPassCustomFields: false,
    });
  };
  render() {
    const {
      showSeasonPassList,
      showSeasonPassCustomFields,
      productsList,
      selectedProducts,
      showAddon,
      showCancellationComponent,
      orderDetailId,
      addOnDetails,
      hasToShowCancellationComponent,
      productCustomQuestion,
      briefDetails,
      isToShowAddon,
      editShowCancellationComponent,
    } = this.state;
    const {
      api,
      seasonPassEdit,
      productCategoryDetail,
      global,
      categoryViewBack,
      backBtnInventoryView,
    } = this.props;

    return (
      <Fragment>
        {api.isApiLoading && <LoaderComponent />}
        {!seasonPassEdit.seasonPassEdit &&
          (showSeasonPassList || showSeasonPassCustomFields) && (
            <InventoryBreadcrumb newOrderBack={this?.props?.categoryViewBack} />
          )}
        {showSeasonPassList && (
          <SeasonList
            productCategoryDetail={productCategoryDetail}
            onContinueClick={this.onContinueClick}
            categoryViewBack={categoryViewBack}
            backBtnInventoryView={backBtnInventoryView}
            seasonPassEdit={seasonPassEdit}
            // seasonPassEdit={{ seasonPassEdit: false }}
          />
        )}
        {showSeasonPassCustomFields && (
          <SeasonPassCustomFields
            productCategoryDetail={productCategoryDetail}
            selectedProducts={selectedProducts}
            productsList={productsList}
            // moveToNextStep={this.handleSubmit}
            moveToNextStep={this.onAddOrderDetailId}
            onBackToSeasonPassList={this.onBackToSeasonPassList}
          />
        )}
        {showAddon && (
          <AddOnAllBusinessType
            stateRef={this}
            orderDetailId={orderDetailId}
            onContinueClick={this.onAddOnContinueClick}
            addOnDetails={addOnDetails}
            hasToShowCancellationComponent={hasToShowCancellationComponent}
          />
        )}

        {editShowCancellationComponent && (
          <CancelationCommonComponent
            businessTypeName="Season Pass"
            allFunction={this}
            orderDetailId={this.props.customQuestionEdit.questionOrderDetailId}
            productId={this.props.customQuestionEdit.questionProductId}
            productCustomQuestion={productCustomQuestion}
            showAddon={false}
            briefDetails={false}
            editQuestion={true}
            onEditQuestionNext={() => this.props.next(3)}
          />
        )}

        {showCancellationComponent && (
          <CancelationCommonComponent
            businessTypeName="Season Pass"
            allFunction={this}
            orderDetailId={orderDetailId}
            productCustomQuestion={productCustomQuestion}
            productId={global.inventoryDetail.productId}
            briefDetails={briefDetails}
            showAddon={isToShowAddon}
            onNext={this.onCancelContinueClick}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
    newOrderDetail: state.newOrderDetail,
    orderState: state.orderState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      addSelectedDateRange: bindActionCreators(addSelectedDateRange, dispatch),
      addSelectedItems: bindActionCreators(addSelectedItems, dispatch),
      addSelectedDateRange: bindActionCreators(addSelectedDateRange, dispatch),
      getOrderItems: bindActionCreators(actions.getOrderItems, dispatch),

      showNotification: bindActionCreators(Notifications.show, dispatch),

      addMultipleFlatFeeAndOtherDetails: bindActionCreators(
        actions.addMultipleFlatFeeAndOtherDetails,
        dispatch
      ),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SeasonPass);
