import * as env from "./env.config";

// Read api url from env file
const API_URL = env.API_URL;

// API end points
const API_ENDPOINTS = {
  LOGIN: "/Auth/Login",
  LOGOUT: "/Auth/Logout",
  CUSTOMER: "/Customer",
  PASSCODE: "/Auth/Passcode",
  GLOBALCODE: "/GlobalCode",
  ADDBUSINESSLOCATION: "/BusinessLocation/AddBusinessLocation",
  GETBUSINESSLOCATION: "/BusinessLocation/GetBusinessLocation",
  GETBREADCRUMBS: "/ProductCategory/GetBreadCrumbs",
  GETSINGLEPRODUCTTRAIT: "/Trait/GetSingleProductTrait",
  UPDATESINGLETRAIT: "/Trait/UpdateSingleTrait",
  UPDATECATEGORYFORPRODUCT: "/ProductCategory/UpdateCategoryForProduct",
  UPDATEROLEMASTERLOGIN: "/Role/UpdateRoleMasterLogin",
  UPDATEBUSINESSIMAGE: "/CropImage/UpdateBusinessTest",
  DELETECROPIMAGE: "/CropImage/DeleteCropImageTest",

  GETACTIVITYNAME: "/Order/GetActivityName",
  GETACTIVITYTIME: "/Order/GetActivityTime",
  GETACTIVITYMANIFEST: "/Order/GetActivityManifest",

  GETCUSTOMQUESTION: "/CustomQuestion/GetCustomQuestion",
  GETCALENDARITEMLIST: "/Dashboard/GetCalendarItemList",
  GETREPORTLIST: "/Report/GetReportList",
  GETREPORT: "/Report/GetReport",
  GETCUSTOMERREPORT: "/Report/GetCustomerReport",
  GETBUSINESSLOCATIONLIST: "/BusinessLocation/GetBusinessLocationList",

  DELETEBUSINESSLOCATION: "/BusinessLocation/DeleteBusinessLocation",
  UPDATEBUSINESSLOCATION: "/BusinessLocation/UpdateBusinessLocation",
  UPDATESHOPHOURS: "/BusinessLocation/UpdateShopHours",
  GETSHOPHOURS: "/BusinessLocation/GetShopHours",
  GETITEMDETAILHISTORY: "/Product/GetItemDetailHistory",
  GETBUSINESS: "/Business/GetBusiness",
  GETBUSINESSDETAIL: "/Business/GetBusinessDetail",
  UPDATEBUSINESS: "/Business/UpdateBusiness",
  ADDCATEGORY: "/ProductCategory/AddCategory",
  GETBUSINESSCATEGORYTYPE: "/ProductCategory/GetBusinessCategoryType",
  GETCATEGORYANDPRODUCT: "/ProductCategory/GetCategoryAndProduct",
  DELETECATEGORY: "/ProductCategory/DeleteCategory",
  UPDATECATEGORY: "/ProductCategory/UpdateCategory",
  GETCATEGORY: "/ProductCategory/GetCategory",
  GETPRODUCTINVENTORYLIST: "/Product/GetProductInventoryList",
  GETPRODUCTCATEGORYTAG: "/ProductCategory/GetProductCategoryTag",
  GETPRODUCTDETAILLIST: "/Product/GetProductDetailList",
  GETPRODUCTDETAILLISTFORINVENTORY: "/Product/GetProductDetailListForInventory",
  DELETEPRODUCTBYBUSINESSTYPE: "/ProductCategory/DeleteProductByBusinessType",
  DELETEPRODUCTDETAIL: "/Product/DeleteProductDetail",
  DELETESINGLEPRODUCTDETAIL: "/Product/DeleteSingleProductDetail",
  GETPRODUCTSTOCKITEM: "/Product/GetProductStockItem",
  CATEGORYTOGGLE: "/Toggle/CategoryToggle",
  UPDATEPRODUCTDETAILTOGGAL: "/Product/UpdateProductDetailToggle",
  UPDATEPRODUCTSTOCKITEM: "/Product/UpdateProductStockItem",
  DELETEPRODUCT: "/Product/DeleteProduct",
  UPDATEPRODUCTDETAILTOGGLE: "/Product/UpdateProductDetailToggle",
  GETBUNDLE: "/Bundle/GetBundle",
  UPDATEBUNDLE: "/Bundle/UpdateBundle",
  GETBUNDLEIMAGE: "/Bundle/GetBundleImage",
  GETACTIVITY: "/Activity/GetActivity",
  UPDATEACTIVITY: "/Activity/UpdateActivity",
  UPDATEBUSINESSBYTYPETOGGLE: "/ProductCategory/UpdateBusinessByTypeToggle",
  GETACTIVITYIMAGE: "/Activity/GetActivityImage",
  GETPRODUCT: "/Product/GetProduct",
  UPDATEPRODUCT: "/Product/UpdateProduct",
  ADDUSER: "/User/AddUser",
  GETUSER: "/User/GetUser",
  DELETEUSER: "/User/DeleteUser",
  GETROLES: "/Role/GetRole",
  UPDATEUSER: "/User/UpdateUser",
  GETDASHBOARDSTATS: "/Dashboard/GetDashBoardStats",
  GETDASHBOARDORDERSTATUS: "/Dashboard/GetDashBoardOrderStatus",
  GETDASHBOARDTOPTENITEMS: "/Dashboard/GetDashBoardTopTenItems",
  FORGOTPASSWORD: "/Auth/ForgotPassword",
  FORGOTPASSCODE: "/Auth/ForgotPasscode",
  GETDASHBOARDACTIVITYVIEW: "/Dashboard/GetDashBoardActivityView",
  GETDASHBOARDTOTALSALE: "/Dashboard/GetDashBoardTotalSale",
  UPDATEPASSCODEANDPASSWORD: "/Auth/UpdatePasscodeAndPassword",
  GETCUSTOMER: "/Customer/GetCustomer",
  UPDATETEAMTOGGLE: "/Toggle/UpdateTeamToggle",
  GETORDER: "/Order/GetOrder",
  ADDBREAKTYPE: "/BreakType/AddBreakType",
  GETBREAKTYPE: "/BreakType/GetBreakType",
  GETBREAKTIMEDATA: "/BreakTime/GetBreakTimeData",
  UPDATEBREAKTYPE: "/BreakType/UpdateBreakType",
  DELETEBREAKTYPE: "/BreakType/DeleteBreakType",
  UPDATECUSTOMERTOGGLE: "/Toggle/UpdateCustomerToggle",
  UPDATETOGGLELOCATION: "/Toggle/UpdateToggle",
  UPDATEPASSWORD: "/Auth/UpdatePassword",
  UPDATEPASSCODE: "/Auth/UpdatePasscode",
  ADDDOCUMENT: "/Document/AddDocument",
  GETDOCUMENT: "/Document/GetDocument",
  DELETEDOCUMENT: "/Document/DeleteDocument",
  ADDPRODUCT: "/Product/AddProduct",
  UPDATEDOCUMENT: "/Document/UpdateDocument",
  UPDATEDOCUMENTTOGGLE: "/Toggle/UpdateDocumentToggle",
  ADDTAGS: "/Tag/AddTags",
  GETTAGS: "/Tag/GetTags",
  DELETETAGS: "/Tag/DeleteTags",
  UPDATETAG: "/Tag/UpdateTag",
  UPDATETAGTOGGLE: "/Toggle/UpdateTagToggle",
  ADDREQUIREMENT: "/Requirement/AddRequirement",
  GETREQUIREMENTS: "/Requirement/GetRequirements",
  ADDBUNDLEIMAGE: "/Bundle/AddBundleImage",
  DELETEFEES: "/Fee/DeleteFees",
  UPDATEFEES: "/Fee/UpdateFees",
  UPDATEREQUIREMENTTOGGLE: "/Toggle/UpdateRequirementToggle",
  ADDFEES: "/Fee/AddFees",
  GETFEES: "/Fee/GetFees",
  UPDATEFEETOGGLE: "/Toggle/UpdateFeeToggle",
  ADDPAYMENTTYPE: "/PaymentType/AddPaymentType",
  GETPAYMENTTYPE: "/PaymentType/GetPaymentType",
  DELETEPAYMENTTYPE: "/PaymentType/DeletePaymentType",
  UPDATEPAYMENTTYPE: "/PaymentType/UpdatePaymentType",
  GETCLENDARVIEW: "/Dashboard/GetCalendarView",
  ADDCUSTOMER: "/Customer/AddCustomer",
  GETADDONS: "/AddOn/GetAddOns",
  ADDADDONS: "/AddOn/AddAddOns",
  UPDATECUSTOMER: "/Customer/UpdateCustomer",
  DELETECUSTOMER: "/Customer/DeleteCustomer",
  DELETEADDONS: "/AddOn/DeleteAddOns",
  UPDATEADDONS: "/AddOn/UpdateAddOns",
  GETCALENDARVIEW: "/Dashboard/GetCalendarView",
  UPLOADITEMIMAGE: "/Product/AddProductImage",
  ADDDISCOUNTANDCOUPONS: "/DiscountAndCoupon/AddDiscountAndCoupons",
  GETDISCOUNTANDCOUPONS: "/DiscountAndCoupon/GetDiscountAndCoupons",
  DELETEDISCOUNTANDCOUPONS: "/DiscountAndCoupon/DeleteDiscountAndCoupons",
  GETBLACKOUTDATE: "/BlackOutDate/GetBlackOutDate",
  DELETEBLACKOUTDATE: "/BlackOutDate/DeleteBlackOutDate",
  ADDBLACKOUTDATE: "/BlackOutDate/AddBlackOutDate",
  UPDATEDISCOUNTANDCOUPONS: "/DiscountAndCoupon/UpdateDiscountAndCoupons",
  DISCOUNTANDCOUPONSTOGGLE: "/Toggle/DiscountAndCouponsToggle",
  UPDATEBLACKOUTDATE: "/BlackOutDate/UpdateBlackOutDate",
  GETCUSTOMERNOTE: "/Customer/GetCustomerNote",
  ADDCUSTOMERNOTE: "/Customer/AddCustomerNote",
  GETTIMEFRAME: "/TimeFrame/GetTimeFrame",
  GETTIMEFRAMEDETAIL: "/TimeFrame/GetTimeFrameDetail",
  POSTRENTALPRICE: "/PricingAndTax/AddRentalPrice",
  POSTRENTALDURATION: "/Product/AddRentalProductDuration",
  GETRENTALPRODUCTPRICE: "/PricingAndTax/GetRentalPrice",
  DELETERENTALPRODUCTPRICE: "/PricingAndTax/DeleteRentalPrice",
  UPDATERENTALPRODUCTPRICE: "/PricingAndTax/UpdateRentalPrice",
  UPDATEBREAKTYPETOGGLE: "/Toggle/UpdateBreakTypeToggle",
  ADDBUNDLE: "/Bundle/AddBundle",
  UPDATEPAYMENTTYPETOGGLE: "/Toggle/UpdatePaymentTypeToggle",
  GETORDERSUMMARY: "/Order/GetOrderSummary",
  UPDATEROLEPERMISSION: "/Role/UpdateRoleAndPermissionToggle",
  DELETEROLEPERMISSION: "/Role/DeleteRoleAndPermission",
  UPDATEREQUIREMENTS: "/Requirement/UpdateRequirements",
  DELETEREQUIREMENTS: "/Requirement/DeleteRequirements",
  GETROLEPERMISSION: "/Role/GetRoleAndPermission",
  GETGENERALTAX: "/PricingAndTax/GetGeneralTax",
  ADDGENERALTAX: "/PricingAndTax/AddGeneralTax",
  DELETEGENERALTAX: "/PricingAndTax/DeleteGeneralTax",
  GETPRODUCTPRICEDURATION: "/Product/GetRentalProductDuration",
  UPDATEPRODUCTPRICEDURATION: "/Product/UpdateRentalProductDuration",
  UPDATEGENERALTAX: "/PricingAndTax/UpdateGeneralTax",
  GETACTIVITYPRICE: "/PricingAndTax/GetActivityPrice",
  ADDACTIVITYPRICE: "/PricingAndTax/AddActivityPrice",
  UPDATEACTIVITYPRICE: "/PricingAndTax/UpdateActivityPrice",

  DELETEACTIVITYPRICE: "/PricingAndTax/DeleteActivityPrice", //Activity Pricing Delete
  // ACTIVITYPRICINGTOGGLE: "/Toggle/ActivityPricingToggle",

  ACTIVITYPRICINGTOGGLE: "/PricingAndTax/UpdateActivityPricingToggle",
  // api/PricingAndTax %E2%80%8B/ UpdateActivityPricingToggle
  ADDACTIVITY: "/Activity/AddActivity",
  UPLOADACTIVITYIMAGE: "/Activity/AddActivityImage",
  UPDATEACTIVITYIMAGE: "/Activity/UpdateActivityImage",
  ADDACTIVITYPRICING: "/Activity/AddActivityPrice",
  GETACTIVITYPRICING: "/Activity/GetActivityPrice",
  UPDATEACTIVITYPRICING: "/Activity/UpdateActivityPrice",
  DELETEACTIVITYPRICING: "/Activity/DeleteActivityPrice",
  GETCUSTOMERSUMMARY: "/Customer/GetCustomerSummary",
  GETCATEGORYFORPRODUCT: "/Product/GetCategoryForProduct",
  UPDATEUSERHOMEPAGE: "/User/UpdateUserHomePage",
  DELETECUSTOMERNOTE: "/Customer/DeleteCustomerNote",
  GETROLE: "/Role/GetRole",
  ADDROLE: "/Role/AddRole",
  GETROLEANDPERMISSIONLIST: "/Role/GetRoleAndPermissionList",
  UPDATEROLEANDPERMISSIONLIST: "/Role/UpdateRoleAndPermission",
  UPDATEBLACKOUTDATETOGGLE: "/BlackOutDate/UpdateBlackOutDateToggle",
  UPDATEADDONTOGGLE: "/AddOn/UpdateAddOnToggle",
  ADDFAQ: "/FAQ/AddFAQ",
  GETFAQLIST: "/FAQ/GetFAQList",
  GETFAQ: "/FAQ/GetFAQ",
  UPDATEFAQ: "/FAQ/UpdateFAQ",
  DELETEFAQ: "/FAQ/DeleteFAQ",
  FAQTOGGLE: "/Toggle/FAQToggle",
  GETBUNDLEPRODUCT: "/Bundle/GetBundleProduct",
  GETCOUNTER: "/Order/GetCounter",
  GETCUSTOMTIME: "/Custom/GetCustomTime",
  UPDATEGENERALTAXTOGGLE: "/PricingAndTax/UpdateGeneralTaxToggle",
  UPDATEACTIVITYPRICINGTOGGLE: "/PricingAndTax/UpdateActivityPricingToggle",
  GETUSERMENU: "/UserMenu/GetUserMenu",
  UPDATEUSERMENU: "/UserMenu/UpdateUserMenu",
  ADDCOUNTER: "/Order/AddCounter",
  UPDATEBUNDLEIMAGE: "/Bundle/UpdateBundleImage",
  GETBUNDLEPRICING: "/Bundle/GetBundlePricing",
  BUSINESSTAGTYPE: "/GlobalCode/BusinesstagType",
  COUNTERBEGINNINGAMOUNT: "/Order/AddCounterBeginningAmount",
  ADDBUNDLEPRICING: "/Bundle/AddBundlePricing",
  COUNTERCLOSINGAMOUNT: "/Order/AddCounterClosingAmount",
  CREATEORDERID: "/Order/AddOrder",
  COMMONGRID: "/UserGridColumn/GetUserGridSystem",
  GETCUSTOMFIELD: "/Custom/GetCustomTField",
  POSTCOMMONTABLE: "/UserGridColumn/AddGridColumns",
  POSTCUSTOMFIELD: "/Custom/AddCustomField",
  UPDATECOUNTER: "/Order/UpdateCounter",
  DELETECOUNTER: "/Order/DeleteCounter",
  GETRETAIL: "/Retail/GetRetail",
  UPDATERETAIL: "/Retail/UpdateRetail",
  UPDATERETAILIMAGE: "/Retail/UpdateRetailImage",
  ADDRETAIL: "/Retail/AddRetail",
  ADDRETAILIMAGE: "/Retail/AddRetailImage",
  GETRETAILIMAGE: "/Retail/GetRetailImage",
  GETPRODUCTIMAGE: "/Product/GetProductImage",
  UPDATEPRODUCTIMAGE: "/Product/UpdateProductImage",
  ADDRETAILPRICE: "/Retail/AddRetailPrice",
  POSTCUSTOMERQUESTION: "/CustomQuestion/AddCustomQuestion",
  ADDPAYMENTS: "/Payment/AddPayments",
  DELETETIMEFRAME: "/TimeFrame/DeleteTimeFrame",
  UPDATETIMEFRAME: "/TimeFrame/UpdateTimeFrame",
  GETADDTIMEFRAME: "/TimeFrame/AddTimeFrame",
  GETACTIVITYTIMESLOTS: "/Order/GetActivityTimeSlots",

  POSTADDORDERACTIVITYDETAILS: "/Order/AddOrderActivityDetail",

  POSTADDORDERACTIVITYPRICINGCUSTOMERANSWER:
    "/Order/AddOrderActivityPricingCustomAnswers",

  ADDTIMEFRAME: "/TimeFrame/AddTimeFrame",
  ADDORDERPRODUCTDETAIL: "/Order/AddOrderProductDetail",
  GETRETAILPRICE: "/Retail/GetRetailPrice",
  UPDATERETAILPRICE: "/Retail/UpdateRetailPrice",
  UPDATEPRODUCTPriceDURATION: "/Product/UpdateRentalProductDuration",
  GETPRODUCTPriceDURATION: "/Product/GetRentalProductDuration",
  ADDCUSTOMTIME: "/Custom/AddCustomTime",
  GETUSERROLEWISE: "/User/GetUserRoleWise",
  ADDEMAILSUBSCRIPTION: "/EmailSubscription/AddEmailSubscription",
  GETRENTALPRODUCTPrice: "/PricingAndTax/GetRentalPrice",
  UPDATERENTALPRODUCTPrice: "/PricingAndTax/UpdateRentalPrice",
  GETORDERCUSTOMERSUMMARY: "/Customer/GetOrderCustomerSummary",
  GETCALENDARLISTVIEW: "/Dashboard/GetCalendarListView",
  GETCALENDARDETAILVIEW: "/Dashboard/GetCalendarDetailView",
  GETCALENDARITEM: "/Dashboard/GetCalendarItem",
  POSTADDNOTE: "/Note/AddNote",
  GETNOTE: "/Note/GetNote",
  DELETENOTE: "/Note/DeleteNote",
  DELETEORDERPRODUCT: "/Order/DeleteOrderProduct",
  ADDORDERRETAILSDETAILS: "/Order/AddOrderRetailDetail",
  GETTRAITS: "/Trait/GetTrait",
  // POSTADDORDERPRODUCTDOCUMENT: "/Order/AddOrderProductDocument",
  GETORDERCANDIDATENAMEPERDOCUMENTASYNC:
    "/Order/GetOrderCandidateNameperDocumentAsync",
  GETORDERTRANSACTIONDETAIL: "/Order/GetOrderTransactionDetail",
  ADDPRODUCTTRAITS: "/Product/AddProductTraits",

  POSTRENTALPrice: "/PricingAndTax/AddRentalPrice",
  POSTADDORDERBUNDLEDETAILS: "/Order/AddOrderBundleDetail",

  GETORDERPRODUCTDOCUMENT: "/Order/GetOrderProductDocument",
  GETORDERSIGNATUREOFCANDIDATEASYNC: "/Order/GetOrderSignatureOfCandidateAsync",

  GETORDERCHECKNINCHECKOUT: "/Order/GetOrderChecknInCheckOut",
  PUTUPDATEORDERCHECKINANDCHECKOUTTIME:
    "/Order/UpdateOrderCheckInAndCheckOutTime",
  PUTUPDATEORDERCHECKINTIME: "/Order/UpdateOrderCheckInTime",
  POSTADDORDERFEE: "/Order/AddOrderFee",
  POSTADDORDERCUSTOMERCHARGE: "/Order/AddOrderCustomCharge",
  POSTADDORDERADDON: "/Order/AddOrderAddOn",
  GETITEMDETAILSUMMARY: "/Product/GetItemDetailSummary",



  GETITEMSUMMARY: "/Product/GetItemSummary",
  POSTADDORDERDISCOUNTANDCOUPONS: "/Order/AddOrderDiscountAndCoupons",

  UPDATETIMEFRAMETOGGLE: "/TimeFrame/UpdateTimeFrameToggle",
  DELETEBUSINESSIMAGE: "/CropImage/DeleteCropImage",

  // Break time API
  ADDBREAKTIME: "/BreakTime/AddBreakTime",
  GETBREAKTIMESTART: "/BreakTime/GetBreakTime",
  UPDATEBREAKTIME: "/BreakTime/UpdateBreakTime",

  ADDTRAIT: "/Trait/AddTrait",
  GETGLOBALSEARCH: "/GlobalSearch/GetGlobalSearch",

  // new order api's
  GETORDERITEMS: "/Order/GetOrderItems",
  GETORDERRENTALPRICE: "/Order/GetOrderRentalPrice",
  GETORDERBUNDLEPRICE: "/Order/GetOrderBundlePrice",
  DELETEORDERCUSTOMCHARGES: "/Order/DeleteOrderCustomCharges",
  DELETEORDERDISCOUNTANDCOUPONS: "/Order/DeleteOrderDiscountAndCoupons",
  ORDERADDONDETAIL: "/Order/AddOrderAddOnDetail",
  GETORDERRETAILDETAIL: "/Order/GetOrderRetailDetail",

  UPDATEORDERRENTALDETAIL: "/Order/UpdateOrderProductDetail",
  RETAILUPDATEORDERDETAIL: "/Order/UpdateOrderRetailDetail",
  ACTIVITYUPDATEORDERDETAIL: "/Order/UpdateOrderActivityDetail",

  GETPRODUCTAVAILAVILITY:
    "/BusinessCategoryWebsiteWidget/GetProductAvailabiltyForWebsiteWidget",
  GETSLOTSAVAILABILITY:
    "/BusinessCategoryWebsiteWidget/GetTimeSlotsforProductWebsiteWidgetAsync",
  UPDATEDEPOSITSTATUSINORDER: "/Order/UpdateDepositStatusForOrder",

  ORDERSUMMARYPRINTANDEMAIL: "/Order/GetOrderSummaryPrintAndEmail",

  DELETEITEMDEPOSIT: "/Order/DeleteOrderItemDeposit",
  DELETEORDERDEPOSIT: "/Order/DeleteOrderDeposit",
  UPDATEORDERDEPOSIT: "/Order/UpdateOrderDeposit",
  UPDATEORDERPRODUCTPRICE: "/Order/UpdateOrderProductPrice",
  BUNDLEUPDATEORDERDETAIL: "/Order/UpdateOrderBundleDetail",
  TIPADDORDER: "/Order/AddOrderTip",
  REFRESHTOKEN: "/Auth/RefreshToken",
  GETDOCUMENTPDF: "/Order/GetOrderDocumentPDF",
  GETPRODUCTANALYTICS: "/Product/GetProductAnalytics",
  UPDATERETAILBULK: "/Retail/UpdateRetailBulk",
  ADDRETAILBULK: "/Retail/AddRetailBulk",
  GETRETAILBULKITEM: "/Retail/GetRetailBulkItem",
  DELETERETAILBULK: "/Retail/DeleteRetailBulkItem",

  //get api for dropdown in add rental and activity modal's
  GETDOUCUMENTBYBUSINESSTYPE: "/Product/GetDocumentByBusinessCategoryType",
  //Intigration screen
  POSTINTEGRATION: "/Integration/AddIntegration",
  GETINTEGRATIONSDATA: "/Integration/GetIntegrationData",
  GETPAYMENTMETHODDETAILFORINTEGRATION:
    "/Integration/GetPaymentMethodDetailsForIntegration",
  GETPAYMENTTYPEFORORDER: "/Integration/GetPaymentTypeForOrder",
  UPDATEINTEGRATION:
    "/BusinessCategoryWebsiteWidget/UpdateBusinessForWebsiteWidget",
  UPDATEBANNERIMAGE: "/Integration/UpdateIntegration",
  GETINTEGRATIONS: "/Integration/GetIntegrations",
  PAYMENTREFUND: "/Payment/PaymentRefund",
  GETPAYMENTREFUND: "/Payment/GetPaymentRefund",
  DELETECUSTOMTIME: "/Custom/DeleteCustomTime",
  GETCOUNTERNAME: "/Order/GetCounterName",
  GETACTIVITYTICKETDETAILS: "/Order/GetOrderActivityDetailTickets",

  ADDSTRIPEDETAIL: "/Integration/AddBusinessStripeDetail",
  GETSTRIPEDETAIL: "/Integration/GetBusinessStripeDetail",
  UPDATEORDERCANCELLATION: "/Order/UpdateOrderCancellation",
  GETITEMAVAILABILITYDETAIL: "/Order/GetOrderItemMayNotBeAvailable",
  GETCONNECTIONTOKEN: "/Payment/ConnectionToken",
  ADDPAYMENTMNUALLY: "/Payment/AddPaymentManually",

  HOLDPAYMENT: "/Payment/HoldPayment", // for holding a payment
  CAPTUREHOLDPAYMENT: "/Payment/CaptureHoldPayment", // for capture hold a payment

  UPDATETRANSACTIONSIGN: "/Payment/UpdateTransactionSignature",
  CREATEPAYMENTINTENT: "/Payment/create_payment_intent",

  CAPTUREPAYMENT: "/Payment/capture_payment_intent", // not used yet
  GETPARTIALDATES: "/Order/GetOrderProductPartialDates",
  UPDATEORDERCLOSESTATUS: "/Order/UpdateOrderCloseStatus",
  GETALLTRANSACTIONDETAILS: "/Order/GetOrderAllTransactionDetails",
  DELETEORDERDETAILADDONS: "/Order/DeleteOrderDetailAddOns",
  CONTACTTOSUPERADMIN: "/FAQ/ContactToSuperAdmin",
  DELETECUSTOMQUESTION: "/CustomQuestion/DeleteCustomQuestion",
  UPDATECUSTOMERQUESTION: "/CustomQuestion/UpdateCustomQuestion",
  ADDORDERPRICEDEPOSIT: "/Payment/AddOrderPriceDeposit",

  DELETEORDERDATA: "/Order/DeleteOrderData",

  ADDORDERQUESTIONANSWER: "/Order/AddOrderCustomQuestionAnswers",

  CANCELHOLDPAYMENT: "/Payment/CancelHoldPayment",

  ADDORDERCHECKTIMEFORBUNDLEORDER: "/Order/AddOrderCheckTimeForBundleOrder",
  GETORDERAVAILABLEACTIVITYTICKETS: "/Order/GetOrderAvailableActivityTickets",

  PAYMENTFROMPAYMENTTYPES: "/Payment/PaymentFromPaymentTypes",
  HOLDPAYMENTNOTMANUALLY: "/Payment/HoldPaymentNotManually",

  GETINTEGRATIONSTRIPEDETAILS: "/Integration/GetStripeDetails",
  GETCUSTOMQUESTIONRESPONSE: "/Order/GetOrderCustomQuestionResponses",
  ADDORDERREQUIREMENT: "/Order/AddOrderRequirement",
  ADDCUSTOMDURATION: "/Product/AddCustomDuration",
  GETCUSTOMDURATION: "/Product/GetCustomDurations",
  DELETECUSTOMDURATION: "/Product/DeleteCustomDuration",
  // GETCUSTOMSLOTDURATION: "/Product/GetCustomDurationSlots",
  DELETECUSTOMDURATIONSLOT: "/Product/DeleteCustomDurationSlot",
  ADDCUSTOMDURATIONSLOT: "/Product/AddCustomDurationSlot", // custom time slot  /api/Product/AddCustomDurationSlot
  ADDRENTALCUSTOMTIMEDURATION: "/Product/AddRentalCustomTimeDurations", // FOR SAVE NEW RENTAL PRICE
  GETPRODUCTCUSTOMDURATIONS: "/Product/GetProductCustomDurations",
  DELETECUSTOMTIMEDURATIONS: "/Product/DeleteRentalCustomTimeDurations",
  GETPRODUCTDURATIONSLOTS: "/Product/GetProductCustomDurationSlots",
  GETDROPDOWNBUSINESSLOCATION: "/BusinessLocation/GetDropDownBusinessLocation",
  GETTIMEZONELIST: "/GlobalCode/GetTimeZoneList",
  CURRENTDATEFORBUSINESS: "/Order/CurrentDateForBusinessLocationGet",
  UPDATEBOOKNOW: "/Business/UpdateBookKnow",
  GETORDERHISTORY: "/Order/GetOrderHistory",
  ADDSEASONPASS: "/SeasonPass/AddSeasonPass",
  UPDATESEASONPASS: "/SeasonPass/UpdatedSeasonPass",
  ADDSEASONPASSIMAGE: "/SeasonPass/AddSeasonPassImage",
  UPDATESEASONPASSIMAGE: "/SeasonPass/UpdateSeasonPassImage",
  ADDUPDATESEASONPASSPRICE: "/SeasonPass/AddUpdateSeasonPassPrice",
  GETSEASONPASSPRICE: "/SeasonPass/GetSeasonPassPrice",
  GETSEASASONPASSPRICE: "/SeasonPass/GetSeasonPassPrice",
  GETSEASONPASS: "/SeasonPass/GetSeasonPass",
  GETSEASONPASSIMAGE: "/SeasonPass/GetSeasonPassImage",
  GETSEASONPASSLIST: "/SeasonPass/GetSeasonPassList",
  GETSIGNEDDOCUMENT: "/Document/GetSignedDocument",
  GETNEWORDER: "/NewOrder/GetNewOrder",
  ADDUPDATENEWORDER: "/NewOrder/AddUpdateNewOrder",
  ///Guest List
  GETWAVIERCUSTOMER: "/WavierCustomer/GetWavierCustomer",
  GETGUESTLIST: "/Customer/GetGuestList",
  ADDGUESTLIST: "/Customer/AddGuestList",
  DELETECUSTOMERGUEST: "/Customer/DeleteCustomerGuest",
  ASSIGNSEASONPASSTOCUSTOMER: "/SeasonPass/AssignSeasonPassToCustomer",
  UPDATEACTIVECHECKIN: "/Customer/UpdateActiveCheckIn",
  ADDORDERPRODUCTDOCUMENTFORGUEST: "/Order/AddOrderProductDocumentForGuest",
  GETORDERSEASONPASSCUSTOMFIELDLIST: "/Order/GetOrderSeasonPassCustomFieldList",
  ADDORDERSEASONPASSCUSTOMFIELD: "/Order/AddOrderSeasonPassCustomField",
  GETORDERSEASONPASSDETAIL: "/Order/GetOrderSeasonPassDetail",
  ADDPAYMENTMETHODDETAILSFORINTEGRATION: "/Integration/AddPaymentMethodDetailsForIntegration",
  GETORDERSUMMARYDOCUMENT: "/Order/GetOrderSummaryDocument",
  GETACTIVITYBOOKINGDATES: "/Order/GetActivityBookingDates",
  ADDUPDATEDEAFAULTCOUNTER: "/User/AddUpdateDeafaultCounter",
  GETDEFAULTCOUNTEANDREADER: "/User/GetDefaultCounterAndReader",
  ADDORDERSEASONPASSCOUPONS: "/Order/AddOrderSeasonPassCoupons",
  DELETEORDERSEASONPASSCOUPONS: "/Order/DeleteOrderSeasonPassCoupons",
  DELETEINTEGRATIONPAYMENTINFORMATION: "/Integration/DeleteIntegrationPaymentInformation",
  UPDATEMASTERCATEGORY: "/ProductCategory/UpdateMasterCategory",
  ADDVERIFIEDSENDER: "/Integration/AddVerifiedSender",
  UPDATESENDERVERIFIED: "/Integration/UpdateIsSenderVerified",
  RESENDVERIFICATIONEMAIL: "/Integration/ResendVerificationEmail",
  CONNECTCODE: "/Integration/ConnectCode",
  DELETEMASTERCATEGORYIMAGE: "/ProductCategory/DeleteMasterCategoryImage",
  ADDUPDATETIPS: "/Tips/AddUpdateTips",
  GETTIPS: "/Tips/GetTips",
  DELETETIPS: "/Tips/DeleteTips",
  UPDATETIPTOGGLE: "/Tips/UpdateTipToggle",
  GETTIPFORPAYMNETS: "/Tips/GetTipForPayments",
  UPDATESHOWHIDEBALANCEDUETOGGLE: "/Order/UpdateShowHideBalanceDueToggle",
  GETAUTHCODE: "/GoogleCalendar/GetAuthCode",
  CREATEINVOICE: "/Invoice/CreateInvoice",
  ADDGOOGLECALENDERDETAIL:"/GoogleCalendar/AddGoogleCalendarDetail",
  GETGOOGLECALENDERDETAIL:"/GoogleCalendar/GetGoogleCalendarDetail",
  DELETEGOOGLECALENDERDETAIL:"/GoogleCalendar/DeleteGoogleCalendarDetail",
};

export const getApiUrl = (key) => {
  return API_URL + API_ENDPOINTS[key];
};
