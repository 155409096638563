import React, { Component } from "react";
import { Button, Grid, Form, Modal } from "semantic-ui-react";
import { connect, bindActionCreators, actions, Link, commonFunctions, Notifications } from "../../../../functional/global-import";


class ForgetPasscodeModal extends Component {
  // Put all key & values that need to reset on form submit and reset form.
  get initialState() {
    return {
      resetPasscode: { businessId:  this.props.global.bussinessDetail.businessId, emailId: "" }
    };
  }
  constructor(props) {
    super(props);
    this.state = {
      ...this.initialState,
      resetPasscodedetil: false,
      resetPasscodemain: true
    };
    this.validator = commonFunctions.initializeSimpleValidator();
  }

  // This function is used to set handle change
  onHandleChange = (e, { name, value, type, checked, data }) => {
    
    var resetPasscode = commonFunctions.onHandleChange(e, { name, value, type, checked, data }, this.state.resetPasscode);
    this.setState({ resetPasscode });
  }

  // This function is used to set handle submit
  onHandleSubmit = (e) => {
    
    var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
    if (isFormValid) {
      //  Api call login post 
      this.props.actions.apiCall({
        urls: ["FORGOTPASSCODE"], method: "Post", data: this.state.resetPasscode, 
        onSuccess: (response) => this.showResetDetails(response), showNotification: true
        // onSuccess: (response) =>{
        //   
        //   this.showResetDetails(response)
        // }, showNotification: true
      });
    }
  };

  showResetDetails = (response) => {
    this.setState(this.initialState);
    this.setState({
      resetPasscodedetil: true,
      resetPasscodemain: false
    });
  };

  showmain = () => {
    this.setState({
      resetPasscodedetil: false,
      resetPasscodemain: true
    });
  };
  onClose = () => {
    this.setState(this.initialState);
    this.props.closeModal()
    this.setState({
      resetPasscodedetil: false,
      resetPasscodemain: true
    })
  }
  render() {
    const { resetPasscode } = this.state;
    const { api } = this.props;
    return (
      <Modal open={this.props.openModal} closeIcon onClose={this.onClose} size={"mini"} >
        <Modal.Header>Reset Passcode</Modal.Header>
        <Modal.Content>
          <Grid>
            {this.state.resetPasscodemain && (
              <React.Fragment>
                <Grid.Column width={16}>
                  <p className="mbt">Enter email to reset passcode</p>
                  <Form> <Form.Input fluid textAlign="left" name="txtEmail" placeholder="E-mail address" error={this.validator.message("emailId", resetPasscode.emailId, "required|email")} data="emailId" onChange={this.onHandleChange} value={resetPasscode.emailId} /></Form>
                </Grid.Column>

                <Grid.Column width={16}>
                  <Button loading={api.isApiLoading} className="orange-btn" onClick={this.onHandleSubmit} > Send </Button>
                </Grid.Column>
              </React.Fragment>
            )}

            {this.state.resetPasscodedetil && (
              <React.Fragment>
                <Grid.Column width={16}>
                  <p className="mbt"> A reset passcode link has been sent to your registered email account. Please click the reset passcode link to set your new passcode. </p>
                  <p className="mbt">Not received the email yet?</p>
                  <p> Please check your spam folder, or
                    <Link className="orange-color" onClick={this.showmain}> try again.</Link>
                  </p>
                </Grid.Column>
              </React.Fragment>
            )}
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}


const mapStateToProps = state => {
  return {
    api: state.api,
    global: state.global,
    auth :state.auth
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      // showNotification: bindActionCreators(Notifications.show, dispatch),
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ForgetPasscodeModal);
