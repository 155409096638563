import React, { Component } from 'react'
import { Dropdown } from 'semantic-ui-react'

const options = [
    { key: 'Trait 1', text: 'Trait 1', value: 'Trait 1' },
    { key: 'Trait 2', text: 'Trait 2', value: 'Trait 2' },
    { key: 'Trait 3', text: 'Trait 3', value: 'Trait 3' },
  ]

class TraitSelect extends Component {
    state = { options }

    handleAddition = (e, { value }) => {
        this.setState((prevState) => ({
          options: [{ text: value, value }, ...prevState.options],
        }))
      }
    
    handleChange = (e, { value }) => {
        this.setState({ currentValues: value })
    }

    render() {
        const { currentValues } = this.state 
        return (
        
            <Dropdown options={this.state.options} placeholder='Traits' search selection fluid multiple allowAdditions value={currentValues} onAddItem={this.handleAddition} onChange={this.handleChange} className="custom-select"/>
            );
        }
    }
 
export default TraitSelect;