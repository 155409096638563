import * as types from "../types";

const orderInitialState = {
  isSaving: false,
  isLoading: false,
  currentOrder: {
    orderId: null,
    guestListToggle: false,
    isAhoyShareONCash:false,
    isCustomOnCash:false,
    customerId: null,
    createdDate: "",
    orderStatus: "",
    orderDeposit: null,
    paymentStatus: null,
    depositStatus: "",
    deposit: null,
    totalPayment: "",
    paidPayment: "",
    balanceDue: "",
    customerDetail: { firstName: "", lastName: "", fullDetail: "" },
    checkAllItemDetails: false,
    cartEditIcon: false,
    orderDetails: [],
    selectedItemsWithAmount: [],
    selectedRetailItem: [],
    seasonPassItem: [],
    selectedActivityItems: [],
    selectedBundleItems: [],
    pricings: null,
    onAddingNewItemToCart: [],
    selectedAmountItem: [],
    selectedDepositAmount: [],
    orderAddonCheckbox: false,
  },
};

// const orignalstate = JSON.parse(JSON.stringify(orderInitialState));

const onCheckPidToatlOfAddon = (
  productItemPaid,
  businessTypeArray,
  type,
  addArrayName
) => {
  businessTypeArray.map((businessTypeObj) => {
    businessTypeObj[addArrayName] =
      businessTypeObj[addArrayName] &&
      businessTypeObj[addArrayName].map((businessTypeAddonObj) => {
        productItemPaid.map((paidObj) => {
          if (
            businessTypeAddonObj.OrderDetailAddonId ===
              paidObj.orderDetailAddonId &&
            type === paidObj.producttype
          ) {
            businessTypeAddonObj.paidAmount = true;
          }
          return { ...paidObj };
        });
        return {
          ...businessTypeAddonObj,
        };
      });
    return {
      ...businessTypeObj,
    };
  });
};

// rental item deposit paid or not
const onCheckPidToatlOfRentalDeposit = (
  productItemPaid,
  businessTypeArray,
  type
) => {
  businessTypeArray.map((businessTypeObj) => {
    businessTypeObj.Deposit !== null &&
      productItemPaid.map((paidObj) => {
        if (
          businessTypeObj.OrderDetailId === paidObj.orderDetailId &&
          type === paidObj.producttype
        ) {
          businessTypeObj.paidRentalDepositAmount = true;
        }
        return { ...paidObj };
      });
    return {
      ...businessTypeObj,
    };
  });
};

const onCheckPidToatl = (productItemPaid, businessTypeArray, type) => {
  businessTypeArray.map((businessTypeObj) => {
    productItemPaid.map((paidObj) => {
      if (
        businessTypeObj.OrderDetailId === paidObj.orderDetailId &&
        type === paidObj.producttype
      ) {
        businessTypeObj.paidAmount = true;
      }
      return { ...paidObj };
    });
    return {
      ...businessTypeObj,
    };
  });
};

// order deposit paid or not

const onCheckPaidToatalOfOrderDeposit = (
  productItemPaid,
  currentOrder,
  type
) => {
  productItemPaid.map((paidObj) => {
    if (
      paidObj.orderDetailId === currentOrder.orderId &&
      type === paidObj.producttype
    ) {
      currentOrder.paidOrderDepositAmount = true;
    }
    return { ...paidObj };
  });
};
const onCheckPaidToatlOrderAddon = (
  productItemPaid,
  businessTypeArray,
  type
) => {
  businessTypeArray.map((businessTypeObj) => {
    productItemPaid.map((paidObj) => {
      if (
        businessTypeObj.OrderAddOnId === paidObj.orderDetailId &&
        type === paidObj.producttype
      ) {
        businessTypeObj.paidAmount = true;
      }
      return { ...paidObj };
    });
    return {
      ...businessTypeObj,
    };
  });
};
const order = (state = 
  orderInitialState, action) => {
  switch (action.type) {
    case types.CREATE_ORDER_ID: {
      if (action.payload.orderId) {
        let itemDetails =
          action.payload.rentalItems && JSON.parse(action.payload.rentalItems);
        let retailItemDetails =
          action.payload.retailItems && JSON.parse(action.payload.retailItems);
        let seasonPassItemDetails =
          action.payload.seasonPassItem &&
          JSON.parse(action.payload?.seasonPassItem);
        let activityItemDetails =
          action.payload.activityItems &&
          JSON.parse(action.payload.activityItems);
        let bundleItemDetails =
          action.payload.bundleItems && JSON.parse(action.payload.bundleItems);
        let pricing = action.payload.pricings && JSON.parse(action.payload.pricings)[0];
        // order addon maping
        let orderAddOn =
          pricing.Addons !== null &&
          pricing.Addons.map((addonObj) => {
            return {
              ...addonObj,
              orderAddonCheckbox: false,
              paidAmount: false,
            };
          });
        pricing.Addons = orderAddOn === false ? null : orderAddOn;
        // pricing.Taxes = pricing.Taxes === 0 ? null : pricing.Taxes
        state["currentOrder"]["pricings"] = pricing;
        state["currentOrder"]["rehntitshare"] = action.payload.rehntitshare;
        state["currentOrder"]["ahoyMinimumChargeAmount"] =action.payload.ahoyMinimumChargeAmount;

        if (pricing) {
          // for retail order
          action.payload.rentalItems &&
            state.currentOrder.orderDetails.map((item, idx) => {
              if (
                item.id === state.currentOrderDetails.id &&
                item.type === "Rental" &&
                JSON.parse(action.payload.rentalItems)[idx]
              ) {
                // return { ...item, orderDetailId: JSON.parse(action.payload.rentalItems)[0].OrderDetailId }
                item.orderDetailId = JSON.parse(action.payload.rentalItems)[
                  idx
                ].OrderDetailId;
              } else {
                return { ...item };
              }
              return { ...item };
            });
        }

        state["currentOrder"]["cartEditIcon"] = false;
        state["currentOrder"]["checkAllItemDetails"] = false;
        state["currentOrder"]["orderAddonCheckbox"] = false;
        state["currentOrder"]["orderDepositCheckbox"] = false;
        state["currentOrder"]["paidOrderDepositAmount"] = false;
        state["currentOrder"]["selectedAmountItem"] = [];
        state["currentOrder"]["selectedDepositAmount"] = [];
        state["currentOrder"]["createdDate"] = action.payload.createdDate;
        state["currentOrder"]["productItemPaid"] =
          action.payload.productItemPaid;
        state["currentOrder"]["deposit"] = action.payload.deposit
          ? action.payload.deposit
          : null;
        state["currentOrder"]["orderStatus"] = action.payload.orderStatus;
        state["currentOrder"]["orderDeposit"] = action.payload.orderDeposit;
        state["currentOrder"]["totalDeposit"] = action.payload.totalDeposit;
        state["currentOrder"]["paymentStatus"] = action.payload.paymentStatus;
        state["currentOrder"]["depositStatus"] = action.payload.depositStatus;
        state["currentOrder"]["depositStatusId"] =
          action.payload.depositStatusId;
        state["currentOrder"]["orderId"] = action.payload.orderId;
        state["currentOrder"]["guestListToggle"] = action.payload.guestListToggle;
        state["currentOrder"]["isAhoyShareONCash"] = action.payload.isAhoyShareONCash;
        state["currentOrder"]["isCustomOnCash"] = false;
        state["currentOrder"]["customerDetail"] = {
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          fullDetail: action.payload,
          customerId: action.payload.customerId,
        };

        // rental items
        let selectedItemsWithAmount = [];
        if (itemDetails) {
          selectedItemsWithAmount = itemDetails.map((rentalObj) => {
            rentalObj.ItemAddons =
              rentalObj.ItemAddons &&
              rentalObj.ItemAddons.map((itemAddonObj) => {
                return {
                  ...itemAddonObj,
                  rentalItemAddon: false,
                  paidAmount: false,
                };
              });
            return {
              ...rentalObj,
              rentalItemCheckbox: false,
              paidRentalDepositAmount: false,
              rentalDepositCheckbox: false,
              paidAmount: false,
              showIcon: true,
            };
          });
        }

        let productItemPaid = JSON.parse(
          state["currentOrder"]["productItemPaid"]
        );

        // for  order deposit
        productItemPaid &&
          state.currentOrder.orderDeposit !== 0 &&
          onCheckPaidToatalOfOrderDeposit(
            productItemPaid,
            state.currentOrder,
            "OD"
          );

        // for rental item deposit
        productItemPaid &&
          selectedItemsWithAmount.length > 0 &&
          onCheckPidToatlOfRentalDeposit(
            productItemPaid,
            selectedItemsWithAmount,
            "PD"
          );

        // paid code order addon
        productItemPaid &&
          pricing.Addons &&
          pricing.Addons.length > 0 &&
          onCheckPaidToatlOrderAddon(productItemPaid, pricing.Addons, "OA");

        // paid code for rental items
        productItemPaid &&
          selectedItemsWithAmount.length > 0 &&
          onCheckPidToatl(productItemPaid, selectedItemsWithAmount, "P");

        // paid code for  rental items addon
        productItemPaid &&
          selectedItemsWithAmount.length > 0 &&
          onCheckPidToatlOfAddon(
            productItemPaid,
            selectedItemsWithAmount,
            "PA",
            "ItemAddons"
          );

        state["currentOrder"]["selectedItemsWithAmount"] =
          selectedItemsWithAmount;

        let selectedRetailItem = [];
        if (retailItemDetails) {
          selectedRetailItem = retailItemDetails.map((retailData) => {
            retailData.RetailAddons =
              retailData.RetailAddons &&
              retailData.RetailAddons.map((retailAddonObj) => {
                return {
                  ...retailAddonObj,
                  retailItemAddon: false,
                  paidAmount: false,
                };
              });

            return {
              ...retailData,
              retailItemCheckbox: false,
              paidAmount: false,
              showIcon: true,
            };
          });
        }
        //  new code 19 mar 2023
        let seasonPassItem = [];
        if (seasonPassItemDetails) {
          seasonPassItem = seasonPassItemDetails.map((seasonPassData) => {
            seasonPassData.SeasonPassAddons =
              seasonPassData.SeasonPassAddons &&
              seasonPassData?.SeasonPassAddons.map((seasonAddonObj) => {
                return {
                  ...seasonAddonObj,
                  seasonItemAddon: false,
                  paidAmount: false,
                };
              });
            return {
              ...seasonPassData,
              seasonItemCheckbox: false,
              paidAmount: false,
              showIcon: true,
            };
          });
        }

        // paid code

        productItemPaid &&
          onCheckPidToatl(productItemPaid, selectedRetailItem, "R");

        productItemPaid &&
          selectedRetailItem.length > 0 &&
          onCheckPidToatlOfAddon(
            productItemPaid,
            selectedRetailItem,
            "RA",
            "RetailAddons"
          );
        //  for season pass
        productItemPaid &&
          onCheckPidToatl(productItemPaid, seasonPassItem, "S");

        productItemPaid &&
          seasonPassItem.length > 0 &&
          onCheckPidToatlOfAddon(
            productItemPaid,
            seasonPassItem,
            "SA",
            "SeasonPassAddons"
          );

        state["currentOrder"]["selectedRetailItem"] = selectedRetailItem;

        state["currentOrder"]["seasonPassItem"] = seasonPassItem;

        let selectedActivityItems = [];
        if (activityItemDetails) {
          selectedActivityItems = activityItemDetails.map((activityData) => {
            activityData.ActivityAddons =
              activityData.ActivityAddons &&
              activityData.ActivityAddons.map((activityAddonObj) => {
                return {
                  ...activityAddonObj,
                  activityItemAddon: false,
                  paidAmount: false,
                };
              });
            return {
              ...activityData,
              activityItemCheckbox: false,
              paidAmount: false,
              showIcon: true,
            };
          });
        }

        // paid code
        productItemPaid &&
          onCheckPidToatl(productItemPaid, selectedActivityItems, "A");
        productItemPaid &&
          selectedActivityItems.length > 0 &&
          onCheckPidToatlOfAddon(
            productItemPaid,
            selectedActivityItems,
            "AA",
            "ActivityAddons"
          );

        state["currentOrder"]["selectedActivityItems"] = selectedActivityItems;

        let selectedBundleItems = [];
        if (bundleItemDetails) {
          selectedBundleItems = bundleItemDetails.map((bundleData) => {
            return {
              ...bundleData,
              bundleItemCheckbox: false,
              paidAmount: false,
              showIcon: true,
              // bundleTimeCheckbox: false,
            };
          });
        }

        // paid code
        productItemPaid &&
          onCheckPidToatl(productItemPaid, selectedBundleItems, "B");

        state["currentOrder"]["selectedBundleItems"] = selectedBundleItems;

        return { ...state };
      } else {
        return {
          ...state,
          currentOrder: {
            orderId: action.payload.keyId,
            guestListToggle: action.payload.guestListToggle,
            customerDetail: {
              firstName: action.payload.firstName,
              lastName: action.payload.lastName,
              fullDetail: action.payload,
              customerId: action.payload.customerId,
            },
            depositStatus: "",
            deposit: null,
            totalPayment: "",
            paidPayment: "",
            balanceDue: "",
            pricings: null,
            orderStatus: action.payload.orderStatus,
            orderDeposit: action.payload.orderDeposit,
            paymentStatus:
              action.payload.paymentStatus !== null
                ? action.payload.paymentStatus
                : "-",
            totalOrderQuantity: "",
            createdDate: action.payload.createdTime,
            orderDetails: [],
            checkAllItemDetails: false,
            cartEditIcon: false,
            selectedItemsWithAmount: [],
            selectedRetailItem: [],
            seasonPassItem: [],
            selectedActivityItems: [],
            selectedBundleItems: [],
            onAddingNewItemToCart: [],
            selectedAmountItem: [],
            selectedDepositAmount: [],
            orderAddonCheckbox: false,
          },
        };
      }
    }

    case types.PRICE_DEPOSIT_CHANGE: {
      state["currentOrder"] = action.payload;
      return { ...state };
    }

    case types.UPDATE_ORDER_STATE: {
      state = action.payload;
      return { ...state };
    }

    case types.REMOVE_EXTRA_CHARGES: {
      state["currentOrder"]["pricings"]["ExtraCharges"] =
        action.payload.updatedExtraCharge;
      state["currentOrder"]["pricings"]["OrderTotal"] =
        action.payload.orderTotal;
      state["currentOrder"]["pricings"]["BalanceDue"] =
        action.payload.balanceDue;
      return { ...state };
    }

    case types.REMOVE_DISCOUNT_COUPON: {
      state["currentOrder"]["pricings"]["OrderTotal"] =
        action.payload.orderTotal;
      state["currentOrder"]["pricings"]["BalanceDue"] =
        action.payload.balanceDue;
      state["currentOrder"]["pricings"]["DiscountAndCoupons"] = null;
      return { ...state };
    }

    case types.ADD_MULTIPLE_RENTALFLATFEEANDOTHERDETAILS: {
      const updatedOrderDetail = state.currentOrder.orderDetails.map(
        (item, idx) => {
          if (action.payload) {
            let slotPriceOptions =
              action.payload[0].slotBasedPrice &&
              JSON.parse(action.payload[0].slotBasedPrice).map((slotObj) => {
                return {
                  text: slotObj.CustomDurationTitle,
                  value: slotObj.CustomDurationId,
                  id: slotObj.CustomDurationId,
                };
              });
            let selectedItemInfoPriceCustomFieldDetail = {
              specialPrice:
                action.payload[0].specialPrice &&
                JSON.parse(action.payload[0].specialPrice),
              rate:
                action.payload[0].rate && JSON.parse(action.payload[0].rate),
              price:
                action.payload[0].price && JSON.parse(action.payload[0].price),
              info:
                action.payload[0].info && JSON.parse(action.payload[0].info),
              slotBasedPrice: slotPriceOptions,
              customFields:
                action.payload[0].customFields &&
                JSON.parse(action.payload[0].customFields),
            };
            // let addOnDetailsOfSelected = action.payload[0].addOn && JSON.parse(action.payload[0].addOn);
            if (item.id === state.currentOrderDetails.id) {
              return {
                ...item,
                selectedProductDetails: selectedItemInfoPriceCustomFieldDetail,
              };
            } else {
              return { ...item };
            }
          }
          return { ...item };
        }
      );
      state["currentOrder"]["orderDetails"] = updatedOrderDetail;
      return { ...state };
    }

    case types.REMOVE_ORDER_ID: {
      // return orderInitialState;
      let orderInitialState = {
        isSaving: false,
        isLoading: false,
        currentOrder: {
          orderId: null,
          guestListToggle: false,
          customerId: null,
          createdDate: "",
          orderStatus: "",
          orderDeposit: null,
          paymentStatus: null,
          depositStatus: "",
          deposit: null,
          totalPayment: "",
          paidPayment: "",
          balanceDue: "",
          customerDetail: { firstName: "", lastName: "", fullDetail: "" },
          orderDetails: [],
          checkAllItemDetails: false,
          cartEditIcon: false,
          selectedItemsWithAmount: [],
          selectedRetailItem: [],
          seasonPassItem: [],
          selectedActivityItems: [],
          selectedBundleItems: [],
          pricings: null,
          onAddingNewItemToCart: [],
          selectedAmountItem: [],
          selectedDepositAmount: [],
          orderAddonCheckbox: false,
        },
      };
      return orderInitialState;
    }

    case types.ADD_ORDER_DETAILS: {
      let AddOrderDetails = {
        id: state.currentOrder.orderDetails.length + 1,
        typeId: action.payload.businessCategoryId,
        type: action.payload.businessCategoryName,
      };
      state.currentOrder.orderDetails.push(AddOrderDetails);
      state["currentOrderDetails"] = AddOrderDetails;
      return { ...state };
      // }
    }

    case types.ADD_SELECTED_DATE_RANGE: {
      const nextState = state.currentOrder.orderDetails.map((item, idx) => {
        if (action.payload.startDate) {
          if (item.id === state.currentOrderDetails.id) {
            return {
              ...item,
              selectedDateRange: {
                startDate: action.payload.startDate,
                endDate: action.payload.endDate,
              },
            };
          } else {
            return { ...item };
          }
        } else if (action.payload.startTime) {
          if (item.id === state.currentOrderDetails.id) {
            return {
              ...item,
              selectedDateRange: {
                ...item.selectedDateRange,
                startTime: action.payload.startTime,
                endTime: action.payload.endTime,
              },
            };
          } else {
            return { ...item };
          }
        } else {
          return item;
        }
      });
      state["currentOrder"]["orderDetails"] = nextState;
      return { ...state };
    }

    case types.ADD_SELECTED_ITEMS: {
      const nextState = state.currentOrder.orderDetails.map((item) => {
        if (item.id === state.currentOrderDetails.id) {
          if (action.payload.retailTypeId) {
            // item["retailTypeId"] = action.payload.retailTypeId;
            return {
              ...item,
              selectedItems: action.payload,
              retailTypeId: action.payload.retailTypeId,
            };
          } else {
            return { ...item, selectedItems: action.payload };
          }
        } else {
          return { ...item };
        }
      });

      state["currentOrder"]["orderDetails"] = nextState;
      return { ...state };
    }

    // not to use now
    case types.ADD_ORDER_ACITIVITY_PRICINGDETAILID: {
      const nextState = state.currentOrder.orderDetails.map((item, idx) => {
        if (item.id === state.currentOrderDetails.id) {
          return {
            ...item,
            OrderActivityDetailId: action.payload.keyId,
            OrderDetailId: action.payload.orderDetailId,
          };
        } else {
          return item;
        }
      });
      state["currentOrder"]["orderDetails"] = nextState;
      return { ...state };
    }

    case types.ADD_SELECTED_BOOKINGSLOT: {
      const nextState = state.currentOrder.orderDetails.map((item, idx) => {
        if (item.id === state.currentOrderDetails.id) {
          return { ...item, bookingTimeSlot: action.payload };
        } else {
          return item;
        }
      });
      state["currentOrder"]["orderDetails"] = nextState;
      return { ...state };
    }
    case types.ADD_ORDER_PAYMENT_STATUS: {
      const nextState = state.currentOrder.orderDetails.map((item, idx) => {
        if (item.id === state.currentOrderDetails.id) {
          return { ...item, orderDetailId: action.payload.orderDetailId };
        } else {
          return item;
        }
      });
      state["currentOrder"]["orderDetails"] = nextState;
      state["currentOrder"]["depositStatus"] = action.payload.depositStatus
        ? action.payload.depositStatus
        : "UnPaid";
      state["currentOrder"]["orderStatus"] = action.payload.orderStatus;
      state["currentOrder"]["orderDeposit"] = action.payload.orderDeposit;
      state["currentOrder"]["orderAmount"] = action.payload.orderAmount;
      state["currentOrder"]["totalPayment"] = action.payload.orderTotal;
      state["currentOrder"]["paidPayment"] = action.payload.paidPayment
        ? action.payload.paidPayment
        : 0;
      state["currentOrder"]["deposit"] = action.payload.deposit
        ? action.payload.deposit
        : null;
      state["currentOrder"]["balanceDue"] = action.payload.balanceDue
        ? action.payload.balanceDue
        : 0;
      if (state["currentOrder"]["selectedItemsWithAmount"]) {
        if (state["currentOrder"]["selectedItemsWithAmount"].length > 0) {
        } else {
          state["currentOrder"]["selectedItemsWithAmount"] = [
            {
              price: action.payload.orderAmount,
              startDate: action.payload.startDate,
              endDate: action.payload.endDate,
              startTime: action.payload.startTime,
              endTime: action.payload.endTime,
              totalDate: action.payload.totalDay,
              totalTime: action.payload.totalTime,
              deposit: action.payload.deposit,
              quantity: action.payload.quantity ? action.payload.quantity : 1,
              selectedProduct: action.payload.selectedProduct,
              tickets: action.payload.tickets
                ? JSON.parse(action.payload.tickets)
                : [],
              orderDetailId: action.payload.orderDetailId,
              bundleProducts: action.payload.bundleProducts,
            },
          ];
        }
      }
      return { ...state };
    }

    case types.ADD_FEE_PAYMENT: {
      if (action.payload.selectedFees) {
        state["currentOrder"]["selectedFees"] = action.payload.selectedFees;
        state["currentOrder"]["feePrice"] = action.payload.price;
      }
      if (action.payload.descriptions) {
        state["currentOrder"]["descriptions"] = action.payload.descriptions;
        state["currentOrder"]["descriptionsPrice"] = action.payload.price;
      }
      if (action.payload.selectedItems) {
        state["currentOrder"]["selectedItemsWithAmount"][0]["AddOns"] =
          JSON.parse(action.payload.selectedItems);
      }
      if (action.payload.couponsDiscounts) {
        state["currentOrder"]["couponsDiscounts"] =
          action.payload.couponsDiscounts;
        state["currentOrder"]["couponsDiscountsName"] = action.payload.coupons;
      }
      state["currentOrder"]["orderAmount"] = action.payload.ordertotal;
      state["currentOrder"]["balanceDue"] = action.payload.balanceDue;
      return { ...state };
    }

    case types.UPDATE_ORDER_PAYMENT_STATUS: {
      if (action.payload.depositStatus) {
        state["currentOrder"]["depositStatus"] = action.payload.depositStatus;
        state["currentOrder"]["orderStatus"] = action.payload.orderStatus;
        state["currentOrder"]["orderDeposit"] = action.payload.orderDeposit;
        state["currentOrder"]["paidPayment"] = action.payload.amountPaid
          ? action.payload.amountPaid
          : 0;
        state["currentOrder"]["balanceDue"] = action.payload.balanceDue
          ? action.payload.balanceDue
          : 0;
      } else {
        state["currentOrder"]["orderAmount"] = "";
        state["currentOrder"]["paidPayment"] = "";
        state["currentOrder"]["deposit"] = null;
        state["currentOrder"]["balanceDue"] = "";
        state["currentOrder"]["selectedItemsWithAmount"] = [];
        state["currentOrder"]["selectedRetailItem"] = [];
        state["currentOrder"]["seasonPassItem"] = [];
      }
      return { ...state };
    }
    default:
      return state;
  }
};

export default order;
