import React, { Component } from 'react'
import { Dropdown } from 'semantic-ui-react'

const options = [
  { key: 'Road Bike', text: 'Road Bike', value: 'Road Bike' },
  { key: 'CityBikeTour', text: 'CityBikeTour', value: 'CityBikeTour' },
  { key: 'Kayak', text: 'Kayak', value: 'Kayak' },
]

class SelectBundleItemsTagger extends Component {
  state = { options }

  render() {
    const { currentValues } = this.state
    return (
      <Dropdown options={this.state.options} placeholder='Items' search selection fluid multiple value={currentValues} onAddItem={this.handleAddition} onChange={this.props.onChange} type={this.props.type} data={this.props.data} className="tagger"/>
    )
  }
}

export default SelectBundleItemsTagger;
