import React, { Component } from 'react'
import { Header, Image  } from "semantic-ui-react";
import { CartImg } from "../../../../../functional/global-image-import";

class EmptyCartGrid extends Component {
    render() {
        return (
            <>
                <Header as="h4" className="maroon-color"> Your Cart is Empty </Header>
                <Image src={CartImg} size="small" />
                {/* <Icon name="cart plus" size="massive" /> */}
            </>
        )
    }
}

export default EmptyCartGrid
