import React, { Fragment } from 'react';
import ContentLoader from 'react-content-loader'



const ItemBoxSkeleton = ({ props }) => {
    return (
        <Fragment>
            <ContentLoader
                height={150}
                width={1200}
                {...props}>
                <rect x="46.84" y="15.93" rx="8" ry="6" width="152.55" height="100" />
                <rect x="70" y="120" rx="3" ry="8" width="40" height="28" />  |{" "}
                <rect x="130" y="120" rx="3" ry="8" width="40" height="28" />
            </ContentLoader>
        </Fragment>
    );
};
ItemBoxSkeleton.propTypes = {
};
export default ItemBoxSkeleton;
