import React, { Component } from "react";
import { Button, Grid, Modal,  Form, Checkbox  } from "semantic-ui-react";
import { connect, bindActionCreators, actions } from "../../../shared/functional/global-import";

class AddCustomField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customFieldsList: [],
      addInDropDown: []
    }
  }
  componentDidMount() {
    this.props.actions.apiCall({
      urls: ["GETCUSTOMFIELD"], method: "GET", data: {
        BusinessId: this.props.global.bussinessDetail.businessId, isActive: true,
        CustomFieldId: -1, BusinessLocationId: this.props.global.locationSelected.BusinessLocationId, PageNo: "1", PageSize: "100"
      }, onSuccess: (response) => {
        const customFieldList = response.map((filtercode) => {
          return { key: filtercode.customFieldId, value: filtercode.customFieldName, text: filtercode.customFieldName }
        });
        this.setState({ customFieldsList: customFieldList });
      }
    });
  };

  handleAddition = (e, { value }) => {
    this.setState((prevState) => ({
      customFieldsList: [{ text: value, value }, ...prevState.customFieldsList],
    }));
    const addvalue = this.state.addInDropDown;
    addvalue.push(value);
    this.setState({ addInDropDown: addvalue });
  };

  createCustomFields() {
    return this.props.addCustomFields.map((el, i) => (
      <React.Fragment>
        <Grid.Column width={6}>
          <Form>
            <Form.Dropdown label="Custom" placeholder='Title' options={this.state.customFieldsList} id={i}
              fluid allowAdditions search selection value={el.customField} className="tagger"
              error={this.props.validatorCustomfield.message(`Title ${i+1}`, el.customField, "required|min:0|max:100")}
              onAddItem={this.handleAddition} onChange={this.props.onHandleCustomField} data="customField" />
          </Form>
        </Grid.Column>
        <Grid.Column width={6}>
          <Form>
           {this.props.showRequired && <Checkbox name="Required" dataId={i} checked={el.customRequired} data="customRequired" onChange={this.props.onHandleCustomField} label="Required" className="custome-checkbox customRequiredField" />}
            <Form.TextArea rows={1} fluid label="Description" placeholder="Description" value={el.customDescriptions}
              error={this.props.validatorCustomfield.message(`Description ${i+1}`, el.customDescriptions, "required|min:0|max:2000")}
              data="customDescriptions" id={i} onChange={this.props.onHandleCustomField} />
          </Form>
        </Grid.Column>
        <Grid.Column width={2} textAlign="center" className="d-flex">
        <div className="flex-end d-flex">
          <Button icon="trash alternate" className="orange-button" title="Delete Row" onClick={() => this.props.handleRemoveSpecificRow(i)} />
       </div>
        </Grid.Column>
      </React.Fragment>
    ));
  }

  aa = () => {
    this.props.onHandleAddCustomField(this.props.closeModal);
    this.setState({ a: "a" })
  }
  render() {
    return (
      <Modal open={this.props.openModal} closeIcon onClose={this.props.closeModal} size={"small"} closeOnDimmerClick={false}>
        <Modal.Header>Add Custom Field</Modal.Header>
        <Modal.Content> {this.props.addCustomFields &&
          <Grid>
            {this.createCustomFields()}
            <Grid.Column width={1}  className="d-flex">
            <div className="flex-end d-flex">
              <Button icon="plus" className="purple-button" title="Add Row" onClick={this.props.addClickCustomField} />
            </div>
            </Grid.Column>
          </Grid>}
          <Grid className="addCustomSaveBtn">
            <Grid.Column width={2} textAlign="right">
                          <Button className="orange-btn" onClick={this.aa}>Save</Button>
              
            </Grid.Column>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}
const mapStateToProps = state => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch)
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddCustomField);
