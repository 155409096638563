import React, { Component } from "react";
import { Grid, Modal, Form, Table, Icon, Button, Image } from "semantic-ui-react";
import { commonFunctions, connect, bindActionCreators, actions, Notifications, Link } from "../../../../functional/global-import";

class AssociateAddOnsModal extends Component {
  constructor(props) {
    
    super(props);
    this.state = {
      addOnsList: [],

    };
    this.validator = commonFunctions.initializeSimpleValidator();
  }
  getAddOns = () => {
    this.props.actions.apiCall({
      urls: ["GETADDONS"],
      method: "GET",
      data: {
        addOnId: -1, businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId: this.props.global.locationSelected.BusinessLocationId
        , PageNo: 1, PageSize: 100
      },
      onSuccess: (response) => {
        const addOnsList = response.map((singleAddons) => {
          const image = JSON.parse(singleAddons.image)
          return { value: singleAddons.addOnId, text: singleAddons.addOnTitle,
            image: commonFunctions.concatenateImageWithAPIUrl(image ? image[0].ImageFile : null), Price: singleAddons.addOnPrice }
        });
        this.setState({ addOnsList: addOnsList });
      },
    });
  }
  componentDidMount() {
    this.getAddOns();
  }


  render() {
    const { fullState, allFunction, updateRentalGeneral } = this.props;
    const addOnsEdit = fullState.addOnsSaves
    return (
      <Modal open={this.props.openModal} closeIcon onClose={this.props.closeModal} size={"small"} closeOnDimmerClick={false}>
        <Modal.Header> Associate Add Ons</Modal.Header>
        <Modal.Content>
          <Grid>
            <Grid.Column width={10}>
              <Form.Select className="custom-select" placeholder="Add Ons" options={this.state.addOnsList}
                value={fullState.addOnId} type="select" onChange={allFunction.onHandleAddonDropdown}
                error={this.validator.message("addOns", fullState.AddOnTitle, "required")}
                 fluid />
            </Grid.Column>

            <Grid.Column width={6} verticalAlign="middle">
              <Form.Field>
                <Form.Input placeholder="Total Price"
                  error={this.validator.message("addOnsPrice", fullState.Price, "required|integer|min:0,num")}
                  fluid data="Price" value={fullState.Price}
                  onChange={allFunction.onHandleChangePrice}
                />
              </Form.Field>
            </Grid.Column>

            <Grid.Column width={16} verticalAlign="middle" textAlign="right">
              {!fullState.updatebuttonAddOns ? <Button className="orange-btn" onClick={allFunction.onHandleAdddOnsPush}>Add</Button>
                : <Button className="orange-btn" onClick={allFunction.onHandleUpdatedOnsPush}>Update</Button>}
            </Grid.Column>

            <Grid.Column width={16}>
              {fullState.addOns  &&
                <Table>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Image</Table.HeaderCell>
                      <Table.HeaderCell>
                        Title
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        Price
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="right">
                        Action
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                  {/* {fullState.addOns.length === 0 &&
                   <Table.Row > 
                  <h4 className="singleAddOns"> No Records to show </h4>
                  </Table.Row>} */}
                    {fullState.addOns && fullState.addOns.map((singleAddOns, index) => {
                                                                      
                      return (
                        <Table.Row key={index}>
                          <Table.Cell><Image src={singleAddOns.Image} size="mini" /></Table.Cell>
                          <Table.Cell title={singleAddOns.AddOnTitle} className= "textOverflowHidden">{singleAddOns.AddOnTitle}</Table.Cell>
                          <Table.Cell>${singleAddOns.Price}</Table.Cell>

                          <Table.Cell textAlign="right">
                            <Icon name="pencil alternate" title="Edit" size="large" className="deepviolet-color" link onClick={() => allFunction.editAddOns(singleAddOns, index, true)} />
                            <Icon name="trash alternate" title="Delete" size="large" className="orange-color" link onClick={() => allFunction.deleteAddOns(singleAddOns, index)} />
                          </Table.Cell>
                        </Table.Row>
                      )
                    }) }

                    {/* {this.props.addOns && this.props.addOns.map((singleAddOns, index) => {
                      return (
                        <Table.Row key={index}>
                          <Table.Cell><Image src={singleAddOns.Image} size="mini" /></Table.Cell>
                          <Table.Cell>{singleAddOns.AddOnTitle}</Table.Cell>
                          <Table.Cell>${singleAddOns.Price}</Table.Cell>

                          <Table.Cell textAlign="right">
                            <Icon name="pencil alternate" size="large" className="deepviolet-color" link onClick={() => allFunction.editAddOns(singleAddOns, index, true)} />
                            <Icon name="trash alternate" size="large" className="orange-color" link onClick={() => allFunction.deleteAddOns(singleAddOns, index)} />
                          </Table.Cell>
                        </Table.Row>
                      )
                    })} */}
                  </Table.Body>
                </Table>
                }
            </Grid.Column>

            <Grid.Column width={16} textAlign="right">
              <Button className="blue-btn" onClick={() => allFunction.onHandleClearAddons(this.props.closeModal)}>
                Cancel
              </Button>
              <Button className="orange-btn" onClick={() => allFunction.onHandleAddonsField(this.props.closeModal)}>
                Save
              </Button>
            </Grid.Column>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AssociateAddOnsModal);

