import React from "react";
import { Redirect, Route } from "react-router-dom";
import { env } from "../functional/global-import";
import { connect } from "./global-import";

const PrivateRoute = ({
  render: Component,
  auth,
  global,
  userTypes,
  ...rest
}) => {
  let isRouteAllowed = false;
  if (rest.path.indexOf("passcode") > 0) {
    isRouteAllowed = auth.loggedIn && auth.loggedIn.token ? true : false;
  } else if (rest.path.indexOf("emp-login")) {
    isRouteAllowed = auth.loggedIn && auth.loggedIn.token ? true : false;
  } else {
    isRouteAllowed =
      auth.loggedIn && auth.loggedIn.token && auth.userDetail ? true : false;
  }
  return (
    <Route
      {...rest}
      render={(props) => {
        return isRouteAllowed ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={
              auth.loggedIn && auth.loggedIn.token
                ? `${env.PUBLIC_URL}/passcode`
                : `${env.PUBLIC_URL}`
            }
          />
        );
        // `${env.PUBLIC_URL}/${global.bussinessDetail.businessUrl}`} />
      }}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    global: state.global,
  };
};
export default connect(mapStateToProps, null)(PrivateRoute);
