import React, { Fragment } from 'react';
import { Image } from "semantic-ui-react";
import PropTypes from 'prop-types';
import './item-box.scss';

const ItemBox = ({ imageUrl, inCount, outCount, }) => {
    return (
        <Fragment>
            <div className="item-box">
                <Image src={imageUrl} size={"small"} centered />
                <p>
                    <span className="orange-color">{inCount}</span>
                  
                </p>
            </div>
        </Fragment>
    );
};

ItemBox.propTypes = {
    imageUrl: PropTypes.string.isRequired,
    inCount: PropTypes.string.isRequired,
    outCount: PropTypes.string.isRequired
};
export default ItemBox;

