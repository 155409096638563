import React, { Component } from "react";
import { Grid, Image, Header, Card } from "semantic-ui-react";
import {RetailImg,RentalImg,TrackableImg,BulkImg} from "../../../../functional/global-image-import";
import {commonFunctions ,bindActionCreators, actions,connect,Notifications } from "../../../../functional/global-import";
class AddItemType extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  render() {
    const track = (commonFunctions.getGlobalCodeDetails(this.props.global.codes,"RetailType", "Trackable" )).globalCodeId;
    const bulk = (commonFunctions.getGlobalCodeDetails(this.props.global.codes,"RetailType", "Bulk" )).globalCodeId;
    return (
 
      <React.Fragment>
        <Grid textAlign="center" verticalAlign="middle">
          {this.props.addItemType.rentalretail && (
            <React.Fragment>
              <Grid.Column textAlign="center" width={4}>
                <Card className="cmn-shad" link
                  onClick={() => {
                    this.props.NextClick(1,"Rental");
                    // this.props.handleDocumentFeilds(true);
                    // this.props.handlepricingField(false);
                    // this.props.handletrackableField(true);
                  }}  >
                  <Card.Content>
                    <Header as="h4">Rentals</Header>
                    <Image src={RentalImg} size="tiny" centered />
                  </Card.Content>
                </Card>
              </Grid.Column>
              <Grid.Column textAlign="center" width={4}>
                <Card className="cmn-shad" link
                  onClick={() => {
                    this.props.showtrackbulk();
                    this.props.handleDocumentFeilds(false);
                    this.props.handlepricingField(true);
                  }}>
                  <Card.Content>
                    <Header as="h4">Retail</Header>
                    <Image src={RetailImg} size="tiny" centered />
                  </Card.Content>
                </Card>
              </Grid.Column>
            </React.Fragment>
          )}
          {this.props.addItemType.trackbulk && (
            <React.Fragment>
              <Grid.Column textAlign="center" width={4}>
                <Card className="cmn-shad" link
                  onClick={() => {
                    this.props.NextClick(1,"Retail",track);
                    this.props.handleDocumentFeilds(true);
                    this.props.handletrackableField(true);
                  }}>
                  <Card.Content>
                    <Header as="h4">Trackable</Header>
                    <Image src={TrackableImg} size="tiny" centered />
                  </Card.Content>
                </Card>
              </Grid.Column>
              <Grid.Column textAlign="center" width={4}>
                <Card className="cmn-shad" link
                  onClick={() => {
                    this.props.NextClick(1,"Retail",bulk);
                    this.props.handleDocumentFeilds(true);
                    this.props.handletrackableField(false);
                  }}>
                  <Card.Content>
                    <Header as="h4">Bulk</Header>
                    <Image src={BulkImg} size="tiny" centered />
                  </Card.Content>
                </Card>
              </Grid.Column>
            </React.Fragment>
          )}
        </Grid>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    global: state.global
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch)
    }
  };
};
export default (connect(mapStateToProps, mapDispatchToProps)(AddItemType));

