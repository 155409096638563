import React, { Component } from "react";
import { Button, Form, Radio, Grid, Modal, GridColumn, Dropdown } from "semantic-ui-react";
import { DateInput, TimeInput } from "semantic-ui-calendar-react";
import { bindActionCreators, connect, actions, dispatch } from "../../../../../functional/global-import";
import Moment from "react-moment";
import moment from "moment";

const CustomTimethree = [
  { key: "am", value: "am", text: "AM" },
  { key: "pm", value: "pm", text: "PM" },
];
const CustomTimefirst = [
  { key: "01", value: "01", text: "01" },
  { key: "02", value: "02", text: "02" },
  { key: "03", value: "03", text: "03" },
  { key: "04", value: "04", text: "04" },
  { key: "05", value: "05", text: "05" },
  { key: "06", value: "06", text: "06" },
  { key: "07", value: "07", text: "07" },
  { key: "08", value: "08", text: "08" },
  { key: "09", value: "09", text: "09" },
  { key: "10", value: "10", text: "10" },
  { key: "11", value: "11", text: "11" },
  { key: "12", value: "12", text: "12" },
];
const CustomTimetwo = [
  { key: "00", value: "00", text: "00" },
  { key: "05", value: "05", text: "05" },
  { key: "10", value: "10", text: "10" },
  { key: "15", value: "15", text: "15" },
  { key: "20", value: "20", text: "20" },
  { key: "25", value: "25", text: "25" },
  { key: "30", value: "30", text: "30" },
  { key: "35", value: "35", text: "35" },
  { key: "40", value: "40", text: "40" },
  { key: "45", value: "45", text: "45" },
  { key: "50", value: "50", text: "50" },
  { key: "55", value: "55", text: "55" },
];
class StatusChangeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateCurrentTime: "",
      rentalStartTime: "",
      customDateTime: null,
      customTime: null,
      customMinutes: null,
      customTimeAm: null,
      value: null,

    };
  }

  handleDateChange = (event, { name, value }) => {

    this.setState({ [name]: value });
    // if (this.state.hasOwnProperty(name)) {
    // }
  };
  handleSubmit = () => {
    const { value, updateCurrentTime, rentalStartTime, customDateTime, customTime, customTimeAm, customMinutes } = this.state;
    let customDateTimeAll = customDateTime + ',' + customTime + ':' + customMinutes + '' + customTimeAm;
    const { selectedCheckInCheckOutList, checkIn, orderState } = this.props;
    let checkOutStartTime;
    if (value === "customDateTime") {
      if (customDateTimeAll) {   
        checkOutStartTime = moment(customDateTimeAll, 'MMM DD, YYYY hh:mm A').format("YYYY-MM-DDTHH:mm:ss")}
      else {
        // checkOutStartTime
      }
    }
    else if (value === "rentalStartTime") {
      checkOutStartTime = moment(rentalStartTime, 'hh:mm A MMM DD,YYYY').format("YYYY-MM-DDTHH:mm:ss");
    }
    else if (value === "updateToCurrentTime") {
      checkOutStartTime = moment(updateCurrentTime).format("YYYY-MM-DDTHH:mm:ss");
    }

    if (!checkIn) {
      let checkedOut = selectedCheckInCheckOutList.map((item) => { return ({ "productDetailId": item.productDetailId, "checkOutStartTime": checkOutStartTime, "orderDetailId": item.orderDetailId }) });
      let data = {
        "checkedOut": checkedOut, "orderDetailId": this.props.selectedCheckInCheckOutList[0].orderDetailId, "productDetailId": this.props.selectedCheckInCheckOutList[0].productDetailId, "actionPerformedBy": this.props.auth.userDetail.emailId
      };

      this.props.actions.apiCall({
        urls: ["PUTUPDATEORDERCHECKINANDCHECKOUTTIME"], method: "PUT",
        showNotification: true,
        data: data,
        onSuccess: (response) => {
          this.setState({ orderChecknInCheckOutList: response });
          this.handleClose()
          this.props.actions.getOrderItems(orderState.currentOrder.orderId)
          return response
        }
      });
    }
    else {
      let checkedOut = selectedCheckInCheckOutList.map((item) => {
        return ({ "productDetailId": item.productDetailId, "checkInStartTime": checkOutStartTime, "orderDetailId": item.orderDetailId })
      });
      let data = {
        "checkedIn": checkedOut,
        "orderDetailId": this.props.selectedCheckInCheckOutList[0].orderDetailId,
        "productDetailId": this.props.selectedCheckInCheckOutList[0].productDetailId,
        "actionPerformedBy": this.props.auth.userDetail.emailId,
        endDate: checkOutStartTime,
      };
      this.props.actions.apiCall({
        urls: ["PUTUPDATEORDERCHECKINTIME"], method: "PUT",
        showNotification: true,
        data: data,
        onSuccess: (response) => {
          this.setState({ orderChecknInCheckOutList: response });
          this.handleClose()
          this.props.actions.getOrderItems(orderState.currentOrder.orderId)
          return response
        }
      });
    }

  }
  handleChange = (e, { value }) => this.setState({ value });

  compomentDidMount() {
    if (this.props.orderState) {
      this.getRentalStartTime()
    }
  }
  componentDidUpdate(prevProps, prevState) {

    if ((this.props.openModal !== prevProps.openModal)) {
      this.getRentalStartTime()
    }
  }

  getRentalStartTime = () => {
    const { orderState, checkIn, rentalStartTime } = this.props;
    // const nextState = orderState.currentOrder.orderDetails.filter((item, idx) => item.type === "Rental");
    if (!checkIn) {
      let newRentalStartTime = moment(rentalStartTime, 'MM/DD/YYYY hh:mm a').format('hh:mm A MMM DD,YYYY')
      this.setState({ rentalStartTime: newRentalStartTime })
    }
    else {
      this.setState({ rentalStartTime: "" })
    }
    this.setState({ updateCurrentTime: moment() })
  }

  handleClose = () => {
    this.setState({
      value: "", customDateTime: null, customTime: null,
      customTimeAm: null, customMinutes: null
    })
    this.props.closeModal()
  }

  render() {
    const { value, rentalStartTime, updateCurrentTime, customDateTime, customTimeAm, customTime, customMinutes } = this.state;
    return (
      <Modal open={this.props.openModal} closeIcon onClose={this.props.closeModal} size={"small"}>
        <Modal.Content>
          <Grid>
            <Grid.Column width={16}>
              <Form>
                <Form.Field>
                  {rentalStartTime && <Radio
                    label={<label disabled={value === "rentalStartTime" ? true : false}> <b className="orange-color">Rental Start Time </b> <br /> <small>{rentalStartTime}</small></label>}
                    className="custom-radio-btn "
                    value="rentalStartTime"
                    checked={value === "rentalStartTime"}
                    onChange={this.handleChange}
                  />}
                </Form.Field>
                <Form.Field>
                  <Radio
                    label={
                      <label
                      > <b className="orange-color"> Update to current time</b>
                        <br />
                        <small><Moment format="hh:mm A MMM DD,YYYY">{updateCurrentTime}</Moment></small>
                      </label>
                    }
                    className="custom-radio-btn"
                    value="updateToCurrentTime"
                    checked={value === "updateToCurrentTime"}
                    onChange={this.handleChange}
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    label={
                      <label>
                        <b className="orange-color"> Custom</b>
                      </label>
                    }
                    className="custom-radio-btn"
                    value="customDateTime"
                    checked={value === "customDateTime"}
                    onChange={this.handleChange}
                  />
                  <Grid
                    style={value !== "customDateTime" ? { pointerEvents: "none", opacity: "0.4", alignItems: "center" } : { alignItems: "center" }} >
                    <GridColumn width={5}>
                      <DateInput
                        dateFormat={"MMM DD, YYYY"}
                        timeFormat={"AMPM"}
                        name="customDateTime"
                        disable={value !== "customDateTime" ? true : false}
                        placeholder="Select Date"
                        value={customDateTime}
                        fluid search selection
                        onChange={this.handleDateChange}
                        className="change-status-modal-datetime"
                      />
                    </GridColumn>
                    <GridColumn width={4}>
                      <Dropdown
                        placeholder='Hour'
                        name="customTime"
                        value={customTime}
                        fluid search selection
                        defaultValue="am"
                        options={CustomTimefirst}
                        onChange={this.handleDateChange}
                      />
                    </GridColumn>
                    <GridColumn width={4}>
                      <Dropdown
                        placeholder='Minute'
                        name="customMinutes"
                        value={customMinutes}
                        fluid search selection
                        options={CustomTimetwo}
                        onChange={this.handleDateChange}
                      />
                    </GridColumn>

                    <GridColumn width={3}>
                      <Dropdown placeholder='AM'
                        name="customTimeAm"
                        value={customTimeAm}
                        fluid search selection
                        defaultValue="am"
                        options={CustomTimethree}
                        onChange={this.handleDateChange}
                      />
                    </GridColumn>
                  </Grid>
                </Form.Field>
              </Form>
            </Grid.Column>

            <Grid.Column width={16} textAlign="right">
              <Button className="blue-btn" onClick={this.props.closeModal}>Cancel</Button>
              <Button className="orange-btn" disabled={value === null ? true : value === "customDateTime" &&
                customDateTime === null ? true : false} onClick={() => this.handleSubmit()}>Save</Button>
            </Grid.Column>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    newOrderDetail: state.newOrderDetail,
    orderState: state.orderState,
    auth: state.auth
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      getOrderItems: bindActionCreators(actions.getOrderItems, dispatch),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(StatusChangeModal);