import React, { Component } from "react";
import { Grid, Modal, Button, Input } from "semantic-ui-react";
import { Link } from "react-router-dom";
import AddCommentModal from "../../modal/add-comment-modal";
import PrintInvoice from "./PrintInvoice";
import {
  connect,
  bindActionCreators,
  actions,
  env,
  commonFunctions,
} from "../../../../functional/global-import";
import { withRouter } from "react-router";

class PrintInvoiceModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noteRefrenceId: "",
      noteType: "",
      emailId: "",
      isEmailSending: false,
      printFunction: {},
      isMobTab: false,
    };
    this.validator = commonFunctions.initializeSimpleValidator();
  }

  handleChange = (e, { value }) => this.setState({ value });

  setPrintFunction = (receivedFunction) => {
    this.setState({ printFunction: receivedFunction });
  };

  openNotesModal = (data) => {
    this.setState({ commentStatus: !this.state.commentStatus });
  };

  getMobileOS = () => {
    const ua = navigator.userAgent;
    if (/android/i.test(ua)) {
      return "Android";
    } else if (
      /iPad|iPhone|iPod/.test(ua) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
    ) {
      return "iOS";
    }
    return "Other";
  };

  componentDidMount = () => {

    this.setState({ emailId: this.props.emailId });

    const os = this.getMobileOS();

    const isTabMob = os === "Android" || os === "iOS";

    if (isTabMob) {
      this.setState({ isMobTab: true });
    }

    this.props.actions.getOrderItems(this.props.orderId);
  };

  onEmailSend = (e) => {
    var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
    if (isFormValid) {
      this.setState({ isEmailSending: true });
      const { stateRef, orderId, auth } = this.props;
      stateRef.props.actions.apiCall({
        urls: ["ORDERSUMMARYPRINTANDEMAIL"],
        method: "GET",
        data: {
          applicationId: env.APPLICATION_ID,
          BusinessId: auth.userDetail.businessId,
          emailId: this.state.emailId,
          isViewOrPrint: false,
          orderId: orderId,
        },
        onSuccess: (response) => {
          this.props.closeModal();
          return response;
        },
        onFinally: () => {
          this.setState({ isEmailSending: false });
        },
        showNotification: true,
      });
    }
  };

  onEmailChange = (e) => {

    this.setState({ emailId: e.target.value });
  };
  render() {
    const {
      commentStatus,
      noteType,
      noteRefrenceId,
      emailId,
      isEmailSending,
    } = this.state;
    const { isRemoveOrder } = this.props
    return (
      <>
        <Modal
          open={this.props.openModal}
          closeIcon
          onClose={() => {
            isRemoveOrder &&  this.props.actions.removeOrder();
            this.props.closeModal();
          }}
          size={"tiny"}
        >
          <Modal.Content>
            <Grid>
              <PrintInvoice setPrintFunction={this.setPrintFunction} />
              <Grid.Column width={9} textAlign="right">
                <Link className="deepviolet-color">Receipt?</Link>
              </Grid.Column>

              <Grid.Column width={16} textAlign="center">
                {!this.props.api.isApiLoading ? (
                  <>
                    {this.state.isMobTab ? (
                      <a
                        style={{ textDecoration: "none" }}
                        href=""
                        onClick={this.state.printFunction.handlePrint}
                        id="print"
                      >

                        <i title="Print" class=" large print icon orangeColorPrinter"></i>

                      </a>
                    ) : (

                      <a onClick={this.state.printFunction.handlePrint}>
                        <i title="Print" class=" large print icon orangeColorPrinter"></i>
                      </a>
                    )}
                  </>
                ) : (
                  <>
                    <p>Loading...</p>
                  </>
                )}
              </Grid.Column>

              <Grid.Column width={16} textAlign="center">
                <Input
                  action={
                    <Button
                      loading={isEmailSending}
                      onClick={this.onEmailSend}
                      className="orange-btn"
                    >
                      Send
                    </Button>
                  }
                  icon="mail"
                  iconPosition="left"
                  onChange={this.onEmailChange}
                  value={emailId}
                  className="receipt-input"
                />
                <Grid.Column width={16} textAlign="center">
                  {this.validator.message("email",emailId,"required|email")}
                </Grid.Column>
              </Grid.Column>



            </Grid>
          </Modal.Content>
        </Modal>
        <AddCommentModal
          openModal={commentStatus}
          noteType={noteType}
          noteRefrenceId={noteRefrenceId}
          closeModal={this.openNotesModal}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    api: state.api,
    auth: state.auth,
    orderState: state.orderState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    actions: {
      // apiCall: bindActionCreators(actions.apiCall, dispatch),
      removeOrder: bindActionCreators(actions.removeOrder, dispatch),

      getOrderItems: bindActionCreators(actions.getOrderItems, dispatch),
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PrintInvoiceModal));
