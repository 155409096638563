import React from "react";
import { Step, Icon } from "semantic-ui-react";

const items = [
    {
        label: "Customer",
        icon: "check circle",
    },
    {
        label: "Type",
        icon: "check circle",
    },
    {
        label: "Documents",
        icon: "check circle",
    },
    {
        label: "Payment",
        icon: "check circle",
    },
    {
        label: "Status",
        icon: "check circle",
    },
];

class AppStepper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    renderSteps = (item, index) => {
        const { activeStep, changeStep } = this.props;

        return (
            <Step
                active={activeStep === index ? true : false}
                completed={activeStep > index ? true : false}
                onClick={() => changeStep(index)}
                key={index}
            >
                <Step.Content>
                    <Step.Title>
                        {item.label} &nbsp;
                        <Icon name={item.icon} className="green-check" />
                    </Step.Title>
                    <Step.Description>{index + 1}</Step.Description>
                </Step.Content>
            </Step>
        );
    }

    render() {
        return (
            <Step.Group
                stackable="tablet"
                className="custom-stepper order-stepper"
            >
                {items.map((item, i) => (this.renderSteps(item, i)))}
            </Step.Group>
        );
    }
}

export default AppStepper;