import React, { Component } from "react";
import { Grid, Image, Dimmer, Loader } from "semantic-ui-react";
import CategoryGridCard from "../../../../shared/components/organisms/category-grid-card";
import {
  AddCategoryModal,
  AddItemModal,
  AddActivityModal,
  AddBundleModal,
  MoveItemsModal,
  ItemAvailableModal,
  ActivityManifestModal,
  AddSeasonPassModal,
} from "../../../../shared/components";
import {
  connect,
  env,
  bindActionCreators,
  actions,
  commonFunctions,
} from "../../../../shared/functional/global-import";
import { emptyImg } from "../../../../shared/functional/global-image-import";
import { withRouter } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
// import {ItemAvailableModal} from "../../../"
class RentalCategoryGrid extends Component {
  constructor(props) {
    super(props);
             
    this.state = {
      count: 0,
      open: false,
      openModal: false,
      moveItemStatus: false,
      mainfiestStatus: false,
      addcateStatus: false,
      bundleDetail: [],
      itemDetail: [],
      activityDetail: [],
      itemStatus: false,
      productId: "",
      categoryId: "",
      itemType: "",
      SearchValue: this.props.searchValue,
      isSearchActive: false,
      isItemAvailableModal: false,
      copyLinkId: null,
      globalCodeIdActivity: commonFunctions.getGlobalCodeDetails(
        this.props.global.codes,
        "BusinessCategoryType",
        "Activity"
      ).globalCodeId,
      globalCodeIdRental: commonFunctions.getGlobalCodeDetails(
        this.props.global.codes,
        "BusinessCategoryType",
        "Rental"
      ).globalCodeId,
      globalCodeIdRetail: commonFunctions.getGlobalCodeDetails(
        this.props.global.codes,
        "BusinessCategoryType",
        "Retail"
      ).globalCodeId,
      // globalCodeIdBundle: commonFunctions.getGlobalCodeDetails(
      //   this.props.global.codes,
      //   "BusinessCategoryType",
      //   "Bundle"
      // ).globalCodeId,
      globalCodeIdSeasonPass: commonFunctions.getGlobalCodeDetails(
        this.props.global.codes,
        "BusinessCategoryType",
        "Season Pass"
      ).globalCodeId,
    };
             
  }
  AddCategoryModal = (categoryId, addEdit, type) => {
    this.setState({
      addcateStatus: !this.state.addcateStatus,
      openModal: false,
      categoryId: categoryId,
      addOrEdit: addEdit,
      categoryOrSub: type,
      editCategoryModal: true,
    });
  };

  AddItemModal = (productCategoryDetail, itemType) => {
                               
    if (itemType === "Rental") {
      this.setState({
        editItemModal: true,
        productId: productCategoryDetail,
        itemType: itemType,
        itemStatus: !this.state.itemStatus,
      });
    } else {
      this.setState({
        retailId: productCategoryDetail,
        itemType: itemType,
        editRetailDetail: true,
        itemStatus: !this.state.itemStatus,
      });
    }
  };
  AddActivityModal = (productCategoryDetail) => {
    this.setState({
      activityStatus: !this.state.activityStatus,
      activityId: productCategoryDetail,
    });
  };
  AddBundleModal = (productCategoryDetail) => {
    this.setState({
      bundleId: productCategoryDetail,
      editBundleModal: true,
      bundleStatus: !this.state.bundleStatus,
    });
  };

  SeasonPassAddModal = (productCategoryDetail) => {
    this.setState({
      seasonPassStatus: !this.state.seasonPassStatus,
      editSesonPassModal: true,
      seasonPassId: productCategoryDetail,
    });
  };
  MoveItemsModal = (productCategoryDetail) => {
    this.setState({
      moveItemStatus: !this.state.moveItemStatus,
      productId: productCategoryDetail.productId,
      //businessCategoryType : productCategoryDetail
    });
  };
  ActivityManifestModal = (productCategoryDetail) => {
    this.setState({
      mainfiestStatus: !this.state.mainfiestStatus,
      productId: productCategoryDetail.productId,
      name: productCategoryDetail.name,
    });
  };
  onCloseManifestModal = () => {
    this.setState({ mainfiestStatus: false });
  };
  addCategoryModalClose = () => {
    this.setState({ addcateStatus: false });
  };
  onCloseMoveItemsModal = () => {
    this.setState({ moveItemStatus: false });
  };
  onCategoryClick = (productCategoryDetail) => {
    let inventoryDetail = this.props.global.inventoryDetail;
    inventoryDetail.categoryId = productCategoryDetail.categoryId;
    inventoryDetail.productId = productCategoryDetail.productId;
    inventoryDetail.type = productCategoryDetail.type;
    inventoryDetail.parentId = productCategoryDetail.categoryId;
    inventoryDetail.retailTypeId = productCategoryDetail.retailTypeId;
    this.setState(
      { newOrderProductCategoryDetail: productCategoryDetail },
      () => {
        this.props.getBreadcrumb();
      }
    );
  };

  onCopyLinkClick = (productDetails) => {
    let businessCategoryId =
      productDetails.businessCategoryType === "Rental"
        ? this.state.globalCodeIdRental
        : productDetails.businessCategoryType === "Retail"
        ? this.state.globalCodeIdRetail
        : productDetails.businessCategoryType === "Activity"
        ? this.state.globalCodeIdActivity
        : productDetails.businessCategoryType === "Bundle"
        ? this.state.globalCodeIdBundle
        : productDetails.businessCategoryType === "Season Pass"
        ? this.state.globalCodeIdSeasonPass
        : null;
    if (businessCategoryId) {
      let newProductId = productDetails.productId;
      // let obj = {
      //   businessCategoryType: productDetails.businessCategoryType,
      //   businessCategoryId: businessCategoryId,
      //   categoryId: productDetails.categoryId,
      //   productId: newProductId
      // }

      navigator.clipboard
        .writeText(`businesscategoryid='${businessCategoryId}' productid='${newProductId}'
       categoryid='${productDetails.categoryId}' businessId='${productDetails.businessId}' 
       businessLocationId='${productDetails.businessLocationId}'
       class="individualproductbook"`);

      this.setState({ copyLinkId: newProductId });
      // this.props?.onCopyLinkClick()
    } else {
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const breadcrumbData = prevProps.breadcrumbData;
    if (this.state.isSearchActive !== prevProps.searchData) {
      let inventoryDetail = this.props.global.inventoryDetail;
      if (prevProps.searchData === true) {
        inventoryDetail.inventoryBreadcrumb = [
          inventoryDetail.inventoryBreadcrumb[0],
        ];
        breadcrumbData.map((breadcrumb) =>
          inventoryDetail.inventoryBreadcrumb.push({
            level: breadcrumb.level,
            breadcrumbName: breadcrumb.categoryName,
            type: breadcrumb.type,
            categoryId: breadcrumb.categoryId,
            parentId: breadcrumb.categoryId,
            productId: breadcrumb.productId,
          })
        );
      }
      this.props.actions.storeInventoryDetail(inventoryDetail);
      this.setState({ isSearchActive: prevProps.searchData });
      this.props.getProductCategoryDetail(true);
    }
    if (prevProps.productId && prevProps.searchData === true) {
      if (!this.props.handleShowGrid) {
        this.props.history.push({
          pathname: `${env.PUBLIC_URL}/inventory/inventory-item-list`,
        });
      } else {
        this.props.handleShowGrid(this.state.newOrderProductCategoryDetail);
      }
    }
  }

  onEditIconClick = (productCategoryDetail) => {
    if (
      (productCategoryDetail.productId > 0 &&
        productCategoryDetail.type === "Rental") ||
      productCategoryDetail.type === "Retail-Trackable" ||
      productCategoryDetail.type === "Retail-Bulk"
    ) {
      this.AddItemModal(
        productCategoryDetail.productId,
        productCategoryDetail.type,
        productCategoryDetail.retailTypeId
      );
    }
    if (productCategoryDetail.type === "Category") {
      this.AddCategoryModal(
        productCategoryDetail.categoryId,
        "Edit",
        "Category",
        false
      );
    }
    if (productCategoryDetail.type === "Subcategory") {
      this.AddCategoryModal(
        productCategoryDetail.categoryId,
        "Edit",
        "Sub Category",
        false
      );
    }
    if (
      this.props.global.inventoryDetail.businessCategoryId ===
        this.state.globalCodeIdActivity &&
      productCategoryDetail.type === "Activity"
    ) {
      this.AddActivityModal(productCategoryDetail.productId);
    }
    if (
      this.props.global.inventoryDetail.businessCategoryId ===
        this.state.globalCodeIdBundle &&
      productCategoryDetail.type === "Bundle"
    ) {
      this.AddBundleModal(productCategoryDetail.productId);
    }

    if (
      this.props.global.inventoryDetail.businessCategoryId ===
        this.state.globalCodeIdSeasonPass &&
      productCategoryDetail.type === "Season Pass"
    ) {
      this.SeasonPassAddModal(productCategoryDetail.productId);
    }
  };

  onItemMove = (click, productCategoryDetail) => {
    if (click) {
      this.MoveItemsModal(productCategoryDetail);
    }
  };

  mainfiest = (click, productCategoryDetail) => {
    if (click) {
      this.ActivityManifestModal(productCategoryDetail);
    }
  };
  onClickItemDetail = (e) => {
    this.setState({
      isItemAvailableModal: !this.state.isItemAvailableModal,
      productAvailabilityDetails: e,
    });
  };
  render() {
    const {
      editCategoryModal,
      addcateStatus,
      activityStatus,
      bundleStatus,
      moveItemStatus,
      mainfiestStatus,
      categoryId,
      itemStatus,
      bundleId,
      seasonPassId,
      seasonPassStatus,
      retailId,
      itemDetail,
      productId,
      activityDetail,
      copyLinkId,
      name,
      activityId,
      addOrEdit,
      categoryOrSub,
      isItemAvailableModal,
      productAvailabilityDetails,
      productCategoryDetail,
    } = this.state;
    const {
      productCategoryDetails,
      getProductCategoryDetail,
      responseStored,
      openCategory,
      orderInventoryStateRef,
      fetchMoreData,
    } = this.props;

    const businessCategoryId =
      this.props.global.inventoryDetail.businessCategoryId;

    const rentalGlobalId = commonFunctions.getGlobalCodeDetails(
      this.props.global.codes,
      "BusinessCategoryType",
      "Rental"
    )?.globalCodeId;

    const retailGlobalId = commonFunctions.getGlobalCodeDetails(
      this.props.global.codes,
      "BusinessCategoryType",
      "Retail"
    )?.globalCodeId;
    return (
      <React.Fragment>
        <div className="inventorySpace">
          <InfiniteScroll
            dataLength={productCategoryDetails.length}
            scrollableTarget={`${"scrollable" + "CategoryGrid"}`}
            next={fetchMoreData}
            hasMore={true}
          >
            <Grid>
              {this.props.gridDataLoading && (
                <Dimmer active inverted>
                  <Loader size="small">Loading</Loader>{" "}
                </Dimmer>
              )}
              {responseStored === true && productCategoryDetails.length === 0 && (
                <Grid.Column width={16} className="categoryErrorOuter">
                  <div className="categoryError">
                    {this.props.global.inventoryDetail.categoryId === null &&
                      businessCategoryId === rentalGlobalId && (
                        <h1>You have no categories or items</h1>
                      )}
                    {this.props.global.inventoryDetail.categoryId > 0 &&
                      businessCategoryId === rentalGlobalId && (
                        <h1>You have no Subcategories or items</h1>
                      )}
                    {this.props.global.inventoryDetail.categoryId === null &&
                      businessCategoryId === retailGlobalId && (
                        <h1>You have no categories or items</h1>
                      )}
                    {this.props.global.inventoryDetail.categoryId > 0 &&
                      businessCategoryId === retailGlobalId && (
                        <h1>You have no Subcategories or items</h1>
                      )}
                    {businessCategoryId === this.state.globalCodeIdActivity &&
                      this.props.global.inventoryDetail.categoryId === null && (
                        <h1>You have no categories or activities</h1>
                      )}
                    {businessCategoryId === this.state.globalCodeIdBundle &&
                      this.props.global.inventoryDetail.categoryId === null && (
                        <h1>You have no categories or bundles</h1>
                      )}
                    {businessCategoryId === this.state.globalCodeIdActivity &&
                      this.props.global.inventoryDetail.categoryId > 0 && (
                        <h1>You have no Sub-categories or activities</h1>
                      )}
                    {businessCategoryId === this.state.globalCodeIdBundle &&
                      this.props.global.inventoryDetail.categoryId > 0 && (
                        <h1>You have no Sub-categories or bundles</h1>
                      )}
                    {businessCategoryId === this.state.globalCodeIdSeasonPass &&
                      this.props.global.inventoryDetail.categoryId === null && (
                        <h1>You have no categories or passes</h1>
                      )}
                    {businessCategoryId === this.state.globalCodeIdSeasonPass &&
                      this.props.global.inventoryDetail.categoryId > 0 && (
                        <h1>You have no Sub-categories or passes</h1>
                      )}
                    <Image className="emptyError" src={emptyImg}></Image>{" "}
                  </div>
                </Grid.Column>
              )}
              <Grid.Row
                columns={this.props.handleShowGrid ? 3 : 4}
                className="responsive-three-column"
              >
                {productCategoryDetails.map((productCategoryDetail, index) => (
                  <Grid.Column key={index}>
                    <CategoryGridCard
                      copyLinkId={copyLinkId}
                      onClickItemDetail={() =>
                        this.onClickItemDetail(productCategoryDetail)
                      }
                      handleShowGrid={this.props.handleShowGrid}
                      color={productCategoryDetail.color}
                      imageUrl={productCategoryDetail.image}
                      categoryName={productCategoryDetail.name}
                      type={productCategoryDetail.type}
                      subCategoryItems={productCategoryDetail.count}
                      totalItems={productCategoryDetail.itemCount}
                      wrapped
                      onDeleteIconClick={() =>
                        openCategory(productCategoryDetail)
                      }
                      categoryDetail={productCategoryDetail}
                      ui={false}
                      onItemMove={() =>
                        this.onItemMove(true, productCategoryDetail)
                      }
                      mainfiest={() =>
                        this.mainfiest(true, productCategoryDetail)
                      }
                      onCategoryClick={() =>
                        this.onCategoryClick(productCategoryDetail)
                      }
                      onEditIconClick={() =>
                        this.onEditIconClick(
                          productCategoryDetail,
                          productCategoryDetail.type
                        )
                      }
                      onCopyLinkClick={() =>
                        this.onCopyLinkClick(productCategoryDetail)
                      }
                      index={index}
                    />
                  </Grid.Column>
                ))}
              </Grid.Row>
            </Grid>
          </InfiniteScroll>
          <>
            {isItemAvailableModal && (
              <ItemAvailableModal
                openModal={isItemAvailableModal}
                productAvailabilityDetails={productAvailabilityDetails}
                orderInventoryStateRef={orderInventoryStateRef}
                closeModal={() => this.setState({isItemAvailableModal: false })
                }
              />
            )}
            {addcateStatus && (
              <AddCategoryModal
              editCategoryModal={editCategoryModal}
              categoryId={categoryId}
              categoryOrSub={categoryOrSub}
              addOrEdit={addOrEdit}
              openModal={addcateStatus}
              closeModal={this.addCategoryModalClose}
              getProductCategoryDetail={getProductCategoryDetail}
              />
            )}
            {itemStatus && (
              <AddItemModal
                productId={productId}
                editItemModal={this.state.editItemModal}
                itemDetail={itemDetail}
                openModal={itemStatus}
                getProductCategoryDetail={getProductCategoryDetail}
                editRetailDetail={this.state.editRetailDetail}
                itemType={this.state.itemType}
                retailId={retailId}
                closeModal={this.AddItemModal}
              />
            )}
            {activityStatus && (
              <AddActivityModal
                openModal={activityStatus}
                closeModal={this.AddActivityModal}
                activityDetail={activityDetail}
                activityId={activityId}
                editActivityModal={true}
                getProductCategoryDetail={getProductCategoryDetail}
              />
            )}
            {bundleStatus && (
              <AddBundleModal
                editBundleModal={this.state.editBundleModal}
                bundleId={bundleId}
                openModal={bundleStatus}
                closeModal={this.AddBundleModal}
                getProductCategoryDetail={getProductCategoryDetail}
              />
            )}
            {seasonPassStatus && (
              <AddSeasonPassModal
                editSesonPassModal={this.state.editSesonPassModal}
                seasonPassId={seasonPassId}
                openModal={seasonPassStatus}
                closeModal={this.SeasonPassAddModal}
                getProductCategoryDetail={getProductCategoryDetail}
              />
            )}

            {moveItemStatus && (
              <MoveItemsModal
                openModal={moveItemStatus}
                closeModal={this.onCloseMoveItemsModal}
                productId={productId}
                defaultValue={
                  this.props.global.locationSelected.BusinessLocationId
                }
                getProductCategoryDetail={getProductCategoryDetail}
              />
            )}
            {mainfiestStatus && (
              <ActivityManifestModal
                openModal={mainfiestStatus}
                closeModal={this.onCloseManifestModal}
                productId={productId}
                name={name}
                defaultValue={
                  this.props.global.locationSelected.BusinessLocationId
                }
                getProductCategoryDetail={getProductCategoryDetail}
                productCategoryDetail={productCategoryDetail}
              />
            )}
          </>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      storeInventoryDetail: bindActionCreators(
        actions.storeInventoryDetail,
        dispatch
      ),
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RentalCategoryGrid));
