import React, { Component } from "react";
import { Button, Grid, Modal, Label, Header, Form, Icon, Popup } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { connect, bindActionCreators, actions, commonFunctions, env, Notifications } from "../../../../../../functional/global-import";


class AddDepositAmount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderId: this.props.orderState.currentOrder.orderId,
            amount: null,
            actionPerformedBy: this.props.auth.userDetail.emailId,
        };
        this.validator = commonFunctions.initializeSimpleValidator();
    }
    handleSubmit = (e) => {
        var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
        if (isFormValid) {
            this.props.actions.apiCall({
                urls: ["ADDORDERPRICEDEPOSIT"], method: "POST", showNotification: true,
                data: this.state, onSuccess: (response) => {
                    
                    this.props.closeModal();
                    this.props.actions.getOrderItems(this.props.orderState.currentOrder.orderId);
                    return response
                },
            });
        }
        else {
            return false
        }
    }

    onHandleChange = (e, { name, value, type, checked, data, }) => {
        var paymentTypeForm = commonFunctions.onHandleChange(e, { name, value, type, checked, data }, this.state.paymentTypeForm, this);
        this.setState({ paymentTypeForm });
    };

    handleChange = (e, { value }) => {
        const newAmount = parseFloat(value);
        if (newAmount >= 0) {
            this.setState({ amount: newAmount })}
        else {
            this.setState({ amount: null })}};
    render() {
        const { amount } = this.state;
        const { api } = this.props;
        return (
            <Modal closeOnDimmerClick={false} open={this.props.openModal} closeIcon onClose={this.props.closeModal} size={"mini"}>
                <Modal.Content>
                {api.isApiLoading && commonFunctions.showLoader()}
                    <Grid>
                        <Grid.Column width={8} verticalAlign="middle">
                            <Link className="orange-color"> Add Deposit</Link>
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <Form.Input labelPosition="left" type='number' className="amountInput"
                                error={this.validator.message("amount", amount, "required|min:1,num")}
                                placeholder='Amount' value={amount} onChange={this.handleChange}>
                                <Label basic>$</Label>
                                <input />
                            </Form.Input>
                        </Grid.Column>
                        <Grid.Column width={16} textAlign="right">
                            <Button className="blue-btn" onClick={this.props.closeModal}>
                                Cancel
                            </Button>
                            <Button className="blue-btn" loading={api.isApiLoading} onClick={this.handleSubmit} >Add Amount</Button>
                        </Grid.Column>
                    </Grid>
                </Modal.Content>
            </Modal>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        api: state.api,
        auth: state.auth,
        orderState: state.orderState
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        actions: {
            apiCall: bindActionCreators(actions.apiCall, dispatch),
            getOrderItems: bindActionCreators(actions.getOrderItems, dispatch),
            showNotification: bindActionCreators(Notifications.show, dispatch),
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddDepositAmount);