const paymentAmount = (itemDetailsGridState, orderState, itemOrBalDue) => {
  let totalPayment = "";
  if (
    itemDetailsGridState.itemDesposit &&
    itemDetailsGridState.itemProductName
  ) {
    if (itemDetailsGridState.selectedFees === false) {
      if (orderState.currentOrder.selectedItemsWithAmount[0].feePrice) {
        totalPayment =
          orderState.currentOrder.pricings.BalanceDue -
          orderState.currentOrder.selectedItemsWithAmount[0].feePrice;
      } else {
        totalPayment = orderState.currentOrder.pricings.BalanceDue;
      }
    } else {
      totalPayment = orderState.currentOrder.pricings.BalanceDue;
    }
  } else if (itemDetailsGridState.selectedFees) {
    if (
      orderState.currentOrder.depositStatus !== "Paid" &&
      itemDetailsGridState.itemDesposit
    ) {
      totalPayment =
        orderState.currentOrder.deposit +
        orderState.currentOrder.selectedItemsWithAmount[0].feePrice;
    } else {
      totalPayment =
        orderState.currentOrder.selectedItemsWithAmount[0].feePrice;
    }
  } else if (itemDetailsGridState.itemDesposit) {
    if (orderState.currentOrder.depositStatus !== "Paid") {
      totalPayment = orderState.currentOrder.deposit;
    } else if (orderState.currentOrder.depositStatus === "Paid") {
      if (!itemDetailsGridState.itemProductName) {
        totalPayment = 0;
      } else {
        totalPayment = orderState.currentOrder.pricings.BalanceDue;
      }
    }
  }

  // for discount and coupon modal total payment if SelectedItem checkbox selected
  else if (
    itemDetailsGridState.itemProductName &&
    itemOrBalDue === "SelectedItem"
  ) {
    totalPayment =
      orderState.currentOrder.selectedItemsWithAmount[0].itemAmount;
  } else if (itemOrBalDue === "BalanceDue") {
    if (orderState.currentOrder.pricings.DiscountAndCoupons) {
      totalPayment =
        orderState.currentOrder.pricings.DiscountAndCoupons &&
        orderState.currentOrder.pricings.BalanceDue +
          orderState.currentOrder.pricings.DiscountAndCoupons[0].DiscountAmount;
    } else {
      totalPayment = orderState.currentOrder.pricings.BalanceDue;
    }
  } else {
    totalPayment = orderState.currentOrder.pricings.BalanceDue;
  }
  return totalPayment;
};

const rentalCartTimeConvert = (rentalTime) => {
  let formatted = "";
  if (rentalTime) {
    let duration = rentalTime && rentalTime;

    let week = duration[0].WeekTime;
    week = week > 0 ? (week > 1 ? `${week} weeks` : `${week} week`) : "";

    let year = duration[0].YearTime;
    year = year > 0 ? (year > 1 ? `${year} years` : `${year} year`) : "";

    let month = duration[0].MonthTime;
    month = month > 0 ? (month > 1 ? `${month} months` : `${month} month`) : "";

    let day = duration[0].DayTime;
    day = day > 0 ? (day > 1 ? `${day} days` : `${day} day`) : "";

    let hour = duration[0].HourTime;
    hour = hour > 0 ? (hour > 1 ? `${hour} hours` : `${hour} hour`) : "";

    let minute = duration[0].MinuteTime;
    minute =
      minute > 0 ? (minute > 1 ? `${minute} minutes` : `${minute} minute`) : "";

    formatted =
      `${year}${month}${week}${day}${hour}${minute}` === ""
        ? null
        : `${year} ${month} ${week} ${day} ${hour} ${minute}`;
  }
  return formatted;
};

const timeFrameRange = (timeFrame) => {
  if (timeFrame.timeRangeName === "Custom Time") {
    let time = timeFrame.timing.split(" - ");
    return { timeRangeFrom: time[0], timeRangeTo: time[1] };
  }
  if (timeFrame.timeRangeName === "Time Slots") {
    let time = timeFrame.timing.split(" - ");
    return { timeRangeFrom: time[0], timeRangeTo: time[1] };
  }
};

const getJsonOfSelectedDepositCheckboxes = (orderState) => {
  let selectedDeposit = [];
  orderState.currentOrder.selectedItemsWithAmount.map((rentalObject) => {
    if (rentalObject.Deposit && rentalObject.rentalDepositCheckbox) {
      selectedDeposit.push({
        orderDetailId: rentalObject.OrderDetailId,
        producttype: "PD",
      });
    }
    return {
      ...rentalObject,
    };
  });
  orderState.currentOrder.orderDepositCheckbox &&
    selectedDeposit.push({
      orderDetailId: orderState.currentOrder.orderId,
      producttype: "OD",
    });

  return selectedDeposit;
};

const getJsonOfSelectedCheckboxes = (orderState) => {
  //  for rental
  let orderDetail = [];
  orderState.currentOrder.selectedItemsWithAmount.map((rentalObject) => {
    rentalObject.ItemAddons =
      rentalObject.ItemAddons &&
      rentalObject.ItemAddons.map((itemAddonObj) => {
        if (itemAddonObj.rentalItemAddon) {
          orderDetail.push({
            orderDetailId: itemAddonObj.OrderDetailid,
            orderDetailAddonId: itemAddonObj.OrderDetailAddonId,
            producttype: "PA",
          });
        }
        return {
          ...itemAddonObj,
        };
      });
    if (rentalObject.rentalItemCheckbox) {
      orderDetail.push({
        orderDetailId: rentalObject.OrderDetailId,
        orderDetailAddonId: 0,
        producttype: "P",
      });
    }
    return { ...rentalObject };
  });

  // // for retail
  orderState.currentOrder.selectedRetailItem.map((retailObject) => {
    retailObject.RetailAddons =
      retailObject.RetailAddons &&
      retailObject.RetailAddons.map((retailAddonObj) => {
        if (retailAddonObj.retailItemAddon) {
          orderDetail.push({
            orderDetailId: retailAddonObj.OrderDetailid,
            orderDetailAddonId: retailAddonObj.OrderDetailAddonId,
            producttype: "RA",
          });
        }
        return {
          ...retailAddonObj,
        };
      });

    if (retailObject.retailItemCheckbox) {
      orderDetail.push({
        orderDetailId: retailObject.OrderDetailId,
        orderDetailAddonId: 0,
        producttype: "R",
      });
    }
    return { ...retailObject };
  });
  //  for activity
  orderState.currentOrder.selectedActivityItems.map((activityData) => {
    activityData.ActivityAddons =
      activityData.ActivityAddons &&
      activityData.ActivityAddons.map((activityAddonObj) => {
        if (activityAddonObj.activityItemAddon) {
          orderDetail.push({
            orderDetailId: activityAddonObj.OrderDetailid,
            orderDetailAddonId: activityAddonObj.OrderDetailAddonId,
            producttype: "AA",
          });
        }
        return {
          ...activityAddonObj,
        };
      });

    if (activityData.activityItemCheckbox) {
      orderDetail.push({
        orderDetailId: activityData.OrderDetailId,
        orderDetailAddonId: 0,
        producttype: "A",
      });
    }
    return {
      ...activityData,
    };
  });

  //  for bundle
  orderState.currentOrder.selectedBundleItems.map((bundleData) => {
    if (bundleData.bundleItemCheckbox) {
      orderDetail.push({
        orderDetailId: bundleData.OrderDetailId,
        orderDetailAddonId: 0,
        producttype: "B",
      });
    }
    return {
      ...bundleData,
    };
  });

  //    for seasonPass
  orderState.currentOrder.seasonPassItem.map((seasonPassData) => {
    seasonPassData.SeasonPassAddons =
      seasonPassData.SeasonPassAddons &&
      seasonPassData?.SeasonPassAddons?.map((seasonAddonObj) => {
        if (seasonAddonObj.seasonItemAddon) {
          orderDetail.push({
            orderDetailId: seasonAddonObj.OrderDetailid,
            orderDetailAddonId: seasonAddonObj.OrderDetailAddonId,
            producttype: "SA",
          });
        }
        return {
          ...seasonAddonObj,
        };
      });

    if (seasonPassData.seasonItemCheckbox) {
      orderDetail.push({
        orderDetailId: seasonPassData.OrderDetailId,
        orderDetailAddonId: 0,
        producttype: "S",
      });
    }
    return { ...seasonPassData };
  });

  // // for order add on
  orderState.currentOrder.pricings.Addons &&
    orderState.currentOrder.pricings.Addons.map((orderAddOnObj) => {
      if (orderAddOnObj.orderAddonCheckbox) {
        orderDetail.push({
          orderDetailId: orderAddOnObj.OrderAddOnId,
          orderDetailAddonId: 0,
          producttype: "OA",
        });
      }
    });
  return orderDetail;
};

const themeColor = () => {
  const theme = {
    primary: {
      light: "#f54952",
      main: "#f54952",
      dark: "#f54952",
    },
    grey: {
      700: "#707070",
      900: "#1b1b1d",
    },
    background: {
      default: "#FFFFFF",
    },
    text: {
      disabled: "#BABABA",
    },
  };
  return theme;
};

const onEditCustomQuestions = (response) => {
  const questiondetails =
    response &&
    response.map((customObj) => {
      if (customObj.associateAddOn) {
        customObj.associateAddOn = JSON.parse(customObj.associateAddOn);
      }
      return {
        ...customObj,
        isQuestionAnswered: "",
      };
    });

  const newQuestiondetails =
    questiondetails &&
    questiondetails.map((customObj) => {
      if (customObj.response) {
        if (
          customObj.customQuestionType === "Text Field" ||
          customObj.customQuestionType === "Checkbox"
        ) {
          customObj.customQuestionOptionValue =
            customObj.customQuestionOptionValue &&
            JSON.parse(customObj.customQuestionOptionValue).map((object) => {
              object.CustomQuestionOptionValue =
                customObj.response[0] &&
                JSON.parse(customObj.response)[0].TextValue;
              object.IsChecked = true;

              return {
                ...object,
              };
            });
          customObj.isQuestionAnswered =
            customObj.response[0] &&
            JSON.parse(customObj.response)[0].TextValue;
        } else if (customObj.customQuestionType.indexOf("Single") > -1) {
          customObj.customQuestionOptionValue =
            customObj.customQuestionOptionValue &&
            JSON.parse(customObj.customQuestionOptionValue).map((object) => {
              if (
                object.customQuestionOptionId ===
                JSON.parse(customObj.response)[0].CustomQuestionOptionId
              ) {
                object.IsChecked = true;
              }
              return {
                ...object,
              };
            });
          customObj.isQuestionAnswered = "answered";
        } else if (customObj.customQuestionType === "Multiple Choice") {
          customObj.customQuestionOptionValue =
            customObj.customQuestionOptionValue &&
            JSON.parse(customObj.customQuestionOptionValue).map((object) => {
              customObj.response[0] &&
                JSON.parse(customObj.response).map((responseObj) => {
                  if (
                    responseObj.CustomQuestionOptionId ===
                    object.customQuestionOptionId
                  ) {
                    object.IsChecked = true;
                  }
                });
              return {
                ...object,
              };
            });
          customObj.isQuestionAnswered = "answered";
        }

        return {
          ...customObj,
        };
      } else {
        customObj.customQuestionOptionValue =
          customObj.customQuestionOptionValue &&
          JSON.parse(customObj.customQuestionOptionValue);
        return {
          ...customObj,
        };
      }
    });
  return newQuestiondetails;
};

const onAllCheckCheckboxClick = (orderState, checked, componentThis) => {
  orderState.currentOrder.checkAllItemDetails = checked;
  // orderState.currentOrder.orderDepositCheckbox = checked;

  //  for rental
  orderState.currentOrder.selectedItemsWithAmount.map((rentalObject) => {
    !rentalObject.paidAmount &&
      !rentalObject.rentalItemCheckbox &&
      componentThis.onCheckBoxClick(
        checked,
        rentalObject.ItemAmount,
        rentalObject.OrderDetailId
      );

    !rentalObject.paidAmount &&
      !checked &&
      componentThis.onCheckBoxClick(
        checked,
        rentalObject.ItemAmount,
        rentalObject.OrderDetailId
      ); // in case for allcheck fasle

    rentalObject.ItemAddons =
      rentalObject.ItemAddons &&
      rentalObject.ItemAddons.map((itemAddonObj) => {
        !itemAddonObj.paidAmount &&
          !itemAddonObj.rentalItemAddon &&
          componentThis.onCheckBoxClick(
            checked,
            itemAddonObj.TotalAmount,
            itemAddonObj.OrderDetailAddonId
          );

        !itemAddonObj.paidAmount &&
          !checked &&
          componentThis.onCheckBoxClick(
            checked,
            itemAddonObj.TotalAmount,
            itemAddonObj.OrderDetailAddonId
          ); // in case for allcheck fasle

        if (itemAddonObj.paidAmount) {
          return {
            ...itemAddonObj,
          };
        } else {
          return {
            ...itemAddonObj,
            rentalItemAddon: checked,
          };
        }
      });
    rentalObject.rentalItemCheckbox = checked;
    // rentalObject.rentalDepositCheckbox = checked;
    return { ...rentalObject };
  });
  // for retail
  orderState.currentOrder.selectedRetailItem.map((retailObject) => {
    !retailObject.paidAmount &&
      !retailObject.retailItemCheckbox &&
      componentThis.onCheckBoxClick(
        checked,
        retailObject.TotalAmount,
        retailObject.OrderDetailId
      );

    !retailObject.paidAmount &&
      !checked &&
      componentThis.onCheckBoxClick(
        checked,
        retailObject.TotalAmount,
        retailObject.OrderDetailId
      ); // in case for allcheck fasle

    retailObject.RetailAddons =
      retailObject.RetailAddons &&
      retailObject.RetailAddons.map((retailAddonObj) => {
        !retailAddonObj.paidAmount &&
          !retailAddonObj.retailItemAddon &&
          componentThis.onCheckBoxClick(
            checked,
            retailAddonObj.TotalAmount,
            retailAddonObj.OrderDetailAddonId
          );

        !retailAddonObj.paidAmount &&
          !checked &&
          componentThis.onCheckBoxClick(
            checked,
            retailAddonObj.TotalAmount,
            retailAddonObj.OrderDetailAddonId
          ); // in case for allcheck fasle

        if (retailAddonObj.paidAmount) {
          return {
            ...retailAddonObj,
          };
        } else {
          return {
            ...retailAddonObj,
            retailItemAddon: checked,
          };
        }
      });
    retailObject.retailItemCheckbox = checked;
    return { ...retailObject };
  });
  //  for activity
  orderState.currentOrder.selectedActivityItems.map((activityData) => {
    !activityData.paidAmount &&
      !activityData.activityItemCheckbox &&
      componentThis.onCheckBoxClick(
        checked,
        activityData.Amount,
        activityData.OrderDetailId
      );

    !activityData.paidAmount &&
      !checked &&
      componentThis.onCheckBoxClick(
        checked,
        activityData.Amount,
        activityData.OrderDetailId
      ); // // in case for allcheck fasle

    //  for activity addon
    activityData.ActivityAddons =
      activityData.ActivityAddons &&
      activityData.ActivityAddons.map((activityAddonObj) => {
        !activityAddonObj.paidAmount &&
          !activityAddonObj.activityItemAddon &&
          componentThis.onCheckBoxClick(
            checked,
            activityAddonObj.TotalAmount,
            activityAddonObj.OrderDetailAddonId
          );

        !activityAddonObj.paidAmount &&
          !checked &&
          componentThis.onCheckBoxClick(
            checked,
            activityAddonObj.TotalAmount,
            activityAddonObj.OrderDetailAddonId
          ); // in case for allcheck fasle

        if (activityAddonObj.paidAmount) {
          return {
            ...activityAddonObj,
          };
        } else {
          return {
            ...activityAddonObj,
            activityItemAddon: checked,
          };
        }
      });

    activityData.activityItemCheckbox = checked;
    return { ...activityData };
  });
  //  for bundle
  orderState.currentOrder.selectedBundleItems.map((bundleData) => {
    !bundleData.paidAmount &&
      !bundleData.bundleItemCheckbox &&
      componentThis.onCheckBoxClick(
        checked,
        bundleData.TotalAmount,
        bundleData.OrderDetailId
      );

    !bundleData.paidAmount &&
      !checked &&
      componentThis.onCheckBoxClick(
        checked,
        bundleData.TotalAmount,
        bundleData.OrderDetailId
      ); // in case for allcheck fasle

    bundleData.bundleItemCheckbox = checked;
    return { ...bundleData };
  });
  //   for season pass
  orderState.currentOrder.seasonPassItem.map((seasonPassData) => {
    !seasonPassData.paidAmount &&
      !seasonPassData.seasonItemCheckbox &&
      componentThis.onCheckBoxClick(
        checked,
        seasonPassData.TotalAmount,
        seasonPassData.OrderDetailId
      );

    !seasonPassData.paidAmount &&
      !checked &&
      componentThis.onCheckBoxClick(
        checked,
        seasonPassData.TotalAmount,
        seasonPassData.OrderDetailId
      ); // in case for allcheck fasle

    seasonPassData.SeasonPassAddons =
      seasonPassData.SeasonPassAddons &&
      seasonPassData.SeasonPassAddons.map((seasonAddonObj) => {
        !seasonAddonObj.paidAmount &&
          !seasonAddonObj.seasonItemAddon &&
          componentThis.onCheckBoxClick(
            checked,
            seasonAddonObj.TotalAmount,
            seasonAddonObj.OrderDetailAddonId
          );

        !seasonAddonObj.paidAmount &&
          !checked &&
          componentThis.onCheckBoxClick(
            checked,
            seasonAddonObj.TotalAmount,
            seasonAddonObj.OrderDetailAddonId
          ); // in case for allcheck fasle

        if (seasonAddonObj.paidAmount) {
          return {
            ...seasonAddonObj,
          };
        } else {
          return {
            ...seasonAddonObj,
            seasonItemAddon: checked,
          };
        }
      });
    seasonPassData.seasonItemCheckbox = checked;
    return { ...seasonPassData };
  });
  return orderState;
};

const onValidateMinMaxPerItem = (productCount, min, max) => {
  const isMaxHaveValue = max === null ? false : true;
  const isMaxConditionFulfiled = isMaxHaveValue ? productCount <= max : true;
  if (productCount >= min && isMaxConditionFulfiled) {
    return true;
  } else {
    return false;
  }
};
const onSelectingDefaultDetail = (arrayDetails, defaultDetail, fieldName) => {
    
  const defaultReaderDetails = arrayDetails.filter(
    (singleReaderObj) => singleReaderObj[fieldName] === defaultDetail
  );
  const readerName =
    defaultReaderDetails.length > 0
      ? defaultReaderDetails[0].value
      : arrayDetails[0].value;
  return readerName;
};
// const onSelectingDefaultCounter = (counterDetails,defaultCounter) => {
//     
// };

export const orderCommonFunctions = {
  paymentAmount,
  timeFrameRange,
  rentalCartTimeConvert,
  getJsonOfSelectedCheckboxes,
  getJsonOfSelectedDepositCheckboxes,
  themeColor,
  onEditCustomQuestions,
  onAllCheckCheckboxClick,
  onValidateMinMaxPerItem,
  onSelectingDefaultDetail,
};
