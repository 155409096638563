import React, { Component } from 'react';
import { Grid, Header, Modal, Table, List, Form, Select, Button, Dimmer, Loader } from 'semantic-ui-react';
import { DateInput } from "semantic-ui-calendar-react";
import { commonFunctions, connect, bindActionCreators, actions, Notifications} from "../../../../functional/global-import";
import moment from 'moment';

class ActivityManifestModal extends Component {
    get initialState() {
        return {
            activityManifestData: {
                activityId: "",
                activityDate: "",
                activitySlotTime: "",
            }
        };
    }

    constructor(props) {
        
        super(props);
        this.state = { ...this.initialState, activityOptions: [], activitySlotData: [], activityManifest: [], orderDetails: [], dataLoader: false }
        this.validator = commonFunctions.initializeSimpleValidator();
    }

    onHandleChange = (e, { name, value, type, checked, data }) => {

        var activityManifestData = commonFunctions.onHandleChange(e, { name, value, type, checked, data }, this.state.activityManifestData);
        if (data === "activityName") {
            activityManifestData.activityId = value
        }
        if (data === "activityDate") {
            activityManifestData.activityDate = value
            this.getActivityTime(value)
        }
        if (data === "activitySlot") {
            activityManifestData.activitySlotTime = value
            this.getActivityManifest(value)
        }
        this.setState({ activityManifestData });
    }

    componentDidMount() {
        this.getActivityName()
    }

    getActivityName = () => {
        this.props.actions.apiCall({
            urls: ["GETACTIVITYNAME"],
            method: "GET",
            data: {
                businessId: this.props.global.bussinessDetail.businessId,
                businessLocationId: this.props.global.locationSelected.BusinessLocationId,
                ActivityId: -1
            },
            onSuccess: (response) => {
                let activityData = response.map((post) => {
                    return {
                        text: post.activityName, value: post.activityId,
                        id: post.activityId, key: post.activityName
                    }
                });
                this.setState({ activityOptions: activityData })
            },
        });
    }

    getActivityTime = (activityStartDate) => {
        this.props.actions.apiCall({
            urls: ["GETACTIVITYTIME"],
            method: "GET",
            data: {
                StartDate: activityStartDate,
                ActivityId: this.state.activityManifestData.activityId
            },
            onSuccess: (response) => {
                let activityTime = JSON.parse(response[0].timeSlots).map((post) => {
                    return {
                        text: post.TimeSlotAvailable, value: post.TimeSlotAvailable,
                        id: post.TimeSlotAvailable, key: post.TimeSlotAvailable
                    }
                });
                this.setState({ activitySlotData: activityTime })
            },
        });
    }

    getActivityManifest = (activitySlotTime) => {
        this.setState({ dataLoader: true })
        this.props.actions.apiCall({
            urls: ["GETACTIVITYMANIFEST"],
            method: "GET",
            data: {
                SelectDate: this.state.activityManifestData.activityDate,
                ActivityId: this.state.activityManifestData.activityId,
                Time: activitySlotTime
            },
            onSuccess: (response) => {
                let order = response.map((x) => {
                    return (
                        JSON.parse(x.orderDetails)[0]
                    )
                })
                this.setState({ activityManifest: response[0], orderDetails: order, dataLoader: false })
            },
        });
    }

    resetHandle = () => {
        this.setState({ activityManifest: [], orderDetails: [], })
        this.setState(this.initialState);
    }

    render() {
        const { activityOptions, activitySlotData, activityManifestData, orderDetails, activityManifest } = this.state;

        return (
            <Modal
                open={this.props.openModal}
                closeIcon
                onClose={this.props.closeModal}
               >
                {/* <Header content="Move Item" /> */}
                <Modal.Content scrolling>
                    <div className="cmn-shad">
                        <Grid>
                            <Grid.Column width={14} verticalAlign="middle">
                                <Header as="h1">Activity Slot Manifest</Header>
                            </Grid.Column>
                            <Grid.Column width={2}>
                                <Button textAlign="left" size="small" className="deepviolet-button" onClick={this.resetHandle}>Reset</Button>
                            </Grid.Column>
                        </Grid>
                        <Grid columns="3">
                            <Grid.Column width={16}>
                                <Form>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <label>Select Actvity </label>
                                            <Form.Dropdown search fluid selection placeholder='Select Actvity' options={activityOptions}
                                                name="activityName" data="activityName" className="custom-select" value={activityManifestData.activityId}
                                                onChange={this.onHandleChange} />

                                        </Form.Field>
                                        <Form.Field>
                                            <label>Select Date </label>
                                            <DateInput name="activityDate" type="text"
                                                hideMobileKeyboard={true}
                                                popupPosition="bottom center" placeholder="Select Date"
                                                value={activityManifestData.activityDate}
                                                dateFormat="MMM DD, YYYY"
                                                iconPosition="right"
                                                onChange={this.onHandleChange}
                                                data="activityDate"
                                                closable fluid />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Select Slot </label>
                                            <Select className="custom-select" placeholder='Select Slot'
                                                options={activitySlotData}
                                                value={activityManifestData.activitySlotTime}
                                                name="activitySlot"
                                                data="activitySlot"
                                                onChange={this.onHandleChange} />
                                        </Form.Field>
                                    </Form.Group>
                                </Form>
                            </Grid.Column>
                        </Grid>
                        {this.state.dataLoader === true && <Dimmer active inverted>
                            <Loader size='small'>Loading</Loader> </Dimmer>}
                        {activityManifest.activityName &&
                            <Grid>
                                {/* {activityManifest && activityManifest.map((data, index) => { */}
                                <>
                                    <Grid.Column width={6} verticalAlign="middle">
                                        <Header as="h3" className="maroon-color">{activityManifest.activityName} | {activityManifest.selectedDate} | {activityManifest.selectedTime}</Header>
                                    </Grid.Column>
                                    <Grid.Column width={5}>
                                        {activityManifest.availabilityType === "Time Slots" && <Header as="h2">Booked Tickets : <span className="orange-color">{activityManifest.ticketsBooked} of {activityManifest.totalTickets}</span></Header>}
                                        {activityManifest.availabilityType !== "Time Slots" && <Header as="h2">Booked Tickets : <span className="orange-color">{activityManifest.ticketsBooked}</span></Header>}
                                    </Grid.Column>
                                    {activityManifest.availabilityType === "Time Slots" && <Grid.Column width={5}>
                                        <Header as="h2">Remaining Tickets : <span className="orange-color">{activityManifest.ticketAvailable}  of {activityManifest.totalTickets}</span></Header>
                                    </Grid.Column>}
                                </>
                            </Grid>
                        }
                        {orderDetails && orderDetails.map((data, index) => {

                            return (
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <Header as="h4" className="orange-color">Order on {data.SelectedDate} | Order #{data.OrderId} | Order Status : {data.OrderStatus}</Header>
                                        </Grid.Column>
                                        <Grid.Column width={16}>
                                            <Table fixed className="borderlessTable">
                                                <Table.Body>
                                                    <Table.Row>
                                                        <Table.Cell title={data.Label, ":", data.SelectedDate} className="textOverflowHidden"><Header as="h4">{data.Label} : {data.SelectedDate}</Header></Table.Cell>
                                                        <Table.Cell colSpan="3" title={data.ActivityName} className="textOverflowHidden"><Header as="h2">{data.ActivityName}</Header></Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row >
                                                        <Table.Cell>
                                                            {data.CustomerDetails && JSON.parse(data.CustomerDetails).map((detail, index) => {

                                                                return (
                                                                    <List>
                                                                        <List.Item className="mbt" Title={detail.FirstName} className="textOverflowHidden">{detail.FirstName}</List.Item>
                                                                        <List.Item className="mbt" Title={detail.EmailId} className="textOverflowHidden">{detail.EmailId}</List.Item>
                                                                        <List.Item className="mbt" Title={detail.PhoneNumber} className="textOverflowHidden">{detail.PhoneNumber}</List.Item>
                                                                        <List.Item className="mbt" Title={detail.Address," ", detail.City} className="textOverflowHidden">{detail.Address} <br />{detail.City}</List.Item>
                                                                    </List>
                                                                )
                                                            })}

                                                            <List>
                                                                <List.Item className="mbt">Order Taken by : <span className="orange-color" title={data.OrderTakenBy}>{data.OrderTakenBy}</span></List.Item>
                                                                <List.Item className="mbt">Order Closed by : <span className="orange-color" title={data.OrderClosedBy}>{data.OrderClosedBy}</span></List.Item>
                                                            </List>
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            {data.Tickets && JSON.parse(data.Tickets).map((ticket, index) => {
                                                                return (
                                                                    <List>
                                                                        <List.Item>
                                                                            <b>{ticket.Title} - {ticket.NumberOfTickets} x ${ticket.TicketAmount} = ${ticket.Amount}</b>
                                                                            {ticket.Tickets !== null && ticket.Tickets.map((dataa, index) => {

                                                                                return (
                                                                                    <List.List>
                                                                                        <List.Item>{dataa.customField} : <span className="orange-color">{dataa.customAnswer}</span></List.Item>
                                                                                    </List.List>
                                                                                )
                                                                            })}
                                                                        </List.Item>
                                                                    </List>
                                                                )
                                                            })}
                                                        </Table.Cell>
                                                    </Table.Row>
                                                </Table.Body>
                                            </Table>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            )
                        })}
                    </div>
                </Modal.Content>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        api: state.api,
        global: state.global,
        auth: state.auth,
        warnUserState: state.warnUserState
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            apiCall: bindActionCreators(actions.apiCall, dispatch),
            storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
            showNotification: bindActionCreators(Notifications.show, dispatch),
            storeWarnUser: bindActionCreators(actions.storeWarnUser, dispatch)
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityManifestModal);