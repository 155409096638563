import React, { Component, Fragment } from "react";
import { Grid, Tab, Button, Select, Header, Popup, Icon, Table } from "semantic-ui-react";
import moment from 'moment';
import { DatePicker } from 'react-trip-date';
import { commonFunctions } from "../../../../../../functional/global-import";
import { orderCommonFunctions } from "../../order-common-function";


const handleResponsive = setNumberOfMonth => {
    let width = document.querySelector('.tp-calendar').clientWidth;
    if (width > 900) {
        setNumberOfMonth(3);
    } else if (width < 900 && width > 580) {
        setNumberOfMonth(2);
    } else if (width < 580) {
        setNumberOfMonth(1);
    }
};

const Day = ({ day }) => {
    return (
        <>
            <p className="date">{day.format('DD')}</p>
            <p className="date">7</p>
        </>
    );
};

const CustomTimefirst = [
    { key: "01", value: "01", text: "01" },
    { key: "02", value: "02", text: "02" },
    { key: "03", value: "03", text: "03" },
    { key: "04", value: "04", text: "04" },
    { key: "05", value: "05", text: "05" },
    { key: "06", value: "06", text: "06" },
    { key: "07", value: "07", text: "07" },
    { key: "08", value: "08", text: "08" },
    { key: "09", value: "09", text: "09" },
    { key: "10", value: "10", text: "10" },
    { key: "11", value: "11", text: "11" },
    { key: "12", value: "12", text: "12" },
];

const CustomTimetwo = [
    { key: "00", value: "00", text: "00" },
    { key: "05", value: "05", text: "05" },
    { key: "10", value: "10", text: "10" },
    { key: "15", value: "15", text: "15" },
    { key: "20", value: "20", text: "20" },
    { key: "25", value: "25", text: "25" },
    { key: "30", value: "30", text: "30" },
    { key: "35", value: "35", text: "35" },
    { key: "40", value: "40", text: "40" },
    { key: "45", value: "45", text: "45" },
    { key: "50", value: "50", text: "50" },
    { key: "55", value: "55", text: "55" },
];

const CustomTimethree = [
    { key: "am", value: "am", text: "AM" },
    { key: "pm", value: "pm", text: "PM" },
];

export class BundleDate extends Component {

    state = {
        date: '',
        time: '',
        dateTime: '',
        datesRange: '',
        bookingSlot: {
            startDate: '',
            startTimeHour: '',
            startTimeMinute: '',
            startTimeAm: '',
        },
        currentTime: null,
        nonAvalableDAtes: [],
    };


    handleChange = (event, { name, value }) => {
        const { bookingSlot } = this.state;
        bookingSlot[name] = value;
        this.setState({ bookingSlot });
    }

    handleSubmit() {
        const { bookingSlot, currentTime } = this.state;
        bookingSlot.startDate = currentTime
        // this.props.handleSubmitShowDate(bookingSlot)

        this.props.onCheckOfSlotAvailability(bookingSlot)

    }

    getMinutes = (number) => {

        if (number % 5 === 0) {
            return ('0' + number).slice(-2);
        }
        else {
            let numberValue = number - number % 5
            return ('0' + numberValue).slice(-2);
        }
    }
    componentDidMount() {
        // Current-hour//
        var hours = new Date().getHours();
        hours = (hours % 12) || 12;
        let CurHour = ('0' + hours).slice(-2);
        // mintues//
        var Mintues = new Date().getMinutes();
        let MintuesGet = this.getMinutes(Mintues);
        let GetFinalMintues = MintuesGet.toString();
        let am = new Date().getHours() >= 12 ? "pm" : "am";
        let pm = new Date().getHours() + 1 >= 12 ? "pm" : "am"
         
        this.setState({
            bookingSlot: {
                ...this.state.bookingSlot,
                startTimeHour: CurHour,
                startTimeAm: am,
                startTimeMinute: GetFinalMintues,

            }
        });

        const today = this.props.currentDateFromApi === undefined ? new Date() : this.props.currentDateFromApi
        const timeConverted = (moment(today, 'MMM DD YYYY, hh:mm A').format('YYYY-MM-DD'));

        this.setState({ currentTime: timeConverted, });
    }

    onChange = (date) => {

        this.setState({ currentTime: date[0] });
    }

    onRangeDateInScreen = (window) => {
        const { bundleStateRef } = this.props
        bundleStateRef.props.actions.apiCall({
            urls: ["GETPRODUCTAVAILAVILITY"],
            method: "GET",
            data: {
                "StartDate": window.start,
                "ProductId": this.props.productId,
                "businessCategoryType": (commonFunctions.getGlobalCodeDetails(bundleStateRef.props.global.codes, "BusinessCategoryType", "Bundle")).globalCodeId,
            },
            onSuccess: (response) => {
                if (response[0].notAvailableDays === null) {
                    this.setState({ nonAvalableDAtes: [] })
                }
                else {

                    let nonAvalableDAte = JSON.parse(response[0].notAvailableDays).map((post) => {
                        return (post.dates)
                    });
                    this.setState({ nonAvalableDAtes: nonAvalableDAte });
                    let currentdate = new Date();
                    let aa = moment(currentdate).format("YYYY-MM-DD");
                    if (nonAvalableDAte.indexOf(aa) > -1) {
                        this.setState({ currentTime: "" })
                    }
                    // else {
                    //     let currentdate = new Date();
                    //     let aa = "";
                    //     aa = moment(currentdate).format("YYYY-MM-DD");
                    //     this.setState({ currentTime: aa })
                    // }
                }
                // this.onSettingPartialDates()
            },
        });

    }
    render() {
        const { bookingSlot, currentTime, nonAvalableDAtes } = this.state;
        const { rate, customfields, currentDateFromApi } = this.props;
        // const bundleDescription = null
        const bundleDescription = this.props.bundleDescription && this.props.bundleDescription;

        const theme = orderCommonFunctions.themeColor()

        const panes = [
            {
                menuItem: "Book Now",
                render: () => (
                    <Tab.Pane attached={false}>
                        <Grid>
                            <Grid.Column width={16} textAlign="right">
                                <Popup
                                    trigger={<Icon name="info circle" className="orange-color" />}
                                >
                                    {/* <div>
                                        <input className="orangeBox" ></input>Partially booked dates
                                    </div>
                                    &nbsp;&nbsp; */}
                                    <div>
                                        <input className="blueBox" ></input>Selected date
                                    </div>
                                    &nbsp;&nbsp;
                                    <div>
                                        <input className="blankBox" value=" / " ></input>Not available date
                                    </div>
                                </Popup>
                            </Grid.Column>
                            <Grid.Column width={16}>
                                <Header as="h2" className="maroon-color">Start Time</Header>

                            </Grid.Column>
                            <Grid.Column width={4}><Select options={CustomTimefirst} value={bookingSlot.startTimeHour} className="custom-select" defaultValue="9" name="startTimeHour" fluid onChange={this.handleChange} /></Grid.Column>
                            <Grid.Column width={5}><Select options={CustomTimetwo} value={bookingSlot.startTimeMinute} className="custom-select" defaultValue="00" name="startTimeMinute" fluid onChange={this.handleChange} /></Grid.Column>
                            <Grid.Column width={5}> <Select options={CustomTimethree} value={bookingSlot.startTimeAm} className="custom-select" name="startTimeAm" defaultValue="am" fluid onChange={this.handleChange} />
                            </Grid.Column>
                        </Grid>
                    </Tab.Pane>
                ),
            },
            {
                menuItem: "Info",
                render: () => (
                    <Tab.Pane attached={false}>
                        {bundleDescription !== "" &&
                            <>
                                <p><strong>Description :</strong></p>
                                <p> {bundleDescription}</p>
                            </>
                        }
                        <br></br>
                        {customfields && customfields.length > 0 &&
                            customfields.map((customObj) => {
                                return (
                                    <>
                                        <p><strong>{customObj.customField} :</strong></p>
                                        <p>{customObj.customDescriptions}</p>
                                        <br></br>
                                    </>
                                )
                            })
                        }
                    </Tab.Pane >
                ),
            },
            {
                menuItem: "Rate",
                render: () => (
                    <Tab.Pane attached={false}>

                        {rate && rate.length > 0 &&
                            <Grid.Column width={16} >
                                <Grid>
                                    < Grid.Column width={16}>
                                        <Header as="h5">Bundle price</Header>
                                        <Table className="flatfee">
                                            <Table.Header>
                                                <Table.HeaderCell>Date Range</Table.HeaderCell>
                                                <Table.HeaderCell>Available Timings</Table.HeaderCell>
                                                <Table.HeaderCell>Price</Table.HeaderCell>
                                            </Table.Header>
                                            <Table.Body>
                                                {rate && rate.map((fee) => {
                                                    return (
                                                        <Table.Row>
                                                            <Table.Cell>{fee.DateRange}</Table.Cell>
                                                            <Table.Cell>{fee.AvailableTimings} </Table.Cell>
                                                            <Table.Cell className="bundle-price">{commonFunctions.isNumberInteger(fee.Price)}</Table.Cell>
                                                        </Table.Row>
                                                    )
                                                })}

                                            </Table.Body>
                                        </Table>
                                    </Grid.Column>
                                </Grid>
                            </Grid.Column >
                        }
                    </Tab.Pane>
                ),
            },
        ];
        return (
            <Fragment>
                <Grid>
                    {/*  */}
                    <Grid.Column width={8}>
                        {currentTime !== null &&
                            <DatePicker
                                theme={theme}
                                onRangeDateInScreen={this.onRangeDateInScreen}
                                onChange={this.onChange}
                                numberOfMonths={1}
                                selectedDays={[currentTime]}
                                responsive={handleResponsive}
                                // disabledBeforeToday={true}
                                disabledBeforeDate={(moment(currentDateFromApi).format('YYYY-MM-DD'))}
                                numberOfSelectableDays={1}
                                disabledDays={nonAvalableDAtes}
                                disabled={false}
                                dayComponent={Day}
                                titleComponent='{Title}'
                            />
                        }
                    </Grid.Column>
                    {/*  */}
                    {/* <Grid.Column width={8}>
                        <DateInput minDate={moment().toDate()} inline name='startDate' placeholder="Date Time" value={bookingSlot.startDate} onChange={this.handleChange} inlineLabel={true} />
                    </Grid.Column> */}

                    <Grid.Column width={8}>
                        <Tab menu={{ secondary: true }} panes={panes} className="availablity-tab" />
                    </Grid.Column>
                    <Grid.Column width={16} textAlign="right">
                        <Button className="blue-btn" onClick={this.props.backBtnInventoryView}>Back</Button>
                        <Button className="orange-btn"
                            disabled={currentTime === undefined || currentTime === "" || currentTime === null}
                            // disabled={bookingSlot.startDate === ""}
                            onClick={() => this.handleSubmit()}>Continue</Button>
                    </Grid.Column>
                </Grid>
            </Fragment>
        )
    }
}

export default BundleDate
