import { lazy } from 'react';
import { env } from '../../shared/functional/global-import';

const NoTemplateRoutesConfing = [
  
  {
    exact: true,
    private: true,
    path: `${env.PUBLIC_URL}/location`,
    component: lazy(() => import('../../pages/location'))
  },
  {
    exact: true,
    private: true,
    path: `${env.PUBLIC_URL}/passcode`,
    component: lazy(() => import('../../pages/passcode'))
  },
  {
    exact: true,
    private: false,
    path: `${env.PUBLIC_URL}/reset-passcode`,
    component: lazy(() => import('../../pages/reset-passcode-page'))

  },
  {
    exact: true,
    private: false,
    path: `${env.PUBLIC_URL}/reset-password`,
    component: lazy(() => import('../../pages/reset-password-page'))

  },
  {
    exact: true,
    private:true,
    path: `${env.PUBLIC_URL}/emp-login`,
    component: lazy(() => import('../../pages/emplogin'))
  },
];

export default NoTemplateRoutesConfing;