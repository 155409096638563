import React from "react";
import propTypes from "prop-types";
// import { propTypes } from 'react-notification-system';
import { Button, Form, Grid, Modal, Header } from "semantic-ui-react";
import { connect } from "../../../../functional/global-import";

const CommonDeleteIntegrationModal = ({
  edgePayHeader,
  onConfirm,
  confirmationButtonText,
  isModalOpen,
  onClose,
  onClearPayment,
  onDeletePayment,
  api,
  subHeader,
  stripeHeader,
  cancelButtonText,
  paymentMenthod,
  ConfirmationStripeNo,
  ConfirmationStripeYes,
}) => {
  return (
    <Modal open={isModalOpen} closeIcon={true} onClose={onClose} size={"tiny"}>
      <Modal.Content>
        <Form loading={api.isApiLoading}>
          <Grid>
            <Grid.Column width={16} textAlign="center">
              <Header as="h3" className="orange-color">
                {paymentMenthod === "Stripe" ? stripeHeader : edgePayHeader}
              </Header>
              {subHeader && (
                <Modal.Content>
                  <Modal.Description className="orange-color">
                    {subHeader}
                  </Modal.Description>
                </Modal.Content>
              )}
            </Grid.Column>
            <Grid.Column width={16} textAlign="center">
              {paymentMenthod === "Stripe" ? (
                <Button className="blue-btn" onClick={onClose}>
                  {ConfirmationStripeNo}
                </Button>
              ) : (
                <Button className="blue-btn" onClick={onClearPayment}>
                  {cancelButtonText}
                </Button>
              )}
              &nbsp;&nbsp;
              {paymentMenthod === "Stripe" ? (
                <Button className="orange-btn" onClick={onDeletePayment}>
                  {ConfirmationStripeYes}
                </Button>
              ) : (
                <Button className="orange-btn" onClick={onDeletePayment}>
                  {confirmationButtonText}
                </Button>
              )}
            </Grid.Column>
          </Grid>
        </Form>
      </Modal.Content>
    </Modal>
  );
};
const mapStateToProps = (state) => {
  return {
    api: state.api,
  };
};
CommonDeleteIntegrationModal.propTypes = {
  isModalOpen: propTypes.bool.isRequired,
  onClose: propTypes.bool.isRequired,
  onConfirm: propTypes.func.isRequired,
  header: propTypes.string.isRequired,
  cancelButtonText: propTypes.string.isRequired,
  confirmationButtonText: propTypes.string.isRequired,
};

export default connect(mapStateToProps)(CommonDeleteIntegrationModal);
