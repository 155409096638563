import React, { Component } from "react";
import { Grid, Divider, Form, Header, Button, Icon} from "semantic-ui-react";
import { connect, bindActionCreators, actions, withRouter} from "../../../../functional/global-import";


class SelectRole extends Component {
  componentDidMount() {
    const {propsData} = this.props;
    const { roleForm } = this.props.fullState;
    roleForm.businessId = this.props.auth.loggedIn.businessId
    this.setState({ roleForm })
    
  }

  render() {
                           
    const { api, fullState, allfunction , propsData} = this.props;
    return (
      <Grid >
        <Grid.Column width={8}>
          <div >
          <span>Select a role</span>      
           <span className="newClass"> Click clone to create new role</span>
           </div>
          <Divider hidden />
          
          <Form >
            {fullState.roleDetails && fullState.roleDetails.map((data, index) => (
              <Grid columns="2">
                <Grid.Column >
                  <Form.Field>
                    <Form.Radio label={data.roleName} description={data.roleDescription} className="custom-radio-btn" name="rgpRole"
                      fluid data="roleId" value={data.roleId}
                    
                       checked={propsData.editRole === true ? propsData.roleId : fullState.roleForm.roleId === data.roleId}
                       onChange={allfunction.onHandleChangeSelectRole} />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column>
                  {fullState.roleForm.roleId === data.roleId && fullState.iconClicked ?
                    <Icon name="clone outline icon" title="Clone Role" size="large" className="orange-color" link onClick={()=>allfunction.onIconClick(data)} /> :
                    <Icon name="clone outline icon" title="Clone Role" size="large" className="deepviolet-color" link onClick={()=>allfunction.onIconClick(data)}/>}
                </Grid.Column>
              </Grid>
            ))}
          </Form>
        </Grid.Column>
        <Grid.Column width={8} className="select-role-description">
          <Form loading={api.isApiLoading}>
            {fullState.masterAccess === true && <Header as="h4">Master Login</Header> }
            {fullState.rolePermissionScreenList && fullState.rolePermissionScreenList.map((data, index) => {
              
              const permission = data.permission
              return (
               <>
               {data.Access === 1 &&  <Header id={data.moduleId} as="h4">{data.moduleName}</Header>}
                  {permission !== null && permission.map((screen, index) => {
                    return (
                      <>
                      {screen.access === 1 && <p>{screen.ScreenName }</p>}</>
                    )
                  })}
                  {data.Access === 1 && <Divider />}
                </>
              )
            })}
          </Form>
        </Grid.Column>
        <Grid.Column width={16} textAlign="right">
          <Button className="orange-btn" onClick={allfunction.onHandleSubmitSelectRole}> Continue </Button>
        </Grid.Column>
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    api: state.api,
    global: state.global,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
    }
  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SelectRole));

