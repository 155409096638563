import React, { Component } from "react";
import { Button, Form, Grid, Modal } from "semantic-ui-react";
import { connect, actions, bindActionCreators } from "../../../../functional/global-import";
import { timeTableOptions } from '../../../../../config/time-options/time.config';

class CustomTimeFrameModal extends Component {
  // Put all key & values that need to reset on form submit and reset form.
  get initialState() {
    return {
      customTime: {},
    };
  }
  constructor(props) {
    super(props);
    this.state = { ...this.initialState, customTimeList: [] };
  }
  render() {
    return (
      <React.Fragment>
        <Modal open={this.props.openModal} closeIcon onClose={this.props.closeModal} size={"small"}>
          <Modal.Header>Add Custom Time</Modal.Header>
          <Modal.Content>
            <Form>
              <Grid>
                <Grid.Column width={5}>
                  <Form.Dropdown options={timeTableOptions.timeHours} className="custom-select" data="customTimeFirst" name="customTimeFirst" selection value={this.props.customTimeFirst} placeholder="Hour" type="select" onChange={this.props.onChange} error={this.props.validator.message("hour", this.props.customTimeFirst, "required")} fluid />
                </Grid.Column>
                <Grid.Column width={5}>
                  <Form.Dropdown options={timeTableOptions.timeMinutes} className="custom-select" data="customTimeTwo" name="customTimeTwo" selection value={this.props.customTimeTwo} placeholder="Minute" type="select" onChange={this.props.onChange} fluid
                    error={this.props.validator.message("minute", this.props.customTimeTwo, "required")}
                  />
                </Grid.Column>
                <Grid.Column width={5}>
                  <Form.Dropdown options={timeTableOptions.timeMeridian} className="custom-select" data="customTimeThree" name="customTimeThree" selection value={this.props.customTimethree} placeholder="AM/PM" type="select" onChange={this.props.onChange}
                    error={this.props.validator.message("period", this.props.customTimeThree, "required")}
                    fluid />
                </Grid.Column>
                <Grid.Column width={16} textAlign="right">
                  <Button className="blue-btn" onClick={this.props.closeModal}>Cancel</Button>
                  <Button className="orange-btn" onClick={this.props.postSpecificTime}>Save</Button>
                </Grid.Column>
              </Grid>
            </Form>
          </Modal.Content>
        </Modal>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomTimeFrameModal);
