export const CREATE_ORDER_ID = "CREATE_ORDER_ID";
export const REMOVE_ORDER_ID = "REMOVE_ORDER_ID";
export const ADD_ORDER_DETAILS = "ADD_ORDER_DETAILS";
export const ADD_SELECTED_DATE_RANGE = "ADD_SELECTED_DATE_RANGE";
export const ADD_SELECTED_ITEMS = "ADD_SELECTED_ITEMS";
export const ADD_SELECTED_BOOKINGSLOT = "ADD_SELECTED_BOOKINGSLOT";
export const ADD_ORDER_PAYMENT_STATUS = "ADD_ORDER_PAYMENT_STATUS";
export const UPDATE_ORDER_PAYMENT_STATUS = "UPDATE_ORDER_PAYMENT_STATUS";
export const ADD_ACITIVITY_TIMESLOTS = "ADD_ACITIVITY_TIMESLOTS";
export const ADD_ORDER_ACITIVITY_PRICINGDETAILID = "ADD_ORDER_ACITIVITY_PRICINGDETAILID";
export const ADD_FEE_PAYMENT = "ADD_FEE_PAYMENT";
export const REMOVE_DISCOUNT_COUPON = "REMOVE_DISCOUNT_COUPON";
export const ADD_MULTIPLE_RENTALFLATFEEANDOTHERDETAILS = "ADD_MULTIPLE_RENTALFLATFEEANDOTHERDETAILS";
export const REMOVE_EXTRA_CHARGES = "REMOVE_EXTRA_CHARGES";
export const UPDATE_ORDER_STATE = "UPDATE_ORDER_STATE";
export const PRICE_DEPOSIT_CHANGE = "PRICE_DEPOSIT_CHANGE";

// export const ADD_ORDER_ACTIVITY_TICKETS


// addOrderActivingTicketsDetail