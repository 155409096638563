import React, { Component } from 'react';
import {  Grid,Modal} from "semantic-ui-react";
import Webcam from "react-webcam";
class ClickWebCamModal extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
      }
     
    render() {
      const videoConstraints = {
        width: 150,
        height:150,
        facingMode: "user",
      
      };
      const {capture, imageSrc} = this.props;
      
        return (
            <Modal open={this.props.openModal} closeIcon onClose={this.props.closeModal} size={"medium"} closeOnDimmerClick={false}>
            <Modal.Header>WebCamera</Modal.Header>
            <Modal.Content> 
              <Grid>
                <Grid.Column width={16} textAlign="centre">
                  <div>
                   <Webcam
                     audio={false}
                     ref={this.props.setRef}
                     screenshotFormat="image/jpg"
                     videoConstraints={videoConstraints}/>
                    
                      </div>
                     <button onClick={capture} floated="right">Capture photo</button> 
                     <button floated="right">close</button>


                     {imageSrc && <img src = {imageSrc} />}
                        {/*<Button onClick={capture} floated="right" basic >Capture</Button>
                       <Button floated="right" basic >Cancel</Button>*/}

                </Grid.Column>
              </Grid>
            </Modal.Content>
          </Modal>
        );
    }
}
export default ClickWebCamModal;