import React, { Component } from "react";
import {
  Table,
  Dropdown,
  Divider,
  Dimmer,
  Loader,
  Grid,
  Header,
} from "semantic-ui-react";
import CapturePreAuthorizationModal from "../capture-pre-authorization-modal";
import {
  connect,
  bindActionCreators,
  actions,
  commonFunctions,
} from "../../../../../../functional/global-import";
import Moment from "react-moment";
import CommonDeleteUpdateModal from "../../../common-delete-update-modal";
import AddRefundModal from "../../../../../../../pages/more-setting/transaction-setting/refundTranscationModal";
import { PrintInvoiceModal } from "../../../../../../components";
import SplitSignatureModal from "../../../split-signature-modal";
import AddCommentModal from "../../../../modal/add-comment-modal";

class PaymentTable extends Component {
  constructor() {
    super();
    this.state = {
      activeStep: 0,
      transactionDetail: [],
      capturePayment: {
        businessId: "",
        businessLocationId: "",
        orderId: "",
        amount: "",
        transactionDetailId: null,
        paymentIntentId: null,
        paymentMethodType: null,
        actionPerformedBy: "",
      },

      isCancelHoldPaymentModalOpen: false,
      refundDetails: null,
      ReceiptStatus: false,
      splitSignatureModal: false,
      commentStatus: false,
      edgePayTransactionId: null,
      selectPaymentType: null,
    };
  }

  openCancelHoldPaymentModal = (objDetails) => {
    this.setState({
      isCancelHoldPaymentModalOpen: true,
      holdTransactionDetailId: objDetails.transactionDetailId,
      holdPaymentIntentId: objDetails.paymentIntentId,
      paymentMethodType: objDetails.paymentGatewayMethod,
      edgePayTransactionId: objDetails.edgePayTransactionId,
    });
  };

  onConfirmCancelHoldPayment = () => {
    const {
      holdTransactionDetailId,
      holdPaymentIntentId,
      edgePayTransactionId,
      paymentMethodType,
    } = this.state;

    this.props.actions.apiCall({
      urls: ["CANCELHOLDPAYMENT"],
      method: "POST",
      data: {
        orderId:this.props.orderState.currentOrder.orderId,
        transactionDetailId: holdTransactionDetailId,
        paymentIntentId: holdPaymentIntentId,
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId: parseInt(
          this.props.global.locationSelected.BusinessLocationId
        ),
        edgePayTransactionId: edgePayTransactionId,
        paymentMethodType: paymentMethodType,
        actionPerformedBy: this.props.auth.userDetail.emailId,
      },
      onSuccess: () => {
               
        this.setState({
          isCancelHoldPaymentModalOpen: false,
          holdPaymentIntentId: null,
          holdTransactionDetailId: null,
        });
        this.props.actions.getOrderItems(
          this.props.orderState.currentOrder.orderId
        );
        this.getAllTransactionDetails();
      },
      showNotification: true,
    });
  };
  capPreAuthModal = (objDetails) => {
    this.setState({
      capturePayment: {
        ...this.state.capturePayment,
        transactionDetailId: objDetails.transactionDetailId,
        paymentIntentId: objDetails.paymentIntentId,
        amount: objDetails.amount,
        paymentMethodType: objDetails.paymentGatewayMethod,
      },
      cappreautStatus: !this.state.cappreautStatus,
    });
  };

  receiptModal = () => {
    this.setState({
      ReceiptStatus: !this.state.ReceiptStatus,
    });
  };

  openRefundModal = (data) => {
    let newrefundAccess = this.props.auth.userDetail.roleAndPermission?.filter(
      (item) => {
        return item.ModuleName === "more-setting";
      }
    );
    let newRefundScreen = newrefundAccess[0].permission.filter(
      (x) => x.ScreenName === "Refund-transaction" && x.Access === 0
    );
    if (newRefundScreen.length > 0) {
      this.props.actions.showNotification(
        {
          title: "Error",
          message: "Screen is not accessible.",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    } else {
      data.TransactionDetailId = data.transactionDetailId;
      data.OrderId = data.orderId;
      data.StripeTransactionId = data.stripeTransactionId;
      this.setState({
        openRefund: !this.state.openRefund,
        refundDetails: data,
        edgePayTransactionId: data.edgePayTransactionId,
        selectPaymentType: data.paymentGatewayMethod,
      });
    }
  };

  getAllTransactionDetails = () => {
    const { orderState } = this.props;
    this.props.actions.apiCall({
      urls: ["GETALLTRANSACTIONDETAILS"],
      method: "GET",
      data: {
        OrderId: orderState.currentOrder.orderId,
        PageNo: 1,
        PageSize: 100,
        SearchValue: "",
        SortColumn: "",
        SortOrder: "",
      },
      onSuccess: (response) => {
                       
        this.setState({
          transactionDetail: response,
          selectPaymentType: response.paymentGatewayMethod,
        });
      },
    });
  };

  componentDidMount() {
    this.getAllTransactionDetails();
    this.setState({
      capturePayment: {
        ...this.state.capturePayment,
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId: parseInt(
          this.props.global.locationSelected.BusinessLocationId
        ),
        orderId: this.props.orderState.currentOrder.orderId,
        actionPerformedBy: this.props.auth.userDetail.emailId,
      },
    });
  }

  onConfirmCapture = () => {
    const { capturePayment } = this.state;
    const { orderState } = this.props;
    capturePayment.ProductItemPaid = orderState.currentOrder.productItemPaid;
    capturePayment.paymentMethod = commonFunctions.getGlobalCodeDetails(
      this.props.global.codes,
      "PaymentMethod",
      "Card"
    ).globalCodeId;
    this.props.actions.apiCall({
      urls: ["CAPTUREHOLDPAYMENT"],
      method: "POST",
      data: capturePayment,
      onSuccess: (response) => {
        this.setState({
          transactionDetails: { KeyId: response.keyId },
          newTotalAmountPaid: capturePayment.amount,
          cappreautStatus: false,
          splitSignatureModal: true,
        });
        this.props.actions.getOrderItems(orderState.currentOrder.orderId);
        this.getAllTransactionDetails();
      },
      showNotification: true,
    });
  };

  addCommentModal = (item) => {
    this.setState({
      commentStatus: !this.state.commentStatus,
      notesTransactionDetailId: item.transactionDetailId,
    });
  };

  render() {
    const {
      cappreautStatus,
      transactionDetail,
      ReceiptStatus,
      isCancelHoldPaymentModalOpen,
      openRefund,
      edgePayTransactionId,
      refundDetails,
      splitSignatureModal,
      transactionDetails,
      newTotalAmountPaid,
      commentStatus,
      notesTransactionDetailId,
      selectPaymentType,
    } = this.state;
    const { api, orderState } = this.props;
       
    return (
      <React.Fragment>
        {api.isApiLoading && (
          <Dimmer active inverted>
            {" "}
            <Loader size="small">Loading</Loader>{" "}
          </Dimmer>
        )}

        {!api.isApiLoading && transactionDetail.length > 0 && (
          <div className="common-table">
            <Table size="small" compact singleLine>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>User </Table.HeaderCell>
                  <Table.HeaderCell>Date </Table.HeaderCell>
                  <Table.HeaderCell>Time </Table.HeaderCell>
                  <Table.HeaderCell>Card </Table.HeaderCell>
                  <Table.HeaderCell>Status </Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">Amount</Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {transactionDetail.map((item, index) => {
                  return (
                    <Table.Row>
                      <Table.Cell
                        className="textOverflowHidden"
                        title={item.user}
                      >
                        <span className="deepviolet-color">{item.user}</span>
                      </Table.Cell>
                      <Table.Cell>
                        <Moment format="MMM DD, YYYY">
                          {item.paymentDate}
                        </Moment>
                      </Table.Cell>
                      <Table.Cell>{item?.time}</Table.Cell>
                      <Table.Cell>{item.card}</Table.Cell>
                      <Table.Cell className="orange-color">
                        {item.paymentStatus}
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        {commonFunctions.isNumberInteger(item.amount)}
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        <Dropdown
                          scrolling
                          icon="ellipsis horizontal"
                          className="item-list-dropdown"
                        >
                          {/* <Dropdown.Menu className="left"> */}
                          <Dropdown.Menu
                            className={index > 5 ? "bottomAline" : "left"}
                          >
                            <Dropdown.Item
                              hidden={
                                item.paymentStatus === "Pre-Authorized"
                                  ? false
                                  : true
                              }
                              icon="credit card"
                              text="Capture Pre-Authorization"
                              onClick={() => this.capPreAuthModal(item)}
                            />
                            <Dropdown.Item
                              hidden={
                                item.paymentStatus === "Pre-Authorized"
                                  ? false
                                  : true
                              }
                              icon="ban"
                              text="Cancel Pre-Authorization"
                              onClick={() =>
                                this.openCancelHoldPaymentModal(item)
                              }
                            />
                            <Dropdown.Item
                              hidden={
                                item.paymentStatus === "Pre-Authorized"
                                  ? true
                                  : item.paymentStatus ===
                                    "Transaction Cancelled"
                                  ? true
                                  : false
                              }
                              icon="money bill alternate outline"
                              text="Refund Transaction"
                              onClick={() => this.openRefundModal(item)}
                            />
                            <Dropdown.Item
                              icon="print"
                              text="Send or Print Receipt "
                              onClick={() => this.receiptModal(item)}
                            />
                            <Dropdown.Item
                              icon="sticky note"
                              text="Notes"
                              onClick={() => this.addCommentModal(item)}
                            />
                          </Dropdown.Menu>
                        </Dropdown>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </div>
        )}

        {!api.isApiLoading && transactionDetail.length === 0 && (
          <>
            <Grid>
              <Grid.Column width={16} textAlign="center">
                <Header as="h2">No Transaction details </Header>
              </Grid.Column>
              <Grid.Column width={16} textAlign="center">
                <Header as="h2">!</Header>
              </Grid.Column>
            </Grid>
          </>
        )}

        <Divider hidden />

        {isCancelHoldPaymentModalOpen && (
          <CommonDeleteUpdateModal
            isModalOpen={isCancelHoldPaymentModalOpen}
            onClose={() =>
              this.setState({
                isCancelHoldPaymentModalOpen: false,
                holdPaymentIntentId: null,
                holdTransactionDetailId: null,
              })
            }
            header="Are you sure you want to cancel hold payment ?"
            confirmationButtonText={"Yes"}
            transactionDetail
            cancelButtonText={"Cancel"}
            onConfirm={this.onConfirmCancelHoldPayment}
          />
        )}

        {splitSignatureModal && (
          <SplitSignatureModal
            transactionDetails={transactionDetails}
            onSignSubmit={() => this.props.closeModal()}
            splitAmount={newTotalAmountPaid}
            isModalOpen={splitSignatureModal}
            onClose={() => this.setState({ splitSignatureModal: false })}
          />
        )}

        {cappreautStatus && (
          <CapturePreAuthorizationModal
            api={api}
            openModal={cappreautStatus}
            closeModal={() => this.setState({ cappreautStatus: false })}
            onConfirmCapture={this.onConfirmCapture}
          />
        )}

        {ReceiptStatus && (
          <PrintInvoiceModal
            stateRef={this}
            orderId={orderState.currentOrder.orderId}
            emailId={orderState.currentOrder.customerDetail.fullDetail.emailId}
            openModal={ReceiptStatus}
            closeModal={this.receiptModal}
            isRemoveOrder={false}
          />
        )}
        

        {openRefund && (
          <AddRefundModal
            openModal={openRefund}
            closeModal={() => this.setState({ openRefund: false })}
            refundDetails={refundDetails}
            transactionDetail={this.state.transactionDetail}
            getCounter={this.getCounter}
            getAllTransactionDetails={this.getAllTransactionDetails}
            edgePayTransactionId={edgePayTransactionId}
            selectPaymentType={selectPaymentType}
            orderId={this.props.orderState.currentOrder.orderId}
          />
        )}

        <AddCommentModal
          openModal={commentStatus}
          noteType={"TransactionDetail"}
          noteRefrenceId={notesTransactionDetailId}
          closeModal={() => this.setState({ commentStatus: false })}
        />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    auth: state.auth,
    global: state.global,
    newOrderDetail: state.newOrderDetail,
    orderState: state.orderState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      getOrderItems: bindActionCreators(actions.getOrderItems, dispatch),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PaymentTable);
