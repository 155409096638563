import { Component } from 'react';
import { Modal } from "semantic-ui-react";
import { DataTable } from '../../../../components/organisms'
import moment from 'moment-timezone';
import { connect, bindActionCreators, actions, commonFunctions, Notifications } from "../../../../functional/global-import";

class AddWavierModalList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            businessId: this.props.businessId,
            businessLocationId: this.props.businessLocationId,
            gridDetail: []
        }
    }

    render() {
        const fullState = this.state;
        const allFunction = this;
        return (
            <Modal open={this.props.openModal} closeIcon onClose={this.props.closeModal} size={'medium'} Guestcustomerid={this.props.Guestcustomerid}>
                <Modal.Content>
                    <DataTable
                        getApiName="GETWAVIERCUSTOMER"
                        gridName="wavierCustomerGrid"
                        screenName="CustomerSummaryGrid"
                        fullState={fullState}
                        allFunction={allFunction}
                        emptyGrid="No records to show"
                        getGridDataParamters={{
                            CustomerId: this.props.Guestcustomerid,
                        }}
                        columns={[
                            {
                                headerName: "Name",
                                fieldName: "name",
                                isSorting: true,
                                className: "orange-color"

                            },
                            {
                                headerName: "OrderId",
                                fieldName: "orderId",
                                isSorting: true,
                                className: "orange-color",
                                Cell: (props) => {
                                    return (
                                        <span>#{props.orderId}</span>
                                    );
                                },
                            },
                            {
                                headerName: "Document",
                                fieldName: "document",
                                className: "textOverflowHidden",

                                isSorting: true,
                                Cell: (props) => {
                                    return (
                                        <span style={{ color: '#4CAF50' }
                                        } > {props.document}</span>
                                    );
                                },
                            },
                            {
                                headerName: "Date Signed",
                                fieldName: "signedDate",
                                className: "orange-color",
                                isSorting: true,
                                Cell: (props) => {
                                    return (
                                        moment(props.signedDate).format('MM/DD/yyyy')
                                    )
                                },
                            },
                            {
                                headerName: "Download",
                                fieldName: "signedDocument",
                                className: "orange-color textBreak",
                                Cell: (props) => {
                                    return (
                                        <a
                                            target="_blank"
                                            title={props.document}
                                            className="orange-color"
                                            href={commonFunctions.concatenateImageWithAPIUrl(props.signedDocument
                                            )}
                                        >
                                            Download Signed Document
                                        </a>
                                    )
                                },

                            },
                            // {
                            //     headerName: "Action",
                            //     fieldName: "action",
                            //     isSorting: false,
                            //     Cell: (props) => {
                            //         return (
                            //             <React.Fragment>
                            //                 <Icon
                            //                     name="pencil alternate"
                            //                     size="large"
                            //                     title="Edit"
                            //                     className="deepviolet-color pointer"
                            //                     onClick={() => allFunction.editBreakType(props)}
                            //                 />
                            //                 <Icon
                            //                     name="trash alternate"
                            //                     size="large"
                            //                     className="orange-color pointer"
                            //                     title="Delete"
                            //                     link
                            //                     onClick={() => commonFunctions.deleteModalMessage(allFunction, props.breakTypeTitle, props.breakTypeId, "breakTypeId")}
                            //                 />
                            //             </React.Fragment>
                            //         );
                            //     },
                            // },
                        ]}
                    />
                </Modal.Content>
            </Modal >
        );
    }
}
const mapStateToProps = state => {
    return {
        api: state.api,
        global: state.global,
        auth: state.auth
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            apiCall: bindActionCreators(actions.apiCall, dispatch),
            storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
            showNotification: bindActionCreators(Notifications.show, dispatch)
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)((AddWavierModalList));

