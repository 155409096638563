import React, { Component } from "react";
import CreditCardModal from "../credit-card/credit-card-modal/credit-card-modal";
import CashPaymentModal from "./cash-payment-modal";
import {
  connect,
  bindActionCreators,
  actions,
  commonFunctions,
  withRouter
} from "../../../../../../functional/global-import";
class CashModal extends Component {
  constructor() {
    super();
    this.state = {
      activeStep: 0,
      value: "Balance Due",
      isCustomOnCash:false ,
      selectedGlobalCodeId:null,
    };
  }
  handleChange = (e, { value }) => {
    this.setState(prevState => ({
      value: value,
      isCustomOnCash: value === "Custom Amount" ? true :false,
    }));
  };
  handleSubmit = (e) => {
    const { value } = this.state;
    const { stateRef } = this.props; 
    const orderTypeJson =this.props.orderTypeData;
    const customCodeId = commonFunctions.getGlobalCodeIdByCodeName(value, orderTypeJson);
    if (!value) {
      return stateRef.props.actions.showNotification(
        {
          title: "Error",
          message: "Please select any payment option",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    }
    const { orderState } = stateRef.props;
    if (value === "Selected Item(s)") {
      const selectedItems = orderState.currentOrder.selectedAmountItem;
      const amountSelected = selectedItems.reduce(
        (total, item) => total + item.amount,
        0
      );
      if (selectedItems.length > 0) {
        this.props.CashPaymentModal(amountSelected);
        this.setState({selectedGlobalCodeId:customCodeId})

      } else {
        stateRef.props.actions.showNotification(
          {
            title: "Error",
            message: "Please select at least one item",
            position: "br",
            autoDismiss: commonFunctions.notificationTime(),
          },
          "error"
        );
      }
    } else {
      this.props.CashPaymentModal(orderState.currentOrder.pricings.BalanceDue);
      this.setState({selectedGlobalCodeId:customCodeId})
    }
  };
  
  handleClose = () => {
    this.setState({ value: "" });
    this.props.CreditCardModal();
  };
  render() {
    const { value } = this.state;
    const { selectedAmount, defaultUserDetails } = this.props;

    return (
      <>
        <CreditCardModal
          isShowGettrixPayment={this.props.isShowGettrixPayment}
          openModal={this.props.openModal}
          closeModal={this.props.closeCashModal}
          onNextClick={this.props.onNextCashPaymentModal}
          value={value}
          title="Cash"
          orderTypeJson={this.props.orderTypeJson}

          CreditCard={this.props.CreditCard}
          fullState={this.state}
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit}
          handleClose={this.props.closeCashModal}
          itemDetailsGridState={this.props.itemDetailsGridState}
        />
        {this.props.creditCardModal && (
          <CashPaymentModal
            CreditCard={this.props.CreditCard}
            openModal={this.props.creditCardModal}
            amountSlected={selectedAmount}
            closeModal={this.props.CashPaymentModal}
            onChargeClick={this.props.onChargeClick}
            orderTypeJson={this.props.orderTypeJson}
            value={value}
            fullState={this.state}
            title="Cash"
            paymentType="Cash"
            itemDetailsGridState={this.props.itemDetailsGridState}
            defaultUserDetails={defaultUserDetails}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    auth: state.auth,
    global: state.global,
    orderState: state.orderState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    actions: {
      // apiCall: bindActionCreators(actions.apiCall, dispatch),
      removeOrder: bindActionCreators(actions.removeOrder, dispatch),

      getOrderItems: bindActionCreators(actions.getOrderItems, dispatch),
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CashModal));
