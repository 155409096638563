import React, { Component } from "react";
import { Icon } from "semantic-ui-react";
import { commonFunctions, } from "../../../../shared/functional/global-import";
import { DataTable } from '../../../../shared/components/organisms';

class ActivityGrid extends Component {
    childsChild = React.createRef();
    render() {
        const { fullState, allFunction } = this.props;
        return (
            <DataTable
                ref={this.childsChild}
                gridName="ActivityPricingGrid"
                screenName="pricing&taxes"
                getApiName="GETACTIVITYPRICE"
                searchOption={{ show: false, placeHolder: "Search" }}
                updateHeader={this.props.activityId}
                updateHeaderColumn="Active"
                hideEmptyGrid={true}
                getGridDataParamters={{ "activityId": this.props.activityId }}
                // defaultSort = {{sortColumn : "modifiedDate",sortOrder : "desc"}}
                // defaultPageSize = {10}
                fullState={fullState}
                allFunction={allFunction}
                tableHeight="250px"
                emptyGrid="No records to show"
                columns={[
                    {
                        headerName: "Title",
                        fieldName: "title",
                        isSorting: true,
                        className: "textOverflowHidden",
                        Cell: (props) => { 
                            return (
                                <span title={props.title}>{props.title}</span>
                              );
                           },
                    },
                    {
                        headerName: "Time Frame",
                        fieldName: "timeFrame",
                        isSorting: true,
                        className:"textOverflowHidden",
                        Cell: (props) => { 
                            return (
                                <span title={props.timeFrame}>{props.timeFrame}</span>
                              );
                           },
                    },
                    {
                        headerName: "Price",
                        fieldName: "price",
                        isSorting: true,
                        Cell: (props) => {
                            return (
                                props.price === 0 ? <span > - </span> : <span >
                                    {commonFunctions.isNumberInteger(props.price)}
                                    </span>
                            );
                        },
                    },
                    {
                        headerName: "Description",
                        fieldName: "description",
                        isSorting: true,
                        className: "textOverflowHidden",
                        Cell: (props) => { 
                            return (
                                <span title={props.description}>{props.description}</span>
                              );
                           },
                    },
                    // this.props.activityId == undefined &&
                    // {
                    //     headerName: "Active",
                    //     fieldName: "isActive",
                    //     isSorting: true,
                    //     Cell: (props) => {
                    //         return (
                    //             <Checkbox
                    //             className="custom-toggle"
                    //             checked={props.isActive}
                    //             onClick={(data, r) =>
                    //             commonFunctions.activateDeactivateToggle(allFunction, props.title, props.activityPricingId, 
                    //                 "activityPricingId", props.isActive)
                    //             }></Checkbox >
                    //         );
                    //     },
                    // },
                    {
                        headerName: "Custom Field",
                        fieldName: "customDescriptions",
                        isSorting: true,
                        className: "textOverflowHidden",
                        Cell: (props) => {  
                            return  JSON.parse(props.customField)[0]?.customField ? JSON.parse(props.customField)[0]?.customField : <span> - </span>;
                        },
                    },
                    {
                        headerName: "Action",
                        fieldName: "action",
                        isSorting: false,
                        Cell: (props) => {
                            return (
                                <React.Fragment>
                                    <Icon
                                        name="pencil alternate"
                                        size="large"
                                        title="Edit"
                                        className="deepviolet-color"
                                        onClick={() => allFunction.editActivityPrice(props)} />
                                    <Icon
                                        name="trash alternate"
                                        size="large"
                                        className="orange-color"
                                        title="Delete"
                                        link
                                        onClick={() => commonFunctions.deleteModalMessage(allFunction, props.title,
                                           props.activityPricingId, "activityPricingId")} />
                                </React.Fragment>
                            );
                        },
                    },
                ]}
            />
        );
    }
}
export default ActivityGrid;
