import React, { Component } from "react";
import { Dropdown } from "semantic-ui-react";

const options = [
  { key: "Driving Licence", text: "Driving Licence", value: "Driving Licence" },
  { key: "18 Years Old +", text: "18 Years Old +", value: "18 Years Old +" },
  {
    key: "Closed Toed Shoes",
    text: "Closed Toed Shoes",
    value: "Closed Toed Shoes",
  },
];

class RequirementSelect extends Component {
  state = { options };
  handleAddition = (e, { value }) => {
    this.setState((prevState) => ({
      options: [{ text: value, value }, ...prevState.options],
    }));
  };
  handleChange = (e, { value }) => {
    this.setState({ currentValues: value });
  };

  render() {
    const { currentValues } = this.state;
    return (
      <Dropdown options={this.state.options} placeholder="Requirements" search selection fluid multiple allowAdditions value={currentValues} onAddItem={this.handleAddition} onChange={this.handleChange} className="tagger"/>
    );
  }
}

export default RequirementSelect;
