import React from 'react';
import ContentLoader from "react-content-loader";

const TotalSaleSkeleton = (props) => {
    return (
        <div>
            <ContentLoader width={700} height={420} viewBox="0 10 200 200" {...props}>
                <rect x="0" y="160" rx="0" ry="0" width="25" height="100" />
                <rect x="30" y="145" rx="0" ry="0" width="25" height="500" />
                <rect x="60" y="126" rx="0" ry="0" width="25" height="74" />
                <rect x="90" y="80" rx="0" ry="0" width="25" height="120" />
                <rect x="120" y="142" rx="0" ry="0" width="25" height="58" />
            </ContentLoader>

        </div>
    );
};


TotalSaleSkeleton.propTypes = {

};


export default TotalSaleSkeleton;