import React, { Component, Fragment } from "react";
import { Icon, Radio, Grid, Button, Popup, Menu } from "semantic-ui-react";
import { Link } from "react-router-dom";
import {
  commonFunctions,
  connect,
  bindActionCreators,
  Notifications,
  actions,
} from "../../../../../functional/global-import";
import { loadCustomers } from "../../../../../../store/actions/customer.action";
import SearchInput from "../../../../widgets/SearchInput";
import { CommonDeleteUpdateModal } from "../../..";
import { DataTable } from "../../../../organisms";
import { Checkbox } from "semantic-ui-react";
import Moment from "react-moment";
import { debounce } from "lodash";

const noMatchResultsStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontStyle: "italic",
  color: "#000",
};

const menustyle = {
  display: "flex",
  flexDirection: "column",
  border: "none",
};

const popupStyle = {
  padding: 0,
  border: "none",
  margin: "4px 0px",
};

const ellipsisIconStyle = {
  fontSize: 14,
  // app color
  color: "#f54952",
};

class ExistingCustomer extends Component {
  child = React.createRef();

  get initialState() {
    return {
      CutstomerDetailList: {
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        // businessLocationId: -1,
        customerId: -1,
        pageNo: 1,
        pageSize: 100,
        sortColumn: "",
        sortOrder: "",
        sortingArrow: "sort",
        gridDataLoading: false,
        searchValue: "",
        isActive: true,
      },
      customerForm: {
        // businessId: this.props.global.bussinessDetail.businessId,
        // businessLocationId: this.props.global.locationSelected.BusinessLocationId,
        customerId: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        stateId: "",
        emailId: "",
        city: "",
        zipcode: "",
        address: "",
        searchValue: "",
        actionPerformedBy: this.props.auth.userDetail.emailId,
      },
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      ...this.initialState,
      gridDetail: [],
      createcustomerButtonPanel: true,
      radioButton: false,
      selectedCustomer: [],
      isActive: false,
      isConfirmationModalOpen: false,
      anchorEle: null,
      actionOnSelectedCustomer: {},
      confirmationButtonText: "",
      confirmationHeaderText: "",
    };
    this.validator = commonFunctions.initializeSimpleValidator();
  }
  componentDidMount() {
    if (this.props.onSaveNewCustomer && this.props.onSaveNewCustomerId) {
      this.setState({
        CutstomerDetailList: {
          ...this.state.CutstomerDetailList,
          searchValue: this.props.onSaveNewCustomer,
        },
        customerForm: {
          ...this.state.customerForm,
          customerId: this.props.onSaveNewCustomerId,
        },
        selectedCustomer: {
          customerId: this.props.onSaveNewCustomerId,
          firstName: this.props.onSaveNewCustomer,
        },
      });
    }
    if (
      this.props.onSaveNewCustomer == null &&
      this.props.onSaveNewCustomerId == null
    ) {
      if (this.props.customerAutoPopulate) {
        this.setState({
          CutstomerDetailList: {
            ...this.state.CutstomerDetailList,
            searchValue: this.props.customerAutoPopulate,
          },
          customerForm: {
            ...this.state.customerForm,
            customerId: this.props.selectedCustomerId,
          },
          selectedCustomer: {
            customerId: this.props.selectedCustomerId,
            firstName: this.props.customerAutoPopulate,
          },
        });
      }

      if (this.props.customerFirstNameQuary) {
        this.setState({
          CutstomerDetailList: {
            ...this.state.CutstomerDetailList,
            searchValue: this.props.customerFirstNameQuary,
          },
          customerForm: {
            ...this.state.customerForm,
            customerId: this.props.customerIdFromQuery,
          },
          selectedCustomer: {
            customerId: this.props.customerIdFromQuery,
            firstName: this.props.customerFirstNameQuary,
          },
        });
      }
    }
  }
  getCustomerDetail() {
    // const { global } = this.props;
    const { CutstomerDetailList } = this.state;
    this.props.actions.apiCall({
      urls: ["GETCUSTOMER"],
      method: "GET",
      data: CutstomerDetailList,
      onSuccess: (response) => {
        this.setState({ ...this.state, gridDetail: response });
      },
    });
  }

  removeCustomer = () => {
    const { actionOnSelectedCustomer } = this.state;
    this.props.actions.apiCall({
      urls: ["DELETECUSTOMER"],
      method: "DELETE",
      data: {
        customerId: actionOnSelectedCustomer.customerId,
        actionPerformedBy: this.props.auth.userDetail.emailId,
      },
      onSuccess: (response) => {
        const { gridDetail } = this.state;
        let newGridDetail = gridDetail.filter((singleCUstomerObj) => {
          return singleCUstomerObj.customerId !== response.keyId;
        });

        this.setState({
          gridDetail: newGridDetail,
          isConfirmationModalOpen: false,
        });
      },
      showNotification: true,
    });

    return;
  };

  updateCustomerStatus = () => {
    this.props.actions.apiCall({
      urls: ["UPDATECUSTOMERTOGGLE"],
      method: "PUT",
      data: {
        customerId: this.state.customerId,
        businessId: this.props.global.bussinessDetail.businessId,
        isActive: this.state.isActive,
        actionPerformedBy: "admin",
      },
      onSuccess: (response) => {
        //var afterDeletedData = posts.filter(post => post.businessLocationId !== this.state.selectedId)
        this.setState({ activateModalopen: false }, () =>
          this.getCustomerDetail()
        );
      },
      showNotification: true,
    });
    this.setState({
      isConfirmationModalOpen: false,
    });
  };

  onHandleChange = (e, { name, value, type, checked, data }) => {
    var customerForm = commonFunctions.onHandleChange(
      e,
      { name, value, type, checked, data },
      this.state.customerForm
    );
    this.setState({ customerForm }, this.getCustomerDetail);
  };

  radioHandleChnage = (e) => {
    const { customerForm } = this.state;
    customerForm.customerId = e.customerId;
    this.setState({ customerForm, selectedCustomer: e });
  };
  dataSaveOfCustomer = () => {
    const { selectedCustomer, CutstomerDetailList } = this.state;
    localStorage.removeItem("availableItemDate")

    if (Object.keys(selectedCustomer).length === 0) {
      this.props.actions.showNotification(
        {
          title: "Error",
          message: "Please select the customer before creating a order ",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    } else {
      selectedCustomer["businessLocationId"] =
        CutstomerDetailList.businessLocationId;
      selectedCustomer["actionPerformedBy"] =
        this.props.auth.userDetail.emailId;
      selectedCustomer["businessId"] = CutstomerDetailList.businessId;
      this.props.actions.createOrder(selectedCustomer);
      this.props.onRadioClick();
    }
  };

  componentDidUpdate(nextProps) {
    if (
      this.props.customerTypePanel === false &&
      this.props.customerTypePanel !== nextProps.customerTypePanel
    ) {
      const { dispatch } = this.props;

      this.setState(
        {
          CutstomerDetailList: {
            ...this.state.CutstomerDetailList,
            searchValue: "",
          },
          customerForm: { ...this.state.customerForm, customerId: "" },
        },
        () => dispatch(loadCustomers(this.state.CutstomerDetailList))
      );
    }
  }
  handleToggle = () => {
    const { isActive } = this.state;
    this.setState({ isActive: !isActive });
  };

  onNextFunctionCall = debounce((val) => {
    const { CutstomerDetailList } = this.state;
    if (val === "") {
      return;
    } else if (CutstomerDetailList.searchValue !== "") {
      this.child.current.getDefaultData();
    }
  }, 1000);

  handleSearch = (e) => {
    const newOrderAccess = this.props.auth.userDetail.roleAndPermission?.filter(
      (item) => {
        return item.ModuleName === "new-order";
      }
    );
    const screenName = newOrderAccess[0].permission[0].Access === 0;
    if (screenName) {
      this.props.actions.showNotification(
        {
          title: "Error",
          message: "Customer List is not accessible to role assigned to you.",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    } else {
      const searchValue = e.target.value;
      const { CutstomerDetailList } = this.state;
      const prevSearch = CutstomerDetailList.searchValue;
      CutstomerDetailList["searchValue"] = searchValue;
      this.setState({ CutstomerDetailList }, () =>
        this.onNextFunctionCall(prevSearch)
      );
    }
  };

  openDeleteConfirmationModal = () => {
    const { actionOnSelectedCustomer } = this.state;
    const headerText = `Do you want to delete " ${actionOnSelectedCustomer.fullName}" ?`;

    this.setState({
      anchorEle: null,
      confirmationButtonText: "Delete",
      confirmationHeaderText: headerText,
      isConfirmationModalOpen: true,
    });
  };

  closeConfirmationModal = () => {
    this.setState({
      confirmationButtonText: "",
      confirmationHeaderText: "",
      isConfirmationModalOpen: false,
      actionOnSelectedCustomer: {},
    });
  };

  renderMenuItems = () => {
    const { actionOnSelectedCustomer } = this.state;

    return (
      <Menu style={menustyle} className="menu-list-items">
        <Menu.Item
          name="edit"
          onClick={() => this.props.onEditClickButton(actionOnSelectedCustomer)}
        >
          <Icon name="edit" />
          Edit
        </Menu.Item>

        <Link
          to={`${process.env.REACT_APP_PUBLIC_URL}/customer-summary/${actionOnSelectedCustomer.customerId}`}
          target="_blank"
          className="orange-color"
        >
          <Menu.Item name="client-sumary">
            <Icon name="file alternate outline" /> Client Summary
          </Menu.Item>
        </Link>

        <Link
          to={`${process.env.REACT_APP_PUBLIC_URL}/customer-summary/${actionOnSelectedCustomer.customerId}`}
          target="_blank"
          className="orange-color"
        >
          <Menu.Item name="client-sumary">
            <Icon name="history" />
            Order History
          </Menu.Item>
        </Link>
        <Menu.Item name="delete" onClick={this.openDeleteConfirmationModal}>
          <Icon name="trash alternate" />
          Delete
        </Menu.Item>
      </Menu>
    );
  };

  renderActionIconWithPopup = () => {
    const { anchorEle } = this.state;
    return (
      <Popup
        basic={true}
        context={anchorEle}
        style={popupStyle}
        content={this.renderMenuItems()}
        on="click"
        open={anchorEle != null}
        onClose={this.closeActionPopup}
      />
    );
  };

  closeActionPopup = () => {
    this.setState({
      anchorEle: null,
    });
  };

  renderConfirmationModal = () => {
    const {
      isConfirmationModalOpen,
      confirmationHeaderText,
      confirmationButtonText,
    } = this.state;

    return (
      <CommonDeleteUpdateModal
        onConfirm={this.removeCustomer}
        confirmationButtonText={confirmationButtonText}
        header={confirmationHeaderText}
        isModalOpen={isConfirmationModalOpen}
        onClose={this.closeConfirmationModal}
        cancelButtonText={"Cancel"}
        subHeader={"Deleted records can not be reverted back."}
      />
    );
  };

  openActionPopup = (e, customer) => {
    this.setState({
      actionOnSelectedCustomer: customer,
      anchorEle: e.target,
    });
  };

  render() {
    const {onAssignButton, api } = this.props;
        
    const { CutstomerDetailList,customerForm } = this.state;
    const fullState = this.state;
    const allFunction = this;


    return (
      <React.Fragment>
        {this.props.customerTypePanel && (
          <Grid>
            <SearchInput
              hasSearchIocn={true}
              iconPosition="left"
              data="searchValue"
              className="common-search-bar"
              fluid={true}
              searchValue={CutstomerDetailList.searchValue}
              placeholder="Search by Name &amp; Email &amp; Phone Number"
              searchHandler={this.handleSearch}
            />
            {this.state.createcustomerButtonPanel && (
              <Grid.Column width={6} textAlign="right">
                {" "}
                <Button
                  className="orange-btn"
                  onClick={this.props.onClickButton}
                >
                  <Icon name="plus" /> Create Customer{" "}
                </Button>
              </Grid.Column>
            )}
          </Grid>
        )}
        {CutstomerDetailList.searchValue && (
          <Fragment>
            {/* {this.state.CutstomerDetailList.searchValue && gridDetail.length === 0 && (
              <Grid>
                <Grid.Column width={16} style={noMatchResultsStyle}>
                  <p>No Matching Results</p>
                </Grid.Column>{" "}
              </Grid>
            )} */}

            <Grid>
              <Grid.Column width={16}>
                <div className="common-table">
                  <DataTable
                    celled
                    padded
                    ref={this.child}
                    getApiName="GETCUSTOMER"
                    getGridDataParamters={{
                      searchValue: CutstomerDetailList.searchValue,
                      isActive: true
                    }}
                    // searchOption={{show: true,placeHolder:"Search Customer-Detail"}}
                    // searchOption={{ show: true, placeHolder: "Search Orders" }}
                    //defaultSort={{sortColumn:"modifiedDate",sortOrder:"desc"}}
                    //defaultPageSize={10}
                    gridName="OrderCustomerGrid"
                    screenName="CustomerSummaryGrid"
                    fullState={fullState}
                    allFunction={allFunction}
                    tableHeight="310px"
                    emptyGrid="No records to show"
                    columns={[
                      {
                        headerName: "Select",
                        fieldName: "Select",
                        headerClass: "custom-width",
                        className: "custom-width",
                        isSorting: false,
                        Cell: (props) => {
                          return (
                            <Radio
                              className="custom-radio-btn"
                              name="radioGroup"
                              value={props.customerId}
                              checked={
                                props.customerId === customerForm.customerId
                              }
                              data="customerId"
                              onChange={() => this.radioHandleChnage(props)}
                            />
                          );
                        },
                      },
                      {
                        headerName: "Minor",
                        fieldName: "Minor",
                        headerClass: "custom-width",
                        className: "custom-width",
                        isSorting: false,
                        Cell: (props) => {
                          return (
                            <>
                              {props.minor === true ? (
                                <Checkbox
                                  className="custome-checkbox "
                                  data="Minor"
                                  checked={props.minor}
                                />
                              ) : (
                                ""
                              )}
                            </>
                          );
                        },
                      },

                      {
                        headerName: "Name",
                        fieldName: "FirstName",
                        isSorting: true,
                        className: "textOverflowHidden",
                        Cell: (props) => {
                          return (
                            <Link
                              title={
                                props.firstName +
                                " " +
                                (props.lastName !== null ? props.lastName : "")
                              }
                              to={`${process.env.REACT_APP_PUBLIC_URL}/customer-summary/${props.customerId}`}
                              className="orange-color"
                              onClick={() => {
                                //this.props.history.push(`${env.PUBLIC_URL}/customer-summary/${props.customerId}`);
                                this.props.closeModal();
                              }}
                            >
                              {props.firstName} {props.lastName}{" "}
                            </Link>
                          );
                        },
                      },
                      {
                        headerName: "Flag Profile",
                        fieldName: "FlagProfile",
                        Cell: (props) => {
                          return (
                            <>
                              {props?.flagProfile ? (
                                <Icon
                                  size="big"
                                  name={
                                    props.flagProfile
                                      ? "flag"
                                      : "flag outline"
                                  }
                                  style={
                                    props.flagProfile
                                      ? { color: "red" }
                                      : { color: "" }
                                  }
                                />
                              ) : (
                                <Icon
                                  size="big"
                                  name={"flag outline"}
                                />
                              )}
                            </>
                          );
                        },
                      },
                      {
                        headerName: "Document",
                        fieldName: "document",
                        className: "textOverflowHidden",
                        isSorting: true,
                        Cell: (props) => {

                          const document = props?.document === null ? "-" : props?.document
                          return <span style={{ color: "#007500" }}
                            title={document}>
                            <strong>
                              {document}
                            </strong>
                          </span>
                        },
                      },
                      {
                        headerName: "Date Signed",
                        fieldName: "signedDate",
                        className: "textOverflowHidden",
                        Cell: (props) => {

                          const signedDate = props?.signedDate === null ? "-" : props?.signedDate
                          return <span className="orange-color"
                            title={signedDate}>
                            {signedDate}</span>
                        },
                      },
                      {
                        headerName: "Email",
                        fieldName: "EmailId",
                        isSorting: true,
                        className: "textOverflowHidden",
                        Cell: (props) => {
                          return (
                            <a
                              title={
                                props.emailId === null ? "-" : props.emailId
                              }
                              className="orange-color"
                              href={`mailto:${props.emailId}`}
                            >
                              {props.emailId === null ? "-" : props.emailId}
                            </a>
                          );
                        },
                      },
                     
                      {
                        headerName: "Phone No.",
                        fieldName: "phoneNumber",
                        className: "textOverflowHidden",
                        isSorting: true,
                      },

                      {
                        headerName: "DOB",
                        fieldName: "DOB",
                        isSorting: true,
                        className: "textOverflowHidden",
                        Cell: (props) => {
                          return (
                            <>
                              {props.dob !== null ? (
                                <Moment format="MM/DD/YYYY">
                                  {props?.dob}
                                </Moment>
                              ) : (
                                "-"
                              )}
                            </>
                          );
                        },
                      },
                      {
                        headerName: "Action",
                        fieldName: "Action",
                        isSorting: false,
                        Cell: (props) => {
                          return (
                            <React.Fragment>
                              <Icon
                                name="ellipsis horizontal"
                                onClick={(e) => this.openActionPopup(e, props)}
                                style={ellipsisIconStyle}
                                size="large"
                                link
                              />
                            </React.Fragment>
                          );
                        },
                      },
                    ]}
                  />
                </div>
              </Grid.Column>
              <Grid.Column width={16} textAlign="right">
                {onAssignButton == undefined ? null : onAssignButton ==
                  false ? (
                  <Button
                    className="orange-btn"
                    onClick={this.dataSaveOfCustomer}
                  >
                    Continue
                  </Button>
                ) : (
                  <Button
                    loading={api.isApiLoading}
                    className="green-btn"
                    onClick={() =>
                      this.props?.onAssignSeasonPassButton(
                        customerForm.customerId
                      )
                    }
                  >
                    Assign to Passes
                  </Button>
                )}
              </Grid.Column>
            </Grid>
          </Fragment>
        )}
        {this.renderActionIconWithPopup()}
        {this.renderConfirmationModal()}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (storeState) => {
  return {
    api: storeState.api,
    global: storeState.global,
    auth: storeState.auth,
    customerState: storeState.customerState,
    orderState: storeState.orderState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
      createOrder: bindActionCreators(actions.createOrder, dispatch),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExistingCustomer);
