import { Button, Grid, Modal, Header } from "semantic-ui-react";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { connect, bindActionCreators, actions, withRouter } from "../../../../functional/global-import";

const useBeforeUnload = ({ when, title, setShowPrompt }) => {
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault()
            // setShowPrompt(true)
            event.returnValue = "";
            return;
        }
        if (when) {
            window.addEventListener('beforeunload', handleBeforeUnload)
        }
        return () => window.removeEventListener('beforeunload', handleBeforeUnload)
    }, [when, title])
}

const RouterPrompt = ({ when, onOK, onCancel, title, stateReference, showmessage }) => {
    // 
    const history = useHistory();
    const [showPrompt, setShowPrompt] = useState(false);
    const [currentPath, setCurrentPath] = useState("");

    useBeforeUnload({
        when,
        message: title,
        setShowPrompt
    });
    useEffect(() => {

        if (when) {
            history.block((prompt) => {
                // 
                setCurrentPath(prompt.pathname);
                setShowPrompt(true);
                return "true";
            });
        } else {
            history.block(() => { });
        }

        return () => {
            history.block(() => { });
        };
    }, [history, when]);

    const handleOK = useCallback(async () => {
        if (onOK) {
            const canRoute = await Promise.resolve(onOK());
            if (canRoute) {
                history.block(() => { });
                history.push(currentPath);
                // history.push("/RehntitBoatServices/more-setting");
            }
        }
        let warnUserState = stateReference.props.warnUserState;
        warnUserState.isFormChanged = false;
        stateReference.props.actions.storeWarnUser(warnUserState);
        setShowPrompt(false);
    }, [currentPath, history, onOK]);

    const handleCancel = useCallback(async () => {
        
        if (onCancel) {
            const canRoute = await Promise.resolve(onCancel());
            if (canRoute) {
                history.block(() => { });
                history.push(currentPath);
            }
        }
        setShowPrompt(false);
    }, [currentPath, history, onCancel]);

    return showPrompt ? (
        <Modal open={showPrompt}  closeIcon onClick={handleCancel} size={"tiny"}>
            <Modal.Content>
                <Grid>
                    <Grid.Column width={16} textAlign="center">
                        <Header as="h3" className="orange-color">
                            {title}
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={16} textAlign="center">
                        <Button className="blue-btn" onClick={handleCancel} >
                            Cancel
                        </Button> &nbsp;&nbsp;
                        <Button className="orange-btn" onClick={handleOK} >
                            Yes
                        </Button>
                    </Grid.Column>
                </Grid>
            </Modal.Content>
        </Modal>
    ) : null;
};

const mapStateToProps = state => {
    return {
        api: state.api,
        warnUserState: state.warnUserState,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            storeWarnUser: bindActionCreators(actions.storeWarnUser, dispatch),
        }
    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RouterPrompt));