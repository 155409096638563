import React, { Component } from "react";
import { Button, Grid, Form, Modal } from "semantic-ui-react";
import { connect, bindActionCreators, actions, Link, commonFunctions } from "../../../../functional/global-import";

class ForgetPasswordModal extends Component {
  // Put all key & values that need to reset on form submit and reset form.
  get initialState() {
    return {
      resetPassword: { businessId: "", emailId: "" }
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      ...this.initialState,
      resetpassworddetil: false,
      resetpasswordmain: true,
    };
    this.validator = commonFunctions.initializeSimpleValidator();
  }

  componentDidMount() {
    const resetPassword = this.state.resetPassword;
    resetPassword.businessId = this.props.global.bussinessDetail.businessId;
    this.setState({ resetPassword })
  }

  // This function is used to set handle change
  onHandleChange = (e, { name, value, type, checked, data }) => {
    e.preventDefault();
    var resetPassword = commonFunctions.onHandleChange(e, { name, value, type, checked, data }, this.state.resetPassword);
    this.setState({ resetPassword });
  }

  // This function is used to set handle submit
  onHandleSubmit = (e) => {

    var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
    if (isFormValid) {
      //  Api call login post 
      this.props.actions.apiCall({
        urls: ["FORGOTPASSWORD"], method: "POST", data: this.state.resetPassword, onSuccess: (response) => this.showResetDetails(response), showNotification: true
      });
    }
  };

  showResetDetails = (response) => {
    const resetPassword = this.state.resetPassword;
    resetPassword.emailId = "";
    this.setState({
      resetPassword,
      resetpassworddetil: true,
      resetpasswordmain: false
    });
  };
  onClose = () => {
    const resetPassword = this.state.resetPassword;
    resetPassword.emailId = "";
    this.setState({ resetPassword, resetpassworddetil: false, resetpasswordmain: true })
    this.props.closeModal()
  }

  showMain = () => {
    this.setState({
      resetpassworddetil: false,
      resetpasswordmain: true
    });
  };
  render() {
    const { resetPassword } = this.state;
    const { api } = this.props;
    return (
      <Modal open={this.props.openModal} closeIcon onClose={this.onClose} size={"mini"} >
        <Modal.Header>Reset Password</Modal.Header>
        <Modal.Content>
          <Grid>
            {this.state.resetpasswordmain && (
              <React.Fragment>
                <Grid.Column width={16}>
                  <p className="mbt">Enter email to reset password</p>
                  <Form>
                    <Form.Input fluid textAlign="left" name="txtEmail" placeholder="E-mail address" error={this.validator.message("email", resetPassword.emailId, "required|email")} data="emailId" onChange={this.onHandleChange} value={resetPassword.emailId} />
                  </Form>
                </Grid.Column>

                <Grid.Column width={16}>
                  <Button loading={api.isApiLoading} className="orange-btn" onClick={this.onHandleSubmit}> Send </Button>
                </Grid.Column>
              </React.Fragment>
            )}

            {this.state.resetpassworddetil && (
              <React.Fragment>
                <Grid.Column width={16}>
                  <p className="mbt"> A reset password link has been sent to your registered email  account. Please click the reset password link to set your new password.</p>
                  <p className="mbt">Not received the email yet?</p>
                  <p> Please check your spam folder, or
                    <Link className="orange-color" onClick={this.showMain}> try again.</Link>
                  </p>
                </Grid.Column>
              </React.Fragment>
            )}
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}


const mapStateToProps = state => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ForgetPasswordModal);
