import React, { Component } from "react";
import {
  Grid,
  Button,
  Form,
  Dimmer,
  Checkbox,
  Loader,
  Input,
  Header,
  Icon,
  Table,
  GridColumn,
  Divider,
  Radio,
} from "semantic-ui-react";
import { DateFormatInput } from "../../../../components";
import {
  bindActionCreators,
  actions,
  connect,
  Notifications,
  commonFunctions,
} from "../../../../functional/global-import";
import {
  CommonCustomField,
  GlobalCodeSelect,
  GlobalRadioGroup,
  CommonBlackOutDates,
  DataTable,
} from "../../../../components";
import EmailNotification from "../../../../../pages/more-setting/pricing-taxes-setting/activity-pricing/email-notification";

class SeasonPassPricing extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  // Form.Select
  render() {
    const { api, allFunction, fullState } = this.props;
    const customerUse = commonFunctions.getGlobalCodeDetails(
      this.props.global.codes,
      "CategoryType",
      "Unlimited Uses"
    ).globalCodeId;
    const boolenvalue =
      fullState.seasonPassPrice.seasonPassUsageType === customerUse;
    return (
      <React.Fragment>
        {api.isApiLoading && (
          <Dimmer active inverted>
            <Loader />
          </Dimmer>
        )}
        <Grid>
          <Grid.Column mobile={16} className="editSessionFormWrapper">
            <div className="controlField">
              <Input
                label={{ basic: true, content: "$" }}
                type="number"
                labelPosition="left"
                data="seasonPassPrice"
                step="any"
                placeholder="Cost price"
                fluid
                name="txtValue"
                required
                value={fullState.seasonPassPrice.seasonPassPrice}
                className="form-control"
                onChange={allFunction.onHandleChangePrice}
                error={allFunction.validator.message(
                  "costPrice",
                  fullState.seasonPassPrice.seasonPassPrice,
                  "required|integer|min:0,num|numeric"
                )}
              />
              {allFunction.validator.message(
                "costPrice",
                fullState.seasonPassPrice.seasonPassPrice,
                "required|integer|min:0,num|numeric"
              )}
            </div>

            <div className="amount-percentage">
              <label className="label-margin">Amount</label>
              <Checkbox
                className="custom-toggle"
                label="Percentage"
                value={fullState.seasonPassPrice.seasonPassPricing}
                name="discountType"
                checked={fullState.seasonPassPrice.seasonPassPricing}
                onChange={allFunction.sesonPassToggal}
              />
            </div>
            {fullState.seasonPassPrice.seasonPassPricingType === "A" && (
              <div className="controlField">
                <Input
                  label={{ basic: true, content: "$" }}
                  type="number"
                  labelPosition="left"
                  data="amountDiscount"
                  step="any"
                  placeholder="Discount Amt."
                  fluid
                  name="txtValue"
                  required
                  value={fullState.seasonPassPrice.amountDiscount}
                  className="form-control discount-amount"
                  onChange={allFunction.onHandleChangePrice}
                  error={allFunction.validator.message("Amount",
                    fullState.seasonPassPrice.amountDiscount, "required|numeric|min:1,num")} />
                {allFunction.validator.message(
                  "Amount", fullState.seasonPassPrice.amountDiscount, "required|numeric|min:1,num")}
              </div>)}
            {fullState.seasonPassPrice.seasonPassPricingType === "P" && (
              <div className="error">
                <Input
                  label={{ basic: true, content: "%" }}
                  type="number"
                  labelPosition="right"
                  data="discountAmount"
                  step="any"
                  placeholder="Discount Amt."
                  fluid
                  name="txtValue"
                  required
                  value={fullState.seasonPassPrice.discountAmount}
                  className="form-control discount-amount"
                  onChange={allFunction.onHandleChangePrice}
                  error={allFunction.validator.message(
                    "Precentage",
                    fullState.seasonPassPrice.discountAmount,
                    "required|min:1,num,|max:100,num")}
                />
                {allFunction.validator.message(
                  "Precentage",
                  fullState.seasonPassPrice.discountAmount,
                  "required|min:1,num,|max:100,num")}
              </div>
            )}
            <DateFormatInput
              name="txtblackOutStartDate"
              placeholder="Activation Date"
              value={fullState.seasonPassPrice.activationDate}
              iconPosition="right"
              onChange={allFunction.onHandleChangePricing}
              data="activationDate"
              error={allFunction.validator.message(
                "activationDate",
                fullState.seasonPassPrice.activationDate,
                "required"
              )}
            />

            <DateFormatInput
              id={"expirationDate"}
              name="txtblackOutEndDate"
              placeholder="Expiration Date"
              minDate={
                fullState.seasonPassPrice.activationDate
                  ? fullState.seasonPassPrice.activationDate
                  : ""
              }
              value={fullState.seasonPassPrice.expirationDate}
              iconPosition="right"
              onChange={allFunction.onHandleChangePricing}
              data="expirationDate"
              error={allFunction.validator.message(
                "expirationDate",
                fullState.seasonPassPrice.expirationDate,
                "required"
              )}
            />
          </Grid.Column>
          <Grid.Column mobile={16} className="editSessionFormWrapper">
            <Form>
              <Form.Group inline textAlign="right">
                <GlobalRadioGroup
                  name="ddRadioGroup"
                  className="seasonPassUsage"
                  data="seasonPassUsageType"
                  showRadioButtons={["Custom", "Unlimited Uses"]}
                  categoryType="CategoryType"
                  onChange={allFunction.onHandleChangeCustom}
                  value={fullState.seasonPassPrice.seasonPassUsageType}
                />
              </Form.Group>
            </Form>
            <Form.Input
              placeholder="Custom Qty."
              className="customQualityField"
              disabled={boolenvalue}
              fluid
              data="seasonPassUsageTypeValue"
              value={fullState.seasonPassPrice.seasonPassUsageTypeValue}
              onChange={allFunction.onHandleChangePricing}
            />
            <label
              disabled={boolenvalue}
              className="season-label globalCodeSelectField">
              <b>Uses Per</b>
            </label>
            <Form.Select
              disabled={boolenvalue}
              placeholder="User Per"
              data="durationTypeId"
              value={fullState.seasonPassPrice.durationTypeId}
              search
              selection
              options={fullState.TimeDurationMin}
              onChange={allFunction.onHandleChangePricing}
            />
          </Grid.Column>
          <Grid.Column
            className="customuser"
            mobile={16}
            tablet={8}
            computer={2}
          ></Grid.Column>
          <Grid.Column mobile={16} tablet={2} computer={2}></Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={6}></Grid.Column>
          <CommonCustomField
            fullState={fullState}
            allFunction={allFunction}
            stateForm="seasonPassPrice"
            variableName="customField"
            showRequired={true}
          />
          <GridColumn width={16}>
            <Divider />
          </GridColumn>
          <Grid.Column mobile={16} tablet={2} computer={16}>
            <div className="bookedEmail">
              <EmailNotification
                fullState={fullState}
                stateForm="seasonPassPrice"
                allFunction={allFunction}
              />
            </div>
          </Grid.Column>
          <CommonBlackOutDates
            fullState={fullState}
            allFunction={allFunction}
            stateForm="seasonPassPrice"
            variableName="seasonPassBlackOutDateValue"
          />

          <Grid.Column width={16} textAlign="right">
            <Button className="blue-btn" onClick={allFunction.backStepPricng}>
              {" "}
              Back{" "}
            </Button>
            <Button
              className="orange-btn"
              onClick={() => allFunction.postSeasonPricing()}
            >
              {" "}
              Save{" "}
            </Button>
          </Grid.Column>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    api: state.api,
    auth: state.auth,
    global: state.global,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SeasonPassPricing);
