import React, { Component, Fragment } from "react";
import {
  connect,
  bindActionCreators,
  actions,
  Notifications,
  commonFunctions,
} from "../../../../../../functional/global-import";
import {
  addSelectedDateRange,
  addSelectedItems,
} from "../../../../../../../store/actions/order.actions";
import RentalItems from "../rental/rental-items";
import RentalChooseDates from "../rental/rental-choose-date";
import AddOnAllBusinessType from "../../common/addOn-all-business-type";
import CancelationCommonComponent from "../../common/cancelation-common";
import moment from "moment";
import { Dimmer, Loader } from "semantic-ui-react";
import RentalAvailabilityModal from "./rental-avaiblity -modal";
class Rental extends Component {
  state = {
    hideRentalDate: false,
    dateTime: { startDate: "", endDate: "" },
    hideAddCategory: true,
    productCategoryDetail: "",
    showChooseDates: false,
    showAddon: false,
    showRentalItems: this.props.customQuestionEdit.customQuestionEdit
      ? false
      : true,
    showCancellationComponent: false,
    editShowCancellationComponent:
      this.props.customQuestionEdit.customQuestionEdit,
    addOnDetails: [],
    selectedItemsDetails: null,
    productCustomQuestion: this.props.customQuestionEdit.customQuestionEdit
      ? this.props.customQuestionEdit.productCustomQuestion
      : null,
    customfields: null,
    rentalAvailabilityModal: false,
    isAddMoreCLicked: null,
    rentalAvaibilityYes: null,
    rentalAvaibilityNo: null,
    productDateDetails: {
      availability: {},
      blackOut: [],
    },
    // showItemGrid: false,
  };

  showRentalCategory = (dateTime) => {
    let date = dateTime.split(" - ");
    this.setState(
      {
        dateTime: {
          ...this.state.dateTime,
          startDate: date[0],
          endDate: date[1] ? date[1] : date[0],
        },
        hideRentalDate: true,
      },
      () => this.props.actions.addSelectedDateRange(this.state.dateTime)
    );
  };

  showRentalChooseDates = (item) => {
    if (item.length > 0) {
      this.props.actions.addSelectedItems(item);
      this.setState({ selectedItemsDetails: item }, () =>
        this.getRentalItemPrice()
      );
    } else {
      this.props.actions.showNotification(
        {
          title: "Error",
          message: "Please select atleast one item",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    }
  };

  getRentalItemPrice = () => {
    this.props.actions.apiCall({
      urls: ["GETORDERRENTALPRICE"],
      method: "GET",
      data: {
        productId: this.props.global.inventoryDetail.productId,
        inWebsiteWidget: false,
      },
      //  showNotification: true,
      onSuccess: (response) => {
        var addonDetails = null;
        if (response[0].addOn) {
          addonDetails =
            response[0].addOn &&
            JSON.parse(response[0].addOn).map(function (el) {
              var addOnObj = Object.assign({}, el);
              addOnObj.qunatity = 0;
              addOnObj.minvalue = el.minAddOns;
              return addOnObj;
            });
        }
        const briefDetails = JSON.parse(response[0].briefDetails)[0];
        const newRequirement =
          briefDetails && briefDetails.Requirements !== null
            ? JSON.parse(briefDetails.Requirements)
            : null;
        briefDetails.Requirements = newRequirement;
        const productCustomQuestion =
          briefDetails.ProductCustomQuestion &&
          JSON.parse(briefDetails.ProductCustomQuestion);
        const customfield =
          response &&
          response[0] &&
          response[0].customfields &&
          JSON.parse(response[0].customfields);

        const questiondetails =
          productCustomQuestion &&
          productCustomQuestion.map((customObj) => {
            if (customObj.associateAddOn) {
              customObj.associateAddOn = JSON.parse(customObj.associateAddOn);
            }
            return {
              ...customObj,
              isQuestionAnswered: "",
            };
          });

        const hasToShowCancellationComponent =
          briefDetails.CancellationPolicy !== null ||
          briefDetails.ProductCustomQuestion !== null ||
          briefDetails.Requirements !== null
            ? true
            : false;
        this.props.actions.addMultipleFlatFeeAndOtherDetails(response);
        const isToShowAddon =
          JSON.parse(response[0].addOn).length > 0 ? true : false;
        this.setState({
          showChooseDates: true,
          showRentalItems: false,
          isToShowAddon: isToShowAddon,
          addOnDetails: addonDetails,
          hasToShowCancellationComponent: hasToShowCancellationComponent,
          briefDetails: briefDetails,
          productCustomQuestion: questiondetails,
          customfields: customfield,
        });
      },
    });
  };

  moveToSignature = async (bookingslot) => {
    const { orderState } = this.props;
    let datebookingslot = bookingslot.bookingslot;
    let data = {
      startTime:
        datebookingslot.startTimeHour +
        ":" +
        datebookingslot.startTimeMinute +
        datebookingslot.startTimeAm,
      endTime:
        datebookingslot.endTimeHour +
        ":" +
        datebookingslot.endTimeMinute +
        datebookingslot.endTimeAm,
    };

    let isDateValid = true;
    if (bookingslot.value === "custom") {
      if (
        bookingslot.selectedDurationDetails &&
        bookingslot.selectedDurationDetails.length > 0
      ) {
      } else {
        const selectedDate = localStorage.getItem("availableItemDate");
        let startDate = `${bookingslot.newTimeRange.from} ${data.startTime}`;
        let endDate = `${
          bookingslot.newTimeRange.to === ""
            ? bookingslot.newTimeRange.from
            : bookingslot.newTimeRange.to
        } ${data.endTime}`;

        const startDateHour = moment(startDate, "YYYY-MM-DD, hh:mm A").format(
          "HH"
        );
        const startDateMinute = moment(startDate, "YYYY-MM-DD, hh:mm A").format(
          "mm"
        );

        const endDateHour = moment(endDate, "YYYY-MM-DD, hh:mm A").format("HH");
        const endDateMinute = moment(endDate, "YYYY-MM-DD, hh:mm A").format(
          "mm"
        );

        startDate = new Date(bookingslot.newTimeRange.from);
        startDate.setHours(startDateHour, startDateMinute);

        endDate = new Date(
          bookingslot.newTimeRange.to === ""
            ? bookingslot.newTimeRange.from
            : bookingslot.newTimeRange.to
        );
        endDate.setHours(endDateHour, endDateMinute);
        selectedDate ? isDateValid = true : isDateValid = endDate > startDate;
        
      }
    }

    if (isDateValid) {
      if (
        bookingslot.selectedDurationDetails &&
        bookingslot.selectedDurationDetails.length > 0
      ) {
        this.onSaveSlotBasedPrice(data, bookingslot);
      } else {
        this.props.actions.addSelectedDateRange(data);
        const nextState = orderState.currentOrder.orderDetails
          .filter(
            (orderDetail) =>
              orderDetail.id === orderState.currentOrderDetails.id
          )
          .map((item, idx) => {
            let startTime = data.startTime;
            let endTime = data.endTime;
            return {
              orderId: orderState.currentOrder.orderId,
              businessCategoryType: item.typeId,
              startDate: `${bookingslot.newTimeRange.from} ${startTime}`,
              endDate:
                bookingslot.value === "custom"
                  ? `${
                      bookingslot.newTimeRange.to === ""
                        ? bookingslot.newTimeRange.from
                        : bookingslot.newTimeRange.to
                    } ${endTime}`
                  : null,
              productId: item.selectedItems[0].productid,
              productDetailId: item.selectedItems.map(
                (item) => item.productdetailid
              ),
              rentalPricingId: bookingslot.selectedFlatFee,
              actionPerformedBy: this.props.auth.userDetail.emailId,
              durationTime:
                bookingslot.value === "duration" ? bookingslot.count : null,
              durationId:
                bookingslot.value === "duration"
                  ? bookingslot.durationId
                  : null,
              inOpenTime: bookingslot.value === "open" ? true : false,
            };
          });
        this.onAddOrderProductDetail(nextState[0], bookingslot);
      }
    }
    // else {
    //   this.props.actions.showNotification(
    //     {
    //       title: "Error",
    //       message: "End date/time must be greater than start date/time ",
    //       position: "br",
    //       autoDismiss: commonFunctions.notificationTime(),
    //     },
    //     "error"
    //   );
    // }
  };

  onSaveSlotBasedPrice = (data, bookingslot) => {
    const { orderState } = this.props;
    this.props.actions.addSelectedDateRange(data);
    const nextState = orderState.currentOrder.orderDetails
      .filter(
        (orderDetail) => orderDetail.id === orderState.currentOrderDetails.id
      )
      .map((item, idx) => {
        let startTime = data.startTime;
        let endTime = data.endTime;
        return {
          orderId: orderState.currentOrder.orderId,
          businessCategoryType: item.typeId,
          startDate: `${bookingslot.newTimeRange.from} ${bookingslot.customDurationForm.selectedCustomDurationSlots}`,
          endDate: null,
          productId: item.selectedItems[0].productid,
          productDetailId: item.selectedItems.map(
            (item) => item.productdetailid
          ),
          rentalPricingId:
            bookingslot.selectedDurationDetails[0].rentalPricingId,
          durationTime: bookingslot.selectedDurationDetails[0].durationTime,
          durationId: bookingslot.selectedDurationDetails[0].durationId,
          inOpenTime: false,
          actionPerformedBy: this.props.auth.userDetail.emailId,
        };
      });
    this.onAddOrderProductDetail(nextState[0], bookingslot);
  };

  onAddOrderProductDetail = (dataObj, bookingslot) => {
    this.props.actions.apiCall({
      urls: ["ADDORDERPRODUCTDETAIL"],
      method: "POST",
      data: dataObj,
      showNotification: true,
      onSuccess: (response) => {
        const { orderState } = this.props;
        if (response !== null) {
          if (bookingslot.addMoreItem) {
            this.props.onShowRentalDate();
          } else if (bookingslot.rentalAviablity) {
            this.props.backBtnInventoryView();
          } else if (this.state.isToShowAddon) {
            this.setState({
              showAddon: this.state.isToShowAddon,
              showChooseDates: false,
            });
          } else if (this.state.hasToShowCancellationComponent) {
            this.setState({
              showChooseDates: false,
              showCancellationComponent:
                this.state.hasToShowCancellationComponent,
            });
          } else {
            this.props.next(2, false, {
              BusinessCategoryType: dataObj.businessCategoryType,
              ProductId: dataObj.productId,
            });
          }

          this.props.actions.getOrderItems(orderState.currentOrder.orderId);

          this.setState({ orderDetailId: response.orderDetailId });
          return response;
        } else {
          this.props.actions.showNotification(
            {
              title: "Error",
              message: "Something Went Wrong in Create Customer Process!!",
              position: "br",
              autoDismiss: commonFunctions.notificationTime(),
            },
            "error"
          );
        }
      },
    });
  };

  onAddMoreItem = (isAddMoreCLicked) => {
    this.setState({
      rentalAvailabilityModal: !this.state.rentalAvailabilityModal,
      isAddMoreCLicked: isAddMoreCLicked,
      rentalAvaibilityYes: true,
      rentalAvaibilityNo: false,
    });
  };
  onHandleRentalInventory = (
    e,
    isAddMoreCLicked,
    rentalAvaibilityYes,
    rentalAvaibilityNo
  ) => {
    if (rentalAvaibilityNo) {
      this.props.onShowRentalDate();
    } else if (rentalAvaibilityYes) {
      this.props.backBtnInventoryView();
    }
  };

  onAddOnContinueClick = (isAddMoreCLicked) => {
    if (isAddMoreCLicked) {
      this.onAddMoreItem(isAddMoreCLicked);
      // this.props.onShowRentalDate();
    } else {
      if (this.state.hasToShowCancellationComponent) {
        this.setState({
          showAddon: false,
          showCancellationComponent: this.state.hasToShowCancellationComponent,
        });
      } else {
        this.props.next(2);
      }
    }
  };

  // onAddMoreClicked = (isAddMoreClicked) => {
  //   if (isAddMoreClicked) {
  //     this.props.onShowRentalDate();
  //   } else {
  //     this.props.next(2);
  //   }
  //   this.props.actions.getOrderItems(
  //     this.props.orderState.currentOrder.orderId
  //   );
  // };

  onCancelContinueClick = (isAddMoreClicked) => {
    if (isAddMoreClicked) {
      this.props.onShowRentalDate();
    } else {
      this.props.next(2);
    }
  };

  getProductRental = () => {
    this.setState({ loadingApi: true, click: true });
    this.props.actions.apiCall({
      urls: ["GETPRODUCT"],
      method: "GET",
      data: {
        productId: this.props.global.inventoryDetail.productId,
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        PageNo: 1,
        PageSize: 100,
      },
      onSuccess: (response) => {
        const blackOuts = JSON.parse(response[0].blackOutDates).map((item) => {
          return item;
        });
        const availability = JSON.parse(response[0].availability);
        this.setState({
          productDateDetails: {
            availability: availability[0],
            blackOut: blackOuts,
          },
        });
      },
      onFinally: () => {
        // this.setState({ loadingApi: false });
      },
    });
  };

  componentDidMount() {
    this.setState({
      hideRentalDate: false,
      dateTime: { startDate: "", endDate: "" },
      hideAddCategory: true,
      showItemGrid: false,
      productCategoryDetail: "",
      showChooseDates: false,
    });
    this.getProductRental();
  }

  backToInventory = () => {
    this.setState({ showRentalItems: false });
  };

  backToRentalItems = () => {
    this.setState({ showChooseDates: false, showRentalItems: true });
  };

  render() {
    const {
      dateTime,
      showChooseDates,
      showRentalItems,
      isToShowAddon,
      showAddon,
      addOnDetails,
      showCancellationComponent,
      briefDetails,
      orderDetailId,
      hasToShowCancellationComponent,
      selectedItemsDetails,
      productCustomQuestion,
      editShowCancellationComponent,
      customfields,
      productDateDetails,
    } = this.state;
    const {
      rentalEdit,
      allFunction,
      rentalDatesSelected,
      bookingSlot,
      startTime,
      endTime,
      api,
      currentDateFromApi,
      productCategoryDetail,
      initialMonthDate,
      initialMonth

    } = this.props;

    return (
      <Fragment>
        {api.isApiLoading && (
          <Dimmer active inverted>
            {" "}
            <Loader size="small"></Loader>{" "}
          </Dimmer>
        )}
        {showRentalItems && (
          <RentalItems
            startTime={startTime}
            endTime={endTime}
            rentalDatesSelected={rentalDatesSelected}
            allFunction={allFunction}
            next={this.props.next}
            rentalEdit={rentalEdit}
            backBtnInventoryView={this.props.backBtnInventoryView}
            dateTime={dateTime}
            backToInventory={this.props.backToInventory}
            onShowRentalDate={this.props.onShowRentalDate}
            productCategoryDetail={this.props.productCategoryDetail}
            showRentalChooseDates={this.showRentalChooseDates}
          />
        )}
        {showChooseDates && (
          <RentalChooseDates
            currentDateFromApi={currentDateFromApi}
            startTime={startTime}
            endTime={endTime}
            customfields={customfields}
            bookingSlot={bookingSlot}
            selectedItemsDetails={selectedItemsDetails}
            rentalDatesSelected={rentalDatesSelected}
            productId={this.props.global.inventoryDetail.productId}
            showAddon={isToShowAddon}
            hasToShowCancellationComponent={hasToShowCancellationComponent}
            productCategoryDetail={productCategoryDetail}
            initialMonthDate={initialMonthDate}
            moveToSignature={this.moveToSignature}
            backToRentalItems={this.backToRentalItems}
            productDateDetails={productDateDetails}
            initialMonth={initialMonth}
          />
        )}
        {showAddon && (
          <AddOnAllBusinessType
            stateRef={this}
            businessTypeName="Rental"
            orderDetailId={orderDetailId}
            onContinueClick={this.onAddOnContinueClick}
            addOnDetails={addOnDetails}
            backBtnInventoryView={this.props.backBtnInventoryView}
            hasToShowCancellationComponent={hasToShowCancellationComponent}
          />
        )}

        {editShowCancellationComponent && (
          <CancelationCommonComponent
            businessTypeName="Rental"
            allFunction={this}
            orderDetailId={this.props.customQuestionEdit.questionOrderDetailId}
            productId={this.props.customQuestionEdit.questionProductId}
            productCustomQuestion={productCustomQuestion}
            showAddon={false}
            briefDetails={false}
            backBtnInventoryView={this.props.backBtnInventoryView}
            editQuestion={true}
            onEditQuestionNext={() => this.props.next(3)}
          />
        )}

        {showCancellationComponent && (
          <CancelationCommonComponent
            businessTypeName="Rental"
            allFunction={this}
            orderDetailId={orderDetailId}
            backBtnInventoryView={this.props.backBtnInventoryView}
            productId={this.props.global.inventoryDetail.productId}
            productCustomQuestion={productCustomQuestion}
            briefDetails={briefDetails}
            showAddon={isToShowAddon}
            onNext={this.onCancelContinueClick}
          />
        )}
        {this.state.rentalAvailabilityModal && (
          <RentalAvailabilityModal
            openModal={this.state.rentalAvailabilityModal}
            closeModal={this.onAddMoreItem}
            onHandleRentalInventory={this.onHandleRentalInventory}
            isAddMoreCLicked={this.state.isAddMoreCLicked}
            rentalAvaibilityYes={this.state.rentalAvaibilityYes}
            rentalAvaibilityNo={this.state.rentalAvaibilityNo}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
    newOrderDetail: state.newOrderDetail,
    orderState: state.orderState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      addSelectedDateRange: bindActionCreators(addSelectedDateRange, dispatch),
      addSelectedItems: bindActionCreators(addSelectedItems, dispatch),
      addSelectedDateRange: bindActionCreators(addSelectedDateRange, dispatch),
      getOrderItems: bindActionCreators(actions.getOrderItems, dispatch),

      showNotification: bindActionCreators(Notifications.show, dispatch),

      addMultipleFlatFeeAndOtherDetails: bindActionCreators(
        actions.addMultipleFlatFeeAndOtherDetails,
        dispatch
      ),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Rental);
