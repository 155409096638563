import React, { Component } from "react";
import { Grid, Card, Icon, Checkbox } from "semantic-ui-react";
import { LoaderComponent } from "../../../../../components";

class ShowSignatureDocument extends Component {
  state = {
    signatureDocument: [],
  };

  getOrderCandidateNameperDocumentAsync = () => {
    const { documentDetails } = this.props;
    this.props.apiCall({
      urls: ["GETORDERCANDIDATENAMEPERDOCUMENTASYNC"],
      method: "GET",
      data: {
        orderId: this.props.orderId,
        documentId: documentDetails.documentId,
      },
      onSuccess: (response) => {
        this.setState({ signatureDocument: response });
        return response;
      },
    });
  };

  componentDidMount() {
    if (this.props.documentDetails.documentId) {
      this.getOrderCandidateNameperDocumentAsync();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.documentDetails.orderDetailId !==
      this.props.documentDetails.orderDetailId
    ) {
      // Now fetch the new data here.
      const { documentDetails } = this.state;
      const { orderState } = this.props;
      const nextState = orderState.currentOrder.orderDetails.filter(
        (item, idx) => item.id === orderState.currentOrderDetails.id
      );
      if (nextState[0].typeId === 3) {
        documentDetails["productId"] = nextState[0].selectedItems.itemId;
      } else {
        documentDetails["productId"] = nextState[0].selectedItems[0].productId;
      }
      documentDetails["orderDetailId"] = nextState[0].orderDetailId;
      documentDetails["typeId"] = nextState[0].typeId;
      this.setState({ documentDetails });
    }
  }
  // onSingleCheckbox = (index) => {
  onSingleCheckbox = (e, { index, checked }) => {
    const { allFunction } = this.props;
    const { signatureDocument } = this.state;
    signatureDocument[index].isChecked = !signatureDocument[index].isChecked;
    const checkedDetails = signatureDocument.filter((sigObj) => {
      return sigObj.isChecked;
    });
    if (checkedDetails.length === 1) {
      allFunction.setState({
        ...allFunction.state,
        documentDetails: {
          ...allFunction.state.documentDetails,
          candidateName: checkedDetails[0].candidateName,
        },
      });
    }
    const currentClickedCandidateName = signatureDocument[index].candidateName;
    const oldCandidateName = allFunction.state.documentDetails.candidateName;
    const newCandidateName =
      checkedDetails.length > 0 ? checkedDetails[0].candidateName : "";
    if (!checked && currentClickedCandidateName === oldCandidateName) {
      allFunction.setState({
        ...allFunction.state,
        documentDetails: {
          ...allFunction.state.documentDetails,
          candidateName: newCandidateName,
        },
      });
    }
    this.setState({ signatureDocument: signatureDocument });
  };
  render() {
    const { signatureDocument } = this.state;
    const { api } = this.props;
    return (
      <Grid.Column width={5}>
        {api?.isApiLoading && <LoaderComponent />}
        <Card fluid className="agreement-box-document scrolling">
          <Card.Content className="agreement-box-content">
            {signatureDocument.map((item, index) => (
              <p className="agreement-signature">
                {item.isGuest && (
                  <>
                    <Checkbox
                      index={index}
                      className="custome-checkbox"
                      disabled={item.isSigned}
                      //   name="selectAll;"
                      checked={item.isChecked}
                      //   data="customerId"
                      onChange={this.onSingleCheckbox}
                    />
                  </>
                )}
                <span
                  className="orange-color agreement-signature-name"
                  title={item.candidateName}
                  onClick={() =>
                    this.props.handleShowOrderCandidate(item.candidateName)
                  }
                >
                  {item.candidateName}
                </span>
                {item.isSigned && (
                  <Icon
                    style={{
                      color: "#228B22",
                      fontSize: "15px",
                      padding: "0px 10px 0px",
                    }}
                    name="check circle"
                  />
                )}
              </p>
            ))}
          </Card.Content>
        </Card>
      </Grid.Column>
    );
  }
}

export default ShowSignatureDocument;
