import React, { Component } from "react";
import { Button, Grid, Modal, Header } from "semantic-ui-react";

class CapturePreAuthorizationModal extends Component {
  render() {
    return (
      <Modal
        open={this.props.openModal}
        closeIcon
        onClose={this.props.closeModal}
        size={"mini"}
      >
        <Modal.Content>
          <Grid>
            <Grid.Column width={16} textAlign="center">
              <Header as="h4" className="orange-color">
                Capture Pre-Authorization?
              </Header>
            </Grid.Column>

            <Grid.Column width={16} textAlign="center">
              <Button className="blue-btn" onClick={this.props.closeModal}>
                Cancel
              </Button>
              <Button loading={this?.props?.api?.isApiLoading} className="orange-btn" onClick={this.props.onConfirmCapture}>
                Ok
              </Button>
            </Grid.Column>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}
export default CapturePreAuthorizationModal;
