import React, { Component } from "react";
import { Table, Dimmer, Header } from "semantic-ui-react";
import InfiniteScroll from "react-infinite-scroll-component";
import { LoaderComponent } from "../../../components";

class TableRow extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      columns,
      data,
      emptyGrid,
      fetchMoreData,
      hasMore,
      gridName,
      gridDataLoading,
      hideColumns,
      tableHeight,
    } = this.props;

    return (
      <InfiniteScroll
        dataLength={data?.length}
        scrollableTarget={`${"scrollable" + gridName}`}
        next={fetchMoreData}
        hasMore={hasMore}
      >
        <Table.Body
          id={`${"scrollable" + gridName}`}
          style={
            tableHeight !== undefined
              ? { maxHeight: tableHeight, minHeight: "100px" }
              : { maxHeight: "310px", minHeight: "310px" }
          }
        >
          {gridDataLoading && <LoaderComponent />}
          {/* {gridDataLoading && (
            <Dimmer active inverted>
              {" "}
              <Loader size="small">Loading</Loader>{" "}
            </Dimmer>
          )} */}
          {data.length === 0 ? (
            <Header as="h5" className="forCommonTable">
              {emptyGrid ?? "No record found"}
            </Header>
          ) : (
            data.map((singleData, dataIndex) => {
              return (
                <Table.Row key={dataIndex}>
                  {columns.map((singleField, index) => {
                    const fieldValue = singleField.fieldName;
                    const columnHiding = hideColumns[index];
                    return (
                      <>
                        {columnHiding && columnHiding.CD === 1 ? (
                          <Table.Cell className={singleField.className}>
                            <span>
                              {singleField.Cell
                                ? singleField.Cell(singleData, dataIndex)
                                : singleData[fieldValue]}
                            </span>
                          </Table.Cell>
                        ) : null}
                      </>
                    );
                  })}
                </Table.Row>
              );
            })
          )}
        </Table.Body>
      </InfiniteScroll>
    );
  }
}

export default TableRow;
