import React, { Component } from "react";
import {
  Button,
  Grid,
  Form,
  Icon,
  Dropdown,
  Header,
  Table,
  Divider,
  Dimmer,
  Loader,
  Popup,
} from "semantic-ui-react";
import {
  commonFunctions,
  bindActionCreators,
  actions,
  connect,
  Notifications,
} from "../../../../functional/global-import";
import {
  CommonChooseTags,
  CommonCustomField,
  CommonChooseColor,
  CommonTraits,
  CommonAvailableOn,
  CommonCustomerQuestions,
  CommonAddOns,
} from "../../../../components";
import { TreeSelect } from "antd";
import "antd/dist/antd.css";

class seasonPassGenralInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  render() {
    const { allFunction, fullState, api } = this.props;

    return (
      <React.Fragment>
        {api.isApiLoading && (
          <Dimmer active inverted>
            <Loader />
          </Dimmer>
        )}
        <Grid>
          <Grid.Column width={16}>
            <Header as="h4">
              General Infomation &nbsp;
              <Popup
                trigger={
                  <Icon
                    name="info circle"
                    className="orange-color discount-css"
                  />
                }
              >
                <div>
                  All General Season-pass Information will not be displayed in
                  the receipt / reservation confirmation email. To display any
                  of this information in the receipt / confirmation email,
                  please create a custom field with the same information and
                  check the “confirmation email” box. Do not check the “website”
                  box or you will have two of the same displayed online. If the
                  custom field is unique and you want to display on your
                  website, you can check the “website” box.
                </div>
              </Popup>
            </Header>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={4}>
            <Form>
              <Form.Input
                placeholder="Item Name"
                fluid
                data="itemName"
                value={fullState.seasonPassGenralInfo?.itemName}
                error={allFunction.validatorGeneral.message(
                  "itemName",
                  fullState.seasonPassGenralInfo?.itemName,
                  "required|min:0|max:100"
                )}
                onChange={allFunction.onHandleChangeGeneral}
              />
            </Form>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={4}>
            <Form>
              <Form.Input
                placeholder="Item Quantity"
                data="itemQuantity"
                value={fullState.seasonPassGenralInfo?.itemQuantity}
                error={allFunction.validatorGeneral.message(
                  "itemQuantity",
                  fullState.seasonPassGenralInfo?.itemQuantity,
                  "required|integer|min:0,num|numeric|max:2000,num"
                )}
                disabled={
                  fullState.seasonPassGenralInfo.seasonPassId ? true : false
                }
                onChange={allFunction.onHandleChangeGeneral}
              />
            </Form>
          </Grid.Column>
          <Grid.Column
            mobile={16}
            tablet={8}
            computer={4}
            className="controlField"
          >
            <TreeSelect
              showSearch
              filterTreeNode={(search, item) => {
                return (
                  item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0
                );
              }}
              treeDefaultExpandedKeys={fullState.expandthisnode}
              value={
                !api.isApiLoading && fullState.seasonPassGenralInfo?.categoryId
              }
              treeData={fullState.allCategoryList}
              placeholder="Choose Inventory Location"
              onChange={allFunction.onDropDownTreeChange}
            />
            {allFunction.validatorGeneral.message(
              "inventoryLocation",
              fullState.seasonPassGenralInfo?.categoryId,
              "required"
            )}
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={4}>
            <Form>
              <Form.Input
                placeholder="Stock ID Prefix"
                fluid
                data="stockIdPrefix"
                value={fullState.seasonPassGenralInfo.stockIdPrefix}
                error={allFunction.validatorGeneral.message(
                  "stockIdPrefix",
                  fullState.seasonPassGenralInfo.stockIdPrefix,
                  "required|min:0|max:50"
                )}
                onChange={allFunction.onHandleChangeGeneral}
              />
            </Form>
          </Grid.Column>
          <React.Fragment>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <Form>
                <CommonChooseTags
                  fullState={fullState}
                  allFunction={allFunction}
                  stateForm="seasonPassGenralInfo"
                  variableName="productTags"
                />
              </Form>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <Form>
                <Form.TextArea
                  placeholder="Description"
                  rows="2"
                  max="2000"
                  error={allFunction.validatorGeneral.message(
                    "Description",
                    fullState.seasonPassGenralInfo?.itemDescription,
                    "|max:2000|min:1"
                  )}
                  data="itemDescription"
                  type="textarea"
                  onChange={allFunction.onHandleChangeGeneral}
                  value={fullState.seasonPassGenralInfo?.itemDescription}
                />
              </Form>
            </Grid.Column>
          </React.Fragment>
          <CommonChooseColor
            fullState={fullState}
            allFunction={allFunction}
            stateForm="seasonPassGenralInfo"
            variableName="color"
          />
          <CommonCustomField
            fullState={fullState}
            allFunction={allFunction}
            stateForm="seasonPassGenralInfo"
            variableName="customField"
          />
          <Grid.Column
            mobile={8}
            tablet={8}
            computer={4}
            verticalAlign="middle"
          >
            <Form.Checkbox
              label="Website"
              className="custome-checkbox"
              data="webWidget"
              checked={fullState.seasonPassGenralInfo?.webWidget}
              value={fullState.seasonPassGenralInfo?.webWidget}
              onChange={allFunction.onHandleChangeGeneral}
            />
          </Grid.Column>

          <Grid.Column
            mobile={8}
            tablet={8}
            computer={4}
            verticalAlign="middle"
          >
            <Form.Checkbox
              label="In Confirmation Email"
              className="custome-checkbox"
              data="inConfirmationEmail"
              checked={fullState.seasonPassGenralInfo?.inConfirmationEmail}
              value={fullState.seasonPassGenralInfo?.inConfirmationEmail}
              onChange={allFunction.onHandleChangeGeneral}
            />
          </Grid.Column>
          <Grid.Column width={16}>
            <Divider />
          </Grid.Column>
          <Grid.Column width={16}>
            <Header style={{ color: "red" }} as="h4">
              Minimum & Maximum Per Order
            </Header>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={4}>
            <label>
              {" "}
              <b>Min.</b>
            </label>
            <Form.Input
              placeholder="Min."
              data="minItemPerOrder"
              className="qty"
              min="1"
              value={fullState.seasonPassGenralInfo?.minItemPerOrder}
              onChange={allFunction.onHandleMinDuration}
              error={allFunction.validatorGeneral.message(
                "min PerOrder",
                fullState.seasonPassGenralInfo?.minItemPerOrder,
                `integer|numeric|min:1,num`
              )}
            />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={4}>
            <label>
              <b>Max.</b>
            </label>
            <Form.Input
              placeholder="Max."
              data="maxItemPerOrder"
              type="number"
              className="qty"
              value={fullState.seasonPassGenralInfo?.maxItemPerOrder}
              onChange={allFunction.onHandleMaxDurationChange}
              maxActivity
              error={allFunction.validatorGeneral.message(
                "max PerOrder",
                fullState.seasonPassGenralInfo?.maxItemPerOrder,
                `integer|numeric|min:1,num`
              )}
            />
          </Grid.Column>
          <CommonTraits
            fullState={this.props.fullState}
            allFunction={this.props.allFunction}
            stateForm="seasonPassGenralInfo"
            variableName="traits"
          />
          <CommonAvailableOn
            fullState={this.props.fullState}
            allFunction={this.props.allFunction}
            stateForm="seasonPassGenralInfo"
          />
          <CommonCustomerQuestions
            fullState={this.props.fullState}
            allFunction={this.props.allFunction}
            stateForm="seasonPassGenralInfo"
            variableName="seasonCustomQuestion"
          />
          <Grid.Column width={16}>
            <Divider />
          </Grid.Column>
          <CommonAddOns
            fullState={this.props.fullState}
            allFunction={this.props.allFunction}
            stateForm="seasonPassGenralInfo"
          />
          <Grid.Column width={16} textAlign="right">
            <Button
              className="orange-btn"
              loading={api.isApiLoading}
              onClick={() => allFunction.onHandleContinue("continue")}
            >
              Continue
            </Button>
          </Grid.Column>
        </Grid>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    auth: state.auth,
    global: state.global,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(seasonPassGenralInfo);
