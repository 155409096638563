import React, { Component } from "react";
import { Grid, Header, Form, Button, Checkbox } from "semantic-ui-react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { GlobalCodeSelect } from "../../../shared/components";
import { CommonDeleteUpdateModal } from "../../../shared/components";
import HelpCenterGrid from "./help-center-grid";
import { connect, bindActionCreators, actions, commonFunctions, withRouter, Notifications, env } from "../../../shared/functional/global-import";

const faqCategoryId = "";
class HelpCenterForm extends Component {
	child = React.createRef()
	get initialState() {
		return {
			helpCenter: {
				businessId: this.props.global.bussinessDetail.businessId,
				businessLocationId: this.props.global.locationSelected.BusinessLocationId,
				question: "", faqCategoryId: faqCategoryId, chooseDocument: "", answer: "",
				documentName: "", actionPerformedBy: this.props.auth.userDetail.emailId, image: [],
				isActive: true,
				applicationId: env.APPLICATION_ID,
			}, editorState: EditorState.createEmpty(),
			displayButton: "Save",
			isHelpCenterFormLoading: false,
			applicationId: env.APPLICATION_ID,
		};
	}
	constructor(props) {
		super(props);

		this.state = { ...this.initialState, gridDetail: [], isCommonDeleteUpdateModalOpen: false, faqId: "", };
		this.validator = commonFunctions.initializeSimpleValidator();
	};

	onEditorStateChange = (editorState) => {
		const { helpCenter } = this.state;
		helpCenter.answer = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
		this.setState({ editorState, helpCenter });
	}

	onHandleChange = (e, { name, value, type, checked, data }) => {
		if (data === "faqCategoryId") {
			var helpCenter = commonFunctions.onHandleChange(e, { name, value, type, checked, data }, this.state.helpCenter, this);
			this.setState({ helpCenter })
			this.setState({
				editorState: EditorState.createEmpty(),
				displayButton: "Save",
			})
			helpCenter.answer = "";
			helpCenter.chooseDocument = "";
			helpCenter.documentName = "";
			helpCenter.isActive = true;
			// helpCenter.isImageUpdated = false;
			helpCenter.question = "";
			this.setState(helpCenter, () => this.child.current.childsChild.current.getDefaultData());

		}
		else {
			var helpCenter = commonFunctions.onHandleChange(e, { name, value, type, checked, data }, this.state.helpCenter, this);
			// if (this.state.helpCenter.faqCategoryId !== this.state.faqCategoryId) {
			// 	helpCenter.faqCategoryId = this.state.faqCategoryId
			// }
			this.setState({ helpCenter });
		}
	}

	getEditFaq = (faqId) => {
		this.props.actions.apiCall({
			urls: ["GETFAQ"],
			method: "GET",
			data: { FAQId: faqId },
			onSuccess: (response) => {
				
				this.setState({ response });
				const { helpCenter } = this.state
				helpCenter.image = [commonFunctions.concatenateImageWithAPIUrl(response[0].image)];
				helpCenter.faqCategoryId = response[0].faqCategoryId;
				helpCenter.question = response[0].question;
				helpCenter.answer = response[0].answer;
				helpCenter.chooseDocument = response[0].fileName;
				helpCenter.documentName = response[0].documentName
				helpCenter.isActive = response[0].isActive
				helpCenter.faqId = response[0].faqId;
				this.setState({
					updateButton: true,
					helpCenter,
					editorState: EditorState.createWithContent(convertFromRaw(JSON.parse(response[0].answer))
					)
				});

			},
		});
	};


	onImageChange = (event) => {
		const { helpCenter } = this.state
		helpCenter.image = event.target.files
		helpCenter.chooseDocument = event.target.files[0].name
		helpCenter.isImageUpdated = true
		this.setState({ helpCenter });
	}

	faqUpdate = (selectedEditRow) => {
		
		this.state.helpCenter = selectedEditRow
		this.setState({
			...this.state, helpCenter: {
				...this.state.helpCenter, actionPerformedBy:
					this.props.auth.userDetail.emailId, businessId: this.props.global.bussinessDetail.businessId,
				businessLocationId: this.props.global.locationSelected.BusinessLocationId,
				isImageUpdated: false
			},
			displayButton: "Update"
		}, () => this.getEditFaq(selectedEditRow.faqId));
	}

	render() {
		const { editorState, helpCenter, isCommonDeleteUpdateModalOpen, messageToShow, buttonName, displayButton } = this.state;
		const fullState = this.state;
		const allFunction = this;
		return (
			<div className="cmn-shad">
				<Grid>
					<Grid.Column width={16}>
						<GlobalCodeSelect
							placeholder='Select your category'
							categoryType="FAQCategory"
							data='faqCategoryId'
							value={helpCenter.faqCategoryId}
							error={this.validator.message("faqCategory", helpCenter.faqCategoryId, "required")}
							onChange={this.onHandleChange} fluid />
					</Grid.Column>
					<Grid.Column width={16}>
						<Form.Input placeholder="What is your question ?"
							value={helpCenter.question}
							data="question" onChange={this.onHandleChange}
							error={this.validator.message("question", helpCenter.question, "required|min:0|max:200")}
							fluid />
					</Grid.Column>
					<Grid.Column width={16}>
						<Header as="h4">Answer</Header>
						<Editor
							editorState={editorState}
							toolbarClassName="toolbarClassName"
							wrapperClassName="wrapperClassName"
							editorClassName="editorClassName"
							toolbar={{
								options: ["inline", "blockType", "fontSize", "fontFamily", "textAlign", "colorPicker", "remove",],
								inline: {
								  inDropdown: false,
								  className: "test",
								  component: undefined,
								  dropdownClassName: undefined,
								  options: [ "bold", "italic", "underline" ],
								  bold: { className: "test", style: { color: "red" } },
								  italic: { className: undefined },
								  underline: { className: undefined },
								},
							  }}
							onEditorStateChange={this.onEditorStateChange}
						/>
						{this.validator.message("answer", helpCenter.answer, "required")}
					</Grid.Column>
					<Grid.Column mobile={6} tablet={6} computer={6}>
						<Form.Input placeholder="Document Name" fluid
							value={helpCenter.documentName}
							data="documentName" onChange={this.onHandleChange}
						/>
					</Grid.Column>
					<Grid.Column mobile={6} tablet={6} computer={6}>
						<div className="input-file">
							<label htmlFor="myInput">Choose File</label>
							<input accept="application/pdf" id="myInput" style={{ display: 'none' }} type={"file"} onChange={this.onImageChange} />
							{helpCenter.chooseDocument !== "" ? <span id="file-chosen">{helpCenter.chooseDocument}</span> : "No file chosen"}
						</div>
					</Grid.Column>
					<Grid.Column width={4} verticalAlign="middle" textAlign="center" >
						<Checkbox label="Active" data="isActive" name="chkisActive"
							checked={helpCenter.isActive}
							value={helpCenter.isActive}
							onChange={this.onHandleChange} className="custom-toggle" />
					</Grid.Column>
				</Grid>
				<Grid reversed='computer tablet'>
					<Grid.Column mobile={8} tablet={8} computer={8} textAlign="right">
						<Button className="blue-btn" onClick={(e) => commonFunctions.onCancelInitialState(allFunction)}>Clear</Button>

						<Button className="orange-btn" onClick={(e) => commonFunctions.onHandleSubmit(e, allFunction, "isHelpCenterFormLoading", "helpCenter", "ADDFAQ", "UPDATEFAQ", true)}>
							{displayButton}
						</Button>
					</Grid.Column>

					{/* ==========showGrid=========Grid Component===============  */}
					{this.props.showGrid && <HelpCenterGrid ref={this.child} fullState={fullState} allFunction={allFunction} />}
				</Grid>

				{isCommonDeleteUpdateModalOpen &&
					<CommonDeleteUpdateModal
						isModalOpen={isCommonDeleteUpdateModalOpen}
						onConfirm={() => commonFunctions.onConfirmFunction(allFunction, "faqId", "gridDetail",
							"DELETEFAQ", "FAQTOGGLE")}
						onClose={() => commonFunctions.onCloseDeleteUpdateModal(allFunction, "faqId")}
						confirmationButtonText={buttonName}
						header={messageToShow}
						cancelButtonText={'Cancel'}
					/>}
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		api: state.api,
		global: state.global,
		auth: state.auth,
		warnUserState: state.warnUserState,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			apiCall: bindActionCreators(actions.apiCall, dispatch),
			storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
			showNotification: bindActionCreators(Notifications.show, dispatch),
			storeWarnUser: bindActionCreators(actions.storeWarnUser, dispatch)
		}
	};
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HelpCenterForm));

