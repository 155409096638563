import React, { Component } from "react";
import { Icon, Checkbox, Image } from "semantic-ui-react";
import { commonFunctions, } from "../../../shared/functional/global-import";
import { DataTable } from '../../../shared/components/organisms';

class AddOnsGrid extends Component {
  childsChild = React.createRef();
  render() {

    const { fullState, allFunction } = this.props;
    return (
      <DataTable
        ref={this.childsChild}
        getApiName="GETADDONS"
        searchOption={{ show: true, placeHolder: "Search Add-Ons" }}
        // defaultSort={{sortColumn:"modifiedDate",sortOrder:"desc"}}
        // defaultPageSize={10}
        gridName="addOnsGrid"
        screenName="add-ons"
        fullState={fullState}
        allFunction={allFunction}
        tableHeight="250px"
        emptyGrid="No records to show"
        columns={[
          {
            headerName: "Image",
            fieldName: "image",
            isSorting: false,
            Cell: (props) => {
              const image = JSON.parse(props.image)
              return (
                <Image src={commonFunctions.concatenateImageWithAPIUrl(image ? image[0].ImageFile : null)} size="mini" />
              );
            },
          },
          {
            headerName: "Title",
            fieldName: "addOnTitle",
            isSorting: true,
            className: "textOverflowHidden",
            Cell: (props) => {
              return (
                <span title={props.addOnTitle}>{props.addOnTitle}</span>
              );
            },
          },
          {
            headerName: "Price",
            fieldName: "addOnPrice",
            isSorting: true,
            Cell: (props) => {
              return (
                props.addOnPrice === 0 ? <span > - </span> : <span >$  {props.addOnPrice}</span>
              );
            },
          },
          // {
          //   headerName: "Min",
          //   fieldName: "minAddOns",
          //   isSorting: true,
          //   Cell: (props) => {
          //     return (
          //       props.minAddOns === 0 ? <span > - </span> : <span >{props.minAddOns}</span>
          //     );
          //   },
          // },
          // {
          //   headerName: "Max",
          //   fieldName: "maxAddOns",
          //   isSorting: true,
          //   Cell: (props) => {
          //     return (
          //       props.maxAddOns === 0 ? <span > - </span> : <span >{props.maxAddOns}</span>
          //     );
          //   },
          // },
          {
            headerName: "Active",
            fieldName: "isActive",
            isSorting: true,
            Cell: (props) => {
              return (
                <Checkbox
                  className="custom-toggle"
                  checked={props.isActive}
                  onClick={(data, r) =>
                    commonFunctions.activateDeactivateToggle(allFunction, props.addOnTitle, props.addOnId, "addOnId", props.isActive)
                  }
                ></Checkbox>
              );
            },
          },
          {
            headerName: "Action",
            fieldName: "action",
            isSorting: false,
            Cell: (props) => {
              return (
                <React.Fragment>
                  <Icon
                    name="pencil alternate"
                    size="large"
                    title="Edit"
                    className="deepviolet-color pointer"
                    onClick={() => allFunction.editAddon(props)}
                  />
                  <Icon
                    name="trash alternate"
                    size="large"
                    className="orange-color pointer"
                    title="Delete"
                    link
                    onClick={() => commonFunctions.deleteModalMessage(allFunction, props.addOnTitle, props.addOnId, "addOnId")}
                  />
                </React.Fragment>
              );
            },
          },
        ]}
      />
    );
  }
}
export default AddOnsGrid;
