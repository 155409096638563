import React, { Component } from "react";
import { Button, Grid, Modal, Header, Form } from "semantic-ui-react";
import { env, connect, Link, bindActionCreators, actions, Notifications, commonFunctions } from "../../../functional/global-import";

class BreakTimeModal extends Component {
  get initialState() {
    return {
      breakType: {
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId: this.props.global && this.props.global.locationSelected && this.props.global.locationSelected.BusinessLocationId,
        breakTypeTitle: "",
        isActive: true,
        showBreakTimeOnUserMenu: false,
        actionPerformedBy: this.props.auth.userDetail?.emailId,
        pageNo: 1, pageSize: 100, breakTypeId: -1
      },
      addBreakTime: {
        breakTypeId: "",
        userId: this.props.auth.userDetail?.keyId,
        actionPerformedBy: this.props.auth.userDetail?.emailId,
        businessLocationId: this.props.global && this.props.global.locationSelected && this.props.global.locationSelected.BusinessLocationId,
      },
      timerStarted: false,
      timerStopped: true,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      ...this.initialState,
      breakTypeList: [],
      breakTimeId: "",
    };
    this.validator = commonFunctions.initializeSimpleValidator();
  }

  getBreakTypeList = () => {
    this.props.actions.apiCall({
      urls: ["GETBREAKTYPE"],
      method: "GET",
      data: this.state.breakType,
      onSuccess: (response) => {
        const breakTitle = response.map((singleBreakType) => {
          return {
            value: singleBreakType.breakTypeId,
            text: singleBreakType.breakTypeTitle
          }
        });
        this.setState({ breakTypeList: breakTitle });
      },
    });
  }

  // Break time Function 
  startBreakTime = (e) => {
    var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
    if (isFormValid) {
      const { addBreakTime } = this.state;
      this.props.actions.apiCall({
        urls: ["ADDBREAKTIME"], method: "POST", data: addBreakTime,
        onSuccess: (response) => {
          this.setState({ breakTimeId: response.keyId })
          this.handleStart()
        }, showNotification: true,
      });
    }
  }

  stopTimer = (e) => {
    this.props.actions.apiCall({
      urls: ["UPDATEBREAKTIME"],
      method: "PUT",
      data: {
        "breakTimeId": this.state.breakTimeId,
        "breakTypeId": this.state.addBreakTime.breakTypeId,
        "userId": this.props.auth.userDetail.keyId, "actionPerformedBy": this.props.auth.userDetail.emailId
      },
      onSuccess: (response) => { }
    })
    clearInterval(this.interval);
    this.props.actions.storeUserBreak(false);
    let userDetail = this.props.auth.userDetail
    userDetail.isBreakTime = true
    userDetail.breakTimes = '[{"BreakTimeId":0,"BreakTypeId":0,"userId":0,"Day":"0","Hour":"0","Minute":"0","Second":"0"}]'
    this.props.actions.storeUserDetail(userDetail);
    this.setState(this.initialState);
  }

  handleStart = (e) => {
    if (this.state.timerStopped) {
      this.interval = setInterval(() => {
        this.setState({ timerStarted: true, timerStopped: false })
        if (this.state.timerStarted) {
          if (this.state.seconds >= 60) {
            this.setState((prevState) => ({ minutes: prevState.minutes + 1, seconds: 0 }))
          }
          if (this.state.minutes >= 60) {
            this.setState((prevState) => ({ hours: prevState.hours + 1, minutes: 0, seconds: 0 }))
          }
          this.setState((prevState) => ({ seconds: prevState.seconds + 1 }))
        }
      }, 1000);
    }
  };

  // onChange function For Break time reason. 
  onHandleChange = (e, { name, value, type, checked, data }) => {
    var addBreakTime = commonFunctions.onHandleChange(e, { name, value, type, checked, data }, this.state.addBreakTime, this);
    if (name === "breakTypeId") {
      addBreakTime["breakTypeId"] = value
    }
    this.setState({ addBreakTime });
    this.validator = commonFunctions.initializeSimpleValidator();
  }

  componentDidMount() {
    this.getBreakTypeList();
  }

  componentDidUpdate = () => {
    const { addBreakTime } = this.state
    const isBreakTime = this.props.auth.userDetail?.isBreakTime
    const breakTimes = this.props.auth && this.props.auth.userDetail?.breakTimes && JSON.parse(this.props.auth.userDetail.breakTimes)
    if (isBreakTime !== undefined && isBreakTime === false) {
      if (isBreakTime !== this.state.timerStopped) {
        addBreakTime["breakTypeId"] = breakTimes[0].BreakTypeId
        this.setState({ hours: Number(breakTimes[0].Hour), minutes: Number(breakTimes[0].Minute), seconds: Number(breakTimes[0].Second), breakTimeId: breakTimes[0].BreakTimeId, timerStarted: true, timerStopped: false, addBreakTime })
        this.handleStart()
      }
    }
  }

  closeModal = () => {
    if (this.state.timerStarted) {
      this.props.actions.showNotification(
        {
          title: "Warning",
          message: "Please stop the break time .",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        }, "error");
    }
    else {
      this.props.actions.storeUserBreak(false);
      this.setState(this.initialState);
    }
  }

  userLogout = async () => {

    this.props.actions.storeUserBreak(false);
    this.props.actions.apiCall({
      urls: ["LOGOUT"], method: "POST", data: {
        "userId": this.props.auth.userDetail.keyId,
        "businessLocationId": this.props.global.locationSelected.BusinessLocationId,
        "actionPerformedBy": this.props.auth.userDetail.emailId
      }, onSuccess: (response) => {
      }
    });
  }

  render() {
    const { addBreakTime } = this.state
    return (
      <Modal open={this.props.auth.isActiveBreak} closeIcon onClose={this.closeModal} size={"mini"}>
        <Modal.Content>
          <Grid>
            <Grid.Column width={16} textAlign="center">
              <Header as="h3" className="orange-color"> Break Time! </Header>
              <p>Manage your own break schedule!</p>
            </Grid.Column>
            <Grid.Column width={16} textAlign="center">
              <Form.Select placeholder="Break Reason" options={this.state.breakTypeList}
                name="breakTypeId" data="breakTypeId"
                value={addBreakTime.breakTypeId}
                className="custom-select mbt" onChange={this.onHandleChange} fluid
                error={this.validator.message("breakReason", addBreakTime.breakTypeId, "required")} />
              <Header as="h4" className="deepviolet-color">
                <div className="timer">
                  {this.state.hours + " : " + this.state.minutes + " : " + this.state.seconds}
                </div>
              </Header>
            </Grid.Column>
            <Grid.Column width={16}>
              <Button as={Link} to={`${env.PUBLIC_URL}/passcode`} className="blue-btn" onClick={this.userLogout}>Switch User</Button>
              {this.state.timerStopped ? <Button className="orange-btn" onClick={this.startBreakTime} floated="right">Start Break</Button>
                : <Button className="orange-btn" onClick={this.stopTimer} floated="right">End Break</Button>}
            </Grid.Column>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
      storeUserBreak: bindActionCreators(actions.storeUserBreak, dispatch),
      storeUserDetail: bindActionCreators(actions.storeUserDetail, dispatch),
      storeUserBreakTimeStart: bindActionCreators(actions.storeUserBreakTimeStart, dispatch),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BreakTimeModal);
