import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import { connect,bindActionCreators,actions,commonFunctions } from '../../../functional/global-import';

class RolesSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userRoleDetails: []
    };
  }

  componentDidMount = () => {
      this.props.actions.apiCall({
        urls: ["GETROLES"], method: "GET", data: {
          RoleId: "-1", "BusinessId": this.props.global.bussinessDetail.businessId
        },
        onSuccess: (response) => {
          const roleDetail = response.map((roles) => {
            return { id: roles.roleId, value: roles.roleId, text: roles.roleName }
          });
          this.setState({ userRoleDetails: roleDetail });
        }
      })
    }

    render() {
        const{userRoleDetails}=this.state;
        return (
            <Form.Dropdown  selection
            fluid options={userRoleDetails} placeholder={this.props.placeholder}
            name={this.props.name}
            data={this.props.data}
            type={this.props.type}
            value={this.props.value}
            error={this.props.error}
            onChange={this.props.onChange}
            className="custom-select"/>
        )}
}

RolesSelect.propTypes = {
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  data: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  error: PropTypes.func
};


const mapStateToProps = state => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch)
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RolesSelect);