import React, { Component } from "react";
import { Modal, Step, Grid, Icon, Dimmer, Loader } from "semantic-ui-react";
import SeasonPassGeneralInfo from "./add-seasonpass-genral";
import UploadSeasonPassPhoto from "./upload-images";
import SeasonPassPricing from "./addseason-Pass-Pricing";
import {
  connect,
  bindActionCreators,
  actions,
  commonFunctions,
  Notifications,
  env,
} from "../../../../functional/global-import";
import "react-dropdown-tree-select/dist/styles.css";
import {
  CommonDeleteUpdateModal,
  AddBlackOutDates,
} from "../../../../components";
// import moment from "moment";
let CustomPrice = [
  { label: "Unlimited Uses", value: 494 },
  { label: "Custom ", value: 495 },
];
class AddSeasonPassModal extends Component {
  // Put all key & values that need to reset on form submit and reset form.
  get initialState() {
    return {
      seasonPassGenralInfo: {
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        businessCategoryType: commonFunctions.getGlobalCodeDetails(
          this.props.global.codes,
          "BusinessCategoryType",
          "Season Pass"
        ).globalCodeId,
        bookingConfirmation: null,
        seasonPassId: null,
        inStore: true,
        websiteWidget: true,
        categoryId: null,
        itemName: "",
        itemQuantity: null,
        stockIdPrefix: "",
        itemDescription: "",
        color: "",
        inConfirmationEmail: true,
        webWidget: true,
        productTags: [],
        cancellationPolicyDescription: "",
        cancellationPolicyInStore: true,
        cancellationPolicyInCofirmationEmail: true,
        cancellationPolicyWebsiteWidget: true,
        deliveryOption: true,
        allowCustomerToEnterDeliveryAddress: true,
        addOns: [],
        traits: [],
        seasonCustomQuestion: [],
        customField: [],
        selectedLocation: [],
        minItemPerOrder: null,
        maxItemPerOrder: null,
        actionPerformedBy: this.props.auth.userDetail.emailId,
      },

      uploadImage: {
        imageId: [],
        deleted: [],
        seasonPassId: "",
        images: [],
        DefaultImage: [],
        isDefaultImage: false,
        DefaultImageId: null,
        responseImages: [],
        actionPerformedBy: this.props.auth.userDetail.emailId,
      },
      seasonPassPrice: {
        seasonPassPriceId: null,
        seasonPassPricing: false,
        seasonPassPricingType: "A",
        discountAmount: null,
        seasonPassPrice: null,
        activationDate: "",
        expirationDate: "",
        seasonPassUsageType: commonFunctions.getGlobalCodeDetails(
          this.props.global.codes,
          "CategoryType",
          "Unlimited Uses"
        ).globalCodeId,
        amountDiscount: null,
        seasonPassUsageTypeValue: null,
        durationTypeId: null,
        customField: [],
        isActive: true,
        seasonPassBlackOutDates: [],
        seasonPassBlackOutDateValue: [],
        isEmailBooked: false,
        roleId: null,
        toEmail: [],
        actionPerformedBy: this.props.auth.userDetail.emailId,
      },
      isSesonPassImageAdded: false,
      isCommonDeleteUpdateModalOpen: false,
      blackOutDateStatus: false,
      commonBlackOut: false,
      gridDetail: [],
      blackOutDateId: "",
      customFields: false,
      open: false,
      advance: false,
      customHeader: false,
      editDate: [],
      boolenvalue: true,
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      ...this.initialState,
      globalBusinesCategories: [],
      activeStep: 0,
      sesonPassGenralModal: false,
      updateSeasonPassGeneral: false,
      customeUsageType: false,
      allCategoryList: [{ value: -1, title: "Add at root level" }],
      amountSelect: null,
      customTraits: {
        traits: [
          {
            traitId: null,
            traitName: "",
            traitDescription: "",
            isUpdate: true,
            isDeleted: false,
            disabled: true,
          },
        ],
      },
      editBundleDetails: [],
      bundleImageDetail: [],
      imageData: false,
      secondStepCompleted: false,
      blackOutRows: { blackoutDates: [] },
      availability: { availabilityNew: [] },
      BlackOutTitle: "",
      blackOutId: "",
      blackoutstartdate: "",
      blackoutenddate: "",
      expandthisnode: [],
      TimeFrameTitle: "",
      timeframeId: "",
      startTime: "",
      endTime: "",
      Timing: "",
      range: "",
      copyCategoryList: [],
      customfield: {
        customFieldRows: [{ customField: "", customDescriptions: "" }],
      },
      customFields: false,
      customFieldsButton: true,
      ProductList: [],
      arr1: [],
      openCommonModal: false,
      addCustomPriceRows: "",
      BusinessCategory: [],
      itemName: "",
      timeFrameList: [],
      rows: [{}],
      bundlePricingTitle: "",
      timeFrameId: 0,
      price: 0,
      blackOutDatesList: [],
      TimeDurationMin: [],
    };
    this.validatorGeneral = commonFunctions.initializeSimpleValidator();
    this.validatorPrice = commonFunctions.initializeSimpleValidator();
    this.validatorAmount = commonFunctions.initializeSimpleValidator();
    this.validatorCustomfield = commonFunctions.initializeSimpleValidator();
    this.validatorTraits = commonFunctions.initializeSimpleValidator();
    this.validator = commonFunctions.initializeSimpleValidator();
  }
  ////////////////   CHECK  ////////////////
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.editSesonPassModal &&
      this.state.uploadImage.seasonPassId !== prevProps.seasonPassId
    ) {
      this.getGeneralInfo(this.props.seasonPassId);
      this.getImageDetail(this.props.seasonPassId);
      this.getSeasonPricing(this.props.seasonPassId);
      this.setState({
        seasonPassGenralInfo: {
          ...this.state.seasonPassGenralInfo,
          seasonPassId: this.props.seasonPassId,
        },
      });
      this.setState({
        uploadImage: {
          ...this.state.uploadImage,
          seasonPassId: this.props.seasonPassId,
        },
      });
      this.setState({
        activeStep: 0,
        updateSeasonPassGeneral: this.props.editSesonPassModal,
      });
      this.setState({
        seasonPassPrice: {
          ...this.state.seasonPassPrice,
          seasonPassId: this.props.seasonPassId,
        },
      });
    }
    if (
      this.props.inventoryType ===
      this.props.global.inventoryDetail.businessCategoryId &&
      prevState.seasonPassGenralInfo.categoryId === null
    ) {
      this.setState({
        activeStep: 0,
        updateSeasonPassGeneral: this.props.editSesonPassModal,
      });
      let newCategoryId =
        this.props.global.inventoryDetail.inventoryBreadcrumb &&
          this.props.global.inventoryDetail.inventoryBreadcrumb.length > 1
          ? this.props.global.inventoryDetail.inventoryBreadcrumb[
            this.props.global.inventoryDetail.inventoryBreadcrumb.length - 1
          ].parentId
          : undefined;
      let newCategoryid = newCategoryId === null ? undefined : newCategoryId;

      this.setState(() => ({
        seasonPassGenralInfo: {
          ...this.state.seasonPassGenralInfo,
          categoryId: newCategoryid,
        },
      }));
      this.state.expandthisnode.push(
        this.props.global.inventoryDetail.inventoryBreadcrumb &&
        this.props.global.inventoryDetail.inventoryBreadcrumb.length > 1 &&
        this.props.global.inventoryDetail.inventoryBreadcrumb[
          this.props.global.inventoryDetail.inventoryBreadcrumb.length - 1
        ].parentId
      );
    }
  }

  //  get all value in gernal form   //
  getImageDetail = (editId) => {
    this.setState((this.state.uploadImage.images = []));
    this.setState((this.state.uploadImage.DefaultImage = []));
    this.props.actions.apiCall({
      urls: ["GETSEASONPASSIMAGE"],
      method: "GET",
      data: {
        businessId: this.props.global.bussinessDetail.businessId,
        SeasonPassId: editId,
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
      },
      onSuccess: (response) => {
        let isimageExist = response.length > 0;
        const { uploadImage } = this.state;
        this.setState((uploadImage.responseImages = response));
        const images = response.map((data) => {
          return (
            uploadImage.imageId.push(data.imageId),
            uploadImage.images.push(
              commonFunctions.concatenateImageWithAPIUrl(data.image)
            )
          );
        });
        // var filterImages = response.filter((x) => x.isDefaultImage !== true)
        // uploadImage.images.push(commonFunctions.concatenateImageWithAPIUrl(filterImages.map((x) => x.image)))
        var isDefaultImage = response.filter((x) => x.isDefaultImage === true);
        if (isDefaultImage) {
          uploadImage.DefaultImage.push(
            commonFunctions.concatenateImageWithAPIUrl(isDefaultImage[0].image)
          );
        }
        this.setState({ uploadImage, isSesonPassImageAdded: isimageExist });
      },
    });
  };
  onNext = () => {
    const { seasonPassGenralInfo, activeStep } = this.state;
    this.setState({ activeStep: activeStep + 1 });
    if (seasonPassGenralInfo.seasonPassId === "") {
      this.validatorGeneral = commonFunctions.initializeSimpleValidator();
    }
  };

  onPrevious = () => {
    const { seasonPassGenralInfo, activeStep } = this.state;
    this.setState({ activeStep: activeStep - 1 });
    if (seasonPassGenralInfo.seasonPassId === "") {
      this.validatorGeneral = commonFunctions.initializeSimpleValidator();
    }
  };

  onUpdateFormCheck = (e) => {
    var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
    if (!isFormValid) {
      this.props.actions.showNotification(
        {
          title: "Warning",
          message: "Please fill all required fields.",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );

      this.setState({
        seasonPassGenralInfo: {
          ...this.state.seasonPassGenralInfo,
          valid: false,
        },
      });
      return false;
    } else {
      this.updateBundle(isFormValid);
    }
  };

  //   CHECK  //
  componentDidMount() {
    this.globalBusinesCategory();
    // this.getTimeFrame();
    this.getCategories();
    this.getBlackOutDateList();
    this.getGobalCode();
  }
  getGobalCode = () => {
    this.props.actions.apiCall({
      urls: ["GLOBALCODE"],
      method: "GET",
      data: { CategoryName: "RentalItemTimePeriod", GlobalCodeId: -1 },
      onSuccess: (response) => {
        const UserperDuration = response.filter(
          (durationTime) =>
            durationTime.codeName === "Hour" ||
            durationTime.codeName === "Monthly" ||
            durationTime.codeName === "Week (7Days per week)" ||
            durationTime.codeName === "Day" ||
            durationTime.codeName === "Yearly"
        );
        const TimePeriod = [
          { text: "Select TimePeriod", value: null, disable: true },
        ];
        UserperDuration.map((singleTime) => {
          TimePeriod.push({
            value: singleTime.globalCodeId,
            text: singleTime.codeName,
            key: singleTime.codeName,
            id: singleTime.durationTime,
          });
          return { ...TimePeriod };
        });

        this.setState({ TimeDurationMin: TimePeriod });
      },
    });
  };

  getCategories = () => {
    this.props.actions.apiCall({
      urls: ["GETCATEGORYFORPRODUCT"],
      method: "GET",
      data: {
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        BusinessCategoryType:
          this.props.global.inventoryDetail.businessCategoryId,
        PageNo: 1,
        PageSize: 100,
      },
      onSuccess: (response) => {
        if (response) {
          const { seasonPassGenralInfo } = this.state;
          if (
            seasonPassGenralInfo.categoryId === undefined ||
            seasonPassGenralInfo.categoryId === null ||
            response[0].productCategories === null
          ) {
            this.setState({
              seasonPassGenralInfo: {
                ...this.state.seasonPassGenralInfo,
                categoryId: -1,
              },
            });
          }
          if (response[0].productCategories) {
            let productCategoriesArray = JSON.parse(
              response[0].productCategories
            );
            this.setState({
              allCategoryList: [
                ...this.state.allCategoryList,
                ...productCategoriesArray,
              ],
            });
          }
        }
      },
    });
  };
  ///////////// -for prompt if any changes in form- ///////////
  onCloseModalStepOne = () => {
    if (this.props.warnUserState.isFormChanged) {
      this.setState({ isCommonDeleteUpdateModalOpen: true });
    } else {
      this.onModalCloseFormFalse();
    }
  };
  onModalCloseFormFalse = () => {
    this.props.closeModal();
    if (this.props.getProductCategoryDetail) {
      this.props.getProductCategoryDetail();
    }
    this.setState(this.initialState);
    this.setState({ activeStep: 0 });
  };

  onConfirmWarnUser = () => {
    this.setState({ isCommonDeleteUpdateModalOpen: false });
    commonFunctions.onApiSucessFormChangedFalse(this);
    this.onModalCloseFormFalse();
  };
  globalBusinesCategory = () => {
    let globalBusinesCategories = [];
    globalBusinesCategories = this.props.global.codes
      .filter((code) => code.categoryName === "BusinessCategoryType")
      .map((filtercode) => {
        return {
          id: filtercode.globalCodeId,
          value: filtercode.globalCodeId,
          text: filtercode.codeName,
          checked: true,
        };
      });
    var deletedBundleCategory = globalBusinesCategories.filter((data) => {
      return data.text !== "Season Pass";
    });
    this.setState({ globalBusinesCategories: deletedBundleCategory });
  };

  // new changes Season Pass //

  /// UPLOAD photo component function's
  onImageChange = (imageList) => {
    this.setState(() => ({
      uploadImage: { ...this.state.uploadImage, images: imageList },
    }));
    commonFunctions.onFormChange(this);
  };
  //////////////// on change default image ////////////////
  handleChangeDefaultImage = (image) => {
    const { uploadImage } = this.state;
    if (!image.data_url && image !== undefined) {
      const removeUrl = image.split(env.IMG_URL);
      const setDefaultImageId = this.state.uploadImage.responseImages.filter(
        (img) => img.image === removeUrl[1]
      );

      uploadImage.DefaultImageId = setDefaultImageId[0].imageId;
      uploadImage.DefaultImage = [];
      uploadImage.images = [];
      this.setState(uploadImage);
      this.updateSeasonPassImages();
    } else {
      this.setState(() => ({
        uploadImage: {
          ...this.state.uploadImage,
          DefaultImage: [image],
          DefaultImageId: "",
        },
      }));
    }
    commonFunctions.onFormChange(this);
  };
  updateSeasonPassImages = () => {
    const { uploadImage } = this.state;
    if (uploadImage.DefaultImage[0] !== undefined) {
      var newArrayImage = uploadImage.images.filter(
        (defaultObj) =>
          defaultObj.data_url !== uploadImage.DefaultImage[0].data_url
      );
      uploadImage.images = newArrayImage;
    }
    var formData = commonFunctions.getFormData(this.state.uploadImage);
    this.props.actions.apiCall({
      urls: ["UPDATESEASONPASSIMAGE"],
      method: "PUT",
      data: formData,
      onSuccess: (response) => {
        this.setState((this.state.uploadImage.imageId = []));
        this.getImageDetail(this.state.uploadImage.seasonPassId);
      },
      isFormData: true,
      showNotification: true,
    });
  };
  // -FOR EDIT IMAGE REMOVE FUNCTION FOR RENTAL- //
  onImageRemove = (index) => {
    commonFunctions.onFormChange(this);
    const allImages = this.state.uploadImage.images;
    if (
      this.state.uploadImage.DefaultImage[0] ===
      this.state.uploadImage.images[index]
    ) {
      this.setState((this.state.uploadImage.DefaultImage = []));
    }
    allImages.splice(index, 1);
    this.setState({
      uploadImage: { ...this.state.uploadImage, images: allImages },
    });
    if (
      this.state.uploadImage.imageId[index] &&
      this.state.uploadImage.imageId[index] !== null
    ) {
      this.state.uploadImage.deleted.push(
        this.state.uploadImage.imageId[index]
      );
      const allImageId = this.state.uploadImage.imageId;
      allImageId.splice(index, 1);
      this.setState({
        uploadImage: { ...this.state.uploadImage, imageId: allImageId },
      });
    }
  };
  changeStepToGeneral = () => {
    if (
      (this.state.uploadImage.images[0] !== undefined &&
        this.state.uploadImage.DefaultImage[0] !== undefined) ||
      (this.state.uploadImage.images[0] === undefined &&
        this.state.uploadImage.DefaultImage[0] === undefined)
    ) {
      this.changeStep(0);
    } else if (
      this.state.uploadImage.images[0] !== undefined &&
      this.state.uploadImage.DefaultImage[0] === undefined
    ) {
      this.props.actions.showNotification(
        {
          title: "Error",
          message: "Please select default image.",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    }
  };
  changeStepToPricing = () => {
    if (
      (this.state.uploadImage.images[0] !== undefined &&
        this.state.uploadImage.DefaultImage[0] !== undefined) ||
      (this.state.uploadImage.images[0] === undefined &&
        this.state.uploadImage.DefaultImage[0] === undefined)
    ) {
      this.changeStep(2);
    } else if (
      this.state.uploadImage.images[0] !== undefined &&
      this.state.uploadImage.DefaultImage[0] === undefined
    ) {
      this.props.actions.showNotification(
        {
          title: "Error",
          message: "Please select default image.",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    }
    this.validator = commonFunctions.initializeSimpleValidator();
  };
  backStep = (e) => {
    if (e === 2) {
      this.getImageDetail(this.state.uploadImage.seasonPassId);
      this.onPrevious(1);
    } else {
      this.updateSeasonPassImages();
      this.onPrevious(0);
    }
  };

  changeNext = (stepNumber) => {
    const { api } = this.props;
    const { isSesonPassImageAdded } = this.state;
    if (!isSesonPassImageAdded && this.state.uploadImage.images.length !== 0) {
      api.isApiLoading && (
        <Dimmer active inverted>
          <Loader />
        </Dimmer>
      );
      const { uploadImage } = this.state;

      if (uploadImage.DefaultImage[0] !== undefined) {
        var newArrayImage = uploadImage.images.filter(
          (defaultObj) =>
            defaultObj.data_url !== uploadImage.DefaultImage[0].data_url
        );
        uploadImage.images = newArrayImage;
      }

      var formData = commonFunctions.getFormData(this.state.uploadImage);
      this.props.actions.apiCall({
        urls: ["ADDSEASONPASSIMAGE"],
        method: "POST",
        data: formData,
        onSuccess: (response) => {
          if (response.responseCode == 200) {
            commonFunctions.onApiSucessFormChangedFalse(this);
            this.setState({ activeStep: stepNumber });
          }
        },
        isFormData: true,
        showNotification: true,
      });
    } else if (isSesonPassImageAdded) {
      const { uploadImage } = this.state;
      if (uploadImage.DefaultImage[0] && uploadImage.DefaultImage[0].data_url) {
        let filterImage = uploadImage.images.filter(
          (x) => x === uploadImage.DefaultImage
        );
        uploadImage.images = filterImage;
        this.setState({ uploadImage });
      }
      var formData = commonFunctions.getFormData(this.state.uploadImage);
      this.props.actions.apiCall({
        urls: ["UPDATESEASONPASSIMAGE"],
        method: "PUT",
        data: formData,
        onSuccess: (response) => {
          if (response.responseCode == 200) {
            commonFunctions.onApiSucessFormChangedFalse(this);
            this.setState({ activeStep: stepNumber });
          }
        },
        isFormData: true,
        showNotification: true,
      });
    } else if (
      this.state.updateSeasonPassGeneral &&
      !this.props.warnUserState.isFormChanged
    ) {
      this.onNext(2);
    } else {
      this.setState({ activeStep: stepNumber });
    }
  };

  //////- stepper function- /////
  changeStep = (stepNumber) => {
    var forEdit =
      this.props.warnUserState.isFormChanged &&
        this.state.updateSeasonPassGeneral
        ? true
        : false;
    var forAdd =
      !this.props.warnUserState.isFormChanged &&
        !this.state.updateSeasonPassGeneral
        ? true
        : false;
    var a = forAdd
      ? true
      : this.props.warnUserState.isFormChanged &&
        !this.state.updateSeasonPassGeneral
        ? true
        : false;

    if (forEdit || a) {
      const { activeStep, uploadImage } = this.state;
      if (activeStep === 0) {
        this.onHandleContinue(stepNumber);
      } else {
        if (
          (activeStep === 1 &&
            stepNumber < activeStep &&
            uploadImage.images.length > 0) ||
          (activeStep === 1 &&
            stepNumber < activeStep &&
            uploadImage.images.length === 0)
        ) {
          if (stepNumber === 0) {
            this.changeNext(stepNumber);
          }
        }

        // On image Section and Move Backward with click on stepper
        else {
          if (
            (activeStep === 1 &&
              stepNumber > activeStep &&
              uploadImage.images.length > 0) ||
            (activeStep === 1 &&
              stepNumber > activeStep &&
              uploadImage.images.length === 0)
          ) {
            if (stepNumber === 2) {
              this.changeNext(stepNumber);
            }
          } else {
            this.postSeasonPricing();
          }
        }
      }
    } else {
      if (stepNumber === 1) {
        this.getImageDetail(this.state.uploadImage.seasonPassId);
      }
      this.setState({ activeStep: stepNumber });
    }
  };

  // -General Screen Season Pass continue function- //
  onHandleContinue = (e, stepNumber) => {
    const { minItemPerOrder, maxItemPerOrder } =
      this.state.seasonPassGenralInfo;

    if (this.validatorGeneral.allValid() === false) {
      this.validatorGeneral.showMessages();
      this.forceUpdate();
      this.props.actions.showNotification(
        {
          title: "Warning",
          message: "Please fill all required fields.",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
      this.setState({
        seasonPassGenralInfo: {
          ...this.state.seasonPassGenralInfo,
          valid: false,
        },
      });
      return false;
    } else if (
      minItemPerOrder || maxItemPerOrder
        ? minItemPerOrder >= maxItemPerOrder
        : null
    ) {
      this.props.actions.showNotification(
        {
          title: "Warning",
          message: `Max Per Order item can not less then min per order.`,
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
      return false;
    } else if (!this.state.updateSeasonPassGeneral) {
      this.props.actions.apiCall({
        urls: ["ADDSEASONPASS"],
        method: "POST",
        data: this.state.seasonPassGenralInfo,
        onSuccess: (response) => {
          if (response.responseCode === 200) {
            const { uploadImage, seasonPassGenralInfo, seasonPassPrice } =
              this.state;
            seasonPassGenralInfo.seasonPassId = response.keyId;
            uploadImage.seasonPassId = response.keyId;
            seasonPassPrice.seasonPassId = response.keyId;
            this.setState({
              uploadImage,
              seasonPassGenralInfo,
              updateSeasonPassGeneral: true,
            });
            commonFunctions.onApiSucessFormChangedFalse(this);

            this.setState({ activeStep: 1 });
          }
        },
        showNotification: true,
      });
    } else if (
      this.state.updateSeasonPassGeneral &&
      this.props.warnUserState.isFormChanged
    ) {
      const { seasonPassGenralInfo } = this.state;
      this.props.actions.apiCall({
        urls: ["UPDATESEASONPASS"],
        method: "PUT",
        data: seasonPassGenralInfo,
        onSuccess: (response) => {
          commonFunctions.onApiSucessFormChangedFalse(this);
          this.setState({ activeStep: 1 });
        },
        showNotification: true,
      });
    } else if (
      this.state.updateSeasonPassGeneral &&
      !this.props.warnUserState.isFormChanged
    ) {
      this.onNext(1);
    } else {
      this.setState({ activeStep: 1 });
    }
  };

  onHandleChangeGeneral = (e, { name, value, type, checked, data }) => {
    var seasonPassGenralInfo = commonFunctions.onHandleChange(
      e,
      { name, value, type, checked, data },
      this.state.seasonPassGenralInfo,
      this
    );
    this.setState({ seasonPassGenralInfo });
  };
  onHandleChangePricing = (e, { name, value, type, checked, data }) => {
    if (data === "activationDate") {
      this.setState({
        seasonPassPrice: {
          ...this.state.seasonPassPrice,
          activationDate: value,
          expirationDate: value,
        },
      });
    } else {
      this.setState({
        seasonPassPrice: { ...this.state.seasonPassPrice, [data]: value },
      });
    }
  };
  onHandleChangeCustom = (e, { name, value, type, checked, data }) => {
    const customerUse = commonFunctions.getGlobalCodeDetails(
      this.props.global.codes,
      "CategoryType",
      "Unlimited Uses"
    ).globalCodeId;
    const boolenvalues = value === customerUse;
    if (boolenvalues === true) {
      this.setState({
        seasonPassPrice: {
          ...this.state.seasonPassPrice,
          seasonPassUsageType: value,
          seasonPassUsageTypeValue: "",
          durationTypeId: null,
        },
      });
    } else {
      this.setState({
        seasonPassPrice: { ...this.state.seasonPassPrice, [data]: value },
      });
    }
  };
  // ***********************MinMaxTimeDuration*************************** //
  onHandleMaxDurationChange = (e, { value, data }) => {
    var ex = /^(?:\d*\d{1,2}|\d+)$/;
    if (ex.test(value) === true) {
      this.setState({
        seasonPassGenralInfo: {
          ...this.state.seasonPassGenralInfo,
          [data]: parseInt(value),
        },
      });
    }
    if (ex.test(value) === false) {
      this.setState({
        seasonPassGenralInfo: {
          ...this.state.seasonPassGenralInfo,
          [data]: null,
        },
      });
    }
    commonFunctions.onFormChange(this);
  };
  onHandleMinDuration = (e, { name, value, type, checked, data }) => {
    var ex = /^(?:\d*\d{1,2}|\d+)$/;
    this.setState({
      seasonPassGenralInfo: {
        ...this.state.seasonPassGenralInfo,
        [data]: parseInt(value),
      },
    });

    if (ex.test(value) === false) {
      this.setState({
        seasonPassGenralInfo: {
          ...this.state.seasonPassGenralInfo,
          [data]: null,
        },
      });
    }
    commonFunctions.onFormChange(this);
  };
  // for get dropdown id of inventory location //
  onDropDownTreeChange = (value) => {
    this.setState({
      seasonPassGenralInfo: {
        ...this.state.seasonPassGenralInfo,
        categoryId: value,
      },
    });
    commonFunctions.onFormChange(this);
  };
  //***********/ Pricing Section ****************//
  sesonPassToggal = (e, { checked }) => {
    const { seasonPassPrice } = this.state;
    if (checked === false) {
      seasonPassPrice.seasonPassPricingType = "A";
      seasonPassPrice.seasonPassPricing = false;
      seasonPassPrice.discountAmount = null;
      this.setState({ seasonPassPrice });
    }
    if (checked === true) {
      seasonPassPrice.seasonPassPricingType = "P";
      seasonPassPrice.seasonPassPricing = true;
      seasonPassPrice.amountDiscount = null;
      this.setState({ seasonPassPrice });
    }
    commonFunctions.onFormChange(this);
  };
  onHandleChangePrice = (e, { name, value, type, checked, data }) => {
    var ex = /^\d*(\.\d+)?$/;
    if (ex.test(value) == true) {
      this.setState({
        seasonPassPrice: {
          ...this.state.seasonPassPrice,
          [data]: value,
        },
      });
    }
    if (ex.test(value) === false) {
      this.setState({
        seasonPassPrice: { ...this.state.seasonPassPrice, [data]: null },
      });
    }
    commonFunctions.onFormChange(this);
  };

  blackOutDateModalModal = () => {
    this.setState({
      blackOutDateStatus: !this.state.blackOutDateStatus,
    });
    this.getBlackOutDateList();
  };
  getBlackOutDateList = () => {
    this.props.actions.apiCall({
      urls: ["GETBLACKOUTDATE"],
      method: "GET",
      data: {
        businessId: this.props.global.bussinessDetail.businessId,
        blackOutDateId: -1,
        InDiscountAndCoupons:
          this.props.InDiscountAndCoupons === true ? true : false,
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        PageNo: 1,
        PageSize: 100,
      },
      onSuccess: (response) => {
        this.setState({ blackOutDatesList: response });
      },
    });
  };
  //  get all value in gernal form  //
  getGeneralInfo = (editId) => {
    this.props.actions.apiCall({
      urls: ["GETSEASONPASS"],
      method: "GET",
      data: {
        businessId: this.props.global.bussinessDetail.businessId,
        SeasonPassId: editId,
        businessCategoryType:
          this.props.global.inventoryDetail.businessCategoryId,
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        categoryId: this.props.global.inventoryDetail.categoryId,
        pageNo: 1,
        pageSize: 100,
      },
      onSuccess: (response) => {
        const { seasonPassGenralInfo } = this.state;
        seasonPassGenralInfo.itemName = response.itemName;
        seasonPassGenralInfo.itemDescription = response.itemDescription;
        seasonPassGenralInfo.stockIdPrefix = response.stockIdPrefix;
        seasonPassGenralInfo.itemQuantity = response.itemQuantity;
        seasonPassGenralInfo.customField = JSON.parse(response.customFields);
        seasonPassGenralInfo.color = response.color;
        seasonPassGenralInfo.inStore = response.inStore;
        seasonPassGenralInfo.websiteWidget = response.websiteWidget;
        seasonPassGenralInfo.traits = JSON.parse(response.traits);
        seasonPassGenralInfo.minItemPerOrder = response.minItemPerOrder;
        seasonPassGenralInfo.maxItemPerOrder = response.maxItemPerOrder;
        seasonPassGenralInfo.productTags = JSON.parse(response.productTags);
        seasonPassGenralInfo.categoryId = response.categoryId;
        seasonPassGenralInfo.seasonCustomQuestion = JSON.parse(
          response.seasonPassCustomQuestion
        );
        seasonPassGenralInfo.addOns = JSON.parse(response.addOn);
        seasonPassGenralInfo.inConfirmationEmail = response.inConfirmationEmail;
        seasonPassGenralInfo.webWidget = response.webWidget;
        this.setState({ seasonPassGenralInfo });
        this.state.expandthisnode.push(response.categoryId);
        this.setState({
          customfield: {
            ...this.state.customfield,
            customFieldRows: JSON.parse(response.customFields),
          },
        });
        this.setState({
          seasonPassGenralInfo: {
            ...this.state.seasonPassGenralInfo,
            customerQues: JSON.parse(response[0].seasonPassCustomQuestion),
          },
        });
      },
    });
  };
  getSeasonPricing = (editId) => {
    this.props.actions.apiCall({
      urls: ["GETSEASASONPASSPRICE"],
      method: "GET",
      data: {
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        SeasonPassId: editId,
      },
      onSuccess: (response) => {
          
        let a = JSON.stringify(
          JSON.parse(response.blackOutDates),
          function (key, value) {
            return value === null ? [] : value;
          }
        );
        const { seasonPassPrice } = this.state;
        seasonPassPrice.seasonPassPricing =
          response.seasonPassPricingType === "P" ? true : false;
        seasonPassPrice.seasonPassPricingType = response.seasonPassPricingType;
        seasonPassPrice.discountAmount =
          response.seasonPassPricingType === "P"
            ? response.discountAmount
            : null;
        seasonPassPrice.amountDiscount =response.seasonPassPricingType === "A"? response.discountAmount
            : null;
        seasonPassPrice.seasonPassPrice = response.seasonPassPrice;
        seasonPassPrice.seasonPassPriceId = response.seasonPassPriceId;
        seasonPassPrice.activationDate = commonFunctions.formattedDate( response.activationDate);
        seasonPassPrice.expirationDate = commonFunctions.formattedDate(response.expirationDate);
        seasonPassPrice.isEmailBooked = response.isEmailBooked;
        seasonPassPrice.roleId = response.roleId;
        seasonPassPrice.toEmail = JSON.parse(response.toEmail ?? "[]");
        seasonPassPrice.seasonPassUsageType = response.seasonPassUsageType;
        seasonPassPrice.seasonPassUsageTypeValue =
          response.seasonPassUsageTypeValue;
        seasonPassPrice.durationTypeId = response.durationTypeId;
        seasonPassPrice.customField =
          response.customField === null ? [] : JSON.parse(response.customField);
        seasonPassPrice.seasonPassBlackOutDateValue =
          response.seasonPassBlackOutDates === null
            ? []
            : JSON.parse(response.seasonPassBlackOutDates);
        this.setState({ seasonPassPrice });
        this.setState({
          blackOutRows: {
            ...this.state.blackOutRows,
            blackoutDates: JSON.parse(a),
          },
        });

        this.setState({
          customfield: {
            ...this.state.customfield,
            customFieldRows: JSON.parse(response?.customField),
          },
        });
      },
    });
  };

  // Post API Bundle Pricing
  postSeasonPricing = (stepNumber) => {
    const { seasonPassPrice } = this.state;
    const customerUse = commonFunctions.getGlobalCodeDetails(
      this.props.global.codes,
      "CategoryType",
      "Custom"
    ).globalCodeId;
    const boolenvalues = seasonPassPrice.seasonPassUsageType === customerUse;
    const DefaultValue = boolenvalues
      ? seasonPassPrice.seasonPassUsageTypeValue === "" ||
      seasonPassPrice.seasonPassUsageTypeValue === null
      : seasonPassPrice.seasonPassUsageTypeValue;
    const DefaultTypeId = boolenvalues
      ? seasonPassPrice.durationTypeId === null
      : seasonPassPrice.durationTypeId;
    const CustomFieldValid =
      seasonPassPrice.customField === null ||
      seasonPassPrice.customField.length === 0;
    const seasonPassPercentage =
      seasonPassPrice.seasonPassPricingType === "P" ||
        seasonPassPrice.amountDiscount === null ||
        seasonPassPrice.seasonPassPrice === null
        ? seasonPassPrice.discountAmount === null
          ? null
          : seasonPassPrice.discountAmount <= 100
        : seasonPassPrice.amountDiscount;

    if (seasonPassPercentage ? null : this.validator.allValid() === false) {
      this.validator.showMessages();
      this.forceUpdate();
      this.props.actions.showNotification(
        {
          title: "Warning",
          message: "Please fill all required fields.",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
      this.setState({
        seasonPassPrice: { ...this.state.seasonPassPrice, valid: false },
      });
      return false;
    } else if (seasonPassPrice.expirationDate === "") {
      this.props.actions.showNotification(
        {
          title: "Warning",
          message: "Please Select the ExpirationDate.",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
      return false;
    } else if (DefaultValue) {
      this.props.actions.showNotification(
        {
          title: "Warning",
          message: "Please add the Custom Qty.",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
      return false;
    } else if (DefaultTypeId) {
      this.props.actions.showNotification(
        {
          title: "Warning",
          message: "Please Select one User per.",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
      return false;
    } else if (CustomFieldValid) {
      this.props.actions.showNotification(
        {
          title: "Warning",
          message: "Please add the custom field.",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
      return true;
    } else {
      let seasonPriceSelected = [];
      seasonPassPrice.seasonPassBlackOutDateValue &&
        seasonPassPrice.seasonPassBlackOutDateValue.map((singleObj) => {
          seasonPriceSelected.push({
            blackOutDateId: singleObj,
          });
          return { ...singleObj };
        });
      seasonPassPrice.seasonPassUsageTypeValue = boolenvalues
        ? seasonPassPrice.seasonPassUsageTypeValue
        : null;
      seasonPassPrice.seasonPassBlackOutDates = seasonPriceSelected;
      seasonPassPrice.discountAmount =
        seasonPassPrice.seasonPassPricingType === "A"
          ? seasonPassPrice.amountDiscount
          : seasonPassPrice.discountAmount;
      seasonPassPrice["activationDate"] = commonFunctions.saveFormattedDate(
        seasonPassPrice["activationDate"]
      );
      seasonPassPrice["expirationDate"] = commonFunctions.saveFormattedDate(
        seasonPassPrice["expirationDate"]
      );
      this.props.actions.apiCall({
        urls: ["ADDUPDATESEASONPASSPRICE"],
        method: "POST",
        data: this.state.seasonPassPrice,
        onSuccess: (response) => {
          commonFunctions.onApiSucessFormChangedFalse(this);
          if (this.props.getProductCategoryDetail) {
            this.props.getProductCategoryDetail();
          }
          this.props.closeModal();
        },
        showNotification: true,
      });
    }
  };
  backStepPricng = (e) => {
    if (e === 2) {
      this.getImageDetail(this.state.uploadImage.seasonPassId);
      this.onPrevious(2);
    } else {
      this.getImageDetail(this.state.uploadImage.seasonPassId);
      this.onPrevious(1);
    }
    this.validator = commonFunctions.initializeSimpleValidator();
  };
  getStepContent = (stepIndex) => {
    const { uploadImage } = this.state;
    switch (stepIndex) {
      case 0:
        return (
          <React.Fragment>
            <SeasonPassGeneralInfo
              onNext={this.changeStep}
              onClose={this.props.closeModal}
              onPrev={this.onPrevious}
              fullState={this.state}
              allFunction={this}
            />
          </React.Fragment>
        );
      case 1:
        return (
          <React.Fragment>
            <UploadSeasonPassPhoto
              uploadImage={uploadImage}
              onImageChange={this.onImageChange}
              backStep={() => this.backStep(stepIndex)}
              onNext={this.onNext}
              fullState={this.state}
              allFunction={this}
            />
          </React.Fragment>
        );
      case 2:
        return (
          <React.Fragment>
            <SeasonPassPricing
              onClose={this.props.closeModal}
              onNext={this.changeStep}
              onPrev={this.onPrevious}
              fullState={this.state}
              allFunction={this}
              CustomPrice={CustomPrice}
            />
          </React.Fragment>
        );
      default:
        return null;
    }
  };

  onPrevious = (step) => {
    this.setState({ activeStep: step });
  };
  render() {
    const { activeStep, isCommonDeleteUpdateModalOpen } = this.state;
    return (
      <>
        <Modal
          open={this.props.openModal}
          closeIcon
          onClose={this.onCloseModalStepOne}
          closeOnDimmerClick={false}
          size="large"
        >
          {this.props.editSesonPassModal ? (
            <Modal.Header className="ora">Edit Passes </Modal.Header>
          ) : (
            <Modal.Header className="ora">Add Passes</Modal.Header>
          )}
          <Modal.Content scrolling>
            <Modal.Description>
              <Step.Group stackable="tablet" className="custom-stepper">
                <Step
                  active={activeStep === 0 ? true : false}
                  completed={activeStep > 0 ? true : false}
                  onClick={() => this.changeStepToGeneral()}
                >
                  <Step.Content>
                    <Step.Title>
                      General{" "}
                      <Icon name="check circle" className="green-check" />
                    </Step.Title>
                    <Step.Description>1</Step.Description>
                  </Step.Content>
                </Step>
                <Step
                  active={activeStep === 1 ? true : false}
                  completed={activeStep > 1}
                  onClick={() => this.changeStep(1)}
                >
                  <Step.Content>
                    <Step.Title>
                      Photo <Icon name="check circle" className="green-check" />
                    </Step.Title>
                    <Step.Description>2</Step.Description>
                  </Step.Content>
                </Step>
                <Step
                  active={activeStep === 2 ? true : false}
                  completed={activeStep > 2}
                  onClick={() => this.changeStepToPricing()}
                >
                  <Step.Content>
                    <Step.Title>
                      Pricing{" "}
                      <Icon name="check circle" className="green-check" />
                    </Step.Title>
                    <Step.Description>3</Step.Description>
                  </Step.Content>
                </Step>
              </Step.Group>
              <div>{this.getStepContent(activeStep)}</div>
            </Modal.Description>
          </Modal.Content>
        </Modal>

        {isCommonDeleteUpdateModalOpen && (
          <CommonDeleteUpdateModal
            isModalOpen={isCommonDeleteUpdateModalOpen}
            onConfirm={this.onConfirmWarnUser}
            onClose={() =>
              this.setState({ isCommonDeleteUpdateModalOpen: false })
            }
            confirmationButtonText={"Yes"}
            header={"Are you sure you want to discard your changes ?"}
            cancelButtonText={"Cancel"}
          />
        )}
        {this.state.blackOutDateStatus && (
          <AddBlackOutDates
            getBlackOutDateList={this.getBlackOutDateList}
            commonBlackOut={this.state.commonBlackOut}
            openModal={this.state.blackOutDateStatus}
            closeModal={this.blackOutDateModalModal}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
    warnUserState: state.warnUserState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      storeWarnUser: bindActionCreators(actions.storeWarnUser, dispatch),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddSeasonPassModal);
