import React, { Component } from "react";
import {
  Button,
  Grid,
  Modal,
  Header,
  Radio,
  Form,
  Checkbox,
  Input,
  Select,
  FormRadio,
  Label,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import {
  connect,
  bindActionCreators,
  actions,
  commonFunctions,
} from "../../../../../../functional/global-import";
import SplitSignatureModal from "../../../../modal/split-signature-modal";
import { LoaderComponent } from "../../../../../../components";
import { orderCommonFunctions } from "../../order-common-function";
import AddTipModal from "../../../../modal/add-tipAmount-modal";
import CashonCash from "./custom-on-cash";
import AhoyShareOnCash from "./ahoy-share-on-cash";
class CashPaymentModal extends Component {
  constructor() {
    super();
    this.state = {
      creatediscount: true,
      saveddiscount: false,
      countryOptions: [],
      cashPaymentObj: {
        counterId: "",
        paymentMethod: "SinglePayment",
        totalPayment: null,
      },
      terminal: null,
      discoveredReaders: null,
      connectResult: null,
      paymentIntentId: null,
      changeToGive: null,
      totalCashGiven: null,
      tipPrice: null,
      tipCheckBox: false,
      splitValue: 1,
      splitSignatureModal: false,
      transactionDetails: null,
      balanceDue: null,
      newTotalAmountPaid: null,
      tipDetailData: [],
      tipTotalValue: null,
      customtipTotalValue: null,
      totalAmountCharge: null,
      customTipAdded: false,
      isAddtipModal: false,
      selectedGlobalCodeId:null
    };
    this.validator = commonFunctions.initializeSimpleValidator();
  }
  showsavediscount = () => {
    this.setState({
      saveddiscount: true,
      balanceDue: this.state.cashPaymentObj.totalPayment,
    });
  };
  showcreatediscount = () => {
    this.setState({
      saveddiscount: false,
    });
  };
  componentDidMount() {
    this.getCounterDetails();
  }
  getCounterDetails = () => {
    const { cashPaymentObj } = this.state;
    this.props.actions.apiCall({
      urls: ["GETCOUNTERNAME"],
      method: "GET",
      data: {
        businessId: this.props.global.bussinessDetail.businessId,
        PaymentMethod: -1,
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        PageNo: 1,
        PageSize: 100,
      },
      onSuccess: (response) => {
        const { defaultUserDetails } = this.props;
        const getCounter = response.map((singleCounter) => {
          return {
            value: singleCounter.counterId,
            key: singleCounter.counterId,
            text: singleCounter.counterName,
          };
        });
        const defaultCounterId = defaultUserDetails.defaultCounterId;
        const defaultCounter =
          getCounter.length > 0
            ? orderCommonFunctions.onSelectingDefaultDetail(
                getCounter,
                defaultCounterId,
                "value"
              )
            : null;
        if (getCounter.length > 0) {
          cashPaymentObj["counterId"] = defaultCounter;
        }
        cashPaymentObj["totalPayment"] = this.props.amountSlected;
        this.setState({ cashPaymentObj, countryOptions: getCounter });
      },
    });
  };

  handlePayment = (data, creditCardPayment, stateRef) => {
    this.props.actions.apiCall({
      urls: ["PAYMENTFROMPAYMENTTYPES"],
      method: "POST",
      data: data,
      showNotification: true,
      onSuccess: (response) => {
        this.props.actions.getOrderItems(
          this.props.orderState.currentOrder.orderId
        );
        this.props.closeModal();
        return response;
      },
    });
  };

  handleClose = () => {
    this.setState({
      cashPaymentObj: {
        ...this.state.cashPaymentObj,
        counterId: "",
        paymentMethod: "SinglePayment",
      },
    });
    this.validator = commonFunctions.initializeSimpleValidator();
    this.props.closeModal();
  };

  handleChange = (e, { name, value }) => {
    const { balanceDue } = this.state;
    if (name === "totalPayment") {
      const newAmount = parseFloat(value);
      if (newAmount >= 0) {
        this.setState({
          cashPaymentObj: { ...this.state.cashPaymentObj, [name]: newAmount ,
          },
        });
      } else {
        this.setState({
          cashPaymentObj: { ...this.state.cashPaymentObj, [name]: newAmount ,
           },
        });
      }
    } else {
      if (balanceDue !== null) {
        this.setState({
          cashPaymentObj: {
            ...this.state.cashPaymentObj,
            [name]: value,
            totalPayment: balanceDue,
          },
          balanceDue: null,
          splitValue: 1,
        });
      } else {
        this.setState({
          cashPaymentObj: { ...this.state.cashPaymentObj,
             [name]: value ,
            },
        });
      }
    }
  };
  onCashHandleChange = (e, { value }) => {
    const { cashPaymentObj, tipPrice } = this.state;
    const { fullState } = this.props;
    const orderState = this.props.orderState.currentOrder;
    const AhoyShareONCash = orderState.isAhoyShareONCash;
    const rehntitNewAmount =(orderState.rehntitshare / 100) *cashPaymentObj.totalPayment;
    const totalPaymentShow = parseFloat(cashPaymentObj.totalPayment);
    const ahoyChargeAmount = parseFloat(rehntitNewAmount);
    const ahoyAmount = fullState.isCustomOnCash ? totalPaymentShow : totalPaymentShow + ahoyChargeAmount;
    const totalAhoyAmount = AhoyShareONCash ? ahoyAmount: cashPaymentObj.totalPayment;
    const newAmount = parseFloat(value);
    if (newAmount >= 0) {
    let returnCash = (newAmount - totalAhoyAmount - tipPrice).toFixed(2); 
      this.setState({ totalCashGiven: newAmount, changeToGive: returnCash });
    } else {this.setState({totalCashGiven: null, changeToGive: null});
  }
  };
  onTipCheckboxChange = () => {
    this.setState({
      tipCheckBox: !this.state.tipCheckBox,
      tipPrice: null,
      changeToGive: null,
    });
  };
  onTiphandleChange = (e, { value }) => {          
    const { cashPaymentObj, totalCashGiven } = this.state;
    const orderState = this.props.orderState.currentOrder
    const rehntitNewAmount =(orderState.rehntitshare / 100) *cashPaymentObj.totalPayment;
    const ahoyAmount =cashPaymentObj.totalPayment >= orderState.ahoyMinimumChargeAmount
    ? cashPaymentObj.totalPayment + rehntitNewAmount: cashPaymentObj.totalPayment;
    const AhoyShareONCash = orderState.isAhoyShareONCash;
    const ahoyChargeValue = this.props.fullState.isCustomOnCash ? cashPaymentObj.totalPayment : ahoyAmount;
    const totalAhoyAmount = AhoyShareONCash ? ahoyChargeValue : cashPaymentObj.totalPayment;
    const newAmount = parseFloat(value);
    if (newAmount >= 0) {
      let returnCash = (totalCashGiven - totalAhoyAmount - newAmount).toFixed(2);
      this.setState({ tipPrice: newAmount, changeToGive: returnCash });
    } else {
      this.setState({
        tipPrice: null,
        changeToGive: totalCashGiven - totalAhoyAmount,
      });
    }
  };

  onsplitValueChange = (e, { value }) => {
    const { cashPaymentObj, balanceDue} = this.state;
    const newAmount = parseInt(value);
    if (newAmount >= 1) {
      cashPaymentObj.totalPayment = (balanceDue / newAmount).toFixed(2);
      this.setState({
        ...this.state,
        cashPaymentObj: { ...cashPaymentObj },
        splitValue: newAmount,
      });
    } else {
      cashPaymentObj.totalPayment = balanceDue;
      this.setState({
        ...this.state,
        cashPaymentObj: { ...cashPaymentObj },
        splitValue: 0,
      });
    }
  };

  onSplitPaymentSubmit = (transactionDetails, addPaymentObj) => {
    let newTotalAmountPaid = addPaymentObj.isTipAdded
      ? addPaymentObj.tipAmount + parseFloat(transactionDetails.AmountPaid)
      : transactionDetails.AmountPaid;
    this.setState({
      transactionDetails: transactionDetails,
      splitSignatureModal: !this.state.splitSignatureModal,
      splitDetails: this.state.cashPaymentObj,
      newTotalAmountPaid,
    });
  };

  onSinglePaymentSubmit = (transactionDetails, addPaymentObj) => {
    let newTotalAmountPaid = addPaymentObj.isTipAdded
      ? addPaymentObj.tipAmount + parseFloat(transactionDetails.AmountPaid)
      : transactionDetails.AmountPaid;
    this.setState({
      transactionDetails: transactionDetails,
      splitSignatureModal: !this.state.splitSignatureModal,
      splitDetails: this.state.cashPaymentObj,
      newTotalAmountPaid,
    });               
  };
  onSignSubmit = () => {
    const { balanceDue,cashPaymentObj,splitValue,saveddiscount } = this.state;
    if (saveddiscount) {
      let balanceAfterSplit = balanceDue - cashPaymentObj.totalPayment;
      if (splitValue - 1 === 0) {
        this.handleClose();
      } else {
        this.setState({
          splitValue: this.state.splitValue - 1,
          balanceDue: balanceAfterSplit,
        });
      }
    } else {
      this.handleClose();
    }
  };
  // ----------------- New code add Stripe-Connect and Tip ------------------- //
  getTipAmount = (addProductsTocart) => {
    this.setState({ isTipLoading: true });
    this.props.actions.apiCall({
      urls: ["GETTIPFORPAYMNETS"],
      method: "GET",
      data: {
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        amount: addProductsTocart.amount,
      },
      onSuccess: (response) => {
        this.setState({ tipDetailData: JSON.parse(response.tipData) });
      },
      onFinally: () => {
        this.setState({ isTipLoading: false });
      },
    });
  };
  handleSubmit = (
    e,
    cashPaymentObj,
    isSplitPayment,
    totalCashGiven,
    customCashValue
  ) => {
    const fullState = this.props.fullState;
    const {tipPrice} = this.state;
    const isAhoyShareONCash = this.props.orderState.currentOrder.isAhoyShareONCash;
    const ahoyChargeValue = isAhoyShareONCash && fullState.isCustomOnCash;
    var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
    if (isFormValid){
      cashPaymentObj["paymentType"] = this.props.paymentType;
      cashPaymentObj.tipPrice = tipPrice;
      cashPaymentObj.totalPayment = ahoyChargeValue ? customCashValue
      : cashPaymentObj.totalPayment;
      cashPaymentObj.isSplitPayment = isSplitPayment;
      cashPaymentObj.totalCashGiven = totalCashGiven;
      cashPaymentObj.paymentMethodSubTypeId = fullState.selectedGlobalCodeId;
      cashPaymentObj.isSelectedItem = this.props.value === "Selected Item(s)" ? true : false;
      let modalInfo = {
        stateRef:this,
        modalName:"cash"};
      this.props.onChargeClick(cashPaymentObj, modalInfo);
    } else {
      return false;
    }
  };
  onSubmitNext = (addProductsTocart) => {
    this.setState(
      {
        isAddtipModal: !this.state.isAddtipModal,
        totalAmountCharge: this.props.rehntitShareAmount,
        tipTotalValue: parseFloat(0).toFixed(2),
      },
      () => this.getTipAmount(addProductsTocart)
    );
  };

  render() {
    const {
      countryOptions,
      cashPaymentObj,
      changeToGive,
      totalCashGiven,
      splitValue,
      balanceDue,
      tipPrice,
      tipCheckBox,
      splitSignatureModal,
      splitDetails,
      transactionDetails,
      newTotalAmountPaid,
      isAddtipModal,
    } = this.state;
    const { value, api, orderState, fullState } = this.props;
    const rehntitNewAmount =
      (orderState.currentOrder.rehntitshare / 100) *
      cashPaymentObj.totalPayment;
    const AhoyShareONCash = this.props.orderState.currentOrder.isAhoyShareONCash;
    let cashGiven = parseFloat(cashPaymentObj.totalPayment + (AhoyShareONCash ? rehntitNewAmount : 0));
    const cashGivenTotal = cashGiven.toFixed(2);
    const totalPaymentShow = parseFloat(cashPaymentObj.totalPayment);
    const ahoyChargeAmount = parseFloat(rehntitNewAmount);
    const rehntitShareAmount = parseFloat(
      totalPaymentShow + (AhoyShareONCash ? ahoyChargeAmount : 0)
    ).toFixed(2);
    const customCashCalculation = (cashPaymentObj.totalPayment * 100) / (100 + orderState.currentOrder.rehntitshare);
    const customCashValue = parseFloat(customCashCalculation).toFixed(2);                
    return (
      <Modal
        open={this.props.openModal}
        closeIcon
        onClose={() => this.handleClose()}
        size={"tiny"}
      >
        <Modal.Content>
          {api?.isApiLoading && <LoaderComponent />}
          <Grid>
            <Grid.Column width={16} verticalAlign="middle">
              <Header as="h4" className="orange-color">
                {" "}
                Cash
              </Header>
            </Grid.Column>
            <Grid.Column width={4} verticalAlign="middle">
              <span>
                {" "}
                <b>Counter</b>
              </span>
            </Grid.Column>
            <Grid.Column width={9} verticalAlign="middle">
              <Select
                placeholder="Select Cash Drawer"
                value={cashPaymentObj.counterId}
                name="counterId"
                className="custom-select"
                options={countryOptions}
                fluid
                onChange={this.handleChange}
              />
              {this.validator.message(
                "Counter",
                cashPaymentObj.counterId,
                "required"
              )}
            </Grid.Column>
            <Grid.Column width={8}>
              <Form>
                <Form.Field>
                  <FormRadio
                    label="Single Payment"
                    name="paymentMethod"
                    className="custom-radio-btn"
                    value="SinglePayment"
                    checked={cashPaymentObj.paymentMethod === "SinglePayment"}
                    onChange={this.handleChange}
                    onClick={this.showcreatediscount}
                  />
                </Form.Field>
              </Form>
            </Grid.Column>
            <Grid.Column width={8}>
              {value === "Balance Due" ? (
                <Form>
                  <Form.Field>
                    <Radio
                      label="Split Payment"
                      className="custom-radio-btn"
                      name="paymentMethod"
                      value="SplitPayment"
                      checked={cashPaymentObj.paymentMethod === "SplitPayment"}
                      onChange={this.handleChange}
                      onClick={this.showsavediscount}
                    />
                  </Form.Field>
                </Form>
              ) : null}
            </Grid.Column>
            {/*  tip section */}
            <Grid.Column width={8} verticalAlign="middle">
              <Checkbox
                onChange={this.onTipCheckboxChange}
                checked={tipCheckBox}
                className="custom-toggle"
                label="Tip on"
              />
            </Grid.Column>
            <Grid.Column width={6}>
              {tipCheckBox && (
                <>
                  <Form.Input
                    className="amountInput"
                    labelPosition="left"
                    type="number"
                    name="price"
                    data="price"
                    fluid
                    placeholder="Amount"
                    value={tipPrice}
                    onChange={this.onTiphandleChange}
                  >
                    <Label basic>$</Label>
                    <input />
                  </Form.Input>
                </>
              )}
            </Grid.Column>

            {this.state.saveddiscount && (
              <>
                <Grid.Column width={16}>
                  <Link className="orange-color">
                    Balance Due: $ {parseFloat(balanceDue).toFixed(2)}{" "}
                  </Link>
                </Grid.Column>
                <Grid.Column width={16}>
                  <Link className="orange-color">Split into</Link>
                  <Input
                    value={splitValue}
                    onChange={this.onsplitValueChange}
                    type="text"
                    className="split-into"
                  />
                  <Link className="orange-color">Amounts</Link>
                </Grid.Column>
              </>
            )}
            <Grid.Column width={5}>
              <Link className="orange-color"> Total Amount</Link>
              <span className="plus-sign-credit-modal orange-color"></span>
            </Grid.Column>
            <Grid.Column width={8}>
              <Form.Input
                name="totalPayment"
                disabled={this.props.value !== "Custom Amount" ? true : false}
                labelPosition="left"
                type="number"
                className="amountInput"
                placeholder="Amount"
                value={
                  this.props.value === "Custom Amount"
                    ? cashPaymentObj.totalPayment
                    : parseFloat(cashPaymentObj.totalPayment).toFixed(2)
                }
                onChange={this.handleChange}
              >
                <Label basic>$</Label>
                <input />
              </Form.Input>
            </Grid.Column>

            {fullState.isCustomOnCash ? (
              AhoyShareONCash && (
                <CashonCash
                  rehntitShareAmount={rehntitShareAmount}
                  cashPaymentObj={cashPaymentObj}
                  orderState={orderState}
                  customCashValue={customCashValue}
                  customValue={this.props.value}
                  AhoyShareONCash={AhoyShareONCash}
                />
              )
            ) : (
              <AhoyShareOnCash
                cashPaymentObj={cashPaymentObj}
                AhoyShareONCash={AhoyShareONCash}
                rehntitShareAmount={rehntitShareAmount}
                orderState={orderState}
                customValue={this.props.value}
                allFunction={this}
              />
            )}
            {!AhoyShareONCash && (
              <>
                <Grid.Column width={8} verticalAlign="middle">
                  <Link className="orange-color">Amount to Charge</Link>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Form.Input
                    name="totalPayment"
                    disabled={
                      this.props.value !== "Custom Amount" ? true : false
                    }
                    labelPosition="left"
                    type="number"
                    className="amountInput"
                    placeholder="Amount"
                    value={
                      this.props.value === "Custom Amount"
                        ? cashPaymentObj.totalPayment
                        : parseFloat(cashPaymentObj.totalPayment).toFixed(2)
                    }
                    onChange={this.handleChange}
                  >
                    <Label basic>$</Label>
                    <input />
                  </Form.Input>
                </Grid.Column>
              </>
            )}

            <Grid.Column width={8} verticalAlign="middle">
              <Link className="orange-color">Total Cash Given</Link>
            </Grid.Column>
            <Grid.Column width={8}>
              <Form.Input
                labelPosition="left"
                type="number"
                className="amountInput"
                placeholder="Cash Given"
                value={totalCashGiven}
                onChange={this.onCashHandleChange}
              >
                <Label basic>$</Label>
                <input />
              </Form.Input>
            </Grid.Column>
            {0 > changeToGive && (
              <Grid.Column width={16} textAlign="center">
                <Link className="orange-color">
                  Cash given should be equal or more than{" "}
                  {commonFunctions.isNumberInteger(
                    fullState.isCustomOnCash
                      ? cashPaymentObj.totalPayment
                      : cashGivenTotal
                  )}{" "}
                  to Charge{" "}
                </Link>
              </Grid.Column>
            )}
            <Grid.Column width={8} verticalAlign="middle">
              <Link className="orange-color">Change To Give</Link>
            </Grid.Column>
            <Grid.Column width={8}>
              <Form.Input
                labelPosition="left"
                type="number"
                className="amountInput"
                placeholder="Change To Give"
                value={changeToGive === null ? 0 : changeToGive}
                disabled
              >
                <Label basic>$</Label>
                <input />
              </Form.Input>
            </Grid.Column>
            <Grid.Column width={16} textAlign="right">
              {this.state.saveddiscount ? (
                <Button
                  className="orange-btn"
                  disabled={
                    cashPaymentObj["totalPayment"] === 0
                      ? true
                      : cashPaymentObj["totalPayment"] === null
                      ? true
                      : totalCashGiven === null
                      ? true
                      : 0 > changeToGive
                      ? true
                      : false
                  }
                  onClick={(e) =>
                    this.handleSubmit(
                      e,
                      cashPaymentObj,
                      true,
                      totalCashGiven,
                      customCashValue
                    )
                  }
                  loading={api?.isApiLoading}
                >
                  Charge
                </Button>
              ) : (
                <Button
                  className="orange-btn"
                  disabled={
                    cashPaymentObj["totalPayment"] === 0
                      ? true
                      : cashPaymentObj["totalPayment"] === null
                      ? true
                      : totalCashGiven === null
                      ? true
                      : 0 > changeToGive
                      ? true
                      : false
                  }
                  onClick={(e) =>
                    this.handleSubmit(
                      e,
                      cashPaymentObj,
                      false,
                      totalCashGiven,
                      customCashValue
                    )
                  }
                  loading={api?.isApiLoading}
                >
                  Charge
                </Button>
              )}
            </Grid.Column>
          </Grid>
        </Modal.Content>
        {splitSignatureModal && (
          <SplitSignatureModal
            transactionDetails={transactionDetails}
            onSignSubmit={this.onSignSubmit}
            splitDetails={splitDetails}
            splitAmount={newTotalAmountPaid}
            addDeposit={false}
            onClosedPayment={this.handleClose}
            // counterId={creditCardPayment.counterId}
            // totalPayment={creditCardPayment.totalPayment}
            isModalOpen={splitSignatureModal}
            onClose={() => this.setState({ splitSignatureModal: false })}
          />
        )}
        {isAddtipModal && (
          <AddTipModal
            onClose={this.onSubmitNext}
            openModal={this.state.isAddtipModal}
            fullState={this.state}
            allFunction={this}
            rehntitShareAmount={rehntitShareAmount}
            rehntitSharePercentage={orderState.currentOrder.rehntitshare}
            tipDetailData={this.state.tipDetailData}
          />
        )}
      </Modal>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    newOrderDetail: state.newOrderDetail,
    orderState: state.orderState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      getOrderItems: bindActionCreators(actions.getOrderItems, dispatch),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CashPaymentModal);
