import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
// Library used for connect react app to reducer.
import { Provider as ReduxProvider } from "react-redux";
import configureStore from "./store";
import { PersistGate } from "redux-persist/lib/integration/react";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
let { store, persistor } = configureStore();
// use the Publishable Key 
const stripePromise = loadStripe('pk_live_51IoFcSBhJWmhQWeRIY4xQHJnkF2xBXfpB8P16xQYKImh9Rxib1y4CjSfbwEDc09cMOxhsicxG9Fhj2zioiwnov0100aZmYdwxa');
const options = {
  mode: 'payment',
  amount: 1099,
  currency: 'usd',
  // Fully customizable with appearance API.
  appearance: {
    /*...*/
  },
};
ReactDOM.render(
  <ReduxProvider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <React.StrictMode>
        <Elements stripe={stripePromise} options={options}>
          <App/>
        </Elements>
      </React.StrictMode>
    </PersistGate>
  </ReduxProvider>,


  document.getElementById("root")
);

