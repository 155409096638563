import React, { Component } from 'react';
import { Button, Grid, Modal, Header, Form } from "semantic-ui-react";
import { Link, env, commonFunctions, connect, actions, bindActionCreators } from "../../../../functional/global-import";


class EditAddOnModal extends Component {
    // Put all key & values that need to reset on form submit and reset form.
    get initialState() {
        return {
            addons: { businessId: "", addOnTitle: "", addOnPrice: "", businessLocationId: "", actionPerformedBy: "Admin" }
        };
    }
    constructor() {
        super();
        this.state = { ...this.initialState, customFields: false, customFieldsButton: true, addonList: [], openCommonModal: false };
        this.validator = commonFunctions.initializeSimpleValidator();
    }
    render() {
        
        const { addons, addonList } = this.state;
        // { this.state.addons.businessLocationId = this.props.global.locationSelected.BusinessLocationId }
        // { this.state.addons.businessId = this.props.global }
        return (
            <Modal
                open={this.props.openModal}
                closeIcon
                onClose={this.props.closeModal}
                size={"medium"}
            >
                <Modal.Content>
                    <Grid>
                        <Grid.Column width={16}>
                            <Header as="h3">Add Ons</Header>
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <Form.Input placeholder="Title" className="bordered-input" fluid onChange={this.onHandleChange}
                                error={this.validator.message("addOnTitle", addons.addOnTitle, "required|alpha space|addOnTitle")} name="txtaddOnTitle" data="addOnTitle" value={addons.addOnTitle} />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Form.Input placeholder="Price" className="bordered-input" fluid onChange={this.onHandleChange} error={this.validator.message("addOnPrice", addons.addOnPrice, "required|numeric|addOnPrice")} name="txtaddOnTitle" data="addOnPrice" value={addons.addOnPrice} />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Button className="orange-btn">Choose Image</Button>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={16} textAlign="right">
                            <Button className="blue-btn" as={Link} to={`${env.PUBLIC_URL}/more-setting`} > Back</Button>
                            <Button className="orange-btn" onClick={this.postAddOns}>Update</Button>
                            {/* <Button className="orange-btn" as={Link} to={`${env.PUBLIC_URL}/more-setting/fee`}>Save</Button> */}
                        </Grid.Column>
                    </Grid>
                </Modal.Content>
            </Modal>
        );
    }
}

export default EditAddOnModal;



