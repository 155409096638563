import React, { Component } from "react";
import { Button, Grid, Modal, Dropdown, Checkbox } from "semantic-ui-react";
const options = [
    { key: 'Email Address', text: 'Email Address', value: 'Email Address' },
    { key: 'Weight', text: 'Weight', value: 'Weight' },
]

class ActivityQuestionModal extends Component {
    state = { options }

    handleAddition = (e, { value }) => {
        this.setState((prevState) => ({
            options: [{ text: value, value }, ...prevState.options],
        }))
    }

    handleChange = (e, { value }) => {
        this.setState({ currentValues: value })
    }
    render() {
        const { currentValues } = this.state

        return (
            <Modal open={this.props.openModal} closeIcon onClose={this.props.closeModal} size={"small"}>
                <Modal.Header>Add Questions</Modal.Header>
                <Modal.Content>
                    <Grid>
                        <Grid.Column width={10}>
                            <Dropdown options={this.state.options} placeholder='Question Name' search selection fluid multiple allowAdditions value={currentValues} onAddItem={this.handleAddition} onChange={this.handleChange} className="tagger"/>
                        </Grid.Column>

                        <Grid.Column width={6} verticalAlign="middle">
                            <Checkbox className="custome-checkbox" label="Required" />
                        </Grid.Column>
                        <Grid.Column mobile={8} tablet={4} computer={4}>
                            <Checkbox label="In Store" className="custome-checkbox" />
                        </Grid.Column>

                        <Grid.Column mobile={8} tablet={4} computer={4}>
                            <Checkbox label=" Marketplace" className="custome-checkbox"/>
                        </Grid.Column>
                        <Grid.Column mobile={8} tablet={4} computer={4}>
                            <Checkbox label="Website" className="custome-checkbox"/>
                        </Grid.Column>
                        <Grid.Column width={16} textAlign="right">
                            <Button className="blue-btn" onClick={this.props.closeModal}>Cancel</Button>
                            <Button className="orange-btn" onClick={this.props.closeModal}>Save</Button>
                        </Grid.Column>
                    </Grid>
                </Modal.Content>
            </Modal>
        );
    }
}
export default ActivityQuestionModal;
