import React, { Component } from "react";
import { Grid, Form, Divider, Header, Button } from "semantic-ui-react";
import { connect, bindActionCreators, actions, Notifications, commonFunctions } from "../../../functional/global-import";
import AddRequirementModal from "../../organisms/modal/add-requirement-modal/index";
class CommonRequirement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requirementsList: [],
      selectedReq: [],
      requirementStatus: false,
      selectedOptionsProps: [],
      reqList: []
    };
  }
  appendDocsOptionsIfNotExists = () => {
    const { selectedOptionsProps } = this.state;
    this.setState((prevState) => {
      let otherItems = [];
      selectedOptionsProps.forEach((selected) => {
        var hasItem = false;
        prevState.requirementsList.forEach((requirement) => {
          if (requirement.id === selected.id || requirement.requirementTitle === selected.requirementTitle) {
            hasItem = true;
          }
        });

        if (!hasItem) {
          otherItems.push(selected);
        }
      });
      let allOptions = Array.from(new Set([...prevState.requirementsList, ...otherItems]));
      return (prevState.requirementsList = allOptions);
    });
  };
  getRequirementList = () => {
    this.props.actions.apiCall({
      urls: ["GETREQUIREMENTS"],
      method: "GET",
      data: {
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId: this.props.global.locationSelected
          .BusinessLocationId,
        requirementId: -1,
        PageNo: 1,
        PageSize: 100,
        isActive: true
      },
      onSuccess: (response) => {
        let requirementsList = [];
        // Extracting Selected documents by id
        requirementsList = response.map((req) => {

          if (req.requirementId) {
            return {
              id: req.requirementId,
              value: req.requirementTitle,
              text: req.requirementTitle,
              requirementTitle: req.requirementTitle,
              businessId: req.businessId,
              businessLocationId: req.businessLocationId,
              isActive: req.isActive,
              requirementId: req.requirementId
            };
          }
        });
        this.setState(
          {
            requirementsList,
            reqList: [],
          },
          () => {
            this.appendDocsOptionsIfNotExists();
          }
        );

      },
    });
  };
  componentDidMount() {
    this.getRequirementList();
  }

  
  componentDidUpdate(prevProps, prevState) {
    const { fullState, stateForm, variableName } = this.props;
    
    let selectedOptionsProps = fullState.addItemGeneral?.requirements || fullState.addActivityGeneralInfo?.requirements || [];
    if (
      prevProps.fullState.addItemGeneral?.requirements != this.props.fullState.addItemGeneral?.requirements ||
      prevProps.fullState.addActivityGeneralInfo?.requirements != this.props.fullState.addActivityGeneralInfo?.requirements
    ) {
      selectedOptionsProps = selectedOptionsProps.map((item, ind) => {
        return {
          id: ind,
          value: item,
          text: item,
          requirementTitle: item,
          requirementId: ind,
        };
      });
      this.setState({ selectedOptionsProps }, () => {
        this.appendDocsOptionsIfNotExists();
      });
    }
    
    if (
      this.props.fullState[stateForm][variableName] !== this.state.reqList &&
      this.state.requirementsList &&
      this.state.requirementsList.length > 0 &&
      fullState.addOrEdit !== "add" &&
      this.state.dropDownEditValueSet === false
    ) {
      if (
        fullState[stateForm][variableName] &&
        fullState[stateForm][variableName].length > 0
      ) {
        this.setState({ reqList: fullState[stateForm][variableName] });
      }
    }
    if (
      this.props.isEdited &&
      fullState[stateForm][variableName] !== this.state.selectedReq &&
      fullState[stateForm][variableName].length > 0 &&
      !this.state.dropDownEditValueSet
    ) {
      let editDropdown = fullState[stateForm][variableName].map((singleObj) => {
        return singleObj;
      });
      if (fullState.addItemGeneral) {
        const unique = Array.from(new Set([fullState.addItemGeneral.selectedReq, ...editDropdown]))
        this.setState({ selectedReq: unique.filter(item => typeof (item) !== 'undefined'), dropDownEditValueSet: true });
      }
      else {
        const unique = Array.from(new Set([fullState.addActivityGeneralInfo.selectedReq, ...editDropdown]))
        this.setState({ selectedReq: unique.filter(item => typeof (item) !== 'undefined'), dropDownEditValueSet: true });

      }
    }
  }
  onHandleChange = (e, { name, value, type, checked, data, id }) => {
    const { fullState, stateForm, allFunction } = this.props;
    const { requirementsList } = this.state;
    const selectedRequirements = [];
    //Get all Selected documents from list of documents
    requirementsList.forEach((obj) => {
      value.forEach((singleObj) => {
        if (obj.id === singleObj) {
          selectedRequirements.push(obj.requirementTitle);
        }
      });
    });
    fullState[stateForm][data] = value;
    commonFunctions.onFormChange(allFunction);
    // var addDocument = commonFunctions.onHandleChange(e, { name, value, type, checked, data }, fullState[stateForm], allFunction);
    // fullState[stateForm] = addDocument;
    allFunction.setState({ ...fullState });
    this.setState({ selectedReq: value });
  };

  // onHandleChangeCheckbox = (e, { name, value, type, checked, data }) => {
  //                           
  //   this.setState({selectedReq: data});
  // };
  onHandleChangeCheckbox = (e, { name, value, type, checked, data }) => {

    const { fullState, stateForm, allFunction } = this.props;
    var cancellationPolicy = commonFunctions.onHandleChange(e, { name, value, type, checked, data }, fullState[stateForm], allFunction);
    fullState[stateForm] = cancellationPolicy;
    allFunction.setState({ ...fullState });
  };
  requirementModal = () => {
    this.setState({
      requirementStatus: !this.state.requirementStatus,
    });
    this.getRequirementList();
  };
  render() {
    const { fullState, stateForm } = this.props;

    const { selectedReq, requirementsList } = this.state;
    return (
      <React.Fragment>
        <Grid.Column width={16}>
          <Divider />
        </Grid.Column>
        <Grid.Column width={16}>
          <Header as="h4">Requirements</Header>
        </Grid.Column><Grid.Column mobile={16} tablet={8} computer={8}>
          <Form.Dropdown placeholder="Requirements" options={requirementsList} value={selectedReq}
            data="requirements" type="multiple-select" className="custom-select" search selection multiple
            fluid onChange={this.onHandleChange} /></Grid.Column>
        <Grid.Column mobile={16} tablet={8} computer={8}>
          <Button className="orange-btn" onClick={this.requirementModal}>Add New Requirement</Button></Grid.Column>
        <Grid.Column width={16}></Grid.Column>
        <Grid.Column mobile={16} tablet={8} computer={4} verticalAlign="middle">
          <Form.Checkbox
            label="In confirmation email" className="custome-checkbox"
            data="requirementsInConfirmationEmail"
            onChange={this.onHandleChangeCheckbox}
            checked={fullState[stateForm].requirementsInConfirmationEmail ? true : false}
          />
        </Grid.Column>
        <Grid.Column mobile={4} tablet={4} computer={4} verticalAlign="middle">
          <Form.Checkbox
            label="In Store"
            data="requirementInStore"
            checked={fullState[stateForm].requirementInStore ? true : false}
            onChange={this.onHandleChangeCheckbox}
            className="custome-checkbox"

          />
        </Grid.Column>
        <Grid.Column mobile={4} tablet={8} computer={4} verticalAlign="middle">
          <Form.Checkbox
            label="Website"
            data="requirementWebsiteWidget"
            checked={fullState[stateForm].requirementWebsiteWidget ? true : false}
            onChange={this.onHandleChangeCheckbox}
            className="custome-checkbox"
          />
        </Grid.Column>
        <AddRequirementModal
          openModal={this.state.requirementStatus}
          closeModal={this.requirementModal}
          getRequirementList={this.getRequirementList}
        />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CommonRequirement);
