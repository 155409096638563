import React, { Component } from "react";
import {
  Button,
  Grid,
  Form,
  Icon,
  Dropdown,
  Header,
  Table,
  Divider,
  Dimmer,
  Loader,
  Popup,
} from "semantic-ui-react";
import { AvailabilityModal, AddBlackOutDates } from "../../../../components";
import {
  commonFunctions,
  bindActionCreators,
  actions,
  connect,
  Notifications,
} from "../../../../functional/global-import";
import {
  CommonChooseTags,
  CommonCustomField,
  CommonChooseColor,
  CommonAvailability,
  CommonBlackOutDates,
  CommonCancellationPolicy,
} from "../../../../components";
import { TreeSelect } from "antd";
import "antd/dist/antd.css";

class BundleGeneralInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteModal: false,
      displayColorPicker: false,
    };
    this.validator = commonFunctions.initializeSimpleValidator();
  }

  onhandlePushBlackOutDates = (e) => {
    const { title, blackOutStartDate, blackOutEndDate, addBundal } = this.state;
    addBundal["productBlackOutDates"].push({
      title: title,
      blackOutStartDate: blackOutStartDate,
      blackOutEndDate: blackOutEndDate,
    });
    this.setState({ addBundal });
    this.setState({ title: "", blackOutStartDate: "", blackOutEndDate: "" });
  };

  componentDidMount() {
    this.getBlackOutDateList();
  }

  componentDidMount() {
    this.props.getCategory();
    const { addBundal } = this.props;
    addBundal.businessId = this.props.auth.loggedIn.businessId;
    addBundal.businessLocationId =
      this.props.global.locationSelected.BusinessLocationId;
    addBundal.actionPerformedBy = this.props.auth.userDetail.emailId;
    this.setState({ addBundal });
  }

  addCustomPriceRow() {
    let addSingleRow = this.state.addCustomPriceRows;
    addSingleRow.push({ id: addSingleRow.length + 1 });
    this.setState({ addCustomPriceRows: addSingleRow });
  }

  removeCustomPriceRow(index) {
    let selectedRow = this.state.addCustomPriceRows;
    selectedRow.splice(index, 1);
    this.setState({ addCustomPriceRows: selectedRow });
  }

  handlePriceChange = (e, { value }) => this.setState({ value });

  AvailabilityModal = () => {
    this.setState({
      availabilityStatus: !this.state.availabilityStatus,
    });
    this.props.getTimeFrame();
  };

  AddBlackOutDates = () => {
    this.setState({
      blackoutStatus: !this.state.blackoutStatus,
    });
    this.props.getBlackOutDateDetail();
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  render() {
    const {
      addBundal,
      categoryList,
      onHandleChange,
      onCheckboxHandle,
      filteredGlobalCodes,
      onHandleMinMax,
      api,
      firstStepCompleted,
      onDropDownTreeChange,
      globalBusinesCategories,
      allFunction,
      fullState,
    } = this.props;
    const { availabilityStatus, blackoutStatus } = this.state;

    return (
      <React.Fragment>
        {api.isApiLoading && (
          <Dimmer active inverted>
            <Loader />
          </Dimmer>
        )}
        <Grid>
          <Grid.Column width={16}>
            <Header as="h4">
              General Infomation &nbsp;
              <Popup
                trigger={
                  <Icon
                    name="info circle"
                    className="orange-color discount-css"
                  />
                }
              >
                <div>
                  All General Bundle Information will not be displayed in the
                  receipt / reservation confirmation email. To display any of
                  this information in the receipt / confirmation email, please
                  create a custom field with the same information and check the
                  “confirmation email” box. Do not check the “website” box or
                  you will have two of the same displayed online. If the custom
                  field is unique and you want to display on your website, you
                  can check the “website” box.
                </div>
              </Popup>
            </Header>
          </Grid.Column>
          <Grid.Column width={16}>
            <Form>
              <Form.Input
                placeholder="Title"
                error={allFunction.validatorGeneral.message(
                  "Title",
                  fullState.addBundal.bundleTitle,
                  "required|min:0|max:100"
                )}
                data="bundleTitle"
                onChange={onHandleChange}
                value={fullState.addBundal.bundleTitle}
                fluid
              />
            </Form>
          </Grid.Column>
          <Grid.Column width={8} className="controlField">
            <TreeSelect
              showSearch
              filterTreeNode={(search, item) => {
                return (
                  item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0
                );
              }}
              treeDefaultExpandedKeys={fullState.expandthisnode}
              value={!api.isApiLoading && fullState.addBundal.categoryId}
              treeData={fullState.allCategoryList}
              placeholder="Choose Inventory Location"
              onChange={allFunction.onDropDownTreeChange}
            />
            {allFunction.validatorGeneral.message(
              "inventoryLocation",
              fullState.addBundal.categoryId,
              "required"
            )}
          </Grid.Column>
          <CommonChooseTags
            fullState={this.props.fullState}
            allFunction={this.props.allFunction}
            stateForm="addBundal"
            variableName="bundleTags"
          />

          <Grid.Column width={16}>
            <Form>
              <Form.TextArea
                placeholder="Description"
                rows="2"
                max="2000"
                error={allFunction.validatorGeneral.message(
                  "Title",
                  addBundal.bundleDescription,
                  "|max:2000|min:1"
                )}
                data="bundleDescription"
                type="textarea"
                onChange={onHandleChange}
                value={addBundal.bundleDescription}
              />
            </Form>
          </Grid.Column>

          <CommonCustomField
            fullState={fullState}
            allFunction={allFunction}
            stateForm="addBundal"
            variableName="customField"
          />
          <Grid.Column
            mobile={8}
            tablet={8}
            computer={4}
            verticalAlign="middle"
          >
            <Form.Checkbox
              label="Website"
              className="custome-checkbox"
              data="webWidget"
              checked={fullState.addBundal.webWidget}
              value={fullState.addBundal.webWidget}
              onChange={allFunction.onHandleChange}
            />
          </Grid.Column>

          <Grid.Column
            mobile={8}
            tablet={8}
            computer={4}
            verticalAlign="middle"
          >
            <Form.Checkbox
              label="In Confirmation Email"
              className="custome-checkbox"
              data="inConfirmationEmail"
              checked={fullState.addBundal.inConfirmationEmail}
              value={fullState.addBundal.inConfirmationEmail}
              onChange={allFunction.onHandleChange}
            />
          </Grid.Column>

          <Grid.Column width={14}>
            <Form.Dropdown
              placeholder="Product list"
              search
              selection
              fluid
              multiple
              error={allFunction.validatorGeneral.message(
                "Add Product",
                fullState.addBundal.id,
                "required"
              )}
              options={filteredGlobalCodes}
              data="id"
              value={addBundal.id}
              onChange={onHandleChange}
              name="ddlProductList"
              type="multiple-select"
            />
          </Grid.Column>
          <Grid.Column width={2} verticalAlign="middle">
            <Dropdown item simple icon="filter" className="orange-color">
              <Dropdown.Menu className="left">
                {globalBusinesCategories.map((detail, index) => {
                  const businessCategoryType = detail.text;
                  return (
                    <Dropdown.Item>
                      <Form.Checkbox
                        label={detail.text}
                        className="custome-checkbox"
                        id={"Check" + detail.id}
                        value={detail.id}
                        data={detail.text}
                        onChange={(e) =>
                          onCheckboxHandle(
                            e,
                            detail.checked,
                            businessCategoryType,
                            index
                          )
                        }
                      />
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </Grid.Column>
          <Grid.Column width={16} verticalAlign="middle"></Grid.Column>

          {addBundal.bundleDetails &&
            addBundal.bundleDetails.map((data, index) => {

              return (
                <React.Fragment>
                  <Grid.Column width={4} verticalAlign="middle">
                    <p>
                      {data.ProductName} <b>({data.BusinessCategoryTypeId})</b>
                    </p>
                  </Grid.Column>
                  <Grid.Column width={5}>
                    <Form.Field className="add-numbers">
                      <Icon
                        name="minus"
                        data="quantity"
                        className="orange-button"
                        rowId={index}
                        value={data.quantity}
                        onClick={onHandleMinMax}
                      ></Icon>
                      <Form.Input
                        readOnly
                        placeholder="Quantity"
                        value={addBundal.bundleDetails[index].quantity}
                        rowId={index}
                        fluid
                      />
                      <Icon
                        name="plus"
                        className="orange-button plus-btn"
                        value={data.quantity}
                        rowId={index}
                        data="quantity"
                        onClick={onHandleMinMax}
                      ></Icon>
                    </Form.Field>
                  </Grid.Column>

                  <Grid.Column width={3} verticalAlign="middle">
                    <Form.Input
                      placeholder="Min"
                      type="number"
                      min="0"
                      rowId={index}
                      onChange={allFunction.onHandleMinMax}
                      error={allFunction.validatorGeneral.message(
                        "minimumNumber",
                        fullState.addBundal.bundleDetails[index].min,
                        `min:0,num`
                      )}
                      data="min"
                      value={addBundal.bundleDetails[index].min}
                      fluid
                    />
                  </Grid.Column>

                  <Grid.Column width={3} verticalAlign="middle">
                    <Form.Input
                      placeholder="Max"
                      type="number"
                      min="0"
                      rowId={index}
                      onChange={onHandleMinMax}
                      data="max"
                      error={allFunction.validatorGeneral.message(
                        "maximumNumber",
                        fullState.addBundal.bundleDetails[index].max,
                        `min:0,num|minMaxValue:${fullState.addBundal.bundleDetails[index].min}:Value`
                      )}
                      value={addBundal.bundleDetails[index].max}
                      fluid
                    />
                  </Grid.Column>
                </React.Fragment>
              );
            })}
          <React.Fragment>
            <Grid.Column width={16}>
              <Divider />
            </Grid.Column>
            <CommonChooseColor
              fullState={this.props.fullState}
              allFunction={this.props.allFunction}
              stateForm="addBundal"
              variableName="color"
            />
            <Grid.Column width={16}>
              <Header as="h4"> Available On </Header>
            </Grid.Column>
            <Grid.Column mobile={8} tablet={4} computer={4}>
              <Form.Checkbox
                label="In Store"
                data="inStore"
                checked={addBundal.inStore ? true : false}
                onChange={onHandleChange}
                className="custome-checkbox"
              />
            </Grid.Column>
            <Grid.Column mobile={8} tablet={4} computer={4}>
              <Form.Checkbox
                label="Website"
                data="websiteWidget"
                checked={addBundal.websiteWidget ? true : false}
                onChange={onHandleChange}
                className="custome-checkbox"
              />
            </Grid.Column>
            <Grid.Column width={16}>
              <Divider />
            </Grid.Column>
            <CommonAvailability
              fullState={this.props.fullState}
              allFunction={this.props.allFunction}
              stateForm="addBundal"
              variableName="bundleAvailability"
              itemName={"availability"}
              validator={this.props.allFunction.validatorGeneral}
            />
          </React.Fragment>
          <CommonBlackOutDates
            fullState={this.props.fullState}
            allFunction={this.props.allFunction}
            stateForm="addBundal"
            variableName="bundleBlackOutDateId"
          />

          <CommonCancellationPolicy
            fullState={this.props.fullState}
            allFunction={this.props.allFunction}
            stateForm="addBundal"
          />

          <Grid.Column width={16} textAlign="right">
            <Button
              className="orange-btn"
              loading={api.isApiLoading}
              onClick={() => allFunction.onHandleContinue("continue")}
            >
              Continue
            </Button>
          </Grid.Column>
        </Grid>
        <AvailabilityModal
          openModal={availabilityStatus}
          closeModal={this.AvailabilityModal}
          getTimeFrame={() => this.getTimeFrame()}
        />
        <AddBlackOutDates
          openModal={blackoutStatus}
          closeModal={this.AddBlackOutDates}
          getBlackOutDateList={() => this.getBlackOutDateList()}
        />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    auth: state.auth,
    global: state.global,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BundleGeneralInfo);
