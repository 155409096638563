import React, { Component } from "react";
import { Divider, Grid, Header, Form, Button, Dimmer, Loader, Popup, Icon } from "semantic-ui-react";
import { CommonChooseColor, CommonCustomField, CommonTraits, CommonAvailableOn, CommonCustomerQuestions, CommonAddOns, CommonChooseTags, CommonMinTimeDuration } from "../../../organisms";
import { connect, bindActionCreators, actions, Notifications } from "../../../../functional/global-import";
import { TreeSelect } from 'antd';
import 'antd/dist/antd.css';

class AddRetailGeneral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: ""
    };
  }
  backStep = (e) => {
    e.preventDefault();
    this.props.onPrev(this.props.back)
  }
  render() {
    const { api, allFunction, fullState } = this.props;
    return (
      <React.Fragment>
        {api.isApiLoading && (
          <Dimmer active inverted>
            <Loader />
          </Dimmer>
        )}
        <Grid>
          <Grid.Column width={16}>
            <Header as="h4">General item Infomation &nbsp;
              <Popup trigger={<Icon name="info circle" className="orange-color discount-css" />}>
                <div>All General Item Information will not be displayed in the receipt / reservation confirmation email. To display any of this information in the receipt / confirmation email, please create a custom field with the same information and check the “confirmation email” box. Do not check the “website” box or you will have two of the same displayed online. If the custom field is unique and you want to display on your website, you can check the “website” box.</div>
              </Popup></Header>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={4}>
            <Form>
              <Form.Input placeholder="Item Name" fluid data="itemName" value={fullState.addRetailGeneral.itemName}
                error={allFunction.validator.message("itemName", fullState.addRetailGeneral.itemName, "required|min:0|max:100")}
                onChange={allFunction.onHandleChangeretail}
              />
            </Form>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={4}>
            <Form>
              <Form.Input placeholder="Item Quantity" type="number" value={fullState.addRetailGeneral.itemQuantity}
                error={allFunction.validator.message("item Quantity", fullState.addRetailGeneral.itemQuantity, "required|integer|min:0,num|numeric|max:2000,num")}
                data="itemQuantity" fluid onChange={allFunction.onHandleChangeretail}
                disabled={fullState.addRetailGeneral.retailId !== undefined ? true : false} />
            </Form>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={4} className="controlField">
            <TreeSelect showSearch filterTreeNode={(search, item) => {
              return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
            }}
              treeDefaultExpandedKeys={this.props.fullState.expandthisnode}
              value={!api.isApiLoading && fullState.addRetailGeneral.categoryId}
              treeData={fullState.allCategoryLists}
              placeholder="Choose Inventory Location"
              onChange={allFunction.onDropDownTreeChange}
            />{allFunction.validator.message("inventoryLocation", fullState.addRetailGeneral.categoryId, "required")}
          </Grid.Column>
          {this.props.trackable && (
            <Grid.Column mobile={16} tablet={8} computer={4}>
              <Form>
                <Form.Input placeholder="Stock ID Prefix" fluid value={fullState.addRetailGeneral.stockIdPrefix}
                  error={allFunction.validator.message("stockIdPrefix", fullState.addRetailGeneral.stockIdPrefix, "required|min:0|max:50")}
                  onChange={allFunction.onHandleChangeretail} data="stockIdPrefix" />
              </Form>
            </Grid.Column>
          )}
          <React.Fragment>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <Form>
                <CommonChooseTags fullState={this.props.fullState} allFunction={this.props.allFunction}
                  stateForm="addRetailGeneral" variableName="productTags" />
              </Form>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <Form>
                <Form.TextArea rows={2} placeholder="Item Description" type="textarea" value={fullState.addRetailGeneral.itemDescription} data="itemDescription" onChange={allFunction.onHandleChangeretail} error={allFunction.validator.message("itemDescription", fullState.addRetailGeneral.itemDescription, "min:0|max:2000")} />
              </Form>
            </Grid.Column>
          </React.Fragment>
          <CommonChooseColor fullState={this.props.fullState} allFunction={this.props.allFunction} stateForm="addRetailGeneral" variableName="color" />
          <CommonMinTimeDuration fullState={this.props.fullState} allFunction={this.props.allFunction} 
          stateForm="addRetailGeneral" validator={allFunction.validator} />
          <CommonCustomField fullState={this.props.fullState} allFunction={this.props.allFunction} stateForm="addRetailGeneral" variableName="customField" />
          <Grid.Column mobile={4} tablet={8} computer={4} verticalAlign="middle">
            <Form.Checkbox label=" Website" className="custome-checkbox" data="webWidget"
              checked={fullState.addRetailGeneral.webWidget ? true : false} onChange={allFunction.onHandleChangeretail} value={fullState.addRetailGeneral.webWidget} />
          </Grid.Column>
          <Grid.Column mobile={12} tablet={8} computer={4} verticalAlign="middle">
            <Form.Checkbox label="In Confirmation Email" className="custome-checkbox" value={fullState.addRetailGeneral.inConfirmationEmail}
            data="inConfirmationEmail" checked={fullState.addRetailGeneral.inConfirmationEmail ? true : false} onChange={allFunction.onHandleChangeretail} />
          </Grid.Column>
          {this.props.trackable &&
            <CommonTraits fullState={this.props.fullState}allFunction={this.props.allFunction} stateForm="addRetailGeneral" variableName="traits" />}
          <CommonAvailableOn fullState={this.props.fullState} allFunction={this.props.allFunction} stateForm="addRetailGeneral" />
          <CommonCustomerQuestions fullState={this.props.fullState} allFunction={this.props.allFunction} stateForm="addRetailGeneral" variableName="retailCustomQuestion" />
          <Grid.Column width={16}>
            <Divider />
          </Grid.Column>
          <CommonAddOns fullState={this.props.fullState}  allFunction={this.props.allFunction} stateForm="addRetailGeneral"/>
        </Grid>
        <Grid>
          <Grid.Column width={16} textAlign="right">
            {!allFunction.props.editRetailDetail ? <Button className="blue-btn" onClick={() => allFunction.onNext(0)}>Back</Button> : null}
            <Button className="orange-btn" onClick={() => allFunction.changeNexted(2)} loading={api.isApiLoading}>Continue</Button>
          </Grid.Column>
        </Grid>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddRetailGeneral);