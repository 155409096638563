import React, { Component } from "react";
import { Grid, Form, Divider, Header } from "semantic-ui-react";
import { commonFunctions } from "../../../functional/global-import";

class CommonCancellationPolicy extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    onHandleChange = (e, { name, value, type, checked, data }) => {
        const { fullState, stateForm, allFunction } = this.props;
        var cancellationPolicy = commonFunctions.onHandleChange(e, { name, value, type, checked, data }, fullState[stateForm], allFunction);
        fullState[stateForm] = cancellationPolicy;
        allFunction.setState({ ...fullState });
    };

    render() {
        const { fullState, allFunction, stateForm } = this.props;

        return (<React.Fragment>
            <Grid.Column width={16}>
                <Divider />
            </Grid.Column>
            <Grid.Column width={16}>
                <Header as="h4">Cancellation Policy Description</Header>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={16}>
                <Form>
                    <Form.TextArea rows={4} value={fullState[stateForm].cancellationPolicyDescription} type="textarea"
                        data="cancellationPolicyDescription" onChange={this.onHandleChange}
                        error={allFunction.validatorGeneral.message("cancellationPolicyDescription", fullState[stateForm].cancellationPolicyDescription,
                            "min:0|max:2000")} />
                </Form>
            </Grid.Column>
            <Grid.Column mobile={8} tablet={4} computer={4}>
                <Form.Checkbox label="In Store" className="custome-checkbox" value={fullState[stateForm].cancellationPolicyInStore} data="cancellationPolicyInStore" onChange={this.onHandleChange} defaultChecked={true} checked={fullState[stateForm].cancellationPolicyInStore ? true : false} />
            </Grid.Column>
            <Grid.Column mobile={8} tablet={8} computer={4}>
                <Form.Checkbox label="Website" className="custome-checkbox" data="cancellationPolicyWebsiteWidget" onChange={this.onHandleChange} checked={fullState[stateForm].cancellationPolicyWebsiteWidget ? true : false} />
            </Grid.Column>
            <Grid.Column mobile={8} tablet={8} computer={4} verticalAlign="middle">
                <Form.Checkbox label="In Confirmation Email" className="custome-checkbox" checked={fullState[stateForm].cancellationPolicyInConfirmationEmail ? true : false} data="cancellationPolicyInConfirmationEmail" onChange={this.onHandleChange} />
            </Grid.Column>

        </React.Fragment>
        )
    }
}
export default CommonCancellationPolicy;