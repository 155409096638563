import React, { Component, Fragment } from "react";
import { Grid, Button, Header } from "semantic-ui-react";
import ShowSignatureDocument from "./show-signature-document";
import ViewDocument from "./view-document";
import SignatureDocument from "./signature-document";
import {
  bindActionCreators,
  actions,
  connect,
  Notifications,
  commonFunctions,
  withRouter,
} from "../../../../../functional/global-import";
import CustomerAddGuestList from "../customer/customer-add-guestlist";

class DocumentType extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDocumentGuestType: true,
      guestListToggleOn: this.props.orderState.currentOrder.guestListToggle,
      documentDetails: {
        delayRender: null,
        orderId: this.props.orderState.currentOrder.orderId,
        //orderDetailId: null,
        //productId: "",
        documentId: "",
        documentName: "",
        candidateName: "",
        signatureImage: "",
        actionPerformedBy: this.props.auth.userDetail.emailId,
        ProductId: this.props.global.inventoryDetail.productId,
        customerId: this.props.match.params.customerId,
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        businessCategoryType:
          this.props.global.inventoryDetail.businessCategoryId,
      },
    };
  }
  callRefInput = React.createRef();
  childRef = React.createRef();
  handleSignatureDocument = () => {
    this.child.current.getSignBase64();
  };

  handleActiveDocument = (activeDocument) => {
    const { documentDetails } = this.state;
    documentDetails["documentId"] = activeDocument.documentId;
    documentDetails["documentName"] = activeDocument.documentName;
    this.setState({ documentDetails });
    this.childRef.current.getOrderCandidateNameperDocumentAsync();
  };
  getSign = (signBase64) => {
    const { documentDetails } = this.state;
    documentDetails["signatureImage"] = signBase64;
    this.setState({ documentDetails });
  };

  handleMultipleSubmit = async (isContinuePressed) => {
    const { documentDetails } = this.state;
    const data = await this.callRefInput.current.handleGetSignBase64();

    const isSignEmpty =
      this.callRefInput.current.child.current.sigPad.current.isEmpty();
    if (documentDetails.candidateName === "") {
      this.props.actions.showNotification(
        {
          title: "Error",
          message: "Please enter Full name",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    } else if (isSignEmpty) {
      this.props.actions.showNotification(
        {
          title: "Error",
          message: "Please sign the document.",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    } else {
      let guestDocumentDetails = [];
      let guestDocumentArray = [];
      guestDocumentDetails =
        this?.childRef?.current?.state?.signatureDocument.filter((item) => {
          return item.isChecked;
        });

      if (guestDocumentDetails.length > 0) {
        guestDocumentArray = guestDocumentDetails.map((detailsObj) => {
          detailsObj.signerId = detailsObj.customerId;
          detailsObj.mainSigner = true;
          return detailsObj;
        });
        documentDetails.guestSign = true;
      } else {
        documentDetails.guestSign = false;
      }
      documentDetails["signatureImage"] = data;
      documentDetails.guestDocument = guestDocumentArray;
      // (documentDetails["orderId"] = this.props.orderState.currentOrder.orderId)
      documentDetails["candidateName"] = documentDetails.candidateName;
      documentDetails["isSigned"] = true;
      documentDetails["signedDate"] = new Date().toISOString();

      this.onDocumentSave(documentDetails, isContinuePressed);
    }
  };

  onDocumentSave = (documentDetails, isContinuePressed) => {
    this.props.actions.apiCall({
      urls: ["ADDORDERPRODUCTDOCUMENTFORGUEST"],
      method: "POST",
      data: { ...documentDetails },
      onSuccess: (response) => {
        this.childRef.current.getOrderCandidateNameperDocumentAsync();
        this.clear();
        isContinuePressed && this.props.onNext(3);
        return response;
      },
      showNotification: true,
    });
  };

  clear = () => {
    const { documentDetails } = this.state;
    documentDetails["candidateName"] = "";
    documentDetails["signatureImage"] = "";
    this.setState({ documentDetails });
    this.callRefInput.current.clear();
  };

  getSignatureDocument() {
    const { documentDetails } = this.state;
    const { orderState } = this.props;
    const nextState = orderState.currentOrder.orderDetails.filter(
      (item, idx) => item.id === orderState.currentOrderDetails.id
    );
    documentDetails["bussinessCateroyType"] = nextState[0].typeId;
  }

  componentDidMount() {
    const { orderState } = this.props;
    const customer = orderState.currentOrder.customerDetail;
    let customerName = customer.firstName + "  " + customer.lastName;
    const { documentDetails } = this.state;
    documentDetails["candidateName"] = customerName;
    documentDetails["delayRender"] = undefined;
    this.setState({
      documentDetails,
      showDocumentGuestType: orderState.currentOrder.guestListToggle,
      guestListToggle: orderState.currentOrder.guestListToggle,
    });
  }
  handleShowOrderCandidate = (CandidateName) => {
    const { documentDetails } = this.state;
    this.props.actions.apiCall({
      urls: ["GETORDERSIGNATUREOFCANDIDATEASYNC"],
      method: "GET",
      data: {
        candidateName: CandidateName,
        orderId: this.props.orderState.currentOrder.orderId,
        documentId: documentDetails.documentId,
      },
      onSuccess: (response) => {
        documentDetails["signatureImage"] = response[0].signatureImage;
        documentDetails["candidateName"] = CandidateName;
        this.state({ documentDetails });
        return response;
      },
    });
  };
  handleChange = (e, { name, value }) => {
    const { documentDetails } = this.state;
    documentDetails["signatureImage"] = "";
    this.setState({
      documentDetails: {
        ...documentDetails,
        [name]: value,
        signatureImage: "",
      },
    });
  };
  handleSubmitNextStep = () => {
    const isSignEmpty =
      this.callRefInput.current.child.current.sigPad.current.isEmpty();
    const { documentDetails } = this.state;
    if (documentDetails.candidateName !== "" && !isSignEmpty) {
      this.handleMultipleSubmit(true);
    } else {
      this.props.onNext(3);
    }
  };

  // handleSubmitNextStep = () => {
  //
  //   if (this.childRef.current.state.signatureDocument.length > 0) {
  //     this.props.onNext(3);
  //   } else {
  //     this.props.onNext(3);
  //     // till now not compulsory to sign document  so comented below line
  //     // this.props.actions.showNotification({ title: "Error", message: 'Something went wrong ', position: 'br', autoDismiss: 5 }, "error");
  //   }
  // };

  onPrevious = () => {
    this.setState({ showDocumentGuestType: true });
  };
  onContinueClick = () => {
    this.setState({ showDocumentGuestType: false });
  };
  onNextBtnClick = () => {
    if (this.props.orderState.currentOrder.pricings) {
      this.props.onNext(3);
    } else {
      this.props.actions.showNotification(
        {
          title: "Error",
          message: "Please add Item to cart first !",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    }
  };
  render() {
    const { documentDetails, showDocumentGuestType, guestListToggleOn } =
      this.state;
    const { getOrderProductDetails, global, orderState, api } = this.props;
    return (
      <Fragment>
        {showDocumentGuestType ? (
          <CustomerAddGuestList
            businessId={global.bussinessDetail.businessId}
            businessLocationId={global.locationSelected.BusinessLocationId}
            ProductId={global.inventoryDetail.productId}
            // customerId={this.props.match.params.customerId}
            businessCategoryId={global.inventoryDetail.businessCategoryId}
            orderId={orderState.currentOrder.orderId}
            onRadioClick={this.props.onRadioClick}
            onClickButton={this.props.onClickButton}
            onEditClickButton={this.props.onEditClickButton}
            onContinueClick={this.onContinueClick}
            onSaveNewCustomerId={this.props.onSaveNewCustomerId}

          />
        ) : (
          <>
            {getOrderProductDetails.length > 0 ? (
              <>
                <Grid>
                  {documentDetails.delayRender !== null ? (
                    <ViewDocument
                      api={api}
                      guestListToggle={guestListToggleOn}
                      handleActiveDocument={this.handleActiveDocument}
                      documentDetails={documentDetails}
                      onNext={this.props.onNext}
                      getOrderProductDetails={getOrderProductDetails}
                    />
                  ) : null}

                  <ShowSignatureDocument
                    allFunction={this}
                    api={api}
                    orderId={this.props.orderState.currentOrder.orderId}
                    documentDetails={documentDetails}
                    ref={this.childRef}
                    apiCall={this.props.actions.apiCall}
                    handleShowOrderCandidate={this.handleShowOrderCandidate}
                  />

                  <SignatureDocument
                    handleChange={this.handleChange}
                    ref={this.callRefInput}
                    documentDetails={documentDetails}
                    getSign={this.getSign}
                  />
                </Grid>

                <Grid>
                  <Grid.Column width={8}>
                    <Button className="blue-btn" onClick={(e) => this.clear()}>
                      Clear
                    </Button>
                    <Button
                      disabled={documentDetails.signatureImage ? true : false}
                      className="orange-btn"
                      onClick={(e) => this.handleMultipleSubmit(false)}
                      //   onClick={(e) => this.handleSubmit()}
                    >
                      Sign
                    </Button>
                  </Grid.Column>
                  <Grid.Column width={8} textAlign="right">
                    <Button
                      className="blue-btn"
                      onClick={
                        orderState.currentOrder.guestListToggle
                          ? this.onPrevious
                          : () => this.props.onPrevious(1)
                      }
                      //   onClick={() => this.props.onPrevious(1)}
                    >
                      {" "}
                      Back{" "}
                    </Button>
                    <Button
                      className="orange-btn"
                      onClick={() => this.handleSubmitNextStep()}
                    >
                      {" "}
                      Continue{" "}
                    </Button>
                  </Grid.Column>
                </Grid>
              </>
            ) : (
              <>
                <Grid>
                  <Grid.Column width={16} textAlign="center">
                    <Header as="h2">No Documnet to Sign</Header>
                  </Grid.Column>
                  <Grid.Column width={16} textAlign="center">
                    <Header as="h2">!</Header>
                  </Grid.Column>
                </Grid>
                <Grid>
                  <Grid.Column width={16} textAlign="right">
                    <Button
                      className="blue-btn"
                      onClick={
                        orderState.currentOrder.guestListToggle
                          ? this.onPrevious
                          : () => this.props.onPrevious(1)
                      }
                      // onClick={() => this.props.onPrevious(1)}
                    >
                      {" "}
                      Back{" "}
                    </Button>
                    <Button
                      className="orange-btn"
                      onClick={this.onNextBtnClick}
                    >
                      {" "}
                      Continue{" "}
                    </Button>
                  </Grid.Column>
                </Grid>
              </>
            )}
          </>
        )}
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    orderState: state.orderState,
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DocumentType));
