import React, { Component } from "react";
import { Button, Modal, Grid } from "semantic-ui-react";
import { DateFormatInput } from '../../../../components';
import { commonFunctions } from "../../../../functional/global-import";
import moment from 'moment';

class CustomDateChoose extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
    this.validator = commonFunctions.initializeSimpleValidator();
  }

  onHandleSubmit = (e) => {
    var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
    if (isFormValid) {
      this.props.closeModal(isFormValid);
    }
  }
  render() {
    const { transactionDetailForm } = this.props;
    return (
      <Modal open={this.props.openModal} closeIcon onClose={() => this.props.closeModal(false)} >
        <Modal.Header>Choose Custom Date</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Grid>


              <Grid.Column width={8}>
                <DateFormatInput name="txtblackOutStartDate" placeholder="From"
                  iconPosition="right" onChange={this.props.onHandleChange}
                  error={this.validator.message("startDate", transactionDetailForm.startDate, "required")}
                  value={transactionDetailForm.startDate}
                  data="startDate" />
              </Grid.Column>

              <Grid.Column width={8}>
                <DateFormatInput
                  id={"endDate"}
                  name="txtblackOutEndDate"
                  placeholder="To"
                  minDate={
                    transactionDetailForm.startDate
                      ? transactionDetailForm.startDate
                      : ""
                  }
                  iconPosition="right" onChange={this.props.onHandleChange}
                  value={transactionDetailForm.endDate}
                  error={this.validator.message("endDate",transactionDetailForm.endDate, "required")}
                  data="endDate" />
              </Grid.Column>

            </Grid>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => this.props.closeModal(false)} className="blue-btn">Back </Button>
          <Button onClick={this.onHandleSubmit} className="orange-btn">Save </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
export default CustomDateChoose;
