import React, { Component } from "react";
import { Grid, Form, Divider, Button, GridColumn } from "semantic-ui-react";
import AddCustomField from "../../../../pages/more-setting/pricing-taxes-setting/add-custom-field";
import { commonFunctions } from "../../../functional/global-import";
class CommonCustomField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customFieldStatus: false,
      localcustomFields: [],
    };
    this.validatorCustomfield = commonFunctions.initializeSimpleValidator();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.customFieldStatus !== this.state.customFieldStatus &&
      this.state.customFieldStatus
    ) {
      this.setState({
        localcustomFields: [
          ...this.props?.fullState[this.props?.stateForm][
            this.props?.variableName
          ],
        ],
      });
    }
  }

  customFieldModal = () => {
    this.setState({ customFieldStatus: !this.state.customFieldStatus });
  };

  addClickCustomField = (e) => {
    const arr = [...this.state.localcustomFields];
    arr.push({ customField: "", customDescriptions: "" });
    this.setState({ localcustomFields: arr });
  };

  onHandleCustomField = (e, { value, id, data }) => {
    const { localcustomFields } = this.state;
    localcustomFields[id][data] = value;
    const newArr = [...localcustomFields];
    this.setState({ localcustomFields: newArr });
  };

  onHandleCustomFieldDescription = (e, { value, id, data }) => {
    const { fullState, allFunction, stateForm, variableName } = this.props;
    const customFieldData = [...fullState[stateForm][variableName]];
    customFieldData[id][data] = value;
    fullState[stateForm][variableName] = customFieldData;
    allFunction.setState(fullState);
    commonFunctions.onFormChange(allFunction);
  };

  onHandleAddCustomField = (closeModal) => {
    const { fullState, stateForm, allFunction, variableName } = this.props;
    if (this.validatorCustomfield.allValid() === false) {
      this.validatorCustomfield.showMessages();
      this.forceUpdate();
    } else {
      fullState[stateForm][variableName] = JSON.parse(
        JSON.stringify(this.state.localcustomFields)
      );
      allFunction.setState({ ...fullState });
      allFunction.props.actions.showNotification(
        {
          title: "Success",
          message: "Custom fields Added Successfully.",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "success"
      );
      closeModal();
      commonFunctions.onFormChange(allFunction);
    }
  };

  handleRemoveSpecificRow = (index) => {
    const { localcustomFields } = this.state;
    localcustomFields.splice(index, 1);
    this.setState({ localcustomFields: [...localcustomFields] });
  };

  render() {
    const { fullState, stateForm, variableName } = this.props;

    return (
      <React.Fragment>
        <Grid.Column computer={4} tablet={8} mobile={16}>
          <Button className="orange-btn" onClick={this.customFieldModal}>
            {(fullState[stateForm][variableName] &&
              fullState[stateForm][variableName].length > 0) ||
            fullState[stateForm][variableName] === [] ||
            null
              ? "Update Custom Field"
              : "Add Custom Field"}
          </Button>
        </Grid.Column>
        <AddCustomField
          openModal={this.state.customFieldStatus}
          closeModal={this.customFieldModal}
          onHandleCustomField={this.onHandleCustomField}
          addClickCustomField={this.addClickCustomField}
          addCustomFields={this.state.localcustomFields}
          handleRemoveSpecificRow={this.handleRemoveSpecificRow}
          onHandleAddCustomField={this.onHandleAddCustomField}
          validatorCustomfield={this.validatorCustomfield}
        />
      </React.Fragment>
    );
  }
}

export default CommonCustomField;
