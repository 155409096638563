
import React, { Component } from 'react';
import SignaturePad from 'react-signature-canvas'

export default class Signature extends Component {

    state = {
    removeSignatureImage: false
    }

    sigPad = React.createRef();
    clear = () => {
        
        if (!this.sigPad.current !== null) {
            this.sigPad.current.clear();
        }
        else {
            this.setState({ removeSignatureImage: true })
        }
    }

    handleSign = () => {
        this.sigPad.current.toDataURL();
        return this.sigPad.current.toDataURL();
    }

    render() {
        
        return (
            <div>
                { this.props.signatureImage || this.state.removeSignatureImage !== false ?
                    <img src={this.props.signatureImage} className='sigCanvas' /> :
                    <SignaturePad
                        canvasProps={{
                            className: 'sigCanvas',
                            // height: this.props.height
                        }}
                        ref={this.sigPad}
                    />}
            </div>
        )
    }

}