import React, { Component } from "react";
import { Grid, Form, Divider, Header } from "semantic-ui-react";
import { commonFunctions, connect, bindActionCreators, actions,  Notifications } from "../../../functional/global-import";

class CommonBookingConfirmation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            booking: [],
            a: ""
        }
    }

    onHandleChange = (e, { name, value, type, checked, data }) => {
        const { fullState, stateForm, allFunction } = this.props;
        var bookingConfirmation = commonFunctions.onHandleChange(e, { name, value, type, checked, data }, fullState[stateForm],allFunction);
        fullState[stateForm] = bookingConfirmation;
        allFunction.setState({ ...fullState });
    };
    getBookingConfirmation = () => {

        // const { fullState, stateForm, allFunction, variableName } = this.props;
        const bookingValue = this.props.global.codes.filter(code => code.categoryName === "BookingConfirmation").map((filtercode) => {
            return { filtercode: filtercode.globalCodeId, value: filtercode.globalCodeId, text: filtercode.codeName }
        });
        this.setState({ booking: bookingValue });
        // const { fullState, stateForm, allFunction, variableName } = this.props;

        // allFunction.setState(() => ({
        //     [stateForm]: { ...fullState[stateForm], [variableName]: bookingValue[0].filtercode }
        // }));
    }
    componentDidMount() {
        this.getBookingConfirmation();
    }

    // componentDidUpdate(prevProps, prevState) {
    //     const { fullState, stateForm, allFunction, variableName } = this.props;
    //     if (prevState.a === "") {
    //         allFunction.setState(() => ({
    //             [stateForm]: { ...fullState[stateForm], [variableName]: this.state.booking[0].filtercode }
    //         }));
    //         this.setState({ a: "a" })
    //     }
    // }

    render() {
        const { fullState, stateForm, variableName } = this.props;
        // const bookingValue = this.props.global.codes.filter(code => code.categoryName === "BookingConfirmation").map((filtercode) => {
        //     return { filtercode: filtercode.globalCodeId, value: filtercode.globalCodeId, text: filtercode.codeName }
        // });
        return (<React.Fragment>
            <Grid.Column width={16}>
                <Divider />
            </Grid.Column>
            <Grid.Column width={16}>
                <Header as="h4">Booking Confirmation</Header>
            </Grid.Column>

            <Grid.Column width={16}>
                <Form className="radio-button">
                    <Form.Group inline>
                        {this.state.booking && this.state.booking.map((list) => {

                            return (
                                <Form.Field>
                                    <Form.Radio label={list.text} className="custom-radio-btn " name="bookingConfirmation" data={variableName} value={list.filtercode} checked={fullState[stateForm][variableName] === list.value} onChange={this.onHandleChange} />
                                </Form.Field>
                            )
                        })}
                    </Form.Group>
                </Form>
            </Grid.Column>
        </React.Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        api: state.api,
        global: state.global,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            apiCall: bindActionCreators(actions.apiCall, dispatch),
            storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
            showNotification: bindActionCreators(Notifications.show, dispatch),
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CommonBookingConfirmation);

