import React, { Component } from "react";
import { Button, Popup, Modal, Grid, Form, Icon, Checkbox, Dimmer, Loader, Header } from "semantic-ui-react";
import { connect, bindActionCreators, actions } from "../../.././../functional/global-import";

class AddItemTraitModal extends Component {
  constructor(props) {

    super(props);
    this.state = {
      traitsList: [],
      addInDropDown: [],
      addTraits: this.props.addTraits
    };
  }

  componentDidMount() {
    this.props.actions.apiCall({
      urls: ["GETTRAITS"], method: "GET", data: {
        BusinessId: this.props.global.bussinessDetail.businessId,
        TraitId: -1, 
        BusinessLocationId: this.props.global.locationSelected.BusinessLocationId,
        PageNo: "1", PageSize: "100"
      }, onSuccess: (response) => {
        const alltraits = response.map((filtercode) => {
          return { key: filtercode.traitId, value: filtercode.traitName, text: filtercode.traitName }
        });
        this.setState({ traitsList: alltraits });
      }
    });
  };

  handleAddition = (e, { value }) => {
    this.setState((prevState) => ({
      traitsList: [{ text: value, value }, ...prevState.traitsList],
    }))
    const addvalue = this.state.addInDropDown;
    addvalue.push(value);
    this.setState({ addInDropDown: addvalue });
  }
  createUI() {
    return (
      this.props.addTraits.map((el, i) => (
        <React.Fragment>
          {!el.isDeleted &&
            <>
              <Grid.Column width={5}>
                <Form>
                  <Form.Dropdown placeholder='Trait Title' options={this.state.traitsList} id={i}
                    fluid allowAdditions search selection value={el.traitName}
                    onAddItem={this.handleAddition}
                    disabled={this.props.click && el.disabled === undefined || this.props.editClick && el.disabled === undefined}
                    data="traitName" className="tagger"
                    error={this.props.validatorTraits.message("traitName", el.traitName, "required|min:0|max:100")}
                    onChange={(e, { value, id, data, checked }) => this.props.onHandleTraits(e, { value, id, data, checked }, el)}
                  />
                </Form>
              </Grid.Column>
              <Grid.Column width={5}>
                <Form>
                  <Form.TextArea fluid placeholder="Description"
                    error={this.props.click === undefined &&
                       this.props.validatorTraits.message("Description",
                      el.traitDescription, "required|min:0|max:500")}
                    disabled={el.isUpdate === false}
                    data="traitDescription" value={el.traitDescription} id={i}
                    onChange={(e, { value, id, data, checked }) => this.props.onHandleTraits(e, { value, id, data, checked }, el)}
                  />
                </Form>
              </Grid.Column>
              {/* {this.props.click && */}
              <Grid.Column width={2}>
                <Form>
                  <Checkbox
                    className="custome-checkbox"
                    data="traitCheckbox"
                    value={el.traitName}
                    checked={el.isUpdate}
                    disabled={el.disabled === true}
                    onChange={(e, { value, id, data, checked }) => this.props.onHandleTraits(e, { value, id, data, checked }, i)}
                    onClick={this.onChangeModal}
                  ></Checkbox>
                </Form>
              </Grid.Column>
              {/* } */}
              <Grid.Column width={2} textAlign="center">
                <Button icon="trash alternate" className="orange-button" title="Delete Row" onClick={() => this.props.handleRemoveTraitsRow(i)} />

              </Grid.Column>
            </>}
        </React.Fragment>
      )));
  }

  onSaveTraits = () => {
    if (this.props.click) {
    this.props.onHandleAddTraits(this.props.closeModal);
    }
    else {
    this.props.onHandleAddTraitsField(this.props.closeModal);
    }
  }

  onCloseModal = () => {
    this.props.closeModal()
    // this.props.getProductDetailList()
  }

  render() {

    const { api } = this.props
    return (
      <Modal
        open={this.props.openModal}
        closeIcon
        onClose={this.onCloseModal}
        size={"small"} closeOnDimmerClick={false}
      >
        {this.props.editTrait === true && this.props.columnNames[0].traitName === '' ?
          <Modal.Header>
            <Grid>
              <Grid.Column width={16} textAlign="center">
                <Header as="h4" className="orange-color">
                  Please add atleast one trait to edit traits description.
                </Header>
              </Grid.Column>

              <Grid.Column width={16} textAlign="center">
                <Button className="blue-btn" onClick={this.props.closeModal}>Close</Button>
              </Grid.Column>
            </Grid>
          </Modal.Header> :
          <>
            <Modal.Header>
              {this.props.editTrait === true ? <> Edit Trait{" "}</> : <> Add Trait{" "}</>}
              <Popup
                content="Traits will be the columns on the Item List
                    View. This will enable you to customize each individual item for
                    each item type you create. Example: Color, Size, Guide Name,
                    etc."
                trigger={<Icon name="info circle" className="orange-color" />}
              />
            </Modal.Header>
            <Modal.Content>
              {api.isApiLoading && (
                <Dimmer active inverted>
                  <Loader />
                </Dimmer>
              )}
              {this.props.addTraits &&
                <Grid>
                  {this.createUI()}
                  <Grid.Column width={2}>
                    <Button icon="plus" className="purple-button" title="Add Row" onClick={() => this.props.addClickCustomTraits(true)} />
                  </Grid.Column>
                </Grid>
              }
              <>
                {this.props.traitDescriptionData && this.props.traitDescriptionData.map((el, i) => {
                  return (
                    <Grid>
                      <Grid.Column width={8}>
                        <Form>
                          <Form.Dropdown placeholder='Trait Title' options={this.state.traitsList}
                            id={i}
                            fluid allowAdditions search selection
                            value={el.traitName}
                            disabled={el.traitName}
                            onAddItem={this.handleAddition}
                            data="traitName" className="tagger"
                            onChange={(e, { value, id, data }) => this.props.onEditTraits(e, { value, id, data }, el)}
                          />
                        </Form>
                      </Grid.Column>
                      <Grid.Column width={8}>
                        <Form>
                          <Form.TextArea fluid placeholder="Description"
                            data="traitDescription"
                            value={el.traitDescription} id={i}
                            onChange={(e, { value, id, data }) => this.props.onEditTraits(e, { value, id, data }, el)}
                          />
                        </Form>
                      </Grid.Column>
                    </Grid>)
                })}
              </>
              <Grid>
                {this.props.editTrait === true ?
                  <Grid.Column width={16} textAlign="right">
                    <Button className="orange-btn" onClick={this.props.onUpdateTraits} >Update</Button>
                  </Grid.Column> :
                  <Grid.Column width={16} textAlign="right">
                    <Button className="orange-btn" onClick={this.onSaveTraits} >Save</Button>
                  </Grid.Column>}
              </Grid>
            </Modal.Content>
          </>}
      </Modal>
    );
  }
}
const mapStateToProps = state => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch)
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddItemTraitModal);

