import React, { Component } from "react";
import {
  Divider,
  Grid,
  Header,
  Form,
  Icon,
  Button,
  Popup,
} from "semantic-ui-react";
import {
  GlobalCodeSelect,
  CommonChooseColor,
  CommonDocument,
  CommonRequirement,
  CommonAvailableOn,
  CommonCustomerQuestions,
  CommonTraits,
  CommonAvailability,
  CommonAddOns,
  CommonCancellationPolicy,
  CommonCustomField,
  CommonBlackOutDates,
  CommonMinTimeDuration,
} from "../../../../components";
import { CommonChooseTags } from "../../../organisms";
import {
  connect,
  bindActionCreators,
  actions,
  Notifications,
} from "../../../../functional/global-import";
// import EmailNotification from "../../../../../pages/more-setting/pricing-taxes-setting/activity-pricing/email-notification";
import { TreeSelect } from "antd";
import "antd/dist/antd.css";
class AddItemGeneral extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  setSelectedDocument = (data) => {
    this.props.getSelectedDocument(data);
  };
  render() {
    const { api, allFunction, fullState } = this.props;
    return (
      <React.Fragment>
        <Grid>
          <Grid.Column width={16}>
            <Header as="h4">
              General item Infomation &nbsp;
              <Popup
                trigger={
                  <Icon
                    name="info circle"
                    className="orange-color discount-css"
                  />
                }
              >
                <div>
                  All General Item Information will not be displayed in the
                  receipt / reservation confirmation email. To display any of
                  this information in the receipt / confirmation email, please
                  create a custom field with the same information and check the
                  “confirmation email” box. Do not check the “website” box or
                  you will have two of the same displayed online. If the custom
                  field is unique and you want to display on your website, you
                  can check the “website” box.
                </div>
              </Popup>
            </Header>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={4}>
            <Form>
              <Form.Input
                placeholder="Item Name"
                fluid
                data="productName"
                value={fullState.addItemGeneral.productName}
                error={allFunction.validatorGeneral.message(
                  "itemName",
                  fullState.addItemGeneral.productName,
                  "required|min:0|max:100"
                )}
                onChange={allFunction.onHandleChangeGeneral}
              />
            </Form>
          </Grid.Column>

          <Grid.Column mobile={16} tablet={8} computer={4}>
            <Form>
              <Form.Input
                placeholder="Item Quantity"
                value={fullState.addItemGeneral.productQuantity}
                error={allFunction.validatorGeneral.message(
                  "itemQuantity",
                  fullState.addItemGeneral.productQuantity,
                  "required|integer|min:0,num|numeric|max:2000,num"
                )}
                data="productQuantity"
                fluid
                onChange={allFunction.onHandleChangeGeneral}
                disabled={
                  fullState.addItemGeneral.productId !== undefined
                    ? true
                    : false
                }
              />
            </Form>
          </Grid.Column>
          <Grid.Column
            mobile={16}
            tablet={8}
            computer={4}
            className="controlField"
          >
            <TreeSelect
              showSearch
              filterTreeNode={(search, item) => {
                return (
                  item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0
                );
              }}
              treeDefaultExpandedKeys={fullState.expandthisnode}
              value={!api.isApiLoading && fullState.addItemGeneral.categoryId}
              treeData={fullState.allCategoryList}
              placeholder="Choose Inventory Location"
              onChange={allFunction.onDropDownTreeChangeRental}
            />
            {allFunction.validatorGeneral.message(
              "inventoryLocation",
              fullState.addItemGeneral.categoryId,
              "required"
            )}
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={4}>
            <Form>
              <Form.Input
                placeholder="Stock ID Prefix"
                fluid
                value={fullState.addItemGeneral.productPrefix}
                error={allFunction.validatorGeneral.message(
                  "stockIdPrefix",
                  fullState.addItemGeneral.productPrefix,
                  "required|min:0|max:50"
                )}
                onChange={allFunction.onHandleChangeGeneral}
                data="productPrefix"
              />
            </Form>
          </Grid.Column>

          <Grid.Column mobile={16} tablet={8} computer={8}>
            <Form>
              <CommonChooseTags
                fullState={fullState}
                allFunction={allFunction}
                stateForm="addItemGeneral"
                variableName="productTags"
              />
            </Form>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <Form>
              <Form.TextArea
                rows={2}
                placeholder="Pick Up Instructions"
                value={fullState.addItemGeneral.pickUpInstruction}
                type="textarea"
                data="pickUpInstruction"
                onChange={allFunction.onHandleChangeGeneral}
                error={allFunction.validatorGeneral.message(
                  "pickUpInstruction",
                  fullState.addItemGeneral.pickUpInstruction,
                  "min:0|max:2000"
                )}
              />
            </Form>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <Form>
              <Form.TextArea
                rows={2}
                placeholder="Drop Off Instructions"
                value={fullState.addItemGeneral.dropOffInstruction}
                type="textarea"
                data="dropOffInstruction"
                onChange={allFunction.onHandleChangeGeneral}
                error={allFunction.validatorGeneral.message(
                  "dropOffInstruction",
                  fullState.addItemGeneral.dropOffInstruction,
                  "min:0|max:2000"
                )}
              />
            </Form>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <Form>
              <Form.TextArea
                rows={2}
                placeholder="What to know?"
                type="textarea"
                value={fullState.addItemGeneral.whattoKnow}
                data="whattoKnow"
                onChange={allFunction.onHandleChangeGeneral}
                error={allFunction.validatorGeneral.message(
                  "whatToKnow",
                  fullState.addItemGeneral.whattoKnow,
                  "min:0|max:2000"
                )}
              />
            </Form>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <Form>
              <Form.TextArea
                rows={2}
                placeholder="What is included?"
                type="textarea"
                value={fullState.addItemGeneral.whatIsIncluded}
                data="whatIsIncluded"
                onChange={allFunction.onHandleChangeGeneral}
                error={allFunction.validatorGeneral.message(
                  "whatIsIncluded",
                  fullState.addItemGeneral.whatIsIncluded,
                  "min:0|max:2000"
                )}
              />
            </Form>
          </Grid.Column>

          <Grid.Column mobile={16} tablet={8} computer={8}>
            <Form>
              <Form.TextArea
                rows={2}
                placeholder="Item Description"
                type="textarea"
                value={fullState.addItemGeneral.itemDescription}
                data="itemDescription"
                onChange={allFunction.onHandleChangeGeneral}
                error={allFunction.validatorGeneral.message(
                  "itemDescription",
                  fullState.addItemGeneral.itemDescription,
                  "min:0|max:2000"
                )}
              />
            </Form>
          </Grid.Column>
          <CommonChooseColor
            fullState={this.props.fullState}
            allFunction={this.props.allFunction}
            stateForm="addItemGeneral"
            variableName="color"
          />
          <CommonMinTimeDuration
            fullState={fullState}
            allFunction={allFunction}
            stateForm="addItemGeneral"
            validator={allFunction.validatorGeneral}
          />
          <CommonCustomField
            fullState={this.props.fullState}
            allFunction={this.props.allFunction}
            stateForm="addItemGeneral"
            variableName="customField"
          />

          <Grid.Column
            mobile={4}
            tablet={8}
            computer={3}
            verticalAlign="middle"
          >
            <Form.Checkbox
              label=" Website "
              className="custome-checkbox"
              data="webWidget"
              checked={fullState.addItemGeneral.webWidget ? true : false}
              onChange={allFunction.onHandleChangeGeneral}
              value={fullState.addItemGeneral.webWidget}
            />
          </Grid.Column>
          <Grid.Column
            mobile={12}
            tablet={8}
            computer={4}
            verticalAlign="middle"
          >
            <Form.Checkbox
              label="In Confirmation Email"
              className="custome-checkbox"
              value={fullState.addItemGeneral.inConfirmationEmail}
              data="inConfirmationEmail"
              checked={
                fullState.addItemGeneral.inConfirmationEmail ? true : false
              }
              onChange={allFunction.onHandleChangeGeneral}
            />
          </Grid.Column>
          <CommonDocument
            isEdited={this.props.isEdited}
            fullState={this.props.fullState}
            allFunction={this.props.allFunction}
            stateForm="addItemGeneral"
            variableName="documents"
            getSelectedDocument={this.setSelectedDocument}
          />
          <CommonRequirement
            isEdited={this.props.isEdited}
            fullState={this.props.fullState}
            allFunction={this.props.allFunction}
            stateForm="addItemGeneral"
            variableName="requirements"
          />
          <CommonTraits
            fullState={this.props.fullState}
            allFunction={this.props.allFunction}
            stateForm="addItemGeneral"
            variableName="traits"
          />
          <CommonAvailableOn
            fullState={this.props.fullState}
            allFunction={this.props.allFunction}
            stateForm="addItemGeneral"
          />
          <CommonCustomerQuestions
            fullState={this.props.fullState}
            allFunction={this.props.allFunction}
            stateForm="addItemGeneral"
            variableName="productCustomQuestion"
          />
          <Grid>
            <Grid.Column width={16}>
              <Divider />
            </Grid.Column>
            <Grid.Column width={16}>
              <Header as="h4">Restock Time</Header>
            </Grid.Column>
            <Grid.Column width={8}>
              <Grid>
                <Grid.Column width={8} verticalAlign="middle">
                  <p>Before</p>
                </Grid.Column>
                <Grid.Column width={8} verticalAlign="middle">
                  <Form.Checkbox
                    label={{ children: "Active" }}
                    className="custom-toggle"
                    value={fullState.addItemGeneral.beforeTimeDisable}
                    data="beforeTimeDisable"
                    checked={
                      fullState.addItemGeneral.beforeTimeDisable ? true : false
                    }
                    onChange={allFunction.onHandleChangeGeneral}
                  />
                </Grid.Column>
                <Grid.Column width={8}>
                  <Form.Field className="add-numbers">
                    <Icon
                      name="minus"
                      className="orange-button"
                      data="beforeTime"
                      disabled={
                        fullState.addItemGeneral.beforeTimeDisable
                          ? false
                          : true
                      }
                      value={fullState.addItemGeneral.beforeTime}
                      onClick={allFunction.incrementDecrement}
                    ></Icon>
                    <Form.Input
                      defaultValue="1"
                      fluid
                      value={fullState.addItemGeneral.beforeTime}
                      disabled={
                        fullState.addItemGeneral.beforeTimeDisable
                          ? false
                          : true
                      }
                    />
                    <Icon
                      name="plus"
                      className="orange-button plus-btn"
                      data="beforeTime"
                      value={fullState.addItemGeneral.beforeTime}
                      onClick={allFunction.incrementDecrement}
                      disabled={
                        fullState.addItemGeneral.beforeTimeDisable
                          ? false
                          : true
                      }
                    ></Icon>
                  </Form.Field>
                </Grid.Column>
                <Grid.Column width={8}>
                  <GlobalCodeSelect
                    placeholder="Duration"
                    data="beforeTimeType"
                    onChange={allFunction.onHandleChangeGeneral}
                    categoryType="Duration"
                    value={fullState.addItemGeneral.beforeTimeType}
                    className="custom-select"
                    disabled={
                      fullState.addItemGeneral.beforeTimeDisable ? false : true
                    }
                  />
                </Grid.Column>
              </Grid>
            </Grid.Column>
            <Grid.Column width={8}>
              <Grid>
                <Grid.Column width={8} verticalAlign="middle">
                  <p>After</p>
                </Grid.Column>
                <Grid.Column
                  mobile={8}
                  tablet={8}
                  width={8}
                  verticalAlign="middle"
                >
                  <Form.Checkbox
                    label={{ children: "Active" }}
                    className="custom-toggle"
                    value={fullState.addItemGeneral.afterTimeDisable}
                    data="afterTimeDisable"
                    onChange={allFunction.onHandleChangeGeneral}
                    checked={
                      fullState.addItemGeneral.afterTimeDisable ? true : false
                    }
                  />
                </Grid.Column>
                <Grid.Column width={8}>
                  <Form.Field className="add-numbers">
                    <Icon
                      name="minus"
                      className="orange-button"
                      disabled={
                        fullState.addItemGeneral.afterTimeDisable ? false : true
                      }
                      data="afterTime"
                      value={fullState.addItemGeneral.afterTime}
                      onClick={allFunction.incrementDecrement}
                    ></Icon>
                    <Form.Input
                      defaultValue="1"
                      fluid
                      value={fullState.addItemGeneral.afterTime}
                      disabled={
                        fullState.addItemGeneral.afterTimeDisable ? false : true
                      }
                    />
                    <Icon
                      name="plus"
                      className="orange-button plus-btn"
                      disabled={
                        fullState.addItemGeneral.afterTimeDisable ? false : true
                      }
                      data="afterTime"
                      value={fullState.addItemGeneral.afterTime}
                      onClick={allFunction.incrementDecrement}
                    ></Icon>
                  </Form.Field>
                </Grid.Column>
                <Grid.Column width={8}>
                  <GlobalCodeSelect
                    placeholder="Duration"
                    data="afterTimeType"
                    onChange={allFunction.onHandleChangeGeneral}
                    categoryType="Duration"
                    value={fullState.addItemGeneral.afterTimeType}
                    className="custom-select"
                    disabled={
                      fullState.addItemGeneral.afterTimeDisable ? false : true
                    }
                  />
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid>

          <Grid.Column width={16}>
            <Divider />
          </Grid.Column>
          <CommonAvailability
            fullState={this.props.fullState}
            allFunction={this.props.allFunction}
            stateForm="addItemGeneral"
            variableName="productAvailability"
            itemName={"availability"}
            validator={allFunction.validatorGeneral}
          />
          <CommonBlackOutDates
            fullState={this.props.fullState}
            allFunction={this.props.allFunction}
            stateForm="addItemGeneral"
            variableName="productBlackOutDates"
            commonBlackOut={true}
          />
          <CommonCancellationPolicy
            fullState={this.props.fullState}
            allFunction={this.props.allFunction}
            stateForm="addItemGeneral"
          />
          {/* <Grid.Column width={16}>
            <Divider />
          </Grid.Column>
          <Grid.Column width={16}>
            <Header as="h4">Delivery</Header>
          </Grid.Column>
          <Grid.Column width={16} textAlign="left">
            <Form>
              <Form.Group inline>
                <span>Delivery Option &nbsp;</span>
                <Form.Checkbox
                  label={
                    <label>
                      <span className="disable">No</span>
                      <span className="enable">Yes</span>
                    </label>
                  }
                  onChange={allFunction.onHandleChangeGeneral} data="deliveryOption" checked={fullState.addItemGeneral.deliveryOption ? true : false} value={fullState.addItemGeneral.deliveryOption} className="custom-toggle" />
              </Form.Group>
              <Divider hidden />
              <Form.Group inline>
                <span> Allow Customers to enter delivery address? &nbsp;</span>
                <Form.Checkbox
                  label={
                    <label>
                      <span className="disable">No</span>
                      <span className="enable">Yes</span>
                    </label>
                  }
                  onChange={allFunction.onHandleChangeGeneral} data="allowCustomerToEnterDeliveryAddress" checked={fullState.addItemGeneral.allowCustomerToEnterDeliveryAddress ? true : false} value={fullState.addItemGeneral.allowCustomerToEnterDeliveryAddress} className="custom-toggle" />
              </Form.Group>
            </Form>
            <Grid.Column width={16}>
              <Divider hidden />
            </Grid.Column>
            <CommonDeliveryCustomField fullState={this.props.fullState} allFunction={this.props.allFunction}
              stateForm="addItemGeneral" variableName="deliveryCustomField" />
          </Grid.Column> */}
          <Grid.Column width={16}>
            <Divider />
          </Grid.Column>
          {/*<Grid.Column mobile={16} tablet={2} computer={16}>
            <div className="bookedEmail">
              <EmailNotification
                fullState={fullState}
                stateForm="addItemGeneral"
                allFunction={allFunction} />
            </div>
          </Grid.Column>*/}

          {/* <Grid.Column width={16}>
            <Divider />
          </Grid.Column> */}
          <CommonAddOns
            fullState={this.props.fullState}
            allFunction={this.props.allFunction}
            stateForm="addItemGeneral"
          />
          {/*<CommonPhysicalLocation fullState={this.props.fullState} allFunction={this.props.allFunction} stateForm="addItemGeneral" /> */}
          <Grid.Column width={16} textAlign="right">
            {!allFunction.props.editItemModal ? (
              <Button
                className="blue-btn"
                onClick={() => allFunction.onNext(0)}
              >
                Back
              </Button>
            ) : null}
            <Button
              className="orange-btn"
              onClick={() => allFunction.changeStep(2)}
              loading={api.isApiLoading}
            >
              Continue
            </Button>
            {/* <Button className="orange-btn" onClick={() => allFunction.changeNext(2)} loading={api.isApiLoading}>Continue</Button> */}
          </Grid.Column>
        </Grid>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddItemGeneral);
