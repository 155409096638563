import React from "react";
import { Input, Modal, Grid, Button, Checkbox } from "semantic-ui-react";
import { commonFunctions } from "../../../../../../functional/global-import";

const CreateNewDiscountCouponModal = ({
    openModal,
    closeModal,
    onHandleChange,
    showAmount,
    discountAndCoupon,
    onTitleChange,
    showPercent,
    validator,
    handleChangeToggle,
    handleNewDiscountCouponSubmit,
    discountCouponLoader
}) => {

    return (
        <Modal open={openModal} closeIcon onClose={closeModal} size={"mini"}>
            <Modal.Content scrolling>
                {discountCouponLoader && commonFunctions.showLoader()}
                <Grid>
                    <Grid.Column width={16}>
                        <div class="field">
                            <label>Discount Code</label>
                            <Input
                                fluid
                                textAlign="left"
                                name="txtTitle"
                                placeholder="Title"
                                maxLength="50"
                                error={validator.message(
                                    "title",
                                    discountAndCoupon.title,
                                    "required"
                                )}
                                data="title"
                                value={discountAndCoupon.title}
                                onChange={onTitleChange}
                            />
                            <div
                                className="amount-percentage discount-new-order"
                                style={{ "padding-top": "15px" }}
                            >
                                <p>Amount </p>
                                <Checkbox
                                    className="custom-toggle"
                                    name="custom-toggle"
                                    label="Percentage"
                                    onChange={(e, { name, checked, data }) =>
                                        handleChangeToggle(e, { name, checked, data })
                                    }
                                />
                            </div>
                            {showAmount ?
                                <div class="field" style={{ paddingTop: "10px" }}>
                                    <label>Amount</label>
                                    <Input
                                        label={{ basic: true, content: "$" }}
                                        labelPosition="left"
                                        textAlign="left"
                                        name="txtValue"
                                        type="number"
                                        placeholder="Discount Value"
                                        maxLength="50"
                                        error={validator.message("Value", discountAndCoupon.value, "required|num|min:0,num")}
                                        value={discountAndCoupon.value}
                                        data="value"
                                        onChange={(e, { name, value, type, checked, data }) =>
                                            onHandleChange(e, { name, value, type, checked, data })
                                        }
                                        fluid
                                    />
                                </div>

                                :
                                // {/* {showPercent && ( */ }

                                <div class="field" style={{ paddingTop: "10px" }}>
                                    <label>Percentage</label>
                                    <Input
                                        textAlign="left"
                                        name="txtValue"
                                        type="number"
                                        placeholder="Discount Value"
                                        maxLength="50"
                                        error={validator.message("Value", discountAndCoupon.value, "required|numeric|min:0,num|max:100,num")}
                                        value={discountAndCoupon.value}
                                        data="value"
                                        onChange={(e, { name, value, type, checked, data }) =>
                                            onHandleChange(e, { name, value, type, checked, data })
                                        }
                                        fluid
                                    />
                                </div>
                            }

                        </div>
                    </Grid.Column>
                    <Grid.Column width={16} textAlign="right">
                        <Button className="blue-btn" onClick={closeModal}>
                            {" "}
                            Cancel{" "}
                        </Button>
                        <Button className="orange-btn" onClick={(e) => handleNewDiscountCouponSubmit(e)}> Create </Button>
                    </Grid.Column>
                </Grid>
            </Modal.Content>
        </Modal>
    );
};

export default CreateNewDiscountCouponModal;
