// inspired by https://leanpub.com/redux-book
import axios from "axios";
import * as types from "../types";
import * as actions from "../actions";
import * as env from "../../config/env.config";
import { getApiUrl } from "../../config/api.config";
import { Notifications } from "../../shared/functional/global-import";
import { commonFunctions } from "../../shared/functional/common-functions";

// import { error } from "react-notification-system-redux";
var qs = require("qs");

const onCateringError = async (
  error,
  dispatch,
  showNotification,
  onSuccess
) => {
  let APIErrorResut = axios(error.config);
  let result = await APIErrorResut;
  APIErrorResut.then((response) => {
    if (showNotification) {
      dispatch(
        Notifications.success({
          title: "Success",
          message: response.data.responseMessage,
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        })
      );
    }
    onSuccess(response.data);
  }).catch((error) => {
    dispatch(actions.accessDenied(window.location.pathname));
  });
};

const successAPIMessage = (response, dispatch) => {
  dispatch(
    Notifications.success({
      title: "Success",
      message: response.data.responseMessage,
      position: "br",
      autoDismiss: commonFunctions.notificationTime(),
    })
  );
};

const checkLogInAccess = (response, dispatch) => {
  let singleModuleName = JSON.parse(response.data.roleAndPermission)?.filter(
    (item) => {
      return item.ModuleName === "Login";
    }
  );
  if (singleModuleName[0].Access === 1) {
    successAPIMessage(response, dispatch);
  } else {
    dispatch(
      Notifications.error({
        title: "Security",
        message: "Login permission not available for role assigned to you ",
        position: "br",
        autoDismiss: commonFunctions.notificationTime(),
      })
    );
  }
};
const apiMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    if (action.type !== types.API_CALL) return;
    let state = getState();

    const {
      urls,
      method,
      data,
      onSuccess,
      onFailure,
      onFinally,
      headers,
      showNotification,
      isFormData,
    } = action.payload;

    //permision based action---------------------
    const oldUrlScreenNames = window.location.href.split("/");
    const oldUrlScreenName = oldUrlScreenNames[oldUrlScreenNames.length - 1];
    let moduleName = state.auth.userDetail?.roleAndPermission?.filter(
      (item) => {
        return item.Access === 1 && item.permission;
      }
    );
    let allowedScreens = [];
    moduleName?.map((item) => {
      item.permission.map((item2) => {
        if (item2.Access) {
          allowedScreens.push(item2);
        } else {
          return { ...item2 };
        }
        return { ...item2 };
      });
      return { ...item };
    });

    let allowedAction = [];
    allowedScreens.map((item3) => {
      item3.action?.map((item4) => {
        if (
          oldUrlScreenName?.toLowerCase() === item3.ScreenName?.toLowerCase()
        ) {
          allowedAction.push(item4);
          return { ...item4 };
        } else {
          return { ...item4 };
        }
      });
      return { ...item3 };
    });
    let permissionAction;
    if (method === "POST") permissionAction = "Add";
    else if (method === "PUT") permissionAction = "Edit";
    else if (method === "DELETE") permissionAction = "Delete";
    if (
      method !== "GET" &&
      allowedAction.filter(
        (item) => item.Access === 0 && item.ActionName === permissionAction
      ).length > 0
    ) {
      dispatch(
        Notifications.error({
          title: "Security",
          message: "Action is not accessible ",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        })
      );
      return false;
    }

    //    ==========
    // axios default configs
    axios.defaults.headers.common["Content-Type"] = isFormData
      ? "multipart/form-data"
      : "application/json";
    if (state.auth && state.auth.loggedIn && state.auth.loggedIn.token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${
        localStorage.token || state.auth.loggedIn.token
      }`;
    }
    const dataOrParams = ["GET", "DELETE"].includes(method) ? "params" : "data";

    // Application level loading start
    dispatch(actions.apiStart());

    let APIResut;
    // checking is it multiple api call
    if (urls && urls.length > 1) {
      // Call multiple API call using axios via Promise.all
      const fetchURL = (url) => axios.get(getApiUrl(url));
      const promiseArray = urls.map(fetchURL);
      APIResut = Promise.all(promiseArray);
    } else {
      // Api Call for single api
      APIResut = axios.request({
        url: getApiUrl(urls[0]),
        method,
        headers,
        [dataOrParams]: data,
        paramsSerializer: function (params) {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      });
    }

    APIResut.then((response) => {
      // Show notification
      // response.config.url.indexOf("/Auth/Login")> -1)
      if (showNotification) {
        if (response.config.url.indexOf("ForgotPasscode") > -1) {
          successAPIMessage(response, dispatch);
        } else if (response.config.url.indexOf("Auth/Passcode") > -1) {
          checkLogInAccess(response, dispatch);
        }
        //  else if (response.config.url.indexOf("Passcode") > -1) {
        //         checkLogInAccess(response, dispatch)
        //     }
        else if (
          response.config.url.indexOf("/Auth/Login") > -1 &&
          response.data.login === "EmployeeLogin"
        ) {
          checkLogInAccess(response, dispatch);
        } else {
          successAPIMessage(response, dispatch);
        }
      }
      // callback function
      onSuccess(response.data);
      // console sucess if environment is not in production
      if (env.NODE_ENV !== "production") {
        // if (response.length > 0) {
        //     response.map((response, index) => {
        //         return console.log('API : ' + urls[index] + ' Success response :' + JSON.stringify(response));
        //     });
        // } else {
        //     return console.log('API : ' + urls[0] + ' Success response :' + JSON.stringify(response));
        // }
      }
    })
      .catch((error) => {
        //   new code
        const { response } = error;
        if (response) {
          const { status } = response;
          if (status === 401) {
            dispatch(
              actions.apiCall({
                urls: ["REFRESHTOKEN"],
                method: "Post",
                data: {
                  userId: state.auth.userDetail
                    ? state.auth.userDetail.keyId
                    : state.auth.loggedIn.keyId,
                  refreshToken: state.auth.loggedIn.refreshToken,
                  emailId: state.auth.userDetail.emailId,
                },
                onSuccess: (response) => {
                  const { loggedIn } = state.auth;
                  loggedIn.token = response.token;
                  dispatch(actions.loginSuccess({ ...loggedIn }));
                  error.config.headers.Authorization = `Bearer    ${response.token}`;
                  onCateringError(error, dispatch, showNotification, onSuccess);
                },
              })
            );
          } else if (error.response && showNotification) {
            dispatch(
              Notifications.error({
                title: "Error",
                message: error.response.data.responseMessage,
                position: "br",
                autoDismiss: commonFunctions.notificationTime(),
              })
            );
          }
        }

        // if (response && response.status === 401) {
        //     const { status, data } = response;
        //     // place your reentry code
        //     // if (status === 401) {

        //     // alert("token expire now ")
        //     //

        //     dispatch(actions.apiCall({
        //         urls: ["REFRESHTOKEN"], method: "Post", data: {
        //             userId: state.auth.userDetail.keyId,
        //             refreshToken: state.auth.loggedIn.refreshToken,
        //             emailId: state.auth.userDetail.emailId,
        //         }, onSuccess: (response) => {
        //             const { loggedIn } = state.auth;
        //             loggedIn.token = response.token
        //             dispatch(actions.loginSuccess({ ...loggedIn }));
        //             return axios(error.config);
        //         },
        //     }))
        //     // }
        // }

        //   new code
        else {
          // Show notification
          if (error.response && showNotification) {
            dispatch(
              Notifications.error({
                title: "Error",
                message: error.response.data.responseMessage,
                position: "br",
                autoDismiss: commonFunctions.notificationTime(),
              })
            );
          }
          // Application level error handling
          dispatch(actions.apiError(error));
          // callback function
          onFailure(error);

          // console error if environment is not in production
          // if (env.NODE_ENV !== "production") {
          //     console.log('API : Error response :' + JSON.stringify(error));
          // }
          // if (error.response && error.response.status === 403) {
          //     dispatch(actions.accessDenied(window.location.pathname));
          // }
        }
      })
      .finally(() => {
        // Application level loading end
        dispatch(actions.apiEnd());
        onFinally();
      });
  };

export default apiMiddleware;
