import React, { Component } from "react";
import { Grid, Form, Divider, Header, Button } from "semantic-ui-react";
import AddDocumentModal from "../../organisms/modal/add-documents-modal";
import {
  commonFunctions,
  connect,
  bindActionCreators,
  actions,
  Notifications,
} from "../../../functional/global-import";

class CommonDocument extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documentList: [],
      documentStatus: false,
      docList: [],
      selectedDoc: [],
      dropDownEditValueSet: false,
      allDocuments: [],
      selectedOptionsProps: [],
    };
  }

  appendDocsOptionsIfNotExists = () => {
    const { selectedOptionsProps } = this.state;
    this.setState((prevState) => {
      let otherItems = [];
      selectedOptionsProps.forEach((selected) => {
        var hasItem = false;
        prevState.documentList.forEach((document) => {
          if (document.id === selected.id) {
            hasItem = true;
          }
        });
        if (!hasItem) {
          otherItems.push(selected);
        }
      });
      let allOptions = new Set([...prevState.documentList, ...otherItems]);
      return (prevState.documentList = Array.from(allOptions));
    });
  };

  getDocumentsList = () => {
    const { allFunction, stateForm } = this.props;
    this.props.actions.apiCall({
      urls: ["GETDOUCUMENTBYBUSINESSTYPE"],
      method: "GET",
      data: {
        businessCategoryType: allFunction.state[stateForm].businessCategoryType,
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        documentId: -1,
        PageNo: 1,
        PageSize: 100,
        isActive: true,
      },
      onSuccess: (response) => {
        var documentList = [];
        // Extracting Selected documents by id
        documentList = response.map((singleDocument) => {
          if (singleDocument.documentId) {
            return {
              id: singleDocument.documentId,
              value: singleDocument.documentId,
              text: singleDocument.documentName,
            };
          }
        });
        // Documents list dropdown options
        this.setState(
          {
            documentList,
            docList: [],
          },
          () => {
            this.appendDocsOptionsIfNotExists();
          }
        );
      },
    });
  };

  componentDidUpdate(prevProps, prevState) {
      
    var self = this;
    const { fullState, stateForm, variableName } = this.props;

    let selectedOptionsProps = fullState[stateForm][variableName] || [];
    if (
      prevProps.fullState[stateForm][variableName] !==
      this.props.fullState[stateForm][variableName]
    ) {
      selectedOptionsProps = selectedOptionsProps.map((item) => {
        return {
          id: item.documentId,
          value: item.documentId,
          text: item.documentName,
        };
      });
      this.setState({ selectedOptionsProps }, () => {
        this.appendDocsOptionsIfNotExists();
      });
    }
    if (
      this.props.fullState[stateForm][variableName] !== this.state.docList &&
      this.state.documentList &&
      this.state.documentList.length > 0 &&
      fullState.addOrEdit !== "add" &&
      this.state.dropDownEditValueSet === false
    ) {
      if (
        fullState[stateForm][variableName] &&
        fullState[stateForm][variableName].length > 0
      ) {
        this.setState({ docList: fullState[stateForm][variableName] });
      }
    }
    if (
      this.props.isEdited &&
      fullState[stateForm][variableName] !== this.state.selectedDoc &&
      fullState[stateForm][variableName].length > 0 &&
      !this.state.dropDownEditValueSet
    ) {
      let editDropdown = fullState[stateForm][variableName].map((singleObj) => {
        return singleObj.documentId;
      });
      this.setState({ selectedDoc: editDropdown, dropDownEditValueSet: true });
    }
  }

  documentModal = () => {
    this.setState({ documentStatus: !this.state.documentStatus });
    this.getDocumentsList();
  };
  componentDidMount() {
    this.getDocumentsList();
  }

  onHandleChange = (e, { name, value, type, checked, data, id }) => {
    const { fullState, stateForm, allFunction } = this.props;
    const { documentList } = this.state;
    const selectedDocuments = [];
    // Get all Selected documents from list of documents
    documentList.forEach((obj) => {
      value.forEach((singleObj) => {
        if (obj.id === singleObj) {
          selectedDocuments.push({
            documentId: obj.id,
            documentName: obj.text,
          });
        }
      });
    });

    fullState[stateForm][data] = selectedDocuments;

    commonFunctions.onFormChange(allFunction);
    // var addDocument = commonFunctions.onHandleChange(e, { name, value, type, checked, data }, fullState[stateForm], allFunction);
    // fullState[stateForm] = addDocument;
    allFunction.setState({ ...fullState });
    this.setState({ selectedDoc: value });
  };

  render() {
    const { variableName } = this.props;
    const { selectedDoc, documentList } = this.state;
    return (
      <React.Fragment>
        <Grid.Column width={16}>
          <Divider />
        </Grid.Column>
        <Grid.Column width={16}>
          <Header as="h4">Documents</Header>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={8} computer={8}>
          <Form.Dropdown
            placeholder="Documents"
            options={documentList}
            value={selectedDoc}
            data={variableName}
            type="multiple-select"
            className="custom-select"
            search
            selection
            multiple
            fluid
            onChange={this.onHandleChange}
          />
        </Grid.Column>
        <Grid.Column mobile={16} tablet={8} computer={8}>
          <Button className="orange-btn" onClick={this.documentModal}>
            Add New Document
          </Button>
        </Grid.Column>
        {this.state.documentStatus && (
          <AddDocumentModal
            openModal={this.state.documentStatus}
            closeModal={this.documentModal}
            getDocumentsList={this.getDocumentsList}
          />
        )}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CommonDocument);
