import React, { Component } from "react";
import { Grid, Form, Button, Checkbox, } from "semantic-ui-react";
import { CommonDeleteUpdateModal } from "../../../shared/components";
import { connect, bindActionCreators, actions, withRouter, commonFunctions, Notifications, } from "../../../shared/functional/global-import";
import RequirementGrid from './requirement-grid';

class RequirementForm extends Component {
  child = React.createRef();
  get initialState() {
    return {
      requirementForm: {
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId: this.props.global.locationSelected.BusinessLocationId,
        requirementTitle: "",
        isActive: true,
        actionPerformedBy: this.props.auth.userDetail.emailId,
      },
      displayButton: "Save",
      isRequirementFormLoading: false
    };
  }
  constructor(props) {
    super(props);
    this.state = { ...this.initialState, gridDetail: [], isCommonDeleteUpdateModalOpen: false, requirementId: "", };
    this.validator = commonFunctions.initializeSimpleValidator();
  }
  onHandleChange = (e, { name, value, type, checked, data }) => {
    var requirementForm = commonFunctions.onHandleChange(e, { name, value, type, checked, data }, this.state.requirementForm, this);
    this.setState({ requirementForm });
  };
  editRequirement = (requirement) => {
    this.state.requirementForm = requirement
    this.setState({ ...this.state, requirementForm: { ...this.state.requirementForm, actionPerformedBy: this.props.auth.userDetail.emailId }, displayButton: "Update" });
  }

  render() {
    const {
      requirementForm,
      isCommonDeleteUpdateModalOpen,
      messageToShow,
      buttonName,
      isRequirementFormLoading,
      displayButton,
    } = this.state;
    const fullState = this.state;
    const allFunction = this;
    return (
      <React.Fragment>
        <div className="cmn-shad" id="form_section">
          <Grid>

            <Grid.Column width={8}>
              <Form.Field>
                <Form.Input
                  placeholder="Title"
                  id="title"
                  value={requirementForm.requirementTitle}
                  name="txtrequirementTitle"
                  data="requirementTitle"
                  onChange={this.onHandleChange}
                  error={this.validator.message(
                    "requirementTitle",
                    requirementForm.requirementTitle,
                    "required|max:200"
                  )}
                  fluid
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column width={8} verticalAlign="middle" textAlign="center">
              {!this.props.hideToggleForModal && <Checkbox
                label="Active"
                data="isActive"
                name="chkisActive"
                checked={requirementForm.isActive}
                value={requirementForm.isActive}
                onChange={this.onHandleChange}
                className="custom-toggle"
              />}
            </Grid.Column>
          </Grid>
          <Grid reversed='computer tablet'>
            <Grid.Column mobile={8} tablet={8} computer={8} textAlign="right">
              <Button className="blue-btn" onClick={() => commonFunctions.onCancelInitialState(allFunction)}>Clear</Button>
              <Button className="orange-btn" loading={isRequirementFormLoading}
                onClick={(e) => commonFunctions.onHandleSubmit(e, allFunction, "isRequirementFormLoading", "requirementForm",
                  "ADDREQUIREMENT", "UPDATEREQUIREMENTS")}
              >
                {displayButton}
              </Button>
            </Grid.Column>
            {this.props.showGrid && <RequirementGrid ref={this.child} fullState={fullState} allFunction={allFunction} />}
          </Grid>
        </div>
        <CommonDeleteUpdateModal
          isModalOpen={isCommonDeleteUpdateModalOpen}
          onConfirm={() => commonFunctions.onConfirmFunction(allFunction, "requirementId", "gridDetail", "DELETEREQUIREMENTS", "UPDATEREQUIREMENTTOGGLE")}
          onClose={() => commonFunctions.onCloseDeleteUpdateModal(allFunction, "requirementId")}
          confirmationButtonText={buttonName}
          header={messageToShow}
          cancelButtonText={'Cancel'}
        />

      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
    warnUserState: state.warnUserState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
      storeWarnUser: bindActionCreators(actions.storeWarnUser, dispatch),
    },
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RequirementForm)
);
