import React, { Component } from "react";
import DigitalSignature from "../../../../molecules/digital-signature";
import { Grid, Card, Input } from "semantic-ui-react";

class SignatureDocument extends Component {
  child = React.createRef();
  state = {
    signatureDocument: {
      candidateName: "",
      signatureImage: this.props.documentDetails.signatureImage,
    },
  };
  clear = async () => {
    await this.handleEmpty();
    this.child.current.clear();
  };

  handleChange = (e, { name, value }) => {
    const { signatureDocument } = this.state;
    signatureDocument["signatureImage"] = "";
    this.setState({
      signatureDocument: {
        ...signatureDocument,
        [name]: "",
        signatureImage: "",
      },
    });
  };

  handleGetSignBase64 = (signBase64) => {
    this.setState({
      signatureDocument: {
        ...this.state.signatureDocument,
        signBase64: signBase64,
      },
    });
    return this.child.current.handleSign();
  };

  handleEmpty = () => {
    this.setState({
      signatureDocument: {
        ...this.state.signatureDocument,
        candidateName: "",
        signatureImage: "",
      },
    });
  };

  render() {
    const { documentDetails } = this.props;
    return (
      <Grid.Column width={16}>
        <Card fluid className="signature-box">
          <Card.Content>
            <Input
              value={documentDetails.candidateName}
              name="candidateName"
              placeholder="Type Full Name"
              fluid
              onChange={(e, { name, value }) =>
                this.props.handleChange(e, { name, value })
              }
            />
          </Card.Content>
          <Card.Content>
            <DigitalSignature
              ref={this.child}
              getSignBase64={this.getSignBase64}
              height={300}
              signatureImage={documentDetails.signatureImage}
            />
          </Card.Content>
        </Card>
      </Grid.Column>
    );
  }
}
export default SignatureDocument;
