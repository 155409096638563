import React, { Component } from "react";
import { Grid, Breadcrumb } from "semantic-ui-react";
import {
  connect,
  bindActionCreators,
  actions,
  env,
} from "../../shared/functional/global-import";
import { withRouter } from "react-router-dom";

class InventoryBreadcrumb extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleBreadCrumClick = (breadcrumb, index) => {
    let inventoryDetail = this.props.global.inventoryDetail;

    if (inventoryDetail.inventoryBreadcrumb.length - 1 === index) {
      return false;
    }
    if (breadcrumb.type === "ByType") {
      inventoryDetail.categoryId = 0;
      inventoryDetail.productId = 0;
      inventoryDetail.parentId = 0;
      inventoryDetail.businessCategoryId = 0;
      inventoryDetail.inventoryBreadcrumb = [];
      if (this.props.onBytypeClick) {
        this.props.onBytypeClick();
      } else if (this.props.onShowRentalDate) {
        this.props.onShowRentalDate();
      } else {
        this.props.history.push(
          `${process.env.REACT_APP_PUBLIC_URL}/inventory`
        );
      }
      this.props.actions.storeInventoryDetail(inventoryDetail);
    } else {
      if (breadcrumb.type === "BusinessCategoryType") {
        inventoryDetail.categoryId = null;
        inventoryDetail.productId = 0;
        inventoryDetail.parentId = null;
        if (this.props.globalSearch) {
          this.props.globalSearch.categoryId = null;
          // this.setState({ParentId:this.props.globalSearch.ParentId})
        }
      } else if (
        breadcrumb.type === "Subcategory" ||
        breadcrumb.type === "Category" ||
        breadcrumb.type.trim() === "C"
      ) {
        inventoryDetail.categoryId = breadcrumb.categoryId;
        inventoryDetail.parentId = breadcrumb.parentId;
        inventoryDetail.productId = 0;
      }
      inventoryDetail.inventoryBreadcrumb.length = index + 1;
      this.props.actions.storeInventoryDetail(inventoryDetail);
      if (window.location.pathname.indexOf("inventory-item-list") > 0) {
        this.props.history.push(`${env.PUBLIC_URL}/inventory/category-view`);
      } else if (this.props.newOrderBack) {
        this.props.newOrderBack();
      } else {
        this.props.resetGlobal();
        this.props.getProductCategoryDetail();
      }
    }
  };
  render() {
    const { global } = this.props;
    return (
      <Grid.Column width={"16"}>
        <Breadcrumb className="custom-breadcrumb">
          <Breadcrumb.Section
            link={false}
            onClick={() => this.handleBreadCrumClick({ type: "ByType" })}
          >
            By Type
          </Breadcrumb.Section>
          {global?.inventoryDetail?.inventoryBreadcrumb?.map(
            (breadcrumb, index) => {
              return (
                <>
                  <Breadcrumb.Divider icon="right angle" key={index} />
                  <Breadcrumb.Section
                    key={index}
                    link={false}
                    onClick={() => this.handleBreadCrumClick(breadcrumb, index)}
                  >
                    {breadcrumb?.breadcrumbName}
                  </Breadcrumb.Section>
                </>
              );
            }
          )}
        </Breadcrumb>
      </Grid.Column>
      // <></>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    global: state.global,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      storeInventoryDetail: bindActionCreators(
        actions.storeInventoryDetail,
        dispatch
      ),
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InventoryBreadcrumb));
