import React, { Component } from "react";
import { Button, Grid, Modal, Header, Form, Label } from "semantic-ui-react";
import { commonFunctions,  connect,bindActionCreators,actions, } from "../../../../../../functional/global-import";
class CustomCharges extends Component {
  constructor(props) {
    super(props);
    this.state = { customerCharge: { descriptions: "", price: null, orderChargeType: "CustomCharge" }, dataLoader: false };
    this.validator = commonFunctions.initializeSimpleValidator();
  }
  handleChange = (e, { name, value }) => {

    if (name === "price") {

      const newAmount = parseFloat(value);
      if (newAmount >= 0) {
        this.setState({ customerCharge: { ...this.state.customerCharge, [name]: newAmount } })
      }
      else {
        this.setState({ customerCharge: { ...this.state.customerCharge, [name]: null } })
      }
    }
    else {
      this.setState({ customerCharge: { ...this.state.customerCharge, [name]: value } })
    }
  }

  // This function is used to set Form Submit with POST api
  onHandleSubmit = (e) => {
    const { customerCharge } = this.state;
    var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
    if (isFormValid) {
      this.setState({ customerCharge: { ...customerCharge, descriptions: "", price: "" }, dataLoader: true });
      this.props.handlePostAPIForCustomChagre(customerCharge);
      this.props.actions.getOrderItems(this.props.orderState.currentOrder.orderId);
    }
    else {
      return false
    }

  }


  render() {
    const { customerCharge, dataLoader } = this.state;
    return (
      <Modal closeOnDimmerClick={false} open={this.props.openModal} closeIcon onClose={this.props.closeModal} size={"mini"}>
        <Modal.Content>
          {dataLoader && commonFunctions.showLoader()}
          <Grid>
            <Grid.Column width={16}>
              <Header as="h2" className="orange-color"> Custom Charge </Header>
            </Grid.Column>
            <Grid.Column width={16}>
              <div class="field">
                <label>Description</label>
                <Form.Input fluid value={customerCharge.descriptions} onChange={this.handleChange}
                  name="descriptions"
                  error={this.validator.message("descriptions", customerCharge.descriptions, "required|alpha space|firstName")}
                />
              </div>
            </Grid.Column>
            <Grid.Column width={16}>
              <div class="field">
                <label>Amount</label>
                <Form.Input labelPosition='left' type='number' name="price" className="amountInput"
                  data="price"
                  error={this.validator.message("amount", customerCharge.price, "required|min:0,num")}
                  fluid
                  placeholder='Amount' value={customerCharge.price} onChange={this.handleChange}>
                  <Label basic>$</Label>
                  <input />
                </Form.Input>
              </div>
            </Grid.Column>
            <Grid.Column width={16} textAlign="center">
              <Button className="blue-btn" onClick={this.props.closeModal}> Cancel </Button>
              <Button className="orange-btn" loading={this.state.dataLoader}
                onClick={this.onHandleSubmit}
              > Save </Button>
            </Grid.Column>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    orderState: state.orderState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      getOrderItems: bindActionCreators(actions.getOrderItems, dispatch),
      updateOrderState: bindActionCreators(actions.updateOrderState, dispatch),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomCharges);
