import React, { Component } from "react";
import { Header, Modal, Grid, Select, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";

class RentalAvailabilityModal extends Component {
  render() {
    const {onHandleRentalInventory,rentalAvaibilityYes,rentalAvaibilityNo,isAddMoreCLicked,openModal,closeModal} = this.props;
    return (
      <Modal
        open={openModal}
        closeIcon
        onClose={closeModal}
        size={"mini"}
      >
        <Header content="Search with same availability" />
        <Modal.Content>
          <Grid textAlign="center">
          
            <Grid.Column width={16}>
              <Button className="blue-btn" onClick={(e)=>onHandleRentalInventory(e,false,rentalAvaibilityYes,rentalAvaibilityNo)} >
                Yes
              </Button>
              <Button className="orange-btn" onClick={(e)=>onHandleRentalInventory(e,isAddMoreCLicked,rentalAvaibilityNo,rentalAvaibilityYes)}>
                No
              </Button>

            </Grid.Column>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}

export default RentalAvailabilityModal;
