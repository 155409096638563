import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import { connect, bindActionCreators, actions } from '../../../functional/global-import';

class LocationsSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locationOption: []
    };
  }

  componentDidMount = () => {
    this.props.actions.apiCall({
      urls: ["GETBUSINESSLOCATION"], method: "GET", data: {
        BusinessId: this.props.global.bussinessDetail.businessId, BusinessLocationId: -1,
        pageNo: 1, pageSize: 100, inWebsiteWidget: 0
      },
      onSuccess: (response) => this.getResponse(response)
    })
  }

  getResponse = (response) => {
    let locationData = response.map((post) => {
      return {
        text: post.businessLocationName, value: post.businessLocationId,
        id: post.businessLocationId
      }
    });
    this.setState({ locationOption: locationData })
  }

  render() {
    const { locationOption } = this.state;
    return (
      <Form.Dropdown fluid
        multiple={this.props.multiSelect === undefined ? true : this.props.multiSelect}
        allowAdditions
        selection options={locationOption} placeholder={this.props.placeholder}
        name={this.props.name}
        data={this.props.data}
        type={this.props.type}
        value={this.props.value}
        error={this.props.error}
        onChange={this.props.onChange}
        defaultValue={this.props.defaultValue}
        disabled={this.props.disabled}
        className="tagger" />
    )
  }
}

LocationsSelect.propTypes = {
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  data: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  error: PropTypes.func
};


const mapStateToProps = state => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch)
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LocationsSelect);