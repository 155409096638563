import React from "react";
import { orderCommonFunctions } from "../order-common-function";
import moment from "moment";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { commonFunctions } from "../../../../../functional/common-functions";

// import React, { useEffect, useState } from "react";
// import { GlobalCodeSelect } from "../../../../../components";


function handleDateTimeFormat(date) {
  const timeConverted = moment(date, "MMM DD YYYY, hh:mm A").format(
    "MMM D, hh:mm A"
  );
  return timeConverted;
}

const PrintableComponent = () => {
  const orderState = useSelector((state) => state.orderState);

  const fullName = orderState.currentOrder.customerDetail.firstName + " " + (orderState.currentOrder.customerDetail.lastName === null ? " " : orderState.currentOrder.customerDetail.lastName)

  return (
    <>
      {orderState.currentOrder && (
        <>
          <table>
            <thead>
              <tr>
                <th colspan="2">Payment</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Created Date</td>
                <td>
                  <Moment format="MM/DD/YYYY">
                    {orderState.currentOrder.createdDate}
                  </Moment>
                </td>
              </tr>
              <tr>
                <td>Order Id</td>
                <td>{orderState.currentOrder.orderId}</td>
              </tr>
              <tr>
                <td>Order Status</td>
                <td>{orderState.currentOrder.orderStatus}</td>
              </tr>
              <tr>
                <td>Payment Status</td>
                <td>{orderState.currentOrder.paymentStatus}</td>
              </tr>

              {orderState.currentOrder?.selectedItemsWithAmount &&
                orderState.currentOrder?.selectedItemsWithAmount?.map(
                  (item, index) => {
                    return (
                      <>
                        <tr>
                          {" "}
                          <td colspan="2">
                            <hr />
                            <hr />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <input type="checkbox" />
                            {item.Quantity}x {item.SelectedProduct}
                          </td>

                          <td>{commonFunctions.isNumberInteger(item.ItemAmount)}</td>
                        </tr>
                        {item?.Deposit && (
                          <tr>
                            <td>Item Deposit</td>
                            <td>{commonFunctions.isNumberInteger(item.Deposit)}</td>
                          </tr>
                        )}

                        {item.ProductItemDetails && item.ProductItemDetails.map((productDetailObj) => {
                          return (
                            <>
                              <tr>
                                <td style={{ textAlign: "center" }} colspan="2">{productDetailObj.ProductIdName}</td>
                              </tr>
                              <tr>
                                <td>{handleDateTimeFormat(productDetailObj.StartDate)}</td>
                                <td>{productDetailObj.EndDate === null ? "Open" : handleDateTimeFormat(productDetailObj.EndDate)}</td>
                              </tr>
                              <tr>
                                <td style={{ textAlign: "center" }} colspan="2">
                                  Total {orderCommonFunctions.rentalCartTimeConvert(productDetailObj.TotalTime)}
                                </td>
                              </tr>
                            </>
                          )
                        })}


                        {item?.StartDate && item?.EndDate && (
                          <>
                            <tr>
                              <td>{handleDateTimeFormat(item.StartDate)}</td>
                              <td>{handleDateTimeFormat(item.EndDate)}</td>
                            </tr>

                            <tr>
                              <td colspan="2">
                                Total Time:
                                {orderCommonFunctions.rentalCartTimeConvert(
                                  item.TotalTime
                                )}
                              </td>
                            </tr>
                          </>
                        )}

                        {item?.ItemAddons?.map((item, index) => {
                          return (
                            <>
                              <tr key={index}>
                                <td>
                                  <input type="checkbox" />
                                  {item.Quantity}x {item.AddOnTitle}
                                </td>
                                <td>{commonFunctions.isNumberInteger(item.TotalAmount)}</td>
                              </tr>
                            </>
                          );
                        })}
                      </>
                    );
                  }
                )}

              {orderState.currentOrder?.selectedRetailItem &&
                orderState.currentOrder?.selectedRetailItem?.map(
                  (item, index) => {
                    const RetailItemDetails =
                      JSON.parse(item.RetailItemDetails) || [];

                    return (
                      <>
                        <tr>
                          {" "}
                          <td colspan="2">
                            <hr />
                          </td>
                        </tr>
                        <tr key={index}>
                          <td>
                            <input type="checkbox" /> {item.Quantity}x{" "}
                            {item.ItemName}
                          </td>
                          <td>{commonFunctions.isNumberInteger(item.TotalAmount)}</td>
                        </tr>
                        {RetailItemDetails &&
                          RetailItemDetails.map((item, index) => {
                            return (
                              <>
                                <tr key={index}>
                                  <td>Id:</td>
                                  <td>{item.Id}</td>
                                </tr>
                              </>
                            );
                          })}
                        {item.RetailAddons &&
                          item.RetailAddons.map((item, index) => {
                            return (
                              <>
                                <tr key={index}>
                                  <td>
                                    <input type="checkbox" /> {item.Quantity}x{" "}
                                    {item.AddOnTitle}
                                  </td>
                                  <td>{commonFunctions.isNumberInteger(item.TotalAmount)}</td>
                                </tr>
                              </>
                            );
                          })}
                      </>
                    );
                  }
                )}

              {orderState.currentOrder?.selectedActivityItems &&
                orderState.currentOrder?.selectedActivityItems.map(
                  (item, index) => {
                    const tickets = JSON.parse(item.OrderActivityDetails) || [];
                    const addOns = item.ActivityAddons || [];
                    return (
                      <>
                        <tr>
                          {" "}
                          <td colspan="2">
                            <hr />
                          </td>
                        </tr>
                        <tr key={index}>
                          <td>
                            <input type="checkbox" /> {item.ActivityName}
                          </td>
                          <td>{commonFunctions.isNumberInteger(item.Amount)}</td>
                        </tr>
                        {tickets.map((item, index) => {
                          return (
                            <>
                              <tr key={index}>
                                <td>
                                  {item.NumberOfTickets}x {item.Title}
                                </td>
                                <td>{commonFunctions.isNumberInteger(item.Amount)}</td>
                              </tr>
                            </>
                          );
                        })}
                        {addOns.map((item, index) => {
                          return (
                            <>
                              <tr key={index}>
                                <td>
                                  <input type="checkbox" />
                                  {item.Quantity}x {item.AddOnTitle}
                                </td>
                                <td>{commonFunctions.isNumberInteger(item.TotalAmount)}</td>
                              </tr>
                              <td colspan="2">
                                <hr />
                              </td>
                            </>
                          );
                        })}
                      </>
                    );
                  }
                )}

              {orderState.currentOrder?.selectedBundleItems &&
                orderState.currentOrder?.selectedBundleItems.map(
                  (item, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <td>
                            <input type="checkbox" />
                            {item.NumberOfBookings}x {item.BundleTitle}
                          </td>
                          <td>{commonFunctions.isNumberInteger(item.TotalAmount)}</td>
                        </tr>
                        <tr>
                          <td id="bundleContaines">Single Bundle Contains:-</td>
                        </tr>

                        {item?.BundleProducts &&
                          item?.BundleProducts.map((item, index) => {
                            return (
                              <>
                                <tr key={index}>
                                  <td>
                                    {item.quantity}x {item.ProductName}
                                  </td>
                                  <td></td>
                                </tr>
                              </>
                            );
                          })}

                        <tr key={index}>
                          <td colspan="2">
                            {`${moment(`${item.StartDate}`, "YYYY-MM-DD").format(
                              "MMM D"
                            )} ${item.StartTime}`}
                          </td>
                          <td colspan="2"></td>
                        </tr>
                      </>
                    );
                  }
                )}

              {orderState.currentOrder.pricings?.Addons &&
                orderState.currentOrder.pricings?.Addons?.map((item, index) => {
                  return (
                    <>
                      <tr>
                        {" "}
                        <td colspan="2">
                          <hr />
                        </td>
                      </tr>
                      <tr key={index}>
                        <td>
                          <input type="checkbox" />
                          {item.Quantity}x {item.AddOnTitle}
                        </td>
                        <td>${item.TotalPrice}</td>
                      </tr>
                    </>
                  );
                })}

              <tr>
                <td colspan="2">
                  <hr />
                  <hr />
                </td>
              </tr>
              {orderState.currentOrder.pricings && orderState.currentOrder.pricings.Subtotal !== undefined &&
                <tr>
                  <td>Subtotal</td>
                  <td>
                    {commonFunctions.isNumberInteger(orderState.currentOrder.pricings.Subtotal)}
                  </td>
                </tr>
              }

              {orderState.currentOrder.pricings?.DiscountAndCoupons &&
                orderState.currentOrder.pricings?.DiscountAndCoupons.map(
                  (item, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <td>{item.Title}</td>
                          <td>{commonFunctions.isNumberInteger(item.DiscountAmount)}</td>
                        </tr>
                      </>
                    );
                  }
                )}

              {orderState.currentOrder?.pricings && (
                <tr>
                  <td>Taxes & Fees</td>
                  <td>{commonFunctions.isNumberInteger(orderState.currentOrder?.pricings?.Taxes)}</td>
                </tr>
              )}

              {orderState.currentOrder.pricings?.ExtraCharges &&
                orderState.currentOrder.pricings?.ExtraCharges?.map(
                  (item, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <td>{item.Descriptions || item.FeeTitle}</td>
                          <td>{commonFunctions.isNumberInteger(item.Price)}</td>
                        </tr>
                      </>
                    );
                  }
                )}

              {orderState.currentOrder.pricings && orderState.currentOrder.pricings.OrderTotal !== undefined &&
                <>
                  <tr>
                    <td>Order Total</td>
                    <td>{commonFunctions.isNumberInteger(orderState.currentOrder.pricings.OrderTotal)}</td>
                  </tr>

                  {orderState.currentOrder?.totalDeposit !== 0 && <tr>
                    <td> Deposit</td>
                    <td>{commonFunctions.isNumberInteger(orderState.currentOrder.totalDeposit)}</td>
                  </tr>}

                  {orderState.currentOrder.totalDeposit !== 0 &&
                    <tr>
                      <td>Deposit Status</td>
                      <td>{orderState.currentOrder.depositStatus}</td>
                    </tr>
                  }

                  {orderState.currentOrder.pricings && orderState.currentOrder.pricings.TipAmount !== null &&
                    <tr>
                      <td>Paid Tip</td>
                      <td>{commonFunctions.isNumberInteger(orderState.currentOrder.pricings.TipAmount)}</td>
                    </tr>
                  }



                  {orderState.currentOrder.pricings.AmountPaid >= 0 &&
                    <tr>
                      <td>Paid Total</td>
                      <td>
                        {commonFunctions.isNumberInteger(orderState.currentOrder.pricings.AmountPaid)}
                      </td>
                    </tr>
                  }
                  <tr>
                    <td>Balance Due</td>
                    <td>
                      {commonFunctions.isNumberInteger(orderState.currentOrder.pricings.BalanceDue)}
                    </td>
                  </tr>

                </>
              }

              <tr>
                {" "}
                <td colspan="2">
                  <hr />
                </td>
              </tr>

              <br />
              <tr>
                <td style={{ textAlign: "center" }} colspan="2">
                  Thank You
                </td>
              </tr>
            </tbody>
          </table>

          <div className='thankyou'>
            <table>
              <tbody>
                <tr>
                  <td style={{ textAlign: "center" }} colspan="2">
                    Signature __________________________________
                  </td>
                </tr>

                <tr>
                  <td style={{ textAlign: "right" }} colspan="2">
                    {fullName}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
};

export default PrintableComponent;
