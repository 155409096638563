import React from 'react'
import { Table } from "semantic-ui-react";
import Moment from "react-moment";

function TableCurrentOrder(props) {
    const  currentOrder = props.currentOrder
    if (currentOrder.orderId || currentOrder.orderId !== null) {
        return (
            <Table basic="very" className="borderlessTable" compact="very" size="small">
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>Created Date</Table.Cell>
                        <Table.Cell textAlign="right">Order #</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell textAlign="left"><Moment format="MM/DD/YYYY">{currentOrder.createdDate}</Moment></Table.Cell>
                        <Table.Cell textAlign="right"  className="orange-color">{currentOrder.orderId}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Order Status</Table.Cell>
                        <Table.Cell textAlign="right">Payment Status</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell className="maroon-color">{currentOrder.orderStatus}</Table.Cell>
                        <Table.Cell textAlign="right">{currentOrder.paymentStatus!==null ? currentOrder.paymentStatus  : "-"}</Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        )
    }
    else {
        return <div></div>
    }
}
export default TableCurrentOrder;
