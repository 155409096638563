import React, { Component } from "react";
import { Grid, Button, Dimmer, Loader } from "semantic-ui-react";
import { commonFunctions, connect, bindActionCreators, actions, Notifications } from "../../../../functional/global-import";
import CommonImage from "../../../atoms/common-image";

class UploadImages extends Component {
  backStep = (e) => {
    e.preventDefault();
    if ((this.props.uploadImage.images[0] !== undefined &&
      this.props.uploadImage.DefaultImage[0] !== undefined) ||
      (this.props.uploadImage.images[0] === undefined &&
        this.props.uploadImage.DefaultImage[0] === undefined)) {
      this.props.onPrev(this.props.back)
    }
    else if (this.props.uploadImage.images[0] !== undefined &&
      this.props.uploadImage.DefaultImage[0] === undefined) {
      this.props.actions.showNotification({
        title: "Error", message: 'Please select default image.', position: 'br',
        autoDismiss: commonFunctions.notificationTime()
      }, "error");
    }
  }
  changeStep = () => {
    if ((this.props.uploadImage.images[0] !== undefined && this.props.uploadImage.DefaultImage[0] !== undefined && this.props.uploadImage.images.length <= 10) ||
      (this.props.uploadImage.images[0] === undefined && this.props.uploadImage.DefaultImage[0] === undefined && this.props.uploadImage.images.length <= 10)) {
      this.props.allFunction.changeStep(3);
    }
    else if (this.props.uploadImage.images[0] !== undefined && this.props.uploadImage.DefaultImage[0] === undefined) {
      this.props.actions.showNotification({ title: "Error", message: 'Please select default image.', position: 'br', autoDismiss: commonFunctions.notificationTime() }, "error");
    } else {
      this.props.actions.showNotification({ title: "Error", message: 'Maximum 10 images allowed to upload.', position: 'br', autoDismiss: commonFunctions.notificationTime() }, "error");
    }
  }
  render() {
    const { api } = this.props;

    return (
      <React.Fragment>
        <Grid>
          <Grid.Column
            mobile={16}
            tablet={16}
            computer={16}
            className="controlField">
            <CommonImage
              uploadImage={this.props.uploadImage}
              imagelist={this.props.uploadImage.images}
              imageId={this.props.uploadImage.imageId}
              multiple={true}
              onImageChange={this.props.onImageChange}
              onImageRemove={this.props.onImageRemove}
              handleChangeDefaultImage={this.props.handleChangeDefaultImage}
              DefaultImageId={this.props.uploadImage.DefaultImageId}
              defaultImage={this.props.uploadImage.DefaultImage}
              isRadio={true}
            />
          </Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column width={16} textAlign="right">
            <Button className="blue-btn" onClick={this.backStep}>Back</Button>
            <Button className="orange-btn" onClick={this.changeStep} loading={api.isApiLoading}>Continue</Button>
          </Grid.Column>
        </Grid>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UploadImages);