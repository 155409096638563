import React, { Component } from "react";
import { Divider, Checkbox, Form, Grid, Button, Dimmer, Loader } from "semantic-ui-react";
import { connect, bindActionCreators, actions, withRouter, } from "../../../../functional/global-import";

class PermissionRole extends Component {
  render() {
    const { allfunction, fullState, propsData, addRoleForm, api } = this.props;
    return (
      <Grid>

        {api.isApiLoading &&
          <Dimmer active inverted> <Loader size='small'>Loading</Loader> </Dimmer>
        }
        <Grid.Column width={16}>
          <p>Select permissions</p>
          <Divider hidden />
          <Grid columns="2">
            <Grid.Column>
              <Form>
                <Form.Field>
                  <Checkbox label={<label> <b>Master Login</b> </label>}
                    className="custome-checkbox"
                    data="masterLogin"
                    checked={fullState.masterAccess === false ? false : true}
                    // checked={propsData.roleName ==="Admin" === false ? false : false}
                    disabled={addRoleForm.roleName === "Admin" ? true : false}
                    onChange={(e, checked) => allfunction.onHandleMasterChange(e, checked)} />
                </Form.Field>
                <Divider />
                {fullState.rolePermissionScreenList.map((data, moduleIndex) => {
                  const permission = data.permission
                    ;
                  return (
                    <>
                      <Form.Field>
                    
                        <Checkbox label={<label> {data.moduleName === "Inventory" ? <b>{data.moduleName}
                          ({"Category/Sub category/item/activity/bundle"})</b> : <b>{data.moduleName}</b>} </label>}
                          className="custome-checkbox" data={data.moduleName} 
                          // disabled={propsData.roleName === "Admin" ? true : false}
                          disabled={addRoleForm.roleName === "Admin" ? true : false}
                          checked={data.Access === 0 ? false : true}
                          onChange={(e, checked) => allfunction.onHandleModuleChange(moduleIndex, e, checked)} />
                      </Form.Field>
                      <Divider />
                      {permission !== null && permission.map((actionDetail, screenIndex) => {
                        const actionList = actionDetail.action ? actionDetail.action : null

                        return (
                          <>
                            <Form.Field>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <Checkbox label={actionDetail.ScreenName}
                                // disabled={addRoleForm.roleName === "Admin" ? true : false}
                                disabled={addRoleForm.roleName === "Admin" ? true : false}
                                data={actionDetail.ScreenName}
                                checked={actionDetail.access === 0 ? false : true}
                                onChange={(e, checked, indeterminate) => allfunction.onScreenModuleChange(moduleIndex, screenIndex, e, checked, indeterminate)}
                                className="custome-checkbox" />
                            </Form.Field>
                            {actionList?.map((action, actionIndex) => {
                              return (
                                <Form.Field>
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <Checkbox label={action.ActionName} data={action.ActionName}
                                    checked={action.Access === 0 ? false : true}
                                    // disabled={propsData.roleName === "Admin" ? true : false}
                                    disabled={addRoleForm.roleName === "Admin" ? true : false}
                                    className="custome-checkbox" onChange={(e, checked) => allfunction.onActionHandleChange(moduleIndex, screenIndex, actionIndex, e, checked)} />
                                </Form.Field>)
                            })}
                          </>
                        )
                      })}
                      <Divider />
                    </>
                  )
                })}
              </Form>
            </Grid.Column>
            <Grid.Column width={16} textAlign="right">
              <Button className="blue-btn" onClick={() => allfunction.onPrevious(1)} > Back </Button>
              {fullState.iconClicked === true ? <Button onClick={allfunction.onSubmit} className="orange-btn"> Save </Button> : <Button onClick={allfunction.onUpdateRoleAndPermissionList} className="orange-btn"> Update </Button>}
            </Grid.Column>
          </Grid>
        </Grid.Column>
      </Grid>
    );
  }
}
const mapStateToProps = state => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
    }
  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PermissionRole));