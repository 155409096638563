import React, { Component } from "react";
import {Modal} from "semantic-ui-react";
import DocumentForm from "../../../../../pages/more-setting/document-setting/document-form";
class AddDocumentModal extends Component {

  render() {
    return (
      <Modal open={this.props.openModal} closeIcon onClose={this.props.closeModal} 
      size={"small"}>        
          <Modal.Content scrolling>
            <DocumentForm onClose={this.props.closeModal} getDocumentsList={this.props.getDocumentsList} hideToggleForModal={true} />
          </Modal.Content>
      </Modal>
    );
  }
}
export default AddDocumentModal;
