import React, { Fragment, useState } from "react";
import { Divider, Table, Icon, Checkbox, Image } from "semantic-ui-react";
import moment from "moment";
import { commonFunctions } from "../../../../../functional/global-import";
import { orderCommonFunctions } from "../order-common-function";
import {
  AddCircle,
  AddNoteMaroon,
  Delete,
  EditCircle,
} from "../../../../../functional/global-image-import";

const ItemDetailsGrid = ({ stateRef, orderState }) => {
  const [showTable, setShowTable] = useState(true);
  // const [showRetailTable, setShowRetailTable] = useState(true);
  // const [showActivityTable, setShowActivityTable] = useState(true);
  // const [showBundleTable, setShowBundleTable] = useState(true);

  // function handleShowHideBundleField() {
  //   setShowBundleTable(!showBundleTable);
  // }

  function handleShowHideField(index) {
    setShowTable(!(showTable + index));
  }

  // function handleShowHideActivityField() {
  //   setShowActivityTable(!showActivityTable);
  // }

  // function handleShowHideRetailField() {
  //   setShowRetailTable(!showRetailTable);
  // }

  function handleDateTimeFormat(date) {
    const timeConverted = moment(date, "MMM DD YYYY, hh:mm A").format(
      "MMM D, hh:mm A"
    );
    return timeConverted;
  }

  function onaddIconClick() {
    if (stateRef.state.activeStep > 1) {
      stateRef.handleSelectedBusinessCategoryEmpty();
      stateRef.onNext(1);
    } else {
      stateRef.props.actions.showNotification(
        {
          title: "Error",
          message: "Please complete second step first !",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    }
  }

  function onBundlePriceChange(e, bundleIndex) {
    const selectedBundleItems =
      stateRef.props.orderState.currentOrder.selectedBundleItems;
    selectedBundleItems[bundleIndex].TotalAmount = e.target.value;
    stateRef.props.actions.onPriceAndDepositChangeInCart(
      stateRef.props.orderState.currentOrder
    );
  }

  function onActivityPriceChange(e, activityIndex) {
    const selectedActivityItems =
      stateRef.props.orderState.currentOrder.selectedActivityItems;
    selectedActivityItems[activityIndex].Amount = e.target.value;
    stateRef.props.actions.onPriceAndDepositChangeInCart(
      stateRef.props.orderState.currentOrder
    );
  }

  function onRetailPriceChange(e, retailIndex) {
    const selectedRetailItem =
      stateRef.props.orderState.currentOrder.selectedRetailItem;
    selectedRetailItem[retailIndex].TotalAmount = e.target.value;
    stateRef.props.actions.onPriceAndDepositChangeInCart(
      stateRef.props.orderState.currentOrder
    );
  }
  function onSeasonPassPriceChange(e, seasonIndex) {
    const selectedSeasonItem =
      stateRef.props.orderState.currentOrder.seasonPassItem;
    selectedSeasonItem[seasonIndex].TotalAmount = e.target.value;
    stateRef.props.actions.onPriceAndDepositChangeInCart(
      stateRef.props.orderState.currentOrder
    );
  }

  function onRentalPriceChange(e, index) {
    const selectedItemsWithAmount =
      stateRef.props.orderState.currentOrder.selectedItemsWithAmount;
    selectedItemsWithAmount[index].ItemAmount = e.target.value;
    stateRef.props.actions.onPriceAndDepositChangeInCart(
      stateRef.props.orderState.currentOrder
    );
  }

  function onPriceSaveOfAllTypes(rentalPriceObj, amount) {
    const { orderState } = stateRef.props;
    stateRef.props.actions.apiCall({
      urls: ["UPDATEORDERPRODUCTPRICE"],
      method: "PUT",
      data: {
        orderId: orderState.currentOrder.orderId,
        orderDetailId: rentalPriceObj.OrderDetailId,
        businessCategoryType: rentalPriceObj.BusinessCategoryType,
        amount: parseFloat(amount),
        // amount: JSON.parse(amount),
        // amount: JSON.parse(rentalPriceObj.ItemAmount),
        actionPerformedBy: stateRef.props.auth.userDetail.emailId,
      },
      showNotification: true,
      onSuccess: (response) => {
        stateRef.props.actions.getOrderItems(orderState.currentOrder.orderId);
        return response;
      },
    });
  }

  function onDepositChange(e, index) {
    const selectedItemsWithAmount =
      stateRef.props.orderState.currentOrder.selectedItemsWithAmount;
    selectedItemsWithAmount[index].Deposit = e.target.value;
    stateRef.props.actions.onPriceAndDepositChangeInCart(
      stateRef.props.orderState.currentOrder
    );
  }

  function onDepositSave(rentalDepObj) {
    const { orderState } = stateRef.props;
    stateRef.props.actions.apiCall({
      urls: ["UPDATEORDERDEPOSIT"],
      method: "PUT",
      data: {
        orderDetailId: rentalDepObj.OrderDetailId,
        businessCategoryType: rentalDepObj.BusinessCategoryType,
        amount: JSON.parse(rentalDepObj.Deposit),
        actionPerformedBy: stateRef.props.auth.userDetail.emailId,
      },
      showNotification: true,
      onSuccess: (response) => {
        stateRef.props.actions.getOrderItems(orderState.currentOrder.orderId);
        return response;
      },
    });
  }

  // function to remove $ if required
  // function onDepositEditing(depositValue) {
  //
  //     const depositValueReturned = commonFunctions.isNumberInteger(depositValue)
  //     return (depositValueReturned.split(" ")[1])
  // }

  if (orderState.currentOrder.pricings !== null) {
    const checkAllItemDetails = orderState.currentOrder.checkAllItemDetails;
    const cartEditIcon = orderState.currentOrder.cartEditIcon;
    // const depositDropdown = (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "Deposit Status", this.props.productCategoryDetail.businessCategoryType))
    return (
      <Fragment>
        <Divider fitted />
        <Table
          basic="very"
          className="borderlessTable"
          compact="very"
          size="small"
        >
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <Checkbox
                  className="custome-checkbox"
                  name="checkAllItemDetails"
                  checked={checkAllItemDetails}
                  onChange={(e, { name, checked }) =>
                    stateRef.onHandleChangeOfCheckbox(e, { name, checked })
                  }
                />
              </Table.Cell>
              <Table.Cell textAlign="right">
                <span
                  title="Add Notes"
                  onClick={() => stateRef.addCommentModal()}
                >
                  <Image src={AddNoteMaroon} />
                </span>
                <span title="Add more" onClick={onaddIconClick}>
                  <Image src={AddCircle} />
                </span>

                {!cartEditIcon && (
                  <span title="Edit" onClick={() => stateRef.onEditIconClick()}>
                    <Image src={EditCircle} />
                  </span>
                )}
                {cartEditIcon && (
                  <span
                    title="Edit"
                    onClick={() => stateRef.onEditIconClick()}
                    style={{
                      filter: "brightness(0.5) saturate(1) hue-rotate(265deg)",
                    }}
                  >
                    <Image src={EditCircle} />
                  </span>
                )}
                <span title="Delete" onClick={() => stateRef.DeleteCartModal()}>
                  <Image src={Delete} />
                </span>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        {/****************** * for Rental items  *************************/}
        {orderState.currentOrder.selectedItemsWithAmount.length > 0 &&
          orderState.currentOrder.selectedItemsWithAmount.map((item, index) => {
            const formatted = orderCommonFunctions.rentalCartTimeConvert(
              item.TotalTime
            );
            return (
              <>
                <Divider fitted />
                <Table
                  basic="very"
                  className="borderlessTable cartTable"
                  compact="very"
                  size="small"
                >
                  <Table.Body>
                    {item.AreQuestionsAvailableForEditing && !item.paidAmount && (
                      <Table.Row>
                        <Table.Cell
                          colspan="2"
                          className="orange-color"
                          title="Edit Questions"
                          onClick={() =>
                            stateRef.onRentalEditQuestionClick(item, "Rental")
                          }
                        >
                          <span className="question-cart"> Q & A</span>
                        </Table.Cell>
                        <Table.Cell textAlign="right" className="orange-color">
                          <Icon
                            name="edit"
                            className="custom-question-icon"
                            title="Edit Questions"
                            onClick={() =>
                              stateRef.onRentalEditQuestionClick(item, "Rental")
                            }
                          />
                        </Table.Cell>
                      </Table.Row>
                    )}
                    {/* //  ************************  for edit *********************** */}
                    {cartEditIcon && item.rentalItemCheckbox ? (
                      <Table.Row>
                        <Table.Cell colspan="2">
                          <Checkbox
                            className="custome-checkbox rentalEditcart labelInputPayment"
                            name="rentalItemCheckbox"
                            businessType="selectedItemsWithAmount"
                            businessTypeAddon="ItemAddons"
                            addOnName="rentalItemAddon"
                            checked={
                              checkAllItemDetails
                                ? true
                                : item.rentalItemCheckbox
                                ? true
                                : false
                            }
                            onChange={(
                              e,
                              {
                                name,
                                checked,
                                businessType,
                                businessTypeAddon,
                                addOnName,
                              }
                            ) =>
                              stateRef.onHandleChangeOfCheckbox(
                                e,
                                {
                                  name,
                                  checked,
                                  businessType,
                                  businessTypeAddon,
                                  addOnName,
                                },
                                item,
                                index,
                                item.ItemAmount
                              )
                            }
                          />
                          <label
                            title={item.SelectedProduct}
                            className="rentalPrice first textOverflowHidden"
                            onClick={() =>
                              stateRef.backTorentalitemSelect(item)
                            }
                          >
                            {item.Quantity} X{" "}
                            <span className="orange-color textOverflowHidden">
                              {commonFunctions.truncateText(
                                item.SelectedProduct,
                                9
                              )}
                            </span>
                          </label>
                        </Table.Cell>

                        <Table.Cell textAlign="right" className="collapseIcon">
                          ${" "}
                          <input
                            type="number"
                            value={item.ItemAmount}
                            onChange={(e) => onRentalPriceChange(e, index)}
                            onBlur={() =>
                              onPriceSaveOfAllTypes(item, item.ItemAmount)
                            }
                            className="cartDepositInput"
                          />
                          {!item.showIcon ? (
                            <Icon
                              name="chevron right"
                              className="orange-color"
                              style={{ "font-size": "14px" }}
                              size={"large"}
                              onClick={() =>
                                stateRef.handleShowHideField(
                                  "selectedItemsWithAmount",
                                  index
                                )
                              }
                            />
                          ) : (
                            <Icon
                              name="chevron down"
                              className="orange-color"
                              style={{ "font-size": "14px" }}
                              size={"large"}
                              onClick={() =>
                                stateRef.handleShowHideField(
                                  "selectedItemsWithAmount",
                                  index
                                )
                              }
                            />
                          )}
                        </Table.Cell>
                      </Table.Row>
                    ) : (
                      <Table.Row>
                        <Table.Cell colspan="2">
                          {/* for paid item code  */}
                          {item.paidAmount ? (
                            <Checkbox
                              className="custome-paid-checkbox rentalEditcart"
                              checked={item.paidAmount}
                              label={
                                <label>
                                  {item.Quantity} X{" "}
                                  <span className="orange-color">
                                    {item.SelectedProduct}
                                  </span>
                                </label>
                              }
                            />
                          ) : (
                            //  {/*************************  for non editable *********************/}
                            <Checkbox
                              style={{ "font-size": "11.3px" }}
                              className="custome-checkbox labelInputPayment"
                              name="rentalItemCheckbox"
                              checked={
                                checkAllItemDetails
                                  ? true
                                  : item.rentalItemCheckbox
                                  ? true
                                  : false
                              }
                              businessType="selectedItemsWithAmount"
                              businessTypeAddon="ItemAddons"
                              addOnName="rentalItemAddon"
                              label={
                                <label>
                                  {item.Quantity} X{" "}
                                  <span className="orange-color">
                                    {item.SelectedProduct}
                                  </span>
                                </label>
                              }
                              onChange={(
                                e,
                                {
                                  name,
                                  checked,
                                  businessType,
                                  businessTypeAddon,
                                  addOnName,
                                }
                              ) =>
                                stateRef.onHandleChangeOfCheckbox(
                                  e,
                                  {
                                    name,
                                    checked,
                                    businessType,
                                    businessTypeAddon,
                                    addOnName,
                                  },
                                  item,
                                  index,
                                  item.ItemAmount
                                )
                              }
                            />
                          )}
                        </Table.Cell>
                        <Table.Cell textAlign="right" className="collapseIcon">
                          {commonFunctions.isNumberInteger(item.ItemAmount)}
                          {!item.showIcon ? (
                            <Icon
                              name="chevron right"
                              className="orange-color "
                              style={{ "font-size": "14px" }}
                              size={"large"}
                              onClick={() =>
                                stateRef.handleShowHideField(
                                  "selectedItemsWithAmount",
                                  index
                                )
                              }
                            />
                          ) : (
                            <Icon
                              name="chevron down"
                              className="orange-color"
                              style={{ "font-size": "14px" }}
                              size={"large"}
                              onClick={() =>
                                stateRef.handleShowHideField(
                                  "selectedItemsWithAmount",
                                  index
                                )
                              }
                            />
                          )}
                        </Table.Cell>
                      </Table.Row>
                    )}

                    {item.Deposit !== null && (
                      <Table.Row>
                        <Table.Cell colspan="2">
                          {item.paidRentalDepositAmount ? (
                            <Checkbox
                              className="custome-paid-checkbox rentalEditcart"
                              checked={item.paidRentalDepositAmount}
                              label={
                                <label className="orange-color">
                                  {" "}
                                  Item Deposit{" "}
                                </label>
                              }
                            />
                          ) : (
                            <Checkbox
                              className="custome-checkbox labelInputPayment"
                              name="rentalDepositCheckbox"
                              businessType="selectedItemsWithAmount"
                              // checked={checkAllItemDetails ? true : item.rentalDepositCheckbox ? true : false}
                              checked={item.rentalDepositCheckbox}
                              onChange={(e, { name, checked, businessType }) =>
                                stateRef.onHandleChangeOfDepositCheckbox(
                                  e,
                                  { name, checked, businessType },
                                  item,
                                  index,
                                  item.Deposit
                                )
                              }
                              label={
                                <label className="orange-color">
                                  {" "}
                                  Item Deposit{" "}
                                </label>
                              }
                            />
                          )}
                        </Table.Cell>
                        {cartEditIcon && item.rentalDepositCheckbox ? (
                          <Table.Cell
                            textAlign="right"
                            className="orange-color"
                          >
                            <Icon
                              name="minus circle"
                              onClick={() =>
                                stateRef.onDepositBtndeleteModalCLick(item)
                              }
                            />
                            ${" "}
                            <input
                              type="number"
                              // value={onDepositEditing(item.Deposit)}
                              value={item.Deposit}
                              onChange={(e) => onDepositChange(e, index)}
                              onBlur={() => onDepositSave(item)}
                              className="cartDepositInput"
                            />
                            {/* <Form.Input  type='number'
                                                        placeholder='Amount' value={item.Deposit} onChange={(e) => onDepositChange(e, item, index)}>
                                                        <Label basic>$</Label>
                                                        <input />
                                                        <Label basic></Label>
                                                    </Form.Input> */}
                          </Table.Cell>
                        ) : (
                          <Table.Cell
                            textAlign="right"
                            className="orange-color"
                          >
                            <Icon
                              name="minus circle"
                              onClick={() =>
                                stateRef.onDepositBtndeleteModalCLick(
                                  item,
                                  index
                                )
                              }
                            />{" "}
                            {commonFunctions.isNumberInteger(item.Deposit)}
                          </Table.Cell>
                        )}
                      </Table.Row>
                    )}

                    {item.showIcon &&
                      item.ProductItemDetails &&
                      item.ProductItemDetails.map((productDetailObj) => {
                        return (
                          <>
                            <Table.Row>
                              <Table.Cell
                                colspan="3"
                                textAlign="center"
                                className="deepviolet-color"
                              >
                                {productDetailObj.ProductIdName}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>
                                <span className="nonCheckBoxes">
                                  {handleDateTimeFormat(
                                    productDetailObj.StartDate
                                  )}
                                </span>
                              </Table.Cell>
                              <Table.Cell className="deepviolet-color">
                                <Icon name="long arrow alternate right" />
                              </Table.Cell>
                              <Table.Cell textAlign="right">
                                {productDetailObj.EndDate === null
                                  ? "Open"
                                  : handleDateTimeFormat(
                                      productDetailObj.EndDate
                                    )}
                              </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                              <Table.Cell
                                colspan="3"
                                textAlign="center"
                                className="deepviolet-color"
                              >
                                Total{" "}
                                {orderCommonFunctions.rentalCartTimeConvert(
                                  productDetailObj.TotalTime
                                )}
                                {/* {formatted} */}
                              </Table.Cell>
                            </Table.Row>
                          </>
                        );
                      })}

                    {item.showIcon && item.TotalTime && (
                      <Fragment>
                        <Table.Row>
                          <Table.Cell>
                            <span className="nonCheckBoxes">
                              {handleDateTimeFormat(item.StartDate)}
                            </span>
                          </Table.Cell>
                          <Table.Cell className="deepviolet-color">
                            <Icon name="long arrow alternate right" />
                          </Table.Cell>
                          <Table.Cell
                            textAlign="right"
                            className="textOverflowHidden"
                          >
                            {item.EndDate === null
                              ? "Open"
                              : handleDateTimeFormat(item.EndDate)}
                          </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                          <Table.Cell
                            colspan="3"
                            textAlign="center"
                            className="deepviolet-color"
                          >
                            Total Time:{formatted}
                          </Table.Cell>
                        </Table.Row>
                      </Fragment>
                    )}

                    {/* add on of item  */}
                    {item.ItemAddons &&
                      item.ItemAddons.map((ItemdataObj, itemAddOnIndex) => {
                        return (
                          <>
                            <Table.Row>
                              <Table.Cell colspan="2">
                                {/* for paid item code  */}
                                {ItemdataObj.paidAmount ? (
                                  <Checkbox
                                    className="custome-paid-checkbox"
                                    checked={ItemdataObj.paidAmount}
                                    label={
                                      <label>
                                        {ItemdataObj.Quantity} X{" "}
                                        <span className="orange-color">
                                          {ItemdataObj.AddOnTitle}
                                        </span>
                                      </label>
                                    }
                                  />
                                ) : (
                                  <Checkbox
                                    className="custome-checkbox"
                                    name="rentalItemAddon"
                                    businessType="selectedItemsWithAmount"
                                    businessTypeAddon="ItemAddons"
                                    businessTypeName="rentalItemCheckbox"
                                    checked={
                                      checkAllItemDetails
                                        ? true
                                        : ItemdataObj.rentalItemAddon
                                        ? true
                                        : false
                                    }
                                    onChange={(
                                      e,
                                      {
                                        name,
                                        checked,
                                        businessType,
                                        businessTypeAddon,
                                        businessTypeName,
                                      }
                                    ) =>
                                      stateRef.onHandleChangeOfAddOnCheckbox(
                                        e,
                                        {
                                          name,
                                          checked,
                                          businessType,
                                          businessTypeAddon,
                                          businessTypeName,
                                        },
                                        index,
                                        itemAddOnIndex,
                                        ItemdataObj.TotalAmount
                                      )
                                    }
                                    label={
                                      <label>
                                        {ItemdataObj.Quantity} X{" "}
                                        <span className="orange-color">
                                          {ItemdataObj.AddOnTitle}
                                        </span>
                                      </label>
                                    }
                                  />
                                )}
                              </Table.Cell>
                              <Table.Cell textAlign="right">
                                {commonFunctions.isNumberInteger(
                                  ItemdataObj.TotalAmount
                                )}
                              </Table.Cell>
                            </Table.Row>
                          </>
                        );
                      })}
                    {/* -------------------------------------------------- */}

                    {item.showIcon && item?.CheckedInItem !== 0 && (
                      <>
                        <Table.Row>
                          <Table.Cell className="withoutCheckBoxStyle">
                            <label className="orange-color">Item Status</label>
                          </Table.Cell>
                          <Table.Cell colspan="2" textAlign="right">
                            {item.CheckedInItem} X Checked In
                          </Table.Cell>
                        </Table.Row>
                      </>
                    )}
                    {item.showIcon && item?.CheckedOutItem !== 0 && (
                      <Table.Row>
                        <Table.Cell className="withoutCheckBoxStyle">
                          <label className="orange-color">Item Status</label>
                        </Table.Cell>
                        <Table.Cell colspan="2" textAlign="right">
                          {item.CheckedOutItem} X Checked Out
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
              </>
            );
          })}

        {/******************************************  retail items table data ************************************************* */}
        {orderState.currentOrder.selectedRetailItem.length > 0 &&
          orderState.currentOrder.selectedRetailItem.map(
            (retailItem, retailIndex) => {
              return (
                <>
                  <Divider fitted />
                  <Table
                    basic="very"
                    className="borderlessTable"
                    compact="very"
                    size="small"
                  >
                    <Table.Body>
                      {retailItem.AreQuestionsAvailableForEditing &&
                        !retailItem.paidAmount && (
                          <Table.Row>
                            <Table.Cell
                              colspan="2"
                              className="orange-color"
                              title="Edit Questions"
                              onClick={() =>
                                stateRef.onRetailEditQuestionClick(
                                  retailItem,
                                  "Retail"
                                )
                              }
                            >
                              <span className="question-cart"> Q & A</span>
                            </Table.Cell>
                            <Table.Cell
                              textAlign="right"
                              className="orange-color"
                            >
                              <Icon
                                name="edit"
                                className="custom-question-icon"
                                title="Edit Questions"
                                onClick={() =>
                                  stateRef.onRetailEditQuestionClick(
                                    retailItem,
                                    "Retail"
                                  )
                                }
                              />
                            </Table.Cell>
                          </Table.Row>
                        )}
                      {/* for edit Retail item code  */}
                      {cartEditIcon && retailItem.retailItemCheckbox ? (
                        <Table.Row>
                          <Table.Cell colspan="2">
                            <Checkbox
                              style={{ "font-size": "11.3px" }}
                              className="custome-checkbox rentalEditcart labelInputPayment"
                              name="retailItemCheckbox"
                              businessType="selectedRetailItem"
                              addOnName="retailItemAddon"
                              businessTypeAddon="RetailAddons"
                              checked={
                                checkAllItemDetails
                                  ? true
                                  : retailItem.retailItemCheckbox
                                  ? true
                                  : false
                              }
                              onChange={(
                                e,
                                {
                                  name,
                                  checked,
                                  businessType,
                                  businessTypeAddon,
                                  addOnName,
                                }
                              ) =>
                                stateRef.onHandleChangeOfCheckbox(
                                  e,
                                  {
                                    name,
                                    checked,
                                    businessType,
                                    businessTypeAddon,
                                    addOnName,
                                  },
                                  retailItem,
                                  retailIndex,
                                  retailItem.TotalAmount
                                )
                              }
                            />
                            <label
                              title={retailItem.ItemName}
                              className="rentalPrice first"
                              onClick={() =>
                                stateRef.backToretailItemSelect(retailItem)
                              }
                            >
                              {retailItem.Quantity} X{" "}
                              <span className="orange-color">
                                {commonFunctions.truncateText(
                                  retailItem.ItemName,
                                  9
                                )}
                              </span>
                            </label>
                          </Table.Cell>
                          <Table.Cell
                            textAlign="right"
                            className="collapseIcon"
                          >
                            ${" "}
                            <input
                              type="number"
                              value={retailItem.TotalAmount}
                              onChange={(e) =>
                                onRetailPriceChange(e, retailIndex)
                              }
                              onBlur={() =>
                                onPriceSaveOfAllTypes(
                                  retailItem,
                                  retailItem.TotalAmount
                                )
                              }
                              className="cartDepositInput"
                            />
                            {!retailItem.showIcon ? (
                              <Icon
                                name="chevron right"
                                className="orange-color "
                                style={{ "font-size": "14px" }}
                                size={"large"}
                                onClick={() =>
                                  stateRef.handleShowHideField(
                                    "selectedRetailItem",
                                    retailIndex
                                  )
                                }
                              />
                            ) : (
                              <Icon
                                name="chevron down"
                                className="orange-color"
                                style={{ "font-size": "14px" }}
                                size={"large"}
                                onClick={() =>
                                  stateRef.handleShowHideField(
                                    "selectedRetailItem",
                                    retailIndex
                                  )
                                }
                              />
                            )}
                          </Table.Cell>
                        </Table.Row>
                      ) : (
                        <Table.Row>
                          <Table.Cell colspan="2">
                            {/* for paid Retail item code */}
                            {retailItem.paidAmount ? (
                              <Checkbox
                                style={{ "font-size": "11.3px" }}
                                className="custome-paid-checkbox"
                                checked={retailItem.paidAmount}
                                label={
                                  <label>
                                    {retailItem.Quantity} X{" "}
                                    <span className="orange-color">
                                      {retailItem.ItemName}
                                    </span>
                                  </label>
                                }
                              />
                            ) : (
                              <Checkbox
                                style={{ "font-size": "11.3px" }}
                                className="custome-checkbox labelInputPayment"
                                name="retailItemCheckbox"
                                businessType="selectedRetailItem"
                                addOnName="retailItemAddon"
                                businessTypeAddon="RetailAddons"
                                checked={
                                  checkAllItemDetails
                                    ? true
                                    : retailItem.retailItemCheckbox
                                    ? true
                                    : false
                                }
                                label={
                                  <label>
                                    {retailItem.Quantity} X{" "}
                                    <span className="orange-color">
                                      {retailItem.ItemName}
                                    </span>
                                  </label>
                                }
                                onChange={(
                                  e,
                                  {
                                    name,
                                    checked,
                                    businessType,
                                    businessTypeAddon,
                                    addOnName,
                                  }
                                ) =>
                                  stateRef.onHandleChangeOfCheckbox(
                                    e,
                                    {
                                      name,
                                      checked,
                                      businessType,
                                      businessTypeAddon,
                                      addOnName,
                                    },
                                    retailItem,
                                    retailIndex,
                                    retailItem.TotalAmount
                                  )
                                }
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell
                            textAlign="right"
                            className="collapseIcon"
                          >
                            {commonFunctions.isNumberInteger(
                              retailItem.TotalAmount
                            )}

                            {!retailItem.showIcon ? (
                              <Icon
                                name="chevron right"
                                className="orange-color "
                                style={{ "font-size": "14px" }}
                                size={"large"}
                                onClick={() =>
                                  stateRef.handleShowHideField(
                                    "selectedRetailItem",
                                    retailIndex
                                  )
                                }
                              />
                            ) : (
                              <Icon
                                name="chevron down"
                                className="orange-color"
                                style={{ "font-size": "14px" }}
                                size={"large"}
                                onClick={() =>
                                  stateRef.handleShowHideField(
                                    "selectedRetailItem",
                                    retailIndex
                                  )
                                }
                              />
                            )}
                          </Table.Cell>
                        </Table.Row>
                      )}

                      {retailItem.showIcon &&
                        retailItem.RetailItemDetails &&
                        JSON.parse(retailItem.RetailItemDetails).map(
                          (retailIds) => {
                            return (
                              <Table.Row className="withoutCheckBoxStyle">
                                <Table.Cell />
                                <Table.Cell>Id:</Table.Cell>
                                <Table.Cell
                                  id={retailIds.OrderRetailDetailId}
                                  textAlign="right"
                                >
                                  {retailIds.Id}
                                </Table.Cell>
                              </Table.Row>
                            );
                          }
                        )}

                      {/* add on of Retail  */}
                      {retailItem.RetailAddons &&
                        retailItem.RetailAddons.map(
                          (retaildataObj, retailAddOnIndex) => {
                            return (
                              <>
                                <Table.Row>
                                  <Table.Cell colspan="2">
                                    {/* for paid item code  */}
                                    {retaildataObj.paidAmount ? (
                                      <Checkbox
                                        className="custome-paid-checkbox "
                                        checked={retaildataObj.paidAmount}
                                        label={
                                          <label>
                                            {retaildataObj.Quantity} X{" "}
                                            <span className="orange-color">
                                              {retaildataObj.AddOnTitle}
                                            </span>
                                          </label>
                                        }
                                      />
                                    ) : (
                                      <Checkbox
                                        className="custome-checkbox labelInputPayment"
                                        name="retailItemAddon"
                                        businessType="selectedRetailItem"
                                        businessTypeAddon="RetailAddons"
                                        businessTypeName="retailItemCheckbox"
                                        checked={
                                          checkAllItemDetails
                                            ? true
                                            : retaildataObj.retailItemAddon
                                            ? true
                                            : false
                                        }
                                        onChange={(
                                          e,
                                          {
                                            name,
                                            checked,
                                            businessType,
                                            businessTypeAddon,
                                            businessTypeName,
                                          }
                                        ) =>
                                          stateRef.onHandleChangeOfAddOnCheckbox(
                                            e,
                                            {
                                              name,
                                              checked,
                                              businessType,
                                              businessTypeAddon,
                                              businessTypeName,
                                            },
                                            retailIndex,
                                            retailAddOnIndex,
                                            retaildataObj.TotalAmount
                                          )
                                        }
                                        label={
                                          <label>
                                            {retaildataObj.Quantity} X{" "}
                                            <span className="orange-color">
                                              {retaildataObj.AddOnTitle}
                                            </span>
                                          </label>
                                        }
                                      />
                                    )}
                                  </Table.Cell>
                                  <Table.Cell textAlign="right">
                                    {commonFunctions.isNumberInteger(
                                      retaildataObj.TotalAmount
                                    )}
                                  </Table.Cell>
                                </Table.Row>
                              </>
                            );
                          }
                        )}
                    </Table.Body>
                  </Table>
                </>
              );
            }
          )}

        {/******************************************  Activity items table data ************************************************* */}
        {orderState.currentOrder.selectedActivityItems.length > 0 &&
          orderState.currentOrder.selectedActivityItems.map(
            (activityObj, activityIndex) => {
              //
              return (
                <>
                  <Divider fitted />
                  <Table
                    basic="very"
                    className="borderlessTable"
                    compact="very"
                    size="small"
                  >
                    <Table.Body>
                      {activityObj.AreQuestionsAvailableForEditing &&
                        !activityObj.paidAmount && (
                          <Table.Row>
                            <Table.Cell
                              colspan="2"
                              className="orange-color"
                              title="Edit Questions"
                              onClick={() =>
                                stateRef.onActivityEditQuestionClick(
                                  activityObj,
                                  "Activity"
                                )
                              }
                            >
                              <span className="question-cart"> Q & A</span>
                            </Table.Cell>
                            <Table.Cell
                              textAlign="right"
                              className="orange-color"
                            >
                              <Icon
                                name="edit"
                                className="custom-question-icon"
                                title="Edit Questions"
                                onClick={() =>
                                  stateRef.onActivityEditQuestionClick(
                                    activityObj,
                                    "Activity"
                                  )
                                }
                              />
                            </Table.Cell>
                          </Table.Row>
                        )}
                      {cartEditIcon && activityObj.activityItemCheckbox ? (
                        <Table.Row>
                          <Table.Cell colspan="2">
                            <Checkbox
                              style={{ "font-size": "11.3px" }}
                              className="custome-checkbox rentalEditcart labelInputPayment"
                              name="activityItemCheckbox"
                              businessType="selectedActivityItems"
                              businessTypeAddon="ActivityAddons"
                              addOnName="activityItemAddon"
                              checked={
                                checkAllItemDetails
                                  ? true
                                  : activityObj.activityItemCheckbox
                                  ? true
                                  : false
                              }
                              onChange={(
                                e,
                                {
                                  name,
                                  checked,
                                  businessType,
                                  businessTypeAddon,
                                  addOnName,
                                }
                              ) =>
                                stateRef.onHandleChangeOfCheckbox(
                                  e,
                                  {
                                    name,
                                    checked,
                                    businessType,
                                    businessTypeAddon,
                                    addOnName,
                                  },
                                  activityObj,
                                  activityIndex,
                                  activityObj.Amount
                                )
                              }
                            />
                            <label
                              title={activityObj.ActivityName}
                              className="rentalPrice first orange-color"
                              onClick={() =>
                                stateRef.backToActivityTicketSelect(activityObj)
                              }
                            >
                              {commonFunctions.truncateText(
                                activityObj.ActivityName,
                                12
                              )}
                            </label>
                          </Table.Cell>
                          <Table.Cell
                            textAlign="right"
                            className="collapseIcon"
                          >
                            ${" "}
                            <input
                              type="number"
                              value={activityObj.Amount}
                              onChange={(e) =>
                                onActivityPriceChange(e, activityIndex)
                              }
                              onBlur={() =>
                                onPriceSaveOfAllTypes(
                                  activityObj,
                                  activityObj.Amount
                                )
                              }
                              className="cartDepositInput"
                            />
                            {!activityObj.showIcon ? (
                              <Icon
                                name="chevron right"
                                className="orange-color "
                                style={{ "font-size": "14px" }}
                                size={"large"}
                                onClick={() =>
                                  stateRef.handleShowHideField(
                                    "selectedActivityItems",
                                    activityIndex
                                  )
                                }
                              />
                            ) : (
                              <Icon
                                name="chevron down"
                                className="orange-color"
                                style={{ "font-size": "14px" }}
                                size={"large"}
                                onClick={() =>
                                  stateRef.handleShowHideField(
                                    "selectedActivityItems",
                                    activityIndex
                                  )
                                }
                              />
                            )}
                          </Table.Cell>
                        </Table.Row>
                      ) : (
                        <Table.Row>
                          <Table.Cell colspan="2">
                            {/*  {/* for paid */}
                            {activityObj.paidAmount ? (
                              <Checkbox
                                style={{ "font-size": "11.3px" }}
                                className="custome-paid-checkbox "
                                checked={activityObj.paidAmount}
                                label={
                                  <label>
                                    <span className="orange-color">
                                      {activityObj.ActivityName}
                                    </span>
                                  </label>
                                }
                              />
                            ) : (
                              <Checkbox
                                style={{ "font-size": "11.3px" }}
                                className="custome-checkbox rentalEditcart labelInputPayment"
                                name="activityItemCheckbox"
                                businessType="selectedActivityItems"
                                businessTypeAddon="ActivityAddons"
                                addOnName="activityItemAddon"
                                checked={
                                  checkAllItemDetails
                                    ? true
                                    : activityObj.activityItemCheckbox
                                    ? true
                                    : false
                                }
                                onChange={(
                                  e,
                                  {
                                    name,
                                    checked,
                                    businessType,
                                    businessTypeAddon,
                                    addOnName,
                                  }
                                ) =>
                                  stateRef.onHandleChangeOfCheckbox(
                                    e,
                                    {
                                      name,
                                      checked,
                                      businessType,
                                      businessTypeAddon,
                                      addOnName,
                                    },
                                    activityObj,
                                    activityIndex,
                                    activityObj.Amount
                                  )
                                }
                                label={
                                  <label>
                                    <span className="orange-color">
                                      {activityObj.ActivityName}
                                    </span>
                                  </label>
                                }
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell
                            textAlign="right"
                            className="collapseIcon"
                          >
                            {commonFunctions.isNumberInteger(
                              activityObj.Amount
                            )}
                            {!activityObj.showIcon ? (
                              <Icon
                                name="chevron right"
                                className="orange-color "
                                style={{ "font-size": "14px" }}
                                size={"large"}
                                onClick={() =>
                                  stateRef.handleShowHideField(
                                    "selectedActivityItems",
                                    activityIndex
                                  )
                                }
                              />
                            ) : (
                              <Icon
                                name="chevron down"
                                className="orange-color"
                                style={{ "font-size": "14px" }}
                                size={"large"}
                                onClick={() =>
                                  stateRef.handleShowHideField(
                                    "selectedActivityItems",
                                    activityIndex
                                  )
                                }
                              />
                            )}
                          </Table.Cell>
                        </Table.Row>
                      )}

                      {activityObj.showIcon &&
                        activityObj.OrderActivityDetails &&
                        JSON.parse(activityObj.OrderActivityDetails).map(
                          (ticket, ticketIndex) => {
                            return (
                              <>
                                <Table.Row>
                                  <Table.Cell colspan="2">
                                    {" "}
                                    <span className="nonCheckBoxes">
                                      {" "}
                                      {ticket.NumberOfTickets} X{" "}
                                      <span className="orange-color">
                                        {" "}
                                        {ticket.Title}
                                      </span>
                                    </span>
                                    {/* <Checkbox
                                                            style={{ "font-size": "11.3px" }} className="custome-checkbox"
                                                            name={`activityTicketName ${ticketIndex}`}
                                                            checked={itemDetailsGridState.checkAllItemDetails ? true : itemDetailsGridState[`activityTicketName ${ticketIndex}`] ? true : false}
                                                            label={<label>{ticket.NumberOfTickets} X{" "}<span className="orange-color">{ticket.Title}</span></label>}
                                                            onChange={(e, { name, value }) => handleChange(e, { name, value }, ticket)} /> */}
                                  </Table.Cell>
                                  <Table.Cell
                                    textAlign="right"
                                    className="collapseIcon"
                                  >
                                    {commonFunctions.isNumberInteger(
                                      ticket.Amount
                                    )}
                                  </Table.Cell>
                                </Table.Row>
                              </>
                            );
                          }
                        )}

                      {/*   // ${activityObj.StartTime} */}
                      {activityObj.showIcon && activityObj.StartDate && (
                        <Table.Row>
                          <Table.Cell>
                            {" "}
                            <span className="nonCheckBoxes">
                              {" "}
                              {`${moment(
                                `${
                                  activityObj.StartDate && activityObj.StartDate
                                }`,
                                "YYYY-MM-DD"
                              ).format("MMM D")}`}
                            </span>
                            <span>{` , ${
                              activityObj.timeRangeFrom &&
                              activityObj.timeRangeFrom
                            }`}</span>
                            {/* <Checkbox
                                                    className="custome-checkbox"
                                                    name={`activityTime ${activityIndex}`}
                                                    checked={itemDetailsGridState.checkAllItemDetails ? true : itemDetailsGridState[`activityTime ${activityIndex}`] ? true : false}
                                                    label={`${(moment(`${activityObj.StartDate}`, 'YYYY-MM-DD').format('MMM D'))}`}
                                                    onChange={(e, { name, value }) => handleChange(e, { name, value }, activityObj)}
                                                /> */}
                          </Table.Cell>
                        </Table.Row>
                      )}

                      {/* add on of activity  */}
                      {activityObj.ActivityAddons &&
                        activityObj.ActivityAddons.map(
                          (activitydataObj, activityAddOnIndex) => {
                            return (
                              <>
                                <Table.Row>
                                  <Table.Cell colspan="2">
                                    {/* for paid item code  */}
                                    {activitydataObj.paidAmount ? (
                                      <Checkbox
                                        className="custome-paid-checkbox "
                                        checked={activitydataObj.paidAmount}
                                        label={
                                          <label>
                                            {activitydataObj.Quantity} X{" "}
                                            <span className="orange-color">
                                              {activitydataObj.AddOnTitle}
                                            </span>
                                          </label>
                                        }
                                      />
                                    ) : (
                                      <Checkbox
                                        className="custome-checkbox labelInputPayment"
                                        name="activityItemAddon"
                                        businessType="selectedActivityItems"
                                        businessTypeAddon="ActivityAddons"
                                        businessTypeName="activityItemCheckbox"
                                        checked={
                                          checkAllItemDetails
                                            ? true
                                            : activitydataObj.activityItemAddon
                                            ? true
                                            : false
                                        }
                                        onChange={(
                                          e,
                                          {
                                            name,
                                            checked,
                                            businessType,
                                            businessTypeAddon,
                                            businessTypeName,
                                          }
                                        ) =>
                                          stateRef.onHandleChangeOfAddOnCheckbox(
                                            e,
                                            {
                                              name,
                                              checked,
                                              businessType,
                                              businessTypeAddon,
                                              businessTypeName,
                                            },
                                            activityIndex,
                                            activityAddOnIndex,
                                            activitydataObj.TotalAmount
                                          )
                                        }
                                        label={
                                          <label>
                                            {activitydataObj.Quantity} X{" "}
                                            <span className="orange-color">
                                              {activitydataObj.AddOnTitle}
                                            </span>
                                          </label>
                                        }
                                      />
                                    )}
                                  </Table.Cell>
                                  <Table.Cell
                                    textAlign="right"
                                    className="no-wrap"
                                  >
                                    {commonFunctions.isNumberInteger(
                                      activitydataObj.TotalAmount
                                    )}
                                  </Table.Cell>
                                </Table.Row>
                              </>
                            );
                          }
                        )}
                    </Table.Body>
                  </Table>
                </>
              );
            }
          )}

        {/******************************************  Bundle items table data ************************************************* */}
        {orderState.currentOrder.selectedBundleItems.length > 0 &&
          orderState.currentOrder.selectedBundleItems.map(
            (bundleObj, bundleIndex) => {
              //
              return (
                <>
                  <Divider fitted />
                  <Table
                    basic="very"
                    className="borderlessTable"
                    compact="very"
                    size="small"
                  >
                    <Table.Body>
                      {/* for edit bundle code  */}
                      {cartEditIcon && bundleObj.bundleItemCheckbox ? (
                        <Table.Row>
                          <Table.Cell colspan="2">
                            <Checkbox
                              style={{ "font-size": "11.3px" }}
                              className="custome-checkbox rentalEditcart"
                              name="bundleItemCheckbox"
                              businessType="selectedBundleItems"
                              checked={
                                checkAllItemDetails
                                  ? true
                                  : bundleObj.bundleItemCheckbox
                                  ? true
                                  : false
                              }
                              onChange={(e, { name, checked, businessType }) =>
                                stateRef.onHandleChangeOfCheckbox(
                                  e,
                                  { name, checked, businessType },
                                  bundleObj,
                                  bundleIndex,
                                  bundleObj.TotalAmount
                                )
                              }
                            />
                            <label
                              title={bundleObj.BundleTitle}
                              className="rentalPrice first"
                              onClick={() =>
                                stateRef.backTobundleItemSelect(bundleObj)
                              }
                            >
                              {bundleObj.NumberOfBookings} X{" "}
                              <span className="orange-color">
                                {commonFunctions.truncateText(
                                  bundleObj.BundleTitle,
                                  9
                                )}
                              </span>
                            </label>
                          </Table.Cell>

                          <Table.Cell
                            textAlign="right"
                            className="collapseIcon"
                          >
                            ${" "}
                            <input
                              type="number"
                              value={bundleObj.TotalAmount}
                              onChange={(e) =>
                                onBundlePriceChange(e, bundleIndex)
                              }
                              onBlur={() =>
                                onPriceSaveOfAllTypes(
                                  bundleObj,
                                  bundleObj.TotalAmount
                                )
                              }
                              className="cartDepositInput"
                            />
                            {!bundleObj.showIcon ? (
                              <Icon
                                name="chevron right"
                                className="orange-color "
                                style={{ "font-size": "14px" }}
                                size={"large"}
                                onClick={() =>
                                  stateRef.handleShowHideField(
                                    "selectedBundleItems",
                                    bundleIndex
                                  )
                                }
                              />
                            ) : (
                              <Icon
                                name="chevron down"
                                className="orange-color"
                                style={{ "font-size": "14px" }}
                                size={"large"}
                                onClick={() =>
                                  stateRef.handleShowHideField(
                                    "selectedBundleItems",
                                    bundleIndex
                                  )
                                }
                              />
                            )}
                          </Table.Cell>
                        </Table.Row>
                      ) : (
                        <Table.Row>
                          <Table.Cell colspan="2">
                            {/* for paid item code  */}
                            {bundleObj.paidAmount ? (
                              <Checkbox
                                style={{ "font-size": "11.3px" }}
                                className="custome-paid-checkbox"
                                checked={bundleObj.paidAmount}
                                label={
                                  <label>
                                    {bundleObj.NumberOfBookings} X{" "}
                                    <span className="orange-color">
                                      {bundleObj.BundleTitle}
                                    </span>
                                  </label>
                                }
                              />
                            ) : (
                              <Checkbox
                                style={{ "font-size": "11.3px" }}
                                className="custome-checkbox labelInputPayment"
                                name="bundleItemCheckbox"
                                businessType="selectedBundleItems"
                                checked={
                                  checkAllItemDetails
                                    ? true
                                    : bundleObj.bundleItemCheckbox
                                    ? true
                                    : false
                                }
                                onChange={(
                                  e,
                                  { name, checked, businessType }
                                ) =>
                                  stateRef.onHandleChangeOfCheckbox(
                                    e,
                                    { name, checked, businessType },
                                    bundleObj,
                                    bundleIndex,
                                    bundleObj.TotalAmount
                                  )
                                }
                                label={
                                  <label>
                                    {bundleObj.NumberOfBookings} X{" "}
                                    <span className="orange-color">
                                      {bundleObj.BundleTitle}
                                    </span>
                                  </label>
                                }
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell
                            textAlign="right"
                            className="collapseIcon"
                          >
                            {commonFunctions.isNumberInteger(
                              bundleObj.TotalAmount
                            )}
                            {!bundleObj.showIcon ? (
                              <Icon
                                name="chevron right"
                                className="orange-color "
                                style={{ "font-size": "14px" }}
                                size={"large"}
                                onClick={() =>
                                  stateRef.handleShowHideField(
                                    "selectedBundleItems",
                                    bundleIndex
                                  )
                                }
                              />
                            ) : (
                              <Icon
                                name="chevron down"
                                className="orange-color"
                                style={{ "font-size": "14px" }}
                                size={"large"}
                                onClick={() =>
                                  stateRef.handleShowHideField(
                                    "selectedBundleItems",
                                    bundleIndex
                                  )
                                }
                              />
                            )}
                          </Table.Cell>
                        </Table.Row>
                      )}
                      {bundleObj.showIcon && bundleObj.StartTime && (
                        <>
                          <Table.Row className="withoutCheckBoxStyle">
                            <Table.Row>
                              <span
                                className="nonCheckBoxes"
                              >
                                <b className="orange-color">
                                  Single Bundle Contains:-
                                </b>
                              </span>
                            </Table.Row>
                            {bundleObj.BundleProducts &&
                              bundleObj.BundleProducts.map((bundleItem) => {
                                return (
                                  <p className="nonCheckBoxes">
                                    <span>
                                      {bundleItem.quantity} X{" "}
                                      <span className="orange-color">
                                        {" "}
                                        {bundleItem.ProductName}
                                      </span>
                                    </span>
                                  </p>
                                );
                              })}
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>
                              {" "}
                              <span className="nonCheckBoxes">{`${moment(
                                `${bundleObj.StartDate}`,
                                "YYYY-MM-DD"
                              ).format("MMM D")} ${bundleObj.StartTime}`}</span>
                              {/* <Checkbox
                                                        className="custome-checkbox"
                                                        name="bundleTimeCheckbox"
                                                        businessType="selectedBundleItems"
                                                        checked={checkAllItemDetails ? true : bundleObj.bundleTimeCheckbox ? true : false}

                                                        label={`${(moment(`${bundleObj.StartDate}`, 'YYYY-MM-DD').format('MMM D'))} ${bundleObj.StartTime}`}
                                                        onChange={(e, { name, checked, businessType }) => stateRef.onHandleChangeOfCheckbox(e, { name, checked, businessType }, bundleObj, bundleIndex)}
                                                    /> */}
                            </Table.Cell>
                          </Table.Row>
                        </>
                      )}
                    </Table.Body>
                  </Table>
                </>
              );
            }
          )}
        {/******************************************  SeasonPass items table data ************************************************* */}
        {orderState.currentOrder.seasonPassItem.length > 0 &&
          orderState.currentOrder.seasonPassItem.map(
            (seasonItem, seasonIndex) => {
              return (
                <>
                  <Divider fitted />
                  <Table
                    basic="very"
                    className="borderlessTable"
                    compact="very"
                    size="small"
                  >
                    <Table.Body>
                      {seasonItem.AreQuestionsAvailableForEditing &&
                        !seasonItem.paidAmount && (
                          <Table.Row>
                            <Table.Cell
                              colspan="2"
                              className="orange-color"
                              title="Edit Questions"
                              onClick={() =>
                                stateRef.onSeasonPassEditQuestionClick(
                                  seasonItem,
                                  "Season Pass"
                                )
                              }
                            >
                              <span className="question-cart"> Q & A</span>
                            </Table.Cell>
                            <Table.Cell
                              textAlign="right"
                              className="orange-color"
                            >
                              <Icon
                                name="edit"
                                className="custom-question-icon"
                                title="Edit Questions"
                                onClick={() =>
                                  stateRef.onSeasonPassEditQuestionClick(
                                    seasonItem,
                                    "Season Pass"
                                  )
                                }
                              />
                            </Table.Cell>
                          </Table.Row>
                        )}
                      {/* for edit SeasonPass item code  */}
                      {cartEditIcon && seasonItem.seasonItemCheckbox ? (
                        <Table.Row>
                          <Table.Cell colspan="2">
                            <Checkbox
                              style={{ "font-size": "11.3px" }}
                              className="custome-checkbox rentalEditcart labelInputPayment"
                              name="seasonItemCheckbox"
                              businessType="seasonPassItem"
                              addOnName="seasonItemAddon"
                              businessTypeAddon="SeasonPassAddons"
                              checked={
                                checkAllItemDetails
                                  ? true
                                  : seasonItem.seasonItemCheckbox
                                  ? true
                                  : false
                              }
                              onChange={(
                                e,
                                {
                                  name,
                                  checked,
                                  businessType,
                                  businessTypeAddon,
                                  addOnName,
                                }
                              ) =>
                                stateRef.onHandleChangeOfCheckbox(
                                  e,
                                  {
                                    name,
                                    checked,
                                    businessType,
                                    businessTypeAddon,
                                    addOnName,
                                  },
                                  seasonItem,
                                  seasonIndex,
                                  seasonItem.TotalAmount
                                )
                              }
                            />
                            <label
                              title={seasonItem.ItemName}
                              className="rentalPrice first"
                              onClick={() =>
                                stateRef.backToSeasonPassSelect(seasonItem)
                              }
                            >
                              {seasonItem.Quantity} X{" "}
                              <span className="orange-color">
                                {commonFunctions.truncateText(
                                  seasonItem.ItemName,
                                  9
                                )}
                              </span>
                            </label>
                          </Table.Cell>
                          <Table.Cell
                            textAlign="right"
                            className="collapseIcon"
                          >
                            ${" "}
                            <input
                              type="number"
                              value={seasonItem.TotalAmount}
                              onChange={(e) =>
                                onSeasonPassPriceChange(e, seasonIndex)
                              }
                              onBlur={() =>
                                onPriceSaveOfAllTypes(
                                  seasonItem,
                                  seasonItem.TotalAmount
                                )
                              }
                              className="cartDepositInput"
                            />
                            {!seasonItem.showIcon ? (
                              <Icon
                                name="chevron right"
                                className="orange-color "
                                style={{ "font-size": "14px" }}
                                size={"large"}
                                onClick={() =>
                                  stateRef.handleShowHideField(
                                    "seasonPassItem",
                                    seasonIndex
                                  )
                                }
                              />
                            ) : (
                              <Icon
                                name="chevron down"
                                className="orange-color"
                                style={{ "font-size": "14px" }}
                                size={"large"}
                                onClick={() =>
                                  stateRef.handleShowHideField(
                                    "seasonPassItem",
                                    seasonIndex
                                  )
                                }
                              />
                            )}
                          </Table.Cell>
                        </Table.Row>
                      ) : (
                        <Table.Row>
                          <Table.Cell colspan="2">
                            {/* for paid SeasonPass item code */}
                            {seasonItem.paidAmount ? (
                              <Checkbox
                                style={{ "font-size": "11.3px" }}
                                className="custome-paid-checkbox"
                                checked={seasonItem.paidAmount}
                                label={
                                  <label>
                                    {seasonItem.Quantity} X{" "}
                                    <span className="orange-color">
                                      {seasonItem.ItemName}
                                    </span>
                                  </label>
                                }
                              />
                            ) : (
                              <Checkbox
                                style={{ "font-size": "11.3px" }}
                                className="custome-checkbox labelInputPayment"
                                name="seasonItemCheckbox"
                                businessType="seasonPassItem"
                                addOnName="seasonItemAddon"
                                businessTypeAddon="SeasonPassAddons"
                                checked={
                                  checkAllItemDetails
                                    ? true
                                    : seasonItem.seasonItemCheckbox
                                    ? true
                                    : false
                                }
                                label={
                                  <label>
                                    {seasonItem.Quantity} X{" "}
                                    <span className="orange-color">
                                      {seasonItem.ItemName}
                                    </span>
                                  </label>
                                }
                                onChange={(
                                  e,
                                  {
                                    name,
                                    checked,
                                    businessType,
                                    businessTypeAddon,
                                    addOnName,
                                  }
                                ) =>
                                  stateRef.onHandleChangeOfCheckbox(
                                    e,
                                    {
                                      name,
                                      checked,
                                      businessType,
                                      businessTypeAddon,
                                      addOnName,
                                    },
                                    seasonItem,
                                    seasonIndex,
                                    seasonItem.TotalAmount
                                  )
                                }
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell
                            textAlign="right"
                            className="collapseIcon"
                          >
                            {commonFunctions.isNumberInteger(
                              seasonItem.TotalAmount
                            )}

                            {!seasonItem.showIcon ? (
                              <Icon
                                name="chevron right"
                                className="orange-color "
                                style={{ "font-size": "14px" }}
                                size={"large"}
                                onClick={() =>
                                  stateRef.handleShowHideField(
                                    "seasonPassItem",
                                    seasonIndex
                                  )
                                }
                              />
                            ) : (
                              <Icon
                                name="chevron down"
                                className="orange-color"
                                style={{ "font-size": "14px" }}
                                size={"large"}
                                onClick={() =>
                                  stateRef.handleShowHideField(
                                    "seasonPassItem",
                                    seasonIndex
                                  )
                                }
                              />
                            )}
                          </Table.Cell>
                        </Table.Row>
                      )}

                      {seasonItem.showIcon &&
                        seasonItem?.SeasonPassItemDetails &&
                        seasonItem.SeasonPassItemDetails.map((seasonId) => {
                          return (
                            <Table.Row className="withoutCheckBoxStyle">
                              <Table.Cell />
                              <Table.Cell>Id:</Table.Cell>
                              <Table.Cell
                                id={seasonId.OrderSeasonPassDetailId}
                                textAlign="right"
                              >
                                {seasonId.Id}
                              </Table.Cell>
                            </Table.Row>
                          );
                        })}

                      {/* add on of seasonPass  */}
                      {seasonItem.SeasonPassAddons &&
                        seasonItem.SeasonPassAddons.map(
                          (seasonAddonObj, seasonAddonIndex) => {
                            return (
                              <>
                                <Table.Row>
                                  <Table.Cell colspan="2">
                                    {/* for paid item code  */}
                                    {seasonAddonObj.paidAmount ? (
                                      <Checkbox
                                        className="custome-paid-checkbox "
                                        checked={seasonAddonObj.paidAmount}
                                        label={
                                          <label>
                                            {seasonAddonObj.Quantity} X{" "}
                                            <span className="orange-color">
                                              {seasonAddonObj.AddOnTitle}
                                            </span>
                                          </label>
                                        }
                                      />
                                    ) : (
                                      <Checkbox
                                        className="custome-checkbox labelInputPayment"
                                        name="seasonItemAddon"
                                        businessType="seasonPassItem"
                                        businessTypeAddon="SeasonPassAddons"
                                        businessTypeName="seasonItemCheckbox"
                                        checked={
                                          checkAllItemDetails
                                            ? true
                                            : seasonAddonObj.seasonItemAddon
                                            ? true
                                            : false
                                        }
                                        onChange={(
                                          e,
                                          {
                                            name,
                                            checked,
                                            businessType,
                                            businessTypeAddon,
                                            businessTypeName,
                                          }
                                        ) =>
                                          stateRef.onHandleChangeOfAddOnCheckbox(
                                            e,
                                            {
                                              name,
                                              checked,
                                              businessType,
                                              businessTypeAddon,
                                              businessTypeName,
                                            },
                                            seasonIndex,
                                            seasonAddonIndex,
                                            seasonAddonObj.TotalAmount
                                          )
                                        }
                                        label={
                                          <label>
                                            {seasonAddonObj.Quantity} X{" "}
                                            <span className="orange-color">
                                              {seasonAddonObj.AddOnTitle}
                                            </span>
                                          </label>
                                        }
                                      />
                                    )}
                                  </Table.Cell>
                                  <Table.Cell textAlign="right">
                                    {commonFunctions.isNumberInteger(
                                      seasonAddonObj.TotalAmount
                                    )}
                                  </Table.Cell>
                                </Table.Row>
                              </>
                            );
                          }
                        )}
                    </Table.Body>
                  </Table>
                </>
              );
            }
          )}

        {/****************************************** order  add on's added mapping here *********************************/}
        {orderState.currentOrder.pricings &&
          orderState.currentOrder.pricings.Addons &&
          orderState.currentOrder.pricings.Addons.map(
            (orderAddOnObj, addOnIndex) => {
              return (
                <>
                  <Divider fitted />
                  <Table
                    basic="very"
                    className="borderlessTable"
                    compact="very"
                    size="small"
                  >
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell colspan="2">
                          {/* for paid item code  */}
                          {orderAddOnObj.paidAmount ? (
                            <Checkbox
                              className="custome-paid-checkbox"
                              checked={orderAddOnObj.paidAmount}
                              label={
                                <label>
                                  {orderAddOnObj.Quantity} X{" "}
                                  <span className="orange-color">
                                    {orderAddOnObj.AddOnTitle}
                                  </span>
                                </label>
                              }
                            />
                          ) : (
                            <Checkbox
                              className="custome-checkbox"
                              name="orderAddonCheckbox"
                              checked={
                                checkAllItemDetails
                                  ? true
                                  : orderAddOnObj.orderAddonCheckbox
                                  ? true
                                  : false
                              }
                              onChange={(e, { checked }) =>
                                stateRef.onHandleChangeOfOrderAddon(
                                  e,
                                  { checked },
                                  orderAddOnObj.TotalPrice,
                                  addOnIndex
                                )
                              }
                              label={
                                <label>
                                  {orderAddOnObj.Quantity} X{" "}
                                  <span className="orange-color">
                                    {orderAddOnObj.AddOnTitle}
                                  </span>
                                </label>
                              }
                            />
                          )}
                        </Table.Cell>
                        <Table.Cell textAlign="right">
                          {commonFunctions.isNumberInteger(
                            orderAddOnObj.TotalPrice
                          )}
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </>
              );
            }
          )}

        {/* -----order deposit ---- */}

        {orderState.currentOrder.orderDeposit !== 0 && (
          <>
            <Divider fitted />
            <Table
              basic="very"
              className="borderlessTable"
              compact="very"
              size="small"
            >
              <Table.Body>
                <Table.Row>
                  <Table.Cell colspan="2">
                    {orderState.currentOrder.paidOrderDepositAmount ? (
                      <Checkbox
                        className="custome-paid-checkbox rentalEditcart"
                        checked={orderState.currentOrder.paidOrderDepositAmount}
                        label={
                          <label>
                            <span className="orange-color">Order Deposit</span>
                          </label>
                        }
                      />
                    ) : (
                      <Checkbox
                        className="custome-checkbox"
                        name="orderAddonCheckbox"
                        // checked={checkAllItemDetails ? true : orderState.currentOrder.orderDepositCheckbox ? true : false}
                        checked={orderState.currentOrder.orderDepositCheckbox}
                        onChange={(e, { checked }) =>
                          stateRef.onOrderDepositChange(
                            e,
                            { checked },
                            orderState.currentOrder.orderDeposit
                          )
                        }
                        label={
                          <label>
                            <span className="orange-color">Order Deposit</span>
                          </label>
                        }
                      />
                    )}
                  </Table.Cell>
                  <Table.Cell textAlign="right" className="orange-color">
                    <Icon
                      name="minus circle"
                      title={
                        orderState.currentOrder.paidOrderDepositAmount
                          ? ""
                          : "Delete"
                      }
                      disabled={orderState.currentOrder.paidOrderDepositAmount}
                      onClick={() =>
                        stateRef.onOrderDepositBtndeleteModalCLick()
                      }
                    />
                    {commonFunctions.isNumberInteger(
                      orderState.currentOrder.orderDeposit
                    )}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </>
        )}
      </Fragment>
    );
  } else {
    return null;
  }
};

export default ItemDetailsGrid;
