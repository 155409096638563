import React, { Component } from "react";
import { Icon, Checkbox } from "semantic-ui-react";
import { commonFunctions, } from "../../../shared/functional/global-import";
import { DataTable } from '../../../shared/components/organisms';

class RequirementGrid extends Component {
    childsChild = React.createRef();
    render() {
        const { fullState, allFunction } = this.props;
        return (
            <DataTable
                ref={this.childsChild}
                getApiName="GETREQUIREMENTS"
                getGridDataParamters={{ "IsActive": null }}
                searchOption={{ show: true, placeHolder: "Search requirement" }}
                defaultSort={{ sortColumn: "modifiedDate", sortOrder: "desc" }}
                
                gridName="requirementsGrid"
                screenName="requirements"
                fullState={fullState}
                allFunction={allFunction}
                tableHeight="250px"
                emptyGrid="No records to show"
                columns={[
                    {
                        headerName: "Title",
                        fieldName: "requirementTitle",
                        isSorting: true,
                        className: "textOverflowHidden",
                        Cell: (props) => { 
                            return (
                                <span title={props.requirementTitle}>{props.requirementTitle}</span>
                              );
                           },
                    },
                    {
                        headerName: "Active",
                        fieldName: "isActive",
                        isSorting: true,
                        Cell: (props) => {
                            return (
                                <Checkbox
                                    className="custom-toggle"
                                    checked={props.isActive}
                                    onClick={(data, r) =>
                                    commonFunctions.activateDeactivateToggle(allFunction, props.requirementTitle, props.requirementId,"requirementId", props.isActive)
                                    }
                                ></Checkbox>
                            );
                        },
                    },
                    {
                        headerName: "Action",
                        fieldName: "action",
                        isSorting: false,
                        Cell: (props) => {
                            return (
                                <React.Fragment>
                                    <Icon
                                        name="pencil alternate"
                                        size="large"
                                        title="Edit"
                                        className="deepviolet-color pointer"
                                        onClick={() => allFunction.editRequirement(props)} />
                                    <Icon
                                        name="trash alternate"
                                        size="large"
                                        className="orange-color pointer"
                                        title="Delete"
                                        link
                                        onClick={() => commonFunctions.deleteModalMessage(allFunction, props.requirementTitle, props.requirementId,"requirementId")} />
                                </React.Fragment>
                            );
                        },
                    },
                ]}
            />
        );
    }
}
export default RequirementGrid;
