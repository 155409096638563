import React, { Component } from "react";
import { Icon, Checkbox, Image } from "semantic-ui-react";
import { commonFunctions, env, connect, bindActionCreators, actions, withRouter } from "../../../../shared/functional/global-import";
import { DataTableWithOutApi } from '../../../../shared/components/organisms';

import { AddActivityModal, AddCategoryModal, AddItemModal, AddBundleModal, CommonDeleteUpdateModal, AddSeasonPassModal } from "../../../../shared/components";

class RentalItemList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productCategoryDetails: [], activateModalopen: false,
            activityDetail: [], open: false, addcateStatus: false, openModal: false,
            itemStatus: false, categoryId: "", productId: "", productDetailId: -1, updateButton: false,
            buttonName: "", seasonPassStatus: false
        }

    }
    AddCategoryModal = (categoryId, addEdit, type) => {
        this.setState({
            addcateStatus: !this.state.addcateStatus,
            openModal: false,
            categoryId: categoryId,
            editCategoryModal: true,
            addOrEdit: addEdit,
            categoryOrSub: type,
        });
    }

    addCategoryModalClose = () => {
        this.setState({ addcateStatus: false });
    }

    AddItemModal = (productId, itemType) => {

        if (itemType === "Rental") {
            this.setState({
                editItemModal: true,
                productId: productId,
                itemType: itemType,
                itemStatus: !this.state.itemStatus,
            });
        }
        else {
            this.setState({
                retailId: productId,
                itemType: itemType,
                editRetailDetail: true,
                itemStatus: !this.state.itemStatus
            });
        }
    };

    AddActivityModal = (productId) => {
        this.setState({
            activityStatus: !this.state.activityStatus,
            activityId: productId,
        });
    };

    AddBundleModal = (productId) => {
        this.setState({
            bundleId: productId,
            editBundleModal: true,
            bundleStatus: !this.state.bundleStatus,
        });
    };
    SeasonPassAddModal = (productId) => {
        this.setState({
            seasonPassId: productId,
            editSesonPassModal: true,
            seasonPassStatus: !this.state.seasonPassStatus,
        });
    };

    onEditListIcon = (category) => {

        const activityGlobalId = (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "BusinessCategoryType", "Activity"))?.globalCodeId

        const bundleGlobalId = (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "BusinessCategoryType", "Bundle"))?.globalCodeId

        const sesonpassGlobalId = (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "BusinessCategoryType", "Season Pass")).globalCodeId

        if (category.productId > 0 && category.type === "Rental" ||
            category.type === "Retail-Trackable" || category.type === "Retail-Bulk") {
            this.AddItemModal(category.productId, category.type);
        }
        if (category.type === "Category") {
            this.AddCategoryModal(category.categoryId, "Edit", "Category");
        }
        if (category.type === "Subcategory") {
            this.AddCategoryModal(category.categoryId, "Edit", "Sub Category");
        }
        if (this.props.businessCategoryId === activityGlobalId && category.type === "Activity") {
            this.AddActivityModal(category.productId);
            this.setState({ updateButton: true });
        }
        if (this.props.businessCategoryId === bundleGlobalId && category.type === "Bundle") {
            this.AddBundleModal(category.productId);
        }
        if (this.props.businessCategoryId === sesonpassGlobalId && category.type === "Season Pass") {
            this.SeasonPassAddModal(category.productId);
        }
    }
    activateModal = (e, b, r) => {
        const message = "Do you want to"
        const messagetoShowInModal = (r.checked ? "activate" : "deactivate") + "  " + "\"" + e.name + "\"" + " ?"
        this.setState({ activateModalopen: true, buttonName: "Update", messageToShow: `${message} ${messagetoShowInModal}`, productId: e.productId, categoryId: e.categoryId, isActive: r.checked })
    }
    closeActiveModal = () => {
        this.setState({ activateModalopen: !this.state.activateModalopen });
    };
    onCategoryClick = (category) => {
        let inventoryDetail = this.props.global.inventoryDetail;
        inventoryDetail.categoryId = category.categoryId;
        inventoryDetail.productId = category.productId;
        inventoryDetail.type = category.type;
        inventoryDetail.parentId = category.categoryId;
        this.setState({ newOrderProductCategoryDetail: category }, () => { this.props.getBreadcrumb() });
    }
    componentDidUpdate(prevProps, prevState) {
        const breadcrumbData = prevProps.breadcrumbData;
        if (this.state.isSearchActive !== prevProps.searchData) {
            let inventoryDetail = this.props.global.inventoryDetail;
            if (prevProps.searchData === true) {
                inventoryDetail.inventoryBreadcrumb = [inventoryDetail.inventoryBreadcrumb[0]];
                breadcrumbData.map((breadcrumb) => (
                    inventoryDetail.inventoryBreadcrumb.push({ level: breadcrumb.level, breadcrumbName: breadcrumb.categoryName, type: breadcrumb.type, categoryId: breadcrumb.categoryId, parentId: breadcrumb.categoryId, productId: breadcrumb.productId, })
                ))
            }
            this.props.actions.storeInventoryDetail(inventoryDetail);
            this.setState({ isSearchActive: prevProps.searchData })
            this.props.getProductCategoryDetail(true)
        }
        if (prevProps.productId > 0 && prevProps.searchData === true) {
            if (!this.props.handleShowGrid) {
                // this.props.getTraitProduct()
                this.props.history.push({
                    pathname: `${env.PUBLIC_URL}/inventory/inventory-item-list`,
                });
            }
            else {
                this.props.handleShowGrid(this.state.newOrderProductCategoryDetail)
            }
        }
    }

    render() {
        const { addcateStatus, bundleId, seasonPassId, retailId, productId, editCategoryModal, categoryId, itemStatus, activityStatus, bundleStatus, seasonPassStatus, itemDetail, activityDetail, activityId, categoryOrSub, addOrEdit, buttonName, messageToShow, activateModalopen } = this.state;
        const { getProductCategoryDetail, productCategoryDetails, openCategory, categoryList, onUpdateBusinessByTypeToggle, onHandleSorting, gridDataLoading, fetchMoreData, hasMore } = this.props;

        const rentalGlobalId = (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "BusinessCategoryType", "Rental"))?.globalCodeId

        const activityGlobalId = (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "BusinessCategoryType", "Activity"))?.globalCodeId

        const bundleGlobalId = (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "BusinessCategoryType", "Bundle"))?.globalCodeId

        const sesonpassGlobalId = (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "BusinessCategoryType", "Season Pass")).globalCodeId
        return (
            <>
                <DataTableWithOutApi
                    gridName="CategoryGrid"
                    screenName="category-view"
                    data={productCategoryDetails}
                    onHandleSorting={onHandleSorting}
                    sortingArrow={this.props.categoryList.sortingArrow}
                    sortColumn={this.props.categoryList.sortColumn}
                    tableHeight="500px"
                    emptyGrid="No records to show"
                    hasMore={hasMore}
                    fetchMoreData={fetchMoreData}
                    gridDataLoading={gridDataLoading}
                    isTofilterTableHeader={this.props.businessCategoryId === rentalGlobalId ? false : true}
                    //businessCategoryId= {this.props.businessCategoryId}
                    columns={[
                        {
                            headerName: "Image",
                            fieldName: "titleName",
                            isSorting: false,
                            Cell: (props) => {
                                return (
                                    <Image src={commonFunctions.concatenateImageWithAPIUrl(props.image)} rounded size="mini"
                                        onClick={() => this.onCategoryClick(props)} />


                                )
                            }
                        },
                        {
                            headerName: "Name",
                            fieldName: "name",
                            className: "textOverflowHidden",
                            isSorting: true,
                            Cell: (props) => {
                                return (
                                    <span onClick={() => this.onCategoryClick(props)} title={props.name} className="orange-color">{props.name}</span>
                                )
                            }
                        },
                        {
                            headerName: "Type",
                            fieldName: "type",
                            isSorting: true,
                            Cell: (props) => {
                                const passes = props.type === "Season Pass" ? "Passes":props.type
                                return (
                                    <span onClick={() => this.onCategoryClick(props)} className="orange-color">{passes} </span>
                                )
                            }
                        },
                        {
                            headerName: "SCat",
                            fieldName: "SCat",
                            isSorting: true,
                            //  className: "textCenter",
                            Cell: (props) => {
                                return (
                                    props.count === 0 ? <div> - </div> :
                                        <span>{props.count} </span>
                                )
                            }
                        },
                        {
                            headerName: this.props.businessCategoryId === activityGlobalId ? "Total"
                                : this.props.businessCategoryId === bundleGlobalId ? "Total"
                                    : "Total",
                            fieldName: "itemCount",
                            // className: "textCenter",
                            isSorting: true,
                            Cell: (props) => {
                                return (
                                    props.itemCount === 0 ? <div> - </div> :
                                        <span>{props.itemCount} </span>
                                )
                            }
                        },


                        {
                            headerName: "In",
                            fieldName: "In",
                            // className: "textCenter",
                            isSorting: true,
                            Cell: (props) => {
                                return (
                                    // props.itemCount === 0 ? <div> - </div> :
                                    <span>{props.itemIn} </span>
                                )
                            }



                        },
                        {
                            headerName: " Out",
                            fieldName: "Out",
                            // className: "textCenter",
                            isSorting: true,
                            Cell: (props) => {
                                return (
                                    // props.itemCount === 0 ? <div> - </div> :
                                    <span>{props.itemOut} </span>
                                )
                            }
                        },
                        {
                            headerName: "Active",
                            fieldName: "isActive",
                            isSorting: true,
                            Cell: (props) => {
                                return (
                                    <Checkbox
                                        className="custom-toggle"
                                        checked={props.isActive ? true : false} value={categoryList.isActive === props.isActive}
                                        onChange={(data, r) => this.activateModal(props, data = "Isctive", r)} />
                                );
                            },
                        },
                        {
                            headerName: "Action",
                            fieldName: "Action",
                            isSorting: false,
                            Cell: (props) => {
                                return (
                                    <React.Fragment>
                                        <Icon
                                            name="pencil alternate"
                                            size="large"
                                            title="Edit"
                                            className="deepviolet-color pointer"
                                            onClick={() => this.onEditListIcon(props)}
                                        />
                                        <Icon
                                            name="trash alternate"
                                            size="large"
                                            className="orange-color pointer"
                                            title="Delete"
                                            link
                                            onClick={() => openCategory(props)}
                                        />
                                    </React.Fragment>
                                );
                            },
                        },
                    ]}
                />
                {/* </div>
                        </InfiniteScroll> */}

                <>
                    {addcateStatus &&
                        <AddCategoryModal
                            editCategoryModal={editCategoryModal}
                            categoryId={categoryId}
                            categoryOrSub={categoryOrSub}
                            addOrEdit={addOrEdit}
                            openModal={addcateStatus}
                            closeModal={this.addCategoryModalClose}
                            getProductCategoryDetail={getProductCategoryDetail}
                        />
                    }
                    {itemStatus &&
                        <AddItemModal
                            productId={productId}
                            editItemModal={this.state.editItemModal}
                            itemDetail={itemDetail}
                            openModal={itemStatus}
                            getProductCategoryDetail={this.props.getProductCategoryDetail}
                            editRetailDetail={this.state.editRetailDetail}
                            itemType={this.state.itemType}
                            retailId={retailId}
                            closeModal={this.AddItemModal}
                        />
                    }
                    {activityStatus &&
                        <AddActivityModal
                            openModal={activityStatus}
                            closeModal={this.AddActivityModal}
                            activityDetail={activityDetail}
                            activityId={activityId}
                            editActivityModal={true}
                            getProductCategoryDetail={this.props.getProductCategoryDetail}
                        />
                    }
                    {AddBundleModal &&
                        <AddBundleModal
                            editBundleModal={this.state.editBundleModal}
                            bundleId={bundleId}
                            openModal={bundleStatus}
                            closeModal={this.AddBundleModal}
                            getProductCategoryDetail={getProductCategoryDetail}
                        />
                    }
                    {seasonPassStatus &&
                        <AddSeasonPassModal
                            editSesonPassModal={this.state.editSesonPassModal}
                            seasonPassId={seasonPassId}
                            openModal={seasonPassStatus}
                            closeModal={this.SeasonPassAddModal}
                            getProductCategoryDetail={getProductCategoryDetail}
                        />}

                    <CommonDeleteUpdateModal
                        onConfirm={() => onUpdateBusinessByTypeToggle(productId, categoryId, this)}
                        onClose={this.closeActiveModal}
                        confirmationButtonText={buttonName}
                        header={messageToShow}
                        isModalOpen={activateModalopen}
                        cancelButtonText={'Cancel'}
                    />
                </>
            </>
        );
    }
}
const mapStateToProps = state => {
    return {
        api: state.api,
        global: state.global,
        auth: state.auth
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            apiCall: bindActionCreators(actions.apiCall, dispatch),
            storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
            storeInventoryDetail: bindActionCreators(actions.storeInventoryDetail, dispatch),
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RentalItemList));