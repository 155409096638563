import React, { Component } from "react";
import { Button, Grid, Modal, Header } from "semantic-ui-react";

class RetaiInfoModal extends Component {

    render() {
        const { retailDescription } = this.props;
        const description = retailDescription && (JSON.parse(retailDescription)) && (JSON.parse(retailDescription))[0] && (JSON.parse(retailDescription))[0].ItemDescription
        const customFields = retailDescription && (JSON.parse(retailDescription)) && (JSON.parse(retailDescription))[0] && (JSON.parse(retailDescription))[0].Customfiels
        
        return (
            <Modal
                open={this.props.openModal}
                closeIcon
                onClose={this.props.closeModal}
                size={"small"}
            >
                <Modal.Content>
                    <Grid>
                        {description && description !== "" &&
                            <Grid.Column width={16}>
                                <Header as="h4">Description :</Header>
                                <p> {description}</p>
                            </Grid.Column>
                        }
                        {description === "" && customFields === undefined &&
                            <Grid.Column width={16}>
                                <Header as="h4"> No Description added</Header>
                            </Grid.Column>
                        }
                        {description === "" && customFields === null &&
                            <Grid.Column width={16}>
                                <Header as="h4"> No Description added</Header>
                            </Grid.Column>
                        }
                        {customFields && customFields.length > 0 && customFields.map((customObj) => {
                            return (
                                <Grid.Column width={16}>
                                    <Header as="h4">{customObj.customField} :</Header>
                                    <p> {customObj.customDescriptions}</p>
                                </Grid.Column>
                            )
                        })}
                        <Grid.Column width={16} textAlign="right">
                            <Button className="blue-btn" onClick={this.props.closeModal}>
                                Back
                            </Button>
                            <Button className="orange-btn" onClick={this.props.closeModal}>
                                Ok
                            </Button>
                        </Grid.Column>
                    </Grid>
                </Modal.Content>
            </Modal>
        );
    }
}
export default RetaiInfoModal;
