import React from 'react'
import { Modal, Input, Grid, Button } from "semantic-ui-react";
import { commonFunctions } from "../../../../../../functional/global-import";

const CreateAddOnsModal = ({ openModal, closeModal, addOnsForm, onHandleChange, handleCreateAddOnsModal, validator,AddOnsLoader }) => {
    return (
        <Modal open={openModal} closeIcon onClose={closeModal} size={"mini"}>
            <Modal.Content scrolling>
            {AddOnsLoader && commonFunctions.showLoader()}
                <Grid>
                    <Grid.Column width={16}>
                        <div class="field">
                            <label className="deepviolet-color">Add Ons</label>
                            <Input
                                fluid
                                name="addOnTitle"
                                data="addOnTitle"
                                value={addOnsForm.addOnTitle}
                                error={validator.message("Title", addOnsForm.addOnTitle, "required")}
                                onChange={(e, { name, value, type, checked, data }) => onHandleChange(e, { name, value, type, checked, data })}
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <div class="field">
                            <label className="deepviolet-color">Amount</label>
                            <Input
                                label={{ basic: true, content: '$' }}
                                labelPosition='left'
                                pattern="[0-9]*"
                                name="addOnPrice"
                                data="addOnPrice"
                                onChange={(e, { name, value, type, checked, data }) => onHandleChange(e, { name, value, type, checked, data })}
                                error={validator.message("amount", addOnsForm.addOnPrice, "required|numeric|min:0,num")}
                                value={addOnsForm.addOnPrice}
                                fluid
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Column width={16} textAlign="right">
                        <Button className="blue-btn" onClick={closeModal}> Cancel </Button>
                        <Button className="orange-btn" onClick={(e) => handleCreateAddOnsModal(e)}> Add Ons </Button>
                    </Grid.Column>
                </Grid>
            </Modal.Content>
        </Modal>
    )
}

export default CreateAddOnsModal
