import React, { Component } from "react";
import { Button, Grid, Modal } from "semantic-ui-react";
import {  Link, bindActionCreators, connect, actions } from "../../../../functional/global-import";


class ExitAppModal extends Component {
    onHandleLogout = async () => {                         
     this.props.actions.logout();
        this.props.closeModal();
        this.props.onClearPress && this.props.onClearPress()
    }
    render() {
        return (
            <Modal open={this.props.openModal} closeIcon onClose={this.props.closeModal} size={"mini"} >
                <Modal.Content>
                    <Grid>
                        <Grid.Column width={14}>
                            <p className="orange-color"> Are you sure you want to log out of the main application? You 
                            will need the master account to re-login.</p>
                        </Grid.Column>
                        <Grid.Column width={16} textAlign="center">
                            <Button className="blue-btn" onClick={this.props.closeModal}>Cancel</Button> &nbsp; &nbsp;
                            <Button as={Link} className="orange-btn" onClick={this.onHandleLogout}>Confirm</Button>
                        </Grid.Column>
                    </Grid>
                </Modal.Content>
            </Modal>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            logout: bindActionCreators(actions.logout, dispatch),
        }
    };
};

export default connect(null, mapDispatchToProps)(ExitAppModal);
