import React, { Component } from "react";
import {
  Button,
  Tab,
  Grid,
  Header,
  Select,
  Table,
  Popup,
  Icon,
} from "semantic-ui-react";

const CustomTimefirst = [
  { key: "01", value: "01", text: "01" },
  { key: "02", value: "02", text: "02" },
  { key: "03", value: "03", text: "03" },
  { key: "04", value: "04", text: "04" },
  { key: "05", value: "05", text: "05" },
  { key: "06", value: "06", text: "06" },
  { key: "07", value: "07", text: "07" },
  { key: "08", value: "08", text: "08" },
  { key: "09", value: "09", text: "09" },
  { key: "10", value: "10", text: "10" },
  { key: "11", value: "11", text: "11" },
  { key: "12", value: "12", text: "12" },
];
const CustomTimetwo = [
  { key: "00", value: "00", text: "00" },
  { key: "05", value: "05", text: "05" },
  { key: "10", value: "10", text: "10" },
  { key: "15", value: "15", text: "15" },
  { key: "20", value: "20", text: "20" },
  { key: "25", value: "25", text: "25" },
  { key: "30", value: "30", text: "30" },
  { key: "35", value: "35", text: "35" },
  { key: "40", value: "40", text: "40" },
  { key: "45", value: "45", text: "45" },
  { key: "50", value: "50", text: "50" },
  { key: "55", value: "55", text: "55" },
];

const CustomTimethree = [
  { key: "am", value: "am", text: "AM" },
  { key: "pm", value: "pm", text: "PM" },
];

class ActivityTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectTime: false,
    };
  }

  renderModalHeader = () => {
    const { bookingSlot, onTimeHandleChange } = this.props;

    return (
      <>
        <Grid>
          <Grid.Column width={16}>
            <Header as="h2" className="maroon-color">
              Start Time
            </Header>
          </Grid.Column>
          <Grid.Column width={4}>
            <Select
              options={CustomTimefirst}
              value={bookingSlot.startTimeHour}
              className="custom-select"
              defaultValue="9"
              name="startTimeHour"
              fluid
              onChange={onTimeHandleChange}
            />
          </Grid.Column>
          <Grid.Column width={5}>
            <Select
              options={CustomTimetwo}
              value={bookingSlot.startTimeMinute}
              className="custom-select"
              defaultValue="m00"
              name="startTimeMinute"
              fluid
              onChange={onTimeHandleChange}
            />
          </Grid.Column>
          <Grid.Column width={5}>
            <Select
              options={CustomTimethree}
              value={bookingSlot.startTimeAm}
              className="custom-select"
              name="startTimeAm"
              defaultValue="am"
              fluid
              onChange={onTimeHandleChange}
            />
          </Grid.Column>
        </Grid>
      </>
    );
  };

  render() {
    const {
      activityTimeSlots,
      handleSelectTime,
      selectedTime,
      bookingSlot,
      onTimeHandleChange,
      ticketsDetails,
      ticketsTable,
      api,
      customfields,
    } = this.props;

    const info =
      activityTimeSlots &&
      activityTimeSlots.info &&
      JSON.parse(activityTimeSlots.info)[0];

    const panes = [
      {
        menuItem: "Book Now",
        render: () => (
          <Tab.Pane attached={false}>
            <Header as="h5">
              <Popup
                trigger={<Icon name="info circle" className="orange-color" />}
              >
                {/* <div>
                  <input className="orangeBox" ></input>Partially booked dates
                </div>
                &nbsp;&nbsp; */}
                <div>
                  <input className="blueBox"></input>Selected date
                </div>
                &nbsp;&nbsp;
                <div>
                  <input className="blankBox" value=" / "></input>Not available
                  date
                </div>
              </Popup>
            </Header>

            {activityTimeSlots &&
              activityTimeSlots.timeForProduct !== null &&
              Object.keys(activityTimeSlots.timeForProduct).length !== 0 &&
              activityTimeSlots.timeForProduct.split(" , ").map((item) => {
                return (
                  <Button
                    name={item}
                    basic
                    className="select-btn mbt"
                    // active={selectedTime.indexOf(item) > -1 ? true : false}
                    active={selectedTime === item ? true : false}
                    onClick={(e) => handleSelectTime(e)}
                  >
                    {item}
                  </Button>
                );
              })}

            {/*  start time and end time */}

            {activityTimeSlots &&
              activityTimeSlots.timeSlotsForProduct !== null &&
              this.renderModalHeader()}

            {activityTimeSlots &&
              activityTimeSlots.slotsForProduct !== null &&
              this.renderModalHeader()}
            {activityTimeSlots &&
              activityTimeSlots.shopHours !== null &&
              this.renderModalHeader()}

            {ticketsTable &&
              activityTimeSlots &&
              activityTimeSlots.slotsForProduct === null && (
                <>
                  {/* {api.isApiLoading &&
                  <Dimmer active inverted> <Loader size='small'></Loader> </Dimmer>
                } */}

                  <Table className="flatfee">
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Total Tickets</Table.HeaderCell>
                        <Table.HeaderCell>Open Tickets</Table.HeaderCell>
                        <Table.HeaderCell>Purchased Tickets </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {ticketsDetails &&
                        ticketsDetails.map((ticketObj) => {
                          return (
                            <Table.Row>
                              <Table.Cell>{ticketObj.totalTickets}</Table.Cell>
                              <Table.Cell>
                                {ticketObj.availableTickets}
                              </Table.Cell>
                              <Table.Cell>{ticketObj.ticketBooked}</Table.Cell>
                            </Table.Row>
                          );
                        })}
                    </Table.Body>
                  </Table>
                </>
              )}
            {/*  */}
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Info",
        render: () => (
          <Tab.Pane attached={false}>
            {info && (
              <Grid>
                {info.Description !== "" && (
                  <Grid.Column width={16}>
                    <p>
                      <strong>Description :</strong> {info.Description}
                    </p>
                  </Grid.Column>
                )}
                {info.WhatIsIncluded !== "" && (
                  <Grid.Column width={16}>
                    <p>
                      <strong>What is included :</strong> {info.WhatIsIncluded}
                    </p>
                  </Grid.Column>
                )}
                {info.WhatToKnow !== "" && (
                  <Grid.Column width={16}>
                    <p>
                      <strong>What to know :</strong> {info.WhatToKnow}
                    </p>
                  </Grid.Column>
                )}
              </Grid>
            )}

            <br></br>
            {customfields &&
              customfields.length > 0 &&
              customfields.map((customObj) => {
                return (
                  <>
                    <p>
                      <strong>{customObj.customField} :</strong>
                    </p>
                    <p>{customObj.customDescriptions}</p>
                    <br></br>
                  </>
                );
              })}
          </Tab.Pane>
        ),
      },
    ];
    return (
      <Tab
        menu={{ secondary: true }}
        panes={panes}
        className="availablity-tab "
      />
    );
  }
}

export default ActivityTab;
