import React, { Component } from "react";
import { Checkbox,Grid } from "semantic-ui-react";
class AvailableOn extends Component {
    state = { 
        
     }
    render() { 
        return ( 
            <Grid columns={3}>
          <Grid.Column  verticalAlign="middle">
            <Checkbox label="In Store" className="custome-checkbox" />
          </Grid.Column>
          <Grid.Column  verticalAlign="middle">
            <Checkbox label="Marketplace" className="custome-checkbox" />
           </Grid.Column>
           <Grid.Column  verticalAlign="middle">
            <Checkbox label="Website" className="custome-checkbox" />
          </Grid.Column>
        </Grid>
         );
    }
}
 
export default AvailableOn;         
        