import React, { Component } from "react";
import {
  Button,
  Grid,
  Modal,
  Header,
  Radio,
  Form,
  Input,
  Select,
  FormRadio,
  Label,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import {
  connect,
  bindActionCreators,
  actions,
  commonFunctions,
  env,
  Notifications,
} from "../../../../../../../functional/global-import";
import { loadStripeTerminal } from "@stripe/terminal-js";
import StripePaymentModal from "../../../../stripe-payment-modal";
import ManualCardModal from "../../../../manual-card-modal";
import SplitSignatureModal from "../../../../../modal/split-signature-modal";
import { orderCommonFunctions } from "../../../order-common-function";

class CreditCardPaymentModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      creatediscount: true,
      saveddiscount: false,
      counterOptions: [],
      creditCardPayment: {
        orderDetailId: null,
        paymentTypeId: null,
        counterId: null,
        amountTypes: null,
        amountPaid: 0,
        isTipAdded: false,
        tipAmount: 0,
        stripeTransactionId: null,
        paymentMethod: "SinglePayment",
      },
      terminal: null,
      discoveredReaders: null,
      connectResult: null,
      paymentIntentId: null,
      isStripeModal: false,
      messageToShow: "",
      tipCheckBox: false,
      tipPrice: null,
      splitValue: 1,
      splitAmount: null,
      chooseReader: null,
      onlineReader: [],
      stripelocationDetails: null,
      isManualCardModalOpen: false,
      isLoading: true,
      balanceDue: null,
      paymentSelectKey: null,
      isSignatureModalOpen: false,
      amountAfterSplit: null,
    };
    this.validator = commonFunctions.initializeSimpleValidator();
  }

  // ----------------Stripe Code -----------------------------------------

  onLoadStripeTirminal = async () => {
    const StripeTerminal = await loadStripeTerminal();
    let token = await this.fetchConnectionToken();
    const onFetchConnectionToken = () => {
      return token;
    };
    if (token) {
      var terminal = StripeTerminal.create({
        onFetchConnectionToken: onFetchConnectionToken,
        onUnexpectedReaderDisconnect: (e) => {
         
        },
      });

      this.setState({ terminal }, () => this.discoverReaderHandler());
    } else {
      this.setState({ isLoading: false, chooseReader: null });
    }
  };

  // 2c. Disconnect from the reader, in case the user wants to switch readers.
  disconnectReader = async () => {
    const { terminal } = this.state;
    await terminal.disconnectReader();
    this.handleClose();

  };

  async fetchConnectionToken() {
    const bodyContent = {
      businessId: this.props.global.bussinessDetail.businessId,
      businessLocationId: parseInt(
        this.props.global.locationSelected.BusinessLocationId
      ),
    };
    const queryString = JSON.stringify(bodyContent);

    return fetch(`${env.API_URL}/Payment/ConnectionToken`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.props.auth.loggedIn.token,
      },
      body: queryString,
    })
      .then(function (response) {
        return response.status !== 500 && response.json();
      })
      .then(function (data) {
        return data[0] && data[0].secret;
      });
  }

  discoverReaderHandler = async () => {
               
    this.setState({ isLoading: true });
    const { terminal } = this.state;
    const { stripelocationDetails, defaultUserDetails } = this.props;

    if (terminal === null || stripelocationDetails === null) {
      return;
    }
    

    var config = {
      simulated: false,
      location: stripelocationDetails[0].stripeLocationId,
    };

    let terminalData = await terminal
      .discoverReaders(config)
      .then(function (discoverResult) {
        if (discoverResult.error) {
          
        } else if (discoverResult.discoveredReaders.length === 0) {
     
        } else {
          const discoveredReaders = discoverResult.discoveredReaders;
     
          return discoveredReaders;
        }
      });
    const onlineReaderDetail = [];
    terminalData &&
      terminalData.map((singlerreaderObj) => {
        if (singlerreaderObj.status === "online") {
          return onlineReaderDetail.push({
            value: singlerreaderObj.label,
            text: singlerreaderObj.label,
            serial_number: singlerreaderObj.serial_number,
          });
        }
      });
    const readerSerialNumber = defaultUserDetails.readerSerialNumber;
    const defaultReader =
      onlineReaderDetail.length > 0
        ? orderCommonFunctions.onSelectingDefaultDetail(
            onlineReaderDetail,
            readerSerialNumber,
            "serial_number"
          )
        : null;
    const discoverReaderDetails =
      terminalData &&
      terminalData.filter(
        (singleReaderObj) => singleReaderObj.status === "online"
      );
    this.setState({
      discoveredReaders: discoverReaderDetails,
      // discoveredReaders: terminalData,
      onlineReader: onlineReaderDetail,
      isLoading: false,
      // chooseReader:
      //   onlineReaderDetail.length > 0 ? onlineReaderDetail[0].value : null,
      chooseReader: defaultReader,
    });
  };

  connectReaderHandler = async () => {
                        ;                     
    const { discoveredReaders, terminal, chooseReader } = this.state;
    let selecReader = discoveredReaders.filter((selectedOne) => {
      return selectedOne.label === chooseReader;
    });

    const selectedReader = selecReader[0];
    
    if (terminal.connectionStatus === "connected") {
      const reader = { reader: selectedReader };
      this.setState(
        {
          connectResult: reader,
          messageToShow: "Connected To Card-Reader collecting Payment !",
        },
        () => this.collectPayment()
      );
    } else {
      let connectReaderData = await terminal
        .connectReader(selectedReader)
        .then(function (connectResult) {
         

          if (connectResult.error) {
           
          } else {
           
         
          }
          return connectResult;
        });

      const newdetails = await connectReaderData;

      if (connectReaderData.error) {
        this.setState(
          {
            isStripeApiRunning: false,
            secondMessage: "Thank You !",
            messageToShow:
              "Make sure reader and POS is on same network (Wi-Fi)",
          },
          () => this.onCloseStripeModal()
        );
      } else {
        this.setState(
          {
            connectResult: connectReaderData,
            messageToShow: "Connected To Card-Reader collecting Payment !",
          },
          () => this.collectPayment()
        );
      }
    }
  };

  onCloseStripeModal = () => {
    setTimeout(() => this.setState({ isStripeModal: false }), 3000);
  };
  fetchPaymentIntentClientSecret(amount) {
               
    const { amountPaid, counterId } = this.state.creditCardPayment;
    const bodyContent = JSON.stringify({
      amount: amountPaid,
      orderId: this.props.orderState.currentOrder.orderId,
      businessId: this.props.global.bussinessDetail.businessId,
      businessLocationId: this.props.global.locationSelected.BusinessLocationId,
      IsTipAdded: this.state.tipCheckBox,
      tipAmount: this.state.tipPrice,
      counterId: counterId,
      actionPerformedBy: this.props.auth.userDetail.emailId,
    });
    return fetch(`${env.API_URL}/Payment/create_payment_intent`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.props.auth.loggedIn.token,
      },
      body: bodyContent,
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        return data[0].clientSecret;
        // return data.client_secret;
      });
  }

  collectPayment = async () => {
                                  ;
    const { creditCardPayment, terminal, tipPrice } = this.state;

    // const { orderState } = this.props;

    // const rehntitNewAmount = ((orderState.currentOrder.rehntitshare / 100) * creditCardPayment.amountPaid)

    // const totalAmount = creditCardPayment.amountPaid >= 25 ? creditCardPayment.amountPaid + (tipPrice === null ? 0 : tipPrice) + rehntitNewAmount : creditCardPayment.amountPaid + (tipPrice === null ? 0 : tipPrice)

    let isTipAddedNow =
      tipPrice === null ? false : tipPrice === 0 ? false : true;
    let totalAmount = isTipAddedNow
      ? tipPrice + creditCardPayment.amountPaid
      : creditCardPayment.amountPaid;

    let intentClientSecret = await this.fetchPaymentIntentClientSecret(
      totalAmount
    );
    terminal.setSimulatorConfiguration({
      testPaymentMethod: "",
      testCardNumber: "",
    });

    const paymentMethodPromise = await terminal.collectPaymentMethod(
      intentClientSecret
    );

    const result = await paymentMethodPromise;
    if (result.error) {
  
    } else {
                         ;
      const confirmResult = await terminal.processPayment(result.paymentIntent);
      // At this stage, the payment can no longer be canceled because we've sent the request to the network.
      if (confirmResult.error) {
                           ;
      } else if (confirmResult.paymentIntent) {
                           ;
        if (confirmResult.paymentIntent.status !== "succeeded") {
          try {
            // Capture the PaymentIntent from your backend client and mark the payment as complete
            const captureResult = await this.capture({
              paymentIntentId: confirmResult.paymentIntent.id,
            });

            const transactionDetails = await captureResult;
            if (transactionDetails.StatusCode === 500) {
              this.setState(
                {
                  isStripeApiRunning: false,
                  messageToShow:
                    "Payment Un-Successful due to some reasons!, Please try other payment options",
                  secondMessage: "Thank You !",
                },
                () => this.onFailedMessageUpdate()
              );
            } else {
                                 ;

              this.updatePaymentSuccess(
                JSON.parse(transactionDetails.stripeobject).latest_charge,
                transactionDetails,
                totalAmount
              );
            }

            return captureResult;
          } catch (e) {
            // Suppress backend errors since they will be shown in logs
            return;
          }
        } else {
         
          this.setState({ singlePayment: " Single Payment  Successful!" });
          return confirmResult;
        }
      }
    }
  };

  onFailedMessageUpdate = (totalAmount) => {
    setTimeout(() => this.setState({ isStripeModal: false }), 3000);
  };

  updatePaymentSuccess = (stripeTrId, transactionDetails, totalAmount) => {
    this.setState(
      {
        creditCardPayment: {
          ...this.state.creditCardPayment,
          stripeTransactionId: stripeTrId,
        },
        isStripeApiRunning: false,
        messageToShow: "Payment Successful!",
        secondMessage: "Thank You !",
        transactionDetails: { KeyId: transactionDetails.transactionDetailId },
      },
      () => this.onNextApiCall(totalAmount)
    );
  };

  onNextApiCall = (totalAmount) => {
    setTimeout(
      () =>
        this.setState({ isStripeModal: false }, () =>
          this.getOrderItemAndCloseModal(totalAmount)
        ),
      3000
    );
  };

  getOrderItemAndCloseModal = (totalAmount) => {
                                               
    this.props.actions.getOrderItems(
      this.props.orderState.currentOrder.orderId
    );
    this.onSplitPaymentSubmit(totalAmount, true);
  };

  onSuccessManualCardPayment = (responseOfManualCardPayment) => {       
                                                              
    this.setState(
      { transactionDetails: { KeyId: responseOfManualCardPayment.keyId } },
      this.onSplitPaymentSubmit(
        responseOfManualCardPayment.amountwithRehntitCharges,
        false,
      )
    );
  };

  onSplitPaymentSubmit = (chargedAmount, isPaymentThroughCard) => {
                                               
    const rehntitNewAmount =(this.props.orderState.currentOrder.rehntitshare / 100) * chargedAmount;
    const rehntitShareAmount1 = chargedAmount + rehntitNewAmount;
    const rehntitShareAmount = rehntitShareAmount1.toFixed(2);
    this.setState({
      isSignatureModalOpen: !this.state.isSignatureModalOpen,
      isManualCardModalOpen: false,
      amountAfterSplit:isPaymentThroughCard?rehntitShareAmount:chargedAmount,
    });
  };

  onSignSubmit = () => {
    const { balanceDue, splitValue, creditCardPayment } = this.state;
    let balanceAfterSplit = balanceDue - creditCardPayment.amountPaid;
    if (splitValue - 1 === 0) {
      this.disconnectReader();
    } else {
      this.setState({
        splitValue: this.state.splitValue - 1,
        balanceDue: balanceAfterSplit,
      });
      this.onLoadStripeTirminal();
    }
  };

  capture = async (paymentIntentId) => {
               
    const { creditCardPayment, tipPrice } = this.state;
    const { orderState, auth, global, allFunction } = this.props;

    let totalItemsSelectedDetails = [];
    if (this.props.value === "Selected Item(s)") {
      let paymentDoneAlready =
        orderState.currentOrder.productItemPaid &&
        JSON.parse(orderState.currentOrder.productItemPaid);
      paymentDoneAlready =
        paymentDoneAlready === null ? [] : paymentDoneAlready;
      let selectedItemDetails =
        orderCommonFunctions.getJsonOfSelectedCheckboxes(orderState);
      totalItemsSelectedDetails = JSON.stringify([
        ...paymentDoneAlready,
        ...selectedItemDetails,
      ]);
    }

    creditCardPayment.id = paymentIntentId.paymentIntentId;
    creditCardPayment.businessId =
      allFunction.props.global.bussinessDetail.businessId;
    creditCardPayment.businessLocationId =
      global.locationSelected.BusinessLocationId;
    creditCardPayment.orderId = orderState.currentOrder.orderId;
    creditCardPayment.actionPerformedBy = auth.userDetail.emailId;
    creditCardPayment.paymentMethod = commonFunctions.getGlobalCodeDetails(
      this.props.global.codes,
      "PaymentMethod",
      "Card"
    ).globalCodeId;

    creditCardPayment.productItemPaid =
      this.props.value === "Selected Item(s)"
        ? totalItemsSelectedDetails
        : orderState.currentOrder.productItemPaid;

    creditCardPayment.isTipAdded =
      tipPrice === null ? false : tipPrice === 0 ? false : true;
    creditCardPayment.tipAmount = tipPrice;

    return fetch(`${env.API_URL}/Payment/capture_payment_intent`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.props.auth.loggedIn.token,
      },
      body: JSON.stringify(creditCardPayment),
    })
      .then(function (response) {
                   
        return response.json();
      })
      .then(function (data) {
                   
        return data;
      });
  };

  // ----------------------------------  stripe code ------------------

  componentDidMount() {
    this.onLoadStripeTirminal();
    this.getCounterDetails();
    const defaultPaymentType =
      this.props.paymentSelect?.length > 0
        ? this.props.paymentSelect?.find((a) => a.key === "Stripe") ||
          this.props.paymentSelect?.find((a) => a.key === "EdgePay")
        : null;
    this.setState({
      paymentSelectKey: defaultPaymentType.key,
    });
  }

  getCounterDetails = () => {
    const { creditCardPayment } = this.state;
    this.props.actions.apiCall({
      urls: ["GETCOUNTERNAME"],
      method: "GET",
      data: {
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        PageNo: 1,
        PageSize: 100,
      },
      onSuccess: (response) => {
        const { defaultUserDetails } = this.props;
        const getCounter = response.map((singleCounter) => {
          return {
            value: singleCounter.counterId,
            key: singleCounter.counterId,
            text: singleCounter.counterName,
          };
        });
        const defaultCounterId = defaultUserDetails.defaultCounterId;
        const defaultCounter =
          getCounter.length > 0
            ? orderCommonFunctions.onSelectingDefaultDetail(
                getCounter,
                defaultCounterId,
                "value"
              )
            : null;

        if (getCounter.length > 0) {
          // creditCardPayment["counterId"] = getCounter[0].value;
          creditCardPayment["counterId"] = defaultCounter;
        }
        creditCardPayment["amountPaid"] = this.props.amountSlected;

        this.setState({ creditCardPayment, counterOptions: getCounter });
      },
    });
  };

  onReaderChange = (e, { name, value }) => {
    this.setState({ chooseReader: value });
  };

  handleChange = (e, { name, value }) => {
    if (name === "amountPaid") {
      const newAmount = parseFloat(value);
      // type='number'
      // if (e.target.validity.valid) {
      if (newAmount >= 0) {
        this.setState({
          creditCardPayment: {
            ...this.state.creditCardPayment,
            [name]: newAmount,
          },
        });
      } else {
        this.setState({
          creditCardPayment: { ...this.state.creditCardPayment, [name]: null },
        });
      }
    } else {
      this.setState({
        creditCardPayment: { ...this.state.creditCardPayment, [name]: value },
      });
    }
  };

  handleSubmit = (e, creditCardPayment) => {
  
    this.setState(
      {
        isStripeModal: true,
        isStripeApiRunning: true,
        messageToShow: "Connecting to Card-Reader ",
        secondMessage: "Please wait ....",
      },
      () => this.connectReaderHandler()
    );
  };

  handleClose = () => {
                       ;
    this.validator = commonFunctions.initializeSimpleValidator();
    this.props.closeModal();
    const { terminal } = this.state;
    terminal.disconnectReader();
  };

  onTipCheckboxChange = () => {
    this.setState({ tipCheckBox: !this.state.tipCheckBox, tipPrice: null });
  };

  onTiphandleChange = (e, { name, value }) => {
    const { tipPrice } = this.state;
    const newAmount = parseFloat(value);
    if (newAmount >= 0) {
      this.setState({ tipPrice: newAmount });
    } else {
      this.setState({ tipPrice: null });
    }
  };

  manualCardModal = () => {
    if (this.state.counterOptions.length === 0) {
      this.props.actions.showNotification(
        {
          title: "Error",
          message: "Please add counter first.",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    } else {
      this.setState({
        isManualCardModalOpen: !this.state.isManualCardModalOpen,
      });
    }
  };

  onsplitValueChange = (e, { value }) => {
    const { creditCardPayment, balanceDue } = this.state;
    // creditCardPayment["amountPaid"] = this.props.amountSlected
    const newAmount = parseInt(value);
    // const newAmount = parseFloat(value);
    if (newAmount >= 1) {
      creditCardPayment.amountPaid = parseFloat(
        (balanceDue / newAmount).toFixed(2)
      );

      // let returnCash = (totalCashGiven - cashPaymentObj.totalPayment - tipPrice).toFixed(2); //  3 - 34 = -31
      this.setState({
        ...this.state,
        creditCardPayment: { ...creditCardPayment },
        splitValue: newAmount,
      });
    } else {
      creditCardPayment.amountPaid = balanceDue;
      // let returnCash = (totalCashGiven - cashPaymentObj.totalPayment - tipPrice).toFixed(2);
      this.setState({
        ...this.state,
        creditCardPayment: { ...creditCardPayment },
        splitValue: 0,
      });
    }
  };

  showsavediscount = () => {
    this.setState({
      saveddiscount: true,
      balanceDue: this.props.amountSlected,
    });
  };

  showcreatediscount = () => {
    const { creditCardPayment, balanceDue } = this.state;
    creditCardPayment.amountPaid = balanceDue;
    this.setState({
      saveddiscount: false,
      creditCardPayment,
      splitValue: 1,
      balanceDue: null,
    });
  };
  onClosedPaymentModal = () => {
    this.props.closeModal();
  };
  onCloseStripePaymentModal = () => {
    const { terminal } = this.state;
    this.setState({ isStripeModal: false }, () =>
      terminal.clearReaderDisplay()
    );
    // cancelCollectSetupIntentPaymentMethod
    // clearReaderDisplay
  };
  render() {
    const {
      counterOptions,
      creditCardPayment,
      isStripeModal,
      messageToShow,
      isStripeApiRunning,
      secondMessage,
      tipPrice,
      splitValue,
      chooseReader,
      onlineReader,
      isManualCardModalOpen,
      isLoading,
      paymentSelectKey,
      balanceDue,
      isSignatureModalOpen,
      transactionDetails,
      amountAfterSplit,
    } = this.state;
    const { value, orderState, selectPaymentType ,fullState } = this.props;  
    const rehntitNewAmount =(orderState.currentOrder.rehntitshare / 100) * creditCardPayment.amountPaid;
    const rehntitShareAmount1 = creditCardPayment.amountPaid + rehntitNewAmount;
    const rehntitShareAmount = rehntitShareAmount1.toFixed(2);
                                      
    return (
      <Modal
        open={this.props.openModal}
        closeIcon
        onClose={() => this.handleClose()}
        size={"small"}
      >
        {isLoading && (
          <Dimmer active inverted>
            {" "}
            <Loader size="small"></Loader>{" "}
          </Dimmer>
        )}
        <Modal.Content>
          <Grid>
            <Grid.Column width={16} verticalAlign="middle">
              <Header as="h4" className="orange-color">
                {" "}
                {this.props.title}
              </Header>
            </Grid.Column>
            <Grid.Column width={4} verticalAlign="middle">
              <span>
                {" "}
                <b>Counter</b>
              </span>
            </Grid.Column>
            <Grid.Column width={6} verticalAlign="middle">
              <Select
                placeholder="Select Cash Drawer"
                value={creditCardPayment.counterId}
                name="counterId"
                className="custom-select"
                options={counterOptions}
                fluid
                onChange={this.handleChange}
              />
              {this.validator.message(
                "Counter",
                creditCardPayment.counterId,
                "required"
              )}
            </Grid.Column>

            <Grid.Column width={6} verticalAlign="middle">
              {onlineReader.length > 0 ? (
                <Select
                  placeholder="Select Reader"
                  value={chooseReader}
                  name="counterId"
                  className="custom-select"
                  options={onlineReader}
                  fluid
                  onChange={this.onReaderChange}
                />
              ) : (
                <p className="orange-color">No reader is online </p>
              )}
              {this.validator.message("chooseReader", chooseReader, "required")}
            </Grid.Column>

            <Grid.Column width={8}>
              <Form>
                <Form.Field>
                  <FormRadio
                    label="Single Payment"
                    name="paymentMethod"
                    className="custom-radio-btn"
                    value="SinglePayment"
                    checked={
                      creditCardPayment.paymentMethod === "SinglePayment"
                    }
                    onChange={this.handleChange}
                    onClick={this.showcreatediscount}
                  />
                </Form.Field>
              </Form>
            </Grid.Column>
            <Grid.Column width={8}>
              {value === "Balance DuecreditCardPayment.amountPaid" ? (
                <Form>
                  <Form.Field>
                    <Radio
                      label="Split Payment"
                      className="custom-radio-btn"
                      name="paymentMethod"
                      value="SplitPayment"
                      checked={
                        creditCardPayment.paymentMethod === "SplitPayment"
                      }
                      onChange={this.handleChange}
                      onClick={this.showsavediscount}
                    />
                  </Form.Field>
                </Form>
              ) : null}
            </Grid.Column>

            <Grid.Column width={16} verticalAlign="center">
              <Link onClick={this.manualCardModal} className="deepviolet-color">
                Manual Card Entry
              </Link>
            </Grid.Column>
            {this.state.saveddiscount && (
              <>
                <br></br>
                <Grid.Column width={4}></Grid.Column>
                <Grid.Column width={12}>
                  <Link className="orange-color">
                    Balance Due: $ {parseFloat(balanceDue).toFixed(2)}
                  </Link>
                </Grid.Column>
                <Grid.Column width={4}></Grid.Column>
                <Grid.Column width={12}>
                  <Link className="orange-color">Split into</Link>
                  <Input
                    value={splitValue}
                    onChange={this.onsplitValueChange}
                    type="text"
                    className="split-into"
                  />
                  <Link className="orange-color">Amounts</Link>
                </Grid.Column>
              </>
            )}
            <Grid.Column width={4}>
              <Link className="orange-color"> Total Amount</Link>
              <span className="plus-sign-credit-modal orange-color">+</span>
            </Grid.Column>
            <Grid.Column width={4}>
              <Form.Input
                name="amountPaid"
                disabled={
                  this.state.saveddiscount
                    ? true
                    : this.props.value !== "Custom Amount"
                    ? true
                    : false
                }
                labelPosition="left"
                type="number"
                className="amountInput"
                placeholder="Amount"
                value={
                  this.props.value === "Custom Amount"
                    ? creditCardPayment.amountPaid
                    : parseFloat(creditCardPayment.amountPaid).toFixed(2)
                }
                onChange={this.handleChange}
              >
                <Label basic>$</Label>
                <input />
              </Form.Input>
            </Grid.Column>
            {paymentSelectKey === "EdgePay" ? (
              <>
                <Grid.Column width={2}>
                  <span className="plus-sign-credit-modal-right orange-color">
                    +
                  </span>
                </Grid.Column>
                <Grid.Column width={4}>
                  <div className="rehntit-fee">
                   
                    {orderState.currentOrder.rehntitshare}% Booking Fee.
                  </div>
                </Grid.Column>
              </>
            ) : null}
            {paymentSelectKey === "Stripe" ? (
              <>
              
                <Grid.Column width={2}>
                  <span className="plus-sign-credit-modal-right orange-color">
                    +
                  </span>
                </Grid.Column>
                <Grid.Column width={4}>
                  <div className="rehntit-fee">
                  
                    {orderState.currentOrder.rehntitshare}% Booking Fee.
                  </div>
                </Grid.Column>
              </>
            ) : null}
            <Grid.Column width={4} verticalAlign="middle">
              <Link className="orange-color">Amount to Charge</Link>
              <br></br>
            
            </Grid.Column>
            <Grid.Column width={6}>
              <Form.Input
                name="amountPaid"
                readOnly
                labelPosition="left"
                className="amountInput padding-amount"
                placeholder="Amount"
                value={rehntitShareAmount}
              >
                <Label basic>$</Label>
                <input />
              </Form.Input>
            </Grid.Column>
            <>
            </>
            
            
            <Grid.Column width={16} textAlign="right">
              {onlineReader.length === 0 ? (
                <Button
                  onClick={this.discoverReaderHandler}
                  className="blue-btn"
                >
                  Find Reader
                </Button>
              ) : (
                <Button
                  className="orange-btn"
                  onClick={() => this.disconnectReader()}
                >
                  Cancel Payment
                </Button>
              )}

              <Button
                className="orange-btn"
                disabled={
                  creditCardPayment["amountPaid"] === 0
                    ? true
                    : chooseReader === null
                    ? true
                    : creditCardPayment["amountPaid"] === null
                    ? true
                    : false
                }
                onClick={(e) => this.handleSubmit(e, creditCardPayment)}
              >
                Charge
              </Button>
            </Grid.Column>
          </Grid>
        </Modal.Content>

        {isSignatureModalOpen && (
          <SplitSignatureModal
            transactionDetails={transactionDetails}
            onSignSubmit={this.onSignSubmit}
            splitAmount={amountAfterSplit}
            onClosedPayment={this.handleClose}
            addDeposit={false}
            closeModalSignture={this.props.closeModal}
            isModalOpen={isSignatureModalOpen}
            onClose={() => this.setState({ isSignatureModalOpen: false })}
          />
        )}

        {isManualCardModalOpen && (
          <ManualCardModal
            paymentSelect={this.props.paymentSelect}
            rehntitShareAmount={rehntitShareAmount}
            rehntitSharePercentage={orderState.currentOrder.rehntitshare}
            ahoyMinimumChargeAmount={orderState.currentOrder.ahoyMinimumChargeAmount}
            isToShowRehntitShare={true}
            paymentSelectKey={paymentSelectKey}
            selectedPaymentType={this.props.value}
            tipPrice={tipPrice}
            holdDeposit={false}
            selectedGlobalCodeId ={fullState.selectedGlobalCodeId}
            onClosedPaymentModal={this.onClosedPaymentModal}
            selectPaymentType={selectPaymentType}
            closeCreditModal={this.onSuccessManualCardPayment}
            closeModal={this.props.closeModal}
            counterId={creditCardPayment.counterId}
            totalPayment={creditCardPayment.amountPaid}
            isModalOpen={isManualCardModalOpen}
            onClose={() => this.setState({ isManualCardModalOpen: false })}
            amount={creditCardPayment.amountPaid}
          />
        )}
        {isStripeModal && (
          <StripePaymentModal
            isStripeApiRunning={isStripeApiRunning}
            secondMessage={secondMessage}
            messageToShow={messageToShow}
            isApiLoading={true}
            addDeposit={false}

            isModalOpen={isStripeModal}
            // onClose={() => this.setState({ isStripeModal: false })}
            onClose={this.onCloseStripePaymentModal}
          />
        )}
      </Modal>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    auth: state.auth,
    global: state.global,
    newOrderDetail: state.newOrderDetail,
    orderState: state.orderState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      getOrderItems: bindActionCreators(actions.getOrderItems, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreditCardPaymentModal);
