import React, { Component } from "react";
import { Grid, Button } from "semantic-ui-react";
import { commonFunctions, connect, bindActionCreators, actions, Notifications } from "../../../../functional/global-import";
import CommonImage from "../../../atoms/common-image";

class UploadBundalPhoto extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  backStep = (e) => {
    e.preventDefault();

    const { fullState } = this.props
    if ((this.props.fullState.uploadImage.images[0] !== undefined && this.props.fullState.uploadImage.DefaultImage[0] !== undefined) || (this.props.fullState.uploadImage.images[0] === undefined && this.props.fullState.uploadImage.DefaultImage[0] === undefined)) {
      this.props.allFunction.backStep();
    }
    else if (this.props.fullState.uploadImage.images[0] !== undefined && this.props.fullState.uploadImage.DefaultImage[0] === undefined) {
      this.props.actions.showNotification({ title: "Error", message: 'Please select default image.', position: 'br', autoDismiss: commonFunctions.notificationTime() }, "error");
    }
  }
  changeNext = () => {
    const { fullState } = this.props
    // if (this.props.fullState.uploadImage.images.length === 1 && this.props.fullState.uploadImage.DefaultImage.length === 0) {
    //                                                                 
    //   let data = this.props.fullState.uploadImage.images
    //   fullState.uploadImage["DefaultImage"] = data
    //   this.setState({ fullState })

    // }
    if ((this.props.fullState.uploadImage.images[0] !== undefined && this.props.fullState.uploadImage.DefaultImage[0] !== undefined) || (this.props.fullState.uploadImage.images[0] === undefined && this.props.fullState.uploadImage.DefaultImage[0] === undefined)) {
      this.props.allFunction.changeNext(2);
    }
    else if (this.props.fullState.uploadImage.images[0] !== undefined && this.props.fullState.uploadImage.DefaultImage[0] === undefined) {
      this.props.actions.showNotification({ title: "Error", message: 'Please select default image.', position: 'br', autoDismiss: commonFunctions.notificationTime() }, "error");
    }
  }

  render() {
    const { uploadImage, onImageChange, changeNext, backStep, changeEditNext, validator, secondStepCompleted
      , api, fullState, allFunction, } = this.props;

    return (
      <React.Fragment>
        <Grid>
          <Grid.Column mobile={16} tablet={16} computer={16} textAlign="center" className="controlField">
            <CommonImage
              uploadImage={uploadImage}
              imagelist={uploadImage.images}
              onImageChange={onImageChange}
              multiple={true}
              onImageRemove={this.props.onImageRemove}
              defaultImage={this.props.uploadImage.DefaultImage}
              isRadio={true}
              // defaultImage={[commonFunctions.concatenateImageWithAPIUrl(null)]}
              imageId={uploadImage.imageId}
              handleChangeDefaultImage={allFunction.handleChangeDefaultImage}
            />
          </Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column width={16} textAlign="right">
            <Button className="blue-btn" onClick={this.backStep}>Back</Button>
            {/* {fullState.updateBundleGeneral ? <Button className="orange-btn" onClick={()=>changeEditNext(2)}>Continue</Button> : */}
            <Button className="orange-btn" loading={api.isApiLoading} onClick={this.changeNext}>Continue</Button>
          </Grid.Column>
        </Grid>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UploadBundalPhoto);