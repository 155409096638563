import React, { Component } from 'react'
import { Grid, Card, Input, Modal, Image, Header } from "semantic-ui-react";
import { commonFunctions} from "../../../../functional/global-import";

class Signature extends Component {
    render() {
        const { signatureDocument } = this.props;
        return (
            <Modal open={this.props.openModal} closeIcon onClose={this.props.closeModal} size={"small"}>
                <Modal.Content>
                    <Grid.Column width={16}>
                        <Card.Content>
                            <span> {signatureDocument.PaymentMethod} :{" "} <span className="orange-color">{commonFunctions.isNumberInteger(signatureDocument.Amount)}</span></span>
                        </Card.Content>
                        <Card fluid className="signature-box">
                            <Header as="h4" className="signature-date">{signatureDocument.SignatureDate}</Header>

                            <Card.Content>
                                <Input readOnly value={signatureDocument.CustomerName} name="candidateName" placeholder="Type Full Name" fluid
                                />
                            </Card.Content>
                            <Card.Content>
                                <Image src={signatureDocument.CustomerSignature} />
                            </Card.Content>
                        </Card>
                    </Grid.Column>
                </Modal.Content>
            </Modal>
        )
    }
}
export default Signature;