import * as types from "../types"
const initialState = {
    isFormChanged: false,
};

const warnUser = (state = initialState, actions) => {
    switch (actions.type) {
        case types.STORE_WARN_USER:
            return { ...state }
        
        default:
            return state;
    }
}
export default warnUser;
