import React, { Component } from "react";
import { Grid,  Header,  Button,  Input, Icon, Popup } from "semantic-ui-react";
import { CommonDeleteUpdateModal } from "../../../../components";
// import { CommonTaxes } from "../../../../components";
import { commonFunctions, connect, bindActionCreators, actions,  Notifications } from "../../../../functional/global-import";
import EmailNotification from "../../../../../pages/more-setting/pricing-taxes-setting/activity-pricing/email-notification";
class AddRetailPricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.validator = commonFunctions.initializeSimpleValidator();
    this.validatorGeneral = commonFunctions.initializeSimpleValidator();
  }

  closeDeleteModal = () => this.setState({ deleteModalOpen: false })

  backStep = (e) => {
    const { allFunction } = this.props;
    allFunction.setState({ activeStep: 2 })
  }
  render() {
    const { buttonName, messageToShow, deleteModalOpen} = this.state;
    const { api, allFunction, fullState, validator } = this.props;

    return (
      <div>
        <Grid>
          <Grid.Column width={16}>
            <Header as="h4">Retail Pricing &nbsp;
            <Popup trigger={<Icon name="info circle" className="orange-color discount-css" />}>
              <div>Cost price will not be displayed.</div>
            </Popup>
            </Header>
          </Grid.Column>

          <React.Fragment>
            <Grid.Column width={8}>
              <Input label={{ basic: true, content: '$' }} labelPosition='left' min="0" placeholder="Cost Price" fluid
                value={fullState.addRetailPrice.costPrice} data="costPrice" onChange={allFunction.onHandleChangePrice} />
            </Grid.Column>
            <Grid.Column width={8}>
              <Input label={{ basic: true, content: '$' }} labelPosition='left' placeholder="Sale Price" fluid value={fullState.addRetailPrice.salePrice}
                data="salePrice" onChange={allFunction.onHandleChangePrice}
                error={validator.message("Sale Price", fullState.addRetailPrice.salePrice,
                  "required|min:0,num")} />
            </Grid.Column>
          </React.Fragment>
          <Grid.Column width={16}>
            {/* <Divider /> */}
          </Grid.Column>
          <Grid.Column mobile={16} tablet={2} computer={16}>
            <div className="bookedEmail">
              <EmailNotification
                fullState={fullState}
                stateForm="addRetailPrice"
                allFunction={allFunction}
              />
            </div>
          </Grid.Column>
          {/* <CommonTaxes fullState={fullState} allFunction={allFunction} stateForm="addRetailPrice" variableName="generalTaxes" />  */}
        </Grid>
        {this.props.moreSettingPricing === undefined &&
          <Grid>
            <Grid.Column width={16} textAlign="right">
              <div>
                {!allFunction.props.editRetailDetail ? <Button className="blue-btn" onClick={() => allFunction.onNext(2, "Retail")}>Back</Button> : null}
                <Button className="orange-btn" onClick={() => allFunction.postRetailPricing("continue")} loading={api.isApiLoading}>Save</Button>
              </div>
            </Grid.Column>
          </Grid>
        }
        <CommonDeleteUpdateModal onUpdateModal={this.deletePricing}
          buttonName={buttonName} messageToShow={messageToShow} isModalOpen={deleteModalOpen}
          onCloseModal={this.closeDeleteModal} />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddRetailPricing);
