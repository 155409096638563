import React, { Component } from "react";
import { Table, Checkbox, Icon, Grid, Button, Dimmer, Loader, Header } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { bindActionCreators, connect, actions, Notifications, commonFunctions } from "../../../../../functional/global-import";
import StatusChangeModal from "../status/rental-start-time-modal";
import moment from "moment";
import AddCommentModal from "../../add-comment-modal";


class StatusTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderChecknInCheckOutList: [],
      selectedCheckInCheckOutList: [],
      changeStatus: false,
      checkOut: true,
      checkIn: false,
      NonCheckOutCheckIn: false,
      note: {
        noteRefrenceId: "",
        noteType: "",
      },
      isApiLoading: true,
    };
  }

  getOrderChecknInCheckOut = () => {
    const { orderState, global, auth } = this.props
    this.props.actions.apiCall({
      urls: ["GETORDERCHECKNINCHECKOUT"], method: "GET", data: {
        OrderId: orderState.currentOrder.orderId,
        BusinessId: auth.userDetail.businessId,
        BusinessLocationId: global.locationSelected.BusinessLocationId
      },
      onSuccess: (response) => {
        this.setState({ orderChecknInCheckOutList: response, isApiLoading: false });
        let checkInData = response.filter(item => item.status === "Checked In");
        // if(response.length === checkInData.length){
        //   this.props.handleRedirect()
        // }
      }
    });
  }

  openNotesModal = (data, id) => {
    const { note } = this.state;
    this.setState({ commentStatus: !this.state.commentStatus });
    if (data === "ItemDetail") {
      note.noteRefrenceId = id.productDetailId;
      note.noteType = data;
      this.setState({ note });
    }
  };

  componentDidMount() {
    this.getOrderChecknInCheckOut();
  }

  handleChange = (e, { checked, name }, selectedProduct) => {

    const { selectedCheckInCheckOutList } = this.state;
    let data = selectedCheckInCheckOutList;
    if (checked !== false) {
      if (name === "CheckAll") {
        data = selectedProduct
      }
      else {
        data.push(selectedProduct);
      }
    }

    else {
      if (name === "CheckAll") {
        data = [];
      }
      else {
        data = selectedCheckInCheckOutList.filter(item => item.id !== selectedProduct.id)
      }

    }
    let checkOutData = data.filter(item => item.status === "Checked Out");
    let checkInData = data.filter(item => item.status === "Checked In");
    ;
    if (checkOutData.length > 0) {
      if (checkOutData.length === data.length) {
        this.setState({ checkIn: true, checkOut: false, NonCheckOutCheckIn: false })
      }
      else {
        this.setState({ NonCheckOutCheckIn: true })
      }
    }
    else if (checkInData.length > 0) {
      ;
      if (checkOutData.length === data.length) {
        this.setState({ NonCheckOutCheckIn: true })
      }
      else if (checkInData.length === data.length) {
        this.setState({ checkIn: false, checkOut: false })
        // this.props.handleRedirect()
      }
      else {
        this.setState({ NonCheckOutCheckIn: true })
      }
    }
    else {
      this.setState({ checkIn: false, checkOut: true, NonCheckOutCheckIn: false })
    }
    this.setState({ selectedCheckInCheckOutList: data })
  }

  statusModal = (data) => {

    let openModal = !this.state.changeStatus
    let rentalStartTime = null
    if (openModal && data && data.length > 0) {
      rentalStartTime = data[0].checkedOut === null ? data[0].startDate : data[0].endDate
    }
    this.setState({ changeStatus: openModal, rentalStartTime });

    this.getOrderChecknInCheckOut()
    if (openModal === false) {
      this.setState({ selectedCheckInCheckOutList: [] })
    }

  };

  onBackBtnClick = () => {

    if (this.props.orderState.currentOrder.pricings) {
      this.props.onPrevious(3)
    }
    else {
      this.props.actions.showNotification({ title: "Error", message: "Please add Item to cart first !", position: 'br', autoDismiss: commonFunctions.notificationTime() }, "error");
    }
  }

  render() {
    const { commentStatus, note, selectedCheckInCheckOutList, changeStatus, NonCheckOutCheckIn, checkOut, checkIn, orderChecknInCheckOutList, isApiLoading, rentalStartTime } = this.state;
    return (
      <>
        {isApiLoading && <Dimmer active inverted> <Loader size='small'>Loading</Loader> </Dimmer>}

        {!isApiLoading && orderChecknInCheckOutList.length > 0 &&
          <Grid>
            <Grid.Column loading={true} width={16}>
              <div>
                <Table style={{ fontSize: "12px" }}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>
                        <Checkbox
                          className="custome-checkbox"
                          name="CheckAll"
                          checked={orderChecknInCheckOutList.length === selectedCheckInCheckOutList.length ? true : false}
                          onChange={(e, { value, checked, name }) => this.handleChange(e, { value, checked, name }, orderChecknInCheckOutList)}
                        /> </Table.HeaderCell>
                      <Table.HeaderCell> Name <Icon link name="sort" className="orange-color" /></Table.HeaderCell>
                      <Table.HeaderCell> ID <Icon link name="sort" className="orange-color" /></Table.HeaderCell>
                      <Table.HeaderCell> Status <Icon link name="sort" className="orange-color" /></Table.HeaderCell>
                      <Table.HeaderCell> Checked Out{" "}<Icon link name="sort" className="orange-color" /></Table.HeaderCell>
                      <Table.HeaderCell>Checked In{" "}<Icon link name="sort" className="orange-color" /></Table.HeaderCell>
                      <Table.HeaderCell textAlign="right">Notes</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {orderChecknInCheckOutList.map((item) => {
                      return (
                        <Table.Row>
                          <Table.Cell>
                            {item.status === "Checked In" ?
                              <Checkbox
                                className="custome-paid-checkbox rentalEditcart"
                                checked
                              />
                              :
                              <Checkbox className="custome-checkbox"
                                checked={selectedCheckInCheckOutList.filter(selectedCheckInCheckOut => selectedCheckInCheckOut.id === item.id).length > 0}
                                onChange={(e, { value, checked, name }, selectedProduct) => this.handleChange(e, { value, checked }, item)} />
                            }
                          </Table.Cell>
                          <Table.Cell title={item.productName} className="textOverflowHidden"><Link className="orange-color">{item.productName}</Link></Table.Cell>
                          <Table.Cell>{item.id}</Table.Cell>
                          <Table.Cell>{item.status}</Table.Cell>
                          <Table.Cell><Link className="orange-color"> {item.checkedOut && moment(item.checkedOut, 'MM/DD/YYYY hh:mm:ss').format('MMM DD YYYY, h:mm A')}</Link> </Table.Cell>
                          <Table.Cell><Link className="deepviolet-color"> {item.checkedIn && moment(item.checkedIn, 'MM/DD/YYYY hh:mm:ss').format('MMM DD YYYY, h:mm A')}</Link></Table.Cell>
                          <Table.Cell textAlign="right">
                            <Link onClick={() => this.openNotesModal("ItemDetail", item)}>
                              <span class="iconify maroon-color" data-icon="mdi:comment-plus" data-inline="false" data-flip="horizontal" data-width="18px" data-height="18px" />
                            </Link>
                          </Table.Cell>
                        </Table.Row>
                      )
                    })}
                  </Table.Body>
                </Table>
              </div>
            </Grid.Column>
            <Grid.Column width={16} textAlign="right">
              <Button className="blue-btn" onClick={() => this.props.onPrevious(3)}>Back</Button>

              {checkOut &&
                <Button className="orange-btn"
                  // disabled={NonCheckOutCheckIn ? true : false}
                  disabled={selectedCheckInCheckOutList.length === 0 ? true : false}

                  onClick={() => { this.statusModal(selectedCheckInCheckOutList); }}>Check Out</Button>
              }
              {checkIn &&
                <Button className="blue-btn"
                  //  disabled={NonCheckOutCheckIn ? true : false}
                  disabled={selectedCheckInCheckOutList.length === 0 ? true : false}
                  onClick={() => { this.statusModal(selectedCheckInCheckOutList); }}>Check In</Button>
              }
            </Grid.Column>
          </Grid>
        }

        {!isApiLoading && orderChecknInCheckOutList.length === 0 &&
          <>
            <Grid>
              <Grid.Column width={16} textAlign="center">
                <Header as="h2">No Items for check-in check-out </Header>
              </Grid.Column>
              <Grid.Column width={16} textAlign="center">
                <Header as="h2">!</Header>
              </Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column width={16} textAlign="right">
                <Button className="blue-btn" onClick={this.onBackBtnClick}>Back</Button>
              </Grid.Column>
            </Grid>
          </>
        }

        <StatusChangeModal getOrderChecknInCheckOut={this.getOrderChecknInCheckOut} openModal={changeStatus} checkIn={checkIn} closeModal={this.statusModal} selectedCheckInCheckOutList={selectedCheckInCheckOutList}
          rentalStartTime={rentalStartTime}
        />

        

        <AddCommentModal
          openModal={commentStatus}
          noteType={note.noteType}
          noteRefrenceId={note.noteRefrenceId}
          closeModal={this.openNotesModal}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    orderState: state.orderState,
    auth: state.auth
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(StatusTable);
