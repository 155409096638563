// **********************************************************************************************************************************
// ***************************************************  used  ***************************************************************
// **********************************************************************************************************************************

import React, { Component } from "react";
import { Grid, Modal, Header, Dimmer, Loader } from "semantic-ui-react";


class StripePaymentModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    };




    render() {
        const { isModalOpen, onClose,  messageToShow, secondMessage, isStripeApiRunning } = this.props;

        return (
            <>
                <Modal
                    className="striprModalCss"
                    //  closeOnDimmerClick={false} 
                    open={isModalOpen} closeIcon={false} size={"small"} onClose={onClose} >
                        
                    {isStripeApiRunning &&
                        <Dimmer active inverted> <Loader size='small'></Loader> </Dimmer>
                    }
                    <Modal.Content>
                        <Grid>
                            <Grid.Column width={16} textAlign="center">
                                <Header className="orange-color"> {messageToShow}</Header>
                            </Grid.Column>
                            <Grid.Column width={16} textAlign="center">
                                <h4 className="deepviolet-color">{secondMessage}</h4>
                            </Grid.Column>
                        </Grid>
                    </Modal.Content>
                </Modal>
            </>
        );
    };
}

export default (StripePaymentModal);