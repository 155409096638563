import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import PrintableComponent from "../new-order-modal/printableComponent/PrintableComponent";

const PrintInvoice = (props) => {
  const componentRef = useRef();
  const componentRef2 = useRef();

  const [passPrntUri, setPassPrntUri] = useState("");

  const handlePrint = useReactToPrint({
    content: () => componentRef2.current,
    pageStyle: `
    .receipt{
      display:block !important;
    }
    `,
  });

  const getMobileOS = () => {
    const ua = navigator.userAgent;
    if (/android/i.test(ua)) {
      return "Android";
    } else if (
      /iPad|iPhone|iPod/.test(ua) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
    ) {
      return "iOS";
    }
    return "Other";
  };

  const os = getMobileOS();
  // const isMobTab = os === "Android" || os === "iOS";

  var passprnt_uri;

  function createURI() {
    passprnt_uri = "starpassprnt://v1/print/nopreview?";

    const printContent = `<html><head><style>
   
    .receipt{
        display:block;
    }
    .thankyou{
      margin-top: 160px;
    }
   .receipt table {
       width: 100%;
     }
     .receipt table thead {
       font-size: 25px;
       font-weight:bold;
       color: black;
     }
     .receipt table tbody tr td {
       font-size: 20px;
       font-weight:bold;
       color: black;
     }
     .receipt table tbody tr td:first-child {
       color: black;
       font-weight:bold;
       font-size: 20px;
     }
     .receipt table tbody tr td:last-child {
       text-align: right;
       color: black;
       font-weight:bold;
       font-size: 20px;
     }
     hr{
         color: black;
         border:1px solid black;
  
     }
     
  </style></head><body>${componentRef2.current.outerHTML}</body></html>`;

    passprnt_uri =
      passprnt_uri + "back=" + encodeURIComponent(window.location.href);

    passprnt_uri = passprnt_uri + "&html=" + encodeURIComponent(printContent);

    setPassPrntUri(passprnt_uri);

    var target = document.getElementById("print");
    target.href = passprnt_uri;
  }

  useEffect(() => {
    const operatingSystem = getMobileOS();
    const isMobTab = operatingSystem === "Android" || operatingSystem === "iOS";

    props.setPrintFunction({
      handlePrint: isMobTab ? createURI : handlePrint,
      passPrntUri,
    });
  }, []);

  return (
    <div>
      {/* {isMobTab ? (
        <a href="" onClick={createURI} id="print">
          <i title="Print" class=" large print icon orangeColorPrinter"></i>
        </a>
      ) : (
        <a onClick={handlePrint}>
          <i title="Print" class=" large print icon orangeColorPrinter"></i>
        </a>
      )} */}
      <PaymentBox ref={{ componentRef, componentRef2 }} {...props} />
    </div>
  );
};

const PaymentBox = React.forwardRef(({}, ref) => {
  const orderState = useSelector((state) => state.orderState);

  return (
    <div id="printableArea" ref={ref.componentRef}>
      <div ref={ref.componentRef2} className="receipt" id="receipt">
        <PrintableComponent orderState={orderState} />
      </div>
    </div>
  );
});

export default PrintInvoice;
