// *********************************************************************************
// *************************** Common Add on For All Business Type  ***********************
// *********************************************************************************

import React, { Component } from "react";
import {
  Header,
  Table,
  Image,
  Input,
  Icon,
  Grid,
  Button,
  Form,
} from "semantic-ui-react";
import {
  connect,
  bindActionCreators,
  actions,
  Notifications,
  commonFunctions,
} from "../../../../../functional/global-import";
import { Link } from "react-router-dom";
class AddOnAllBusinessType extends Component {
  onSaveAddOnDetails = (isAddMoreCLicked) => {
    const addOnDetail = [];
    this.props.addOnDetails.map((item) => {
      if (item.qunatity > 0) {
        addOnDetail.push({
          addOnId: item.addOnId,
          price: item.Price,
          quantity: item.qunatity,
        });
      } else {
        return { ...item };
      }
    });
    if (addOnDetail.length > 0) {
      const orderState = this.props.orderState;
      this.props.actions.apiCall({
        urls: ["ORDERADDONDETAIL"],
        method: "POST",
        data: {
          orderId: orderState.currentOrder.orderId,
          orderDetailId: this.props.orderDetailId,
          addOn: addOnDetail.length > 0 ? addOnDetail : null,
          actionPerformedBy: this.props.auth.userDetail.emailId,
        },
        showNotification: true,
        onSuccess: (response) => {
          const { addOnDetails, stateRef } = this.props;
          addOnDetails.map(function (addOnObj) {
            addOnObj.qunatity = 0;
            return addOnObj;
          });
          stateRef.setState({ addOnDetails: [...addOnDetails] });

          this.props.onContinueClick(isAddMoreCLicked);
          this.props.actions.getOrderItems(
            this.props.orderState.currentOrder.orderId
          );
        },
      });
    } else {
      this.props.onContinueClick(isAddMoreCLicked);
    }
  };
  countHandleChangeMinus = (index) => {
    const { addOnDetails, stateRef } = this.props;
    if (addOnDetails[index]["qunatity"] >= 1) {
      if (addOnDetails[index].qunatity === addOnDetails[index].minvalue) {
        addOnDetails[index]["qunatity"] = 1;
      }
      addOnDetails[index]["qunatity"] = addOnDetails[index]["qunatity"] - 1;
      stateRef.setState({ addOnDetails: [...addOnDetails] });
    }
  };
  
  countHandleChangePlus = (index) => {
    const { addOnDetails, stateRef } = this.props;
    if (addOnDetails[index]["maxAddOns"] !== null) {
      if (addOnDetails[index]["maxAddOns"] <= addOnDetails[index]["qunatity"]) {
        this.props.actions.showNotification(
          {
            title: "Warning",
            message: `You can't add more Addons for this.`,
            position: "br",
            autoDismiss: commonFunctions.notificationTime(),
          },
          "error"
        );
        return false;
      }
    }
    if (addOnDetails[index]["minAddOns"] !== null) {
      if (addOnDetails[index]["qunatity"] === 0) {
        addOnDetails[index]["qunatity"] = addOnDetails[index]["minAddOns"];
        this.props.actions.showNotification(
          {
            title: "success",
            message: `This Addons required min. is ${addOnDetails[index]["minAddOns"]}.`,
            position: "br",
            autoDismiss: commonFunctions.notificationTime(),
          },
          "success"
        );
      } else {
        addOnDetails[index]["qunatity"] = addOnDetails[index]["qunatity"] + 1;
      }
    }
    if (addOnDetails[index]["minAddOns"] == null) {
      addOnDetails[index]["qunatity"] = addOnDetails[index]["qunatity"] + 1;
    }
    stateRef.setState({ addOnDetails: [...addOnDetails] });
  };

  render() {
    const { addOnDetails, hasToShowCancellationComponent } = this.props;

    return (
      <>
        <Grid>
          {/* {api.isApiLoading &&
                        <Dimmer active inverted> <Loader size='small'></Loader> </Dimmer>
                    } */}

          <Grid.Column width={16}>
            <Header as="h2" className="maroon-color">
              Add on
            </Header>
          </Grid.Column>

          <Grid.Column width={16}>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Image</Table.HeaderCell>
                  <Table.HeaderCell>Name </Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">
                    Quantity{" "}
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">
                    Price/ per Item{" "}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {addOnDetails &&
                  addOnDetails.map((singleProduct, index) => {
                    return (
                      <Table.Row>
                        <Table.Cell>
                          <Image
                            src={commonFunctions.concatenateImageWithAPIUrl(
                              singleProduct.Image
                            )}
                            size="tiny"
                            bordered
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <Link
                            title={singleProduct.AddOnTitle}
                            className="orange-color"
                          >
                            {singleProduct.AddOnTitle.length > 18
                              ? commonFunctions.truncateText(
                                  singleProduct.AddOnTitle,
                                  18
                                )
                              : singleProduct.AddOnTitle}
                          </Link>
                        </Table.Cell>
                        <Table.Cell>
                          <Form.Field className="add-numbers">
                            <Icon
                              name="minus"
                              className="orange-button"
                              onClick={() => this.countHandleChangeMinus(index)}
                            ></Icon>
                            <Input
                              value={singleProduct.qunatity}
                              name="numberOfTickets"
                              fluid
                            />
                            <Icon
                              name="plus"
                              className="orange-button plus-btn"
                              onClick={() => this.countHandleChangePlus(index)}
                            ></Icon>
                          </Form.Field>
                        </Table.Cell>
                        <Table.Cell textAlign="right">
                          {commonFunctions.isNumberInteger(singleProduct.Price)}
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
              </Table.Body>
            </Table>
          </Grid.Column>
          <Grid.Column width={16} textAlign="right">
            {!hasToShowCancellationComponent && (
              <Button
                className="blue-btn"
                onClick={
                  () => this.onSaveAddOnDetails(true)}>
                Add More items
              </Button>
            )}
            <Button
              className="orange-btn"
              onClick={() => this.onSaveAddOnDetails(false)}
            >
              Continue
            </Button>
          </Grid.Column>
        </Grid>
        
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
    orderState: state.orderState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
      getOrderItems: bindActionCreators(actions.getOrderItems, dispatch),
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddOnAllBusinessType);
