import React,{ Component } from "react";
import { Grid,Form,Divider,Button,GridColumn } from "semantic-ui-react";
import { commonFunctions } from "../../../functional/common-functions";
class CommonCutomFieldDisplay extends Component {
    constructor(props) {
        super(props);
    }

    onHandleCustomFieldDescription = (e, { value, id, data }) => {
        const { fullState, allFunction, stateForm, variableName } = this.props;
        const customFieldData = [...fullState[stateForm][variableName]];
        customFieldData[id][data] = value;
        fullState[stateForm][variableName] = customFieldData;
        allFunction.setState(fullState);
        commonFunctions.onFormChange(allFunction);
    }
    render() {
        const { fullState,stateForm,allFunction,variableName } = this.props;
        return (
            <React.Fragment>
                {
                    fullState[stateForm][variableName] && fullState[stateForm][variableName].length > 0 &&
                    fullState[stateForm][variableName] && fullState[stateForm][variableName].map((singleData,i) => {
                        return (
                            <>
                                <Grid.Column width={8}>
                                    <Form.Input className="btm-margin-text-area" fluid placeholder={singleData.customField} id={i} data="customDescriptions"
                                        onChange={this.onHandleCustomFieldDescription} value={singleData.customDescriptions}
                                        error={allFunction.validatorGeneral.message(singleData.customField,singleData.customDescriptions,"min:0|max:2000")} />
                                </Grid.Column>
                            </>)
                    }
                    )
                }
            </React.Fragment>
        )
    }
}

export default CommonCutomFieldDisplay;