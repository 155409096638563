import React, { Component } from "react";
import { Button, Modal, Grid, Form, Header, Label } from "semantic-ui-react";
import { connect, bindActionCreators, actions, commonFunctions, Notifications } from "../../../../functional/global-import";
import { withRouter } from "react-router-dom";
import { TreeSelect } from 'antd';
import 'antd/dist/antd.css';
import SimpleReactValidator from 'simple-react-validator';
import LocationsSelect from '../../../../components/atoms/locations-select';

class MoveItemsModal extends Component {
    get initialState() {
        return {
            moveItemForm: {
                userLocations: this.props.global.locationSelected.BusinessLocationId,
                categoryId: null
            },
        }
    };
    constructor(props) {
        super(props);
        this.state = {
            ...this.initialState,
           errorData: true
        }
        this.validator = new SimpleReactValidator();
    }

    onHandleChange = (e, { name, value, type, checked, data, }) => {
        var moveItemForm = commonFunctions.onHandleChange(e, { name, value, type, checked, data }, this.state.moveItemForm, this)
        this.setState({ moveItemForm })
        if (data === "userLocations") {
            this.getCategoryForProduct(value)
        }
    };

    componentDidMount() {
        this.getCategoryForProduct()
    }

    onDropDownTreeChangeRental = (value) => {
        if (value) {
            this.setState({ errorData: true })
        }
        this.setState({
            moveItemForm: { ...this.state.moveItemForm, categoryId: value },
        });
    };

    getCategoryForProduct = (value) => {
        this.props.actions.apiCall({
            urls: ["GETCATEGORYFORPRODUCT"],
            method: "GET",
            data: {
                businessId: this.props.global.bussinessDetail.businessId,
                businessLocationId: value ? value : this.props.defaultValue,
                BusinessCategoryType: this.props.global.inventoryDetail.businessCategoryId,
                PageNo: 1, PageSize: 100
            }, onSuccess: (response) => {
                if (response[0].productCategories !== null) {
                    let allCategoryList = []
                    allCategoryList.push({ value: -1, title: "Add at root level", })
                    allCategoryList = allCategoryList.concat(JSON.parse(response[0].productCategories))
                    this.setState({ allCategoryList });  
                }
                else {
                    let allCategoryList = []
                    allCategoryList.push({ value: -1, title: "Add at root level", })
                    this.setState({ allCategoryList });  
                }
            },
        });
    }

    onHandleSubmit = (e) => {
        var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
        if (isFormValid) {
            this.updateCategoryForProduct()
        }
        else {
            this.setState({ errorData: false })
        }
    }

    updateCategoryForProduct = () => {
        this.props.actions.apiCall({
            urls: ["UPDATECATEGORYFORPRODUCT"],
            method: "PUT",
            data: {
                "productId": this.props.productId,
                "categoryId": this.state.moveItemForm.categoryId,
                "businessCategoryType": this.props.global.inventoryDetail.businessCategoryId,
                "businessLocationId": this.state.moveItemForm.userLocations,
                "actionPerformedBy": this.props.auth.userDetail.emailId
            },
            onSuccess: (response) => {
                this.props.getProductCategoryDetail();
                this.props.closeModal()
            }, showNotification: true
        });
    }


    render() {
        const { moveItemForm } = this.state
        return (
            <Modal
                open={this.props.openModal}
                closeIcon
                onClose={this.props.closeModal}
                size={"tiny"}>
                <Header content="Move Item" />
                <Modal.Content >
                    <Form>
                        <Grid>
                            <Grid.Column mobile={16} tablet={8} computer={2}>
                                <Form.Field>
                                    <label>Location </label>
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column mobile={12} tablet={8} computer={6}>
                                <Form.Field>
                                    <LocationsSelect
                                        multiSelect={false}
                                        placeholder={this.props.global.locationSelected.BusinessLocationName}
                                        options={this.props.locationOption}
                                        onChange={this.onHandleChange}
                                        type="select"
                                        name="userLocations" data="userLocations"
                                        value={moveItemForm.userLocations} />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column mobile={16} tablet={8} computer={2}>
                                <Form.Field>
                                    <label>Move to </label>
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column mobile={16} tablet={8} computer={6} className="controlField">
                                <Form.Field>
                                    <TreeSelect showSearch filterTreeNode={(search, item) => {
                                        return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
                                    }} s
                                        value={moveItemForm.categoryId}
                                        treeData={this.state.allCategoryList}
                                        placeholder="Choose Inventory Location"
                                        onChange={this.onDropDownTreeChangeRental} />
                                    <div className={this.state.errorData === false ? "ui red pointing above basic label" : "abc"}>
                                        {this.validator.message("Inventory Location", moveItemForm.categoryId, "required")}
                                    </div>
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column textAlign="right" mobile={16}>
                                <Button className="blue-btn" onClick={this.props.closeModal}> Cancel </Button>
                                <Button className="orange-btn" onClick={this.onHandleSubmit}> Move </Button>
                            </Grid.Column>
                        </Grid>
                    </Form>
                </Modal.Content>
            </Modal>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        api: state.api,
        global: state.global,
        auth: state.auth
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            apiCall: bindActionCreators(actions.apiCall, dispatch),
            storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
            showNotification: bindActionCreators(Notifications.show, dispatch)
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MoveItemsModal));

