import React, { Component } from 'react';
import { Dropdown } from 'semantic-ui-react';

const options = [
    { key: "lc1", value: "lc1", text: "Key Largo, FL" },
    { key: "lc2", value: "lc2", text: "Santa Rosa, MN" },
  ];

class PhysicalLocation extends Component {
    state = { options }
    
    handleAddition = (e, { value }) => {
        this.setState((prevState) => ({
          options: [{ text: value, value }, ...prevState.options],
        }));
      };
    
    handleChange = (e, { value }) => {
        this.setState({ currentValues: value });
    };
    render() { 
        const { currentValues } = this.state;
        return (
            <Dropdown options={this.state.options} placeholder='Key Largo, FL' search selection fluid value={currentValues} onChange={this.handleChange} className="tagger"/>
            );
        }
    }
 
export default PhysicalLocation;