import React, { Component } from "react";
import { Grid, Header, Form, Button } from "semantic-ui-react";
import { CommonDeleteUpdateModal } from "../../../../components";
import AddNewTaxModal from "../add-new-tax-modal";
import { commonFunctions, connect, bindActionCreators, actions, Notifications } from "../../../../functional/global-import";
import RentalForm from "../../../../../../src/pages/more-setting/pricing-taxes-setting/rental-pricing/rental-form";
class AddItemPricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedId: "",
      time: "",
      fieldSelected: "",
      flatFeeTable: true,
      a: ""
    };
  }

  AddNewTaxModal = () => {
    this.setState({
      taxStatus: !this.state.taxStatus,
    });
  };
  deleteModal = (a) => {
    const messagetoShowInModal = 'Do you want to delete ' + a.rentalPricingTitle + " Title?";
    this.setState({
      deleteModalOpen: true,
      buttonName: "Delete",
      messageToShow: messagetoShowInModal,
      selectedId: a.rentalPricingId,
      deleteProductId: a.productId
    });
  }
  deletePricing = () => {
    if (this.state.buttonName === "Delete") {
      this.props.actions.apiCall({
        urls: ["DELETERENTALPRODUCTPRICE"], method: "DELETE", data: { "ProductId": this.state.deleteProductId, "RentalPricingId": this.state.selectedId, "DeletedBy": this.props.auth.userDetail.emailId }, onSuccess: (response) => {
          const { rentalPriceList } = this.props;
          var afterDeletedData = rentalPriceList.filter(singleList => singleList.rentalPricingId !== this.state.selectedId)
          this.setState({ rentalPriceList: afterDeletedData, deleteModalOpen: false, deleteModal: true }, () =>
            this.props.getProductPrice());
        }, showNotification: true
      });
    }
  }
  closeDeleteModal = () => this.setState({ deleteModalOpen: false })

  backStep = (e) => {
    const { allFunction } = this.props;
    allFunction.setState({ activeStep: 2 })
    this.props.allFunction.getProductImageOnUpdate(this.props.fullState.CreatedProductId);
  }

  nextStep = (e) => {
    
    const { allFunction } = this.props;
    this.props.getProductCategoryDetail && this.props.getProductCategoryDetail()
    allFunction.props.closeModal();
    allFunction.onNext(this.props.next, "lastStep")
    allFunction.allStateBlank();
    commonFunctions.onApiSucessFormChangedFalse(allFunction);
  }

  render() {
    const { buttonName, messageToShow, deleteModalOpen } = this.state;
    // const { api, onHandleItemPricing, onHandleRentalDuration, rentalPricing, addItemPricing, onHandleApplyToAll, applyAll, onHandlePriceType, validator } = this.props;

    return (
      <>
        <RentalForm productId={this.props.fullState.CreatedProductId} rentalProductDurationId={this.props.fullState.rentalProductDurationId} fullState={this.props.fullState} allFunction={this.props.allFunction} rentalPricingType={this.props.fullState.rentalPricingType} getProductCategoryDetail={this.props.getProductCategoryDetail} />

        <CommonDeleteUpdateModal
          onConfirm={this.deletePricing}
          confirmationButtonText={buttonName}
          header={messageToShow}
          isModalOpen={deleteModalOpen}
          subHeader={"Deleted records can not be reverted back."}
          onClose={this.closeDeleteModal}
          cancelButtonText={'Cancel'}
        />
        <Grid>
          <Grid.Column width={16} textAlign="right">
            <Button className="blue-btn" onClick={this.backStep}>Back</Button>
            <Button className="orange-btn" onClick={this.nextStep}>Close</Button>
          </Grid.Column>
        </Grid>

      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddItemPricing);
