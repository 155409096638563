import React, { Component } from "react";
import { DataTable } from "../../../../organisms";
import moment from "moment-timezone";
import Moment from "react-moment";
import {
  Icon,
  Grid,
  Button,
  Radio,
  Checkbox,
  Popup,
  Menu,
} from "semantic-ui-react";
import SearchInput from "../../../../widgets/SearchInput";
import { commonFunctions } from "../../../../../functional/common-functions";
import {
  connect,
  bindActionCreators,
  Notifications,
  actions,
} from "../../../../../functional/global-import";
import { Link } from "react-router-dom";
import { CommonDeleteUpdateModal } from "../../../../organisms";
import { LoaderComponent } from "../../../../../components";
import { debounce } from "lodash";
import AddSelectedGuestsModal from "../../add-Selected-Guests-Modal";
import { CLOSING } from "ws";
import { NewCustomer } from "../../../../../components";
const menustyle = {
  display: "flex",
  flexDirection: "column",
  border: "none",
};

const popupStyle = {
  padding: 0,
  border: "none",
  margin: "4px 0px",
};

const ellipsisIconStyle = {
  fontSize: 14,
  // app color
  color: "#f54952",
};

class CustomerAddGuestList extends Component {
  child = React.createRef();

  get initialState() {
    return {
      CutstomerDetailList: {
        businessId: this.props.businessId,
        businessLocationId: this.props.businessLocationId,
        customerId: -1,
        pageNo: 1,
        pageSize: 100,
        sortColumn: "",
        sortOrder: "",
        sortingArrow: "sort",
        gridDataLoading: false,
        searchValue: "",
        isActive: true,
      },
      customerForm: {
        customerId: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        stateId: "",
        emailId: "",
        city: "",
        zipcode: "",
        address: "",
        searchValue: "",
        actionPerformedBy: this.props.auth.userDetail.emailId,
        active: "",
        checkedIn: "",
        selectAll: false,
        selectCustomer: false,
        customer: null,
      },
    };
  }
  constructor(props) {
    super(props);
    this.state = {
      ...this.initialState,
      defaultUpdate: null,
      checkboxLoader: false,
      customerGuestId: null,
      gridDetail: [],
      isSelectAll: false,
      dropDownFilter: false,
      createcustomerButtonPanel: true,
      businessId: this.props.businessId,
      businessLocationId: this.props.businessLocationId,
      newGuestModalOpen: false,
      selectedCustomer: [],
      actionOnSelectedCustomer: {},
      confirmationButtonText: "",
      confirmationHeaderText: "",
      anchorEle: null,
      isConfirmationModalOpen: false,
      selectedValues: [],
      selectedGuests: [],
      businessCategoryId: this.props.businessCategoryId,
      customerId:
        this.props.orderState.currentOrder.customerDetail.fullDetail.customerId,
      ProductId: this.props.ProductId,
      OrderId:
        this.props.orderState.currentOrder.customerDetail.fullDetail.orderId,
      selectAll: false,
      showSelectAll: true,
      isCommonDeleteUpdateModalOpen: false,
      selectedGuestsModalOpen: false,
      openCreateCustomerPannel: false,
    };
  }
  selectedGuestsModal = () => {
    this.setState({
      selectedGuestsModalOpen: !this.state.selectedGuestsModalOpen,
    });
  };
  onNextFunctionCall = debounce((val) => {
    const { CutstomerDetailList } = this.state;
    if (val === "") {
      return;
    } else if (CutstomerDetailList.searchValue !== "") {
      this.child.current.getDefaultData();
    }
  }, 1000);
  handleSearch = (e) => {
    const { CutstomerDetailList } = this.state;
    let newOrderAccess = this.props.auth.userDetail.roleAndPermission?.filter(
      (item) => {
        return item.ModuleName === "new-order";
      }
    );
    let screenName = newOrderAccess[0].permission[0].Access === 0;
    if (screenName) {
      this.props.actions.showNotification(
        {
          title: "Error",
          message: "Customer List is not accessible to role assigned to you.",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    } else {
      const searchValue = e.target.value;
      const { CutstomerDetailList } = this.state;
      const prevSearch = CutstomerDetailList.searchValue;
      CutstomerDetailList["searchValue"] = searchValue;
      this.setState({ CutstomerDetailList }, () =>
        this.onNextFunctionCall(prevSearch)
      );
    }
  };

  dataSaveOfCustomer = () => {
    const { selectedCustomer, CutstomerDetailList } = this.state;

    if (Object.keys(selectedCustomer).length === 0) {
      this.props.actions.showNotification(
        {
          title: "Error",
          message: "Please select the customer before creating a order ",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    } else {
      selectedCustomer["businessLocationId"] =
        CutstomerDetailList.businessLocationId;
      selectedCustomer["actionPerformedBy"] =
        this.props.auth.userDetail.emailId;
      selectedCustomer["businessId"] = CutstomerDetailList.businessId;
      this.props.actions.createOrder(selectedCustomer);
      //   this.props.onRadioClick();
    }
  };
  radioHandleChnage = (e) => {
    const { customerForm } = this.state;
    customerForm.active = e.active;
    this.setState({ customerForm, selectedCustomer: e });
  };
  onHandleChange = (e, { name, value, type, checked, data }) => {
    var customerForm = commonFunctions.onHandleChange(
      e,
      {
        name,
        value,
        type,
        checked,
        data,
      },
      this.state.customerForm
    );
    this.setState({ customerForm });
  };
  getCustomerDetail() {
    const { CutstomerDetailList } = this.state;
    this.props.actions.apiCall({
      urls: ["GETCUSTOMER"],
      method: "GET",
      data: CutstomerDetailList,
      onSuccess: (response) => {
        this.setState({ ...this.state, gridDetail: response });
      },
    });
  }

  removeCustomer = () => {
    const { actionOnSelectedCustomer } = this.state;
    this.props.actions.apiCall({
      urls: ["DELETECUSTOMER"],
      method: "DELETE",
      data: {
        customerId: actionOnSelectedCustomer.customerId,
        actionPerformedBy: this.props.auth.userDetail.emailId,
      },
      onSuccess: (response) => {
        const { gridDetail } = this.state;
        let newGridDetail = gridDetail.filter((singleCUstomerObj) => {
          return singleCUstomerObj.customerId !== response.keyId;
        });

        this.setState({
          gridDetail: newGridDetail,
          isConfirmationModalOpen: false,
        });
      },
      showNotification: true,
    });

    return;
  };

  updateCustomerStatus = () => {
    this.props.actions.apiCall({
      urls: ["UPDATECUSTOMERTOGGLE"],
      method: "PUT",
      data: {
        customerId: this.state.customerId,
        businessId: this.props.global.bussinessDetail.businessId,
        isActive: this.state.isActive,
        actionPerformedBy: "admin",
      },
      onSuccess: (response) => {
        this.setState({ activateModalopen: false }, () =>
          this.getCustomerDetail()
        );
      },
      showNotification: true,
    });
    this.setState({
      isConfirmationModalOpen: false,
    });
  };
  openDeleteConfirmationModal = () => {
    const { actionOnSelectedCustomer } = this.state;
    const headerText = `Do you want to delete " ${actionOnSelectedCustomer.fullName}" ?`;

    this.setState({
      anchorEle: null,
      confirmationButtonText: "Delete",
      confirmationHeaderText: headerText,
      isConfirmationModalOpen: true,
    });
  };

  closeConfirmationModal = () => {
    this.setState({
      confirmationButtonText: "",
      confirmationHeaderText: "",
      isConfirmationModalOpen: false,
      actionOnSelectedCustomer: {},
    });
  };

  onEditClickButton = (customerDetail) => {
    const { customerForm } = this.state;
    customerForm.customer = customerDetail;
    this.setState({ customerForm, openCreateCustomerPannel: true });
  };

  renderMenuItems = () => {
    const { actionOnSelectedCustomer } = this.state;

    return (
      <Menu style={menustyle} className="menu-list-items">
        <Menu.Item
          name="edit"
          onClick={() => this.onEditClickButton(actionOnSelectedCustomer)}
        >
          <Icon name="edit" />
          Edit
        </Menu.Item>

        <Link
          to={`${process.env.REACT_APP_PUBLIC_URL}/customer-summary/${actionOnSelectedCustomer.customerId}`}
          target="_blank"
          className="orange-color"
        >
          <Menu.Item name="client-sumary">
            <Icon name="file alternate outline" /> Client Summary
          </Menu.Item>
        </Link>

        <Link
          to={`${process.env.REACT_APP_PUBLIC_URL}/customer-summary/${actionOnSelectedCustomer.customerId}`}
          target="_blank"
          className="orange-color"
        >
          <Menu.Item name="client-sumary">
            <Icon name="history" />
            Order History
          </Menu.Item>
        </Link>
        <Menu.Item name="delete" onClick={this.openDeleteConfirmationModal}>
          <Icon name="trash alternate" />
          Delete
        </Menu.Item>
      </Menu>
    );
  };

  renderActionIconWithPopup = () => {
    const { anchorEle } = this.state;
    return (
      <Popup
        basic={true}
        context={anchorEle}
        style={popupStyle}
        content={this.renderMenuItems()}
        on="click"
        open={anchorEle != null}
        onClose={this.closeActionPopup}
      />
    );
  };

  closeActionPopup = () => {
    this.setState({
      anchorEle: null,
    });
  };

  renderConfirmationModal = () => {
    const {
      isConfirmationModalOpen,
      confirmationHeaderText,
      confirmationButtonText,
    } = this.state;

    return (
      <CommonDeleteUpdateModal
        onConfirm={this.removeCustomer}
        confirmationButtonText={confirmationButtonText}
        header={confirmationHeaderText}
        isModalOpen={isConfirmationModalOpen}
        onClose={this.closeConfirmationModal}
        cancelButtonText={"Cancel"}
        subHeader={"Deleted records can not be reverted back."}
      />
    );
  };

  openActionPopup = (e, customer) => {
    this.setState({
      actionOnSelectedCustomer: customer,
      anchorEle: e.target,
    });
  };
  onSingleCheckbox = (obj, index) => {
    const tableDetails = this.state.gridDetail;
    tableDetails[index].isSelected = !this.state.gridDetail[index].isSelected;
    const isAllSelected = tableDetails.every((obj) => obj.isSelected === true);
    this.setState({ isSelectAll: isAllSelected, gridDetail: tableDetails });
  };
  onCheckboxClick = (index, paramName) => {
    const { gridDetail } = this.state;
    const { orderState } = this.props;
    this.setState({ checkboxLoader: true });
    const currentCustomerId =
      orderState.currentOrder.customerDetail.fullDetail.customerId;
    const updateCheckboxObj = {
      customerId:
        gridDetail[index]?.guestId === 0
          ? currentCustomerId
          : gridDetail[index]?.guestId === null
          ? currentCustomerId
          : gridDetail[index]?.guestId,
      orderId: orderState.currentOrder.customerDetail.fullDetail.orderId,
      documentId: gridDetail[index].documentId,
      active: gridDetail[index].active,
      checkIn: gridDetail[index].checkIn,
      actionPerformedBy: this.props.auth.userDetail.emailId,
      columnModified: paramName,
    };
    if (paramName === "active") {
      updateCheckboxObj.active = !gridDetail[index].active;
    }
    if (paramName === "checkIn") {
      updateCheckboxObj.checkIn = !gridDetail[index].checkIn;
    }
    this.props.actions.apiCall({
      urls: ["UPDATEACTIVECHECKIN"],
      method: "PUT",
      data: updateCheckboxObj,
      onSuccess: (response) => {
        const tableDetails = gridDetail;
        tableDetails[index][paramName] = !tableDetails[index][paramName];
        this.setState({ gridDetail: tableDetails });
      },
      onFinally: () => {
        this.setState({ checkboxLoader: false });
      },
      isFormData: false,
      showNotification: true,
    });
  };
  onSelectAll = (e, { checked }) => {
    const newTableData = this.state.gridDetail.map((singleGuestList) => {
      singleGuestList.isSelected = checked;
      return singleGuestList;
    });
    this.setState({ isSelectAll: checked, gridDetail: newTableData });
  };
  //   UPDATEACTIVECHECKIN PUT
  checkboxHandleChnage = (e, index) => {
    const { gridDetail } = this.state;
    gridDetail[index].isSelect = !gridDetail[index].isSelect;
    const allGridDetails = JSON.parse(JSON.stringify(gridDetail));
    this.handleClickGuestSelect(allGridDetails, index);
    const selectedCustomers = this.state.gridDetail
      .filter((item) => item.isSelect)
      .map((item) => {
        return { guestsId: item.customerId };
      });
    this.setState({ selectedValues: selectedCustomers });
  };

  handleClickGuestSelect = (guestDetails, index) => {
    const { selectedGuests } = this.state;
    const customerId = guestDetails[index].customerId;
    if (guestDetails[index].isSelect) {
      const found = selectedGuests.some((el) => el.customerId === customerId);
      if (!found) {
        selectedGuests.push(guestDetails[index]);
      }
      this.setState({ selectedGuests });
    } else {
      const selectedGuestsDetails = selectedGuests.filter(
        (item) => item.customerId !== customerId
      );
      this.setState({ selectedGuests: selectedGuestsDetails });
    }
  };
  onCheckGuestFromModal = (index) => {
    const { selectedGuests } = this.state;
    const newGuestDetails = JSON.parse(JSON.stringify(selectedGuests));
    newGuestDetails[index].isSelect = !newGuestDetails[index].isSelect;
    this.setState({
      selectedGuests: newGuestDetails,
      defaultUpdate: Math.random(),
    });
  };

  onCheckedSaveGuests = () => {
    const { selectedGuests } = this.state;
    const selectedCustomers = selectedGuests
      .filter((item) => item.isSelect)
      .map((item) => {
        return { guestsId: item.customerId };
      });
    this.handleSaveGuest(selectedCustomers);
  };
  handleSaveGuest = (guestDetails) => {
    const { customerId } = this.state;
    this.props.actions.apiCall({
      urls: ["ADDGUESTLIST"],
      method: "POST",
      data: {
        guests: guestDetails,
        actionPerformedBy: this.props.auth.userDetail.emailId,
        customerId: customerId,
        orderId:
          this.props.orderState.currentOrder.customerDetail.fullDetail.orderId,
      },
      onSuccess: (response) => {
        this.setState({
          CutstomerDetailList: {
            ...this.state.CutstomerDetailList,
            searchValue: "",
          },
          selectedGuestsModalOpen: false,
          openCreateCustomerPannel: false,
          selectedGuests: [],
        });
      },
      showNotification: true,
    });
  };
  onSaveGuest = () => {
    const { selectedValues } = this.state;
    if (selectedValues.length === 0) {
      this.props.actions.showNotification(
        {
          title: "Error",
          message: "Please select the customer first",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    } else if (selectedValues.length > 0) {
      this.setState({ selectedGuestsModalOpen: true });
    }
  };
  onSaveCustomer = (keyId) => {
    const { customerId, customerForm } = this.state;
    let savedCustomer = [];
    let saveCustomerId = { guestsId: keyId };
    savedCustomer.push(saveCustomerId);

    this.closeActionPopup();
    if (customerForm.customer === null) {
      this.props.actions.apiCall({
        urls: ["ADDGUESTLIST"],
        method: "POST",
        data: {
          guests: savedCustomer,
          actionPerformedBy: this.props.auth.userDetail.emailId,
          customerId: customerId,
          orderId:
            this.props.orderState.currentOrder.customerDetail.fullDetail
              .orderId,
        },
        onSuccess: (response) => {
          customerForm.customer = null;
          this.setState({ customerForm, openCreateCustomerPannel: false });
        },
        showNotification: true,
      });
    } else {
      customerForm.customer = null;
      this.setState({ customerForm, openCreateCustomerPannel: false });
    }
  };
  render() {
    const fullState = this.state;
    const allFunction = this;
    const {
      ProductId,
      customerId,
      businessCategoryId,
      businessLocationId,
      CutstomerDetailList,
      isCommonDeleteUpdateModalOpen,
      messageToShow,
      buttonName,
      checkboxLoader,
      selectedGuestsModalOpen,
      defaultUpdate,
      openCreateCustomerPannel,
    } = this.state;
    const { api, orderState } = this.props;
    return (
      <>
        <Grid>
          <>
            {!openCreateCustomerPannel ? (
              <>
                <Grid.Column width={16}>
                  <h5>Guest List & Active Signatures</h5>
                </Grid.Column>
                <SearchInput
                  hasSearchIocn={true}
                  iconPosition="left"
                  data="searchValue"
                  className="common-search-bar"
                  fluid={true}
                  searchValue={CutstomerDetailList.searchValue}
                  placeholder="Search by Name &amp; Email &amp; to add Guest List"
                  searchHandler={this.handleSearch}
                />
                {this.state.createcustomerButtonPanel && (
                  <Grid.Column width={6} textAlign="right">
                    <Button
                      className="orange-btn"
                      onClick={() => {
                        const { customerForm } = this.state;
                        customerForm.customer = null;
                        this.setState({
                          customerForm,
                          openCreateCustomerPannel: true,
                        });
                      }}
                    >
                      <Icon name="plus" /> Create Customer
                    </Button>
                  </Grid.Column>
                )}

                <Grid.Column width={16}>
                  {checkboxLoader && <LoaderComponent />}
                  {CutstomerDetailList.searchValue === "" ? (
                    <>
                      <div className="common-table">
                        {/* render first time i.e. Guest list */}
                        <DataTable
                          ref={this.child}
                          celled
                          padded
                          getApiName="GETGUESTLIST"
                          gridName="GuestCustomerGrid"
                          screenName="GuestCustomerSummaryGrid"
                          fullState={fullState}
                          allFunction={allFunction}
                          emptyGrid="No records to show"
                          // tableHeight="250px"
                          getGridDataParamters={{
                            customerId: customerId,
                            orderId: orderState.currentOrder.orderId,
                            businessCategoryType: businessCategoryId,
                            productId: ProductId,
                            businessLocationId: businessLocationId,
                            pageSize: 1000,
                          }}
                          columns={[
                            {
                              headerName: "Name",
                              fieldName: "name",
                              className: "orange-color textOverflowHidden",
                              Cell: (props) => {
                                return (
                                  <Link
                                    title={props.name}
                                    to={`${process.env.REACT_APP_PUBLIC_URL}/customer-summary/${props.customerId}`}
                                    target="_blank"
                                    className="orange-color"
                                  >
                                    {props.name}
                                  </Link>
                                );
                              },
                            },
                            {
                              headerName: "Document",
                              fieldName: "document",
                              className: "textOverflowHidden",
                              Cell: (props) => {
                                return (
                                  <span
                                    style={{
                                      color: "#4CAF50",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {props.document}
                                  </span>
                                );
                              },
                            },
                            {
                              headerName: "Date Signed",
                              fieldName: "signedDate",
                              className: "orange-color",
                              isSorting: true,
                              Cell: (props) => {
                                const isDate = props.signedDate;
                                if (isDate) {
                                  return moment(props.signedDate).format(
                                    "MMM DD,yyyy"
                                  );
                                } else {
                                  return `-`;
                                }
                              },
                            },
                            {
                              headerName: "Active",
                              fieldName: "active",
                              className: "custome-paid-checkbox",
                              Cell: (props, index) => {
                                return (
                                  <>
                                    <Checkbox
                                      className="custome-checkbox "
                                      data="active"
                                      name="active"
                                      checked={
                                        props.active === undefined
                                          ? false
                                          : props.active
                                      }
                                      onChange={() =>
                                        this.onCheckboxClick(index, "active")
                                      }
                                    />
                                  </>
                                );
                              },
                            },
                            {
                              headerName: "Checked In",
                              fieldName: "checkIn",
                              className: "custome-paid-checkbox",
                              Cell: (props, index) => {
                                return (
                                  <Checkbox
                                    className="custome-checkbox "
                                    data="checkIn"
                                    name="checkIn"
                                    checked={
                                      props.checkIn === undefined
                                        ? false
                                        : props.checkIn
                                    }
                                    onChange={() =>
                                      this.onCheckboxClick(index, "checkIn")
                                    }
                                  />
                                );
                              },
                            },
                            {
                              headerName: "Action",
                              fieldName: "action",
                              isSorting: false,
                              Cell: (props) => {
                                return (
                                  <React.Fragment>
                                    <Icon
                                      disabled={props.customerGuestId === null}
                                      name="trash alternate"
                                      size="large"
                                      className="orange-color pointer"
                                      title="Delete"
                                      link
                                      onClick={() =>
                                        commonFunctions.deleteModalMessage(
                                          allFunction,
                                          props.name,
                                          props.customerGuestId,
                                          "customerGuestId"
                                        )
                                      }
                                    />
                                  </React.Fragment>
                                );
                              },
                            },
                          ]}
                        />
                      </div>
                      <div style={{ textAlign: "right", paddingTop: "15px" }}>
                        <Button
                          loading={api.isApiLoading}
                          className="orange-btn"
                          onClick={() => this?.props?.onContinueClick?.()}
                        >
                          Continue
                        </Button>
                      </div>
                    </>
                  ) : (
                    //  render when user searched
                    <Grid.Column width={16}>
                      <Grid>
                        <>
                          <Grid.Column width={16}>
                            <div>
                              <DataTable
                                celled
                                padded
                                ref={this.child}
                                getApiName="GETCUSTOMER"
                                getGridDataParamters={{
                                  searchValue: CutstomerDetailList.searchValue,
                                }}
                                gridName="OrderCustomerGrid"
                                screenName="CustomerSummaryGrid"
                                fullState={fullState}
                                allFunction={allFunction}
                                tableHeight="310px"
                                emptyGrid="No records to show"
                                columns={[
                                  {
                                    headerName: "Select",
                                    fieldName: "isSelect",
                                    headerClass: "custom-width",
                                    className: "custom-width",
                                    isSorting: false,
                                    Cell: (props, index) => {
                                      return (
                                        <Checkbox
                                          className="custome-checkbox"
                                          name="isSelect"
                                          checked={
                                            props.isSelect === undefined
                                              ? false
                                              : props.isSelect
                                          }
                                          data="isSelect"
                                          onChange={() =>
                                            this.checkboxHandleChnage(
                                              props,
                                              index
                                            )
                                          }
                                        />
                                      );
                                    },
                                  },
                                  {
                                    headerName: "Minor",
                                    fieldName: "Minor",
                                    headerClass: "custom-width",
                                    className:
                                      "textOverflowHidden custom-width",
                                    isSorting: false,
                                    Cell: (props) => {
                                      return (
                                        <>
                                          {props.minor === true ? (
                                            <Checkbox
                                              className="custome-checkbox "
                                              data="Minor"
                                              checked={props.minor}
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      );
                                    },
                                  },

                                  {
                                    headerName: "Name",
                                    fieldName: "FirstName",
                                    isSorting: true,
                                    className: "textOverflowHidden",
                                    Cell: (props) => {
                                      return (
                                        <Link
                                          title={
                                            props.firstName +
                                            " " +
                                            (props.lastName !== null
                                              ? props.lastName
                                              : "")
                                          }
                                          to={`${process.env.REACT_APP_PUBLIC_URL}/customer-summary/${props.customerId}`}
                                          target="_blank"
                                          className="orange-color"
                                        >
                                          {props.firstName} {props.lastName}
                                        </Link>
                                      );
                                    },
                                  },
                                  {
                                    headerName: "Phone No.",
                                    fieldName: "phoneNumber",
                                    isSorting: true,
                                  },
                                  {
                                    headerName: "Document",
                                    fieldName: "document",
                                    className: "textOverflowHidden",
                                    isSorting: false,
                                    Cell: (props) => {
                                      const document =
                                        props?.document === null
                                          ? "-"
                                          : props?.document;
                                      return (
                                        <span
                                          style={{ color: "#007500" }}
                                          title={document}
                                        >
                                          <strong>{document}</strong>
                                        </span>
                                      );
                                    },
                                  },
                                  {
                                    headerName: "Date Signed",
                                    fieldName: "signedDate",
                                    className: "textOverflowHidden",
                                    Cell: (props) => {
                                      const signedDate =
                                        props?.signedDate === null
                                          ? "-"
                                          : props?.signedDate;
                                      return (
                                        <span
                                          className="orange-color"
                                          title={signedDate}
                                        >
                                          {signedDate}
                                        </span>
                                      );
                                    },
                                  },
                                  {
                                    headerName: "DOB",
                                    fieldName: "DOB",
                                    isSorting: true,
                                    className: "textOverflowHidden",
                                    Cell: (props) => {
                                      return (
                                        <>
                                          {props.dob !== null ? (
                                            <Moment format="MM/DD/YYYY">
                                              {props?.dob}
                                            </Moment>
                                          ) : (
                                            "-"
                                          )}
                                        </>
                                      );
                                    },
                                  },
                                  {
                                    headerName: "Flag Profile",
                                    fieldName: "FlagProfile",
                                    Cell: (props) => {
                                      return (
                                        <>
                                          {props?.flagProfile ? (
                                            <Icon
                                              size="big"
                                              name={
                                                props.flagProfile
                                                  ? "flag"
                                                  : "flag outline"
                                              }
                                              style={
                                                props.flagProfile
                                                  ? { color: "red" }
                                                  : { color: "" }
                                              }
                                            />
                                          ) : (
                                            <Icon
                                              size="big"
                                              name={"flag outline"}
                                            />
                                          )}
                                        </>
                                      );
                                    },
                                  },
                                  {
                                    headerName: "Email",
                                    fieldName: "EmailId",
                                    isSorting: true,
                                    className: "textOverflowHidden",
                                    Cell: (props) => {
                                      return (
                                        <a
                                          title={
                                            props.emailId === null
                                              ? "-"
                                              : props.emailId
                                          }
                                          className="orange-color"
                                          href={`mailto:${props.emailId}`}
                                        >
                                          {props.emailId === null
                                            ? "-"
                                            : props.emailId}
                                        </a>
                                      );
                                    },
                                  },
                                  {
                                    headerName: "Action",
                                    fieldName: "Action",
                                    isSorting: false,
                                    Cell: (props) => {
                                      return (
                                        <>
                                          <Icon
                                            name="ellipsis horizontal"
                                            onClick={(e) =>
                                              this.openActionPopup(e, props)
                                            }
                                            style={ellipsisIconStyle}
                                            size="large"
                                            link
                                          />
                                        </>
                                      );
                                    },
                                  },
                                ]}
                              />
                            </div>
                          </Grid.Column>
                          <Grid.Column width={16} textAlign="right">
                            <Button
                              className="orange-btn"
                              loading={api.isApiLoading}
                              onClick={this.onSaveGuest}
                            >
                              Add to Guest List
                            </Button>
                          </Grid.Column>
                        </>
                      </Grid>
                    </Grid.Column>
                  )}
                  {this.renderActionIconWithPopup()}
                  {this.renderConfirmationModal()}
                </Grid.Column>
              </>
            ) : (
              <NewCustomer
                onClickButton={() => {
                  const { customerForm } = this.state;
                  customerForm.customer = null;
                  this.setState({
                    customerForm,
                    openCreateCustomerPannel: false,
                  });
                }}
                onSaveCustomer={this.onSaveCustomer}
                customer={this.state.customerForm.customer}
                isNewOrderGuestlist={true}
              />
            )}
          </>
        </Grid>
        {isCommonDeleteUpdateModalOpen && (
          <CommonDeleteUpdateModal
            isModalOpen={isCommonDeleteUpdateModalOpen}
            onConfirm={() =>
              commonFunctions.onConfirmFunction(
                allFunction,
                "customerGuestId",
                "gridDetail",
                "DELETECUSTOMERGUEST"
              )
            }
            onClose={() =>
              commonFunctions.onCloseDeleteUpdateModal(
                allFunction,
                "customerGuestId"
              )
            }
            confirmationButtonText={buttonName}
            header={messageToShow}
            cancelButtonText={"Cancel"}
          />
        )}
        {selectedGuestsModalOpen && (
          <AddSelectedGuestsModal
            defaultUpdate={defaultUpdate}
            onCheckedSaveGuests={this.onCheckedSaveGuests}
            onCheckGuestFromModal={this.onCheckGuestFromModal}
            selectedGuests={this.state.selectedGuests}
            openModal={selectedGuestsModalOpen}
            closeModal={this.selectedGuestsModal}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (storeState) => {
  return {
    api: storeState.api,
    global: storeState.global,
    auth: storeState.auth,
    customerState: storeState.customerState,
    orderState: storeState.orderState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
      createOrder: bindActionCreators(actions.createOrder, dispatch),
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerAddGuestList);
