import React, { Component } from "react";
import { Grid, Button, Icon, Form, Popup, Input ,Dimmer,Loader} from "semantic-ui-react";
import { commonFunctions, bindActionCreators, actions, connect, Notifications } from "../../../../functional/global-import";


class BundlePricing extends Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  };

  
componentDidMount() {
    this.props.defaultprising();
}
  render() {
    const { handleRemoveSpecificRow, timeFrameList, onHandleChange, postBundalPricing,api,
    backStep, bundlePrice, validator, handleChange, allFunction, bundlePricingType,fullState, handleAddRow } = this.props;
    const ExistingPrices = (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "BundlePricingType", "Use Existing Prices")).globalCodeId;
    const CustomPrice = (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "BundlePricingType", "Custom Prices")).globalCodeId;
    return (
      <React.Fragment>
          {api.isApiLoading && (
            <Dimmer active inverted>
              <Loader />
            </Dimmer>
                )}
        <Grid>
          {/* <Grid.Column width={16}>
            <Form.Radio className="custom-radio-btn" name="radioGroup" value={ExistingPrices} data="bundlePricingType" onChange={onHandleChange}
              checked={bundlePricingType === ExistingPrices}
              label={
                <label>
                  Use existing Prices{" "}
                  <Popup content="This will ONLY be an option if NO rental items are selected" trigger={<Icon name="info circle" className="orange-color" />} />
                </label>
              }
            /></Grid.Column> */}
          <Grid.Column width={16}>
            <Form.Radio className="custom-radio-btn" name="radioGroup" label="Custom Price" data="bundlePricingType" value={CustomPrice} onChange={onHandleChange} checked={bundlePricingType === CustomPrice} />
          </Grid.Column>
          <Grid.Column width={16}>

            {bundlePrice.bundlePricing.map((item, index) => {
             
              return (
                <Grid columns="3">
                  {!item.isDeleted && <> <Grid.Column width={4} >
                    <Form.Select data="timeFrameId"
                      name="timeFrameId"
                      type="select" fluid placeholder="Time Frame" disabled={bundlePricingType === ExistingPrices}       
                      options={timeFrameList} key={index} value={item.timeFrameId} onChange={(e, { name, value, type, checked, data, key }) => onHandleChange(e, { name, value, type, checked, data, key }, index)} />
                  </Grid.Column>


                    <Grid.Column width={4}>
                      <Input
                        label={{ basic: true, content: '$' }}
                        labelPosition='left'
                        data="price"
                        type="number" placeholder="Price"
                        disabled={bundlePricingType === ExistingPrices}
                        value={item.price === null ? "" : item.price}
                        // value={item.price}      
                        name='price'
                        onChange={(e, { name, value, type, checked, data, key }) => onHandleChange(e, { name, value, type, checked, data, key }, index)}
                        className="form-control" 
                        fluid/>
                        {allFunction.validatorPrice.message("Price",  fullState.bundlePrice.bundlePricing[index].price, "min:0,num|min:0|max:9")}
                    </Grid.Column>

                    <Grid.Column width={3}>
                      <Icon name="minus" disabled={bundlePricingType === ExistingPrices} className="orange-color" key={index} link size="big" onClick={() => handleRemoveSpecificRow(index)} />
                      <Popup content='Add multiple price' trigger={
                        <Icon name="plus" disabled={bundlePricingType === ExistingPrices} className="orange-color" onClick={handleAddRow} link size="big" />} />
                    </Grid.Column> </>}
                </Grid>
              )
            })}
          </Grid.Column>
          <Grid.Column width={16} textAlign="right">
            <Button className="blue-btn" onClick={backStep}> Back </Button>
            <Button className="orange-btn" onClick={() => allFunction.postBundalPricing()}> Save </Button>
          </Grid.Column>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    api: state.api,
    auth: state.auth,
    global: state.global
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch)
    }
  };
};
export default (connect(mapStateToProps, mapDispatchToProps)(BundlePricing));
