import React, { Component } from "react";
import { Grid, Form } from "semantic-ui-react";
import { commonFunctions, connect, bindActionCreators, actions, Notifications } from "../../../functional/global-import";

class CommonChooseTags extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tagList: [],
            selectedTags: [],
            newTagSelected: false
        };
    }

    componentDidUpdate(prevProps, prevState) {
        const { fullState, stateForm, variableName } = this.props;
        if (this.props.fullState[stateForm][variableName] !== this.state.selectedTags &&
            this.state.tagList.length > 0 && fullState.addOrEdit !== "add" && this.state.newTagSelected === false) {
            if (fullState[stateForm][variableName] && fullState[stateForm][variableName].length > 0) {
                commonFunctions.dropdownDeletedValue(fullState, stateForm, variableName, this.state.selectedTags, this.state.tagList, this);
                this.setState({ selectedTags: fullState[stateForm][variableName] });
            }
        }
    }
    
    componentDidMount() {
        this.props.actions.apiCall({
            urls: ["GETTAGS"], method: "GET", data: { "TagId": -1, "BusinessId": this.props.global.bussinessDetail.businessId, "BusinessLocationId ": this.props.global.locationSelected.BusinessLocationId, "PageNo": "1", "PageSize": "10000", isActive: true }, onSuccess: (response) => {
                const getTagData = response.map((tagData) => {
                    // id: tagData.tagId,
                    return { value: tagData.titleName, text: tagData.titleName }
                })
                this.setState({ tagList: getTagData, selectedTags: [] });
            }
        });
    }

    onHandleChange = (e, { name, value, type, checked, data }) => {
        const { fullState, stateForm, allFunction } = this.props;
        var addTags = commonFunctions.onHandleChange(e, { name, value, type, checked, data }, fullState[stateForm]);
        if (value.length === 0 || value[value.length - 1].length < 50) {
            fullState[stateForm] = addTags;
            this.setState({ addTags, newTagSelected: true });
            commonFunctions.onFormChange(allFunction);
        } else {
            return false
        }
    };

    onHandleAddition = (e, { value }) => {
        if (value.length < 50) {
            this.setState((prevState) => ({
                tagList: [{ text: value, value }, ...prevState.tagList],
            }))
        }
        else {
            this.props.actions.showNotification({
                title: "Error", message: 'Tag length must be less than 50 character', position: 'br',
                autoDismiss: commonFunctions.notificationTime()
            }, "error");
        }
    }

    render() {
        const { fullState, stateForm, variableName } = this.props;
        return (<React.Fragment>
            <Grid.Column mobile={16} tablet={8} computer={8}>
                <Form.Dropdown search selection multiple allowAdditions fluid placeholder="Tags- will be shown on Website" options={this.state.tagList}
                    value={fullState[stateForm][variableName]} data={variableName} type="multiple-select" className="tagger"
                    onChange={this.onHandleChange} onAddItem={this.onHandleAddition} name="ddlChooseTags" />
            </Grid.Column>
        </React.Fragment>)
    }
}

const mapStateToProps = (state) => {
    return {
        api: state.api,
        global: state.global,
        auth: state.auth
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            apiCall: bindActionCreators(actions.apiCall, dispatch),
            storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
            showNotification: bindActionCreators(Notifications.show, dispatch),
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CommonChooseTags);