import React, { Component } from "react";
import { Button, Grid, Modal, Form, Icon, Label } from "semantic-ui-react";
import {
  connect,
  bindActionCreators,
  actions,
  commonFunctions,
} from "../../../../functional/global-import";
import { GlobalCodeSelect } from "../../../../components";

class SlotBasedDuration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customDurationForm: {
        title: "",
        isTitle: false,
        productId: this.props.productId,
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        businessCategoryType: this.props.businessCategoryType,
        durationType: null,
        duration: 0,
        price: null,
        deposit: null,
        actionPerformedBy: this.props.auth.userDetail.emailId,
      },
    };
    this.validator = commonFunctions.initializeSimpleValidator();
  }

  subrtactQuantity = () => {
    const { customDurationForm } = this.state;
    if (customDurationForm.duration === 0) {
      customDurationForm.duration = 0;
      this.setState({ customDurationForm });
    } else {
      customDurationForm.duration = customDurationForm.duration - 1;
      this.setState({ customDurationForm });
    }
  };

  additionQuantity = () => {
    const { customDurationForm } = this.state;
    customDurationForm.duration = customDurationForm.duration + 1;
    this.setState({ customDurationForm });
  };

  onHandleChange = (e, { name, value, type, checked, data }) => {
    var customDurationForm = commonFunctions.onHandleChange(
      e,
      { name, value, type, checked, data },
      this.state.customDurationForm
    );
    this.setState({ customDurationForm });
  };

  handleChange = (e, { value, name }) => {

    const newAmount = parseFloat(value);
    if (newAmount >= 0) {
      this.setState({
        customDurationForm: {
          ...this.state.customDurationForm,
          [name]: newAmount,
        },
      });
    } else {
      this.setState({
        customDurationForm: { ...this.state.customDurationForm, [name]: null },
      });
    }
  };
  handleTitleChange = (e, { value, name }) => {
    this.validator.purgeFields();
    this.setState({
      customDurationForm: {
        ...this.state.customDurationForm,
        [name]: name === "isTitle" ? !value : value,
      },
    });
  };

  onSaveDurationBased = (e) => {
    var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
    if (isFormValid) {
      this.props.actions.apiCall({
        urls: ["ADDCUSTOMDURATION"],
        method: "POST",
        data: this.state.customDurationForm,
        onSuccess: (response) => {
          this.props.closeModal();
        },
        showNotification: true,
      });
    }
  };

  render() {
    const { api } = this.props;
    const { customDurationForm } = this.state;

    return (
      <Modal
        open={this.props.openModal}
        closeIcon
        closeOnDimmerClick={false}
        onClose={this.props.closeModal}
        size="mini"
      >
        <Modal.Header>Add Custom Duration </Modal.Header>
        <Modal.Content>
          <Form loading={api.isApiLoading}>
            <Grid>
              <Grid.Column width={8}>
                <Form.Input
                  labelPosition="left"
                  name="title"
                  placeholder="Title"
                  value={customDurationForm.title}
                  onChange={this.handleTitleChange}
                  error={
                    customDurationForm.isTitle &&
                      customDurationForm.title.trim().length >= 0
                      ? this.validator.message(
                        "title",
                        customDurationForm.title,
                        "required|min:0|max:30"
                      )
                      : null
                  }
                ></Form.Input>
              </Grid.Column>
              <Grid.Column width={8} className="isShowTitleCheckbox">
                <Form.Checkbox
                  className="custome-checkbox"
                  label="Show Title"
                  name="isTitle"
                  value={customDurationForm.isTitle}
                  onChange={this.handleTitleChange}
                     />
              </Grid.Column>

              <Grid.Column width={8}>
                <Form.Input
                  labelPosition="left"
                  type="number"
                  className="amountInput"
                  name="price"
                  error={this.validator.message(
                    "price",
                    customDurationForm.price,
                    "required|min:1,num"
                  )}
                  placeholder="Price"
                  value={customDurationForm.price}
                  onChange={this.handleChange}>
                  <Label basic>$</Label>
                  <input />
                </Form.Input>
              </Grid.Column>
              <Grid.Column width={8}>
                <Form.Input
                  labelPosition="left"
                  type="number"
                  className="amountInput"
                  name="deposit"
                  placeholder="Deposit"
                  value={customDurationForm.deposit}
                  onChange={this.handleChange}
                  error={this.validator.message(
                    "deposit",
                    customDurationForm.deposit,
                    "min:0,num"
                  )}
                >
                  <Label basic>$</Label>
                  <input />
                </Form.Input>
              </Grid.Column>
              <Grid.Column width={8}>
                <Form.Field className="add-numbers Rquantity">
                  <Icon
                    name="minus"
                    data="quantity"
                    className="orange-button"
                    onClick={this.subrtactQuantity}
                  ></Icon>
                  <Form.Input
                    readOnly
                    placeholder="Quantity"
                    fluid
                    value={customDurationForm.duration}
                  />
                  <Icon
                    name="plus"
                    className="orange-button plus-btn"
                    data="quantity"
                    onClick={this.additionQuantity}
                  ></Icon>
                </Form.Field>
                {this.validator.message(
                  "duration",
                  customDurationForm.duration,
                  "required|min:1,num"
                )}
              </Grid.Column>
              <Grid.Column width={8}>
                <GlobalCodeSelect
                  placeholder="Duration"
                  data="durationType"
                  name="rentalSlotBased"
                  onChange={this.onHandleChange}
                  categoryType="Duration"
                  value={customDurationForm.durationType}
                  className="custom-select"
                />
                {this.validator.message(
                  "duration",
                  customDurationForm.durationType,
                  "required"
                )}
                {/* <Select options={Hours} className="custom-select" defaultValue="hour" fluid /> */}
              </Grid.Column>
              <Grid.Column width={16} textAlign="right">
                <Button className="blue-btn" onClick={this.props.closeModal}>
                  Cancel
                </Button>
                <Button
                  className="orange-btn"
                  onClick={this.onSaveDurationBased}
                >
                  Save
                </Button>
              </Grid.Column>
            </Grid>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      // storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      // showNotification: bindActionCreators(Notifications.show, dispatch)
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SlotBasedDuration);
