import React, { Component } from "react";
import {
  Button,
  Modal,
  Form,
  Grid,
  Icon,
  Checkbox,
  GridColumn,
  GridRow,
} from "semantic-ui-react";
import {
  GlobalCodeSelect,
  PhoneNumberInput,
  AddCommentModal,
  CommonDeleteUpdateModal,
} from "../../../../components";
import {
  commonFunctions,
  connect,
  bindActionCreators,
  actions,
  Notifications,
} from "../../../../functional/global-import";
import CommonCropImage from "../../../atoms/common-crop-image";
import { DateInput } from "semantic-ui-calendar-react";
import AddWavierModalList from "../add-WaverModal-list";
import CommonCutomFieldButton from "../../common-custom-field-button";
import CommonCutomFieldDisplay from "../../common-custom-field-display";

class CustomerModal extends Component {
  get initialState() {
    return {
      customerForm: {
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        firstName: "",
        lastName: "",
        phoneNumber: "",
        stateId: null,
        emailId: "",
        city: "",
        zipcode: "",
        address: "",
        active: true,
        actionPerformedBy: this.props.auth.userDetail.emailId,
        customerId: "",
        image: [],
        Minor: false,
        Dob: "",
        CustomField: [],
        CustomFieldDisplay: [],
        flagProfile: false,
        seasonPassId: "",
        isSeason: false,
      },
      fileList: [],
      imageData: [],
      isCommonDeleteUpdateModalOpen: false,
    };
  }
  constructor(props) {
    super(props);
    this.state = {
      ...this.initialState,
      newCustomerModalOpen: true,
      customerDetails: [],
      editForm: false,
      customerImageId: "",
      newCustomerUpdateModalOpen: false,
    };
    this.validator = commonFunctions.initializeSimpleValidator();
    this.validatorGeneral = commonFunctions.initializeSimpleValidator();
  }
  // This function is used open/close Add Comment Modal
  addCommentModalFun = () => {
    const { customerForm } = this.state;
    if (customerForm && customerForm.customerId === "") {
      this.props.actions.showNotification(
        {
          title: "Error",
          message: "Customer did not exist yet Create customer first",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    } else {
      this.setState({ commentStatus: !this.state.commentStatus });
    }
  };
  onImageChange = (imageList) => {
    this.setState(() => ({
      customerForm: {
        ...this.state.customerForm,
        image: imageList,
        isImageUpdated: true,
      },
    }));
    commonFunctions.onFormChange(this);
  };

  static getDerivedStateFromProps(props, state) {
    if (
      props.editSelectedData &&
      props.editForm &&
      props.editSelectedData.customerId !== state.customerForm.customerId
    ) {
      const imageData = JSON.parse(props.editSelectedData.image);
      const imageDetail = {
        uid: "2",
        status: "done",
        url:
          imageData === null
            ? commonFunctions.concatenateImageWithAPIUrl(null)
            : commonFunctions.concatenateImageWithAPIUrl(
              imageData[0].ImageFile
            ),
      };       
      return {
        fileList: [imageDetail],
        imageData,
        customerImageId: props.editSelectedData.customerId,
        ImageId: imageData === null ? null : imageData[0].ImageId,
        customerForm: {
          ...state.customerForm,
          firstName: props.editSelectedData.firstName,
          lastName: props.editSelectedData.lastName,
          phoneNumber: props.editSelectedData.phoneNumber,
          stateId: props.editSelectedData.stateId,
          emailId: props.editSelectedData.emailId,
          city: props.editSelectedData.city,
          zipcode: props.editSelectedData.zipCode,
          address: props.editSelectedData.address,
          customerId: props.editSelectedData.customerId,
          Minor: props.editSelectedData.minor,
          Dob:
            props.editSelectedData.dob == null
              ? null
              : commonFunctions.formatDate(props.editSelectedData.dob),
          flagProfile: props.editSelectedData.flagProfile,
          seasonPassId: JSON.parse(props.editSelectedData.seasonPassId),
          isSeason: props.editSelectedData.isSeason,
          CustomFieldDisplay:
            props.editSelectedData.customField === null
              ? []
              : JSON.parse(props.editSelectedData.customField),
        },
      };
    }
    return null;
  }

  onHandleSubmit = (e) => {
    const CustomFields = JSON.stringify(
      this.state.customerForm.CustomFieldDisplay
    );
    this.state.customerForm.CustomField = CustomFields;
    var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
    if (isFormValid) {
      var formData = commonFunctions.getFormData(this.state.customerForm);
      this.props.actions.apiCall({
        urls: ["ADDCUSTOMER"],
        method: "POST",
        data: formData,
        onSuccess: (response) => {
          commonFunctions.onApiSucessFormChangedFalse(this);
          this.onCloseModal();
          this.props.getCustomerDetail();
        },
        showNotification: true,
      });
    }
  };
  onHandleUpdate = (e) => {
    const CustomFields = JSON.stringify(
      this.state.customerForm.CustomFieldDisplay
    );
    this.state.customerForm.CustomField = CustomFields;
    var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
    if (isFormValid) {
      var formData = commonFunctions.getFormData(this.state.customerForm);
      this.props.actions.apiCall({
        urls: ["UPDATECUSTOMER"],
        method: "PUT",
        data: formData,
        onSuccess: (response) => {
          commonFunctions.onApiSucessFormChangedFalse(this);
          this.onCloseModal();
          this.setState({ updateButton: false });
          this.props.getCustomerDetail();
        },
        showNotification: true,
      });
    }
  };

  onHandleChange = (e, { name, value, type, checked, data }) => {
    var customerForm = commonFunctions.onHandleChange(
      e,
      { name, value, type, checked, data },
      this.state.customerForm,
      this
    );
    this.setState({ customerForm });
  };

  onCloseModal = () => {
    if (this.props.warnUserState.isFormChanged) {
      this.setState({ isCommonDeleteUpdateModalOpen: true });
      this.validator = commonFunctions.initializeSimpleValidator();
    } else {
      this.onModalCloseFormFalse();
    }
  };

  onModalCloseFormFalse = () => {
    this.props.closeModal();
    this.setState(this.initialState);
    this.validator = commonFunctions.initializeSimpleValidator();
  };

  onConfirmWarnUser = () => {
    this.setState({ isCommonDeleteUpdateModalOpen: false });
    commonFunctions.onApiSucessFormChangedFalse(this);
    this.onModalCloseFormFalse();
  };
  newCustomerModal = () => {
    this.setState({
      newCustomerUpdateModalOpen: !this.state.newCustomerUpdateModalOpen,
    });
  };
  handleDateChange = (event, { value }) => {
    this.setState(() => ({
      customerForm: {
        ...this.state.customerForm,
        Dob: value,
      },
    }));
  };
  handleFlagChange = (event, { value }) => {
    this.setState(() => ({
      customerForm: {
        ...this.state.customerForm,
        flagProfile: !this.state.customerForm.flagProfile,
      },
    }));
  };

  render() {
    const {
      customerForm,
      isCommonDeleteUpdateModalOpen,
      newCustomerUpdateModalOpen,
      commentStatus,
    } = this.state;
    const parsedData = customerForm.seasonPassId;
      
    return (
      <React.Fragment>
        <Modal
          open={this.props.openModal}
          closeIcon
          onClose={this.onCloseModal}
          size="medium"
        >
          <Modal.Header>
            {this.props.editForm ? "Update Customer" : "Add New Customer"}
          </Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Grid>
                <Grid.Column
                  mobile={16}
                  tablet={4}
                  computer={4}
                  className="controlField"
                >
                  <Grid>
                    <GridRow>
                      <GridColumn>
                        <div className="flex">
                          <CommonCropImage
                            ImageType={"CustomerImage"}
                            fullState={this.state}
                            cropId={this.state.customerImageId}
                            StateRef={this}
                            isDefaultImage={false}
                            stateForm={this.state.customerForm}
                          />
                          <div className="right-buttons">
                            <Icon
                              className="green-color"
                              name="file alternate outline "
                              size="big"
                              onClick={this.newCustomerModal}
                            />
                            <div onClick={this.addCommentModalFun}>
                              <span
                                class="iconify maroon-color"
                                data-icon="mdi:comment-plus"
                                data-inline="false"
                                data-flip="horizontal"
                                style={{ fontSize: "30px" }}
                              ></span>
                            </div>
                          </div>
                        </div>
                      </GridColumn>
                    </GridRow>
                    <GridRow>
                      <GridColumn mobile={16} tablet={16} computer={16}>
                        <Checkbox
                          style={{
                            display: "flex",
                            height: "100%",
                            alignItems: "center",
                          }}
                          label={
                            <label>
                              {" "}
                              <b>Minor</b>{" "}
                            </label>
                          }
                          className="custome-checkbox"
                          data="Minor"
                          checked={customerForm.Minor}
                          onChange={this.onHandleChange}
                        />
                      </GridColumn>
                      <GridColumn
                        mobile={16}
                        tablet={16}
                        computer={16}
                        className="pt-3"
                      >
                        <DateInput
                          className="fullwidth bordered-input"
                          dateFormat={"MM/DD/YYYY"}
                          // disable={customerForm.Dob ? true : false}
                          name="DOB"
                          closable={() => false}
                          iconPosition="right"
                          placeholder="MM/DD/YYYY"
                          value={customerForm.Dob}
                          onChange={this.handleDateChange}
                        />
                      </GridColumn>
                      <p>&nbsp;</p>
                      <GridColumn mobile={16} tablet={16} computer={16}>
                        <CommonCutomFieldButton
                          fullState={this.state}
                          allFunction={this}
                          stateForm="customerForm"
                          variableName="CustomFieldDisplay"
                        />
                        <div className="spacer" />
                      </GridColumn>
                      <p>&nbsp;</p>
                      <GridColumn mobile={16} tablet={16} computer={16}>
                        <Checkbox
                          label={
                            <label>
                              {" "}
                              <b>Passes Account</b>{" "}
                            </label>
                          }
                          disabled
                          className="custome-paid-checkbox "
                          data="isSeason"
                          checked={customerForm.isSeason}
                          onChange={this.onHandleChange}
                        />
                        <div className="spacer" />
                      </GridColumn>
                    </GridRow>
                  </Grid>
                </Grid.Column>

                <Grid.Column mobile={16} tablet={10} computer={10}>
                  <Form>
                    <Grid>
                      <Grid.Column width={8}>
                        <Form.Field>
                          <Form.Input
                            placeholder="First Name"
                            data="firstName"
                            onChange={this.onHandleChange}
                            value={customerForm.firstName}
                            error={this.validator.message(
                              "firstName",
                              customerForm.firstName,
                              "required|max:25"
                            )}
                          />
                        </Form.Field>
                      </Grid.Column>
                      <Grid.Column width={8}>
                        <Form.Field>
                          <Form.Input
                            placeholder="Last Name"
                            data="lastName"
                            value={customerForm.lastName}
                            onChange={this.onHandleChange}
                            error={this.validator.message(
                              "lastName",
                              customerForm.lastName,
                              "required|max:25"
                            )}
                          />
                        </Form.Field>
                      </Grid.Column>
                      <Grid.Column width={8}>
                        <Form.Field>
                          <PhoneNumberInput
                            value={customerForm.phoneNumber}
                            onChange={(value, country, e, formattedValue) => {
                              this.onHandleChange(e, {
                                name: "phoneNumber",
                                value: formattedValue,
                                type: "phoneNumber",
                                data: "phoneNumber",
                              });
                            }}
                          />
                        </Form.Field>
                      </Grid.Column>
                      <Grid.Column width={8}>
                        <Form.Field>
                          <Form.Input
                            placeholder="Email Address"
                            data="emailId"
                            value={customerForm.emailId}
                            onChange={this.onHandleChange}
                          // error={this.validator.message(
                          //   "email",
                          //   customerForm.emailId,
                          //   "required|email"
                          // )}
                          />
                        </Form.Field>
                      </Grid.Column>
                      <Grid.Column width={8}>
                        <Form.Field>
                          <Form.Input
                            placeholder="Address"
                            data="address"
                            value={customerForm.address}
                            onChange={this.onHandleChange}
                          />
                        </Form.Field>
                      </Grid.Column>
                      <Grid.Column width={8}>
                        <Form.Field>
                          <Form.Input
                            placeholder="City & Country (If International)"
                            data="city"
                            value={customerForm.city}
                            onChange={this.onHandleChange}
                          />
                        </Form.Field>
                      </Grid.Column>
                      <Grid.Column width={8}>
                        <GlobalCodeSelect
                          placeholder="Select State"
                          name="ddpState"
                          data="stateId"
                          value={customerForm.stateId}
                          onChange={this.onHandleChange}
                          categoryType="State"
                        />
                      </Grid.Column>
                      <Grid.Column width={8}>
                        <Form.Field>
                          <Form.Input
                            placeholder="Zip Code"
                            data="zipcode"
                            value={customerForm.zipcode}
                            onChange={this.onHandleChange}
                            error={this.validator.message(
                              "zipcode",
                              customerForm.zipcode,
                              "integer|min:5|max:6"
                            )}
                          />
                        </Form.Field>
                      </Grid.Column>
                      <CommonCutomFieldDisplay
                        fullState={this.state}
                        allFunction={this}
                        stateForm="customerForm"
                        variableName="CustomFieldDisplay"
                      />
                    </Grid>
                  </Form>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={2} computer={2}>
                  {" "}
                  <Icon
                    size="big"
                    name={customerForm.flagProfile ? "flag" : "flag outline"}
                    style={
                      customerForm.flagProfile
                        ? { color: "red" }
                        : { color: "" }
                    }
                    value={customerForm.flagProfile}
                    onClick={this.handleFlagChange}
                  />
                </Grid.Column>
                <GridColumn mobile={16} tablet={16} computer={16}>
                  <div className={`passes-account ${parsedData && parsedData?.length > 5 ? "scrollable" : ""}`}>
                    {parsedData && parsedData?.length > 0 ? (
                      parsedData.map((item, index) => (
                        <span><b style={{ color:"#0b0a0a"}}>{item.ItemName}</b> - {item.MemberCode}</span>
                      ))
                    ) : (
                      <div className="orange-color">
                        No Member code assigned
                      </div>
                    )}
                  </div>

                </GridColumn>
              </Grid>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.onCloseModal} className="blue-btn">
              Back
            </Button>
            {this.props.editForm ? (
              <Button onClick={this.onHandleUpdate} className="orange-btn">
                Update
              </Button>
            ) : (
              <Button onClick={this.onHandleSubmit} className="orange-btn">
                Save
              </Button>
            )}
          </Modal.Actions>
        </Modal>
        {newCustomerUpdateModalOpen && (
          <AddWavierModalList
            allFunction={this}
            businessId={this.props.global.bussinessDetail.businessId}
            businessLocationId={
              this.props.global.locationSelected.BusinessLocationId
            }
            Guestcustomerid={customerForm.customerId}
            openModal={newCustomerUpdateModalOpen}
            closeModal={this.newCustomerModal}
          />
        )}

        <AddCommentModal
          openModal={commentStatus}
          noteType={"Customer"}
          noteRefrenceId={customerForm && customerForm.customerId}
          closeModal={this.addCommentModalFun}
        />

        {isCommonDeleteUpdateModalOpen && (
          <CommonDeleteUpdateModal
            isModalOpen={isCommonDeleteUpdateModalOpen}
            onConfirm={this.onConfirmWarnUser}
            onClose={() =>
              this.setState({ isCommonDeleteUpdateModalOpen: false })
            }
            confirmationButtonText={"Yes"}
            header={"Are you sure you want to discard your changes ?"}
            cancelButtonText={"Cancel"}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
    warnUserState: state.warnUserState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
      storeWarnUser: bindActionCreators(actions.storeWarnUser, dispatch),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CustomerModal);
