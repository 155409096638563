import React, { Component } from "react";
import { Modal, Form, Button, Grid } from "semantic-ui-react";
import { connect, bindActionCreators, actions } from "../../../../functional/global-import";
import { commonFunctions } from "../../../../functional/common-functions";
import { GlobalCodeSelect } from "../../../../components";

const countryOptions = [
    { key: "mi", value: "1", text: "Missouri " },
    { key: "oh", value: "2", text: "Ohio" },
    { key: "tx", value: "3", text: "Texas" },
    { key: "wa", value: "4", text: "Washington" },
];
const options = [{ key: 'Music', text: 'Music', value: '1' }, { key: 'Dancing', text: 'Dancing', value: '2' }, { key: 'Sports', text: 'Sports', value: '3' }];

class NewCustomerModalSample extends Component {

    // Put all key & values that need to reset on form submit and reset form.
    get initialState() {
        return {
            customerDetailForm: { businessId: this.props.global.bussinessDetail.businessId, businessLocationId: 1, firstName: "", lastName: "", phoneNumber: "", email: "", address: "", city: "", zipCode: "", stateId: 1, active: true, gender: '', comment: '', hobbies: [], businessCategory: -1, productStatus: -1, createdBy: "admin" }
        };
    }
    constructor(props) {
        super(props);
        this.state = { ...this.initialState, Name: "ProductStatus" };
    }

    // This function is used to set handle change
    onHandleChange = (e, { name, value, type, checked, data }) => {
        var obj = commonFunctions.onHandleChange(e, { name, value, type, checked, data }, this.state.customerDetail);
        this.setState({ obj });
    }

    // This function is used to reset form
    OnBtnCustomerDetailRestForm = () => {
        this.setState(this.initialState);
    }

    // This function is used to reset form and call close modal
    onMdlCustomerDetailClose = () => {
        this.handleResetForm();
        this.props.closeModal();
    }

    OnBtnCustomerDetailSaveForm = () => {
        this.props.actions.apiCall({ urls: ["CUSTOMER"], method: "Post", data: this.state.customerDetail, onSuccess: this.handleCloseModal });
    }

    render() {
        const { customerDetailForm } = this.state;
        const { api } = this.props;
        return (
            <Modal name="mdlCustomerDetail" open={this.props.openModal} closeIcon onClose={this.onMdlCustomerDetailClose}>
                <Modal.Header>Add New Customer</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Grid>
                            <Grid.Column width={16}>
                                <Form>
                                    <Grid>
                                        <Grid.Column width={8}>
                                            <Form.Field>
                                                <Form.Input placeholder="First Name" className="bordered-input" name="txtFirstName" data="firstName" onChange={this.onHandleChange} value={customerDetailForm.firstName} />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column width={8}>
                                            <Form.Field>
                                                <Form.Input placeholder="Last Name" className="bordered-input" name="txtLastName" data="lastName" onChange={this.onHandleChange} value={customerDetailForm.lastName} />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column width={8}>
                                            <Form.Field>
                                                <Form.Input placeholder="Phone Number" className="bordered-input" name="txtPhoneNumber" data="phoneNumber" onChange={this.onHandleChange} value={customerDetailForm.phoneNumber} />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column width={8}>
                                            <Form.Field>
                                                <Form.Input placeholder="Email Address" className="bordered-input" name="txtEmail" data="email" onChange={this.onHandleChange} value={customerDetailForm.email} />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column width={8}>
                                            <Form.Field>
                                                <Form.Input placeholder="Address" className="bordered-input" name="txtAddress" data="address" onChange={this.onHandleChange} value={customerDetailForm.address} />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column width={8}>
                                            <Form.Field>
                                                <Form.Input placeholder="City" className="bordered-input" name="txtCity" data="city" onChange={this.onHandleChange} value={customerDetailForm.city} />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column width={8}>
                                            <Form.Select placeholder="State" type="select-int" options={countryOptions} name="ddpstateId" data="stateId" className="custom-select bordered-input" value={customerDetailForm.stateId} fluid onChange={this.onHandleChange} />
                                        </Grid.Column>
                                        <Grid.Column width={8}>
                                            <Form.Field>
                                                <Form.Input placeholder="Zip Code" className="bordered-input" data="zipCode" name="txtZipCode" onChange={this.onHandleChange} value={customerDetailForm.zipCode} />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column width={8}>
                                            <Form.Field>
                                                <Form.Checkbox label="Inactive" id="active" className="custome-checkbox" data="active" name="chkActive" defaultChecked={customerDetailForm.active} onChange={this.onHandleChange} />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column width={8}>
                                            <Form.Group inline>
                                                <Form.Field>
                                                    <Form.Radio label="Male" className="custom-radio-btn" name="rgpGender" data="gender" value="male" checked={customerDetailForm.gender === "male"} onChange={this.onHandleChange} />
                                                </Form.Field>
                                                <Form.Field>
                                                    <Form.Radio label="Female" className="custom-radio-btn" name="rgpGender" data="gender" value="female" checked={customerDetailForm.gender === "female"} onChange={this.onHandleChange} />
                                                </Form.Field>
                                            </Form.Group>
                                        </Grid.Column>
                                        <Grid.Column width={8}>
                                            <Form.TextArea type="textarea" placeholder="Comment" rows={1} name="comment" onChange={this.onHandleChange} value={customerDetailForm.comment} />
                                        </Grid.Column>
                                        <Grid.Column width={8}>
                                            <Form.Field>
                                                <Form.Dropdown options={options} placeholder='Hobbies' type="multiple-select" name="ddbHobbies" data="hobbies" search selection fluid multiple value={customerDetailForm.hobbies} onChange={this.onHandleChange} className="tagger" />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column width={8}>
                                            <Form.Field>
                                                <GlobalCodeSelect placeholder="Select Business Category" name="ddpBusinessCategory" data="businessCategory" onChange={this.onHandleChange} value={customerDetailForm.businessCategory} categoryType="BusinessCategory" />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column width={8}>
                                            <Form.Field>
                                                <GlobalCodeSelect placeholder="Product Status" name="ddpProductStatus" data="productStatus" onChange={this.onHandleChange} value={customerDetailForm.productStatus} categoryType="ProductStatus" />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid>
                                </Form>
                            </Grid.Column>
                        </Grid>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button name="btnCustomerDetailRestForm" onClick={this.OnBtnCustomerDetailRestForm} className="blue-btn">Rest</Button>
                    <Button name="btnCustomerDetailSaveForm" onClick={this.OnBtnCustomerDetailSaveForm} className="orange-btn" loading={api.isApiLoading}>Save</Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        api: state.api
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            apiCall: bindActionCreators(actions.apiCall, dispatch)
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(NewCustomerModalSample);
