import React, { Component } from "react";
import { Modal, Step, Icon, Dimmer, Loader } from "semantic-ui-react";
import ActivityGeneralInfo from "./add-activity-general-info";
import UploadImages from "./upload-images";
import ActivityPricingModal from "./activity-pricing";
import {
  commonFunctions,
  connect,
  bindActionCreators,
  actions,
  Notifications,
  env,
} from "../../../../functional/global-import";
import { CommonDeleteUpdateModal } from "../../../../components";

class AddActivityModal extends Component {
  // Put all key & values that need to reset on form submit and reset form.
  get initialState() {
    return {
      addActivityGeneralInfo: {
        activityId: "",
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        businessCategoryType: commonFunctions.getGlobalCodeDetails(
          this.props.global.codes,
          "BusinessCategoryType",
          "Activity"
        ).globalCodeId,
        categoryId: null,
        inStore: true,
        websiteWidget: true,
        activityName: "",
        minimumNumberOfSeats: "",
        maximumNumberOfSeats: "",
        description: "",
        whatIsIncluded: "",
        whatToKnow: "",
        color: "",
        tickets: false,
        webWidget: true,
        inConfirmationEmail: true,
        bookingConfirmation: commonFunctions.getGlobalCodeDetails(
          this.props.global.codes,
          "BookingConfirmation",
          "Auto Confirm"
        ).globalCodeId,
        productTags: [],
        documents: [],
        cancellationPolicyDescription: null,
        cancellationPolicyInStore: true,
        cancellationPolicyWebsiteWidget: true,
        cancellationPolicyInConfirmationEmail: true,
        requirements: [],
        customField: [],
        traits: [],
        requirementsInConfirmationEmail: true,
        requirementWebsiteWidget: true,
        requirementInStore: true,
        addOns: [],
        activityBlackOutDates: [],
        activityAvailability: [],
        activityCustomQuestion: [],
        valid: false,
        selectedLocation: [],
        minActivity: null,
        maxActivity: null,
        actionPerformedBy: this.props.auth.userDetail.emailId,
      },
      uploadImage: {
        imageId: [],
        deleted: [],
        activityId: "",
        images: [],
        DefaultImageId: null,
        DefaultImage: [],
        responseImages: [],
        actionPerformedBy: this.props.auth.userDetail.emailId,
      },
      activityPricing: {
        activityPricingId: "",
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        activityId: "",
        title: "",
        timeFrameId: "",
        price: "",
        description: "",
        min: "",
        max: "",
        isActive: true,
        customField: [],
        actionPerformedBy: this.props.auth.userDetail.emailId,
      },
      isCommonDeleteUpdateModalOpen: false,
      isActivityImageAdded: false,
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      ...this.initialState,
      allCategoryList: [{ value: -1, title: "Add at root level" }],
      activeStep: 0,
      expandthisnode: [],
      pricingField: false,
      activityDetails: [],
      imageData: false,
      firstStepCompleted: false,
      secondStepCompleted: false,
      updateButton: false,
      booking: [],
      locationDetails: [],
      locationOption: [],
      documentDetails: [],
      createCustomerQuestions: [],
      addOnsList: [],
      addOnId: "",
      addOnText: "",
      price: "",
      addOnsImage: [],
      addOnsSelected: "",
      addOnInStore: true,
      addOnWebsiteWidget: false,
      activeField: false,
      updatebuttonAddOns: false,
      tagDetails: [],
      customFieldsButton: true,
      Count: 1,
      activityList: [],
      arr1: [],
      openCommonModal: false,
      addCustomPriceRows: "",
      BusinessCategory: [],
      Min: "",
      Max: "",
      timeFrameList: [],
      rows: [{}],
      activityPricingList: [],
      activityPricingType: 0,
      activityPricingTitle: "",
      timeFrameId: 0,
      createUI: [],
      customTraits: { traits: [{ traitName: "", traitDescription: "" }] },
      activityPriceList: [],
      customerQuestions: "",
      customQuesitonType: "",
      customQuestion: "",
      customQuestionId: 0,
      updateQuestion: false,
      updateQuestions: false,
      questionSelected: "",
      customfield: {
        customFieldRows: [{ customField: "", customDescriptions: "" }],
      },
      blackOutDateDetail: [],
      blackOutTitle: "",
      blackOutId: "",
      blackoutstartdate: "",
      blackoutenddate: "",
      addcustomStatus: false,
      TimeFrameTitle: "",
      timeframeId: "",
      startTime: "",
      endTime: "",
      updateActivityGeneral: false,
      timeFrameList1: [],
      blackOutRows: { blackoutDates: [] },
      availability: { availabilityNew: [] },
      rentalItemTimeSelect: [],
      rentalItemTimeSelectedValue: [],
      TimeDurationMin: [],

      activityCustomQuestion: [
        {
          customQuestionId: 0,
          required: true,
          questionInStore: true,
          questionWebsiteWidget: true,
          customQuestionOptionId: 210,
          associateAddOn: [
            {
              addOnId: 0,
              price: 0,
            },
          ],
        },
      ],
      loadingApi: false,
    };
    this.validator = commonFunctions.initializeSimpleValidator();
    this.validatorGeneral = commonFunctions.initializeSimpleValidator();
    this.validatorCustomfield = commonFunctions.initializeSimpleValidator();
    this.validatorTraits = commonFunctions.initializeSimpleValidator();
    this.validatorChooseTags = commonFunctions.initializeSimpleValidator();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.editActivityModal &&
      this.state.uploadImage.activityId !== prevProps.activityId
    ) {
      this.getActivityGeneralInfo(this.props.activityId);
      this.getActivityImages(this.props.activityId);
      this.getActivityPrices(this.props.activityId);
      this.setState({
        uploadImage: {
          ...this.state.uploadImage,
          activityId: this.props.activityId,
        },
      });
      this.setState({
        activeStep: 0,
        updateActivityGeneral: this.props.editActivityModal,
      });
      this.setState({
        activityPricing: {
          ...this.state.activityPricing,
          activityId: this.props.activityId,
        },
      });
    }
    if (
      this.props.inventoryType ===
        this.props.global.inventoryDetail.businessCategoryId &&
      prevState.addActivityGeneralInfo.categoryId === null
    ) {
      this.setState({
        activeStep: 0,
        updateActivityGeneral: this.props.editActivityModal,
      });
      let newCategoryId =
        this.props.global.inventoryDetail.inventoryBreadcrumb &&
        this.props.global.inventoryDetail.inventoryBreadcrumb.length > 1
          ? this.props.global.inventoryDetail.inventoryBreadcrumb[
              this.props.global.inventoryDetail.inventoryBreadcrumb.length - 1
            ].parentId
          : undefined;
      let newCategoryid = newCategoryId === null ? undefined : newCategoryId;

      this.setState(() => ({
        addActivityGeneralInfo: {
          ...this.state.addActivityGeneralInfo,
          categoryId: newCategoryid,
        },
      }));
      this.state.expandthisnode.push(
        this.props.global.inventoryDetail.inventoryBreadcrumb &&
          this.props.global.inventoryDetail.inventoryBreadcrumb.length > 1 &&
          this.props.global.inventoryDetail.inventoryBreadcrumb[
            this.props.global.inventoryDetail.inventoryBreadcrumb.length - 1
          ].parentId
      );
    }
  }

  // Get Activity Details
  getActivityGeneralInfo = (editActivityId) => {
    this.setState({ loadingApi: true });
    this.props.actions.apiCall({
      urls: ["GETACTIVITY"],
      method: "GET",
      data: {
        activityId: editActivityId,
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        businessCategoryType: commonFunctions.getGlobalCodeDetails(
          this.props.global.codes,
          "BusinessCategoryType",
          "Activity"
        ).globalCodeId,
        categoryId: this.props.global.inventoryDetail.categoryId,
        pageNo: 1,
        pageSize: 100,
      },
      onSuccess: (response) => {
        const { addActivityGeneralInfo } = this.state;
        addActivityGeneralInfo.activityName = response[0].activityName;
        addActivityGeneralInfo.activityId = response[0].activityId;
        addActivityGeneralInfo.minimumNumberOfSeats =
          response[0].minimumNumberOfSeats;
        addActivityGeneralInfo.maximumNumberOfSeats =
          response[0].maximumNumberOfSeats;
        addActivityGeneralInfo.productTags = JSON.parse(
          response[0].productTags
        );
        addActivityGeneralInfo.categoryId = response[0].categoryId;
        addActivityGeneralInfo.description = response[0].description;
        addActivityGeneralInfo.whatIsIncluded = response[0].whatIsIncluded;
        addActivityGeneralInfo.whatToKnow = response[0].whatToKnow;

        addActivityGeneralInfo.color = response[0].color;
        addActivityGeneralInfo.customField = JSON.parse(
          response[0].customFields
        );
        addActivityGeneralInfo.traits = JSON.parse(response[0].traits);
        addActivityGeneralInfo.inConfirmationEmail =
          response[0].inConfirmationEmail;
        addActivityGeneralInfo.webWidget = response[0].webWidget;
        addActivityGeneralInfo.bookingConfirmation =
          response[0].bookingConfirmation;
        addActivityGeneralInfo.documents = JSON.parse(response[0].documents);
        addActivityGeneralInfo.requirements = JSON.parse(
          response[0].requirements
        );
        addActivityGeneralInfo.requirementsInConfirmationEmail =
          response[0].requirementsInConfirmationEmail;
        addActivityGeneralInfo.requirementInStore =
          response[0].requirementInStore;
        addActivityGeneralInfo.requirementWebsiteWidget =
          response[0].requirementWebsiteWidget;
        addActivityGeneralInfo.inStore = response[0].inStore;
        addActivityGeneralInfo.activityAvailability = JSON.parse(
          response[0].activityAvailability
        );
        addActivityGeneralInfo.activityBlackOutDates = JSON.parse(
          response[0].activityBlackOutDates
        );
        this.state.blackOutRows.blackoutDates = JSON.parse(
          response[0].blackOutDates
        );
        addActivityGeneralInfo.activityCustomQuestion = JSON.parse(
          response[0].activityCustomQuestion
        );
        addActivityGeneralInfo.addOns = JSON.parse(response[0].addOn);
        addActivityGeneralInfo.cancellationPolicyDescription =
          response[0].cancellationPolicyDescription;
        addActivityGeneralInfo.cancellationPolicyInStore =
          response[0].cancellationPolicyInStore;
        addActivityGeneralInfo.cancellationPolicyWebsiteWidget =
          response[0].cancellationPolicyWebsiteWidget;
        addActivityGeneralInfo.cancellationPolicyInConfirmationEmail =
          response[0].cancellationPolicyInConfirmationEmail;
        addActivityGeneralInfo.websiteWidget = response[0].websiteWidget;
        addActivityGeneralInfo.selectedLocation = response[0].selectedLocation;
        addActivityGeneralInfo.maxActivity = response[0].maxActivity;
        addActivityGeneralInfo.minActivity = response[0].minActivity;
        this.state.expandthisnode.push(response[0].categoryId);
        this.setState({ addActivityGeneralInfo, updateButton: true });
        this.setState({
          customTraits: {
            ...this.state.customTraits,
            traits: JSON.parse(response[0].traits),
          },
        });
        this.setState({
          customfield: {
            ...this.state.customfield,
            customFieldRows: JSON.parse(response[0].customFields),
          },
        });
        this.setState({
          blackOutRows: {
            ...this.state.blackOutRows,
            blackoutDates: JSON.parse(response[0].blackOutDates),
          },
        });
        this.setState({
          availability: {
            ...this.state.availability,
            availabilityNew: JSON.parse(response[0].availability),
          },
        });
        // this.setState({ activityCustomQuestion: { ...this.state.activityCustomQuestion, customerQues: JSON.parse(response[0].customQuestions) } });
        // this.setState({ productCustomQuestion: { ...this.state.productCustomQuestion, customerQues: JSON.parse(response[0].retailCustomQuestion) } });
      },
      onFinally: () => {
        this.setState({ loadingApi: false });
      },
    });
  };

  //Get Activity Photos
  getActivityImages = (imageActivityId) => {
    this.setState((this.state.uploadImage.images = []));
    this.setState((this.state.uploadImage.DefaultImage = []));
    this.props.actions.apiCall({
      urls: ["GETACTIVITYIMAGE"],
      method: "GET",
      data: {
        businessId: this.props.global.bussinessDetail.businessId,
        activityId: imageActivityId,
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
      },
      onSuccess: (response) => {
        let isimageExist = response.length > 0;
        const { uploadImage } = this.state;
        this.setState((uploadImage.responseImages = response));
        const images = response.map((data) => {
          return (
            uploadImage.imageId.push(data.imageId),
            uploadImage.images.push(
              commonFunctions.concatenateImageWithAPIUrl(data.image)
            )
          );
        });
        var isDefaultImage = response.filter((x) => x.isDefaultImage === true);
        if (isDefaultImage) {
          uploadImage.DefaultImage.push(
            commonFunctions.concatenateImageWithAPIUrl(isDefaultImage[0].image)
          );
          this.setState({
            uploadImage,
            imageData: true,
            isActivityImageAdded: isimageExist,
          });
        }
      },
    });
  };

  //Get Activity Pricing Details
  getActivityPrices = (pricingActivity) => {
    this.props.actions.apiCall({
      urls: ["GETACTIVITYPRICE"],
      method: "GET",
      data: {
        activityPricingId: -1,
        activityId: pricingActivity,
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        pageNo: 1,
        pageSize: 100,
      },
      onSuccess: (response) => {
        const { activityPricing } = this.state;
        // activityPricing.activityId = pricingActivity.activityId;
        activityPricing.title = response[0].title;
        activityPricing.timeFrameId = response[0].timeFrameId;
        activityPricing.price = response[0].price;
        activityPricing.description = response[0].description;
        activityPricing.isActive = response[0].isActive;
        activityPricing.min = response[0].min;
        activityPricing.max = response[0].max;
        this.setState({ activityPricing });
      },
    });
  };

  allStateBlank = () => {
    this.setState(this.initialState);
  };

  backStep = (e) => {
    if (e === 2) {
      this.getActivityImages(this.state.uploadImage.activityId);
      this.onPrevious(1);
    } else {
      this.updateActivityImages();
      this.onPrevious(0);
    }
  };

  componentDidMount = () => {
    this.getCategory();
    this.getLocationDetail();
    //  this.getTagList();
    this.getActivityPricing();
    this.getTimeFrameDetails();
  };

  allStateBlank = () => {
    this.setState(this.initialState);
  };

  // ======== Get Activity Inventory Location Function ======== //
  getCategory = () => {
    this.props.actions.apiCall({
      urls: ["GETCATEGORYFORPRODUCT"],
      method: "GET",
      data: {
        businessCategoryType: commonFunctions.getGlobalCodeDetails(
          this.props.global.codes,
          "BusinessCategoryType",
          "Activity"
        ).globalCodeId,
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
      },
      onSuccess: (response) => {
        if (response) {
          const { addActivityGeneralInfo } = this.state;
          if (
            addActivityGeneralInfo.categoryId === undefined ||
            response[0].productCategories === null
          ) {
            this.setState({
              addActivityGeneralInfo: {
                ...this.state.addActivityGeneralInfo,
                categoryId: -1,
              },
            });
          }
          if (response[0].productCategories) {
            let productCategoriesArray = JSON.parse(
              response[0].productCategories
            );
            this.setState({
              allCategoryList: [
                ...this.state.allCategoryList,
                ...productCategoriesArray,
              ],
            });
          }
        }
      },
    });
  };
  getLocationDetail = () => {
    const userDetail = JSON.parse(
      this.props.auth.userDetail.businessUserLocations
    );
    const filteredGlobalCodes = userDetail.map((location) => {
      return {
        value: location.BusinessLocationId,
        text: location.BusinessLocationName,
      };
    });
    this.setState({ locationOption: filteredGlobalCodes });
  };
  // Ant Design Dropdown-List
  onDropDownTreeChange = (value) => {
    this.setState({
      addActivityGeneralInfo: {
        ...this.state.addActivityGeneralInfo,
        categoryId: value,
      },
    });
  };
  // Check Radio Buttons
  handleChange = (e, { value }) => this.setState({ value });
  // This function is used to set handle change for All Fields.
  onHandleChange = (e, { name, value, type, checked, data }) => {
    var addActivityGeneralInfo = commonFunctions.onHandleChange(
      e,
      { name, value, type, checked, data },
      this.state.addActivityGeneralInfo,
      this
    );
    this.setState({ addActivityGeneralInfo });
  };

  // ======== Get Activity Pricing Function ======== //
  getActivityPricing = () => {
    this.props.actions.apiCall({
      urls: ["GETACTIVITYPRICE"],
      method: "GET",
      data: {
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        pageNo: 1,
        pageSize: 100,
      },
      onSuccess: (response) => {
        this.setState({ activityPricingList: response });
      },
    });
  };

  // ======== onChange Function For Activity Images ======== //
  onImageChange = (imageList, index) => {
    const { uploadImage } = this.state;
    this.setState(() => ({
      uploadImage: { ...this.state.uploadImage, images: imageList },
    }));
    commonFunctions.onFormChange(this);
  };
  //////////////  - default image change -  //////////////////
  handleChangeDefaultImage = (image) => {
    const { uploadImage } = this.state;
    if (!image.data_url && image !== undefined) {
      const removeUrl = image.split(env.IMG_URL);
      const setDefaultImageId = this.state.uploadImage.responseImages.filter(
        (img) => img.image === removeUrl[1]
      );
      uploadImage.DefaultImageId = setDefaultImageId[0].imageId;
      uploadImage.DefaultImage = [];
      uploadImage.images = [];
      this.setState(uploadImage);
      this.updateActivityImages();
    } else {
      this.setState(() => ({
        uploadImage: {
          ...this.state.uploadImage,
          DefaultImage: [image],
          DefaultImageId: "",
        },
      }));
    }
    // commonFunctions.onFormChange(this);
  };
  updateActivityImages = () => {
    const { uploadImage } = this.state;
    const { api } = this.props;
    {
      api.isApiLoading && (
        <Dimmer active inverted>
          <Loader />
        </Dimmer>
      );
    }

    if (uploadImage.DefaultImage[0] !== undefined) {
      var newArrayImage = uploadImage.images.filter(
        (defaultObj) =>
          defaultObj.data_url !== uploadImage.DefaultImage[0].data_url
      );
      uploadImage.images = newArrayImage;
    }
    var formData = commonFunctions.getFormData(this.state.uploadImage);
    this.props.actions.apiCall({
      urls: ["UPDATEACTIVITYIMAGE"],
      method: "PUT",
      data: formData,
      onSuccess: (response) => {
        this.setState((this.state.uploadImage.imageId = []));
        this.getActivityImages(this.state.uploadImage.activityId);
      },
      isFormData: true,
      showNotification: true,
    });
  };

  ///////////// - FOR EDIT IMAGE REMOVE FUNCTION FOR RENTAL - ///////////////
  onImageRemove = (index) => {
    const allImages = this.state.uploadImage.images;
    if (
      this.state.uploadImage.DefaultImage[0] ===
      this.state.uploadImage.images[index]
    ) {
      this.setState((this.state.uploadImage.DefaultImage = []));
    }
    allImages.splice(index, 1);
    this.setState({
      uploadImage: { ...this.state.uploadImage, images: allImages },
    });
    if (
      this.state.uploadImage.imageId[index] &&
      this.state.uploadImage.imageId[index] !== null
    ) {
      this.state.uploadImage.deleted.push(
        this.state.uploadImage.imageId[index]
      );
      const allImageId = this.state.uploadImage.imageId;
      allImageId.splice(index, 1);
      this.setState({
        uploadImage: { ...this.state.uploadImage, imageId: allImageId },
      });
      commonFunctions.onFormChange(this);
    }
  };

  // Get Time Frame Data in Activity Pricing //
  getTimeFrameDetails = () => {
    this.props.actions.apiCall({
      urls: ["GETTIMEFRAMEDETAIL"],
      method: "GET",
      data: {
        timeFrameId: -1,
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        PageNo: 1,
        PageSize: 100,
      },
      onSuccess: (response) => {
        const timeFrame = response.map((singleTime) => {
          return {
            value: singleTime.timeFrameId,
            text: singleTime.timeFrameTitle,
            startTime: singleTime.timeRangeFrom,
            endTime: singleTime.timeRangeTo,
            range: singleTime.range,
            timeFrameTitle: singleTime.timeFrameTitle,
          };
        });
        this.setState({ timeFrameList1: timeFrame });
      },
    });
  };

  // onNext = (step, name ) => {
  //   const { addActivityGeneralInfo, activeStep } = this.state;
  //   this.setState({ activeStep: step, type : name });
  //   this.validatorGeneral = commonFunctions.initializeSimpleValidator();
  // };

  onPrevious = (step) => {
    this.setState({ activeStep: step });
  };

  onNext = () => {
    const { addActivityGeneralInfo, activeStep } = this.state;
    this.setState({ activeStep: activeStep + 1 });
    if (addActivityGeneralInfo.activityId === "") {
      this.validatorGeneral = commonFunctions.initializeSimpleValidator();
    }
  };

  // onPrevious = () => {
  //   const { addActivityGeneralInfo,activeStep } = this.state;
  //   this.setState({ activeStep: activeStep - 1 });
  //   if (addActivityGeneralInfo.activityId === "") {
  //       this.validatorGeneral = commonFunctions.initializeSimpleValidator();
  //   }
  // }

  getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <React.Fragment>
            {this.state.loadingApi && (
              <Dimmer active inverted>
                <Loader />
              </Dimmer>
            )}
            <ActivityGeneralInfo
              isEdited={this.props.editActivityModal}
              validatorGeneral={this.validatorGeneral}
              fullState={this.state}
              allFunction={this}
            />
          </React.Fragment>
        );
      case 1:
        return (
          <React.Fragment>
            <UploadImages
              fullState={this.state}
              allFunction={this}
              activityId={this.state.addActivityGeneralInfo.activityId}
            />
          </React.Fragment>
        );
      case 2:
        return (
          <React.Fragment>
            <ActivityPricingModal
              closeModal={this.props.closeModal}
              activityId={this.state.uploadImage.activityId}
              getProductCategoryDetail={this.props.getProductCategoryDetail}
              backStep={() => this.backStep(stepIndex)}
              fullState={this.state}
              allFunction={this}
            />
          </React.Fragment>
        );
      default:
        return null;
    }
  };

  nextStep = (stepNumber) => {
    this.setState({ activeStep: stepNumber });
  };

  // ======== Activity General Screen Add Update Function ======== //
  changeNext = (e, stepNumber) => {
    // var forAdd = (!this.props.warnUserState.isFormChanged && e === "continue") ? false : true
    // const { activeStep } = this.state;
    const { api } = this.props;
    const { addActivityGeneralInfo } = this.state;
    const { minActivity, maxActivity } = this.state.addActivityGeneralInfo;

    // if(forAdd){
    if (this.validatorGeneral.allValid() === false) {
      this.validatorGeneral.showMessages();
      this.forceUpdate();
      this.props.actions.showNotification(
        {
          title: "Warning",
          message: "Please fill all required fields.",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
      this.setState({
        addActivityGeneralInfo: {
          ...this.state.addActivityGeneralInfo,
          valid: false,
        },
      });
      return false;
    }
    if (minActivity || maxActivity ? minActivity >= maxActivity : null) {
      this.props.actions.showNotification(
        {
          title: "Warning",
          message: `Max Per Order item can not less then min per order.`,
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
      return false;
    } else if (!this.state.updateActivityGeneral) {
      {
        api.isApiLoading && (
          <Dimmer active inverted>
            <Loader />
          </Dimmer>
        );
      }

      const { addActivityGeneralInfo } = this.state;

      this.props.actions.apiCall({
        urls: ["ADDACTIVITY"],
        method: "POST",
        data: addActivityGeneralInfo,
        onSuccess: (response) => {
          if (response.responseCode == 200) {
            const allState = this.state.addActivityGeneralInfo;
            const { uploadImage, addActivityGeneralInfo, activityPricing } =
              this.state;
            addActivityGeneralInfo.activityId = response.keyId;
            uploadImage.activityId = response.keyId;
            activityPricing.activityId = response.keyId;
            this.setState({
              uploadImage,
              addActivityGeneralInfo,
              updateActivityGeneral: true,
            });
            commonFunctions.onApiSucessFormChangedFalse(this);

            if (stepNumber === 1) {
              this.onNext(stepNumber);
            } else {
              this.onNext(stepNumber);
            }
          }
        },
        showNotification: true,
      });
    } else if (
      this.state.updateActivityGeneral &&
      this.props.warnUserState.isFormChanged
    ) {
      {
        api.isApiLoading && (
          <Dimmer active inverted>
            <Loader />
          </Dimmer>
        );
      }

      const { addActivityGeneralInfo } = this.state;
      this.props.actions.apiCall({
        urls: ["UPDATEACTIVITY"],
        method: "PUT",
        data: addActivityGeneralInfo,
        onSuccess: (response) => {
          commonFunctions.onApiSucessFormChangedFalse(this);
          if (stepNumber === 1) {
            this.onNext(stepNumber);
          } else {
            this.onNext(stepNumber);
          }
        },
        showNotification: true,
      });
    }
    // }
    else {
      this.setState({ activeStep: stepNumber });
    }
  };

  // ======== Activity Image Function ======== //
  changeNextImage = (stepNumber) => {
    const { api } = this.props;
    const { isActivityImageAdded } = this.state;

    if (!isActivityImageAdded && this.state.uploadImage.images.length !== 0) {
      {
        api.isApiLoading && (
          <Dimmer active inverted>
            <Loader />
          </Dimmer>
        );
      }
      const { uploadImage } = this.state;
      if (uploadImage.DefaultImage[0] !== undefined) {
        var newArrayImage = uploadImage.images.filter(
          (defaultObj) =>
            defaultObj.data_url !== uploadImage.DefaultImage[0].data_url
        );
        uploadImage.images = newArrayImage;
      }

      if (
        this.state.uploadImage.images.length === 0 &&
        this.state.uploadImage.DefaultImage.length === 0
      ) {
        this.setState({ activeStep: stepNumber });
      }

      if (this.state.uploadImage.DefaultImage.length > 0) {
        var formData = commonFunctions.getFormData(this.state.uploadImage);
        this.props.actions.apiCall({
          urls: ["UPLOADACTIVITYIMAGE"],
          method: "POST",
          data: formData,
          onSuccess: (response) => {
            this.setState((this.state.uploadImage.images = []));
            this.setState((this.state.uploadImage.DefaultImage = []));
            commonFunctions.onApiSucessFormChangedFalse(this);
            this.setState({ activeStep: stepNumber });
          },
          isFormData: true,
          showNotification: true,
        });
      }
    } else if (isActivityImageAdded) {
      {
        api.isApiLoading && (
          <Dimmer active inverted>
            <Loader />
          </Dimmer>
        );
      }
      const { uploadImage } = this.state;

      if (uploadImage.DefaultImage[0] !== undefined) {
        var newArrayImage = uploadImage.images.filter(
          (defaultObj) =>
            defaultObj.data_url !== uploadImage.DefaultImage[0].data_url
        );
        uploadImage.images = newArrayImage;
      }
      var formData = commonFunctions.getFormData(this.state.uploadImage);
      this.props.actions.apiCall({
        urls: ["UPDATEACTIVITYIMAGE"],
        method: "PUT",
        data: formData,
        onSuccess: (response) => {
          commonFunctions.onApiSucessFormChangedFalse(this);
          this.setState({ activeStep: stepNumber });
        },
        isFormData: true,
        showNotification: true,
      });
    } else if (
      this.state.updateActivityGeneral &&
      !this.props.warnUserState.isFormChanged
    ) {
      this.onNext(2);
    } else {
      this.setState({ activeStep: stepNumber });
    }
  };

  changeStepToGeneral = () => {
    if (
      (this.state.uploadImage.images[0] !== undefined &&
        this.state.uploadImage.DefaultImage[0] !== undefined) ||
      (this.state.uploadImage.images[0] === undefined &&
        this.state.uploadImage.DefaultImage[0] === undefined)
    ) {
      this.changeStepper(0);
    } else if (
      this.state.uploadImage.images[0] !== undefined &&
      this.state.uploadImage.DefaultImage[0] === undefined
    ) {
      this.props.actions.showNotification(
        {
          title: "Error",
          message: "Please select default image.",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    }
  };
  changeStepToPricing = () => {
    if (
      (this.state.uploadImage.images[0] !== undefined &&
        this.state.uploadImage.DefaultImage[0] !== undefined) ||
      (this.state.uploadImage.images[0] === undefined &&
        this.state.uploadImage.DefaultImage[0] === undefined)
    ) {
      this.changeStepper(2);
    } else if (
      this.state.uploadImage.images[0] !== undefined &&
      this.state.uploadImage.DefaultImage[0] === undefined
    ) {
      this.props.actions.showNotification(
        {
          title: "Error",
          message: "Please select default image.",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    }
  };
  // ======== Stepper Click Function ======== //
  changeStepper = (stepNumber) => {
    var forEdit =
      this.props.warnUserState.isFormChanged && this.state.updateActivityGeneral
        ? true
        : false;
    var forAdd =
      !this.props.warnUserState.isFormChanged &&
      !this.state.updateActivityGeneral
        ? true
        : false;
    var a = forAdd
      ? true
      : this.props.warnUserState.isFormChanged &&
        !this.state.updateActivityGeneral
      ? true
      : false;
    if (forEdit || a) {
      const { activeStep } = this.state;
      if (activeStep === 0) {
        this.changeNext(stepNumber);
      } else {
        if (
          (activeStep === 1 &&
            stepNumber < activeStep &&
            this.state.uploadImage.images.length > 0) ||
          (activeStep === 1 &&
            stepNumber < activeStep &&
            this.state.uploadImage.images.length === 0)
        ) {
          // if (stepNumber === 0 && this.state.uploadImage.images.length === 0){
          //   this.changeNextImage(stepNumber);
          // }
          if (stepNumber === 0) {
            this.changeNextImage(stepNumber);
          }
        } else {
          if (
            (activeStep === 1 &&
              stepNumber > activeStep &&
              this.state.uploadImage.images.length > 0) ||
            (activeStep === 1 &&
              stepNumber > activeStep &&
              this.state.uploadImage.images.length === 0)
          ) {
            if (stepNumber === 2) {
              this.changeNextImage(stepNumber);
            }
          }
        }
      }
    } else if (
      this.state.activeStep === 1 &&
      this.state.uploadImage.images.length === 0
    ) {
      this.changeNextImage(stepNumber);
    } else {
      this.getActivityImages(this.state.uploadImage.activityId);
      this.setState({ activeStep: stepNumber });
    }
  };

  onPreviousStep = (step) => {
    this.setState({ activeStep: step });
  };

  ///////////// -for prompt if any changes in form- ///////////
  onCloseModalStepOne = () => {
    if (this.props.warnUserState.isFormChanged) {
      this.setState({ isCommonDeleteUpdateModalOpen: true });
    } else {
      this.onModalCloseFormFalse();
    }
  };

  onModalCloseFormFalse = () => {
    this.props.closeModal();
    if (this.props.getProductCategoryDetail) {
      this.props.getProductCategoryDetail();
    }
    this.setState(this.initialState);
    this.setState({
      activeStep: 0,
      firstStepCompleted: false,
      secondStepCompleted: false,
    });
  };

  onConfirmWarnUser = () => {
    this.setState({ isCommonDeleteUpdateModalOpen: false });
    commonFunctions.onApiSucessFormChangedFalse(this);
    this.onModalCloseFormFalse();
  };
  // ***********************MinMaxTimeDuration*************************** //
  onHandleMaxDurationChange = (e, { value, data }) => {
    const { maxActivity, minActivity } = this.state.addActivityGeneralInfo;
    var ex = /^(?:\d*\d{1,2}|\d+)$/;
    if (ex.test(value) === true) {
      if (data === "maxActivity") {
        this.setState({
          addActivityGeneralInfo: {
            ...this.state.addActivityGeneralInfo,
            [data]: value,
          },
        });
      }
    }
    if (ex.test(value) === false) {
      this.setState({
        addActivityGeneralInfo: {
          ...this.state.addActivityGeneralInfo,
          [data]: null,
        },
      });
    }
    commonFunctions.onFormChange(this);
  };
  onHandleMinDuration = (e, { name, value, type, checked, data }) => {
    if (data === "minActivity") {
      var ex = /^(?:\d*\d{1,2}|\d+)$/;
      if (ex.test(value) === true) {
        this.setState({
          addActivityGeneralInfo: {
            ...this.state.addActivityGeneralInfo,
            [data]: value,
          },
        });
      }
    }
    if (ex.test(value) === false) {
      this.setState({
        addActivityGeneralInfo: {
          ...this.state.addActivityGeneralInfo,
          [data]: null,
        },
      });
    }
    commonFunctions.onFormChange(this);
  };
  render() {
    const { activeStep, isCommonDeleteUpdateModalOpen } = this.state;
    return (
      <>
        <Modal
          open={this.props.openModal}
          closeIcon
          onClose={this.onCloseModalStepOne}
          closeOnDimmerClick={false}
          size="large"
        >
          {this.props.editActivityModal ? (
            <Modal.Header className="ora">Edit Activity</Modal.Header>
          ) : (
            <Modal.Header className="ora">Add Activity</Modal.Header>
          )}
          <Modal.Content scrolling>
            <Modal.Description>
              <Step.Group stackable="tablet" className="custom-stepper">
                <Step
                  active={activeStep === 0 ? true : false}
                  completed={activeStep > 0 ? true : false}
                  onClick={() => this.changeStepToGeneral()}
                >
                  <Step.Content>
                    <Step.Title>
                      General{" "}
                      <Icon name="check circle" className="green-check" />
                    </Step.Title>
                    <Step.Description>1</Step.Description>
                  </Step.Content>
                </Step>
                <Step
                  active={activeStep === 1 ? true : false}
                  completed={activeStep > 1}
                  onClick={() => this.changeStepper(1)}
                >
                  <Step.Content>
                    <Step.Title>
                      Photo <Icon name="check circle" className="green-check" />
                    </Step.Title>
                    <Step.Description>2</Step.Description>
                  </Step.Content>
                </Step>
                <Step
                  active={activeStep === 2 ? true : false}
                  completed={activeStep > 2}
                  onClick={() => this.changeStepToPricing()}
                >
                  {/* onClick={() => this.changeStepper(2)}> */}
                  <Step.Content>
                    <Step.Title>
                      Pricing{" "}
                      <Icon name="check circle" className="green-check" />
                    </Step.Title>
                    <Step.Description>3</Step.Description>
                  </Step.Content>
                </Step>
              </Step.Group>
              <div>{this.getStepContent(activeStep)}</div>
            </Modal.Description>
          </Modal.Content>
        </Modal>
        {isCommonDeleteUpdateModalOpen && (
          <CommonDeleteUpdateModal
            isModalOpen={isCommonDeleteUpdateModalOpen}
            onConfirm={this.onConfirmWarnUser}
            onClose={() =>
              this.setState({ isCommonDeleteUpdateModalOpen: false })
            }
            confirmationButtonText={"Yes"}
            header={"Are you sure you want to discard your changes ?"}
            cancelButtonText={"Cancel"}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
    warnUserState: state.warnUserState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
      storeActivityId: bindActionCreators(actions.storeActivityId, dispatch),
      storeWarnUser: bindActionCreators(actions.storeWarnUser, dispatch),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddActivityModal);
