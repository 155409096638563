import React from 'react';
import PropTypes from 'prop-types';
import { Input } from "semantic-ui-react";
// import moment from 'moment'

const InputCurrencyTag = ({ onChange, value, placeholder , name, data }) => {

    return (
        <Input
            placeholder={placeholder}
            name={name}
            label={{ basic: true, content: '$' }}
            labelPosition='left'
            data={data}
            type="number"
            // disabled={bundlePricingType === ExistingPrices}
            // value={item.price === null ? "" : item.price}
            value={value}
            // error={error}
            // min="0"
            // value={item.price}      
            onChange={onChange}
        // className="form-control" 
        />
    );
};


InputCurrencyTag.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.isRequired,
    placeholder: PropTypes.isRequired,
    name: PropTypes.isRequired,
    data: PropTypes.isRequired,
};


export default InputCurrencyTag;
