import React, { Component } from "react";
import { Icon } from "semantic-ui-react";
import { commonFunctions, } from "../../../functional/global-import";
import { DataTable } from '../../../components/organisms';

class CustomerQuestionGrid extends Component {
    childsChild = React.createRef();
    render() {
        const { fullState, allFunction } = this.props;
        return (
            <DataTable
                ref={this.childsChild}
                getApiName="GETCUSTOMQUESTION"
                // searchOption={{ show: true, placeHolder: "Search Tag" }}
                // defaultSort={{sortColumn:"modifiedDate",sortOrder:"desc"}}
                // defaultPageSize={10}
                getGridDataParamters={{ "CustomQuestionType": fullState.customerQuestion.customQuestionType, CustomQuestionId: -1, }}
                gridName="CustomerQuestionGrid"
                screenName="customer-question"
                fullState={fullState}
                allFunction={allFunction}
                tableHeight="200px"
                emptyGrid="No records to show"
                columns={[
                    {
                        headerName: "Question Type",
                        fieldName: "customquestionTypeValue",
                        isSorting: true,
                    },
                    {
                        headerName: "Question",
                        fieldName: "customQuestion",
                        isSorting: true,
                        className: "textOverflowHidden",
                        Cell: (props) => { 
                            return (
                                <span title={props.customQuestion}>{props.customQuestion}</span>
                              );
                           },
                    },
                    {
                        headerName: "Label",
                        fieldName: "customQuestionOptionValue",
                        isSorting: true,
                        Cell: (props) => {
                            return (
                                <div style={{whiteSpace: "pre-wrap"}}>{props.customQuestionOptionValue &&
                                     JSON.parse(props.customQuestionOptionValue).map((x) => x.CustomQuestionOptionValue).join('/ ')}</div>
                            );
                        },
                    },
                    // {
                    //     headerName: "Active",
                    //     fieldName: "isActive",
                    //     isSorting: true,
                    //     Cell: (props) => {
                    //         return (
                    //             <Checkbox
                    //                 className="custom-toggle"
                    //                 checked={props.isActive}
                    //                 onClick={(data, r) =>
                    //                     commonFunctions.activateDeactivateToggle(allFunction, props.category, props.faqId, "faqId", props.isActive)
                    //                 }
                    //             ></Checkbox>
                    //         );
                    //     },
                    // },
                    {
                        headerName: "Action",
                        fieldName: "action",
                        isSorting: false,
                        Cell: (props) => {
                            return (
                                <React.Fragment>
                                    <Icon
                                        name="pencil alternate"
                                        size="large"
                                        className="deepviolet-color pointer"
                                        title="Edit"
                                        onClick={() => allFunction.customerQuestionsUpdate(props)}
                                    />
                                    <Icon
                                        name="trash alternate"
                                        size="large"
                                        className="orange-color pointer"
                                        title="Delete"
                                        link
                                        onClick={() => commonFunctions.deleteModalMessage(allFunction, props.customQuestion, props.customQuestionId, "customQuestionId")}
                                    />
                                </React.Fragment>
                            );
                        },
                    },
                ]}
            />
        );
    }
}
export default CustomerQuestionGrid;
