import React, { Component } from "react";
import { Grid, Icon, Checkbox,Header } from "semantic-ui-react";
import { DataTable } from "../../../shared/components/organisms";
import Moment from "react-moment";
import { CSVLink } from "react-csv";
import { GlobalCodeSelect, CustomDateChoose } from "../../../shared/components";
import { connect, bindActionCreators, actions, commonFunctions, Notifications } from "../../../shared/functional/global-import";


class SignedDocumentGrid extends Component {
  child = React.createRef();
  get initialState() {
    return {
      // analyticsDetailForm: {
      //   userLocations: "", reportList: [], locationOption: [],
      //   dateRangeType: (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "TimePeriod", "Today")).globalCodeId,
      // },
      openChooseCustomDate: false,

    };
  }
  constructor(props) {
    super(props);
    this.state = {
      ...this.initialState,
      gridDetail: [],
      dateRangeType: (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "TimePeriod", "Today")).globalCodeId,
      startDate: "", endDate: ""

    };
    this.validator = commonFunctions.initializeSimpleValidator();
  }
  onHandleChange = (e, { value }) => {
    const customGlobalCodeId = (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "TimePeriod", "Custom")).globalCodeId

    if (value === customGlobalCodeId) {
      this.setState({ dateRangeType: value, openChooseCustomDate: !this.state.openChooseCustomDate })
    }
    else {
      this.setState({ dateRangeType: value, filteredDates: "" });
    }
    // this.setState({ dateRangeType: value });
    this.child.current.getDefaultData();
  }
  closeCustomDateChoose = (isFormValid) => {
    if (isFormValid === true) {
      const state = Object.assign({}, this.state);
      const filteredDates = "From " + state.startDate + " to " + state.endDate
      state["startDate"] = commonFunctions.saveFormattedDate(state["startDate"]);
      state["endDate"] = commonFunctions.saveFormattedDate(state["endDate"]);
      this.setState({ ...state, openChooseCustomDate: !this.state.openChooseCustomDate, filteredDates: filteredDates }, () => {
        this.child.current.getDefaultData()
        // this.getCounter();
      })
    }
    else {
      this.setState({ ...this.state, startDate: "", endDate: "", openChooseCustomDate: !this.state.openChooseCustomDate, });
    }
  };
  ondateHandleChange = (e, { name, value, type, checked, data, }) => {
    var state = commonFunctions.onHandleChange(e, { name, value, type, checked, data }, this.state);
    this.setState({ ...state, })
  }
  render() {
    const { allFunction } = this.props;
    const { openChooseCustomDate, filteredDates, dateRangeType, startDate, endDate, gridDetail } = this.state;

    return (

      <><>
      <Grid.Column mobile={16} tablet={4} computer={4} verticalAlign="middle">
         {/*ToDo:  Add New field */}
         {filteredDates ?
                <Header as="h3">{filteredDates}</Header> 
              : null}
        </Grid.Column>
       <Grid.Column mobile={16} tablet={1} computer={1} verticalAlign="middle">
          {gridDetail.length > 0 ?
            <CSVLink
              icon
              data={gridDetail}
            >
              <Icon name="file excel" className='excelIcon' size='big' title='Download Excel'> </Icon>
            </CSVLink> : false}
        </Grid.Column>

        <Grid.Column mobile={16} tablet={3} computer={3} >
          <GlobalCodeSelect
            placeholder="Duration"
            name="ddTimePeriodTransaction"
            data="dateRangeType"
            value={dateRangeType}
            categoryType="TimePeriod"
            onChange={this.onHandleChange}
            className="custom-GlobalCodeSelect"
            fluid />
        </Grid.Column>

       

        <DataTable
          ref={this.child}
          gridName="DocumentSignedGrid"
          screenName="documents"
          getApiName="GETSIGNEDDOCUMENT"
          getGridDataParamters={{ "dateRangeType": dateRangeType, "startDate": startDate, "endDate": endDate }}
          searchOption={{ show: true, placeHolder: "Search signatures" }}
          fullState={this.state}
          allFunction={this}
          tableHeight="250px"
          emptyGrid="No records to show"
          columns={[
            {
              headerName: "Name",
              fieldName: "name",
              isSorting: true,

              Cell: (props) => {
                return <span className="orange-color" title={props.name}>{props.name}</span>;
              },
            },
            {
              headerName: "Minor",
              fieldName: "minor",
              isSorting: true,

              Cell: (props) => {
                return <Checkbox className="custome-checkbox " title={props.minor} checked={props.minor} />;
              },
            },
            {
              headerName: "Order ID",
              fieldName: "orderId",
              isSorting: true,

              Cell: (props) => {
                return <span className="orange-color" title={props.orderId}>#{props.orderId}</span>;
              },
            },
            {
              headerName: "Date Signed ",
              fieldName: "datesigned",
              isSorting: true,
              className: "orange-color",
              Cell: (props) => {
                return <><span /><Moment format={"MMMM DD,YYYY"}>{props.dateSigned}</Moment></>;
              },
            },
            {
              headerName: "Document ",
              fieldName: "document",
              className: "textOverflowHidden",

              isSorting: true,
              Cell: (props) => {
                return <span className="document-grid-color" title={props.document}>{props.document}</span>;
              },
            },
            {
              headerName: "Signed By ",
              fieldName: "signedBy",
              className: "orange-color",
              isSorting: true,
              Cell: (props) => {
                return <span title={props.signedBy}>{props.signedBy}</span>;
              },
            },
            {
              headerName: "Download",
              fieldName: "downloadDocument",
              className: "orange-color textOverflowHidden",
              isSorting: true,
              Cell: (props) => {
                return (
                  <a
                    target="_blank"
                    title={props.documentName}
                    className="orange-color"
                    href={commonFunctions.concatenateImageWithAPIUrl(props.downloadDocument
                    )}
                  >
                    Download Signed Document
                  </a>
                )
              },
            },
            {
              headerName: "Action",
              fieldName: "action",
              isSorting: false,
            },
          ]} /></><>
          <CustomDateChoose
            openModal={openChooseCustomDate}
            closeModal={this.closeCustomDateChoose}
            transactionDetailForm={this.state}
            onHandleChange={this.ondateHandleChange} />
        </></>
    );
  }
}
const mapStateToProps = state => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch)
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SignedDocumentGrid);
