import React, { Component } from "react";
import { Button, Divider, Form, Grid, Modal, Table, Icon, Tab, Dimmer, Loader } from "semantic-ui-react";
import { CommonDeleteUpdateModal } from "../../../../components";
import { connect, bindActionCreators, actions, commonFunctions, withRouter } from "../../../../functional/global-import";
import Moment from "react-moment";
import User from "../../modal/user-setting-modal/user-setting-modal"


class AddCommentModal extends Component {
  // Put all key & values that need to reset on form submit and reset form.
  get initialState() {
    return {
      customerNotesForm: { customerNoteId: "-1", customerId: "", pageNo: "1", pageSize: "100", },
      // addNotesForm: { customerId: "", customerNote: "", actionPerformedBy: "" },
      getNotes: { NoteType: "", noteRefrenceId: "", NoteId: -1, PageNo: 1, PageSize: 10, SortColumn: "noteId", SortOrder: "desc" },
      addNotesForm: {
        openModal: this.props.openModal,
        customerNote: "",
        // NoteType: this.props.noteType,
        // noteRefrenceId: this.props.noteRefrenceId,
        actionPerformedBy:""
      }
      // {
      //   Id: "", businessId: this.props.global.bussinessDetail.businessId, isActive: true, actionPerformedBy: "admin"
      // }
    };
  }
  constructor(props) {
    super(props);

    this.state = { ...this.initialState, notes: [], editNotesForm: false, isCommonDeleteUpdateModalOpen: false, Id: '' };
    this.validator = commonFunctions.initializeSimpleValidator();
  }
  // static  (props, state) {
  //   ;
  static getDerivedStateFromProps(props, state) {
    if (props.customerId !== state.customerNotesForm.customerId) {
      // return {
      // const { customerNotesForm } = state;
      // customerNotesForm.customerId = props.customerId
      // this.setState({ customerNotesForm })

      // props.actions.apiCall({
      //     urls: ["GETCUSTOMERNOTES"], method: "GET", data: {customerNoteId:"-1", customerId: props.customerId , pageNo:"1", pageSize: "100", },
      //     onSuccess: (response) => {
      //       
      //      return {
      //        notes: response
      //      }
      //       // this.setState({ notes:response});

      //     }
      //   });
      return {
        customerNotesForm: {
          ...state.customerNotesForm, customerId: props.customerId,
        },
        addNotesForm: {
          ...state.addNotesForm, customerId: props.customerId, actionPerformedBy: props.auth.userDetail.emailId
        },
        getNotes: {
          ...state.getNotes, NoteType: props.noteType, noteRefrenceId: props.noteRefrenceId
        },
        // editNotesForm: !state.editNotesForm,
      };
    }
    // Return null if the state hasn't changed
    return null;
  }

  componentDidUpdate(prevProps, prevState) {

    if (this.props.openModal !== prevProps.openModal) {
      this.selectNew();
    }
  }

  selectNew = () => {

    const { getNotes } = this.state;
    getNotes["noteRefrenceId"] = this.props.noteRefrenceId
    getNotes["NoteType"] = this.props.noteType
    getNotes["businessId"] = this.props.global.bussinessDetail.businessId
    if (this.props.noteType) {
      this.props.actions.apiCall({
        urls: ["GETNOTE"], method: "GET", data: getNotes, onSuccess: (response) => {
          this.setState({ notes: response });
        }
      });
    }
  }

  openDeleteModal = (a) => {
    const messagetoShowInModal = 'Do you want to delete ' + "SelectedData" + " ?";
    this.setState({
      isCommonDeleteUpdateModalOpen: !this.state.isCommonDeleteUpdateModalOpen,
      Id: a.noteId,
      buttonName: "Delete",
      messageToShow: messagetoShowInModal
    });
  }

  openActiveInactiveModal = (e) => {
    const notesActiveInactiveForm = this.state.notesActiveInactiveForm
    notesActiveInactiveForm.Id = e.Id;
    notesActiveInactiveForm.isActive = e.isActive;
    const activeInactive = e.isActive ? "deactivate" : "activate";
    const messagetoShowInModal = 'Do you want to ' + activeInactive + " " + e.Id + " ?";
    this.setState({
      notesActiveInactiveForm,
      buttonName: "Update",
      messageToShow: messagetoShowInModal,
      isCommonDeleteUpdateModalOpen: !this.state.isCommonDeleteUpdateModalOpen
    });
  }
  closeDeleteModal = () => {
    this.setState({ isCommonDeleteUpdateModalOpen: !this.state.isCommonDeleteUpdateModalOpen });
  }
  updateActiveFee = (e, a, r) => {
    this.setState({ Inactive: false })
    this.setState({ activateModalopen: false })
  }

  updateActiveInactive = (e) => {
    if (this.props.noteType) {
      this.props.actions.apiCall({
        urls: ["DELETENOTE"], method: "DELETE", data: { "NoteId": this.state.Id, "ActionPerformedBy": this.props.auth.userDetail.emailId }, onSuccess: (response) => {
          const { notes } = this.state;
          var afterDeletedData = notes.filter(data => data.customerNoteId !== this.state.Id)


          this.setState({ notes: afterDeletedData, isCommonDeleteUpdateModalOpen: !this.state.isCommonDeleteUpdateModalOpen });
          this.selectNew()
        }, showNotification: true
      })

    }
  }

  addCustomerNotes = (e) => {
    //  Api call login post
    var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
    const { addNotesForm } = this.state;
    if (isFormValid) {
      if (this.props.noteType) {
        let data = {
          "noteType": this.props.noteType, "note": addNotesForm.customerNote,
          "noteRefrenceId": this.props.noteRefrenceId, ActionPerformedBy: this.props.auth.userDetail.emailId
        }
        this.props.actions.apiCall({
          urls: ["POSTADDNOTE"], method: "Post", data: data, onSuccess: (response) => {
            this.setState(this.initialState);
            this.selectNew()
            this.props.getOrdersDetail();
          },
          isFormData: true, showNotification: true
        });
      }
      else {
        this.props.actions.apiCall({
          urls: ["ADDCUSTOMERNOTE"], method: "Post", data: this.state.addNotesForm, onSuccess: (response) => {
            this.setState(this.initialState);
            this.props.getOrdersDetail();
          },
          isFormData: true, showNotification: true
        });
      }
      this.validator = commonFunctions.initializeSimpleValidator();
      this.props.closeModal();
    }


  }
  // This function is used to set handle change
  onHandleChange = (e, { name, value, type, checked, data }) => {
    var addNotesForm = commonFunctions.onHandleChange(e, { name, value, type, checked, data }, this.state.addNotesForm);
    this.setState({ addNotesForm });
  }
  // This function is used to set handle change
  onHandleChange = (e, { name, value, type, checked, data }) => {
    var addNotesForm = commonFunctions.onHandleChange(e, { name, value, type, checked, data }, this.state.addNotesForm);
    this.setState({ addNotesForm });
  }
  onCloseModal = () => {
    this.setState(this.initialState);
    this.validator = commonFunctions.initializeSimpleValidator();
    this.props.closeModal();
    this.props.getOrdersDetail();
  }
  openUserInfoModal = (data) => {
    this.setState({ openUserInfoModalStatus: !this.state.openUserInfoModalStatus, userNotes: data });
  };

  render() {
    const { notes, addNotesForm, isCommonDeleteUpdateModalOpen, openUserInfoModalStatus, messageToShow, buttonName } = this.state;
    // const firstName = this.props.firstName;
    // const emailId = this.props.emailId;

    const { api } = this.props;

    return (
      <React.Fragment>
        <Modal open={this.props.openModal} closeIcon onClose={this.props.closeModal} size={"small"}
          closeOnDimmerClick={false}>

          {api.isApiLoading &&
            <Dimmer active inverted> <Loader size='small'>Loading</Loader> </Dimmer>
          }
          <Modal.Content >
            <Table className="add-comment-table" singleLine unstackable
            //  style={{ 'min-width': 'fit-content' }}
            >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                  // width={4}
                  >User</Table.HeaderCell>
                  <Table.HeaderCell>Date</Table.HeaderCell>
                  <Table.HeaderCell>Time</Table.HeaderCell>
                  <Table.HeaderCell
                  // width={4}
                  >Note</Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">Action</Table.HeaderCell>
                  {/* <Table.HeaderCell>{firstName}</Table.HeaderCell>
                  <Table.HeaderCell>{emailId}</Table.HeaderCell> */}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {notes.map(
                  (data, index) => {
                    return (
                      <Table.Row>
                        <Table.Cell className="orange-color pointer textOverflowHidden" title={data.userName} onClick={() => this.openUserInfoModal(data)}>
                          {data.userName}</Table.Cell>
                        <Table.Cell>
                          <Moment format="MMMM DD,YYYY">{data.createdDate}</Moment>
                        </Table.Cell>
                        <Table.Cell>
                          <Moment format="hh:mm A">{data.createdDate}</Moment></Table.Cell>
                        <Table.Cell className="textBreak">{data.note}</Table.Cell>
                        <Table.Cell textAlign="right">
                          <Icon name="trash alternate" size="large" className="orange-color" title="Delete" onClick={() => this.openDeleteModal(data)} link />
                        </Table.Cell>
                      </Table.Row>
                    )
                  })}
              </Table.Body>
            </Table>
            <Grid>
              <Grid.Column width={16}>
                <Form>
                  <Form.TextArea rows={4} cols={92} fluid placeholder="Add Notes" id="addNotes"
                    type="textarea" textAlign="left" name="txtCustomerNote"
                    data="customerNote" value={addNotesForm.customerNote}
                    onChange={this.onHandleChange}
                    error={this.validator.message("customerNote", addNotesForm.customerNote, "required|max:500")} />
                </Form>
              </Grid.Column>
              <Grid.Column width={16} textAlign="right">
                <Button className="blue-btn" onClick={this.onCloseModal}>Cancel</Button>
                {/* onClick={this.props.closeModal} */}
                <Button className="orange-btn" onClick={this.addCustomerNotes} >Save</Button>
              </Grid.Column>
            </Grid>
          </Modal.Content>
        </Modal>
        <CommonDeleteUpdateModal
          onConfirm={this.updateActiveInactive}
          onClose={this.closeDeleteModal}
          confirmationButtonText={buttonName}
          header={messageToShow}
          isModalOpen={isCommonDeleteUpdateModalOpen}
          cancelButtonText={'Cancel'}
        />
        <User openModal={openUserInfoModalStatus} closeModal={this.openUserInfoModal} userNotes={this.state.userNotes} />
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
    orderState: state.orderState
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
    }
  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddCommentModal));

