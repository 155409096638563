import React, { Component } from "react";
import { Grid, Button, Dimmer, Loader } from "semantic-ui-react";
import ActivityForm from "../../../../../pages/more-setting/pricing-taxes-setting/activity-pricing/activity-form";
import { connect, bindActionCreators, actions, Notifications,commonFunctions } from "../../../../functional/global-import";

class ActivityPricingModal extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
    }
  };

  handleChange = (e, { value }) => this.setState({ value });

  onHandleSaveActivity = () => {
    if (this.props.getProductCategoryDetail) {
      this.props.getProductCategoryDetail();
    }
    this.props.closeModal();
    this.props.actions.showNotification({
      title: "Success", message: 'Activity Saved Successfully',
      position: 'br', autoDismiss: commonFunctions.notificationTime()
    }, "success");
  }

  render() {
    const { AddCustomField, activityId, backStep, api } = this.props;
    return (
      <>
        <Grid>
          {api.isApiLoading && (
            <Dimmer active inverted>
              <Loader />
            </Dimmer>
          )}
          <Grid.Column width={16}>
            <ActivityForm activityId={activityId} fullState={this.props.fullState} allFunction={this.props.allFunction} />
          </Grid.Column>
          <Grid.Column width={16} textAlign="right">
            <Button className="blue-btn" onClick={backStep}>Back</Button>
            <Button className="orange-btn" onClick={this.onHandleSaveActivity}>Save</Button>
          </Grid.Column>
        </Grid>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
      storeActivityId: bindActionCreators(actions.storeActivityId, dispatch)
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ActivityPricingModal);