import React, { Component } from "react";
import { Grid, Table, Input, Icon } from "semantic-ui-react";
import TableHeader from "../data-table/table-header";
import TableRow from "../data-table/table-row";
import {
  connect,
  bindActionCreators,
  actions,
  commonFunctions,
} from "../../../functional/global-import";
import { Fragment } from "react";

class DataTable extends Component {
  get initialState() {
    return {
      tableHeader: {
        businessId: this.props.global.bussinessDetail.businessId,
        userId: this.props.auth.userDetail.keyId,
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        screenName: this.props.screenName,
        gridName: this.props.gridName,
        columns: [],
        actionPerformedBy: this.props.auth.userDetail.emailId,
      },
      getGridDataObj: {
        isActive: false,
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        pageNo: 1,
        pageSize: this.props.defaultPageSize ? this.props.defaultPageSize : 10,
        sortColumn:
          this.props.defaultSort && this.props.defaultSort.sortColumn
            ? this.props.defaultSort.sortColumn
            : "modifiedDate",
        sortOrder:
          this.props.defaultSort && this.props.defaultSort.sortOrder
            ? this.props.defaultSort.sortOrder
            : "desc",
        sortingArrow: "sort",
        searchValue: "",
        actionPerformedBy: this.props.auth.userDetail.emailId,
      },
      hasMore: true,
    };
  }

  constructor(props) {
    super(props);
    this.tableRowRef = React.createRef();
    this.state = {
      ...this.initialState,
      columns: [],
      columnFilter: [],
      columnsList: [],
      userDefinedColumns: [],
      localUserDefinedColumns: [],
      gridDataLoading: false,
      traits: [{ FieldName: "Item Name" }],
    };
  }

  getDefaultData = (locationSelected, e) => {
    // const search = e === undefined ? "" : e
    this.setState({
        ...this.state,
        getGridDataObj: {
          ...this.state.getGridDataObj,
          businessLocationId: locationSelected
            ? locationSelected
            : this.props.global.locationSelected.BusinessLocationId,
          pageNo: 1,
        },
        hasMore: true,
      },
      () => {
        this.getGridData();
      }
    );
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.global.locationSelected.BusinessLocationId !==
      this.props.global.locationSelected.BusinessLocationId
    ) {
      this.getDefaultData(
        this.props.global.locationSelected.BusinessLocationId
      );
    }
  }
  ////////////---Get api call function ----------//////////////////////

  getGridData = () => {
    this.setState({ gridDataLoading: true });
    this.props.actions.apiCall({
      urls: [this.props.getApiName],
      method: "GET",
      data: {
        ...this.state.getGridDataObj,
        ...this.props.getGridDataParamters,
      },
      onSuccess: (response) => {
                    
        let responseData =
          this.state.getGridDataObj.pageNo === 1
            ? response
            : this.props.fullState.gridDetail.concat(response);
        this.props.allFunction.setState({
          ...this.props.fullState,
          gridDetail: responseData,
        });

        if (response?.length !== 10) {
          this.setState({ hasMore: false });
        } else {
          this.setState({ hasMore: true });
        }
      },
      onFinally: () => {
        this.setState({ gridDataLoading: false });
      },
    });
  };

  onHandleSorting = (heading) => {
                
    let sortOrder =
      this.state.getGridDataObj.sortOrder === "asc" ? "desc" : "asc";
    let sortArrow =
      this.state.getGridDataObj.sortingArrow === "sort up"
        ? "sort down"
        : "sort up";
    this.setState(
      {
        getGridDataObj: {
          ...this.state.getGridDataObj,
          sortColumn: heading,
          sortOrder: sortOrder,
          sortingArrow: sortArrow,
          pageNo: 1,
        },
        hasMore: true,
      },
      () => {
        this.getGridData();
      }
    );
  };

  fetchMoreData = () => {
    const countPageNo = this.state.getGridDataObj.pageNo + 1;
    // 20 more records in 1.5 secs
    setTimeout(() => {
      this.setState(
        {
          getGridDataObj: { ...this.state.getGridDataObj, pageNo: countPageNo },
        },
        () => {
          this.getGridData();
        }
      );
    }, 500);
  };
  ///////////// --TABLE HEADING DROPDOWN CHECKBOX FUNCTION-- /////////////
  onHandleColumnDataChange = (e, { checked, index }) => {
    let { localUserDefinedColumns } = this.state;
            
    if (checked) {
      localUserDefinedColumns[index]["CD"] = 1;
    }
    if (!checked) {
      localUserDefinedColumns[index]["CD"] = 0;
    }
    this.setState({ localUserDefinedColumns });
    this.setState(() => ({
      tableHeader: {
        ...this.state.tableHeader,
        columns: this.state.localUserDefinedColumns,
      },
    }));
  };
  ///////////// --TABLE HEADING DROPDOWN DONE FUNCTION-- /////////////
  onHandleHiddenColumn = () => {
            
    this.props.actions.apiCall({
      urls: ["POSTCOMMONTABLE"],
      method: "POST",
      data: this.state.tableHeader,
      onSuccess: (response) => {
                
        this.setState(() => ({
          tableHeader: {
            ...this.state.tableHeader,columns:this.state.localUserDefinedColumns,
          },
        }));
        this.setState({
          userDefinedColumns:JSON.parse(JSON.stringify(this.state.localUserDefinedColumns)
          ),
        });
      },
    });
  };
  /////////////--GET API FOR TABLE--/////////////
  getCommonGrid = () => {
    this.props.actions.apiCall({
      urls: ["COMMONGRID"],
      method: "GET",
      data: {
        businessId: this.props.global.bussinessDetail.businessId,
        userId: this.props.auth.userDetail.keyId,
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        screenName: this.props.screenName,
        gridName: this.props.gridName,
      },
      onSuccess: (response) => {
                
        this.setState({
          columnsList: JSON.parse(response[0].systemDesfinedGridColumns),
          userDefinedColumns: JSON.parse(response[0].userDefinedGridColumns),
          localUserDefinedColumns: JSON.parse(
            response[0].userDefinedGridColumns
          ),
        });
        this.setState(() => ({
          tableHeader: {
            ...this.state.tableHeader,
            columns: JSON.parse(response[0].systemDesfinedGridColumns),
          },
        }));
      },
    });
  };
  componentDidMount = () => {
    this.updateColumn();
    this.getGridData();
    this.getCommonGrid();
  };
  //=================== this function is used to update columns======================
  updateColumn = () => {
    const updateColumn = this.props.updateHeader;
            
    if (updateColumn !== undefined) {
      const columns = this.props.columns.filter((columns) => {
        return columns.headerName !== this.props.updateHeaderColumn;
      });
              
      this.setState({ columns: columns });
    } else {
      this.setState({ columns: this.props.columns });
    }
  };
  onHandleChange = (e, { name, value, type, checked, data }) => {

    var getGridDataObj = commonFunctions.onHandleChange(
      e,
      { name, value, type, checked, data },
      this.state.getGridDataObj
    );
    this.setState({ getGridDataObj });
  };
  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      this.getDefaultData();
    }
  };
  handleSearchPressed = (e) => {
    if (e.type === "click") {
      this.getDefaultData();
    }
  };
  onHandleCloseSearch = () => {
    this.setState(
      { getGridDataObj: { ...this.state.getGridDataObj, searchValue: "" } },
      () => this.getGridData()
    );
  };
  render() {
    const {
      columns,
      emptyGrid,
      gridName,
      tableHeight,
      fullState,
      searchOption,
      hideEmptyGrid,
      className,
      selectAll,
      allFunction,
    } = this.props;
    const { hasMore, gridDataLoading, getGridDataObj } = this.state;
    const hideGrid = hideEmptyGrid && fullState.gridDetail.length === 0;

    return (
      <Fragment>
        {!hideGrid && (
          <>
            {searchOption && searchOption.show && (
              <Grid.Column computer={8} tablet={8}>
                <div className="commonSearch">
                  <Input
                    fluid
                    icon="search"
                    onChange={this.onHandleChange}
                    onKeyPress={this.handleKeyPress}
                    name="searchValue"
                    data="searchValue"
                    value={getGridDataObj.searchValue}
                    iconPosition="left"
                    placeholder={
                      searchOption.placeHolder
                        ? searchOption.placeHolder
                        : "Search"
                    }
                    className="common-search-bar"
                  />
                  <div className="searchIcon">
                    {this.state.getGridDataObj.searchValue.length > 0 ? (
                      <Icon name="close" onClick={this.onHandleCloseSearch} />
                    ) : (
                      false
                    )}
                    <Icon name="search" onClick={this.handleSearchPressed} />
                  </div>
                </div>
              </Grid.Column>
            )}
            <Grid.Column width={16}>
              <div
                className={className === "noClass" ? "noClass" : "common-table"}
              >
                <Table
                  className="table-scrolling commonTableDropdown"
                  id={gridName}
                  singleLine
                >
                  <TableHeader
                    columns={columns}
                    columnsList={this.state.columnsList}
                    onHandleSorting={this.onHandleSorting}
                    sortingArrow={getGridDataObj.sortingArrow}
                    sortColumn={getGridDataObj.sortColumn}
                    onChange={this.onHandleColumnDataChange}
                    hideColumns={this.state.userDefinedColumns}
                    dropdownCheck={this.state.localUserDefinedColumns}
                    onHandleHiddenColumn={this.onHandleHiddenColumn}
                    gridName={gridName}
                    allFunction={allFunction}
                    // fullState={fullState}
                    // selectAll={selectAll}
                    showSelectAll={
                      fullState.showSelectAll === undefined
                        ? false
                        : fullState.showSelectAll
                    }
                    // isToShowDropDown={fullState.dropDownFilter === undefined ? true : fullState.dropDownFilter}
                  />
                  <TableRow
                    singleLine
                    ref={this.tableRowRef}
                    data={fullState.gridDetail}
                    columns={columns}
                    hasMore={hasMore}
                    fetchMoreData={this.fetchMoreData}
                    emptyGrid={emptyGrid}
                    hideColumns={this.state.userDefinedColumns}
                    gridName={gridName}
                    gridDataLoading={gridDataLoading}
                    tableHeight={tableHeight}
                  />
                </Table>
              </div>
            </Grid.Column>
          </>
        )}
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(DataTable);
