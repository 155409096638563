const timeHours = [
    { key: "01", value: "01", text: "01" },
    { key: "02", value: "02", text: "02" },
    { key: "03", value: "03", text: "03" },
    { key: "04", value: "04", text: "04" },
    { key: "05", value: "05", text: "05" },
    { key: "06", value: "06", text: "06" },
    { key: "07", value: "07", text: "07" },
    { key: "08", value: "08", text: "08" },
    { key: "09", value: "09", text: "09" },
    { key: "10", value: "10", text: "10" },
    { key: "11", value: "11", text: "11" },
    { key: "12", value: "12", text: "12" },
  ];
  const timeMinutes = [
    { key: "00", value: "00", text: "00" },
    { key: "05", value: "05", text: "05" },
    { key: "10", value: "10", text: "10" },
    { key: "15", value: "15", text: "15" },
    { key: "20", value: "20", text: "20" },
    { key: "25", value: "25", text: "25" },
    { key: "30", value: "30", text: "30" },
    { key: "35", value: "35", text: "35" },
    { key: "40", value: "40", text: "40" },
    { key: "45", value: "45", text: "45" },
    { key: "50", value: "50", text: "50" },
    { key: "55", value: "55", text: "55" },
  ];
  const timeMeridian = [
    { key: "AM", value: "AM", text: "AM" },
    { key: "PM", value: "PM", text: "PM" },
  ];


export const timeTableOptions = {
    timeHours,
    timeMinutes,
    timeMeridian,
  };