import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import { connect } from '../../../functional/global-import';

const GlobalCodeSelect = ({ placeholder, name, onChange, value, categoryType, data, global, error, disabled, isRounded, defaultValue }) => {
    let filteredGlobalCodes = []

    let globalCodes = global.codes.filter(code => code.categoryName === categoryType).map((filtercode) => {

        return { filtercode: filtercode.codeName, value: filtercode.globalCodeId, text: filtercode.codeName }
    });
    // To   remove Custom from other's dropdown Custom option is used only in transaction screen
    if (name === "rentalOrderDropdown") {
        globalCodes = globalCodes.filter((item) => { return item.text !== "Monthly" && item.text !== "Yearly" });
            //   in rental item in new order this is done 
        let timeFrameDropdownOptionMinute = []
        let timeFrameDropdownOptionHour = []
        let timeFrameDropdownOptionDay = []
        let timeFrameDropdownOptionWeek = []
        globalCodes.map((singleObj) => {
            if (singleObj.text === "Minute") {
                timeFrameDropdownOptionMinute.push(singleObj)
            }
            else if (singleObj.text === "Hour") {
                timeFrameDropdownOptionHour.push(singleObj)
            }
            else if (singleObj.text === "Day") {
                timeFrameDropdownOptionDay.push(singleObj)
            }
            else if (singleObj.text === "Week (7Days per week)") {
                timeFrameDropdownOptionWeek.push(singleObj)
            }
        })

        globalCodes = [...timeFrameDropdownOptionMinute, ...timeFrameDropdownOptionHour, ...timeFrameDropdownOptionDay, ...timeFrameDropdownOptionWeek]
    }
    else if (name === "rentalSlotBased") {
        let newRentalDurationOPtions = []
        globalCodes.map((singleObjSlotBased) => {
            if (singleObjSlotBased.text === "Minute" || singleObjSlotBased.text === "Hour" || singleObjSlotBased.text === "Day") {
                newRentalDurationOPtions.push(singleObjSlotBased)
            }
        })
        globalCodes = newRentalDurationOPtions
    }
    else if (name !== "ddTimePeriodTransaction") {
        globalCodes = globalCodes.filter((item) => { return item.text !== "Custom" });
    }
    else if (name === "ddTimePeriodTransaction") {
        globalCodes = globalCodes.filter((item) => { return item.text !== "Past Week" && item.text !== "Past Month" && item.text !== "Last Year" });
    }


    filteredGlobalCodes.push({ filtercode: -1, value: -1, text: placeholder, disabled: true })
    filteredGlobalCodes = filteredGlobalCodes.concat(globalCodes)

    return (
    <Form.Select placeholder={placeholder} defaultValue={defaultValue}
     type="select-int" options={filteredGlobalCodes} className="custom-select bordered-input"
      data={data} name={name} value={value} fluid onChange={onChange} error={error} disabled={disabled} />);
};

GlobalCodeSelect.propTypes = {
    placeholder: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.number.isRequired,
    data: PropTypes.string.isRequired,
    disabled: PropTypes.string.isRequired,
    categoryType: PropTypes.string.isRequired,
    error: PropTypes.func
};

const mapStateToProps = state => {
    return {
        global: state.global
    };
};

export default connect(mapStateToProps, null)(React.memo(GlobalCodeSelect));

