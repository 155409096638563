import React, { Component } from "react";
import { Dropdown, Grid, Divider, Header } from "semantic-ui-react";
import { commonFunctions,connect, bindActionCreators, actions, Notifications } from "../../../functional/global-import";
class CommonPhysicalLocation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            locationsList: [],
        }
    }
    getPhysicalLocations = () => {
        this.props.actions.apiCall({
            urls: ["GETBUSINESSLOCATION"],
            method: "GET",
            data: { businessId: this.props.global.bussinessDetail.businessId, PageNo: 1, PageSize: 100 },
            onSuccess: (response) => {
                const locations = response.map((singleLocation) => {
                    return { value: singleLocation.businessLocationId, text: singleLocation.businessLocationName }
                });
                this.setState({ locationsList: locations });
            },
        });
    };
    componentDidMount() {
        this.getPhysicalLocations();
        const { fullState,stateForm ,allFunction} = this.props;
        fullState[stateForm].selectedLocation=[JSON.parse(this.props.global.locationSelected.BusinessLocationId)];
        allFunction.setState({ ...fullState});
    }
    onHandleChange = (e, { name, value, type, checked, data }) => {
        const { fullState,stateForm ,allFunction} = this.props;
        var addLocation = commonFunctions.onHandleChange(e, { name, value, type, checked, data }, fullState[stateForm],allFunction);
        fullState[stateForm]=addLocation;
        allFunction.setState({ ...fullState});
    };
 
    render() {
        const { fullState, stateForm } = this.props;
        return (
            <React.Fragment>
                <Grid.Column width={16}>
                    <Divider />
                    <Header as="h4">Physical Location</Header>
                </Grid.Column>

                <Grid.Column width={6}>
                    <Dropdown options={this.state.locationsList} data="selectedLocation" value={fullState[stateForm].selectedLocation} search selection fluid multiple type="multiple-select" onChange={this.onHandleChange} className="tagger" defaultValue="lc1" />
                </Grid.Column>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        api: state.api,
        global: state.global,
        auth: state.auth
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            apiCall: bindActionCreators(actions.apiCall, dispatch),
            storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
            showNotification: bindActionCreators(Notifications.show, dispatch),
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CommonPhysicalLocation);

