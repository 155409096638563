import React, { Component } from "react";
import { Modal, Step, Icon, Grid } from "semantic-ui-react";
import SelectRole from "./select-role";
import DescribeRole from "./describe-role";
import PermissionRole from "./permission-role";
import { connect, bindActionCreators, actions, commonFunctions, withRouter, env, Notifications } from "../../../../functional/global-import";


class AddRoleModal extends Component {
  // Put all key & values that need to reset on form submit and reset form.
  get initialState() {
    return {
      roleForm: {
        roleId: this.props.editRole === true ? this.props.roleId : "",
        applicationId: env.APPLICATION_ID, businessId: this.props.global.bussinessDetail.businessId,
      },
      addRoleForm: {
        oldRoleId: this.props.roleId ? this.props.roleId : "",
        roleId: 0, data: [],
        roleName: "", businessId: this.props.global.bussinessDetail.businessId,
        roleDescription: "", actionPerformedBy: this.props.auth.userDetail.emailId
      },
      displayButton: "Save",
      isDescribeBtnLoading: false,
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      ...this.initialState,
      activeStep: this.props.editRole === true ? 2 : 0,
      roleDetails: [],
      rolePermissionScreenList: [],
      roleId: " ",
      masterAccess: false,
      iconClicked: false,
      newAddRole: [],
      isAddOrEdit: false,
      activeTab:0
    };
    this.validatorRoleForm = commonFunctions.initializeSimpleValidator();
    this.validatorAddRoleForm = commonFunctions.initializeSimpleValidator();
    this.validator = commonFunctions.initializeSimpleValidator();
  }
  componentDidMount = () => {
    if (this.props.editRole && this.props.roleId) {
      // this.getRole();
      this.getRoleAndPermissionList(true);
    }
    this.getRole();
  }
  onHandleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeTab } = this.state;
    const newIndex = activeTab === index ? -1 : index;
    this.setState({ activeTab: newIndex });
  }
  getRole = () => {
    let roleDetailss = []
    this.props.actions.apiCall({
      urls: ["GETROLE"], method: "GET", data: { "roleId": -1, "businessId": this.props.auth.loggedIn.businessId },
      onSuccess: (response) => {
        if (this.props.editRole && this.props.roleId) {
          roleDetailss = response.filter((x) => x.roleId === this.props.roleId);
          const { addRoleForm, iconClicked } = this.state;
          if (!iconClicked) {
            addRoleForm.roleId = roleDetailss[0].roleId;
            addRoleForm.roleName = roleDetailss[0].roleName;
            addRoleForm.roleDescription = roleDetailss[0].roleDescription;
            this.setState({ roleDetails: roleDetailss, addRoleForm, masterAccess: roleDetailss[0].masterLogin, roleName: roleDetailss[0].roleName, roleDescription: roleDetailss[0].roleDescription })
          }
        }
        else {
          this.setState({ roleDetails: response })
        }
      }
    });
  }
  //==================================   Select Role Component Functions  ====================================//

  //  iconClicked: false, rolePermissionScreenList: [], roleName: e.target.innerHTML, roleDescription: description 

  // this function is used for handle cange of icon
  onIconClick = (data) => {
    const { iconClicked, addRoleForm, roleDescription, roleName, rolePermissionScreenList, roleForm } = this.state;
    if (!iconClicked) {
      addRoleForm.roleId = 0
      addRoleForm.roleName = ""
      addRoleForm.roleDescription = ""
      addRoleForm.data = JSON.stringify(rolePermissionScreenList)
    }
    else {
      addRoleForm.roleName = roleName;
      addRoleForm.roleDescription = roleDescription;
    }
    this.setState({ addRoleForm, iconClicked: !iconClicked }, () => { this.getRoleAndPermissionList() })
  }

  // This function is used to set handle change
  onHandleChangeSelectRole = (e, { name, value, type, checked, data, description }) => {
    
    const { addRoleForm, rolePermissionScreenList } = this.state;
    addRoleForm.roleId = value
    addRoleForm.oldRoleId = value
    addRoleForm.roleName = e.target.innerHTML;
    addRoleForm.roleDescription = description;
    addRoleForm.data = JSON.stringify(rolePermissionScreenList)
    var roleForm = commonFunctions.onHandleChange(e, { name, value, type, checked, data }, this.state.roleForm);
    this.setState({ roleForm, addRoleForm, iconClicked: false, rolePermissionScreenList: [], roleName: e.target.innerHTML, roleDescription: description }, () => {
      this.getRoleAndPermissionList(true);
    });
  }
  onHandleSubmitSelectRole = () => {
    
    const { roleForm } = this.state;
    if (roleForm.roleId === "" && this.state.editRole === false) {
      this.props.actions.showNotification(
        {
          title: "Warning",
          message:
            'Please select atleast one role to edit or create new role .',
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    }
    else {
      this.onNext(1);
      this.getRole();
    }
  }

  getRoleAndPermissionList = (detail) => {
    this.props.actions.apiCall({
      urls: ["GETROLEANDPERMISSIONLIST"], method: "GET", data: this.state.roleForm,
      onSuccess: (response) => {
        const screenList = response.map((screen, index) => {
          const permissionlist = JSON.parse(screen.permissions)
          if (screen.permissions !== null) {
            const permissionDetail = permissionlist?.map((permission, index) => {
              const actionDetails = JSON.parse(permission.Actions ? permission.Actions : null);
              return { ScreenName: permission.ScreenName, screenId: permission.ScreenId, access: permission.Access, action: actionDetails }
            })
            return {
              Access: screen.access, moduleId: screen.moduleId, moduleName: screen.moduleName, permission: permissionDetail
            }
          }
          else {
            const permissionDetail = null
            return {
              Access: screen.access, moduleId: screen.moduleId, moduleName: screen.moduleName, permission: permissionDetail
            }
          }
        })
        if (detail && this.state.iconClicked === false) {
          this.setState({
            rolePermissionScreenList: screenList, masterAccess: response[0].masterLogin === 1 ? true : false
          })
        }
        const { addRoleForm } = this.state;
        addRoleForm.data = JSON.stringify(this.state.rolePermissionScreenList);
      }
    });
    // }
  }

  //============================== Update Role And Permission List ===============================//

  onUpdateRoleAndPermissionList = () => {
    this.props.actions.apiCall({
      urls: ["UPDATEROLEANDPERMISSIONLIST"], method: "PUT", data: {
        businessId: this.props.global.bussinessDetail.businessId,
        //businessLocationId: this.props.global.locationSelected.BusinessLocationId,
        applicationId: env.APPLICATION_ID,
        roleId: this.state.newAddRole.length >= 0 ? this.state.roleForm.roleId : this.state.newAddRole.keyId,
        data: JSON.stringify(this.state.rolePermissionScreenList),
        actionPerformedBy: this.props.auth.userDetail.emailId,
      }, onSuccess: (response) => {
        this.props.closeModal();
        this.props.getRolePermissionDetail();
      },
      showNotification: true
    });
  }
  //============================== Describe ROle Functions============================
  //This function is used to ADDROLE 
  onHandleSubmitDescribeRole = (e) => {
    
    const allFunction = this;
    if (this.validatorAddRoleForm.allValid() === false) {
      this.validatorAddRoleForm.showMessages();
      this.forceUpdate();
      this.setState({ addRoleForm: { ...this.state.addRoleForm, valid: false } })
    }
    else {
      this.props.actions.apiCall({
        urls: ["ADDROLE"], method: "Post", data: this.state.addRoleForm, onSuccess: (response) => {
          const { roleForm } = this.state;
          roleForm.roleId = response.keyId
          this.setState({ newAddRole: response, roleForm, isAddOrEdit: false })
          this.getRoleAndPermissionList(true);
          this.props.getRolePermissionDetail();
          this.getRole();
          this.onNext(2)
        }, showNotification: true
      });
      // this.onNext(2)
    }
  }
  // This function is used to set handle change
  onHandleChangeDescribeRole = (e, { name, value, type, checked, data }) => {
    var addRoleForm = commonFunctions.onHandleChange(e, { name, value, type, checked, data }, this.state.addRoleForm);
    this.setState({ addRoleForm, isAddOrEdit: true });
  }

  // ============================ Permission Role Component functions ===========================
  // this function is use for back button prpose and take the data to back 
  onBackClick = () => {
    const { rolePermissionScreenList } = this.state;
    this.props.onPrevious(1, rolePermissionScreenList);
  }
  // UPDATEROLEMASTERLOGIN
  onHandleMasterChange = (e, checked, data) => {
    this.props.actions.apiCall({
      urls: ["UPDATEROLEMASTERLOGIN"], method: "PUT", data: {
        businessId: this.props.global.bussinessDetail.businessId,
        masterLogin: checked.checked,
        roleId: this.state.newAddRole.length >= 0 ? this.state.roleForm.roleId : this.state.newAddRole.keyId,
        actionPerformedBy: this.props.auth.userDetail.emailId,
      }, onSuccess: (response) => {
        this.props.getRolePermissionDetail();
        this.setState({ masterAccess: checked.checked });
      }, showNotification: true
    });
  }
  onScreenModuleChange = (moduleIndex, screenIndex, e, checked, indeterminate) => {
    let { rolePermissionScreenList } = this.state;
    let access = checked.checked ? 1 : 0;
    rolePermissionScreenList[moduleIndex]["Access"] = 1;
    const allAction = rolePermissionScreenList[moduleIndex].permission[screenIndex].action;
    allAction?.forEach((data) => {
      data.Access = access;
    })
    rolePermissionScreenList[moduleIndex].permission[screenIndex]["access"] = access;
    this.setState({ rolePermissionScreenList })
  }
  onHandleModuleChange = (moduleIndex, e, checked) => {
    let { rolePermissionScreenList } = this.state;
    let access = checked.checked ? 1 : 0;
    if (rolePermissionScreenList[moduleIndex]["permission"] === null) {
      rolePermissionScreenList[moduleIndex]["Access"] = access;
    }
    else {
      rolePermissionScreenList[moduleIndex]["Access"] = access;
      const screenAccess = rolePermissionScreenList[moduleIndex]["permission"].filter((x) => x);
      screenAccess.forEach((x) => {
        x.access = access
      })
      let allowedAction = []
      screenAccess.map((item3) => {
        item3.action?.map((item4) => {
          allowedAction.push(item4)
        })
      })
      allowedAction.filter((item) => item);
      allowedAction.forEach((x) => {
        x.Access = access
      })
    }
    this.setState({ rolePermissionScreenList })
  }

  onActionHandleChange = (moduleIndex, screenIndex, actionIndex, e, checked) => {
    let { rolePermissionScreenList } = this.state;
    let access = checked.checked ? 1 : 0;
    rolePermissionScreenList[moduleIndex].permission[screenIndex].action[actionIndex]["Access"] = access;

    /// for showing mixed state 
    const checkAllselected = rolePermissionScreenList[moduleIndex].permission[screenIndex].action
    checkAllselected.forEach((data) => {
      if (data.Access === 1) {
        const screenId = rolePermissionScreenList[moduleIndex].permission[screenIndex]["screenId"]
        rolePermissionScreenList[moduleIndex]["Access"] = 1;
        const screenAccess = rolePermissionScreenList[moduleIndex]["permission"].filter((x) => x.screenId === screenId);
        screenAccess.forEach((x) => {
          x.access = 1
        })
      }
    })
    this.setState({ rolePermissionScreenList })
  }
  onSubmit = () => { this.onUpdateRoleAndPermissionList() }
  //======================================================================================================= //
  static getDerivedStateFromProps(props, state) {
    if (props.roleId && props.roleId !== state.roleId) {
      return {
        roleId: props.roleId,
        editRole: props.editRole
      }
    }
    if (props.editRole !== state.editRole) {
      return {
        editRole: props.editRole
      }
    }
    // Return null if the state hasn't changed
    return null;
  }
  changeStep = (stepNumber) => {
    // 
    const { addRoleForm, roleForm } = this.state;
    if (addRoleForm.roleId === 0 && this.state.editRole === false) {
      if (this.state.iconClicked === false && stepNumber) {
        this.props.actions.showNotification(
          {
            title: "Warning",
            message:
              'Please select atleast one role to edit or create new role .',
            position: "br",
            autoDismiss: commonFunctions.notificationTime(),
          },
          "error"
        );
      }
      if (this.state.iconClicked && this.state.isAddOrEdit === true) {
        this.onHandleSubmitDescribeRole();
      }
      if (this.state.iconClicked && addRoleForm.roleName === "") {
        const { activeStep } = this.state;
        // stepNumber === 0 &&  this.onPrevious(0)
        if (stepNumber === 0) {
          this.onPrevious(0)
        }
        else if (stepNumber === 2 && activeStep === 1) {
          this.onHandleSubmitDescribeRole()
        }
        else if (stepNumber === 2 && activeStep === 0) {
          this.setState({ activeStep: 1 });
        }
        else if (stepNumber === 1 && activeStep === 0) {
          this.setState({ activeStep: 1 });
        }
      }
    }
    else {
      const { activeStep, iconClicked } = this.state;
      if (iconClicked) {
        if (stepNumber === 2 && activeStep === 0) {
          this.setState({ activeStep: 1 });
        }
        else if (stepNumber === 1 && activeStep === 0) {
          this.setState({ activeStep: 1 });
        }

        else if (stepNumber === 2 && activeStep === 1) {
          this.onHandleSubmitDescribeRole()
        }
        else if (stepNumber === 0) {
          this.onPrevious(0)
        }
      }

      else {
        this.setState({ activeStep: stepNumber })
      }

      // this.setState({ activeStep: stepNumber })
    }
  };
  getStepContent = (stepIndex) => {
    const allfunction = this;
    const fullState = this.state;
    const propsData = this.props;
    const { addRoleForm } = this.state
    
    switch (stepIndex) {
      case 0:
        return (
          <Grid>
            <Grid.Column width={16}>
              <SelectRole fullState={fullState} allfunction={allfunction} propsData={propsData} addRoleForm={addRoleForm} />
            </Grid.Column>
          </Grid>
        );
      case 1:
        return (
          <Grid>
            <Grid.Column width={16}>
              <DescribeRole fullState={fullState} allfunction={allfunction} propsData={propsData} addRoleForm={addRoleForm} />
            </Grid.Column>
          </Grid>
        );
      case 2:
        return (
          <Grid>
            <Grid.Column width={16}>
              <PermissionRole fullState={fullState} allfunction={allfunction} propsData={propsData} addRoleForm={addRoleForm} />
            </Grid.Column>
          </Grid>
        );
      default:
        return null;
    }
  };

  onNext = (step) => {
    this.setState({ activeStep: step })
  };

  // onNext = (step, e, roleslist, iconClicked, roleName, roleDescription) => {
  //   // 
  //   if (step === 2) {
  //     this.setState({ activeStep: step });
  //   }
  //   else {
  //     this.setState({ activeStep: step, roleId: e, rolePermissionScreenList: roleslist, iconClicked: iconClicked, roleName: roleName, roleDescription: roleDescription });
  //   }
  // };

  onPrevious = (step) => {
    this.validatorAddRoleForm = commonFunctions.initializeSimpleValidator();
    this.setState({ activeStep: step })
  };
  render() {
    const { activeStep, iconClicked, roleForm } = this.state;
    return (
      <Modal open={this.props.openModal} closeIcon onClose={this.props.closeModal} >
        <Modal.Header>
          {roleForm.roleId && iconClicked ? "Create New Role" : "Edit Role"}
        </Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            <Step.Group stackable="tablet" className="custom-stepper">
              <Step active={activeStep === 0 ? true : false} completed={activeStep > 0 ? true : false} onClick={() => this.changeStep(0)} >
                <Step.Content>
                  <Step.Title>
                    Select Role{" "}
                    <Icon name="check circle" className="green-check" />
                  </Step.Title>
                  <Step.Description>1</Step.Description>
                </Step.Content>
              </Step>
              <Step active={activeStep === 1 ? true : false} completed={activeStep > 1} onClick={() => this.changeStep(1)}>
                <Step.Content>
                  <Step.Title>
                    Describe Role{" "}
                    <Icon name="check circle" className="green-check" />
                  </Step.Title>
                  <Step.Description>2</Step.Description>
                </Step.Content>
              </Step>
              <Step active={activeStep === 2 ? true : false} completed={activeStep > 2} onClick={() => this.changeStep(2)} >
                <Step.Content>
                  <Step.Title>Permissions </Step.Title>
                  <Step.Description>3</Step.Description>
                </Step.Content>
              </Step>
            </Step.Group>
            <div>{this.getStepContent(activeStep)}</div>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    api: state.api,
    global: state.global,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch)
    }
  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddRoleModal));
