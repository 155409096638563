import React, { Component } from "react";
import { Grid, Button } from "semantic-ui-react";
import { commonFunctions, connect, bindActionCreators, actions, Notifications } from "../../../../functional/global-import";
import CommonImage from "../../../atoms/common-image";

class UploadSeasonPassPhoto extends Component {
  constructor(props) {
    super(props);
                
    this.state = {
    };
  }
  backStep = (e) => {
    e.preventDefault();
    const { fullState, allFunction } = this.props
    if ((fullState.uploadImage.images[0] !== undefined && fullState.uploadImage.DefaultImage[0] !== undefined) ||
      (fullState.uploadImage.images[0] === undefined &&
        fullState.uploadImage.DefaultImage[0] === undefined)) {
      allFunction.backStep();
    }
    else if (fullState.uploadImage.images[0] !== undefined && fullState.uploadImage.DefaultImage[0] === undefined) {
      this.props.actions.showNotification({
        title: "Error",
        message: 'Please select default image.', position: 'br',
        autoDismiss: commonFunctions.notificationTime()
      }, "error");
    }
  }
  changeNext = () => {
    const { fullState, allFunction } = this.props
    if ((fullState.uploadImage.images[0] !== undefined && fullState.uploadImage.DefaultImage[0] !== undefined) ||
      (fullState.uploadImage.images[0] === undefined &&
        fullState.uploadImage.DefaultImage[0] === undefined)) {
      allFunction.changeNext(2);
    }
    else if (fullState.uploadImage.images[0] !== undefined && fullState.uploadImage.DefaultImage[0] === undefined) {
      this.props.actions.showNotification({
        title: "Error", message: 'Please select default image.', position: 'br',
        autoDismiss: commonFunctions.notificationTime()
      }, "error");
    }
  }

  render() {
  const { uploadImage, api, fullState, allFunction, } = this.props;       
    return (
      <React.Fragment>
        <Grid>
          <Grid.Column mobile={16} tablet={16} computer={16} textAlign="center" className="controlField">
            <CommonImage
              uploadImage={fullState.uploadImage}
              imagelist={fullState.uploadImage.images}
              onImageChange={allFunction.onImageChange}
              multiple={true}
              onImageRemove={allFunction.onImageRemove}
              defaultImage={fullState.uploadImage.DefaultImage}
              isRadio={true}
              imageId={uploadImage.imageId}
              handleChangeDefaultImage={allFunction.handleChangeDefaultImage}
            />
          </Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column width={16} textAlign="right">
            <Button className="blue-btn"
              onClick={this.backStep}>Back</Button>
            {/* {fullState.updateBundleGeneral ? <Button className="orange-btn" onClick={()=>changeEditNext(2)}>Continue</Button> : */}
            <Button className="orange-btn"
              loading={api.isApiLoading}
              onClick={this.changeNext}>Continue</Button>
          </Grid.Column>
        </Grid>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UploadSeasonPassPhoto);