import React, { Component } from "react";
import {Modal } from "semantic-ui-react";
import {bindActionCreators, connect, actions } from "../../../../functional/global-import";
import MapContainer from './map-container';

class MapModal extends Component {

  onCloseModal = () => {
    this.props.closeModal();

  }
    render() {
        return (
            <Modal open={this.props.openModal} closeIcon onClose={this.props.closeModal} >
                <MapContainer />
            </Modal>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            logout: bindActionCreators(actions.logout, dispatch),
        }
    };
};

export default connect(null, mapDispatchToProps)(MapModal);