import React, { Component } from "react";
import { Modal } from "semantic-ui-react";
// import { Button, Divider, Form, Grid, Modal, Container, Header, Table, TextArea, Accordion } from "semantic-ui-react";
import { connect, bindActionCreators, actions, Notifications,  } from "../../../../functional/global-import";
import ClientPersonalDetail from "../../../../../pages/client-summary/client-summary";

class User extends Component {
    get initialState() {
        return {

        };
    }
    constructor(props) {
        super(props);
        this.state = {
            ...this.initialState,
            user: [],
        };
    }


    componentDidMount = () => {

    }
    render() {

        return (
            <Modal open={this.props.openModal} closeIcon onClose={this.props.closeModal}  size={"small"}>
                <Modal.Header>User Information</Modal.Header>
                <Modal.Content>
                    <ClientPersonalDetail editUser={false} billingInfo={this.props.billingInfo} userNotes={this.props.userNotes} />
                </Modal.Content>
            </Modal>
        );
    }
}
const mapStateToProps = state => {
    return {
        api: state.api,
        global: state.global,
        auth: state.auth
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            apiCall: bindActionCreators(actions.apiCall, dispatch),
            storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
            showNotification: bindActionCreators(Notifications.show, dispatch)
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(User);