import React, { Fragment } from 'react';
import { Link } from "react-router-dom";
import { Image, List } from "semantic-ui-react";
import PropTypes from 'prop-types';

const ActiveBox = ({ imageview, activityadd, cancelBooking, texttime, onHandleActivity,index }) => {

    return (
        <Fragment>
            <List relaxed>
                <List.Item>
                    <Image avatar src={imageview} />
                    <List.Content>
                        <List.Header>
                            {activityadd}
                         </List.Header>
                        <List.Description>{texttime}</List.Description>
                    </List.Content>
                </List.Item>
                <List.Item>
                </List.Item>
            </List>
        </Fragment>
    );

};
ActiveBox.propTypes = {
    imageview: PropTypes.string.isRequired,
    activityadd: PropTypes.string.isRequired,
    cancelBooking: PropTypes.string.isRequired,
    texttime: PropTypes.string.isRequired,
};
export default ActiveBox;