import { Component } from "react";
import { Modal } from "semantic-ui-react";
import {
    Table,
    Checkbox,
    Grid,
    Icon,
    Button,
    Dimmer,
    Loader,
} from "semantic-ui-react";
import { Link} from "react-router-dom";
import Moment from "react-moment";
import {
    connect,
    bindActionCreators,
    actions,
    commonFunctions,
    Notifications,
} from "../../../../functional/global-import";

class AddSelectedGuestsModal extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { selectedGuests, api } = this.props
        const checkedGuestDetails = selectedGuests.filter((item) => item.isSelect)
        return (
            <Modal open={this.props.openModal} closeIcon onClose={this.props.closeModal} size={'medium'}>
                <Modal.Content>
                    {api.isApiLoading && (
                        <Dimmer active inverted>
                            <Loader />
                        </Dimmer>)}
                    <Grid>
                        <Grid.Column width={16}>
                            <div className="common-table orderSummeryList">
                                <Table
                                    singleLine
                                    id="itemsGrid"
                                    className="table-scrolling season-pass-table commonTableDropdown"
                                >
                                    <Table.Header className="tableHeader">
                                        <Table.Row>
                                            <Table.HeaderCell className="custom-Table"> Select</Table.HeaderCell>
                                            <Table.HeaderCell className="custom-Table"> Minor </Table.HeaderCell>
                                            <Table.HeaderCell> Name </Table.HeaderCell>
                                            <Table.HeaderCell> Phone No. </Table.HeaderCell>
                                            <Table.HeaderCell> Dob </Table.HeaderCell>
                                            <Table.HeaderCell> Flag Profile </Table.HeaderCell>
                                            <Table.HeaderCell> Email </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body >
                                        {selectedGuests?.map((selectedGuests, index) => {
                                            
                                            return (
                                                <Table.Row key={index}>
                                                    <Table.Cell className="custom-Table">
                                                        <Checkbox
                                                            className="custome-checkbox"
                                                            checked={selectedGuests.isSelect}
                                                            onChange={() => this.props.onCheckGuestFromModal(index)}
                                                        >
                                                        </Checkbox>
                                                    </Table.Cell>
                                                    <Table.Cell className="custom-Table">
                                                        {selectedGuests.minor === true ? (
                                                            <Checkbox
                                                                className="custome-checkbox "
                                                                data="Minor"
                                                                checked={selectedGuests.minor}
                                                            />
                                                        ) : (
                                                            ""
                                                        )}
                                                    </Table.Cell>
                                                    <Table.Cell >
                                                        <Link title={selectedGuests.fullName}
                                                            className="orange-color textOverflowHidden"
                                                            to={`${process.env.REACT_APP_PUBLIC_URL}/customer-summary/${selectedGuests.customerId}`}
                                                            target="_blank"> {selectedGuests.fullName}
                                                        </Link>

                                                    </Table.Cell>
                                                    <Table.Cell >
                                                        {selectedGuests.phoneNumber}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {selectedGuests.dob !== null ? (
                                                            <Moment format="MM/DD/YYYY">
                                                                {selectedGuests?.dob}
                                                            </Moment>
                                                        ) : (
                                                            "-"
                                                        )}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {selectedGuests?.flagProfile ? (
                                                            <Icon
                                                                size="big"
                                                                name={selectedGuests.flagProfile ? "flag" : "flag outline"}
                                                                style={
                                                                    selectedGuests.flagProfile
                                                                        ? { color: "red" }
                                                                        : { color: "" }
                                                                }
                                                            />
                                                        ) : (
                                                            <Icon size="big" name={"flag outline"} />
                                                        )}
                                                    </Table.Cell>
                                                    <Table.Cell className="orange-color">
                                                        {selectedGuests.emailId}
                                                    </Table.Cell>

                                                </Table.Row>
                                            );
                                        })}

                                    </Table.Body>
                                </Table>
                            </div>
                            <p>&nbsp;</p>
                            <Grid.Column width={8} className='button-flot'>
                                <Button
                                    className="blue-btn"
                                    onClick={this.props.closeModal}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    disabled={checkedGuestDetails.length > 0 ? false : true}
                                    className="orange-btn"

                                    onClick={() => this.props.onCheckedSaveGuests()}
                                >
                                    Continue
                                </Button>
                            </Grid.Column>
                        </Grid.Column>
                    </Grid>
                </Modal.Content>
            </Modal >
        );
    }
}
const mapStateToProps = (state) => {
    return {
        api: state.api,
        global: state.global,
        auth: state.auth,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            apiCall: bindActionCreators(actions.apiCall, dispatch),
            storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
            showNotification: bindActionCreators(Notifications.show, dispatch),
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddSelectedGuestsModal);
