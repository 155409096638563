import React, { Component } from "react";
import {
  connect,
  bindActionCreators,
  actions,
  Notifications,
} from "../../../../../functional/global-import";
import BusinessByType from "../../../../../../pages/inventory/business-by-type";
import RentalDate from "../inventory-type/rental/rental-date";
import CategoryView from "../../../../../../pages/inventory/inventory-rental/rental-category-view/category-view";
import { addSelectedDateRange } from "../../../../../../store/actions/order.actions";
import Rental from "../inventory-type/rental/index";
import SeasonPass from "../inventory-type/season";
import Activity from "./activity/activity";
import Retail from "./retail/retail";
import Bundle from "./bundle/index";
import moment from "moment";

class OrderInventoryType extends Component {
  state = {
    // hideRentalDate: false,
    // showItemGrid: false,
    dateTime: { startDate: "", endDate: "" },
    hideAddCategory: true,
    productCategoryDetail: "",
    showChooseDates: false,
    startTime: "8:00am",
    endTime: "9:00am",
    currentDay: "",
    bookingSlot: {
      startTimeHour: "",
      startTimeMinute: "",
      startTimeAm: "",
      endTimeHour: "",
      endTimeMinute: "",
      endTimeAm: "",
    },
    currentDateFromApi: undefined,
  };

  componentDidMount = () => {
    this.onGetCurrentDate();
  };
  getMinutes = (number) => {
    if (number % 5 === 0) {
      return ("0" + number).slice(-2);
    } else {
      let numberValue = number - (number % 5);
      return ("0" + numberValue).slice(-2);
    }
  };
  onGetCurrentDate = () => {
    this.props.actions.apiCall({
      urls: ["CURRENTDATEFORBUSINESS"],
      method: "GET",
      data: {
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
      },
      onSuccess: (response) => {
        // Current-hour//
        var hours = new Date().getHours();
        hours = hours % 12 || 12;
        let curHour = ("0" + hours).slice(-2);
        // extended-hour//
        var extenndHours = new Date().getHours() + 1;
        extenndHours = extenndHours % 12 || 12;
        let curHours = ("0" + extenndHours).slice(-2);
        // mintues//
        var mintues = new Date().getMinutes();
        let mintuesGet = this.getMinutes(mintues);
        let getFinalMintues = mintuesGet.toString();
        // mintues//
        var mintues = new Date().getMinutes() + 1;
        let mintuesExtended = this.getMinutes(mintues);
        let getExtendedMintues = mintuesExtended.toString();
        let am = new Date().getHours() >= 12 ? "pm" : "am";
        let pm = new Date().getHours() + 1 >= 12 ? "pm" : "am";
        var today = new Date();
        var day = String(today.getDate()).padStart(2, "0");
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = today.getFullYear();
        today = mm + "-" + dd + "-" + yyyy;
        // this.setState({ currentDay: today });
        let aa =
          response &&
          response[0] &&
          response[0].startTime &&
          new Date(response[0].startTime);
        // this.setState({ currentDateFromApi: aa });

        this.setState(
          {
            bookingSlot: {
              ...this.state.bookingSlot,
              startTimeHour: curHour,
              startTimeAm: am,
              startTimeMinute: getFinalMintues,
              endTimeHour: curHours,
              endTimeMinute: getExtendedMintues,
              endTimeAm: pm,
            },
            currentDay: today,
            currentDateFromApi: aa,
          },
          () => this.onSaveStartEndTime()
        );
      },
    });
  };

  onTimeHandleChange = (e, { value, name }) => {
    this.setState(
      { bookingSlot: { ...this.state.bookingSlot, [name]: value } },
      () => this.onSaveStartEndTime()
    );
  };
  onSaveStartEndTime = () => {
    const { bookingSlot } = this.state;

    const startTime =
      bookingSlot.startTimeHour +
      ":" +
      bookingSlot.startTimeMinute +
      bookingSlot.startTimeAm;
    const endTime =
      bookingSlot.endTimeHour +
      ":" +
      bookingSlot.endTimeMinute +
      bookingSlot.endTimeAm;
    this.setState({ startTime, endTime });
  };

  showRentalCategory = (dateTime) => {
    let date = dateTime.split(" - ");

    this.props.allFunction.setState({ hideRentalDate: true });
    this.setState(
      {
        dateTime: {
          ...this.state.dateTime,
          startDate: date[0],
          endDate: date[1] ? date[1] : date[0],
        },
      },
      () => this.props.actions.addSelectedDateRange(this.state.dateTime)
    );
  };

  // onShowRentalDate = () => {
  //
  //     this.setState({ hideRentalDate: false, showItemGrid: false }, () => this.props.handleSelectedBusinessCategoryEmpty())
  // }

  // backToInventory = () => {
  //     this.setState({ showItemGrid: false })
  //     this.props.allFunction.handleSelectedBusinessCategoryEmpty()
  //     this.props.actions.storeInventoryDetail({})
  // };

  handleShowGrid = (productCategoryDetail) => {
    this.setState({ productCategoryDetail: productCategoryDetail });

    this.props.allFunction.setState({
      showItemGrid: true,
      hideRentalDate: false,
    });
    let data = {
      itemId: productCategoryDetail.productId,
      itemName: productCategoryDetail.name,
    };
    if (productCategoryDetail.retailTypeId) {
      data["retailTypeId"] = productCategoryDetail.retailTypeId;
    }

    this.props.actions.addSelectedItems(data);
    if (productCategoryDetail.businessCategoryType === "Bundle") {
      this.props.actions.addSelectedItems([productCategoryDetail]);
    }
  };

  backToInventoryView = () => {
    this.props.allFunction.setState({
      hideRentalDate: true,
      showItemGrid: false,
    });
  };
  backBtnInventoryView = () => {
    let inventoryDetail = this.props.global.inventoryDetail;
    const objectBreadcrumb = inventoryDetail.inventoryBreadcrumb.filter(
      (bredCrumbObj) => {
        return (
          bredCrumbObj.productId === this.state.productCategoryDetail.productId
        );
      }
    );
    inventoryDetail.categoryId = objectBreadcrumb[0].categoryId;
    inventoryDetail.parentId = objectBreadcrumb[0].parentId;
    inventoryDetail.productId = 0;
    this.props.actions.storeInventoryDetail(inventoryDetail);
    this.backToInventoryView();
  };
  render() {
    const {
      selectedBusinessCategory,
      allFunction,
      rentalEdit,
      customQuestionEdit,
    } = this.props;
    const {
      dateTime,
      productCategoryDetail,
      startTime,
      endTime,
      bookingSlot,
      currentDateFromApi,
      currentDay,
    } = this.state;

    const parts = productCategoryDetail &&  productCategoryDetail?.availabilityStartDate?.split("T");
      let initialMonthDate;
      if (productCategoryDetail?.availabilityStartDate) {
       const [year, month] = parts[0].split("-");
       initialMonthDate = `${year}-${month}-01`;
      } else {
     initialMonthDate = currentDateFromApi;
           }


    if (selectedBusinessCategory.businessCategoryName) {
      if (
        selectedBusinessCategory.businessCategoryName === "Rental" &&
        !allFunction.state.hideRentalDate &&
        !allFunction.state.showItemGrid
      ) {
        return (
          <RentalDate
            currentDay={currentDay}
            currentDateFromApi={currentDateFromApi}
            onTimeHandleChange={this.onTimeHandleChange}
            bookingSlot={bookingSlot}
            orderInventoryStateRef={this}
            onContinueClick={this.showRentalCategory}
            onBackBtnClick={allFunction.handleSelectedBusinessCategoryEmpty}
          />
        );
      } else if (allFunction.state.showItemGrid) {
        if (selectedBusinessCategory.businessCategoryName === "Activity") {
          return (
            <Activity
              bookingSlot={bookingSlot}
              currentDateFromApi={currentDateFromApi}
              allFunction={allFunction}
              activityEdit={allFunction.state.activityEdit}
              onAddMoreActivity={
                allFunction.handleSelectedBusinessCategoryEmpty
              }
              backBtnInventoryView={this.backBtnInventoryView}
              productCategoryDetail={this.state.productCategoryDetail}
              customQuestionEdit={customQuestionEdit}
              initialMonthDate={initialMonthDate}
              initialMonth={productCategoryDetail?.availabilityStartDate}
              //  backToInventory={this.backToInventory}
              next={this.props.next}
            />
          );
        } else if (selectedBusinessCategory.businessCategoryName === "Rental") {
          return (
            <Rental
              currentDateFromApi={currentDateFromApi}
              bookingSlot={bookingSlot}
              startTime={startTime}
              endTime={endTime}
              rentalDatesSelected={dateTime}
              allFunction={allFunction}
              rentalEdit={rentalEdit}
              customQuestionEdit={customQuestionEdit}
              onShowRentalDate={allFunction.handleSelectedBusinessCategoryEmpty}
              // onAddmoreItems={allFunction.handleSelectedBusinessCategoryEmpty}
              backToInventory={this.backToInventoryView}
              backBtnInventoryView={this.backBtnInventoryView}
              productCategoryDetail={this.state.productCategoryDetail}
              initialMonthDate={initialMonthDate}
              next={this.props.next}
              initialMonth={productCategoryDetail?.availabilityStartDate}

            />
          );
        } else if (selectedBusinessCategory.businessCategoryName === "Retail") {
          return (
            <Retail
              allFunction={allFunction}
              retailEdit={allFunction.state.retailEdit}
              onAddmoreBtnClick={
                allFunction.handleSelectedBusinessCategoryEmpty
              }
              backBtnInventoryView={this.backBtnInventoryView}
              categoryViewBackRetail={this.backToInventoryView}
              customQuestionEdit={customQuestionEdit}
              productCategoryDetail={productCategoryDetail}
              next={this.props.next}
            />
          );
        } else if (selectedBusinessCategory.businessCategoryName === "Bundle") {
          return (
            <Bundle
              currentDateFromApi={currentDateFromApi}
              bundleEdit={allFunction.state.bundleEdit}
              allFunction={allFunction}
              onAddmoreBtnClick={
                allFunction.handleSelectedBusinessCategoryEmpty
              }
              backBtnInventoryView={this.backBtnInventoryView}
              productCategoryDetail={productCategoryDetail}
              next={this.props.next}
            />
          );
        } else if (
          selectedBusinessCategory.businessCategoryName === "Season Pass"
        ) {
          return (
            <SeasonPass
              seasonPassEdit={allFunction.state.seasonPassEdit}
              allFunction={allFunction}
              productCategoryDetail={productCategoryDetail}
              next={this.props.next}
              backBtnInventoryView={this.backBtnInventoryView}
              onAddmoreBtnClick={
                allFunction.handleSelectedBusinessCategoryEmpty
              }
              categoryViewBack={this.backToInventoryView}
              customQuestionEdit={customQuestionEdit}
            />
          );
        }
      } else {
        return (
          <CategoryView
            orderInventoryStateRef={this}
            startTime={startTime}
            endTime={endTime}
            onBytypeClick={allFunction.handleSelectedBusinessCategoryEmpty}
            hideAddCategory={true}
            startDate={dateTime.startDate}
            endDate={dateTime.endDate}
            inventoryDetail={this.props.selectedBusinessCategory}
            handleShowGrid={this.handleShowGrid}
          />
        );
      }
    } else {
      return (
        <BusinessByType
          handleBusinessByTypeClick={this.props.handleBusinessByTypeClick}
          newOrder={false}
        />
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
    orderState: state.orderState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      addSelectedDateRange: bindActionCreators(addSelectedDateRange, dispatch),
      storeInventoryDetail: bindActionCreators(
        actions.storeInventoryDetail,
        dispatch
      ),
      addSelectedItems: bindActionCreators(actions.addSelectedItems, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderInventoryType);
