import { Component } from 'react';
import HelpCenterForm from './help-center-form';

class HelpCenterSetting extends Component {
  render() {
    return (
      <>
        <HelpCenterForm showGrid={true} />
      </>
    );
  }
}
export default HelpCenterSetting;
