import React, { Component, Fragment } from "react";
import {
  connect,
  bindActionCreators,
  actions,
  env,
  Notifications,
  commonFunctions,
} from "../../../../../../functional/global-import";
import {
  Table,
  Icon,
  Grid,
  Button,
  Input,
  Form,
  Checkbox,
  Header,
  Image,
  Loader,
  Dimmer,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import AddCommentModal from "../../../add-comment-modal";
import InventoryBreadcrumb from "../../../../../../../pages/inventory/breadcrumb";
import { RetaiInfoModal } from "../../../../../../components";
import { orderCommonFunctions } from "../../order-common-function";

class RetailSelectItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxItemPerOrder: null,
      minItemPerOrder: null,
      commentStatus: false,
      count: 0,
      productsList: [],
      selectedProducts: [],
      note: {
        noteRefrenceId: "",
        noteType: "",
      },
      columnNames: null,
      productCategoryDetail: true,
      loading: false,
    };
  }

  openNotesModal = (data, item) => {
    const { note } = this.state;
    this.setState({ commentStatus: !this.state.commentStatus });
    if (data === "ItemDetail") {
      note.noteRefrenceId = item.productdetailid;
      note.noteType = data;
      this.setState({ note });
    }
  };
  retaiInfoModalOpen = () => {
    this.setState({ retailInfoStatus: !this.state.retailInfoStatus });
  };

  countHandleChange = () => {
    const { count } = this.state;
    this.setState({ count: count + 1 });
    this.props.actions.addSelectedItems(this.state.productsList);
  };

  countHandleChangeMinus = () => {
    const { count } = this.state;
    if (count >= 1) {
      this.setState({ count: count - 1 });
    }
    this.props.actions.addSelectedItems(this.state.productsList);
  };

  getProductList = () => {
    this.setState({ loading: true });
    this.props.actions.apiCall({
      urls: ["GETPRODUCTDETAILLIST"],
      method: "GET",
      data: {
        ProductId: this.props.retailEdit.retailEdit
          ? this.props.retailEdit.retailItem.RetailId
          : this.props.global.inventoryDetail.productId,
        isActive: true,
        StartDate: "",
        EndDate: "",
        BusinessCategoryType: this.props.retailEdit.retailEdit
          ? this.props.retailEdit.retailItem.BusinessCategoryType
          : this.props.global.inventoryDetail.businessCategoryId,
        BusinessId: this.props.global.bussinessDetail.businessId,
        BusinessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        PageNo: 1,
        PageSize: 100,
      },
      onSuccess: (response) => {
        let detailsoFObj = {};
        detailsoFObj = JSON.parse(response[0].data).length > 0 && {
          type: JSON.parse(response[0].data)[0].RetailTypeName,
          name: JSON.parse(response[0].data)[0].productname,
          image: JSON.parse(response[0].data)[0].Image,
        };
        const { retailEdit } = this.props;

        if (retailEdit.retailEdit) {
          if (retailEdit.retailItem.RetailItemDetails) {
            const alreadySelected = [];
            const demoalreadySelected = JSON.parse(response[0].data).filter(
              (singleitem) => {
                JSON.parse(retailEdit.retailItem.RetailItemDetails).map(
                  (editRetailObj) => {
                    if (
                      singleitem.productdetailid ===
                      editRetailObj.ProductDetailId
                    ) {
                      alreadySelected.push(singleitem);
                    }
                    return false;
                  }
                );
                return false;
              }
            );
            this.setState({ selectedProducts: alreadySelected });
          } else {
            this.setState({ count: retailEdit.retailItem.Quantity });
          }
        }

        this.setState({
          productCategoryDetail: detailsoFObj,
          columnNames: JSON.parse(response[0].columnNames),
          productsList: JSON.parse(response[0].data),
          minItemPerOrder: response && response[0]?.minItemPerOrder,
          maxItemPerOrder: response && response[0]?.maxItemPerOrder,
        });
        return response;
      },
      onFinally: () => {
        this.setState({ loading: false });
      },
    });
  };

  componentDidMount() {
    this.getProductList();
  }
  handleChange = (e, { value, checked, name }, selectedProduct) => {
    const { selectedProducts } = this.state;

    let data = selectedProducts;
    if (checked) {
      if (name === "CheckAll") {
        data = selectedProduct;
      } else {
        data.push(selectedProduct);
      }
      this.setState({ selectedProducts: data });
    } else {
      if (name === "CheckAll") {
        data = [];
      } else {
        data = selectedProducts.filter(
          (item) => item.productdetailid !== selectedProduct.productdetailid
        );
      }
    }
    this.setState({ selectedProducts: data });
    this.props.actions.addSelectedItems(data);
  };

  onChangeHandler = (e, { value }) => {
    const { productsList, selectedProducts } = this.state;
    let dataObject = selectedProducts;
    if (parseInt(value) > 0) {
      dataObject = productsList.slice(0, parseInt(value));
      this.setState({ selectedProducts: dataObject });
      this.props.actions.addSelectedItems(dataObject);
    } else {
      this.setState({ selectedProducts: [] });
      this.props.actions.addSelectedItems([]);
    }
  };

  onCancelBtnCLick = () => {
    this.props.next(3);
    this.props.allFunction.handleSelectedBusinessCategoryEmpty();
  };

  onUpdateRetailDetail = (selectedProducts) => {
    const { orderState, retailEdit, retailStateRef } = this.props;
    const { minItemPerOrder, maxItemPerOrder, count } = this.state;
    const itemCount =
      retailEdit.retailItem.RetailTypeName === "Retail-Trackable"
        ? selectedProducts?.length
        : count;
    const isAbleToBook = orderCommonFunctions.onValidateMinMaxPerItem(
      itemCount,
      minItemPerOrder,
      maxItemPerOrder
    );
    if (isAbleToBook) {
      this.props.actions.apiCall({
        urls: ["RETAILUPDATEORDERDETAIL"],
        method: "PUT",
        data: {
          orderId: orderState.currentOrder.orderId,
          retailType: retailEdit.retailItem.RetailType,
          itemQuantity:
            retailEdit.retailItem.RetailTypeName === "Retail-Trackable"
              ? null
              : this.state.count,
          orderDetailId: retailEdit.retailItem.OrderDetailId,
          productId: retailEdit.retailItem.RetailId,
          productDetailId:
            retailEdit.retailItem.RetailTypeName === "Retail-Trackable"
              ? selectedProducts.map((item) => item.productdetailid)
              : null,
          actionPerformedBy: this.props.auth.userDetail.emailId,
        },
        onSuccess: (response) => {
          this.props.next(3);
          this.props.allFunction.handleSelectedBusinessCategoryEmpty();
          retailStateRef.props.actions.getOrderItems(
            orderState.currentOrder.orderId
          );
          return response;
        },
        showNotification: true,
      });
    } else {
      this.props.actions.showNotification(
        {
          title: "Warning",
          message: `Please select minimum ${minItemPerOrder} or maximum ${maxItemPerOrder} item per-order`,
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    }
  };

  render() {
    const {
      productCategoryDetail,
      commentStatus,
      retailInfoStatus,
      productsList,
      selectedProducts,
      note,
      count,
      columnNames,
      loading,
    } = this.state;
    const {
      retailDescription,
      isToShowAddon,
      hasToShowCancellationComponent,
      retailEdit,
      productCategoryDetailsFromParent,
      api,
    } = this.props;

    return (
      <>
        <Fragment>
          {!retailEdit.retailEdit && (
            <Grid>
              <Grid.Column width={16}>
                <InventoryBreadcrumb
                  newOrderBack={this.props.categoryViewBackRetail}
                  onShowRentalDate={this.props.onAddmoreBtnClick}
                />
              </Grid.Column>
            </Grid>
          )}

          {/* {productCategoryDetailsFromParent.type && productCategoryDetailsFromParent.type === "Retail-Bulk" && */}

          {productsList &&
            productsList.length > 0 &&
            productCategoryDetail?.type &&
            productCategoryDetail.type === "Retail-Bulk" && (
              <Grid>
                <Grid.Column width={16} textAlign="center">
                  <Header>{`${productsList[0]?.productname} count available is ${productsList[0]?.productquantity}`}</Header>
                </Grid.Column>
                <Grid.Column width={16}>
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell textAlign="center">
                          Name
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">
                          Select Quantity
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">
                          Price
                        </Table.HeaderCell>
                        {columnNames !== null
                          ? columnNames?.map((column) => {
                              return (
                                <Table.HeaderCell
                                  title={column.traitName}
                                  className="textOverflowHidden"
                                >
                                  {column.traitName}
                                </Table.HeaderCell>
                              );
                            })
                          : false}

                        <Table.HeaderCell textAlign="right">
                          Info
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {productsList?.length > 0 &&
                        productsList.map((retailObj) => {
                          return (
                            <Table.Row>
                              <Table.Cell textAlign="center">
                                <Link
                                  className="orange-color"
                                  title={retailObj.productname}
                                >
                                  {retailObj.productname}
                                </Link>
                              </Table.Cell>
                              <Table.Cell>
                                <Form.Field className="add-numbers">
                                  <Icon
                                    name="minus"
                                    onClick={this.countHandleChangeMinus}
                                    className="orange-button"
                                  />
                                  <Input value={count} fluid />
                                  <Icon
                                    name="plus"
                                    className="orange-button plus-btn"
                                    onClick={this.countHandleChange}
                                  />
                                </Form.Field>
                              </Table.Cell>
                              <Table.Cell textAlign="right">
                                <Link
                                  className="orange-color"
                                  title={commonFunctions.isNumberInteger(
                                    retailObj.price
                                  )}
                                >
                                  {commonFunctions.isNumberInteger(
                                    retailObj.price
                                  )}
                                </Link>
                              </Table.Cell>
                              {columnNames &&
                                columnNames.map((column) => {
                                  return (
                                    <Table.Cell
                                      title={
                                        retailObj[
                                          column.traitName.toLowerCase()
                                        ]
                                      }
                                    >
                                      {
                                        retailObj[
                                          column.traitName.toLowerCase()
                                        ]
                                      }
                                    </Table.Cell>
                                  );
                                })}

                              <Table.Cell textAlign="right">
                                <Link onClick={this.retaiInfoModalOpen}>
                                  <span
                                    class="iconify orange-color"
                                    data-icon="fa-solid:info-circle"
                                    data-inline="false"
                                    data-width="18px"
                                    data-height="18px"
                                  />
                                </Link>
                                {/* {retailDescription !== null &&
                            }
                            &nbsp;
                            <Link onClick={this.openNotesModal}>
                              <span class="iconify maroon-color" data-icon="mdi:comment-plus" data-inline="false" data-flip="horizontal" data-width="18px" data-height="18px" />
                            </Link> */}
                              </Table.Cell>
                            </Table.Row>
                          );
                        })}
                      <Table.Row></Table.Row>
                    </Table.Body>
                  </Table>
                </Grid.Column>

                {!retailEdit.retailEdit ? (
                  <Grid.Column width={16} textAlign="right">
                    <Button
                      className="blue-btn"
                      onClick={this.props.backBtnInventoryView}
                    >
                      Back
                    </Button>
                    {!hasToShowCancellationComponent && !isToShowAddon && (
                      <Button
                        className="blue-btn"
                        disabled={count > 0 ? false : true}
                        onClick={() => this.props.bulkOnContinue(true, count)}
                      >
                        Add more items
                      </Button>
                    )}
                    <Button
                      className="orange-btn"
                      disabled={count > 0 ? false : true}
                      onClick={() => this.props.bulkOnContinue(false, count)}
                    >
                      Continue
                    </Button>
                  </Grid.Column>
                ) : (
                  <Grid.Column width={16} textAlign="right">
                    <Button
                      className="blue-btn"
                      onClick={this.onCancelBtnCLick}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="orange-btn"
                      disabled={count > 0 ? false : true}
                      onClick={() => this.onUpdateRetailDetail(null)}
                    >
                      Updates
                    </Button>
                  </Grid.Column>
                )}
              </Grid>
            )}

          {/* {productCategoryDetailsFromParent.type && productCategoryDetailsFromParent.type === "Retail-Trackable" && */}

          {productsList &&
            productsList.length > 0 &&
            productCategoryDetail?.type &&
            productCategoryDetail.type === "Retail-Trackable" && (
              <Grid>
                <Grid.Column width={16}>
                  <Table basic="very" className="borderlessTable">
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>
                          <Image
                            src={
                              productCategoryDetail?.image === undefined
                                ? false
                                : commonFunctions.concatenateImageWithAPIUrl(
                                    productCategoryDetail?.image
                                  )
                            }
                            size="tiny"
                            bordered
                          />
                        </Table.Cell>
                        <Table.Cell
                          title={productCategoryDetail?.name}
                          className="textOverflowHidden"
                        >
                          <Header as="h3">
                            {productCategoryDetail?.name &&
                              productCategoryDetail?.name}
                          </Header>
                        </Table.Cell>
                        <Table.Cell>
                          <Header as="h3">
                            {this.state.productsList.length -
                              this.state.selectedProducts.length}
                          </Header>
                        </Table.Cell>
                        <Table.Cell>
                          <Header as="h3" className="deepviolet-color">
                            Inventory
                          </Header>
                        </Table.Cell>
                        <Table.Cell>
                          <Input
                            value={this.state.selectedProducts.length}
                            onChange={this.onChangeHandler}
                            maxLength={3}
                            className="selecteed"
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <Header as="h3" className="deepviolet-color">
                            Selected
                          </Header>
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Grid.Column>

                <Grid.Column width={16}>
                  <div className="analysticTable">
                    <Table
                      className="table-scrolling commonTableDropdown"
                      id="AnalyticsGrid"
                      singleLine
                    >
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>
                            <Checkbox
                              className="custome-checkbox"
                              name="CheckAll"
                              checked={
                                productsList.length === selectedProducts.length
                                  ? true
                                  : false
                              }
                              onChange={(e, { value, checked, name }) =>
                                this.handleChange(
                                  e,
                                  { value, checked, name },
                                  productsList
                                )
                              }
                            />
                          </Table.HeaderCell>
                          <Table.HeaderCell> Name </Table.HeaderCell>
                          <Table.HeaderCell> ID </Table.HeaderCell>
                          <Table.HeaderCell> Price </Table.HeaderCell>

                          {columnNames !== null
                            ? columnNames.map((column) => {
                                return (
                                  <Table.HeaderCell>
                                    {column.traitName}
                                  </Table.HeaderCell>
                                );
                              })
                            : false}

                          <Table.HeaderCell textAlign="right">
                            Info
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      {loading && (
                        <Dimmer active inverted>
                          <Loader size="small"></Loader>
                        </Dimmer>
                      )}
                      <Table.Body
                        id={`${"scrollable" + "AnalyticsGrid"}`}
                        style={{ maxHeight: "300px" }}
                      >
                        {productsList.length > 0 &&
                          productsList.map((item) => {
                            return (
                              <Table.Row>
                                <Table.Cell>
                                  <Checkbox
                                    className="custome-checkbox"
                                    checked={
                                      selectedProducts.filter(
                                        (selectedProduct) =>
                                          selectedProduct.productdetailid ===
                                          item.productdetailid
                                      ).length > 0
                                    }
                                    onChange={(e, { value, checked, name }) =>
                                      this.handleChange(
                                        e,
                                        { value, checked, name },
                                        item
                                      )
                                    }
                                  />
                                </Table.Cell>
                                <Table.Cell className="textOverflowHidden">
                                  <Link
                                    title={item.productname}
                                    className="orange-color"
                                  >
                                    {item.productname}
                                  </Link>
                                </Table.Cell>

                                <Table.Cell>
                                  <Link
                                    className="orange-color"
                                    to={`${env.PUBLIC_URL}/item-detail/${item.productdetailid} ${item.productid} ${item.businessid} ${item.businesslocationid}`}
                                    target="_blank"
                                  >
                                    {item.id}
                                  </Link>
                                </Table.Cell>

                                <Table.Cell>
                                  <Link className="orange-color">
                                    {commonFunctions.isNumberInteger(
                                      item.price
                                    )}
                                  </Link>
                                </Table.Cell>

                                {columnNames &&
                                  columnNames.map((column) => {
                                    return (
                                      <Table.Cell
                                        title={
                                          item[column.traitName.toLowerCase()]
                                        }
                                        className="textOverflowHidden"
                                      >
                                        {item[column.traitName.toLowerCase()]}
                                      </Table.Cell>
                                    );
                                  })}

                                <Table.Cell textAlign="right">
                                  <Link
                                    onClick={this.retaiInfoModalOpen}
                                    title=""
                                  >
                                    <span
                                      class="iconify orange-color"
                                      data-icon="fa-solid:info-circle"
                                      data-inline="false"
                                      data-width="18px"
                                      data-height="18px"
                                    />
                                  </Link>

                                  {/* <Link onClick={this.RetaiInfoModal}>
                                <span class="iconify orange-color" data-icon="fa-solid:info-circle" data-inline="false" data-width="18px" data-height="18px" />
                              </Link> &nbsp;
                              <Link onClick={() => this.openNotesModal("ItemDetail", item)}>
                                <span class="iconify maroon-color" data-icon="mdi:comment-plus" data-inline="false" data-flip="horizontal" data-width="18px" data-height="18px" />
                              </Link> */}
                                </Table.Cell>
                              </Table.Row>
                            );
                          })}
                      </Table.Body>
                    </Table>
                  </div>
                </Grid.Column>

                {!retailEdit.retailEdit ? (
                  <Grid.Column width={16} textAlign="right">
                    <Button
                      className="blue-btn"
                      onClick={this.props.backBtnInventoryView}
                    >
                      Back
                    </Button>

                    {!hasToShowCancellationComponent && !isToShowAddon && (
                      <Button
                        className="blue-btn"
                        disabled={selectedProducts.length === 0 ? true : false}
                        onClick={() => this.props.moveToNextStep(true)}
                      >
                        Add more items
                      </Button>
                    )}
                    <Button
                      className="orange-btn"
                      disabled={selectedProducts.length === 0 ? true : false}
                      onClick={() => this.props.moveToNextStep(false)}
                    >
                      Continue
                    </Button>
                  </Grid.Column>
                ) : (
                  <Grid.Column width={16} textAlign="right">
                    <Button
                      className="blue-btn"
                      onClick={this.onCancelBtnCLick}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="orange-btn"
                      disabled={selectedProducts.length === 0 ? true : false}
                      onClick={() =>
                        this.onUpdateRetailDetail(selectedProducts)
                      }
                    >
                      Update
                    </Button>
                  </Grid.Column>
                )}
              </Grid>
            )}

          {!productCategoryDetail && (
            <>
              <Grid>
                <Grid.Column width={16} textAlign="center">
                  <Header as="h2">Items Out Of Stock </Header>
                </Grid.Column>
                <Grid.Column width={16} textAlign="center">
                  <Header as="h2">!</Header>
                </Grid.Column>
              </Grid>
              <Grid>
                <Grid.Column width={16} textAlign="right">
                  <Button
                    className="blue-btn"
                    onClick={this.props.backBtnInventoryView}
                  >
                    Back
                  </Button>
                </Grid.Column>
              </Grid>
            </>
          )}
          <AddCommentModal
            openModal={commentStatus}
            noteType={note.noteType}
            noteRefrenceId={note.noteRefrenceId}
            closeModal={this.openNotesModal}
          />
          {retailInfoStatus && (
            <RetaiInfoModal
              retailDescription={retailDescription}
              openModal={retailInfoStatus}
              closeModal={this.retaiInfoModalOpen}
            />
          )}
        </Fragment>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
    orderState: state.orderState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      addSelectedItems: bindActionCreators(actions.addSelectedItems, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RetailSelectItems);
