import React, { Component } from "react";
import { Table, Icon, Checkbox, Dropdown, Button } from "semantic-ui-react";

class TableHeader extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      sortingArrow,
      columns,
      onHandleSorting,
      sortColumn,
      hideColumns,
      dropdownCheck,
      columnsList,
      allFunction,
    } = this.props;

    return (
      <Table.Header>
        <Table.Row>
          {columns.map((singleData, index) => {
            const columnHiding = hideColumns[index];
            return (
              <>
                {columnHiding && columnHiding.CD === 1 ? (
                  <Table.HeaderCell
                    className={
                      singleData.isSorting
                        ? `pointer ${
                            singleData.headerClass !== undefined
                              ? singleData.headerClass
                              : ""
                          }`
                        : `${
                            singleData.headerClass !== undefined
                              ? singleData.headerClass
                              : ""
                          }`
                    }
                    onClick={() => {
                      singleData.isSorting &&
                        onHandleSorting(singleData.fieldName);
                    }}
                    key={index}
                  >
                    {singleData.fieldName === "Select All" ? (
                      <Checkbox
                        className="custome-checkbox"
                        label={singleData.headerName}
                        checked={allFunction.state.isSelectAll}
                        onChange={allFunction.onSelectAll}
                      />
                    ) : (
                      `${singleData.headerName} `
                    )}
                    {singleData.isSorting && (
                      <Icon
                        id={index}
                        name={
                          singleData.fieldName === sortColumn
                            ? sortingArrow
                            : "sort"
                        }
                        className="orange-color"
                      />
                    )}
                    {hideColumns.length - 1 === index && (
                      <Dropdown
                        item
                        simple
                        icon="filter"
                        className="orange-color existing-customer-dropdown"
                      >
                        <Dropdown.Menu className="left">
                          {columnsList.map((singleData, index) => {
                            // const dropDownSelect = dropdownCheck[index]
                            const aa = dropdownCheck[index];
                            return (
                              <Dropdown.Item
                                className={singleData.className}
                                disabled={singleData.SD === 1 ? true : false}
                              >
                                {" "}
                                <Checkbox
                                  label={singleData.FieldName}
                                  className="custome-checkbox"
                                  onClick={this.props.onChange}
                                  checked={aa?.CD === 1 ? true : false}
                                  data={singleData}
                                  key={index}
                                  index={index}
                                  defaultChecked={true}
                                />{" "}
                              </Dropdown.Item>
                            );
                          })}
                          <Dropdown.Item>
                            <Button
                              className="orange-button pointer"
                              size="mini"
                              onClick={this.props.onHandleHiddenColumn}
                            >
                              Done
                            </Button>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </Table.HeaderCell>
                ) : null}
              </>
            );
          })}
        </Table.Row>
      </Table.Header>
    );
  }
}

export default TableHeader;
