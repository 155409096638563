import React, { Component } from "react";
import { Table, Dimmer, Loader, Header } from "semantic-ui-react";
import InfiniteScroll from "react-infinite-scroll-component";

class TableRowWithOutApi extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { columns, data, emptyGrid, fetchMoreData, hasMore, gridName, gridDataLoading,hideColumns,tableHeight } = this.props; 
        return (
            <InfiniteScroll
                dataLength={data.length}
                scrollableTarget={`${"scrollable" + gridName}`}
                next={fetchMoreData}
                hasMore={hasMore}>
                    
                <Table.Body id={`${"scrollable" + gridName}`} style={tableHeight !== undefined ? { maxHeight: tableHeight } : { maxHeight: "200px" }}>
                    {gridDataLoading && <Dimmer active inverted> <Loader size='small'>Loading</Loader> </Dimmer>}
                    {data.length === 0 ? <Header as='h5' className="forCommonTable">{emptyGrid ?? "No record found"}</Header> : data.map((singleData, index) => {
                        return (
                            <Table.Row key={index}>
                                {columns.map((singleField, index) => {
                                    const fieldValue = singleField.fieldName;
                                    const columnHiding = hideColumns[index];
                                    return (
                                        <>
                                           {(columnHiding && columnHiding.CD === 1) ?
                                                <Table.Cell className={singleField.className}>
                                                    {singleField.Cell ? singleField.Cell(singleData) : singleData[fieldValue]}
                                                </Table.Cell>
                                                : null}
                                        </>
                                    );
                                })}
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </InfiniteScroll>
        );
    }
}

export default TableRowWithOutApi;

