import React, { Component } from "react";
import { Grid, Form, GridColumn, Checkbox } from "semantic-ui-react";
import {
  connect,
  bindActionCreators,
  actions,
  commonFunctions,
} from "../../../../shared/functional/global-import";
import RolesSelect from "../../../../shared/components/atoms/roles-select";

class EmailNotification extends Component {
  constructor(props) {
    super(props);
    this.state = { userDetails: [] };
  }

  onHandleChange = (e, { value, data, name, checked }) => {
    const { fullState, allFunction, stateForm } = this.props;
    if (name === "role") {
      fullState[stateForm][data] = value;
      fullState[stateForm].toEmail = [];
      fullState[stateForm]["isEmailBooked"] = true;
      allFunction.setState({ fullState }, () => this.getUserRoleWise(value));
    } else if (name === "toEmail") {
      fullState[stateForm][data] = [...value];
      allFunction.setState(fullState);
    } else {
      if (!checked) {
        fullState[stateForm]["roleId"] = null;
        fullState[stateForm]["toEmail"] = null;
      }
      fullState[stateForm][data] = checked;
      allFunction.setState(fullState);
      allFunction.validator = commonFunctions.initializeSimpleValidator();
    }
    commonFunctions.onFormChange(allFunction);
  };
  getUserRoleWise(roleId) {
    const { global, auth } = this.props;
    var data = {
      roleId: roleId,
      businessId: global.bussinessDetail.businessId,
      businessLocationId: global.locationSelected.BusinessLocationId,
      pageNo: 1,
      pageSize: 100,
      actionPerformedBy: auth.userDetail.emailId,
    };
    this.props.actions.apiCall({
      urls: ["GETUSERROLEWISE"],
      method: "GET",
      data: data,
      onSuccess: (response) => {
        const filterUsers = response.map((singleUser) => {
          return {
            value: singleUser.userId,
            text: singleUser.userName,
            emailType: singleUser.emailType,
          };
        });
        this.setState({ userDetails: filterUsers });
      },
    });
  }

  componentDidMount() {
    const { fullState, stateForm } = this.props;
    if (fullState[stateForm].roleId) {
      this.getUserRoleWise(fullState[stateForm].roleId);
    }
  }

  render() {
    const { userDetails } = this.state;
    const { fullState, allFunction, stateForm } = this.props;

    return (
      <Form>
        <Grid>
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <Checkbox
              label="Email Notification when booked"
              className="custome-checkbox"
              onChange={this.onHandleChange}
              data="isEmailBooked"
              checked={fullState[stateForm]["isEmailBooked"]}
              fluid
            />
          </Grid.Column>
          <Grid.Column
            mobile={16}
            tablet={8}
            computer={6}
            verticalAlign={"top"}
          >
            <RolesSelect
              name="role"
              placeholder="Select Role"
              onChange={this.onHandleChange}
              data="roleId"
              type="select"
              value={fullState[stateForm].roleId}
              error={
                fullState[stateForm]["isEmailBooked"]
                  ? allFunction.validator.message(
                      "Role",
                      fullState[stateForm]["roleId"],
                      "required"
                    )
                  : null
              }
            />
          </Grid.Column>
          <GridColumn mobile={16} tablet={8} computer={10}>
            <Form.Field>
              <Form.Dropdown
                className="custom-select"
                data="toEmail"
                name="toEmail"
                type="multiple-select"
                fluid
                multiple
                selection
                options={userDetails}
                value={fullState[stateForm].toEmail}
                placeholder="Team member"
                onChange={this.onHandleChange}
                error={
                  fullState[stateForm]["isEmailBooked"]
                    ? allFunction.validator.message(
                        "employee",
                        fullState[stateForm]["toEmail"],
                        "required"
                      )
                    : null
                }
              />
            </Form.Field>
          </GridColumn>
        </Grid>
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
    warnUserState: state.warnUserState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeWarnUser: bindActionCreators(actions.storeWarnUser, dispatch),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EmailNotification);
