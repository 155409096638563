import React, { Fragment } from "react";
import { Image, Grid, Header, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { commonFunctions } from "../../../functional/global-import";

const BusinessByTypeCard = ({
  imageUrl,
  routeTo,
  showRouteTo,
  businessCategoryName,
  CategoryId,
  businessCategoryId,
  addBusinessDetails,
  onBusinessClick,
}) => {
                        
  return (
    <Fragment>
      <Grid.Column textAlign="center" onClick={onBusinessClick}>
        <div className="cmn-shad buisness-by-type">
          <Header as="h3">{businessCategoryName} </Header>
          <Image
            src={commonFunctions.concatenateImageWithAPIUrl(imageUrl)}
            size="small"
            centered
          />
          {/* { showRouteTo ? <Link  onClick={(e)=>onBusinessClick}>
              <Header as="h3" >{businessCategoryName} </Header>
            <Image src={commonFunctions.concatenateImageWithAPIUrl(imageUrl)} size="small" centered/>
            </Link>:
             <Link  onClick={(e)=>onBusinessClick}>
                 <Header as="h3" >{businessCategoryName} </Header>
               <Image src={commonFunctions.concatenateImageWithAPIUrl(imageUrl)} size="small" centered/>
               </Link>} */}
        </div>
      </Grid.Column>
    </Fragment>
  );
};

BusinessByTypeCard.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  businessCategoryName: PropTypes.string.isRequired,
  routeTo: PropTypes.string.isRequired,
};

export default BusinessByTypeCard;
