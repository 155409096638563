/*********************************************************************************************
 * Created By : Saddam Husain
 * Created Date : 02/05/2021
 * Description : Configure store for application by proving root reducer.
 **********************************************************************************************/

import {
  legacy_createStore as createStore,
  applyMiddleware,
  compose,
} from "redux";
//import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
//import logger from 'redux-logger';
import thunk from "redux-thunk";
import rootReducer from "./reducers";
// Package used for persist store data when page is refershed.
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import apiMiddleware from "./middleware/api.middleware";
import * as env from "../config/env.config";
import {
  createStateSyncMiddleware,
  // initMessageListener,
} from "redux-state-sync";

// set persist configutation
const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: [
    // Define reducers those data need to be retain after refersh of page.
    "global",
    "auth",
  ],
};

const config = {
  // TOGGLE_TODO will not be triggered in other tabs
  whitelist: ["global"],
};

const middlewares1 = [createStateSyncMiddleware(config)];

const persistedReducer = persistReducer(persistConfig, rootReducer);

const configureStore = () => {
  const middleware =
    env.NODE_ENV !== "production"
      ? [thunk, apiMiddleware]
      : [thunk, apiMiddleware];
  // redux devtools
  const enhancers =
    env.NODE_ENV === "production"
      ? applyMiddleware(...middleware, ...middlewares1)
      : compose(
          applyMiddleware(...middleware, ...middlewares1),
          window.__REDUX_DEVTOOLS_EXTENSION__ &&
            window.__REDUX_DEVTOOLS_EXTENSION__()
        );
  // create redux store
  const store = createStore(persistedReducer, enhancers);
  let persistor = persistStore(store);
  return { store, persistor };
};

export default configureStore;
