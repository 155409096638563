//   Apply Discount & Coupon  first modal  used component

import React, { Component } from "react";
import { Button, Grid, Modal, Header, Radio, Form } from "semantic-ui-react";

class DiscountModal extends Component {
  constructor() {
    super();
    this.state = {
      activeStep: 0,
      value: "SubTotal",
    };
  }

  handleChange = (e, { value }) => {
    this.setState({ value: value });
  };
  render() {
    const { value } = this.state;

    return (
      <>
        <Modal
          closeOnDimmerClick={false}
          open={this.props.openModal && !this.state.creditcardpaymentStatus}
          closeIcon
          onClose={this.props.closeModal}
          size={"mini"}
        >
          <Modal.Content>
            <Grid>
              <Grid.Column width={14}>
                <Header as="h4" className="orange-color">
                  Apply Discount & Coupon to:
                </Header>
              </Grid.Column>
              <Grid.Column width={16}>
                <Form>
                  <Form.Field>
                    <Radio
                      label="Selected Item(s)"
                      className="custom-radio-btn "
                      value="SelectedItem"
                      checked={value === "SelectedItem"}
                      onChange={this.handleChange}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Radio
                      label="SubTotal"
                      className="custom-radio-btn"
                      value="SubTotal"
                      checked={value === "SubTotal"}
                      onChange={this.handleChange}
                    />
                  </Form.Field>
                </Form>
              </Grid.Column>
              <Grid.Column width={16} textAlign="right">
                <Button className="blue-btn" onClick={this.props.closeModal}>
                  Cancel
                </Button>
                <Button
                  className="orange-btn"
                  onClick={() => this.props.onNextClick(value)}
                >
                  Next
                </Button>
              </Grid.Column>
            </Grid>
          </Modal.Content>
        </Modal>
      </>
    );
  }
}
export default DiscountModal;
