import React, { Component } from "react";
import { Button, Header, Modal, Grid, Form, Table, Icon, Dimmer, Loader, Input, TextArea } from "semantic-ui-react";
import { connect, bindActionCreators, actions, commonFunctions, Notifications } from "../../../shared/functional/global-import";
import { withRouter } from "react-router-dom";
import Moment from "react-moment";

class AddRefundModal extends Component {
  get initialState() {
    return {
      refundAmount: "", notes: "", selectPaymentType: null,
    }
  }
  constructor(props) {
    super(props);

    this.state = { ...this.initialState, transactionLogs: [], refundAmountList: [], gridDataLoading: false };
    this.validator = commonFunctions.initializeSimpleValidator();
  }

  onAmountChange = (e, { name, value, data, }) => {
    if (data === "refundAmount") {
      this.setState({ refundAmount: value });
    }
    if (data === "notes") {
      this.setState({ notes: value });
    }
  }

  componentDidMount() {
    this.getPaymentRefund()
  }

  getPaymentRefund = () => {
    this.setState({ gridDataLoading: true });
    this.props.actions.apiCall({
      urls: ["GETPAYMENTREFUND"],
      method: "GET",
      data: { TransactionDetailId: this.props.refundDetails.TransactionDetailId },
      onSuccess: (response) => {
        this.setState({
          transactionLogs: JSON.parse(response[0].transactionDetail),
          refundAmountList: response[0],
          gridDataLoading: true,
          selectPaymentType: response[0].paymentGatewayMethod
        })
      },
      onFinally: () => { this.setState({ gridDataLoading: false }) },
    });
  }
  onRefundTranscation = (e) => {
           
    var click = true
    if (click === true) {
      var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
      if (isFormValid) {
        this.setState({ gridDataLoading: true });
        const PaymentType = this.props.selectPaymentType === undefined ? this.state.selectPaymentType : this.props.selectPaymentType;
        const edgePayTransactionId = this.props.edgePayTransactionId === undefined ? this.props.refundDetails.EdgePayTransactionId:this.props.edgePayTransactionId;
        this.props.actions.apiCall({
          urls: ["PAYMENTREFUND"], method: "PUT", data: {
            "orderId": this.props.refundDetails.OrderId,
            "StripeTransactionId": this.props.refundDetails.StripeTransactionId ? this.props.refundDetails.StripeTransactionId : null,
            "TransactionDetailId": this.props.refundDetails.TransactionDetailId,
            "Amount": this.state.refundAmount,
            "NoteType": "RefundedNotes",
            "Note": this.state.notes,
            "edgePayTransactionId": edgePayTransactionId,
            "selectPaymentType": PaymentType,
            "ActionPerformedBy": this.props.auth.userDetail.emailId},
          onSuccess: () => {
            this.getPaymentRefund()
            this.props.getCounter && this.props.getCounter()
            this.props.getAllTransactionDetails && this.props.getAllTransactionDetails()
            this.props.orderId && this.props.actions.getOrderItems(this.props.orderId)
            this.props.closeModal()
          },
          onFinally: () => {
            this.setState({ gridDataLoading: false })
          }, showNotification: true,
        });
      }
    }
  }

  onCancel = () => {
    this.setState({ refundAmount: "", notes: "", transactionLogs: [] }, this.props.closeModal())
    this.props.getCounter && this.props.getCounter()
  }

  render() {
    return (
      <div className="cmn-shad" id="form_section">
        <Modal
          open={this.props.openModal}
          closeIcon
          onClose={this.props.closeModal}
          size={"small"}>
          <Header as="h3" >
            <span>Refund payment</span>
            <span className="refundOrder" >Order Id : #{this.props.refundDetails.OrderId} </span>
          </Header>
          <Modal.Content scrolling>
            <Grid>
              <Grid.Column width={8}> <span className="orange-color"> Transaction Amount : {commonFunctions.isNumberInteger(this.state.refundAmountList.totalAmount)}</span></Grid.Column>
              <Grid.Column width={8} textAlign="right"> <span className="orange-color">  Amount available to refund : {commonFunctions.isNumberInteger(this.state.refundAmountList.amountAvailableToRefund)}</span></Grid.Column>
              <Grid.Column width={16} >
                <Input label={{ basic: true, content: '$' }} min="0" placeholder="Refund Amount" name="txtRefundAmount" textAlign="left"
                  data="refundAmount" onChange={this.onAmountChange}
                  // type="number"
                  value={this.state.refundAmount}
                  // error={this.validator.message("refundAmount", this.state.refundAmount, "required")} 
                  fluid />
                {this.validator.message("refundAmount", this.state.refundAmount, "required")}
              </Grid.Column>
              <Grid.Column width={16}>
                <Form>
                  <TextArea fluid placeholder="Notes"
                    data="notes"
                    value={this.state.notes}
                    onChange={this.onAmountChange}
                    error={this.validator.message("refundNotes", this.state.notes, "max:500")}
                  />
                </Form>
              </Grid.Column>
              <Grid.Column width={16} textAlign="right">
                <Button className="blue-btn" onClick={this.onCancel}>Cancel</Button>
                <Button className="orange-btn" onClick={this.onRefundTranscation} >Refund</Button>
              </Grid.Column>
            </Grid>
            <Grid>
              <span className="orange-color">
                Total Refunded Amount : {commonFunctions.isNumberInteger(this.state.refundAmountList.refundedAmount)}
              </span>
              {this.state.gridDataLoading && <Dimmer active inverted>
                <Loader size='small'>Loading</Loader> </Dimmer>}
              <Grid.Column width={16}>
                <div className="common-tableRefund">
                  {this.state.transactionLogs && this.state.transactionLogs.length > 0 &&
                    <Table>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell singleLine>
                            Refunded By
                          </Table.HeaderCell>
                          <Table.HeaderCell textAlign="right" singleLine>
                            Refunded Amount

                          </Table.HeaderCell>
                          <Table.HeaderCell singleLine>
                            Refunded Date

                          </Table.HeaderCell>

                          <Table.HeaderCell singleLine>
                            Notes{" "}

                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {this.state.transactionLogs && this.state.transactionLogs.map((data, index) => {
                          return (
                            <Table.Row>
                              <Table.Cell title={data?.RefundedBy} className="textOverflowHidden">{data?.RefundedBy}</Table.Cell>
                              <Table.Cell textAlign="right"><div>{commonFunctions.isNumberInteger(data?.RefundedAmount)}</div></Table.Cell>
                              <Table.Cell > <Moment format="MMM DD,YYYY">{data?.RefundedDate}</Moment></Table.Cell>

                              <Table.Cell>{data?.Notes}</Table.Cell>
                            </Table.Row>
                          )
                        })}
                      </Table.Body>
                    </Table>}
                </div>
              </Grid.Column>
            </Grid>
          </Modal.Content>

        </Modal>
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
      getOrderItems: bindActionCreators(actions.getOrderItems, dispatch),
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddRefundModal));

