import { actions, env } from "../../shared/functional/global-import";
import * as types from "../types";

function addOrder(data) {
  return { type: types.CREATE_ORDER_ID, payload: data };
}

export function onPriceAndDepositChangeInCart(data) {
  return { type: types.PRICE_DEPOSIT_CHANGE, payload: data };
}

export function updateOrderState(data) {
  return { type: types.UPDATE_ORDER_STATE, payload: data };
}
export function removeExtraCharge(data) {
  return { type: types.REMOVE_EXTRA_CHARGES, payload: data };
}

export function removeDiscountCoupon(data) {
  return { type: types.REMOVE_DISCOUNT_COUPON, payload: data };
}
export function addMultipleFlatFeeAndOtherDetails(data) {
  return {
    type: types.ADD_MULTIPLE_RENTALFLATFEEANDOTHERDETAILS,
    payload: data,
  };
}

export function removeOrder() {
  return { type: types.REMOVE_ORDER_ID, payload: "" };
}

export function addOrderDetails(data) {
  return { type: types.ADD_ORDER_DETAILS, payload: data };
}
export function addSelectedDateRange(data) {
  return { type: types.ADD_SELECTED_DATE_RANGE, payload: data };
}

export function getOrderActivityPricingDetailId(data) {
  return { type: types.ADD_ORDER_ACITIVITY_PRICINGDETAILID, payload: data };
}

// export function addOrderActivingTicketsDetail(data) {
//   return { type: types.ADD_ORDER_ACITIVITY_PRICINGDETAILID, payload: data }
// }

export function addSelectedItems(data) {
  return { type: types.ADD_SELECTED_ITEMS, payload: data };
}

export function addSelectedBookingSlot(data) {
  return { type: types.ADD_SELECTED_BOOKINGSLOT, payload: data };
}

export function AddOrderPaymentStatus(data) {
  return { type: types.ADD_ORDER_PAYMENT_STATUS, payload: data };
}

export function UpdateOrderPaymentStatus(data) {
  return { type: types.UPDATE_ORDER_PAYMENT_STATUS, payload: data };
}

export function createOrder(data) {
  if (!data.orderId) {
    const paramsData = {
      businessId: data.businessId,
      applicationId: JSON.parse(env.APPLICATION_ID),
      businessLocationId: data.businessLocationId,
      customerId: data.customerId,
      actionPerformedBy: data.actionPerformedBy,
    };
    return async (dispatch) => {
      dispatch(
        actions.apiCall({
          urls: ["CREATEORDERID"],
          method: "POST",
          data: paramsData,
          onSuccess: (response) => {
            if (response !== null) {
              dispatch(addOrder(response));
              return response;
            } else {
              // change this alert msg to error message
              alert("Something Went Wrong in Create Customer Process!!");
            }
          },
          showNotification: true,
        })
      );
    };
  } else {
    return async (dispatch) => {
      dispatch(addOrder(data));
    };
  }
}

export function addActivityTimeSlots(data) {
  return { type: types.ADD_ACITIVITY_TIMESLOTS, payload: data };
}
export function addFeePayment(data) {
  return { type: types.ADD_FEE_PAYMENT, payload: data };
}
export function GetActivityTimeSlots(data) {
  const paramsData = {
    businessId: data.businessId,
    businessLocationId: data.businessLocationId,
    customerId: data.customerId,
    actionPerformedBy: data.actionPerformedBy,
  };
  return async (dispatch) => {
    dispatch(
      actions.apiCall({
        urls: ["GETACTIVITYTIMESLOTS"],
        method: "POST",
        data: paramsData,
        onSuccess: (response) => {
          if (response !== null) {
            dispatch(addActivityTimeSlots(response));
            return response;
          } else {
            // change this alert msg to error message
            alert("Something Went Wrong in Create Customer Process!!");
          }
        },
        showNotification: true,
      })
    );
  };
}

export function getOrderItems(data, nextFunction) {
  const paramsData = { orderId: data };
  return async (dispatch) => {
    dispatch(
      actions.apiCall({
        urls: ["GETORDERITEMS"],
        method: "GET",
        data: paramsData,
        onSuccess: async (response) => {
          if (response !== null) {
            dispatch(addOrder(response[0]));
            if (nextFunction) {
              // steper.next(2)
              nextFunction(2);
            }
            return response;
          } else {
            // change this alert msg to error message
            alert("Something Went Wrong in Create Customer Process!!");
          }
        },
      })
    );
  };
}
