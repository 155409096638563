import React, { Component } from "react";
import { Button, Grid, Modal, Radio, Table } from "semantic-ui-react";
import { DataTable } from "../../../../../organisms";
import {
  commonFunctions,
  connect,
  bindActionCreators,
  actions,
  Notifications,
} from "../../../../../../functional/global-import";
import CreateFeeModal from "./create-fee-modal";

class FeeModal extends Component {
  child = React.createRef();
  state = {};
  constructor() {
    super();
    this.state = {
      createaddons: true,
      savedaddons: false,
      gridDetail: [],
      selected: "",
      openFeeModal: false,
      feeModalLoader: false,
      feeForm: { businessId: "", businessLocationId: "", feeTitle: "", feePrice: "", isActive: true, actionPerformedBy: "" }
    };
    this.validator = commonFunctions.initializeSimpleValidator();
  }

  showsavedons = () => {
    this.setState({ createaddons: false, savedaddons: true });
  };
  showcreateons = () => {
    this.setState({ savedaddons: false, createaddons: true });
  };

  handleChange = (item) => {
    this.setState({ selected: item });
  };

  handleCreateFee = () => {
    this.setState({ openFeeModal: !this.state.openFeeModal });
  };

  onHandleChange = (e, { name, value, type, checked, data }) => {
    var feeForm = commonFunctions.onHandleChange(
      e,
      { name, value, type, checked, data },
      this.state.feeForm
    );
    this.setState({ feeForm });
  };

  handleSubmit = () => {
    const { selected } = this.state;
    let data = { feeId: selected.feeId, price: selected.feePrice, orderChargeType: "FeeCharge" }
    this.props.handlePostAPIForCustomChagre(data);
  };

  handleFeeSubmit = (e) => {
    const { feeForm } = this.state;

    feeForm["businessId"] = this.props.global.bussinessDetail.businessId;
    feeForm["businessLocationId"] = this.props.global.locationSelected.BusinessLocationId;
    feeForm["actionPerformedBy"] = this.props.auth.userDetail.emailId;
    var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
    if (isFormValid) {
      this.setState({ feeModalLoader: true });
      this.props.actions.apiCall({
        urls: ["ADDFEES"], method: "POST", showNotification: true, data: feeForm, onSuccess: (response) => {
          this.handleCreateFee();
          this.child.current.getDefaultData();
          return response;
        },
        onFinally: () => {
          this.setState({ feeModalLoader: false });
        },
      });
    } else {
      return false;
    }
  };

  render() {
    const { isFeeFormLoading, feeForm, selected, feeModalLoader } = this.state;
    const fullState = this.state;
    const allFunction = this;
    const { api } = this.props;
    return (
      <Modal
        closeOnDimmerClick={false}
        open={this.props.openModal}
        closeIcon
        onClose={this.props.closeModal}
        size={"small"}
      >
        <Modal.Content scrolling className="widthAuto">
          <Grid>
            <Grid.Column width={16}>
              <Grid reversed="computer tablet">
                <Grid.Column
                  mobile={8}
                  tablet={8}
                  computer={8}
                  textAlign="right"
                >
                  <Button
                    className="orange-btn"
                    loading={isFeeFormLoading}
                    onClick={(e) => this.handleCreateFee()}
                  >
                    Create Fee
                  </Button>
                </Grid.Column>
                <DataTable
                  ref={this.child}
                  getApiName="GETFEES"
                  // className="grid"
                  className="noClass"
                  searchOption={{ show: true, placeHolder: "Search fee" }}
                  gridName="feeGrid"
                  screenName="fee"
                  fullState={fullState}
                  allFunction={allFunction}
                  tableHeight="250px"
                  emptyGrid="No records to show"
                  columns={[
                    {
                      headerName: "Selected",
                      fieldName: "selected",
                      isSorting: false,
                      Cell: (props) => {
                        return (
                          <Radio
                            className="custom-radio-btn"
                            checked={selected && selected.feeId === props.feeId}
                            onChange={() => this.handleChange(props)}
                          ></Radio>
                        );
                      },
                    },
                    {
                      headerName: "Title",
                      fieldName: "feeTitle",
                      isSorting: true,
                      className: "textOverflowHidden",
                      Cell: (props) => {
                        return (
                          <span title={props.feeTitle}>{props.feeTitle}</span>
                        );
                      },
                    },
                    {
                      headerName: "Price",
                      fieldName: "feePrice",
                      isSorting: true,
                      headerClass: "textRight",
                      Cell: (props) => {
                        return (
                          <Table.Cell textAlign="right">
                            {" "}
                            {props.feePrice === 0
                              ? "-"
                              : commonFunctions.isNumberInteger(props.feePrice)}
                          </Table.Cell>
                        );
                      },
                    },
                  ]}
                />
                <CreateFeeModal openModal={this.state.openFeeModal}
                  validator={this.validator}
                  feeModalLoader={feeModalLoader}
                  feeForm={feeForm} closeModal={this.props.closeModal}
                  handleFeeSubmit={this.handleFeeSubmit}
                  allFunction={allFunction} onHandleChange={this.onHandleChange} />
              </Grid>
            </Grid.Column>
            <Grid.Column width={16} textAlign="right">
              <Button className="blue-btn" onClick={this.props.closeModal}>Cancel</Button>
              <Button className="orange-btn" disabled={selected === ""}
                loading={api.isApiLoading} onClick={this.handleSubmit}>Save</Button>
            </Grid.Column>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
    orderState: state.orderState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
      addFeePayment: bindActionCreators(actions.addFeePayment, dispatch),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FeeModal);
