import React, { Component } from "react";
import { Button, Icon, Modal, Form, Accordion, Grid, Checkbox } from "semantic-ui-react";
import CommonCropImage from "../../../atoms/common-crop-image";
import { commonFunctions, bindActionCreators, connect, actions } from "../../../../functional/global-import";
import { PhoneNumberInput, CommonDeleteUpdateModal } from "../../../../components";
import RolesSelect from '../../../atoms/roles-select';
import LocationsSelect from '../../../atoms/locations-select';

class TeamMemberModal extends Component {
  get initialState() {
    return {
      memberData: {
        businessId: "",
        userId: "",
        isImageUpdated: false,
        position: "",
        actionPerformedBy: this.props.auth.userDetail.emailId,
        businessLocationId: "",
        image: [],
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        designation: "",
        roleId: "",
        userLocations: [],
        isActive: true,
        password: "", confirmPassword: "", passcode: "", confirmPasscode: ""
      },
      activeAccordian: false,
      getDetail: true,
      isCommonDeleteUpdateModalOpen: false,

      fileList: [],
      imageData: [],

    }
  }
  constructor(props) {
    super(props);
    this.state = { ...this.initialState, locationOption: [], rolesOption: "", UserimageId: "" };
    this.validator = commonFunctions.initializeSimpleValidator();
  }

  static getDerivedStateFromProps(props, state) {
    if (Object.entries(props.memberData).length > 0 && props.openModal && state.getDetail) {
      
      const imageData = JSON.parse(props.memberData.image)
      const imageDetail = {
        uid: '2',
        // name: 'image.png',
        status: 'done',
        url:  imageData === null ? commonFunctions.concatenateImageWithAPIUrl(null) : commonFunctions.concatenateImageWithAPIUrl(imageData[0].ImageFile),
      }
      return {
        fileList: [imageDetail], imageData,
         UserimageId: props.memberData.userId , ImageId : imageData === null ? null : imageData[0].ImageId,
        memberData: {
          businessId: props.global.bussinessDetail.businessId,
          businessLocationId: props.global.locationSelected.BussinessLocationId,
          designation: props.memberData.designation,
          emailId: props.memberData.emailId,
          firstName: props.memberData.firstName,
          lastName: props.memberData.lastName,
          isActive: props.memberData.isActive,
          possition: props.memberData.possition,
          actionPerformedBy: props.auth.userDetail.emailId,
          userId: props.memberData.userId,
          phoneNumber: props.memberData.phoneNumber,
          roleId: props.memberData.roleId,
          userLocations: JSON.parse(props.memberData.userLocations)
        },
        getDetail: false
      };
    }
    // Return null if the state hasn't changed
    return null;
  }


  // This function is used to set handle change
  onHandleChange = (e, { name, value, type, checked, data }) => {
    var memberData = commonFunctions.onHandleChange(e,
      { name, value, type, checked, data }, this.state.memberData, this);
    this.setState({ memberData });
  }

  // Api to update data
  onHandleUpdate = (e) => {
    var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
    if (isFormValid) {
      const { memberData } = this.state;
      var formData = commonFunctions.getFormData(memberData);
      this.props.actions.apiCall({
        urls: ["UPDATEUSER"], method: "PUT", data: formData, onSuccess: (response) => {
          commonFunctions.onApiSucessFormChangedFalse(this)
          this.onCloseModal();
          this.props.getTeamDetails();
          if (response.keyId === this.props.auth.userDetail.keyId) {
            let userDetail = this.props.auth.userDetail;
            userDetail.userName = response.firstName + " " + response.lastName;
            userDetail.userImage = response.image;
            userDetail.numberOfBusinessUserLocations = response.numberOfBusinessUserLocations;
            userDetail.businessUserLocations = response.businessUserLocations;
            this.props.actions.storeUserDetail(userDetail);
            const filteredLocations = commonFunctions.locationDropdown(response)
            this.props.actions.storeLocationOptions(filteredLocations);
          }
        }, isFormData: true, showNotification: true
      });
    }
  }
  onCloseModal = () => {
    if (this.props.warnUserState.isFormChanged) {
      this.setState({ isCommonDeleteUpdateModalOpen: true })
    }
    else {
      this.onModalCloseFormFalse();
    }
  }

  onModalCloseFormFalse = () => {
    this.props.closeModal();
    this.setState(this.initialState);
    this.validator = commonFunctions.initializeSimpleValidator();
  }


  // This Function is used to Collapse Fields 
  onHandleClick = (e, titleProps) => {
    this.setState({ activeAccordian: !titleProps.active })
  }

  onConfirmWarnUser = () => {
    this.setState({ isCommonDeleteUpdateModalOpen: false })
    commonFunctions.onApiSucessFormChangedFalse(this);
    this.onModalCloseFormFalse();
  }

  // this function is used to prevent Press Enter inside Input field  
  onEnterEventHandler = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }

  render() {
    const { activeAccordian, memberData, isCommonDeleteUpdateModalOpen } = this.state;
    const { api } = this.props;

    return (
      <>
        <Modal open={this.props.openModal} closeIcon onClose={this.onCloseModal} closeOnDimmerClick={false} >
          <Modal.Header>Edit Team Member</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Form>
                <Grid>
                  <Grid.Column mobile={16} tablet={4} computer={4} textAlign="center">
                    <CommonCropImage
                      ImageType={"UserImage"}
                      fullState={this.state}
                      cropId={this.state.UserimageId}
                      StateRef={this}
                      isDefaultImage={false}
                      stateForm={this.state.memberData}
                    />
                  </Grid.Column>
                  <Grid.Column width={12}>
                    <Grid>
                      <Grid.Column mobile={16} tablet={8} computer={8}>
                        <Form.Input fluid placeholder="First Name" name="txtFirstName" onKeyPress={this.onEnterEventHandler} textAlign="left" data="firstName" onChange={this.onHandleChange} value={memberData.firstName} error={this.validator.message("firstName", memberData.firstName, "required|firstName")}>
                        </Form.Input>
                      </Grid.Column>
                      <Grid.Column mobile={16} tablet={8} computer={8}>
                        <Form.Input fluid placeholder="Last Name" name="txtLastName" onKeyPress={this.onEnterEventHandler} textAlign="left" data="lastName" onChange={this.onHandleChange} value={memberData.lastName} error={this.validator.message("lastName", memberData.lastName, "required|lastName")}>
                        </Form.Input>
                      </Grid.Column>
                      <Grid.Column mobile={16} tablet={8} computer={8}>
                        <Form.Input fluid textAlign="left" readOnly disabled={true} placeholder="Email Address" maxLength="250" name="txtEmail"
                          error={this.validator.message("email", memberData.emailId, "required|email")} data="emailId" onKeyPress={this.onEnterEventHandler}
                          onChange={this.onHandleChange} value={memberData.emailId}>
                        </Form.Input>
                      </Grid.Column>
                      <Grid.Column mobile={16} tablet={8} computer={8}>
                        <PhoneNumberInput
                          onChange={(value, country, e, formattedValue) => this.onHandleChange(e, {
                            name: "phnPhoneNumber", value: formattedValue, type: "phoneNumber", data: "phoneNumber"
                          })}
                          value={memberData.phoneNumber}
                          // error={this.validator.message("phoneNumber", memberData.phoneNumber, "required|phone")}
                           />
                      </Grid.Column>

                      <Grid.Column mobile={16} tablet={8} computer={8}>
                        <Form.Input fluid textAlign="left" placeholder="Title" name="txtDesignation" error={this.validator.message("Designation", memberData.designation,
                          "required|min:0|max:50")} onKeyPress={this.onEnterEventHandler}
                          data="designation" onChange={this.onHandleChange} value={memberData.designation}>
                        </Form.Input>
                      </Grid.Column>

                      <Grid.Column mobile={16} tablet={8} computer={8}>
                        <RolesSelect
                          placeholder="Select Role"
                          type="select"
                          options={this.props.rolesOption}
                          onChange={this.onHandleChange}
                          name="roleId"
                          data="roleId"
                          value={memberData.roleId}
                          error={this.validator.message("RoleId ", memberData.roleId, "required")} />

                      </Grid.Column>
                      <Grid.Column mobile={16} tablet={8} computer={8} className="controlField">
                        <LocationsSelect
                          placeholder='Select Location'
                          options={this.props.locationOption}
                          onChange={this.onHandleChange}
                          defaultValue={memberData.userLocations}
                          type="multiple-select"
                          name="userLocations" data="userLocations"
                          value={memberData.userLocations}
                          error={this.validator.message("UserLocations", memberData.userLocations, "required")} />
                        {/* {this.validator.message(
                        "UserLocations",
                        memberData.userLocations,
                        "required")} */}
                      </Grid.Column>
                      <Grid.Column mobile={16} tablet={8} computer={8}>
                        <Checkbox data="isActive" checked={memberData.isActive}
                          value={memberData.isActive} label="Active" onChange={this.onHandleChange}
                          className="custom-toggle" />
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                </Grid>
              </Form>
              <Form>
                <Grid>
                  <Grid.Column width={16}>
                    <Accordion styled fluid>
                      <Accordion.Title active={activeAccordian} index={0} onClick={this.onHandleClick}>
                        <Icon name='dropdown' />
                        Change Password or Passcode
                      </Accordion.Title>
                      <Accordion.Content active={activeAccordian}>
                        <Form>
                          <Grid>
                            <Grid.Row columns="3">
                              <Grid.Column width={4} verticalAlign="middle">
                                <label>Change Password</label>
                              </Grid.Column>
                              <Grid.Column width={6}>
                                <Form.Input type="password" fluid textAlign="left" placeholder="Password" onKeyPress={this.onEnterEventHandler}
                                  error={memberData.confirmPassword ? this.validator.message("password", memberData.password, "required|min:6|max:20") :
                                    this.validator.message("password", memberData.password, "min:6|max:20")}
                                  name="txtPassword" data="password" onChange={this.onHandleChange} value={memberData.password}>
                                </Form.Input>
                              </Grid.Column>
                              <Grid.Column width={6}>
                                <Form.Input type="password" data="confirmPassword" fluid textAlign="left" placeholder="Confirm Password"
                                  onKeyPress={this.onEnterEventHandler} value={memberData.confirmPassword} onChange={this.onHandleChange} name="txtConfirmPassword"
                                  error={this.validator.message('confirmPassword', memberData.confirmPassword,
                                    `validConfirmPassword:${memberData.password}`)}>
                                </Form.Input>
                              </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns="3">
                              <Grid.Column width={4} verticalAlign="middle">
                                <label>Change Passcode</label>
                              </Grid.Column>
                              <Grid.Column width={6}>
                                <Form.Input type="password" fluid textAlign="left" onKeyPress={this.onEnterEventHandler}
                                  placeholder="Passcode" data="passcode" onChange={this.onHandleChange}
                                  name="txtPasscode" error={memberData.confirmPasscode ? this.validator.message("passcode", memberData.passcode, "required|integer|min:6|max:6") : this.validator.message("passcode", memberData.passcode, "min:6|max:6")} value={memberData.passcode}>
                                </Form.Input>
                              </Grid.Column>
                              <Grid.Column width={6}>
                                <Form.Input type="password" fluid textAlign="left" placeholder="Confirm Passcode" data="confirmPasscode" onKeyPress={this.onEnterEventHandler}
                                  onChange={this.onHandleChange} name="txtPasscode" error={this.validator.message("confirmPasscode", memberData.confirmPasscode, `validConfirmPasscode:${memberData.passcode}`)} value={memberData.confirmPasscode}>
                                </Form.Input>
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </Form>
                      </Accordion.Content>
                    </Accordion>
                  </Grid.Column>
                </Grid>
              </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.onCloseModal} className="blue-btn"> Cancel </Button>
            <Button onClick={this.onHandleUpdate} loading={api.isApiLoading} className="orange-btn"> Update </Button>
          </Modal.Actions>
        </Modal>
        {isCommonDeleteUpdateModalOpen &&

          <CommonDeleteUpdateModal
            isModalOpen={isCommonDeleteUpdateModalOpen}
            onConfirm={this.onConfirmWarnUser}
            onClose={() => this.setState({ isCommonDeleteUpdateModalOpen: false })}
            confirmationButtonText={"Yes"}
            header={"Are you sure you want to discard your changes ?"}
            cancelButtonText={"Cancel"}
          />
        }
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    api: state.api,
    auth: state.auth,
    global: state.global,
    warnUserState: state.warnUserState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      storeUserDetail: bindActionCreators(actions.storeUserDetail, dispatch),
      storeLocationOptions: bindActionCreators(actions.storeLocationOptions, dispatch),
      storeWarnUser: bindActionCreators(actions.storeWarnUser, dispatch),
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TeamMemberModal);