import React, { Component } from "react";
import {  Grid, Container } from "semantic-ui-react";
import { DataTable } from "../../../components";
// import { Link, env } from "../../../functional/global-import";
import Moment from "react-moment";

class OrderHistoryTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customersSummary: [],
            hasMore: true,
            gridDetail: []
        };
    }

    render() {
        const fullState = this.state;
        const allFunction = this;
        return (
            <Container fluid>
                <Grid>
                    <Grid.Row>
                        <Grid.Column textAlign="right">
                            <Grid.Column textAlign="right"  >
                                <DataTable
                                    ref={this.child}
                                    getApiName="GETORDERHISTORY"
                                    getGridDataParamters={{ "OrderId": this.props.orderId, }}
                                    gridName="OrderCustomerGrid"
                                    screenName="CustomerSummaryGrid"
                                    fullState={fullState}
                                    allFunction={allFunction}
                                    tableHeight="290px"
                                    emptyGrid="No records to show"
                                    columns={[
                                        {
                                            headerName: "Action",
                                            fieldName: "action",
                                            isSorting: true,
                                            className: "textOverflowHidden w-300",
                                            headerClass: "w-300",
                                            Cell: (props) => {
                                                return (
                                                    <span title={props.action} className="orange-color" >
                                                        {props.action}</span>
                                                );
                                            },

                                        },
                                        {
                                            headerName: "User",
                                            fieldName: "user",
                                            isSorting: true,
                                            className: "orderColumnLength"
                                        },
                                        {
                                            headerName: "Date",
                                            fieldName: "startDate",
                                            isSorting: true,
                                            Cell: (props) => {
                                                return (
                                                    <span className="orange-color" ><Moment format="MMM DD,YYYY hh:mm A">{props.startDate}</Moment> </span>
                                                );
                                            },
                                        },
                                    ]}
                                />

                            </Grid.Column>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        );
    }

}

export default OrderHistoryTable;
