import SimpleReactValidator from "simple-react-validator";
import { Label } from "semantic-ui-react";
import { env } from "../functional/global-import";
import moment from "moment";
import { noImage } from "../functional/global-image-import";
import { Loader, Dimmer } from "semantic-ui-react";

const onFormChange = (allData) => {
  let warnUserState = allData.props.warnUserState;
  if (warnUserState !== undefined) {
    warnUserState.isFormChanged = true;
    allData.props.actions.storeWarnUser(warnUserState);
  }
};

// This function is used to handle common onchange in all the forms in the application.
const onHandleChange = (
  e,
  { name, value, type, checked, data },
  obj,
  allData
) => {
  // warn user for unsaved changes  function call if this(allData) last param is passed in onchange
  if (allData) {
    onFormChange(allData);
  }

  const path = data.split(".");
  const depth = path.length;
  const state = { ...obj };
  let ref = state;
  for (let i = 0; i < depth; i += 1) {
    if (i === depth - 1) {
      if (
        type === "text" ||
        type === "number" ||
        type === "radio" ||
        type === "password" ||
        type === "select" ||
        type === "textarea" ||
        type === "multiple-select" ||
        type === "phoneNumber"
      ) {
        ref[path[i]] = value;
      } else if (type === "select-int") {
        ref[path[i]] = parseInt(value);
      } else if (type === "checkbox") {
        ref[path[i]] = checked;
      }
    } else {
      ref = ref[path[i]];
    }
  }
  return state;
};

// This function is used to handle common form submit in all the forms in the application.
const onHandleFormSubmit = (e, formObj) => {
  e.preventDefault();

  if (formObj.validator.allValid() === false) {
    formObj.validator.showMessages();
    formObj.forceUpdate();
    return false;
  } else {
    // sessionStorage.clear();
    // sessionStorage.setItem("isFormChanged", false);
    return true;
  }
};

const initializeSimpleValidator = () => {
  return new SimpleReactValidator({
    element: (message) => (
      <Label basic color="red" pointing="above">
        {message}
      </Label>
    ),
    validators: {
      // For Custom error Message in confirm Passcode field
      validConfirmPasscode: {
        message: "The 'Passcode' and 'Confirm Passcode' doesn't match'",
        rule: (val, params, validator) => {
          if (params[0] !== "undefined" && params[0].length > 1) {
            return val === params[0];
          }
        },
        required: true,
      },
      // For Custom error message in confirm Password field
      validConfirmPassword: {
        message: "The 'Password' and 'Confirm Password' doesn't match'",
        rule: (val, params, validator) => {
          if (params[0] !== "undefined" && params[0].length > 1) {
            return val === params[0];
          }
        },
        required: true,
      },

      // For Custom error message in Minimum & Maximum Seats
      validValues: {
        message: "Maximum Value Must be greater than Minimum Value",
        rule: (val, params, validator) => {
          if (parseFloat(val) < parseFloat(params[0])) {
            return val === params[0];
          }
        },
        require: true,
      },

      // For Custom error message in Minimum & Maximum Seats
      minMaxValue: {
        message: "Maximum Value Must be greater than Minimum Value",
        rule: (value, params, validator) => {
          if (parseFloat(value) < parseFloat(params[0])) {
            return value === params[0];
          }
        },
        require: true,
      },

      // For Custom error message in Minimum & Maximum Value in Pricing
      validMinMaxValues: {
        message: "Maximum Value Must be greater than Minimum Value",
        rule: (value, params, validator) => {
          if (parseFloat(value) < parseFloat(params[0])) {
            return value === params[0];
          }
        },
        require: true,
      },

      //For Avoid Decimal values
      validInteger: {
        message: "Decimal values not Accepted",
        rule: (val, params, validator) => {
          return validator.helpers.testRegex(val, /^[0-9]$/);
        },
        require: true,
      },

      // For Select Atleast One Role
      validRoles: {
        message: "Must be Select One Roles",
        rule: (val, params, validator) => {
          if (params[0].length > 1) {
            return val === params[0];
          }
        },
        required: true,
      },
    },
  });
};

// This function is used for image URL concatnation
const concatenateImageWithAPIUrl = (Image) => {
  if (Image === null) {
    return `${noImage}`;
  } else {
    return `${env.IMG_URL}${Image}`;
    // return `${env.API_URL.replace("/api", "")}${Image}`;
  }
};

const getFormData = (data) => {
  var formData = new FormData();
  Object.entries(data).map(function ([key, val]) {
    if (typeof val !== "undefined" && val !== null) {
      if (key === "image" && val !== null && data.image.length > 0) {
        let file = val[0].file === undefined ? val[0] : val[0].file;
        formData.append(key, file);
      } else if (
        key === "businessLocationCategories" ||
        key === "businessCategories" ||
        key === "userLocations" ||
        key === "documentBusinessCategoryType" ||
        key === "businessTags" ||
        key === "deleted" ||
        key === "emailType" ||
        key === "categoryTag" ||
        key === "users"
      ) {
        var array = val;

        if (array === null || array.length === 0) {
          formData.append(key, "");
        } else {
          for (var i = 0; i < array.length; i++) {
            formData.append(key, array[i]);
          }
        }
      } else if (key === "images" || key === "DefaultImage") {
        var arrayDetails = val;
        for (var i = 0; i < arrayDetails.length; i++) {
          let file = val[i].file === undefined ? val[i] : val[i].file;
          formData.append(key, file);
        }
      } else {
        formData.append(key, val);
      }
    }
  });
  return formData;
};

// This function is used for creating location dropdown in all application
const locationDropdown = (locationJson) => {
  let filteredLocations = JSON.parse(locationJson.businessUserLocations).map(
    (location) => {
      return {
        id: location.BusinessLocationId,
        zipcode: location.Zipcode,
        value: location.BusinessLocationName,
        text: location.BusinessLocationName,
      };
    }
  );
  return filteredLocations;
};

// This function is used to format date for displaying purpose in all application
const formattedDate = (date) => {
  return moment(date).format("MMM DD, YYYY");
};
const formatDate = (date) => {
  return date ? moment(date).format("MM/DD/YYYY") : "MM/DD/YYYY";
};

// This function is used to save Format date in all application
const saveFormattedDate = (date) => {
  return moment(date).format("YYYY-MM-DD");
};

// This function is used to get  Global code details in all application
const getGlobalCodeDetails = (globalCodeData, categoryType, codeName) => {
  const singlevalue = globalCodeData.filter((code) => {
    return code.categoryName === categoryType && code.codeName === codeName;
  });
  return singlevalue[0];
};

// This function is used for activate Deactivate Toggle in all application
const activateDeactivateToggle = (
  staterefrence,
  displayName,
  keyId,
  keyName,
  isActive
  ) => {
    let fullStateForm = staterefrence.state;
                       
  const activeInactive = isActive ? "deactivate" : "activate";
  const messagetoShowInModal = `Do you want to ${activeInactive} "${displayName}" ?`;
  staterefrence.setState({
    ...fullStateForm,
    buttonName: "Update",
    messageToShow: messagetoShowInModal,
    isCommonDeleteUpdateModalOpen:
      !fullStateForm?.isCommonDeleteUpdateModalOpen,
    [keyName]: keyId,
    isActive: isActive,
  });
};

// This function is used for common delete modal open in all application
const deleteModalMessage = (stateRef, displayName, keyId, keyName) => {
  let fullState = stateRef.state;
  const messageInDeleteModal = `Do you want to delete "${displayName}" ?`;
  stateRef.setState({
    ...fullState,
    isCommonDeleteUpdateModalOpen: !fullState.isCommonDeleteUpdateModalOpen,
    [keyName]: keyId,
    buttonName: "Delete",
    messageToShow: messageInDeleteModal,
  });
};

// This function is used for common delete modal open in all application
const cancelOrderMessage = (stateRef, displayName, keyId, keyName) => {
  let fullState = stateRef.state;
  const messageInDeleteModal = `Do you want to cancel "${displayName}" ?`;
  stateRef.setState({
    ...fullState,
    isCommonDeleteUpdateModalOpen: !fullState.isCommonDeleteUpdateModalOpen,
    [keyName]: keyId,
    buttonName: "ok",
    messageToShow: messageInDeleteModal,
  });
};

const onConfirmFunction = (
  stateRefconfirm,
  nameId,

  gridObjectName,
  deleteApiName,
  UpdateApiName,
  additionalParams,
  functioncall
) => {
  let fullStateConfirm = stateRefconfirm.state;
  if (fullStateConfirm.buttonName === "Delete") {
    stateRefconfirm.props.actions.apiCall({
      urls: [deleteApiName],
      method: "DELETE",
      data: {
        [nameId]: fullStateConfirm[nameId],

        actionPerformedBy: stateRefconfirm.props.auth.userDetail.emailId,
        ...additionalParams,
      },
      onSuccess: () => {
        let functionName;
        if (stateRefconfirm.child.current.childsChild !== undefined) {
          functionName = stateRefconfirm.child.current.childsChild.current;
        } else if (stateRefconfirm.child.current !== undefined) {
          functionName = stateRefconfirm.child.current;
        }
        // if (stateRefconfirm.child.current.childsChild.current != null) {
        //   functionName = stateRefconfirm.child.current.childsChild.current;
        // }
        // else if (stateRefconfirm.child.current !== null) {
        //   functionName = stateRefconfirm.child.current;
        // }
        stateRefconfirm.setState(
          {
            ...fullStateConfirm,
            isCommonDeleteUpdateModalOpen:
              !fullStateConfirm.isCommonDeleteUpdateModalOpen,
            [nameId]: "",
          },
          () => functionName && functionName.getDefaultData()
        );
        // stateRefconfirm.setState({ ...fullStateConfirm, isCommonDeleteUpdateModalOpen: !fullStateConfirm.isCommonDeleteUpdateModalOpen, [nameId]: "" }, () => stateRefconfirm.child.current.childsChild.current.getDefaultData())
      },
      showNotification: true,
    });
  }
  if (fullStateConfirm.buttonName === "Update") {
    stateRefconfirm.props.actions.apiCall({
      urls: [UpdateApiName],
      method: "PUT",
      data: {
        [nameId]: fullStateConfirm[nameId],
        businessId: stateRefconfirm.props.global.bussinessDetail.businessId,
        isActive: !fullStateConfirm.isActive,
        actionPerformedBy: stateRefconfirm.props.auth.userDetail.emailId,
        ...additionalParams,
      },
      onSuccess: (response) => {
        fullStateConfirm[gridObjectName].forEach((updateData) => {
          if (updateData[nameId] === response.keyId) {
            updateData.isActive = !updateData.isActive;
          }
        });
        stateRefconfirm.setState({
          ...fullStateConfirm,
          [gridObjectName]: fullStateConfirm[gridObjectName],
          isCommonDeleteUpdateModalOpen:
            !fullStateConfirm.isCommonDeleteUpdateModalOpen,
          [nameId]: "",
        });
      },
      showNotification: true,
    });
  }
};
// This function is used for cancel button inital set state of form in all application
const onCancelInitialState = (stateRefInitial) => {
  stateRefInitial.setState(stateRefInitial.initialState);
  stateRefInitial.validator = initializeSimpleValidator();
  // setting form changed to false as form is cleared through this fiunction
  onApiSucessFormChangedFalse(stateRefInitial);
};
const onApiSucessFormChangedFalse = (stateReference) => {
  let warnUserState = stateReference.props.warnUserState;
  if (warnUserState !== undefined) {
    warnUserState.isFormChanged = false;
    stateReference.props.actions.storeWarnUser(warnUserState);
  }
};
// This function is used to Close common Delete and Update modal in all application
const onCloseDeleteUpdateModal = (stateRefrence, variableId) => {
                     ;
  stateRefrence.setState({
    isCommonDeleteUpdateModalOpen: false,
    [variableId]: "",
  });
};
// This function is used for POST and PUT Functions in all more setting modules
const onHandleSubmit = (
  e,
  formStateRefrence,
  loading,
  formName,
  addApiName,
  updateApiName,
  isFormData = false,
  initialState = true,
  additionalParams
) => {
                     ;
  let formState = formStateRefrence.state;
  const { displayButton } = formState;
  var isFormValid = onHandleFormSubmit(e, formStateRefrence);
  if (isFormValid) {
                       ;
    let apiName, method;
    var requestObject = formStateRefrence.state[formName];
    requestObject = { ...requestObject, ...additionalParams };
    var formData = isFormData ? getFormData(requestObject) : requestObject;
    formStateRefrence.setState({ [loading]: true });
    if (formData.eventEndDate || formData.eventStartDate) {
      formData.eventStartDate = commonFunctions.saveFormattedDate(
        formData.eventStartDate
      );
      formData.eventEndDate = commonFunctions.saveFormattedDate(
        formData.eventEndDate
      );
    }
    if (formData.startDate || formData.endDate) {
      formData.startDate = commonFunctions.saveFormattedDate(
        formData.startDate
      );
      formData.endDate = commonFunctions.saveFormattedDate(formData.endDate);
    }
    if (formData.blackOutStartDate || formData.blackOutEndDate) {
      formData.blackOutStartDate = commonFunctions.saveFormattedDate(
        formData.blackOutStartDate
      );
      formData.blackOutEndDate = commonFunctions.saveFormattedDate(
        formData.blackOutEndDate
      );
    }

    if (displayButton === "Save") {
      apiName = [addApiName];
      method = "POST";
    } else if (displayButton === "Update") {
      if (formData.startDate || formData.endDate) {
        formData.startDate = commonFunctions.saveFormattedDate(
          formData.startDate
        );
        formData.endDate = commonFunctions.saveFormattedDate(formData.endDate);
      }
      if (formData.eventEndDate || formData.eventStartDate) {
        formData.eventStartDate = commonFunctions.saveFormattedDate(
          formData.eventStartDate
        );
        formData.eventEndDate = commonFunctions.saveFormattedDate(
          formData.eventEndDate
        );
      }
      if (formData.blackOutStartDate || formData.blackOutEndDate) {
        formData.blackOutStartDate = commonFunctions.saveFormattedDate(
          formData.blackOutStartDate
        );
        formData.blackOutEndDate = commonFunctions.saveFormattedDate(
          formData.blackOutEndDate
        );
      }
      apiName = [updateApiName];
      method = "PUT";
    }
    formStateRefrence.props.actions.apiCall({
      urls: [apiName],
      method: method,
      data: formData,
      onSuccess: () => {
        let warnUserState = formStateRefrence.props.warnUserState;
        warnUserState.isFormChanged = false;
        formStateRefrence.props.actions.storeWarnUser(warnUserState);
        {
          initialState
            ? formStateRefrence.setState(
                formStateRefrence.initialState,
                () =>
                  formStateRefrence.child &&
                  formStateRefrence.child.current &&
                  formStateRefrence.child.current.childsChild.current.getDefaultData()
              )
            : formStateRefrence.child.current &&
              formStateRefrence.child.current.childsChild.current.getDefaultData();
        }
      },
      showNotification: true,
      onFinally: () => {
        formStateRefrence.setState({ [loading]: false });
      },
    });
    formStateRefrence.validator = initializeSimpleValidator();
  }
};

const getFunction = (
  stateRefrence,
  getApiName,
  idVariable,
  titleVariable,
  dropdownVariable,
  wantRespose = false,
  additionalParams
) => {
  let fullStateDetail = stateRefrence.state;
  stateRefrence.props.actions.apiCall({
    urls: [getApiName],
    method: "GET",
    data: {
      isActive: true,
      businessId: stateRefrence.props.global.bussinessDetail.businessId,
      businessLocationId:
        stateRefrence.props.global.locationSelected.BusinessLocationId,
      pageNo: 1,
      pageSize: 1000,
      ...additionalParams,
    },
    onSuccess: (response) => {
      const dropdownData = response.map((singleObj) => {
        return {
          id: singleObj[idVariable],
          value: singleObj[idVariable],
          text: singleObj[titleVariable],
        };
      });
      stateRefrence.setState({ [dropdownVariable]: dropdownData });
    },
  });
};

const dropdownDeletedValue = (
  fullState,
  stateForm,
  variableName,
  getApiValues,
  dropDownState,
  stateRefTag
) => {
  var second = [];
  const d = getApiValues.map((ss) => {
    second.push(ss.value);
  });
  var first = fullState[stateForm][variableName];
  var difference = first.filter((x) => second.indexOf(x) === -1);

  difference.map((aa) => {
    dropDownState.push({ text: aa, value: aa });
  });
  dropDownState = [...dropDownState, ...getApiValues];
  stateRefTag.setState({ tagList: dropDownState, documentList: dropDownState });
  stateRefTag.setState({ requirementsList: dropDownState });
};

const isNonEmptyObject = (obj) => {
  return obj && Object.keys(obj).length > 0;
};
const isNumberInteger = (value) => {
  if (value !== null && value !== undefined) {
    let a = value;
    if (typeof value === "number") {
      a = JSON.stringify(value);
    }
    let b = a.split(".");
    if (b[0].length === 0) {
      return (
        "$ " +
        (b[1] !== undefined
          ? b[1].length === 1
            ? "0" + value + "0"
            : "0" + value
          : "0" + value + ".00")
      );
    } else {
      return (
        "$ " +
        (b[1] !== undefined
          ? b[1].length === 1
            ? value + "0"
            : value
          : value + ".00")
      );
    }
  } else {
    return "--";
  }
};

const isNumberAnalyticsInteger = (value) => {
  if (value !== null && value !== undefined) {
    let a = value;
    if (typeof value === "number") {
      a = JSON.stringify(value);
    }
    let b = a.split(".");
    if (b[0].length === 0) {
      return b[1] !== undefined
        ? b[1].length === 1
          ? "0" + value + "0"
          : "0" + value
        : "0" + value + ".00";
    } else {
      return b[1] !== undefined
        ? b[1].length === 1
          ? value + "0"
          : value
        : value + ".00";
    }
  } else {
    return "--";
  }
};

const notificationTime = () => {
  let newNotificationTime = 8;
  return newNotificationTime;
};

const truncateText = (truncText, value) => {
  let newTruncateText = truncText.substring(0, value) + "...";
  return newTruncateText;
};
const showLoader = () => {
  return (
    <Dimmer active inverted>
      <Loader />
    </Dimmer>
  );
};
const iframeRemove = () => {
  const iframe = document.querySelector("iframe"); // select the iframe element you want to remove
  if (iframe) {
    iframe.remove(); // remove the element from the DOM if it exists
  }
};
const IsJsonCheckCorrectFunction = (CheckString) => {
  try {
    var json = JSON.parse(CheckString);
    return typeof json === "object";
  } catch (e) {
    return false;
  }
};

// find the first available date
const findFirstMissingDate = (nonAvailableDates) => {
  const today = new Date();
  const firstDate = nonAvailableDates[0];
  const year = parseInt(firstDate.substring(0, 4), 10);
  const month = parseInt(firstDate.substring(5, 7), 10) - 1; // Subtract 1 to convert to zero-based index
  if (today.getFullYear() === year && today.getMonth() === month) {
    today.setFullYear(year, month, today.getDate());
  } else {
    today.setFullYear(year, month, 1);
  }
  const daysInMonth = new Date(year, month + 1, 0).getDate();
  for (let day = today.getDate(); day <= daysInMonth; day++) {
    const currentDate = new Date(year, month, day);

    const formattedDate = `${currentDate.getFullYear()}-${String(
      currentDate.getMonth() + 1
    ).padStart(2, "0")}-${String(currentDate.getDate()).padStart(2, "0")}`;

    if (!nonAvailableDates.includes(formattedDate)) {
      return formattedDate;
    }
  }
};
const getGlobalCodeIdByCodeName = (codeName, data) => {  
  const matchedItem = data.find(item => item.codeName === codeName);
  return matchedItem ? matchedItem.globalCodeId : null;
};

export const commonFunctions = {
  // minmaxvalue,
  iframeRemove,
  onHandleChange,
  onHandleFormSubmit,
  initializeSimpleValidator,
  concatenateImageWithAPIUrl,
  getFormData,
  formattedDate,
  saveFormattedDate,
  getGlobalCodeDetails,
  locationDropdown,
  activateDeactivateToggle,
  deleteModalMessage,
  onConfirmFunction,
  onCloseDeleteUpdateModal,
  onHandleSubmit,
  onCancelInitialState,
  getFunction,
  onFormChange,
  onApiSucessFormChangedFalse,
  dropdownDeletedValue,
  isNonEmptyObject,
  isNumberInteger,
  cancelOrderMessage,
  truncateText,
  notificationTime,
  showLoader,
  formatDate,
  IsJsonCheckCorrectFunction,
  isNumberAnalyticsInteger,
  findFirstMissingDate,
  getGlobalCodeIdByCodeName,
};
