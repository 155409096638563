import React, { Component } from "react";
import { Grid, Form, Divider,  Button, GridColumn } from "semantic-ui-react";
import AddCustomField from "../../../../pages/more-setting/pricing-taxes-setting/add-custom-field";
import { commonFunctions } from "../../../functional/global-import";

class CommonDeliveryCustomField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customFieldStatus: false
        }
    }

    customFieldModal = () => {
        this.setState({
            customFieldStatus: !this.state.customFieldStatus,
        });
    };

    addClickCustomField = (e) => {
        const { fullState, allFunction } = this.props;
        const arr = [...fullState.customfield.deliveryCustomField];
        arr.push({ customField: "", customDescriptions: "" });
        allFunction.setState({ customfield: { ...fullState.customfield, deliveryCustomField: arr } });
    }
    onHandleCustomField = (e, { value, id, data }) => {
        const { fullState, allFunction } = this.props;
        const customFieldData = [...fullState.customfield.deliveryCustomField];
        customFieldData[id][data] = value;
        allFunction.setState({ customfield: { ...fullState.customfield, deliveryCustomField: customFieldData } });
    }

    onHandleAddCustomField = (closeModal) => {
        const { fullState, stateForm, allFunction, variableName } = this.props;
        if (allFunction.validatorCustomfield.allValid() === false) {
            allFunction.validatorCustomfield.showMessages();
            allFunction.forceUpdate();
        }
        else {
            fullState[stateForm][variableName] = fullState.customfield.deliveryCustomField;
            allFunction.setState({ ...fullState })
            allFunction.props.actions.showNotification({ title: "Success", message: 'Custom fields Added Successfully.', position: 'br', autoDismiss: commonFunctions.notificationTime()}, "success")
            closeModal();
            commonFunctions.onFormChange(allFunction);
        }
    }
    handleRemoveSpecificRow = (index) => {
        const { fullState, allFunction } = this.props;
        const rows = [...fullState.customfield.deliveryCustomField];
        rows.splice(index, 1)
        allFunction.setState({ customfield: { ...fullState.customfield, deliveryCustomField: rows } });
    }
    render() {
        const { fullState, stateForm, allFunction, variableName } = this.props;
        return (<React.Fragment>
            <Grid.Column computer={3} tablet={8}>
                <Button className="orange-btn" onClick={this.customFieldModal}>
                    {fullState[stateForm][variableName] && fullState[stateForm][variableName].length > 0 ? "Update Custom Field" : "Add Custom Field"}
                </Button>
            </Grid.Column>

            <GridColumn><Divider hidden></Divider> </GridColumn>

            {fullState[stateForm][variableName] && fullState[stateForm][variableName].length > 0 && fullState[stateForm][variableName].map((singleData, i) => {
                return (
                    <>
                        <Grid.Column width={16} key={i}>
                            <Grid>
                                <Grid.Column width={10}>
                                    <Form>
                                        <Form.TextArea fluid placeholder={singleData.customField}
                                            id={i} data="customDescriptions"
                                            onChange={this.onHandleCustomField}
                                            value={singleData.customDescriptions}
                                            error={allFunction.validatorGeneral.message(singleData.customField, singleData.customDescriptions, "min:0|max:500")} />
                                    </Form>
                                </Grid.Column>
                            </Grid>
                        </Grid.Column>
                    </>)
            }
            )}
            {(this.state.customFieldStatus) &&
                <AddCustomField openModal={this.state.customFieldStatus} closeModal={this.customFieldModal}
                    onHandleCustomField={this.onHandleCustomField} addClickCustomField={this.addClickCustomField} addCustomFields={fullState.customfield.deliveryCustomField} handleRemoveSpecificRow={this.handleRemoveSpecificRow} onHandleAddCustomField={this.onHandleAddCustomField} validatorCustomfield={allFunction.validatorCustomfield}
                />
            }
        </React.Fragment>
        )
    }
}

export default CommonDeliveryCustomField;