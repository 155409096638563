import React, { Component } from "react";
import { Button, Grid,Modal, Table} from "semantic-ui-react";

class TransactionNoteDetailModal extends Component {
  render() {
    return (
      <Modal open={this.props.openModal} closeIcon onClose={this.props.closeModal} size={"small"}>
        <Modal.Content>
          <Table className="add-comment-table">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>User</Table.HeaderCell>
                <Table.HeaderCell>Date</Table.HeaderCell>
                <Table.HeaderCell>Time</Table.HeaderCell>
                <Table.HeaderCell>Note</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>Julie A.</Table.Cell>
                <Table.Cell>Jan 30, 2021</Table.Cell>
                <Table.Cell>2:00 PM</Table.Cell>
                <Table.Cell>Payment made by Robin</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Julie A.</Table.Cell>
                <Table.Cell>Jan 20, 2021</Table.Cell>
                <Table.Cell>6:00 PM</Table.Cell>
                <Table.Cell>Customer made partial payment</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>

          <Grid>
            <Grid.Column width={16} textAlign="right">
              <Button className="blue-btn" onClick={this.props.closeModal}>
                Cancel
              </Button>
              <Button className="orange-btn" onClick={this.props.closeModal}>
                Save
              </Button>
            </Grid.Column>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}
export default TransactionNoteDetailModal;
