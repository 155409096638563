import React, { Component } from 'react'
import { Dropdown } from 'semantic-ui-react'
import {commonFunctions,connect,bindActionCreators,actions,Link,Notifications} from "../../../functional/global-import";


class RequirementsTagger extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      requirementsList:[]
    };
  }

  handleAddition = (e, { value }) => {
    this.setState((prevState) => ({
      requirementsList: [{ text: value, value }, ...prevState.requirementsList],
    }))
  }

  getRequirements = () => {
    this.props.actions.apiCall({
      urls: ["GETREQUIREMENTS"],
      method: "GET",
      data: {businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId: this.props.global.locationSelected.BusinessLocationId,requirementId :-1,PageNo:1,PageSize:100},
      onSuccess: (response) => {
        const getRequirement = response.map((singleRequirement) => {
        return { value:singleRequirement.requirementTitle, text: singleRequirement.requirementTitle }
      });
      this.setState({ requirementsList: getRequirement });
      },
    });
  };
  componentDidMount = () => {
    this.getRequirements();
  };

  render() {
    const { currentValues } = this.state
    return (
      <Dropdown options={this.state.requirementsList} placeholder='Requirements' 
      search selection fluid multiple allowAdditions value={this.props.value} 
      error={this.props.error} onAddItem={this.handleAddition} onChange={this.props.onChange}
       data={this.props.data} type={this.props.type} className="tagger custom-select" />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
auth :state.auth
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
    },
  };
};
export default connect(mapStateToProps,mapDispatchToProps)(RequirementsTagger);

