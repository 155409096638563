// ***************************************************************************************************
// *************** Cancelation common Component on For All Business Type *****************************
// *****************************************************************************************************

import React, { Component } from "react";
import {
  Header,
  Checkbox,
  Grid,
  Button,
  Form,
  Divider,
  Radio,
} from "semantic-ui-react";
import {
  connect,
  bindActionCreators,
  actions,
  Notifications,
  commonFunctions,
} from "../../../../../functional/global-import";
import RentalAvailabilityModal from "../inventory-type/rental/rental-avaiblity -modal";
class CancelationCommonComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rentalAvailabilityModal: false,
      isAddMoreCLicked: null,
      rentalAvaibilityYes: null,
      rentalAvaibilityNo: null,
    };
    this.validator = commonFunctions.initializeSimpleValidator();
  }

  onBackOpenAddOn = () => {
    const { allFunction } = this.props;
    allFunction.setState({ showAddon: true, showCancellationComponent: false });
  };

  onTextFieldChange = (e, { parentIndex, value, index1 }) => {
    const { allFunction, productCustomQuestion } = this.props;
    productCustomQuestion[parentIndex].customQuestionOptionValue[
      index1
    ].CustomQuestionOptionValue = value;
    productCustomQuestion[parentIndex].isQuestionAnswered = value;
    productCustomQuestion[parentIndex].customQuestionOptionValue[
      index1
    ].IsChecked = value === "" ? false : true;
    allFunction.setState({ productCustomQuestion: [...productCustomQuestion] });
  };
  onRadioBtnChange = (e, { checked, value, parentIndex, index1 }) => {
    const { allFunction, productCustomQuestion } = this.props;
    productCustomQuestion[parentIndex].isQuestionAnswered = "answered";
    productCustomQuestion[parentIndex].customQuestionOptionValue.map(
      (radioObj) => {
        if (radioObj.CustomQuestionOptionValue === value) {
          radioObj.IsChecked = checked;
        } else {
          radioObj.IsChecked = false;
        }
      }
    );
    allFunction.setState({ productCustomQuestion: [...productCustomQuestion] });
  };

  onRequirementChange = (e, { checked, index }) => {
    const { allFunction, briefDetails } = this.props;
    briefDetails.Requirements[index].IsChecked = checked;
    briefDetails.Requirements[index].isQuestionAnswered = checked
      ? "answered"
      : "";
    allFunction.setState({ briefDetails: { ...briefDetails } });
  };
  onCheckBoxHandleChange = (
    e,
    { parentIndex, checked, index1, type = undefined }
  ) => {
    const { allFunction, productCustomQuestion } = this.props;
    productCustomQuestion[parentIndex].customQuestionOptionValue[
      index1
    ].IsChecked = checked;
    if (type === "Checkbox") {
      productCustomQuestion[parentIndex].customQuestionOptionValue[
        index1
      ].CustomQuestionOptionValue = "true";
      productCustomQuestion[parentIndex].isQuestionAnswered = checked
        ? "answered"
        : "";
    }
    type === undefined &&
      allFunction.setState(
        { productCustomQuestion: [...productCustomQuestion] },
        this.toCheckAllCheckBoxes(
          productCustomQuestion[parentIndex].customQuestionOptionValue,
          parentIndex
        )
      );
    type !== undefined &&
      allFunction.setState({
        productCustomQuestion: [...productCustomQuestion],
      });
  };

  toCheckAllCheckBoxes = (multipleCheckBoxObj, parentIndex) => {
    const { allFunction, productCustomQuestion } = this.props;

    const multipleCheckBoxValue = multipleCheckBoxObj.map((singleObj) => {
      return singleObj.IsChecked;
    });

    const isSameId = multipleCheckBoxValue.every(
      (currentValue) => currentValue === false
    );

    productCustomQuestion[parentIndex].isQuestionAnswered = isSameId
      ? ""
      : "answered";

    allFunction.setState({ productCustomQuestion: [...productCustomQuestion] });
  };

  onAddrequirement = (
    isAddMoreClicked,
    rentalAvaibilityYes,
    rentalAvaibilityNo
  ) => {
    const { briefDetails, orderState, auth, businessTypeName, orderDetailId } =
      this.props;
    this.props.actions.apiCall({
      urls: ["ADDORDERREQUIREMENT"],
      method: "POST",
      data: {
        orderId: orderState.currentOrder.orderId,
        businessCategoryType: commonFunctions.getGlobalCodeDetails(
          this.props.global.codes,
          "BusinessCategoryType",
          businessTypeName
        ).globalCodeId,
        requirements: briefDetails.Requirements,
        orderDetailId: orderDetailId,
        actionPerformedBy: auth.userDetail.emailId,
      },
      showNotification: true,
      onSuccess: () => {
                   
        if (rentalAvaibilityYes) {
          this.props.backBtnInventoryView();
        } else if (rentalAvaibilityNo) {
          this.props.onNext && this.props.onNext(isAddMoreClicked);
        } else {
          this.props.onNext && this.props.onNext(isAddMoreClicked);
          this.props.onEditQuestionNext && this.props.onEditQuestionNext();
          this.props.actions.getOrderItems(
            this.props.orderState.currentOrder.orderId
          );
        }
      },
    });
  };

  onSaveContinueDetail = (e, isAddMoreClicked) => {
               
    const { productCustomQuestion, businessTypeName, briefDetails } =
      this.props;
    if (productCustomQuestion && productCustomQuestion.length > 0) {
      var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
      if (isFormValid) {
        const {
          orderState,
          auth,
          orderDetailId,
          productId,
          productCustomQuestion,
        } = this.props;
        const questiondetails = productCustomQuestion.map((customObj) => {
          if (customObj.associateAddOn) {
            customObj.customQuestionOptionValue.map((OptioObj) => {
              if (
                OptioObj.CustomQuestionOptionValue === "No" &&
                OptioObj.IsChecked
              ) {
                customObj.associateAddOn = null;
              }
            });
          }
          return { ...customObj };
        });
        const businessCategoryType = commonFunctions.getGlobalCodeDetails(
          this.props.global.codes,
          "BusinessCategoryType",
          businessTypeName
        ).globalCodeId;

        const details = {
          orderId: orderState.currentOrder.orderId,
          orderDetailId: orderDetailId,
          businessCategoryType: businessCategoryType,
          productId: productId,
          customQuestions: questiondetails,
          actionPerformedBy: auth.userDetail.emailId,
        };

        this.props.actions.apiCall({
          urls: ["ADDORDERQUESTIONANSWER"],
          method: "POST",
          data: details,
          showNotification: true,
          onSuccess: (response) => {
            if (
              briefDetails.Requirements &&
              briefDetails.Requirements !== null
            ) {
              this.onAddrequirement(isAddMoreClicked);
            } else {
              this.props.onNext && this.props.onNext(isAddMoreClicked);
              this.props.onEditQuestionNext && this.props.onEditQuestionNext();
              this.props.actions.getOrderItems(
                this.props.orderState.currentOrder.orderId
              );
            }
          },
        });
      }
    } else {
      if (briefDetails.Requirements && briefDetails.Requirements !== null) {
        var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
        if (isFormValid) {
          this.onAddrequirement(isAddMoreClicked);
        }
      } else {
        this.props.onNext && this.props.onNext(isAddMoreClicked);
      }
    }
  };
  onSaveCustomQuestionAnswer = (e, isAddMoreClicked) => {
               
    const { productCustomQuestion, businessTypeName, briefDetails } =
      this.props;
    if (
      (productCustomQuestion && productCustomQuestion.length > 0) ||
      (briefDetails.Requirements && briefDetails.Requirements !== null)
    ) {
      var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
      if (isFormValid) {
        this.setState({
          rentalAvailabilityModal: !this.state.rentalAvailabilityModal,
          isAddMoreCLicked: isAddMoreClicked,
          rentalAvaibilityYes: true,
          rentalAvaibilityNo: false,
        });
      }
    } else {
      this.setState({
        rentalAvailabilityModal: !this.state.rentalAvailabilityModal,
        isAddMoreCLicked: isAddMoreClicked,
        rentalAvaibilityYes: true,
        rentalAvaibilityNo: false,
      });
    }
  };
  onHandleRentalInventory = (
    e,
    isAddMoreClicked,
    rentalAvaibilityYes,
    rentalAvaibilityNo
  ) => {
               
    const { productCustomQuestion, businessTypeName, briefDetails } =
      this.props;
    if (productCustomQuestion && productCustomQuestion.length > 0) {
      const {
        orderState,
        auth,
        orderDetailId,
        productId,
        productCustomQuestion,
      } = this.props;
      const questiondetails = productCustomQuestion.map((customObj) => {
        if (customObj.associateAddOn) {
          customObj.customQuestionOptionValue.map((OptioObj) => {
            if (
              OptioObj.CustomQuestionOptionValue === "No" &&
              OptioObj.IsChecked
            ) {
              customObj.associateAddOn = null;
            }
          });
        }
        return { ...customObj };
      });
      const businessCategoryType = commonFunctions.getGlobalCodeDetails(
        this.props.global.codes,
        "BusinessCategoryType",
        businessTypeName
      ).globalCodeId;

      const details = {
        orderId: orderState.currentOrder.orderId,
        orderDetailId: orderDetailId,
        businessCategoryType: businessCategoryType,
        productId: productId,
        customQuestions: questiondetails,
        actionPerformedBy: auth.userDetail.emailId,
      };

      this.props.actions.apiCall({
        urls: ["ADDORDERQUESTIONANSWER"],
        method: "POST",
        data: details,
        showNotification: true,
        onSuccess: (response) => {
          if (briefDetails.Requirements && briefDetails.Requirements !== null) {
            this.onAddrequirement(
              isAddMoreClicked,
              rentalAvaibilityYes,
              rentalAvaibilityNo
            );
          } else if (rentalAvaibilityYes) {
            this.props.backBtnInventoryView();
          } else if (rentalAvaibilityNo) {
            this.props.onNext && this.props.onNext(isAddMoreClicked);
          } else {
            this.props.onNext && this.props.onNext(isAddMoreClicked);
            this.props.onEditQuestionNext && this.props.onEditQuestionNext();
            this.props.actions.getOrderItems(
              this.props.orderState.currentOrder.orderId
            );
          }
        },
      });
    } else {
      if (briefDetails.Requirements && briefDetails.Requirements !== null) {
        this.onAddrequirement(
          isAddMoreClicked,
          rentalAvaibilityYes,
          rentalAvaibilityNo
        );
      } else {
        if (isAddMoreClicked) {
          this.props.onNext && this.props.onNext(isAddMoreClicked);
        } else {
          this.props.backBtnInventoryView();
        }
      }
    }
  };

  render() {
    const { briefDetails, showAddon, productCustomQuestion, editQuestion } =
      this.props;
                 
    return (
      <>
        <Grid>
          <Grid.Column width={16}>
            {/*  */}

            {productCustomQuestion === null && (
              <Grid>
                <Grid.Column width={16} textAlign="right">
                  {editQuestion === undefined && (
                    <Button
                      className="orange-btn"
                      onClick={(e) => this.props.onNext(false)}
                    >
                      Skip
                    </Button>
                  )}
                  {editQuestion && (
                    <Button
                      className="orange-btn"
                      onClick={(e) => this.props.onEditQuestionNext()}
                    >
                      Skip
                    </Button>
                  )}
                </Grid.Column>
              </Grid>
            )}

            <>
              {productCustomQuestion && productCustomQuestion.length > 0 && (
                <Grid>
                  <Grid.Column width={8}>
                    <Header as="h3"> Custom Questions</Header>
                  </Grid.Column>
                  <Grid.Column width={8} textAlign="right">
                    {editQuestion === undefined && (
                      <Button
                        className="orange-btn"
                        onClick={(e) => this.props.onNext(false)}
                      >
                        Skip
                      </Button>
                    )}
                    {editQuestion && (
                      <Button
                        className="orange-btn"
                        onClick={(e) => this.props.onEditQuestionNext()}
                      >
                        Skip
                      </Button>
                    )}
                  </Grid.Column>
                  <Divider hidden />
                </Grid>
              )}

              {productCustomQuestion &&
                productCustomQuestion.length > 0 &&
                productCustomQuestion.map((CustomQuestion, parentIndex) => {
                  switch (CustomQuestion.customQuestionType) {
                    case "Multiple Choice":
                      return (
                        <Grid.Column width={16}>
                          <Divider hidden />
                          <div className="MultipleChoice">
                            <p className="mbt">
                              <b>{CustomQuestion.customQuestion}</b>
                            </p>

                            {CustomQuestion.customQuestionOptionValue &&
                              CustomQuestion.customQuestionOptionValue.map(
                                (customQuestionOptionValue, index1) => {
                                  return (
                                    <>
                                      <Checkbox
                                        className="custome-checkbox fgyyh"
                                        checked={
                                          customQuestionOptionValue.IsChecked
                                        }
                                        onChange={(e, { checked }) =>
                                          this.onCheckBoxHandleChange(e, {
                                            checked,
                                            parentIndex,
                                            index1,
                                          })
                                        }
                                        label={
                                          customQuestionOptionValue.CustomQuestionOptionValue
                                        }
                                      />
                                      &nbsp;
                                    </>
                                  );
                                }
                              )}

                            <div>
                              {CustomQuestion.required
                                ? this.validator.message(
                                    "multiple choice",
                                    CustomQuestion.isQuestionAnswered,
                                    "required"
                                  )
                                : false}
                            </div>
                          </div>
                        </Grid.Column>
                      );
                    case "Checkbox":
                      const index1 = 0;
                      const type = CustomQuestion.customQuestionType;

                      return (
                        <Grid.Column width={16}>
                          <Divider hidden />
                          <Checkbox
                            className="custome-checkbox dftyui"
                            checked={
                              CustomQuestion.customQuestionOptionValue &&
                              CustomQuestion.customQuestionOptionValue[0]
                                .IsChecked
                            }
                            onChange={(e, { checked }) =>
                              this.onCheckBoxHandleChange(e, {
                                checked,
                                parentIndex,
                                index1,
                                type,
                              })
                            }
                            label={
                              <label>
                                <b>{CustomQuestion.customQuestion}</b>
                              </label>
                            }
                          />
                          &nbsp;
                          <div>
                            {CustomQuestion.required
                              ? this.validator.message(
                                  "checkbox",
                                  CustomQuestion.isQuestionAnswered,
                                  "required"
                                )
                              : false}
                          </div>
                        </Grid.Column>
                      );
                    case "Single Choice":
                      return (
                        // <Grid>
                        <Grid.Column width={16}>
                          <Divider hidden />
                          <div className="singleChoice">
                            <p className="mbt">
                              <b>{CustomQuestion.customQuestion}</b>
                            </p>
                            {CustomQuestion.customQuestionOptionValue &&
                              CustomQuestion.customQuestionOptionValue.map(
                                (customQuestionOptionValue, index1) => {
                                  return (
                                    <>
                                      {
                                        <Radio
                                          className="custom-radio-btn "
                                          name={`radio-${parentIndex}`}
                                          checked={
                                            customQuestionOptionValue.IsChecked
                                          }
                                          label={
                                            customQuestionOptionValue.CustomQuestionOptionValue
                                          }
                                          value={
                                            customQuestionOptionValue.CustomQuestionOptionValue
                                          }
                                          onChange={(e, { checked, value }) =>
                                            this.onRadioBtnChange(e, {
                                              checked,
                                              value,
                                              parentIndex,
                                              index1,
                                            })
                                          }
                                        />
                                      }
                                      &nbsp;
                                    </>
                                  );
                                }
                              )}

                            <div>
                              {CustomQuestion.required
                                ? this.validator.message(
                                    "single-choice",
                                    CustomQuestion.isQuestionAnswered,
                                    "required"
                                  )
                                : false}
                            </div>
                          </div>
                        </Grid.Column>
                        // </Grid>
                      );
                    case "Text Field":
                      return (
                        <Grid.Column width={16}>
                          {CustomQuestion.customQuestionOptionValue &&
                            CustomQuestion.customQuestionOptionValue.map(
                              (customQuestionOptionValue, index1) => {
                                return (
                                  <>
                                    <Divider hidden />
                                    <div>
                                      <p className="mbt">
                                        <b>{CustomQuestion.customQuestion}</b>
                                      </p>
                                    </div>

                                    <div>
                                      {
                                        <Form.TextArea
                                          placeholder="Type Here.."
                                          style={{ width: 300 }}
                                          value={
                                            customQuestionOptionValue.CustomQuestionOptionValue
                                          }
                                          data2={CustomQuestion}
                                          onChange={(e, { value }) =>
                                            this.onTextFieldChange(e, {
                                              parentIndex,
                                              value,
                                              index1,
                                            })
                                          }
                                          maxLength="200"
                                          // error={CustomQuestion.required ? this.validator.message("text", CustomQuestion.isQuestionAnswered, "required")
                                          //     : false}
                                        />
                                      }
                                    </div>
                                    <div>
                                      {CustomQuestion.required
                                        ? this.validator.message(
                                            "text",
                                            CustomQuestion.isQuestionAnswered,
                                            "required"
                                          )
                                        : false}
                                    </div>
                                  </>
                                );
                              }
                            )}
                        </Grid.Column>
                      );
                    case "Single Choice AddOns (Yes/No)":
                      const addons = CustomQuestion.associateAddOn;
                      //  && JSON.parse(CustomQuestion.associateAddOn)
                      return (
                        <Grid.Column width={16}>
                          <Divider hidden />
                          <p className="mbt">
                            <b>{CustomQuestion.customQuestion}</b>
                          </p>

                          <div className="radioBtnWithText">
                            {CustomQuestion.customQuestionOptionValue &&
                              CustomQuestion.customQuestionOptionValue.length >
                                0 &&
                              CustomQuestion.customQuestionOptionValue.map(
                                (customQuestionOptionValue, index1) => {
                                  return (
                                    <>
                                      <div>
                                        <span className="insideMultipleChoice">
                                          {/* { */}
                                          <Radio
                                            className="custom-radio-btn  pr-10"
                                            name={`radioGroup-${parentIndex}`}
                                            checked={
                                              customQuestionOptionValue.IsChecked
                                            }
                                            label={
                                              customQuestionOptionValue.CustomQuestionOptionValue
                                            }
                                            value={
                                              customQuestionOptionValue.CustomQuestionOptionValue
                                            }
                                            data2={CustomQuestion}
                                            onChange={(e, { checked, value }) =>
                                              this.onRadioBtnChange(e, {
                                                checked,
                                                value,
                                                parentIndex,
                                                index1,
                                              })
                                            }
                                          />
                                          <p>&nbsp;</p>
                                        </span>
                                      </div>
                                    </>
                                  );
                                }
                              )}
                          </div>

                          <div className="AsociateAddons">
                            {addons &&
                              addons.map(
                                (customQuestionOptionValue, index1) => {
                                  return (
                                    <>
                                      <span>
                                        {customQuestionOptionValue.AddOnTitle},
                                        &nbsp;
                                      </span>
                                    </>
                                  );
                                }
                              )}
                          </div>

                          <div>
                            {CustomQuestion.required
                              ? this.validator.message(
                                  "single-choice Add Ons",
                                  CustomQuestion.isQuestionAnswered,
                                  "required"
                                )
                              : false}
                          </div>
                        </Grid.Column>
                      );
                  }
                })}
              <Divider hidden />
            </>
            {briefDetails &&
              briefDetails.Requirements &&
              briefDetails.Requirements !== null && (
                <>
                  <Grid.Column>
                    <p className="mbt">
                      <b>Requirements</b>
                    </p>
                  </Grid.Column>
                  {briefDetails.Requirements &&
                    briefDetails.Requirements.map((requirementObj, index) => {
                      return (
                        <Grid.Column>
                          <Checkbox
                            checked={requirementObj.IsChecked}
                            label={requirementObj.RequirementTitle}
                            className="custome-checkbox"
                            onChange={(e, { checked }) =>
                              this.onRequirementChange(e, { checked, index })
                            }
                          />
                          <div>
                            {this.validator.message(
                              "Requirement",
                              requirementObj.isQuestionAnswered,
                              "required"
                            )}
                          </div>
                        </Grid.Column>
                      );
                    })}
                </>
              )}
            <br />

            {briefDetails && briefDetails.CancellationPolicy !== null && (
              <>
                <Grid.Column>
                  <p className="mbt">
                    <b>Cancellation Policy</b>
                  </p>
                </Grid.Column>
                <Grid.Column>
                  <p>
                    {" "}
                    {` - ${
                      JSON.parse(briefDetails.CancellationPolicy)[0]
                        .CancellationPolicyDescription
                    } `}
                  </p>
                </Grid.Column>
              </>
            )}
          </Grid.Column>
          <Grid.Column width={16} textAlign="right">
            {showAddon && (
              <Button
                className="blue-btn"
                onClick={() => this.onBackOpenAddOn()}
              >
                Back
              </Button>
            )}
            {editQuestion === undefined && (
              <Button
                className="blue-btn"
                onClick={
                  this.props.businessTypeName === "Rental"
                    ? (e) => this.onSaveCustomQuestionAnswer(e, true)
                    : (e) => this.onSaveContinueDetail(e, true)
                }
              >
                Add more items
              </Button>
            )}
            {editQuestion && (
              <Button
                className="blue-btn"
                onClick={(e) => this.props.onEditQuestionNext()}
              >
                Cancel
              </Button>
            )}
            <Button
              className="orange-btn"
              onClick={(e) => this.onSaveContinueDetail(e, false)}
            >
              Continue
            </Button>
          </Grid.Column>
        </Grid>
        <RentalAvailabilityModal
          openModal={this.state.rentalAvailabilityModal}
          closeModal={this.onSaveCustomQuestionAnswer}
          onHandleRentalInventory={this.onHandleRentalInventory}
          isAddMoreCLicked={this.state.isAddMoreCLicked}
          rentalAvaibilityYes={this.state.rentalAvaibilityYes}
          rentalAvaibilityNo={this.state.rentalAvaibilityNo}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
    orderState: state.orderState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
      getOrderItems: bindActionCreators(actions.getOrderItems, dispatch),
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CancelationCommonComponent);
