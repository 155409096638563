import React, { Component } from "react";
import {
  Header,
  Table,
  Image,
  Input,
  Grid,
  Button,
  Form,
} from "semantic-ui-react";
import {
  connect,
  bindActionCreators,
  actions,
  Notifications,
  commonFunctions,
} from "../../../../../../functional/global-import";

class SeasonPassCustomFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customField: [],
      seasonPassDetail: [],
      MinItemPerOrder: null,
      MaxItemPerOrder: null,
      commentStatus: false,
      quantity: "",
    };
    this.validator = commonFunctions.initializeSimpleValidator();
  }
  componentDidMount() {
    this.getCustomFieldList();
  }

  getCustomFieldList = () => {
    const { global } = this.props;
    this.props.actions.apiCall({
      urls: ["GETORDERSEASONPASSCUSTOMFIELDLIST"],
      method: "GET",
      data: {
        BusinessId: global.bussinessDetail.businessId,
        BusinessLocationId: global.locationSelected.BusinessLocationId,
        ProductId: global.inventoryDetail.productId,
      },
      onSuccess: (response) => {
        const { selectedProducts } = this.props;
        var customField = JSON.parse(response[0]?.customfields ?? "[]");
        this.setState({ customField: customField });
        if (customField?.length > 0 && selectedProducts?.length > 0) {
          var seasonPassDetail = selectedProducts.map((product) => {
            return {
              customFields: [...customField],
              productdetailid: product.productdetailid,
            };
          });

          this.setState({ seasonPassDetail: seasonPassDetail });
        }
      },
    });
  };

  handleChange = (event, { value }, seasonPassIndex, customFieldIndex) => {
    const { seasonPassDetail } = this.state;
    const clonedSeasonPassDetail = JSON.parse(JSON.stringify(seasonPassDetail));
    clonedSeasonPassDetail[seasonPassIndex].customFields[
      customFieldIndex
    ].customFieldAnswer = value;
    this.setState({ ...this.state, seasonPassDetail: clonedSeasonPassDetail });
  };

  handleRemove = (seasonPassObj, seasonPassIndex) => {
    const { seasonPassDetail } = this.state;
    seasonPassDetail.splice(seasonPassIndex, 1);
    this.setState({ ...this.state, seasonPassDetail: seasonPassDetail });
  };

  handleNext = (e) => {
    var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
    const { orderState, global, auth } = this.props;
    if (isFormValid) {
      const { seasonPassDetail } = this.state;
      this.props.actions.apiCall({
        urls: ["ADDORDERSEASONPASSCUSTOMFIELD"],
        method: "POST",
        data: {
          orderId: orderState.currentOrder.orderId,
          businessCategoryType: global.inventoryDetail.businessCategoryId,
          productId: global.inventoryDetail.productId,
          seasonPassDetail: seasonPassDetail,
          actionPerformedBy: auth.userDetail.emailId,
        },
        onSuccess: (response) => {
          this?.props?.moveToNextStep?.(response.keyId);
        },
      });
    }
  };

  //   onUpdateRentalDetail = (selectedProducts) => {
  //     const { orderState, seasonPassEdit } = this.props;
  //     this.props.actions.apiCall({
  //       urls: ["UPDATEORDERRENTALDETAIL"],
  //       method: "PUT",
  //       data: {
  //         orderId: orderState.currentOrder.orderId,
  //         startDate: seasonPassEdit.StartDate,
  //         endDate: seasonPassEdit.EndDate,
  //         orderDetailId: seasonPassEdit.rentalItem.OrderDetailId,
  //         productId: seasonPassEdit.ProductId,
  //         productDetailId: selectedProducts.map((item) => item.productdetailid),
  //         actionPerformedBy: this.props.auth.userDetail.emailId,
  //       },
  //       onSuccess: (response) => {
  //         this.props.next(3);
  //         this.props.allFunction.handleSelectedBusinessCategoryEmpty();
  //         this.props.actions.getOrderItems(orderState.currentOrder.orderId);
  //         return response;
  //       },
  //       showNotification: true,
  //     });
  //   };

  onCancelBtnCLick = () => {
    this.props.next(3);
    this.props.allFunction.handleSelectedBusinessCategoryEmpty();
  };

  render() {
    const { seasonPassDetail } = this.state;
    const {
      seasonPassEdit,
      productCategoryDetail,
      productsList,
      selectedProducts,
    } = this.props;
    return (
      <>
        <Grid>
          <Grid.Column width={16}>
            <Table basic="very" className="borderlessTable chooseDateRental">
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    {!seasonPassEdit?.seasonPassEdit ? (
                      <>
                        <Image
                          src={commonFunctions.concatenateImageWithAPIUrl(
                            productCategoryDetail?.image
                          )}
                          size="tiny"
                          bordered
                        />
                      </>
                    ) : (
                      <>
                        <Image
                          src={commonFunctions.concatenateImageWithAPIUrl(
                            productsList?.length > 0 && productsList[0]?.Image
                          )}
                          size="tiny"
                          bordered
                        />
                      </>
                    )}
                  </Table.Cell>
                  {seasonPassEdit?.seasonPassEdit ? (
                    <Table.Cell
                      title={
                        productsList &&
                        productsList?.length > 0 &&
                        productsList[0]?.productname
                      }
                      className="textOverflowHidden"
                    >
                      <Header as="h3">
                        {productsList &&
                          productsList?.length > 0 &&
                          productsList[0]?.productname}
                      </Header>
                    </Table.Cell>
                  ) : (
                    <Table.Cell
                      title={productCategoryDetail?.name}
                      className="textOverflowHidden"
                    >
                      <Header as="h3">{productCategoryDetail?.name}</Header>
                    </Table.Cell>
                  )}

                  <Table.Cell>
                    <Header as="h3">
                      {productsList?.length - selectedProducts.length}
                    </Header>
                  </Table.Cell>

                  <Table.Cell>
                    <Header as="h3" className="deepviolet-color">
                      Inventory
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Input
                      value={selectedProducts.length}
                      onChange={this.onChangeHandler}
                      //  disabled={true}
                      maxLength={3}
                      className="selecteed"
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Header as="h3" className="deepviolet-color">
                      Selected
                    </Header>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Grid.Column>
          <Grid.Column computer={16} tablet={16} verticalAlign="center">
            <div className="seasonPassFromCard">
              {seasonPassDetail?.length > 0 &&
                seasonPassDetail.map((seasonPassObj, seasonPassIndex) => {
                  return (
                    <>
                      <div class="ui card newOrderFromCard">
                        <div class="content">
                          <div
                            className="closeIcon"
                            onClick={() =>
                              this.handleRemove(seasonPassObj, seasonPassIndex)
                            }
                          ></div>
                          <div class="season-pass-obj-meta">
                            {seasonPassObj["customFields"]?.map(
                              (cutomObjField, customFieldIndex) => {
                                return (
                                  <Form>
                                    <Form.Field class="ui input">
                                      <Input
                                        placeholder={
                                          cutomObjField.customDescriptions
                                        }
                                        name={`${cutomObjField.customDescriptions} ${seasonPassIndex}`}
                                        id={cutomObjField.customFieldId}
                                        value={cutomObjField.customFieldAnswer}
                                        fluid
                                        onChange={(event, { value }) =>
                                          this.handleChange(
                                            event,
                                            { value },
                                            seasonPassIndex,
                                            customFieldIndex
                                          )
                                        }
                                        error={
                                          cutomObjField.customRequired
                                            ? this.validator.message(
                                                "text",
                                                cutomObjField.customFieldAnswer,
                                                "required"
                                              )
                                            : ""
                                        }
                                      />
                                      {cutomObjField.customRequired && (
                                        <span className="estrickSign">*</span>
                                      )}
                                    </Form.Field>
                                  </Form>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
          </Grid.Column>

          {!seasonPassEdit?.seasonPassEdit ? (
            <>
              <Grid.Column width={16} textAlign="right">
                <Button
                  className="blue-btn"
                  onClick={this.props?.onBackToSeasonPassList}
                >
                  Back
                </Button>
                <Button
                  className="orange-btn"
                  disabled={selectedProducts.length === 0 ? true : false}
                  onClick={(e) => this.handleNext(e)}
                >
                  Continue
                </Button>
              </Grid.Column>
            </>
          ) : seasonPassEdit?.seasonPassEdit ? (
            <>
              <Grid.Column width={16} textAlign="right">
                <Button className="blue-btn" onClick={this.onCancelBtnCLick}>
                  Cancel
                </Button>
                <Button
                  className="orange-btn"
                  disabled={selectedProducts.length === 0 ? true : false}
                  onClick={() => this.onUpdateRentalDetail(selectedProducts)}
                >
                  Update
                </Button>
              </Grid.Column>
            </>
          ) : null}
        </Grid>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
    orderState: state.orderState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
      storeInventoryDetail: bindActionCreators(
        actions.storeInventoryDetail,
        dispatch
      ),
      getOrderItems: bindActionCreators(actions.getOrderItems, dispatch),
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SeasonPassCustomFields);
