import React, { Component } from "react";
import { Button, Header, Modal, Grid, Form } from "semantic-ui-react";
import { connect, bindActionCreators, actions, commonFunctions, Notifications } from "../../../../../shared/functional/global-import";
import { withRouter } from "react-router-dom";

class UpdateStockModal extends Component {
  constructor(props) {
    super(props);
    this.state = { quantity: 0 }
  }
  onHandleChange = (e, { name, value, type, checked, data, }) => {
    var valueString = value
    var value = parseInt(valueString, 0)
    var quantity = commonFunctions.onHandleChange(e, { name, value, type, checked, data }, this.state);
    this.setState({ quantity: value })
  };
  UpdateStockDetail = (e) => {
    const businessLocationId = parseInt(this.props.global.locationSelected.BusinessLocationId, 0)
    this.props.actions.apiCall({
      urls: ["UPDATEPRODUCTSTOCKITEM"], method: "PUT", data: {
        productId: this.props.globalSearch ?
          this.props.globalSearch.productId : this.props.global.inventoryDetail.productId,
        businessCategoryType: this.props.globalSearch ? this.props.globalSearch.businessCategoryId :
          this.props.global.inventoryDetail.businessCategoryId, businessLocationId: businessLocationId,
        quantity: this.state.quantity, actionperformedBy: this.props.auth.userDetail.emailId
      },
      onSuccess: (response) => {
        
        this.props.closeModal()
        if (this.props.type === "Season Pass") {
          this.props.getSeasonDetailList();
        }
        else {
          this.props.getProductDetailList()
        }
        this.props.getProductStockItem()
        this.setState({ quantity: 0 })
      }, showNotification: true
    });
  }

  render() {
    const { api } = this.props;
    return (
      <Modal
        open={this.props.openModal}
        closeIcon
        onClose={this.props.closeModal}
        size={"mini"}
      >
        <Header content="Create Stock Items" />
        <Modal.Content>
          <Grid>
            <Grid.Column width={8} textAlign="center">
              <Form.Input type="number" max="2000"
                value={this.state.quantity}
                data="quantity"
                name="quantity" fluid className="bordered-input text-center-input"
                onChange={this.onHandleChange} />
            </Grid.Column>
            <Grid.Column width={8}>
              {this.state.quantity > 0 ?
                <Button className="orange-btn" fluid loading={api.isApiLoading} onClick={this.UpdateStockDetail}>
                  Continue
                </Button> : <Button className="orange-btn" fluid loading={api.isApiLoading} >
                  Continue
                </Button>}
            </Grid.Column>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch)
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UpdateStockModal));
