import React, { Component } from "react";
import { Button, Grid, Header, Form, Checkbox, Tab, Icon } from "semantic-ui-react";
import TimeFrameDuration from "./time-frame-duration";
import { connect, bindActionCreators, actions, Notifications, commonFunctions, withRouter } from "../../../shared/functional/global-import";
import { GlobalCodeSelect, CustomTimeFrameModal, CommonDeleteUpdateModal, DateFormatInput } from "../../../shared/components";
import TimeFrameList from "./time-frame-list";
import { GlobalCodeRadioGroup } from "../../../shared/components";
import { timeTableOptions } from "../../../config/time-options/time.config";

class TimeFrame extends Component {
  child = React.createRef();
  get initialState() {
    return {
      timeFrameForm: {
        customTimeFirst: "", customTimeThree: "", customTimeTwo: "",
        timeFrameId: "",
        businessId: this.props.global.bussinessDetail.businessId, businessLocationId: this.props.global.locationSelected.BusinessLocationId, timeFrameTitle: "",
        //pageNo: 1, pageSize: 100, searchValue: "", 
        sunday: true, monday: true, tuesday: true, wednesday: true, thursday: true, friday: true, saturday: true,
        //sortOrder: "desc",
        // sortingArrow: "sort",
        // sortColumn: "",
        // hasMore: true,
        // gridDataLoading: false,
        timeFrameEvent: "", timeRangeFrom: null, specificTime: [], eventStartDate: null, eventEndDate: null, timeRangeTo: null, hourFrom: "", minuteFrom: "", hourTo: "", minuteTo: "", AmFrom: "", AmTo: "", hours24: false, actionPerformedBy: this.props.auth.userDetail.emailId, customTimeStatus: false,
        notification: false,
        timeRangeType: true,
        timeRange: commonFunctions.getGlobalCodeDetails(this.props.global.codes, "TimeFrameRange", "24 Hours").globalCodeId,
      },
      specificTimeObj: {
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId: this.props.global.locationSelected.BusinessLocationId,
        customTime: "",
        actionPerformedBy: this.props.auth.userDetail.emailId
      }, deletetimeFrameForm: {
        timeFrameId: "",
        actionPerformedBy: this.props.auth.userDetail.emailId
      },
      displayButton: "Save",
      isTimeFrameFormLoading: false,
      update: false,
      editSpecificTime: []
    };
  }
  constructor(props) {
    super(props);
    this.state = {
      ...this.initialState,
      timeFrameDetail: [],
      customTimeList: [],
      customFields: false,
      customFieldsButton: true,
      pricingtoggle: false,
      date: "02/02/2021",
      selectDate: "",
      monthDate: "",
      monthDate2: "",
      yearDate: "",
      datesRange: "",
      selectDuration: "",
      selectTime: "",
      isCommonDeleteUpdateModalOpen: false,
      updateButton: false,
      activeIndex: 0,
      timeSlots: false,
      gridDetail: [],
      buttonStatus: false
    };
    this.validator = commonFunctions.initializeSimpleValidator();
    this.validatorDate = commonFunctions.initializeSimpleValidator();
  }

  openCustomTimeModal = () => {
    this.setState({
      customTimeStatus: !this.state.customTimeStatus,
    });
    // this.getCustomTime();
  };

  componentDidMount() {
    this.getCustomTime();
  }
  onDeleteCustomTime = (time, index) => {

    if (time.customTimeId) {
      this.props.actions.apiCall({
        urls: ["DELETECUSTOMTIME"], method: "DELETE", data: { CustomTimeId: time.customTimeId, ActionPerformedBy: this.props.auth.userDetail.emailId },
        showNotification: true,
        onSuccess: () => {
          this.getCustomTime();
          // this.setState({ onDepositDeleteClick: false, messageToShow: "", rentalDepositOrderDetailId: "", });
          // this.props.actions.getOrderItems(this.props.orderState.currentOrder.orderId);
          // return response;
        },
      });
    }
    if (time.customTimeId === null) {
      const { editSpecificTime, timeFrameForm } = this.state
      const { specificTime } = this.state.timeFrameForm
      let filterEditSpecificTime = editSpecificTime.filter(item => item !== time)
      this.props.actions.showNotification({ title: "success", message: 'Custom time deleted successfully', position: 'br', autoDismiss: commonFunctions.notificationTime() }, "success");
      let filterSpecificTime = specificTime.filter(item => item !== time.customTime)
      this.state.timeFrameForm.specificTime = filterSpecificTime
      this.setState({ timeFrameForm, editSpecificTime: filterEditSpecificTime })
    }
  }
  postTimeFrame = (e) => {

    const { timeFrameForm } = this.state
    if (timeFrameForm.timeRangeFrom !== null) {
      this.validator = commonFunctions.initializeSimpleValidator();
    }
    if (timeFrameForm.hourFrom && timeFrameForm.minuteFrom && timeFrameForm.AmFrom && timeFrameForm.hourTo && timeFrameForm.minuteTo && timeFrameForm.AmTo) {
      timeFrameForm.timeRangeFrom = timeFrameForm.hourFrom + ":" + timeFrameForm.minuteFrom + timeFrameForm.AmFrom
      timeFrameForm.timeRangeTo = timeFrameForm.hourTo + ":" + timeFrameForm.minuteTo + timeFrameForm.AmTo
      this.setState({ timeFrameForm });
    }

    if (timeFrameForm.sunday !== false || timeFrameForm.monday !== false || timeFrameForm.tuesday !== false || timeFrameForm.wednesday !== false || timeFrameForm.thursday !== false || timeFrameForm.friday !== false || timeFrameForm.saturday !== false) {
      timeFrameForm.notification = true
      this.setState({ timeFrameForm })
    }
    if (timeFrameForm.eventStartDate !== null && timeFrameForm.eventEndDate !== null) {
      timeFrameForm.eventStartDate = commonFunctions.saveFormattedDate(timeFrameForm["eventStartDate"]);
      timeFrameForm.eventEndDate = commonFunctions.saveFormattedDate(timeFrameForm["eventEndDate"]);
      this.setState({ timeFrameForm });
    }
    if (timeFrameForm.timeRangeFrom !== null) {
      this.validator = commonFunctions.initializeSimpleValidator();
    }

    var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
    if (isFormValid) {

      this.props.actions.apiCall({
        urls: ["ADDTIMEFRAME"], method: "POST", data: this.state.timeFrameForm, onSuccess: (response) => {

          this.setState((this.initialState), () => this.getTimeFrame())
          this.setState({ activeIndex: 0 })
        }, isFormData: true, showNotification: true
      });
      this.validator = commonFunctions.initializeSimpleValidator();
      if ((timeFrameForm.timeRangeFrom === ": " || timeFrameForm.timeRangeTo === ": ") && (!timeFrameForm.hours24)) {
        this.props.actions.showNotification({ title: "Error", message: 'Please select time', position: 'br', autoDismiss: commonFunctions.notificationTime() }, "error");
      }
    }
  }
  getCustomTime() {
    this.setState({ updateButton: false });
    this.props.actions.apiCall({
      urls: ["GETCUSTOMTIME"], method: "GET", data: {
        BusinessId: this.props.global.bussinessDetail.businessId, BusinessLocationId: this.props.global.locationSelected.BusinessLocationId
      }, onSuccess: (response) => {

        this.setState({ customTimeList: response });
      }
    });
  }
  getTimeFrame = () => {
    this.props.actions.apiCall({
      urls: ["GETTIMEFRAMEDETAIL"], method: "GET", data: this.state.timeFrameForm, onSuccess: (response) => {
        this.setState({ gridDetail: response })
      }, showNotification: false
    });
  }
  initialCustomTime = () => {
    const { timeFrameForm } = this.state
    timeFrameForm.customTimeFirst = ""
    timeFrameForm.customTimeTwo = ""
    timeFrameForm.customTimeThree = ""
    timeFrameForm.customTimeStatus = !this.state.customTimeStatus
    this.setState(timeFrameForm)
  }
  postSpecificTime = (e) => {
    const { timeFrameForm, specificTimeObj } = this.state
    specificTimeObj.customTime = timeFrameForm.customTimeFirst + ":" + timeFrameForm.customTimeTwo + timeFrameForm.customTimeThree
    this.setState({ specificTimeObj });
    // var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
    // e.preventDefault();
    if (this.validatorDate.allValid() === false) {
      this.validatorDate.showMessages();
      this.forceUpdate();

      this.setState({ update: true })
      return false;
    } else {
      this.props.actions.apiCall({
        urls: ["ADDCUSTOMTIME"], method: "POST", data: this.state.specificTimeObj, onSuccess: (response) => {
          this.initialCustomTime()
          this.getCustomTime()
        }, showNotification: true
      });
      this.validatorDate = commonFunctions.initializeSimpleValidator();
    }
    // if (isFormValid) {
    // }
  }

  editTimeFrame = (timeFrame, index) => {
    this.setState(this.initialState)
    const { timeFrameForm } = this.state
    timeFrameForm.businessId = timeFrame.businessId
    timeFrameForm.timeFrameId = timeFrame.timeFrameId
    timeFrameForm.businessLocationId = timeFrame.businessLocationId
    // timeFrameForm.timeFrameEvent = timeFrame.timeFrameEvent
    timeFrameForm.sunday = timeFrame.sunday
    timeFrameForm.monday = timeFrame.monday
    timeFrameForm.tuesday = timeFrame.tuesday
    timeFrameForm.wednesday = timeFrame.wednesday
    timeFrameForm.thursday = timeFrame.thursday
    timeFrameForm.friday = timeFrame.friday
    timeFrameForm.saturday = timeFrame.saturday

    timeFrameForm.timeRangeFrom = timeFrame.timeRangeFrom;
    timeFrameForm.timeRangeTo = timeFrame.timeRangeTo;


    if (timeFrame.timeFrameTitle === "All (Season)" || timeFrame.timeFrameEvent === "timeFrame.timeFrameEvent" || timeFrameForm.timeRange === "timeFrame.timeRange" || timeFrame.timeRangeType === "timeFrame.timeRangeType") {
      this.props.actions.showNotification({ title: "Error", message: 'All season time frame is system defined, access denied for update.', position: 'br', autoDismiss: commonFunctions.notificationTime() }, "error");
    }
    else {
      timeFrameForm.timeFrameTitle = timeFrame.timeFrameTitle
      timeFrameForm.timeFrameEvent = timeFrame.timeFrameEvent
      timeFrameForm.timeRange = timeFrame.timeRange
      timeFrameForm.timeRangeType = timeFrame.timeRangeType
    }
    if (timeFrame.specificTime) {
      const parsedSpecificTime = JSON.parse(timeFrame.specificTime)
      let deletedSpecificTime = [];
      parsedSpecificTime.map((time) => {
        let isContainData = this.state.customTimeList.some((item) => item.customTime === time);
        if (!isContainData) {
          deletedSpecificTime.push({ businessId: null, businessLocationId: null, customTime: time, customTimeId: null });
        }
      });
      this.setState({ editSpecificTime: deletedSpecificTime });
    }
    if (timeFrameForm.timeRange === commonFunctions.getGlobalCodeDetails(this.props.global.codes, "TimeFrameRange", "Time Slots").globalCodeId) {
      this.setState({ activeIndex: 1 })
      timeFrameForm.specificTime = JSON.parse(timeFrame.specificTime)
    }
    if (timeFrameForm.timeRange !== commonFunctions.getGlobalCodeDetails(this.props.global.codes, "TimeFrameRange", "Time Slots").globalCodeId) {
      const { timeFrameForm } = this.state
      timeFrameForm.specificTime = []
      this.setState({ activeIndex: 0 })
      this.setState({ timeFrameForm })
    }
    if (timeFrame.timeRangeFrom) {
      const getHour = timeFrame.timeRangeFrom
      const splitHour = getHour.split(':')
      const splitminute = splitHour[1].split(' ')
      timeFrameForm.hourFrom = splitHour[0]
      timeFrameForm.minuteFrom = splitminute[0]
      timeFrameForm.AmFrom = splitminute[1]
    }
    if (timeFrame.timeRangeTo) {
      const getHourTo = timeFrame.timeRangeTo
      const splitGetHourTo = getHourTo.split(':')
      const splitminuteTo = splitGetHourTo[1].split(' ')
      timeFrameForm.hourTo = splitGetHourTo[0]
      timeFrameForm.minuteTo = splitminuteTo[0]
      timeFrameForm.AmTo = splitminuteTo[1]
    }
    if (timeFrame.eventStartDate !== null && timeFrame.eventEndDate !== null) {
      timeFrameForm.eventStartDate = commonFunctions.formattedDate(timeFrame.eventStartDate)
      timeFrameForm.eventEndDate = commonFunctions.formattedDate(timeFrame.eventEndDate)
    }
    if (timeFrame.timeFrameEvent)
      this.setState({ timeFrameForm, displayButton: "Update" })

    if (timeFrame.timeFrameTitle === "All (Season)") {
      this.setState({ displayButton: "Save" })
      this.setState(this.initialState)
    }
  }
  // }
  updateCustomTimeFrame = (e) => {
    var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
    if (isFormValid) {
      const { timeFrameForm } = this.state
      if (timeFrameForm.hourFrom && timeFrameForm.hourTo && timeFrameForm.minuteFrom && timeFrameForm.minuteTo && timeFrameForm.AmFrom && timeFrameForm.AmTo) {
        timeFrameForm.timeRangeFrom = timeFrameForm.hourFrom + ":" + timeFrameForm.minuteFrom + timeFrameForm.AmFrom
        timeFrameForm.timeRangeTo = timeFrameForm.hourTo + ":" + timeFrameForm.minuteTo + timeFrameForm.AmTo
        this.setState({ timeFrameForm });
      }
      if (timeFrameForm.eventStartDate !== null && timeFrameForm.eventEndDate !== null) {
        timeFrameForm.eventStartDate = commonFunctions.saveFormattedDate(timeFrameForm.eventStartDate)
        timeFrameForm.eventEndDate = commonFunctions.saveFormattedDate(timeFrameForm.eventEndDate)
        this.setState({ timeFrameForm });
      }
      if (timeFrameForm.timeFrameEvent !== commonFunctions.getGlobalCodeDetails(this.props.global.codes, "TimeFrameEvent", "Week").globalCodeId) {
        timeFrameForm.sunday = false
        timeFrameForm.monday = false
        timeFrameForm.tuesday = false
        timeFrameForm.wednesday = false
        timeFrameForm.thursday = false
        timeFrameForm.friday = false
        timeFrameForm.saturday = false
        this.setState({ timeFrameForm });
      }
      if (timeFrameForm.timeFrameEvent !== commonFunctions.getGlobalCodeDetails(this.props.global.codes, "TimeFrameEvent", "DateRange").globalCodeId) {
        timeFrameForm.eventStartDate = null
        timeFrameForm.eventEndDate = null
        this.setState({ timeFrameForm });
      }
      if (this.state.activeIndex === 1) {
        timeFrameForm.timeRangeFrom = null
        timeFrameForm.timeRangeTo = null
        this.setState({ timeFrameForm });
      }
      var data =
      {
        businessId: timeFrameForm.businessId,
        timeFrameId: timeFrameForm.timeFrameId,
        businessLocationId: timeFrameForm.businessLocationId,
        timeFrameTitle: timeFrameForm.timeFrameTitle,
        timeFrameEvent: timeFrameForm.timeFrameEvent,
        sunday: timeFrameForm.sunday,
        monday: timeFrameForm.monday,
        tuesday: timeFrameForm.tuesday,
        wednesday: timeFrameForm.wednesday,
        thursday: timeFrameForm.thursday,
        friday: timeFrameForm.friday,
        saturday: timeFrameForm.saturday,
        timeRangeFrom: timeFrameForm.timeRangeFrom,
        timeRangeTo: timeFrameForm.timeRangeTo,
        eventStartDate: timeFrameForm.eventStartDate,
        eventEndDate: timeFrameForm.eventEndDate,
        specificTime: timeFrameForm.specificTime,
        timeRangeType: timeFrameForm.timeRangeType,
        timeRange: timeFrameForm.timeRange,
        actionPerformedBy: timeFrameForm.actionPerformedBy
      }
      this.props.actions.apiCall({
        urls: ["UPDATETIMEFRAME"], method: "PUT", data: data, onSuccess: (response) => {
          this.setState(this.initialState, this.props.closeModal)
          this.setState({ activeIndex: 0 }, this.getTimeFrame)
        }, isFormData: true, showNotification: true,
      });
    }
  }
  postCustomTime = (e) => {
    var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
    if (isFormValid) {
      this.props.actions.apiCall({
        urls: ["ADDCUSTOMTIME"], method: "POST", data: this.state.timeFrameForm, onSuccess: (response) => {
          this.setState((this.initialState), () => this.getTimeFrame())
        }, isFormData: true, showNotification: true, customTimeStatus: !this.state.customTimeStatus
      });
      this.validator = commonFunctions.initializeSimpleValidator();
    }
  }
  onHandleChange = (e, { name, value, type, checked, data }) => {
    var timeFrameForm = commonFunctions.onHandleChange(e, { name, value, type, checked, data }, this.state.timeFrameForm, this);
    this.setState({ timeFrameForm });
    if (timeFrameForm.hours24) {
      this.validator = commonFunctions.initializeSimpleValidator();
    }
    
    else if (timeFrameForm.hourFrom && timeFrameForm.minuteFrom && timeFrameForm.AmFrom && timeFrameForm.hourTo && timeFrameForm.minuteTo && timeFrameForm.AmTo) {
      timeFrameForm.timeRangeFrom = timeFrameForm.hourFrom + ":" + timeFrameForm.minuteFrom + timeFrameForm.AmFrom
      timeFrameForm.timeRangeTo = timeFrameForm.hourTo + ":" + timeFrameForm.minuteTo + timeFrameForm.AmTo
      this.setState({ timeFrameForm });
    }
  };

  onHandleCustomTime = (time) => {

    const { timeFrameForm } = this.state
    const itemIndex = timeFrameForm.specificTime.findIndex(item => item === time.customTime);

    if (itemIndex > -1) {
      timeFrameForm.specificTime.splice(itemIndex, 1);
    }
    else {
      timeFrameForm.specificTime.push(time.customTime);
    }
    var listofdata = timeFrameForm.specificTime
    timeFrameForm.specificTime = listofdata
    this.setState({
      timeFrameForm
    })
  }
  handleTabChange = (e, { activeIndex }) => {

    const { timeFrameForm } = this.state
    this.setState({ activeIndex })
    if (activeIndex === 0) {
      this.clearSpecificTime()
      timeFrameForm.timeRangeType = true
      timeFrameForm.timeRange = commonFunctions.getGlobalCodeDetails(this.props.global.codes, "TimeFrameRange", "24 Hours").globalCodeId
      this.setState({
        timeFrameForm
      });
    }
    if (activeIndex === 1) {
      this.clearManualTime()
      timeFrameForm.timeRangeType = false
      timeFrameForm.timeRange = commonFunctions.getGlobalCodeDetails(this.props.global.codes, "TimeFrameRange", "Time Slots").globalCodeId
      this.setState({
        timeFrameForm
      });
    }
  }
  clearManualTime = () => {
    this.validator = commonFunctions.initializeSimpleValidator();
    const { timeFrameForm } = this.state
    timeFrameForm.hourFrom = ""
    timeFrameForm.minuteFrom = ""
    timeFrameForm.AmFrom = ""
    timeFrameForm.hourTo = ""
    timeFrameForm.minuteTo = ""
    timeFrameForm.AmTo = ""
    timeFrameForm.timeRange = null
    this.setState({ timeFrameForm })
  }
  clearSpecificTime = () => {
    this.validator = commonFunctions.initializeSimpleValidator();
    const { timeFrameForm } = this.state
    timeFrameForm.specificTime = []
    this.setState({ timeFrameForm })
  }
  onCancelClick = () => {
    this.setState(this.initialState);
  }
  onhandleSubmit = (e) => {
    
    const { timeFrameForm } = this.state
    if (this.state.activeIndex === 1 && this.state.timeFrameForm.specificTime.length === 0) {
      this.props.actions.showNotification({ title: "Error", message: 'Please select atleast one slot.', position: 'br', autoDismiss: commonFunctions.notificationTime() }, "error");
      return false
    }
    if (timeFrameForm.timeFrameEvent === commonFunctions.getGlobalCodeDetails(this.props.global.codes, "TimeFrameEvent", "Week").globalCodeId && timeFrameForm.sunday === false && timeFrameForm.monday === false && timeFrameForm.tuesday === false && timeFrameForm.wednesday === false && timeFrameForm.thursday === false && timeFrameForm.friday === false && timeFrameForm.saturday === false) {
      this.props.actions.showNotification({ title: "Error", message: 'Please select at least one day', position: 'br', autoDismiss: commonFunctions.notificationTime() }, "error");
      return false
    }
    else {
      commonFunctions.onHandleSubmit(e, this, "isTimeFrameFormLoading", "timeFrameForm", "ADDTIMEFRAME", "UPDATETIMEFRAME")
    }
    // this.props.closeModal();
  }
 

  render() {
    const { customTimeStatus, customTimeList, activeIndex, customdurationStatus, isTimeFrameFormLoading, timeFrameForm, isCommonDeleteUpdateModalOpen, messageToShow, buttonName, update, displayButton, buttonStatus } = this.state;
    const fullState = this.state;
    const allFunction = this;

    const panes = [
      {
        menuItem: "Time Range",
        render: () => (
          <Tab.Pane attached={false}>
            <Form className="pt-3">
              <Grid>
                <GlobalCodeRadioGroup
                  name="ddRadioGroup"
                  data="timeRange"
                  showRadioButtons={["24 Hours", "Shop Hours", "Custom Time"]}
                  categoryType="TimeFrameRange"
                  onChange={this.onHandleChange}
                  value={timeFrameForm.timeRange}
                />

              </Grid>
              {timeFrameForm.timeRange === commonFunctions.getGlobalCodeDetails(this.props.global.codes, "TimeFrameRange", "Custom Time").globalCodeId &&
                <Grid>
                  <Grid.Column width={14}>
                    <Grid>
                      <Grid.Column width={3} verticalAlign="middle">
                        <p>From </p>
                      </Grid.Column>
                      <Grid.Column width={3}>
                        <Form.Dropdown
                          options={timeTableOptions.timeHours}
                          placeholder="Hour"
                          className="custom-select"
                          name="hourFrom"
                          data="hourFrom"
                          selection
                          type="select"
                          onChange={this.onHandleChange}
                          value={timeFrameForm.hourFrom}
                          error={this.validator.message("hourFrom", timeFrameForm.hourFrom, "required")}
                          fluid
                        />
                      </Grid.Column>
                      <Grid.Column width={3}>
                        <Form.Dropdown
                          options={timeTableOptions.timeMinutes}
                          selection
                          placeholder="Minute"
                          className="custom-select"
                          type="select"
                          name="minuteFrom"
                          data="minuteFrom"
                          onChange={this.onHandleChange}
                          value={timeFrameForm.minuteFrom}
                          error={this.validator.message("minuteFrom", timeFrameForm.minuteFrom, "required")}
                          fluid
                        />
                      </Grid.Column>
                      <Grid.Column width={3}>
                        <Form.Dropdown
                          options={timeTableOptions.timeMeridian}
                          selection
                          placeholder="AM/PM"
                          type="select"
                          className="custom-select"
                          name="AmFrom"
                          data="AmFrom"
                          onChange={this.onHandleChange}
                          value={timeFrameForm.AmFrom}
                          error={this.validator.message("AmFrom", timeFrameForm.AmFrom, "required")}
                          fluid
                        />
                      </Grid.Column>

                    </Grid>
                    <Grid>
                      <Grid.Column width={3} verticalAlign="middle">
                        <p>To</p>
                      </Grid.Column>
                      <Grid.Column width={3}>
                        <Form.Dropdown
                          options={timeTableOptions.timeHours}
                          selection
                          placeholder="Hour"
                          type="select"
                          className="custom-select"
                          name="hourTo"
                          data="hourTo"
                          onChange={this.onHandleChange}
                          value={timeFrameForm.hourTo}
                          error={this.validator.message("hourTo", timeFrameForm.hourTo, "required")}
                          fluid
                        />
                      </Grid.Column>
                      <Grid.Column width={3}>
                        <Form.Dropdown
                          options={timeTableOptions.timeMinutes}
                          selection
                          placeholder="Minute"
                          type="select"
                          className="custom-select"
                          name="minuteTo"
                          data="minuteTo"
                          onChange={this.onHandleChange}
                          value={timeFrameForm.minuteTo}
                          error={this.validator.message("minuteTo", timeFrameForm.minuteTo, "required")}
                          fluid
                        />
                      </Grid.Column>
                      <Grid.Column width={3}>
                        <Form.Dropdown
                          options={timeTableOptions.timeMeridian}
                          selection
                          placeholder="AM/PM"
                          type="select"
                          className="custom-select"
                          name="AmTo"
                          data="AmTo"
                          onChange={this.onHandleChange}
                          value={timeFrameForm.AmTo}
                          error={this.validator.message("AmTo", timeFrameForm.AmTo, "required")}
                          fluid
                        />
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>

                </Grid>

              }
            </Form>
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Specific Times",
        render: () => (
          <Tab.Pane attached={false}>
            <Grid>
              <Grid.Column width={16}>
                {[...this.state.editSpecificTime, ...customTimeList].map((time, index) =>
                  <div className="timesButton">
                    <Button
                      name="customTime"
                      data="customTime"
                      type="select"
                      value={timeFrameForm.specificTime}
                      basic
                      active={timeFrameForm.specificTime && timeFrameForm.specificTime.indexOf(time.customTime) > -1}
                      className="select-btn mbt 5"
                      onClick={() => this.onHandleCustomTime(time, "customTime")}
                    >
                      {time.customTime}
                    </Button>
                    <Icon name="close" onClick={() => this.onDeleteCustomTime(time, index)} />
                  </div>
                )}
                <Button
                  className="orange-button mbt"
                  icon
                  onClick={this.openCustomTimeModal}
                >
                  <Icon name="plus" /> Custom Time
                </Button>
              </Grid.Column>
            </Grid>
          </Tab.Pane>
        ),
      },
    ];
    return (
      <>
        <Form>
          <Grid>
            <Grid.Column width={16}>
              <Form.Field>
                <Form.Input placeholder="Title" name="txttimeFrameTitle" textAlign="left"
                  data="timeFrameTitle" onChange={this.onHandleChange} value={timeFrameForm.timeFrameTitle}
                  error={this.validator.message("timeFrameTitle", timeFrameForm.timeFrameTitle, "required")} fluid />
              </Form.Field>
            </Grid.Column>
            <Grid.Column width={16}>
              <Header as="h4">Time Range</Header>
            </Grid.Column>
            <Grid.Column width={16} verticalAlign="middle">
              <GlobalCodeSelect
                placeholder="Select One"
                name="ddtimeFrameEvent"
                data="timeFrameEvent"
                categoryType="TimeFrameEvent"
                value={timeFrameForm.timeFrameEvent}
                error={this.validator.message("timeFrameEvent", timeFrameForm.timeFrameEvent, "required")}
                onChange={this.onHandleChange} />
            </Grid.Column>
            {timeFrameForm.timeFrameEvent === commonFunctions.getGlobalCodeDetails(this.props.global.codes, "TimeFrameEvent", "DateRange").globalCodeId && (
              <>
                <Grid.Column width={4}>
                  <DateFormatInput
                    fluid
                    name="eventStartDate"
                    data="eventStartDate"
                    placeholder="Start date"
                    value={timeFrameForm.eventStartDate !== null ? commonFunctions.formattedDate(timeFrameForm.eventStartDate) : ""}
                    icon="false"
                    iconPosition="right"
                    error={this.validator.message("eventStartDate", timeFrameForm.eventStartDate, "required")}
                    onChange={this.onHandleChange} />
                </Grid.Column>
                <Grid.Column width={4}>
                  <DateFormatInput name="txteventEndDate" placeholder="End date" data="eventEndDate" type="text"
                    iconPosition="right" onChange={this.onHandleChange}
                    error={this.validator.message("eventEndDate", timeFrameForm.eventEndDate, "required")}
                    value={timeFrameForm.eventEndDate !== null ? commonFunctions.formattedDate(timeFrameForm.eventEndDate) : ""}
                  />
                </Grid.Column>
              </>
            )}

            {timeFrameForm.timeFrameEvent === commonFunctions.getGlobalCodeDetails(this.props.global.codes, "TimeFrameEvent", "Week").globalCodeId && (<>
              <Grid.Column width={16}>
                <Header as="h4">Repeat on</Header>
                <Checkbox label="S" className="selected-checkbox" onChange={this.onHandleChange} data="sunday" checked={timeFrameForm.sunday} value={timeFrameForm.sunday} error={this.validator.message("timeFrameForm", timeFrameForm.sunday, "required")} />
                <Checkbox label="M" className="selected-checkbox" onChange={this.onHandleChange} data="monday" checked={timeFrameForm.monday} value={timeFrameForm.monday} />
                <Checkbox label="T" className="selected-checkbox" onChange={this.onHandleChange} data="tuesday" checked={timeFrameForm.tuesday} value={timeFrameForm.tuesday} />
                <Checkbox label="W" className="selected-checkbox" onChange={this.onHandleChange} data="wednesday" checked={timeFrameForm.wednesday} value={timeFrameForm.wednesday} />
                <Checkbox label="T" className="selected-checkbox" onChange={this.onHandleChange} data="thursday" checked={timeFrameForm.thursday} value={timeFrameForm.thursday} />
                <Checkbox label="F" className="selected-checkbox" onChange={this.onHandleChange} data="friday" checked={timeFrameForm.friday} value={timeFrameForm.friday} />
                <Checkbox label="S" className="selected-checkbox" onChange={this.onHandleChange} data="saturday" checked={timeFrameForm.saturday} value={timeFrameForm.saturday} />
              </Grid.Column> </>)}
            <Grid.Column width={16}>
              <Header as="h4">Select Time Range</Header>
              <Tab
                menu={{ secondary: true }}
                panes={panes}
                activeIndex={activeIndex}
                onTabChange={this.handleTabChange}
                className="availablity-tab"
              />
            </Grid.Column>

          </Grid>
          <Grid reversed='computer tablet'>
            <Grid.Column mobile={8} tablet={8} computer={8} textAlign="right">
              {/* <Button className="blue-btn" onClick={this.onCancelClick}>Cancel</Button>
                {(timeFrameForm.timeFrameId > 0 && timeFrameForm.timeFrameEvent) ? <Button
                  className="orange-btn"
                  loading={isTimeFrameFormLoading}
                  onClick={this.updateCustomTimeFrame}
                >Update</Button> :
                  <Button
                    className="orange-btn"
                    loading={isTimeFrameFormLoading}
                    onClick={this.postTimeFrame}
                  >Save</Button>} */}

              <Grid.Column mobile={8} tablet={8} computer={8} textAlign="right">
                <Button className="blue-btn" onClick={() => commonFunctions.onCancelInitialState(allFunction)}>Clear</Button>
                <Button disabled={buttonStatus} className="orange-btn" loading={isTimeFrameFormLoading}
                  onClick={this.onhandleSubmit}
                >{displayButton}</Button>
              </Grid.Column>
            </Grid.Column>
            {this.props.showGrid && <TimeFrameList ref={this.child} fullState={fullState} allFunction={allFunction} />}
          </Grid>
        </Form>
        <CustomTimeFrameModal
          openModal={customTimeStatus}
          closeModal={this.openCustomTimeModal}
          customTimeFirst={timeFrameForm.customTimeFirst}
          customTimeTwo={timeFrameForm.customTimeTwo}
          customTimeThree={timeFrameForm.customTimeThree}
          onChange={this.onHandleChange}
          postSpecificTime={this.postSpecificTime}
          update={update}
          validator={this.validatorDate}
        />
        <TimeFrameDuration
          openModal={customdurationStatus}
          closeModal={this.CustomDuration}
          postCustomTime={this.postCustomTime}
        />
        <CommonDeleteUpdateModal
          isModalOpen={isCommonDeleteUpdateModalOpen}
          onConfirm={() => commonFunctions.onConfirmFunction(allFunction, "timeFrameId", "gridDetail", "DELETETIMEFRAME", "UPDATETIMEFRAMETOGGLE")}
          onClose={() => commonFunctions.onCloseDeleteUpdateModal(allFunction, "timeFrameId")}
          confirmationButtonText={buttonName}
          header={messageToShow}
          cancelButtonText={'Cancel'}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
    warnUserState: state.warnUserState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeUserDetail: bindActionCreators(actions.storeUserDetail, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      storeLocationSelected: bindActionCreators(actions.storeLocationSelected, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
      storeWarnUser: bindActionCreators(actions.storeWarnUser, dispatch)
    }
  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TimeFrame));
