import React, { Component } from "react";
import { Button, Grid, Modal, Form, TextArea } from "semantic-ui-react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { connect, bindActionCreators, actions, commonFunctions } from "../../../../functional/global-import";
import { IntegrationImg } from "../../../../functional/global-image-import";
import HelpCenterSetting from "../../../../../pages/more-setting/help-center-setting"

class IntegrationQuestion extends Component {
    get initialState() {
        return {
            businessId: this.props.global.bussinessDetail.businessId,
            description: "",
            editorState: EditorState.createEmpty(),
            isContentLoading: false,
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            ...this.initialState,
            businessId: this.props.global.bussinessDetail.businessId,
            websiteDetailType: "",
        };
    }

    onEditorStateChange = (editorState) => {
        let { description } = this.state;
        description = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
        this.setState({ editorState, description });
    };

    // componentDidMount() {
    //     // this.setState({ websiteDetailType : this.props.id });
    //     switch (this.props.WidgetScreen) {
    //         case "About us screen":
    //             this.setState({ knowaboutscreen: "A" });
    //             break;
    //         case "Contact us screen":
    //             this.setState({ knowaboutscreen: "C" });
    //             break;
    //         case "Terms & Conditions screen":
    //             this.setState({ knowaboutscreen: "T" });
    //             break;
    //     }

    // }

    // POST API for Post Data
    postData = () => {
        this.props.actions.apiCall({
            urls: ["POSTINTEGRATION"], method: "POST",
            data: {
                businessId: this.props.global.bussinessDetail.businessId,
                integrationType: this.state.knowaboutscreen,
                description: this.state.description,
                actionPerformedBy: this.props.auth.userDetail.emailId,
            }, onSuccess: (response) => {
                if (response.responseCode == 200) {
                    this.onhandleClearForm();
                    // EditorState.createEmpty();
                    this.props.closeModal();
                }
            }, showNotification: true,
        });
    }

    onHandleChange = (e, { name, value, type, checked, data }) => {
        var description = commonFunctions.onHandleChange(e, { name, value, type, checked, data }, this.state.description, this);
        this.setState({ description: value });
    }

    onhandleClearForm = () => {
        this.setState({ description: "" });
    }

    render() {
        const { editorState, isContentLoading } = this.state;
        const { allFunction } = this;
        return (
            <Modal
                closeOnDimmerClick={false}
                open={this.props.openModal}
                closeIcon
                onClose={this.props.closeModal}
                size={"small"}>
                <Modal.Header>Add Text</Modal.Header>
                <Modal.Content scrolling>
                    <Grid>
                        <Grid.Column width={16}>
                            <HelpCenterSetting />
                        </Grid.Column>

                    </Grid>
                </Modal.Content>
            </Modal>
        );
    }
}
const mapStateToProps = state => {
    return {
        api: state.api,
        global: state.global,
        auth: state.auth
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            apiCall: bindActionCreators(actions.apiCall, dispatch),
            storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch)
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationQuestion);