import React, { Component } from "react";
import { Button, Modal, Form, Grid, Icon, Checkbox, Image, Dropdown } from "semantic-ui-react";
import { commonFunctions, connect, bindActionCreators, actions, Link, env } from "../../../../functional/global-import";
import RolesSelect from '../../../atoms/roles-select';
import LocationsSelect from '../../../atoms/locations-select';
import { PhoneNumberInput,CommonDeleteUpdateModal } from "../../../../components";
import 'react-phone-input-2/lib/style.css';
import CommonCropImage from "../../../atoms/common-crop-image";
// import { CommonDeleteUpdateModal } from '../../../../components'

class NewMemberModal extends Component {
  // Put all key & values that need to reset on form submit and reset form.
  get initialState() {
    return {
      newMemberModal: {
        applicationId: env.APPLICATION_ID,
        businessId: this.props.global.bussinessDetail.businessId, emailId: "",
        password: "", confirmPassword: "", passcode: "", confirmPasscode: "", firstName: "",
        lastName: "", phoneNumber: "", designation: "", userPossition: null, actionPerformedBy: this.props.auth.userDetail.emailId,
        roleId: "", userLocations: "", isActive: true, image: [], 
      },
      fileList: [],
      imageData: [],
      isCommonDeleteUpdateModalOpen: false,
    };
  }
  constructor(props) {
    super(props);
    this.state = { ...this.initialState, locationOption: [], userRoleDetails: [], UserimageId: "" };
    this.validator = commonFunctions.initializeSimpleValidator();
  }

  // This function is used to set handle change
  onHandleChange = (e, { name, value, type, checked, data }) => {
    var newMemberModal = commonFunctions.onHandleChange(e, { name, value, type, checked, data }, this.state.newMemberModal, this);
    this.setState({ newMemberModal });
  }

  // Phone Number Handle Change
  onHandlephoneNumber = (value, country, e, formattedValue) => {
    var obj = commonFunctions.onHandlephoneNumber(value, country, e, formattedValue, this.state.newMemberModal);
    this.setState({ obj });
  };

  // This function is used to set Form Submit
  onHandleSubmit = (e) => {
    var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
    if (isFormValid) {
      var formdata = commonFunctions.getFormData(this.state.newMemberModal);
      this.props.actions.apiCall({
        urls: ["ADDUSER"], method: "POST", data: formdata, onSuccess: (response) => {
          commonFunctions.onApiSucessFormChangedFalse(this);
          this.onCloseModal();
          this.props.getTeamDetails();
        }, isFormData: true, showNotification: true,
      });
    }
  }


  onCloseModal = () => {
    if (this.props.warnUserState.isFormChanged) {
      this.setState({ isCommonDeleteUpdateModalOpen: true })
      this.validator = commonFunctions.initializeSimpleValidator();
    }
    else {
      this.onModalCloseFormFalse();
    }
  }

  onModalCloseFormFalse = () => {
    this.props.closeModal();
    this.setState(this.initialState);
    this.validator = commonFunctions.initializeSimpleValidator();
  }


  onConfirmWarnUser = () => {
    this.setState({ isCommonDeleteUpdateModalOpen: false })
    commonFunctions.onApiSucessFormChangedFalse(this);
    this.onModalCloseFormFalse();
  }

   // this function is used to prevent Press Enter inside Input field  
   onEnterEventHandler = (e) => {
    if(e.key === 'Enter'){
      e.preventDefault();
    }
  }

  render() {
    const { newMemberModal, locationOption, userRoleDetails, isCommonDeleteUpdateModalOpen } = this.state;
    const maxNumber = 1;
    const { api } = this.props;
    return (
      <>
        <Modal open={this.props.openModal} closeIcon onClose={this.onCloseModal} closeOnDimmerClick={false}>
          <Modal.Header>Add New Member</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Form>
                <Grid>
                  <Grid.Column mobile={16} tablet={4} computer={4} textAlign="center">
                  <CommonCropImage
                  ImageType={"UserImage"}
                  fullState={this.state}
                  cropId={this.state.UserimageId}
                  StateRef={this}
                  isDefaultImage={false}
                  stateForm = {this.state.newMemberModal} />
                   </Grid.Column>

                  <Grid.Column width={12}>
                    <Grid>
                      <Grid.Column mobile={16} tablet={8} computer={8}>
                        <Form.Input fluid placeholder="First Name" name="txtFirstName" textAlign="left" data="firstName"
                          onChange={this.onHandleChange} value={newMemberModal.firstName} onKeyPress={this.onEnterEventHandler}
                          error={this.validator.message("firstName", newMemberModal.firstName, "required|firstName|min:0|max:100")}>
                        </Form.Input>
                      </Grid.Column>
                      <Grid.Column mobile={16} tablet={8} computer={8}>
                        <Form.Input fluid placeholder="Last Name" name="txtLastName" textAlign="left" onKeyPress={this.onEnterEventHandler}
                          error={this.validator.message("lastName", newMemberModal.lastName, "required|lastName|min:0|max:100")}
                          data="lastName" onChange={this.onHandleChange} value={newMemberModal.lastName}>
                        </Form.Input>
                      </Grid.Column>
                      <Grid.Column mobile={16} tablet={8} computer={8}>
                        <Form.Input fluid textAlign="left" placeholder="Email Address" maxLength="250" name="txtEmail" onKeyPress={this.onEnterEventHandler}
                          error={this.validator.message("emailId", newMemberModal.emailId, "required|email")} data="emailId"
                          onChange={this.onHandleChange} value={newMemberModal.emailId}>
                        </Form.Input>
                      </Grid.Column>
                      <Grid.Column className="controlField" mobile={16} tablet={8} computer={8}>
                        <PhoneNumberInput
                          onChange={(value, country, e, formattedValue) => this.onHandleChange(e, {
                            name: "phnphoneNumber",
                            value: formattedValue, type: "phoneNumber", data: "phoneNumber"
                          })} value={newMemberModal.phoneNumber}
                          // error={this.validator.message("phoneNumber", newMemberModal.phoneNumber, "required|phone")} 
                          />
                      </Grid.Column>
                      <Grid.Column mobile={16} tablet={8} computer={8}>
                        <Form.Input type="password" fluid textAlign="left" placeholder="Password" name="txtPassword"
                          data="password" onChange={this.onHandleChange} value={newMemberModal.password} onKeyPress={this.onEnterEventHandler}
                          error={this.validator.message('password', newMemberModal.password, 'required|min:6|max:20')}>
                        </Form.Input>
                      </Grid.Column>

                      <Grid.Column mobile={16} tablet={8} computer={8}>
                        <Form.Input type="password" fluid textAlign="left" placeholder="Confirm Password" name="txtConfirmPassword"
                          error={this.validator.message('confirmPassword', newMemberModal.confirmPassword,
                            `required|validConfirmPassword:${newMemberModal.password}`)} onKeyPress={this.onEnterEventHandler}
                          data="confirmPassword" onChange={this.onHandleChange} value={newMemberModal.confirmPassword}>
                        </Form.Input>
                      </Grid.Column>

                      <Grid.Column mobile={16} tablet={8} computer={8}>
                        <Form.Input type="password" fluid textAlign="left" placeholder="Passcode"
                          name="txtPasscode" onKeyPress={this.onEnterEventHandler} error={this.validator.message("passcode", newMemberModal.passcode,
                            "required|integer|min:6|max:6")} data="passcode" onChange={this.onHandleChange} value={newMemberModal.passcode}></Form.Input>
                      </Grid.Column>

                      <Grid.Column mobile={16} tablet={8} computer={8}>
                        <Form.Input type="password" fluid textAlign="left" placeholder="Confirm Passcode" name="txtConfirmPasscode"
                          error={this.validator.message('confirmPasscode', newMemberModal.confirmPasscode,
                            `required|validConfirmPasscode:${newMemberModal.passcode}`)} onKeyPress={this.onEnterEventHandler} data="confirmPasscode"
                          onChange={this.onHandleChange} value={newMemberModal.confirmPasscode}>
                        </Form.Input>
                      </Grid.Column>

                      <Grid.Column mobile={16} tablet={8} computer={8}>
                        <Form.Input fluid textAlign="left" placeholder="Title" name="txtDesignation" onKeyPress={this.onEnterEventHandler}
                          error={this.validator.message("designation", newMemberModal.designation, "required|designation|min:0|max:50")} data="designation"
                          onChange={this.onHandleChange} value={newMemberModal.designation}>
                        </Form.Input>
                      </Grid.Column>
                      <Grid.Column mobile={16} tablet={8} computer={8}>
                        <RolesSelect
                          placeholder="Select Role"
                          type="select"
                          options={this.props.userRoleDetails}
                          onChange={this.onHandleChange}
                          name="roleId" data="roleId"
                          value={newMemberModal.roleId}
                          error={this.validator.message("roleId", newMemberModal.roleId, "required")} />

                      </Grid.Column>
                      <Grid.Column mobile={16} tablet={8} computer={8}>
                        <LocationsSelect
                          placeholder='Select Location'
                          options={this.props.locationOption}
                          onChange={this.onHandleChange}
                          type="multiple-select"
                          name="userLocations" data="userLocations"
                          value={newMemberModal.userLocations}
                          error={this.validator.message("userLocations", newMemberModal.userLocations, "required")} />
                      </Grid.Column>

                      <Grid.Column mobile={16} tablet={8} computer={8}>
                        <Checkbox className="custom-toggle" textAlign="right"
                          checked={newMemberModal.isActive} value={newMemberModal.isActive}
                          data="isActive" name="isActive" onChange={this.onHandleChange} label="Active" />
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                </Grid>
              </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.onCloseModal} className="blue-btn">Cancel</Button>
            <Button loading={api.isApiLoading} onClick={this.onHandleSubmit} className="orange-btn">Save</Button>
          </Modal.Actions>
        </Modal>
        {isCommonDeleteUpdateModalOpen &&
          <CommonDeleteUpdateModal
            isModalOpen={isCommonDeleteUpdateModalOpen}
            onConfirm={this.onConfirmWarnUser}
            onClose={() => this.setState({ isCommonDeleteUpdateModalOpen: false })}
            confirmationButtonText={"Yes"}
            header={"Are you sure you want to discard your changes ?"}
            cancelButtonText={"Cancel"}
          />
        }
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    api: state.api,
    global: state.global,
    warnUserState: state.warnUserState,
    auth: state.auth
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      storeBussinessDetail: bindActionCreators(actions.storeBussinessDetail, dispatch),
      storeWarnUser: bindActionCreators(actions.storeWarnUser, dispatch),
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewMemberModal);