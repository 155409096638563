import React, { Component } from "react";
import {
  Grid,
  Form,
  Divider,
  Button,
  GridColumn,
  Checkbox,
} from "semantic-ui-react";
import AddCustomField from "../../../../pages/more-setting/pricing-taxes-setting/add-custom-field";
import { commonFunctions } from "../../../functional/global-import";
class CommonCustomField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customFieldStatus: false,
      localcustomFields: [],
    };
    this.validatorCustomfield = commonFunctions.initializeSimpleValidator();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.customFieldStatus !== this.state.customFieldStatus &&
      this.state.customFieldStatus
    ) {
      const customFiledClone = JSON.parse(
        JSON.stringify(
          this.props?.fullState[this.props?.stateForm][this.props?.variableName]
        )
      );
      this.setState({ localcustomFields: [...customFiledClone] });
    }
  }

  customFieldModal = () => {
    this.setState({ customFieldStatus: !this.state.customFieldStatus });
  };

  addClickCustomField = (e) => {
    const arr = [...this.state.localcustomFields];
    if (this.props.showRequired) {
      arr.push({
        customField: "",
        customDescriptions: "",
        customRequired: false,
      });
    } else {
      arr.push({ customField: "", customDescriptions: "" });
    }
    this.validatorCustomfield = commonFunctions.initializeSimpleValidator();
    this.setState({ localcustomFields: arr });
  };

  onHandleCustomField = (e, { value, id, data, checked, dataId }) => {
    const { localcustomFields } = this.state;
    if (data === "customRequired") {
      localcustomFields[dataId][data] = checked;
    } else {
      localcustomFields[id][data] = value;
    }
    const newArr = [...localcustomFields];
    this.setState({ localcustomFields: newArr });
  };
  onHandleCustomFieldRequired = (e, { data, checked, dataId }) => {
    const { fullState, allFunction, stateForm, variableName } = this.props;
    const customFieldData = [...fullState[stateForm][variableName]];
    customFieldData[dataId][data] = checked;
    fullState[stateForm][variableName] = customFieldData;
    allFunction.setState(fullState);
    commonFunctions.onFormChange(allFunction);
  };

  onHandleCustomFieldDescription = (e, { value, id, data }) => {
    const { fullState, allFunction, stateForm, variableName } = this.props;
    const customFieldData = [...fullState[stateForm][variableName]];
    customFieldData[id][data] = value;
    fullState[stateForm][variableName] = customFieldData;
    allFunction.setState(fullState);
    commonFunctions.onFormChange(allFunction);
  };
  onHandleAddCustomField = (closeModal) => {
    const { fullState, stateForm, allFunction, variableName } = this.props;
               
    if (this.validatorCustomfield.allValid() === false) {
      this.validatorCustomfield.showMessages();
      this.forceUpdate();
    } else {
      fullState[stateForm][variableName] = JSON.parse(
        JSON.stringify(this.state.localcustomFields)
      );
      allFunction.setState({ ...fullState });
      allFunction.props.actions.showNotification(
        {
          title: "Success",
          message: "Custom fields Added Successfully.",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "success"
      );
      closeModal();
    }
    commonFunctions.onFormChange(allFunction);
  };

  handleRemoveSpecificRow = (index) => {
    const { localcustomFields } = this.state;
    localcustomFields.splice(index, 1);
    this.setState({ localcustomFields: [...localcustomFields] });
    this.validatorCustomfield = commonFunctions.initializeSimpleValidator();
  };

  render() {
    const { fullState, stateForm, allFunction, variableName } = this.props;
               
    return (
      <React.Fragment>
        <GridColumn width={16}>
          <Divider />
        </GridColumn>
        <Grid.Column computer={4} tablet={5} mobile={16}>
          <Button className="orange-btn" onClick={this.customFieldModal}>
            {(fullState[stateForm][variableName] &&
              fullState[stateForm][variableName].length > 0) ||
            fullState[stateForm][variableName] === [] ||
            null
              ? "Update Custom Field"
              : "Add Custom Field"}
          </Button>
        </Grid.Column>
        <Grid.Column computer={12} tablet={11} mobile={16}>
          <div className="descriptionFieldCol">
            {fullState[stateForm][variableName] &&
              fullState[stateForm][variableName].length > 0 &&
              fullState[stateForm][variableName] &&
              fullState[stateForm][variableName]?.map((singleData, i) => {
                return (
                  <>
                    <Form key={i}>
                      <Form.Input
                        className="btm-margin-text-area"
                        fluid
                        placeholder={singleData.customField}
                        id={i}
                        data="customDescriptions"
                        onChange={this.onHandleCustomFieldDescription}
                        value={singleData.customDescriptions}
                        error={allFunction.validatorGeneral.message(
                          singleData.customField,
                          singleData.customDescriptions,
                          "min:0|max:2000"
                        )}
                        disabled={true}
                      />
                      {this.props.showRequired && (
                        <Checkbox
                          name="Required"
                          dataId={i}
                          data="customRequired"
                          checked={singleData.customRequired}
                          onChange={this.onHandleCustomFieldRequired}
                          label="Required"
                          className="custome-checkbox customRequiredField"
                        />
                      )}
                    </Form>
                  </>
                );
              })}
          </div>
        </Grid.Column>
        <AddCustomField
          openModal={this.state.customFieldStatus}
          closeModal={this.customFieldModal}
          onHandleCustomField={this.onHandleCustomField}
          addClickCustomField={this.addClickCustomField}
          addCustomFields={this.state.localcustomFields}
          handleRemoveSpecificRow={this.handleRemoveSpecificRow}
          onHandleAddCustomField={this.onHandleAddCustomField}
          validatorCustomfield={this.validatorCustomfield}
          showRequired={this.props.showRequired}
        />
      </React.Fragment>
    );
  }
}

export default CommonCustomField;
