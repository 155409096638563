import React, { Component } from "react";
import { Button, Grid, Modal, Header, Radio, Form, Dimmer, Loader, GridColumn } from "semantic-ui-react";
import { connect, bindActionCreators, Notifications } from "../../../../../../../functional/global-import";


class CreditCardModal extends Component {
  constructor(props) {
    super();
    this.state = {
      activeStep: 0,
      value: "",
    }
  }


  render() {
    const {api,selectPaymentType,paymentSelect,CreditCard } = this.props;
          
    return (
      <>
        <Modal open={this.props.openModal && !this.state.creditcardpaymentStatus} closeIcon onClose={(e) => this.props.handleClose(e)} size={"mini"}>
          <Modal.Content>
            {api.isApiLoading &&
              <Dimmer active inverted> <Loader size='small'></Loader> </Dimmer>}<Grid>
              <Grid.Column width={16}>
                <Header as="h4" className="orange-color">{this.props.title}</Header>
              </Grid.Column>
              {this.props.isShowGettrixPayment === true &&
                <GridColumn width={16}>
                  <Header as="h4" className="deepviolet-color">{this.props.gettrxTittle}</Header>
                  <Form>
                    <Form.Group inline className="gettrxDropdown">
                      <Form.Field>
                        <Form.Select
                          placeholder='Select the payment '
                          selection
                          type="select"
                          data="selectPaymentType"
                          value={selectPaymentType}
                          options={paymentSelect}
                          onChange={this.props.handleChangeSelection} />
                      </Form.Field>
                    </Form.Group>
                  </Form>
                </GridColumn>
              }
              <Grid.Column width={16}>
                {this.props.isShowGettrixPayment === true && <Header as="h4" className="deepviolet-color">{this.props.paymentTittle}</Header>}
                <Form className="gettrxRadio">
                  {CreditCard.map((item) => {
                    return (
                      <Form.Field>
                        <Radio label={item.label} value={item.label} className="custom-radio-btn"
                          checked={this.props.value === item.label}
                          onChange={this.props.handleChange} />
                      </Form.Field>
                    )
                  })}
                </Form>
              </Grid.Column>
              <Grid.Column width={16} textAlign="right">
                <Button className="blue-btn" onClick={(e) => this.props.handleClose(e)}>Cancel</Button>
                <Button className="orange-btn" onClick={(e) => this.props.handleSubmit(e)}>Next</Button>
              </Grid.Column>
            </Grid>
          </Modal.Content>
        </Modal>

      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    actions: {
      showNotification: bindActionCreators(Notifications.show, dispatch),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditCardModal);