import React, { Component } from "react";
import { Modal, Step, Grid, Icon, Dimmer, Loader } from "semantic-ui-react";
import BundleGeneralInfo from "./add-bundle-general";
import UploadBundalPhoto from "./upload-photo";
import BundlePricing from "./bundle-pricing";
import { connect, bindActionCreators, actions, commonFunctions, Notifications, env } from "../../../../functional/global-import";
import 'react-dropdown-tree-select/dist/styles.css';
import { CommonDeleteUpdateModal } from "../../../../components";

class AddBundleModal extends Component {
  // Put all key & values that need to reset on form submit and reset form.
  get initialState() {
    return {
      addBundal: {
        bundleId: "",
        // businessCategoryType: (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "BusinessCategoryType", "Bundle")).globalCodeId,
        businessId: "",
        businessLocationId: "",
        categoryId: null,
        bundleTitle: "", bundleTags: [], bundleDescription: "",
        id: [], cancellationPolicyDescription: null, color: "",
        cancellationPolicyInStore: true, cancellationPolicyInConfirmationEmail: true,
        cancellationPolicyWebsiteWidget: true,
        actionPerformedBy: this.props.auth.userDetail.emailId, bundleDetails: [],
        inStore: true, websiteWidget: true,
        webWidget: true, inConfirmationEmail: true,
        customBundle: false,
        customField: [],
        bundleBlackOutDateId: [],
        bundleAvailability: [],
        valid: false
      },
      uploadImage: {
        imageId: [],
        deleted: [],
        bundleId: "",
        images: [],
        DefaultImage: [],
        isDefaultImage: false,
        DefaultImageId: null,
        responseImages: [],
        actionPerformedBy: this.props.auth.userDetail.emailId,
      },
      bundlePrice: {
        bundleId: "",
        bundlePricingType: "",
        bundlePricing: [
          {
            bundlePricingId: null,
            timeFrameId: null,
            price: null,
            isDeleted: false
          }],
        actionPerformedBy: this.props.auth.userDetail.emailId
      },
      isCommonDeleteUpdateModalOpen: false,
      isBundleImageAdded: false
    }
  }
  constructor(props) {
    super(props);
    this.state = {
      ...this.initialState, blackOutDatesList: [], globalBusinesCategories: [],
      activeStep: 0,
      allCategoryList: [{ value: -1, title: "Add at root level" }],
      documentfield: true, trackable: false,
      pricingField: false, editBundleDetails: [], bundleImageDetail: [], imageData: false, firstStepCompleted: false,
      secondStepCompleted: false, blackOutRows: { blackoutDates: [] }, availability: { availabilityNew: [] },
      BlackOutTitle: "", blackOutId: "", blackoutstartdate: "", blackoutenddate: "", expandthisnode: [],
      TimeFrameTitle: "", timeframeId: "", startTime: "", endTime: "", Timing: "", range: "",
      copyCategoryList: [], customfield: { customFieldRows: [{ customField: "", customDescriptions: "" }] },
      customFields: false, customFieldsButton: true, ProductList: [], arr1: [], openCommonModal: false,
      addCustomPriceRows: "", BusinessCategory: [], itemName: "", timeFrameList: [], rows: [{}], bundlePricingTitle: "",
      timeFrameId: 0, price: 0, updateBundleGeneral: false,
    };
    this.validatorGeneral = commonFunctions.initializeSimpleValidator();
    this.validatorPrice = commonFunctions.initializeSimpleValidator();
    this.validatorCustomfield = commonFunctions.initializeSimpleValidator();
  }

  ////////////////   CHECK  ////////////////
  componentDidUpdate(prevProps, prevState) {

    if (this.props.editBundleModal && this.state.uploadImage.bundleId !== prevProps.bundleId) {
      this.getGeneralInfo(this.props.bundleId);
      this.getImageDetail(this.props.bundleId);
      this.getBundlePricing(this.props.bundleId)
      this.setState({ uploadImage: { ...this.state.uploadImage, bundleId: this.props.bundleId } });
      this.setState({ activeStep: 0, updateBundleGeneral: this.props.editBundleModal });
      this.setState({ bundlePrice: { ...this.state.bundlePrice, bundleId: this.props.bundleId } });
    }
    if (this.props.inventoryType === this.props.global.inventoryDetail.businessCategoryId && prevState.addBundal.categoryId === null) {
      this.setState({
        activeStep: 0, updateBundleGeneral: this.props.editBundleModal
      })

      let newCategoryId = this.props.global.inventoryDetail.inventoryBreadcrumb &&
        this.props.global.inventoryDetail.inventoryBreadcrumb.length > 1 ?
        this.props.global.inventoryDetail.inventoryBreadcrumb[this.props.global.inventoryDetail.inventoryBreadcrumb.length - 1].parentId : undefined
      let newCategoryid = newCategoryId === null ? undefined : newCategoryId

      this.setState(() => ({
        addBundal: {
          ...this.state.addBundal, categoryId: newCategoryid
        }
      }));
      this.state.expandthisnode.push(this.props.global.inventoryDetail.inventoryBreadcrumb &&
        this.props.global.inventoryDetail.inventoryBreadcrumb.length > 1 &&
        this.props.global.inventoryDetail.inventoryBreadcrumb[this.props.global.inventoryDetail.inventoryBreadcrumb.length - 1].parentId)
    }
  }

  //  get all value in gernal form   //
  getGeneralInfo = (editBundleId) => {                                                       
    this.props.actions.apiCall({
      urls: ["GETBUNDLE"], method: "GET", data: { "businessId": this.props.global.bussinessDetail.businessId,
       "BundleId": editBundleId, "businessCategoryType": this.props.global.inventoryDetail.businessCategoryId, "businessLocationId": this.props.global.locationSelected.BusinessLocationId, 
      "categoryId": this.props.global.inventoryDetail.categoryId, "pageNo": 1, "pageSize": 100 }, onSuccess: (response) => {
                                          
        let a = JSON.stringify(JSON.parse(response[0].blackOutDates), function (key, value) { return (value === null) ? [] : value })
        // let aval = JSON.stringify(JSON.parse(response[0].availability), function (key, value) { return (value === null) ? [] : value })
        const { addBundal } = this.state;
        addBundal.bundleTitle = response[0].bundleTitle;
        addBundal.bundleDescription = response[0].bundleDescription;
        addBundal.bundleTags = JSON.parse(response[0].bundleTags);
        addBundal.id = JSON.parse(response[0].id);
        addBundal.categoryId = response[0].categoryId;
        addBundal.websiteWidget = response[0].websiteWidget;
        addBundal.inStore = response[0].inStore;
        addBundal.bundleId = response[0].bundleId;
        addBundal.cancellationPolicyDescription = response[0].cancellationPolicyDescription;
        addBundal.bundleDetails = JSON.parse(response[0].bundleDetails);
        addBundal.bundleBlackOutDateId = JSON.parse(response[0].bundleBlackOutDates);
        addBundal.customBundle = response[0].customBundle;
        addBundal.customField = JSON.parse(response[0].customFields);
        addBundal.cancellationPolicyInStore = response[0].cancellationPolicyInStore;
        addBundal.color = response[0].color;
        addBundal.cancellationPolicyInConfirmationEmail = response[0].cancellationPolicyInConfirmationEmail;
        addBundal.cancellationPolicyWebsiteWidget = response[0].cancellationPolicyWebsiteWidget;
        addBundal.bundleAvailability = JSON.parse(response[0].bundleAvailability);
        this.setState({ addBundal })
        this.state.expandthisnode.push(response[0].categoryId)

        this.setState({ availability: { ...this.state.availability, availabilityNew: JSON.parse(response[0].availability) } });
        this.setState({ blackOutRows: { ...this.state.blackOutRows, blackoutDates: JSON.parse(a) } });
        this.setState({ customfield: { ...this.state.customfield, customFieldRows: JSON.parse(response[0].customFields) } });
      }
    });
  }

  getBundlePricing = (editBundleId) => {
    this.props.actions.apiCall({
      urls: ["GETBUNDLEPRICING"], method: "GET", data: { "BundleId": editBundleId, }, onSuccess: (response) => {
        const { bundlePrice } = this.state;
        bundlePrice.bundleId = response[0].bundleId
        bundlePrice.bundlePricingType = response[0].bundlePricingType
        bundlePrice.bundlePricing = JSON.parse(response[0].bundlePricing)
        this.setState({ bundlePrice })
      }
    });
  }

  getImageDetail = (editBundleId) => {
    this.setState(this.state.uploadImage.images = [])
    this.setState(this.state.uploadImage.DefaultImage = [])
    this.props.actions.apiCall({
      urls: ["GETBUNDLEIMAGE"],
      method: "GET",
      data: { "businessId": this.props.global.bussinessDetail.businessId, "bundleId": editBundleId, "businessLocationId": this.props.global.locationSelected.BusinessLocationId },
      onSuccess: (response) => {

        let isimageExist = response.length > 0
        const { uploadImage } = this.state;
        this.setState(uploadImage.responseImages = response)
        const images = response.map((data) => {
          return (
            uploadImage.imageId.push(data.imageId),
            uploadImage.images.push(commonFunctions.concatenateImageWithAPIUrl(data.image))
          )
        });
        // var filterImages = response.filter((x) => x.isDefaultImage !== true)
        // uploadImage.images.push(commonFunctions.concatenateImageWithAPIUrl(filterImages.map((x) => x.image)))
        var isDefaultImage = response.filter((x) => x.isDefaultImage === true)
        if (isDefaultImage) {
          uploadImage.DefaultImage.push(
            commonFunctions.concatenateImageWithAPIUrl(isDefaultImage[0].image)
          );
        }
        this.setState({ uploadImage, isBundleImageAdded: isimageExist })
      }
    })
  }

  onNext = () => {

    const { addBundal, activeStep } = this.state;
    this.setState({ activeStep: activeStep + 1 });
    if (addBundal.bundleId === "") {
      this.validatorGeneral = commonFunctions.initializeSimpleValidator();
    }
  }

  onPrevious = () => {
    const { addBundal, activeStep } = this.state;
    this.setState({ activeStep: activeStep - 1 });
    if (addBundal.bundleId === "") {
      this.validatorGeneral = commonFunctions.initializeSimpleValidator();
    }
  }

  onUpdateFormCheck = (e) => {
    var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
    if (!isFormValid) {
      this.props.actions.showNotification({
        title: "Warning", message: 'Please fill all required fields.',
        position: 'br', autoDismiss: commonFunctions.notificationTime()
      }, "error");
      this.setState({ addBundal: { ...this.state.addBundal, valid: false } })
      return false;
    }
    else {
      this.updateBundle(isFormValid);
    }
  }

  //   CHECK  //
  componentDidMount() {

    this.globalBusinesCategory();
    this.getTimeFrame();
    this.getTimeFrameDetails();
    this.getGeneralInfo();
    this.getBundlePricing();

  }

  ///////////// -for prompt if any changes in form- ///////////
  onCloseModalStepOne = () => {
    if (this.props.warnUserState.isFormChanged) {
      this.setState({ isCommonDeleteUpdateModalOpen: true })
    } else {
      this.onModalCloseFormFalse();
    }
  }



  onModalCloseFormFalse = () => {
    this.props.closeModal();
    if (this.props.getProductCategoryDetail) {
      this.props.getProductCategoryDetail();
    }
    this.setState(this.initialState);
    this.setState({ activeStep: 0, firstStepCompleted: false, secondStepCompleted: false });
  }

  onConfirmWarnUser = () => {
    this.setState({ isCommonDeleteUpdateModalOpen: false })
    commonFunctions.onApiSucessFormChangedFalse(this);
    this.onModalCloseFormFalse();
  }

  defaultprising = () => {
    if (this.state.addBundal.id[0].includes("Retail") === true && this.state.bundlePrice.bundlePricingType !== 85) {
      const ExistingPrices = (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "BundlePricingType", "Use Existing Prices")).globalCodeId;
      this.setState({ bundlePrice: { ...this.state.bundlePrice, bundlePricingType: ExistingPrices } });
    }
    else if (this.state.BusinessCategory.length === 0 && this.state.bundlePrice.bundlePricingType === 85) {
      const CustomPrice = (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "BundlePricingType", "Custom Prices")).globalCodeId;
      this.setState({ bundlePrice: { ...this.state.bundlePrice, bundlePricingType: CustomPrice } });
    }
    else if (this.state.BusinessCategory.length === 0 && this.state.bundlePrice.bundlePricingType === 84) {
      const ExistingPrices = (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "BundlePricingType", "Use Existing Prices")).globalCodeId;
      this.setState({ bundlePrice: { ...this.state.bundlePrice, bundlePricingType: ExistingPrices } });
    }
    else {
      const CustomPrice = (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "BundlePricingType", "Custom Prices")).globalCodeId;
      this.setState({ bundlePrice: { ...this.state.bundlePrice, bundlePricingType: CustomPrice } });
    }
  }

  globalBusinesCategory = () => {
    let globalBusinesCategories = []
    globalBusinesCategories = this.props.global.codes.filter(code => code.categoryName === "BusinessCategoryType").map((filtercode) => {
      return { id: filtercode.globalCodeId, value: filtercode.globalCodeId, text: filtercode.codeName, checked: true }
    });
    var deletedBundleCategory = globalBusinesCategories.filter((data) => { return data.text !== "Bundle" })
    this.setState({ globalBusinesCategories: deletedBundleCategory })
  }

  onHandleChangeColor = (selectedColor) => {
    if (this.state.type === "Rental") {
      this.setState(() => ({
        addBundal: { ...this.state.addBundal, color: selectedColor.hex }
      }));
    }
    else {
      this.setState(() => ({
      addBundal: { ...this.state.addBundal, color: selectedColor.hex }
      }));
    }
  }

  //this is the method used to get the data from the Api of TIME FRAME//
  getTimeFrame = () => {
    this.props.actions.apiCall({
      urls: ["GETTIMEFRAME"],
      method: "GET",
      data: { timeFrameId: -1, businessId: this.props.global.bussinessDetail.businessId, businessLocationId: this.props.global.locationSelected.BusinessLocationId, PageNo: 1, PageSize: 100 },
      onSuccess: (response) => {
        const timeFrame = response.map((singleTime) => {
          return { value: singleTime.timeFrameId, text: singleTime.timeFrameTitle }
        });
        this.setState({ timeFrameList: timeFrame });
      },
    });
  }

  getTimeFrameDetails = () => {
    this.props.actions.apiCall({
      urls: ["GETTIMEFRAMEDETAIL"],
      method: "GET",
      data: { timeFrameId: -1, businessId: this.props.global.bussinessDetail.businessId, businessLocationId: this.props.global.locationSelected.BusinessLocationId, PageNo: 1, PageSize: 100 },
      onSuccess: (response) => {
        const timeFrame = response.map((singleTime) => {
          return { value: singleTime.timeFrameId, text: singleTime.timeFrameTitle, startTime: singleTime.timeRangeFrom, endTime: singleTime.timeRangeTo, range: singleTime.range, timeFrameTitle: singleTime.timeFrameTitle }
        });
        this.setState({ timeFrameList1: timeFrame });
      },
    });
  }

  // for get dropdown id of inventory location //
  onDropDownTreeChange = value => {
    this.setState({ addBundal: { ...this.state.addBundal, categoryId: value } });
  }

  getCategory = () => {
    this.props.actions.apiCall({
      urls: ["GETCATEGORYFORPRODUCT"],
      method: "GET",
      data: {
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId: this.props.global.locationSelected.BusinessLocationId,
        BusinessCategoryType: (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "BusinessCategoryType", "Bundle")).globalCodeId,
      }, onSuccess: (response) => {
        if (response) {
          const { addBundal } = this.state;
          if (addBundal.categoryId === undefined || response[0].productCategories === null) {
            this.setState({
              addBundal: { ...this.state.addBundal, categoryId: -1 },
            });
          }
          if (response[0].productCategories) {
            let productCategoriesArray = JSON.parse(response[0].productCategories);
            this.setState({ allCategoryList: [...this.state.allCategoryList, ...productCategoriesArray] });
          }
        }
      },
    });
  }

  // -General Screen bundle continue function- //
  onHandleContinue = (e, stepNumber) => {
    if (this.validatorGeneral.allValid() === false) {
      this.validatorGeneral.showMessages();
      this.forceUpdate();
      this.props.actions.showNotification({
        title: "Warning", message: 'Please fill all required fields.',
        position: 'br',
        autoDismiss: commonFunctions.notificationTime()
      }, "error");
      this.setState({
        addBundal: { ...this.state.addBundal, valid: false },
      })
      return false;
    }

    else if (!this.state.updateBundleGeneral) {
      this.props.actions.apiCall({
        urls: ["ADDBUNDLE"], method: "POST", data: this.state.addBundal,
        onSuccess: (response) => {
          if (response.responseCode === 200) {
            const { uploadImage, addBundal, bundlePrice } = this.state;
            addBundal.bundleId = response.keyId;
            uploadImage.bundleId = response.keyId;
            bundlePrice.bundleId = response.keyId;
            this.setState({ uploadImage, addBundal, updateBundleGeneral: true });
            commonFunctions.onApiSucessFormChangedFalse(this);
            this.setState({ activeStep: 1 });
          }
        }, showNotification: true,
      });
    }
    else if (this.state.updateBundleGeneral && this.props.warnUserState.isFormChanged) {
      const { addBundal } = this.state;
      this.props.actions.apiCall({
        urls: ["UPDATEBUNDLE"], method: "PUT", data: addBundal,
        onSuccess: (response) => {
          commonFunctions.onApiSucessFormChangedFalse(this);
          this.setState({ activeStep: 1 });
        }, showNotification: true
      });
    }
    else if (this.state.updateBundleGeneral && !this.props.warnUserState.isFormChanged) {
      this.onNext(1);
    }
    else {
      this.setState({ activeStep: 1 });
    }
  }


  // -image bundle continue function- //
  changeNext = (stepNumber) => {
    const { api } = this.props;
    const { isBundleImageAdded } = this.state;

    if (!isBundleImageAdded && this.state.uploadImage.images.length !== 0) {

      api.isApiLoading && (
        <Dimmer active inverted>
          <Loader />
        </Dimmer>
      )
      const { uploadImage } = this.state;
      if (uploadImage.DefaultImage[0] !== undefined) {
        var newArrayImage = uploadImage.images.filter((defaultObj) => defaultObj.data_url !== uploadImage.DefaultImage[0].data_url)
        uploadImage.images = newArrayImage
      }
      var formData = commonFunctions.getFormData(this.state.uploadImage);
      this.props.actions.apiCall({
        urls: ["ADDBUNDLEIMAGE"], method: "POST", data: formData,
        onSuccess: (response) => {
          if (response.responseCode == 200) {
            commonFunctions.onApiSucessFormChangedFalse(this);
            this.setState({ activeStep: stepNumber });
          }
        }, isFormData: true, showNotification: true,
      });
    }

    else if (isBundleImageAdded) {
      const { uploadImage } = this.state
      if (uploadImage.DefaultImage[0] && uploadImage.DefaultImage[0].data_url) {
        let filterImage = uploadImage.images.filter(x => x === uploadImage.DefaultImage)
        uploadImage.images = filterImage
        this.setState({ uploadImage })
      }
      var formData = commonFunctions.getFormData(this.state.uploadImage);
      this.props.actions.apiCall({
        urls: ["UPDATEBUNDLEIMAGE"], method: "PUT", data: formData,
        onSuccess: (response) => {
          if (response.responseCode == 200) {
            commonFunctions.onApiSucessFormChangedFalse(this);
            this.setState({ activeStep: stepNumber });
          }
        }, isFormData: true, showNotification: true,
      });
    }

    else if (this.state.updateBundleGeneral && !this.props.warnUserState.isFormChanged) {
      this.onNext(2);
    }
    else {
      this.setState({ activeStep: stepNumber });
    }
  }

  // Post API Bundle Pricing 
  postBundalPricing = (stepNumber) => {
    this.state.bundlePrice.bundlePricing = this.state.bundlePrice.bundlePricing.filter(word => word.timeFrameId !== null && word.price !== null);
    this.props.actions.apiCall({
      urls: ["ADDBUNDLEPRICING"],
      method: "POST",
      data: this.state.bundlePrice,
      onSuccess: (response) => {
        if (this.props.getProductCategoryDetail) {
          this.props.getProductCategoryDetail();
        }
        if (stepNumber === undefined) {
          this.onCloseModalStepOne();
        }
        this.setState({ activeStep: stepNumber });
      }, showNotification: true,
    });
  }

  // This function is used to set handle change
  onHandleChange = (e, { name, value, type, checked, data, text, newType }) => {
    commonFunctions.onFormChange(this);
    var addBundal = commonFunctions.onHandleChange(e, { name, value, type, checked, data }, this.state.addBundal);
    if (name === "ddlProductList") {
      let newBundleDetails = [];
      this.state.ProductList.filter(function (item) {
        if (value.indexOf(item.Id) > -1) {
          let exitingBundleDetail = addBundal.bundleDetails.filter(x => x.id === item.Id);
          if (exitingBundleDetail.length > 0) {
            return newBundleDetails.push({ "businessCategoryType": item.BusinessCategoryType, "id": exitingBundleDetail[0].id, "ProductName": item.Name, "quantity": exitingBundleDetail[0].quantity, "BusinessCategoryTypeId": item.CodeName, "min": exitingBundleDetail[0].min, "max": exitingBundleDetail[0].max });
          }
          else {
            return newBundleDetails.push({ "businessCategoryType": item.BusinessCategoryType, "id": item.Id, "ProductName": item.Name, "quantity": 1, "BusinessCategoryTypeId": item.CodeName, "min": null, "max": null });
          }
        }
      });
      addBundal.bundleDetails = newBundleDetails;
    }
    this.setState({ addBundal });
  }

  // This function is used to set handle change
  onHandle = (e, checked, data, index) => {
    commonFunctions.onFormChange(this);
    if (checked) {
      let exictedData = this.state.BusinessCategory.filter(item => item === data);
      if (exictedData.length <= 0) {
        this.state.filteredGlobalCodes = []
        this.setState(() => ({
          BusinessCategory: this.state.BusinessCategory.concat(data)
        }), () => this.getProductList());
      }
    }
    if (!checked) {
      let exictedData = this.state.BusinessCategory
      if (exictedData.length <= 0) {
        this.state.filteredGlobalCodes = []
      }
      this.setState(() => ({
        BusinessCategory: this.state.BusinessCategory.filter(item => item !== data)
      }), () => this.getProductList());
    }
    const { globalBusinesCategories } = this.state
    globalBusinesCategories[index]["checked"] = !checked
    this.setState({ globalBusinesCategories });
    if (this.state.BusinessCategory.length <= 1) {
      this.state.filteredGlobalCodes = []
    }
    if (checked === true) {
      this.setState(() => ({
        bundleDetails: this.state.addBundal.bundleDetails.filter(item => item.BusinessCategoryTypeId !== data)
      }))
    }
  }

  onHandleMinMax = (e, { data, name, value, rowId }) => {

    commonFunctions.onFormChange(this);
    const { addBundal } = this.state;
    if (data === "quantity") {
      const bundleDetails = [...this.state.addBundal.bundleDetails]
      if (name === "plus") {
        addBundal.bundleDetails[rowId][data] = value + 1
        this.setState({ addBundal: { ...this.state.addBundal, bundleDetails } });
      }
      else if (addBundal.bundleDetails[rowId].quantity >= 2) {
        addBundal.bundleDetails[rowId][data] = value - 1
        this.setState({ addBundal: { ...this.state.addBundal, bundleDetails } });
      }
    }
    else {
      const bundleDetails = [...this.state.addBundal.bundleDetails]
      addBundal.bundleDetails[rowId][data] = value === "" ? null : value
      this.setState(() => ({
        addBundal: { ...this.state.addBundal, bundleDetails }
      }));
    }
  }

  //this is the method used to get the data from the Api//
  getProductList = () => {
    this.props.actions.apiCall({
      urls: ["GETBUNDLEPRODUCT"],
      method: "GET",
      data: { businessId: this.props.global.bussinessDetail.businessId, businessLocationId: this.props.global.locationSelected.BusinessLocationId, BusinessCategoryType: this.state.BusinessCategory },
      onSuccess: (response) => {
        const allitems = JSON.parse(response[0].productList)
        const filteredGlobalCodes = allitems.map((items) => {
          return { value: items.Id, text: items.Name, newType: items.CodeName }
        });
        this.setState({ ProductList: allitems, filteredGlobalCodes: filteredGlobalCodes },
        );
      }
    });
  }

  /// UPLOAD photo component function's
  onImageChange = (imageList) => {

    this.setState(() => ({
      uploadImage: { ...this.state.uploadImage, images: imageList }
    }));
    commonFunctions.onFormChange(this);
  };
  //////////////// on change default image ////////////////
  handleChangeDefaultImage = (image) => {
    const { uploadImage } = this.state
    if (!image.data_url && image !== undefined) {
      const removeUrl = image.split(env.IMG_URL);
      const setDefaultImageId = this.state.uploadImage.responseImages.filter((img) => img.image === removeUrl[1])
      uploadImage.DefaultImageId = setDefaultImageId[0].imageId
      uploadImage.DefaultImage = []
      uploadImage.images = []
      this.setState(uploadImage);
      this.updateBundleImages();
    } else {
      this.setState(() => ({
        uploadImage: { ...this.state.uploadImage, DefaultImage: [image], DefaultImageId: "" },
      }));
    }
    // commonFunctions.onFormChange(this);
  }


  updateBundleImages = () => {

    const { uploadImage } = this.state
    if (uploadImage.DefaultImage[0] !== undefined) {
      var newArrayImage = uploadImage.images.filter((defaultObj) => defaultObj.data_url !== uploadImage.DefaultImage[0].data_url)
      uploadImage.images = newArrayImage
    }
    var formData = commonFunctions.getFormData(this.state.uploadImage);
    this.props.actions.apiCall({
      urls: ["UPDATEBUNDLEIMAGE"], method: "PUT", data: formData,
      onSuccess: (response) => {
        this.setState(this.state.uploadImage.imageId = [])
        this.getImageDetail(this.state.uploadImage.bundleId);
      }, isFormData: true, showNotification: true,
    })
  }
  // -FOR EDIT IMAGE REMOVE FUNCTION FOR RENTAL- //
  onImageRemove = (index) => {
    commonFunctions.onFormChange(this);
    const allImages = this.state.uploadImage.images;
    if (this.state.uploadImage.DefaultImage[0] === this.state.uploadImage.images[index]) {
      this.setState(this.state.uploadImage.DefaultImage = [])
    }
    allImages.splice(index, 1)
    this.setState({ uploadImage: { ...this.state.uploadImage, images: allImages } });
    if (this.state.uploadImage.imageId[index] && this.state.uploadImage.imageId[index] !== null) {
      this.state.uploadImage.deleted.push(this.state.uploadImage.imageId[index])
      const allImageId = this.state.uploadImage.imageId;
      allImageId.splice(index, 1);
      this.setState({ uploadImage: { ...this.state.uploadImage, imageId: allImageId } });

    }
  }
  changeStepToGeneral = () => {


    if ((this.state.uploadImage.images[0] !== undefined && this.state.uploadImage.DefaultImage[0] !== undefined) || (this.state.uploadImage.images[0] === undefined && this.state.uploadImage.DefaultImage[0] === undefined)) {
      this.changeStep(0);
    }
    else if (this.state.uploadImage.images[0] !== undefined && this.state.uploadImage.DefaultImage[0] === undefined) {
      this.props.actions.showNotification({ title: "Error", message: 'Please select default image.', position: 'br', autoDismiss: commonFunctions.notificationTime() }, "error");
    }
  }
  changeStepToPricing = () => {
    if ((this.state.uploadImage.images[0] !== undefined && this.state.uploadImage.DefaultImage[0] !== undefined) || (this.state.uploadImage.images[0] === undefined && this.state.uploadImage.DefaultImage[0] === undefined)) {
      this.changeStep(2)
    }
    else if (this.state.uploadImage.images[0] !== undefined && this.state.uploadImage.DefaultImage[0] === undefined) {
      this.props.actions.showNotification({ title: "Error", message: 'Please select default image.', position: 'br', autoDismiss: commonFunctions.notificationTime() }, "error");
    }
  }
  backStep = (e) => {
    if (e === 2) {
      this.getImageDetail(this.state.uploadImage.bundleId)
      this.onPrevious(1)
    }
    else {
      this.updateBundleImages();
      this.onPrevious(0)
    }
  }


  //////- stepper function- /////
  changeStep = (stepNumber) => {

    var forEdit = (this.props.warnUserState.isFormChanged && (this.state.updateBundleGeneral)) ? true : false
    var forAdd = (!this.props.warnUserState.isFormChanged && (!this.state.updateBundleGeneral)) ? true : false
    var a = forAdd ? true : (this.props.warnUserState.isFormChanged && (!this.state.updateBundleGeneral)) ? true : false
    if (forEdit || a) {
      const { activeStep, uploadImage, addBundal, bundlePrice } = this.state;
      if (activeStep === 0) {
        this.onHandleContinue(stepNumber);
      }
      else {
        if (activeStep === 1 && stepNumber < activeStep && this.state.uploadImage.images.length > 0 ||
          activeStep === 1 && stepNumber < activeStep && this.state.uploadImage.images.length === 0) {
          if (stepNumber === 0) {
            this.changeNext(stepNumber);
          }
        }
        // On image Section and Move Backward with click on stepper
        else {
          if (activeStep === 1 && stepNumber > activeStep && this.state.uploadImage.images.length > 0 ||
            activeStep === 1 && stepNumber > activeStep && this.state.uploadImage.images.length === 0) {
            if (stepNumber === 2) {
              this.changeNext(stepNumber);
            }
          }
          else {
            this.postBundalPricing();
          }
        }
      }
    } else {
      if (stepNumber === 1) {
        this.getImageDetail(this.state.uploadImage.bundleId);
      }
      this.setState({ activeStep: stepNumber });
    }
  }

  handleDocumentFeilds = (status) => {
    this.setState({ documentfield: status });
  }

  handlepricingField = (status) => {
    this.setState({ pricingField: status });
  }

  handletrackableField = (status) => {
    this.setState({ trackable: status });
  }



  onHandleChangePricing = (e, { name, value, type, checked, data, key }, index) => {
    const ExistingPrices = (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "BundlePricingType", "Use Existing Prices")).globalCodeId;
    if (value === ExistingPrices) {
      this.setState({ bundlePricingId: null, timeFrameId: null, price: null });
      this.state.bundlePrice.bundlePricing = [];
      const item = {
        bundlePricingId: null, timeFrameId: null, price: null,
      };
      this.setState({ bundlePrice: { ...this.state.bundlePrice.bundlePricing.push(item) } });
    }

    const { bundlePrice } = this.state;
    if (name === data) {
      var ex = /^\d*\.?(?:\d{1,2})?$/;
      const bundlePricing = [...this.state.bundlePrice.bundlePricing]
      const setValue = value === "" ? value : JSON.parse(value);
      if (ex.test(setValue) == true && setValue.toString().length < 9) {
        bundlePrice.bundlePricing[index][data] = setValue;
        this.setState({ bundlePrice: { ...this.state.bundlePrice, bundlePricing } });
      }
    }
    else {
      const bundlePrice = commonFunctions.onHandleChange(e, { name, value, type, checked, data }, this.state.bundlePrice);
      this.setState({ bundlePrice });
    }
  }

  handleAddRow = () => {
    const item = {
      bundlePricingId: null, timeFrameId: null, price: null, isDeleted: false
    };
    const { bundlePrice } = this.state;
    let addValueBundle = bundlePrice.bundlePricing.concat(item);
    this.setState({ bundlePrice: { ...this.state.bundlePrice, bundlePricing: addValueBundle } });
  };

  handleRemoveSpecificRow = (index) => {
    if (index > 0) {
      const bundlePricing = [...this.state.bundlePrice.bundlePricing]
      bundlePricing[index].isDeleted = true;
      this.setState({ bundlePrice: { ...this.state.bundlePrice, bundlePricing } });
    }
  }

  getStepContent = (stepIndex) => {
    const { filteredGlobalCodes, categoryList, addBundal, actionPerformedBy, timeFrameId, bundleId, timeFrameList1, uploadImage, rows,
      price, bundlePricing, bundlePrice, timeFrameList, firstStepCompleted, secondStepCompleted, globalBusinesCategories } = this.state;

    switch (stepIndex) {
      case 0:
        return (
          <React.Fragment>
            <BundleGeneralInfo getTimeFrame={this.getTimeFrame} onHandleContinue={this.onHandleContinue}
              onUpdateFormCheck={this.onUpdateFormCheck} onHandleChangeColor={this.onHandleChangeColor}
              getCategory={this.getCategory} onDropDownTreeChange={this.onDropDownTreeChange} timeFrameList1={timeFrameList1}
              globalBusinesCategories={globalBusinesCategories} onHandleMinMax={this.onHandleMinMax} updateBundle={this.updateBundle}
              onHandleChange={this.onHandleChange} postAddBundal={this.postAddBundal} DecreaseItem={this.DecreaseItem}
              IncrementItem={this.IncrementItem} filteredGlobalCodes={filteredGlobalCodes} onCheckboxHandle={this.onHandle}
              categoryList={categoryList} addBundal={addBundal} firstStepCompleted={firstStepCompleted} bundleId={this.props.bundleId}
              onNext={this.changeStep} onPrev={this.onPrevious} functionAndState={this} fullState={this.state} allFunction={this}
              validatorGeneral={this.validatorGeneral} />
            <Grid>
              <Grid.Column width={16} textAlign="right"></Grid.Column>
            </Grid>
          </React.Fragment>
        );
      case 1:
        return (
          <React.Fragment>
            <UploadBundalPhoto secondStepCompleted={secondStepCompleted} changeEditNext={this.changeEditNext} onImageRemove={this.onImageRemove} handleChangeDefaultImage={this.handleChangeDefaultImage}
              uploadImage={uploadImage} backStep={() => this.backStep(stepIndex)} changeNext={this.changeNext}
              onImageChange={this.onImageChange} bundleId={this.props.bundleId} onNext={this.onNext} fullState={this.state} allFunction={this} />
          </React.Fragment>
        );
      case 2:
        return (
          <React.Fragment>
            <BundlePricing validatorPrice={this.validatorPrice} defaultprising={this.defaultprising} fullState={this.state} timeFrameList={timeFrameList} bundlePricing={bundlePricing}
              deleteBlackOutDates={this.deleteBlackOutDates} rows={rows} handleRemoveSpecificRow={this.handleRemoveSpecificRow}
              backStep={() => this.backStep(stepIndex)} bundlePrice={bundlePrice} bundleId={bundleId}
              bundlePricingType={bundlePrice.bundlePricingType} price={price} timeFrameId={timeFrameId}
              actionPerformedBy={actionPerformedBy} onHandleChange={this.onHandleChangePricing} postBundalPricing={this.postBundalPricing}
              handleChange={this.handleChange} handleAddRow={this.handleAddRow} bonSaveBundle={this.onSaveBundle} onNext={this.onNext}
             allFunction={this}
              onPrev={this.onPrevious} onClose={this.props.closeModal} getProductCategoryDetail={this.props.getProductCategoryDetail} />
          </React.Fragment>
        );
      default:
        return null;
    }
  };

  onPrevious = (step) => {
    this.setState({ activeStep: step });
  };

  render() {
    const { activeStep, isCommonDeleteUpdateModalOpen } = this.state;
    return (
      <>
        <Modal open={this.props.openModal} closeIcon onClose={this.onCloseModalStepOne} closeOnDimmerClick={false} size="large">
          {this.props.editBundleModal ? <Modal.Header className="ora">
            Edit Bundle  </Modal.Header> :
            <Modal.Header className="ora">
              Add Bundle</Modal.Header>}
          <Modal.Content scrolling>
            <Modal.Description>
              <Step.Group stackable="tablet" className="custom-stepper">
                <Step active={activeStep === 0 ? true : false}
                  completed={activeStep > 0 ? true : false}
                  onClick={() => this.changeStepToGeneral()}>
                  <Step.Content>
                    <Step.Title>
                      General <Icon name="check circle" className="green-check" />
                    </Step.Title>
                    <Step.Description>1</Step.Description>
                  </Step.Content>
                </Step>
                <Step active={activeStep === 1 ? true : false}
                  completed={activeStep > 1}
                  onClick={() => this.changeStep(1)}>
                  <Step.Content>
                    <Step.Title>
                      Photo <Icon name="check circle" className="green-check" />
                    </Step.Title>
                    <Step.Description>2</Step.Description>
                  </Step.Content>
                </Step>
                <Step active={activeStep === 2 ? true : false}
                  completed={activeStep > 2}
                  onClick={() => this.changeStepToPricing()}>
                  <Step.Content>
                    <Step.Title>
                      Pricing <Icon name="check circle" className="green-check" />
                    </Step.Title>
                    <Step.Description>3</Step.Description>
                  </Step.Content>
                </Step>
              </Step.Group>
              <div>{this.getStepContent(activeStep)}</div>
            </Modal.Description>
          </Modal.Content>
        </Modal>

        {isCommonDeleteUpdateModalOpen &&
          <CommonDeleteUpdateModal
            isModalOpen={isCommonDeleteUpdateModalOpen}
            onConfirm={this.onConfirmWarnUser}
            onClose={() => this.setState({ isCommonDeleteUpdateModalOpen: false })}
            confirmationButtonText={"Yes"}
            header={"Are you sure you want to discard your changes ?"}
            cancelButtonText={"Cancel"}
          />}
      </>
    );
  }
}


const mapStateToProps = state => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
    warnUserState: state.warnUserState
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      storeWarnUser: bindActionCreators(actions.storeWarnUser, dispatch)
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddBundleModal);