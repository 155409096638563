// *************************** ActivitySelectTicket *******************************

import React, { Component, Fragment } from "react";
import { Grid, Form, Input, Icon, Button, Header } from "semantic-ui-react";
import {
  bindActionCreators,
  actions,
  connect,
  Notifications,
  commonFunctions,
} from "../../../../../../functional/global-import";
import { orderCommonFunctions } from "../../order-common-function";

class ActivitySelectTicket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxActivity: null,
      minActivity: null,
      datesRange: "",
      count: 0,
      countEx: 0,
      countChild: 0,
      countKids: 0,
      isTicketDetailsAdded: false,
      activityPricingId: {
        ActivityPricingId: -1,
        ActivityId: this.props.activityEdit.activityEdit
          ? this.props.activityEdit.activityItem.ActivityId
          : this.props.productCategoryDetail.productId,
        BusinessId: this.props.global.bussinessDetail.businessId,
        BusinessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        PageNo: 1,
        PageSize: 100,
      },
      tickets: [],
    };
    this.validator = commonFunctions.initializeSimpleValidator();
  }

  handleChange = (
    event,
    { placeholder, value, id },
    index,
    customObj,
    customIndex
  ) => {
    const { tickets } = this.state;
    let custom = {
      customField: placeholder,
      customDescriptions: customObj?.customDescriptions,
      customFieldDetailId: id,
      customAnswer: value,
      customRequired: customObj?.customRequired,
    };
    if (tickets[index]["customAnswers"].length > 0) {
      tickets[index]["customAnswers"][customIndex] = custom;
      tickets[index]["customFields"][customIndex] = custom;
    }
    this.setState({ ...this.state, tickets: tickets });
  };

  // ==================== count ===================

  countHandleChange = (index) => {
    const { tickets } = this.state;

    if (tickets[index]["max"] !== null) {
      if (tickets[index]["max"] <= tickets[index]["numberOfTickets"]) {
        this.props.actions.showNotification(
          {
            title: "Warning",
            message: `You can't book more tickets for this ${tickets[index].description}.`,
            position: "br",
            autoDismiss: commonFunctions.notificationTime(),
          },
          "error"
        );
        return false;
      }
    }
    tickets[index]["numberOfTickets"] = tickets[index]["numberOfTickets"] + 1;
    if (
      tickets[index]["numberOfTickets"] > 0 &&
      tickets[index]["customField"].length > 0
    ) {
      const clonedCustomFiled = JSON.parse(
        JSON.stringify(tickets[index]["customField"])
      );
      if (tickets[index]["numberOfTickets"] > 1) {
        tickets[index]["customFields"] = [
          ...tickets[index]["customFields"],
          ...clonedCustomFiled,
        ];
      } else {
        tickets[index]["customFields"] = clonedCustomFiled;
      }
    }

    if (
      tickets[index]["customField"].length > 0 &&
      tickets[index]["numberOfTickets"] > 0
    ) {
      let customNew = tickets[index]["customField"].map((detailCustom) => {
        return {
          customFieldDetailId: detailCustom.customFieldDetailId,
          customAnswer: "",
        };
      });
      tickets[index]["customAnswers"] = [
        ...tickets[index]["customAnswers"].concat(customNew),
      ];
    }
    this.setState({ ...this.state, tickets: tickets });
    this.validator.purgeFields();
  };

  countHandleChangeMinus = (index) => {
    const { tickets } = this.state;
    if (tickets[index]['numberOfTickets'] > 0) {
      tickets[index]['numberOfTickets'] -= 1;
      if (tickets[index]['customField'].length > 0) {
        const newCustomAnswers = tickets[index]['customAnswers'].slice(
          0,
          tickets[index]['numberOfTickets'] * tickets[index]['customField'].length
        );
        tickets[index]['customAnswers'] = newCustomAnswers;
      }

      this.updateCustomFields(index, tickets[index]['numberOfTickets']);
      this.setState({ tickets: tickets });
      this.validator.purgeFields();
    }
  };


  updateCustomFields = (index, newCount) => {
    const { tickets } = this.state;

    if (tickets[index]["customField"].length > 0) {
      const clonedCustomField = JSON.parse(JSON.stringify(tickets[index]["customField"]));
      const customFieldsToAdd = [];

      for (let i = 0; i < newCount; i++) {
        customFieldsToAdd.push(...clonedCustomField);
      }

      tickets[index]["customFields"] = customFieldsToAdd;
      this.setState({ tickets: tickets });
    }
  };

  countHandleChangeInput = (event, index) => {
    const { tickets } = this.state;
    let newCount = parseInt(event.target.value);

    if (event.target.value.length === 0 && event.nativeEvent.inputType === 'deleteContentBackward') {
      tickets[index]['numberOfTickets'] = 0;
      tickets[index]['customAnswers'] = [];
      tickets[index]['customFields'] = [];
      this.setState({ tickets: tickets });
      this.validator.purgeFields();
      return;
    }
    if (newCount > 0) {
      if (tickets[index]['max'] !== null && newCount > tickets[index]['max']) {
        this.props.actions.showNotification(
          {
            title: "Warning",
            message: `You can't book more than ${tickets[index]['max']} tickets.`,
            position: "br",
            autoDismiss: commonFunctions.notificationTime(),
          },
          "error"
        );
        return;
      }

      tickets[index]['numberOfTickets'] = newCount;
      let customAnswers = [];
      for (let i = 0; i < newCount; i++) {
        const customAnswer = tickets[index]['customField'].map(customField => ({
          customFieldDetailId: customField.customFieldDetailId,
          customAnswer: "",
        }));
        customAnswers.push(...customAnswer);
      }
      tickets[index]['customAnswers'] = customAnswers;
      this.updateCustomFields(index, newCount);
      this.setState({ tickets: tickets });
      this.validator.purgeFields();
    }
  };

  // getActivityPrice = () => {
  //   this.props.actions.apiCall({
  //     urls: ["GETACTIVITYPRICE"],
  //     method: "GET",
  //     data: this.state.activityPricingId,
  //     onSuccess: (response) => {
  //       var result = response.map(function (el) {
  //         var o = Object.assign({}, el);
  //         if (o.customField !== null) {
  //           o.customField = JSON.parse(o.customField);
  //           o.customAnswers = [];
  //           o.customFields = [];
  //         }
  //         o.numberOfTickets = 0;
  //         return o;
  //       });

  //       if (this.props.activityEdit.activityEdit) {
  //         result.map((activityObj) => {
  //           JSON.parse(
  //             this.props.activityEdit.activityItem.OrderActivityDetails
  //           ).map((alreadyAddedTicket) => {
  //             if (
  //               activityObj.activityPricingId ===
  //               alreadyAddedTicket.ActivityPricingId
  //             ) {
  //               activityObj.numberOfTickets =
  //                 alreadyAddedTicket.NumberOfTickets;
  //               // activityObj.customFields

  //               if (
  //                 alreadyAddedTicket.NumberOfTickets > 0 &&
  //                 activityObj.customField.length > 0
  //               ) {
  //                 if (alreadyAddedTicket.Tickets) {
  //                   activityObj.customFields = alreadyAddedTicket.Tickets;
  //                   activityObj.customAnswers = alreadyAddedTicket.Tickets;
  //                 }
  //                 // else {
  //                 //   for (var i = 0; i < alreadyAddedTicket.NumberOfTickets; i++) {
  //                 //     activityObj.customFields = [...activityObj.customFields, ...activityObj.customField]
  //                 //     activityObj.customAnswers = [...activityObj.customFields, ...activityObj.customField]
  //                 //   }
  //                 // }
  //               } else {
  //                 activityObj.customFields = activityObj.customField;
  //                 activityObj.customAnswers = activityObj.customField;
  //               }
  //               // if (tickets[index]["customField"].length > 0 && tickets[index]["numberOfTickets"] > 0) {
  //               //   let customNew = tickets[index]["customField"].map((detailCustom) => {
  //               //     return (
  //               //       { "customFieldDetailId": detailCustom.customFieldDetailId, "customAnswer": "" }
  //               //     )
  //               //   })
  //               //   tickets[index]["customAnswers"] = tickets[index]["customAnswers"].concat(customNew)
  //               // }

  //               //
  //             } else {
  //               return { ...activityObj };
  //             }
  //           });
  //         });
  //       }
  //       this.setState({ tickets: result });
  //     },
  //   });
  // };

  componentDidMount() {
    this.getActivityTickets();
  }

  getActivityTickets = () => {
    const { timeChoosen, selectedDate, activityEdit } = this.props;
    this.props.actions.apiCall({
      urls: ["GETORDERAVAILABLEACTIVITYTICKETS"],
      method: "GET",
      data: {
        ActivityId: this.props.activityEdit.activityEdit
          ? this.props.activityEdit.activityItem.ActivityId
          : this.props.productCategoryDetail.productId,
        BusinessId: this.props.global.bussinessDetail.businessId,
        BusinessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        businessCategoryType: commonFunctions.getGlobalCodeDetails(
          this.props.global.codes,
          "BusinessCategoryType",
          "Activity"
        ).globalCodeId,
        StartDate: activityEdit.activityEdit
          ? `${activityEdit.activityItem.StartDate} ${activityEdit.activityItem.timeRangeFrom}`
          : `${selectedDate} ${timeChoosen}`,
        InWidget: false,
      },
      onSuccess: (response) => {
        this.setState({
          minActivity: response.minActivity,
          maxActivity: response.maxActivity,
        });
        var result = JSON.parse(response.availableActivityTickets).map(
          function (el) {
            var o = Object.assign({}, el);
            if (o.customField !== null) {
              o.customField = JSON.parse(o.customField);
              o.customAnswers = [];
              o.customFields = [];
            }
            o.numberOfTickets = 0;
            return o;
          }
        );
        if (this.props.activityEdit.activityEdit) {
          result.map((activityObj) => {
            JSON.parse(
              this.props.activityEdit.activityItem.OrderActivityDetails
            ).map((alreadyAddedTicket) => {
              if (
                activityObj.activityPricingId ===
                alreadyAddedTicket.ActivityPricingId
              ) {
                activityObj.numberOfTickets =
                  alreadyAddedTicket.NumberOfTickets;

                if (
                  alreadyAddedTicket.NumberOfTickets > 0 &&
                  activityObj.customField.length > 0
                ) {
                  alreadyAddedTicket.Tickets.map((singleObj) => {
                    singleObj.customAnswer = singleObj.customAnswer.trim();
                    return { ...singleObj };
                  });
                  if (alreadyAddedTicket.Tickets) {
                    activityObj.customFields = alreadyAddedTicket.Tickets;
                    activityObj.customAnswers = alreadyAddedTicket.Tickets;
                  }
                } else {
                  activityObj.customFields = activityObj.customField;
                  activityObj.customAnswers = activityObj.customField;
                }
              } else {
                return { ...activityObj };
              }
            });
          });
        }
        this.setState({ tickets: result });
      },
      onFinally: () => {
        this.setState({ isTicketDetailsAdded: true });
      },
      showNotification: true,
    });
  };

  handleActivityQuestionRequirement = (e, isAddmoreClicked) => {
    const { tickets, minActivity, maxActivity } = this.state;
    const { orderState, chooseDateDetail } = this.props;
    var isFormValid = commonFunctions.onHandleFormSubmit(e, this);

    if (isFormValid) {
      const total = tickets.reduce(
        (prevValue, currentValue) => prevValue + currentValue.numberOfTickets,
        0
      );
      const isAbleToBook = orderCommonFunctions.onValidateMinMaxPerItem(
        total,
        minActivity,
        maxActivity
      );
      // let count = tickets.reduce((accumulator, object) => {
      //   return accumulator + object.numberOfTickets;
      // }, 0);
      if (tickets.filter((item) => item.numberOfTickets !== 0).length > 0) {
        // if (
        //   count >= this.state.minActivity &&
        //   count <= this.state.maxActivity
        // ) {
        const nextState = orderState.currentOrder.orderDetails.filter(
          (item) => item.id === orderState.currentOrderDetails.id
        );
        let data = {
          orderActivityDetailId: nextState[0]?.OrderActivityDetailId,
          orderId: orderState.currentOrder.orderId,
          businessCategoryType: nextState[0].typeId,
          activityId: nextState[0].selectedItems.itemId,
          customQuestionAnswer: tickets
            .filter((item) => item.numberOfTickets !== 0)
            .map((item) => {
              return {
                activityPricingId: item.activityPricingId,
                numberOfTickets: item.numberOfTickets,
                customAnswers:
                  item.customField !== null
                    ? item.customAnswers.map((customAnswerObj) => {
                      if (customAnswerObj.customAnswer === "") {
                        customAnswerObj.customAnswer = " ";
                        return { ...customAnswerObj };
                      } else {
                        return { ...customAnswerObj };
                      }
                    })
                    : [],
              };
            }),
          timeFrameRange: chooseDateDetail.timeFrameRange,
          startDate: chooseDateDetail.startDate,
          timeRangeFrom: chooseDateDetail.timeRangeFrom,
          timeRangeTo: null,
          actionPerformedBy: this.props.auth.userDetail.emailId,
        };

        const { stateRefActivity } = this.props;
        const availableTickets =
          stateRefActivity.state.ticketsDetails[0] &&
          stateRefActivity.state.ticketsDetails[0].availableTickets;

        let totalSelectedTicket = 0;
        data.customQuestionAnswer.map((singleTicketObj) => {
          totalSelectedTicket =
            totalSelectedTicket + singleTicketObj.numberOfTickets;
        });
        if (totalSelectedTicket > availableTickets) {
          this.props.actions.showNotification(
            {
              title: "Error",
              message: "Available Tickets are less",
              position: "br",
              autoDismiss: commonFunctions.notificationTime(),
            },
            "error"
          );
        } else {
          this.props.onAddPricingAndQuestion(data, isAddmoreClicked);
        }
        // Max Per Order item can not less then min per order
        // else {
        //   this.props.actions.showNotification(
        //     {
        //       title: "Warning",
        //       message: `Please select minimum ${this.state.minActivity} or maximum ${this.state.maxActivity} item per-order`,
        //       position: "br",
        //       autoDismiss: commonFunctions.notificationTime(),
        //     },
        //     "error"
        //   );
        // }
      } else {
        this.props.actions.showNotification(
          {
            title: "Error",
            message: "Please enter number of tickets",
            position: "br",
            autoDismiss: commonFunctions.notificationTime(),
          },
          "error"
        );
      }
    }
  };

  //  for ticket input function need to do in future
  // onTicketInputChange=(e,{value,index})=>{
  //
  //   const { tickets } = this.state;
  //   tickets[index]["numberOfTickets"] = parseInt(value);
  //   this.setState({ ...tickets });
  // }

  onCancelBtnCLick = () => {
    this.props.next(3);
    this.props.allFunction.handleSelectedBusinessCategoryEmpty();
  };

  onUpdateActivityTicket = (e) => {
    var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
    if (isFormValid) {
      const { tickets, minActivity, maxActivity } = this.state;
      // const { orderState, chooseDateDetail } = this.props;
      const { activityEdit } = this.props;
      const total = tickets.reduce(
        (prevValue, currentValue) => prevValue + currentValue.numberOfTickets,
        0
      );
      const isAbleToBook = orderCommonFunctions.onValidateMinMaxPerItem(
        total,
        minActivity,
        maxActivity
      );
      if (tickets.filter((item) => item.numberOfTickets !== 0).length > 0) {
        // if (isAbleToBook) {
        this.props.actions.apiCall({
          urls: ["ACTIVITYUPDATEORDERDETAIL"],
          method: "PUT",
          data: {
            orderId: this.props.orderState.currentOrder.orderId,
            orderDetailId: activityEdit.activityItem.OrderDetailId,
            orderActivityDetailId:
              activityEdit.activityItem.OrderActivityDetailId,
            activityId: activityEdit.activityItem.ActivityId,
            startDate: activityEdit.activityItem.StartDate,
            timeFrameRange: activityEdit.activityItem.timeFrameRange,
            timeRangeFrom: activityEdit.activityItem.timeRangeFrom,
            timeRangeTo: activityEdit.activityItem.timeRangeTo,
            customQuestionAnswer: tickets
              .filter((item) => item.numberOfTickets !== 0)
              .map((item) => {
                return {
                  activityPricingId: item.activityPricingId,
                  numberOfTickets: item.numberOfTickets,
                  // customAnswers: item.customField !== null ? item.customAnswers : [],
                  customAnswers:
                    item.customField !== null
                      ? item.customAnswers.map((customAnswerObj) => {
                        if (customAnswerObj.customAnswer === "") {
                          customAnswerObj.customAnswer = " ";
                          return { ...customAnswerObj };
                        } else {
                          return { ...customAnswerObj };
                        }
                      })
                      : [],
                };
              }),

            actionPerformedBy: this.props.auth.userDetail.emailId,
          },
          onSuccess: (response) => {
            this.props.next(3);
            this.props.allFunction.handleSelectedBusinessCategoryEmpty();
            this.props.actions.getOrderItems(
              this.props.orderState.currentOrder.orderId
            );
            return response;
          },
          showNotification: true,
        });
        // else {
        //   this.props.actions.showNotification(
        //     {
        //       title: "Warning",
        //       message: `Please select minimum ${minActivity} or maximum ${maxActivity} item per-order`,
        //       position: "br",
        //       autoDismiss: commonFunctions.notificationTime(),
        //     },
        //     "error"
        //   );
        // }
      } else {
        this.props.actions.showNotification(
          {
            title: "Error",
            message: "Please enter number of tickets",
            position: "br",
            autoDismiss: commonFunctions.notificationTime(),
          },
          "error"
        );
      }
    }
  };


  render() {
    const { tickets, isTicketDetailsAdded } = this.state;

    const {
      hasToShowCancellationComponent,
      isToShowAddon,
      activityEdit,
      stateRefActivity,
      api,
    } = this.props;
    const availableTickets =
      stateRefActivity.state.ticketsDetails[0] &&
      stateRefActivity.state.ticketsDetails[0].availableTickets;
    const activityTimeSlots = stateRefActivity.state.activityTimeSlots;
    return (
      <div className="">
        <Grid>
          {activityTimeSlots && activityTimeSlots.timeForProduct !== null && (
            <Grid.Column width={16} verticalAlign="center">
              <Header as="h4">
                Available Tickets To Book = {availableTickets}
              </Header>
            </Grid.Column>
          )}

          {tickets.length > 0 ? (
            <>
              <Grid.Column verticalAlign="center" width={3}>
                <h4>Ticket</h4>
              </Grid.Column>
              <Grid.Column verticalAlign="right" width={4}>
                <h4>
                  Prices <small>(Per Ticket)</small>
                </h4>
              </Grid.Column>
              <Grid.Column verticalAlign="center" width={5}>
                <h4>No. of Tickets</h4>
              </Grid.Column>
              <Grid.Column verticalAlign="center" width={4}>
                <h4>Descriptions</h4>
              </Grid.Column>
            </>
          ) : !api.isApiLoading && isTicketDetailsAdded ? (
            <>
              <Grid.Column width={16} textAlign="center">
                <Header as="h2">No ticket availaiable </Header>
              </Grid.Column>
              <Grid.Column width={16} textAlign="center">
                <Header as="h2">!</Header>
              </Grid.Column>
            </>
          ) : null}
        </Grid>
        <Grid>
          {tickets.length > 0 &&
            tickets.map((item, index) => {
              let ticketCount = 0;
              return (
                <Fragment>
                  <Grid.Column width={3} verticalAlign="center">
                    <p>{item.title}</p>
                  </Grid.Column>
                  <Grid.Column
                    width={4}
                    className="gray-color"
                    verticalAlign="right"
                  >
                    {commonFunctions.isNumberInteger(item.price)}
                  </Grid.Column>
                  <Grid.Column verticalAlign="center" width={5}>
                    <Form.Field className="add-numbers">
                      <Icon
                        name="minus"
                        className="orange-button"
                        onClick={() => this.countHandleChangeMinus(index)}
                      ></Icon>
                      <Form.Input
                        value={item.numberOfTickets}
                        index={index}
                        name="numberOfTickets"
                        onChange={(event) => this.countHandleChangeInput(event, index)}
                        fluid
                      />
                      <Icon
                        name="plus"
                        className="orange-button plus-btn"
                        onClick={() => this.countHandleChange(index)}
                      ></Icon>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column width={4} verticalAlign="center">
                    <span
                      className="vertical-ellipses"
                      title={item.description}
                    >
                      {item.description}
                    </span>
                  </Grid.Column>
                  <Grid.Row>
                    {item.numberOfTickets > 0 &&
                      item.customFields &&
                      item.customFields.length > 0 &&
                      item.customFields.map((customObj, customIndex) => {
                        const numberOfCustomFields = item.customField.length;
                        const showTicketCustomFieldBox =
                          customIndex % numberOfCustomFields === 0;
                        if (showTicketCustomFieldBox) {
                          ticketCount += 1;
                        }
                        return (
                          <>
                            {showTicketCustomFieldBox && (
                              <Grid.Column width={16}>
                                <div className="NewRow">
                                  {" "}
                                  <span> Ticket {ticketCount} </span>
                                </div>
                              </Grid.Column>
                            )}
                            <Grid.Column
                              width={8}
                              verticalAlign="middle"
                              className="activity-select"
                              style={{ marginBottom: "15px" }}
                            >
                              <Input
                                placeholder={customObj.customDescriptions}
                                name={`${customObj.customField} ${customIndex}`}
                                id={customObj.customFieldDetailId}
                                value={customObj.customAnswer}
                                fluid
                                onChange={(event, { placeholder, value, id }) =>
                                  this.handleChange(
                                    event,
                                    { placeholder, value, id },
                                    index,
                                    customObj,
                                    customIndex
                                  )
                                }
                                error={
                                  customObj.customRequired
                                    ? this.validator.message(
                                      // "text",
                                      `text ${customIndex}`,
                                      customObj.customAnswer,
                                      "required"
                                    )
                                    : ""
                                }
                              />
                              {customObj.customRequired && (
                                <span className="estrickSign">*</span>
                              )}
                            </Grid.Column>
                          </>
                        );
                      })}
                  </Grid.Row>
                </Fragment>
              );
            })}

          {!activityEdit.activityEdit ? (
            <Grid.Column width={16} textAlign="right">
              <Button
                className="blue-btn"
                onClick={this.props.backToActivityChooseDate}
              >
                Back
              </Button>
              {!isToShowAddon && !hasToShowCancellationComponent && (
                <Button
                  className="blue-btn"
                  onClick={(e) =>
                    this.handleActivityQuestionRequirement(e, true)
                  }
                >
                  Add More
                </Button>
              )}
              <Button
                className="orange-btn"
                onClick={(e) =>
                  this.handleActivityQuestionRequirement(e, false)
                }
              >
                Continue
              </Button>
            </Grid.Column>
          ) : (
            <Grid.Column width={16} textAlign="right">
              <Button className="blue-btn" onClick={this.onCancelBtnCLick}>
                Cancel
              </Button>
              <Button
                className="orange-btn"
                onClick={(e) => this.onUpdateActivityTicket(e)}
              >
                Update
              </Button>
            </Grid.Column>
          )}
        </Grid>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    orderState: state.orderState,
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      addSelectedDateRange: bindActionCreators(
        actions.addSelectedDateRange,
        dispatch
      ),
      storeInventoryDetail: bindActionCreators(
        actions.storeInventoryDetail,
        dispatch
      ),
      AddOrderPaymentStatus: bindActionCreators(
        actions.AddOrderPaymentStatus,
        dispatch
      ),
      showNotification: bindActionCreators(Notifications.show, dispatch),
      getOrderItems: bindActionCreators(actions.getOrderItems, dispatch),
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivitySelectTicket);