import React, { Component } from 'react'
import { Dropdown } from 'semantic-ui-react'
import { connect, bindActionCreators, actions,  Notifications } from "../../../functional/global-import";


class AddOnsTagger extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addOnsList: []
    };
  }

  componentDidMount = () => {
    this.getAddOns();
  }

  getAddOns = () => {
    this.props.actions.apiCall({
      urls: ["GETADDONS"],
      method: "GET",
      data: {
        businessId: this.props.global.bussinessDetail.businessId, addOnId: -1,
        businessLocationId: this.props.global.locationSelected.BusinessLocationId, pageNo: 1, pageSize: 100,
      }, onSuccess: (response) => {
        // const getAddon = response.map((singleAddOn) => {
        //   return { value: singleAddOn.addOnId, text: singleAddOn.addOnTitle }
        // });
        this.setState({ addOnsList: response });
      },
    });
  };

  // state = { options }
  handleAddition = (e, { value }) => {
    this.setState((prevState) => ({
      options: [{ text: value, value }, ...prevState.options],
    }))
  }

  handleChange = (e, { value }) => {
    this.setState({ currentValues: value })
  }

  render() {
    return (
      <Dropdown options={this.state.addOnsList} placeholder='Add Ons' search selection fluid multiple allowAdditions
        value={this.props.value} onAddItem={this.handleAddition} onChange={this.props.onChange} error={this.props.error}
        data={this.props.data} type={this.props.type} className="tagger" />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddOnsTagger);