import React, { Component } from "react";
import { Grid, Input, Checkbox, Form, Button, Icon } from "semantic-ui-react";
import {
  commonFunctions,
  connect,
  bindActionCreators,
  actions,
  Notifications,
} from "../../../shared/functional/global-import";
import { GlobalCodeMultiSelect } from "../../../shared/components";
import DocumentGrid from "./document-grid";
import SignedDocumentGrid from "./signed-document-grid";
import {
  CommonDeleteUpdateModal,
  CommonCustomField,
} from "../../../shared/components";

class DocumentForm extends Component {
  child = React.createRef();
  get initialState() {
    return {
      documentForm: {
        documentName: "",
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        documentAsDefaultToAllProduct: false,
        documentBusinessCategoryType: [],
        actionPerformedBy: this.props.auth.userDetail.emailId,
        isActive: true,
        onlineSignature: true,
        image: "",
        customField: [],
        documentFile: "",
        documentId: "",
        isImageUpdated: false,
      },
      copyDocumentlink: null,
      displayButton: "Save",
      isDocumentgFormLoading: false,
      isDocumnetForm: false,
    };
  }
  constructor(props) {
    super(props);
    this.state = {
      ...this.initialState,
      // gridDetail: [],
      isCommonDeleteUpdateModalOpen: false,
      documentId: "",
      customFieldsButton: true,
      customfield: {
        customFieldRows: [{ customField: "", customDescriptions: "" }],
      },
    };
    this.validator = commonFunctions.initializeSimpleValidator();
    this.validatorGeneral = commonFunctions.initializeSimpleValidator();
  }

  onHandleChange = (e, { name, value, type, checked, data }) => {
    var documentForm = commonFunctions.onHandleChange(
      e,
      { name, value, type, checked, data },
      this.state.documentForm
    );
    this.setState({ documentForm });
    if (data === "documentAsDefaultToAllProduct") {
      documentForm.documentBusinessCategoryType = [];
      let filteeredOptions = [];
      if (checked) {
        this.props.global.codes
          .filter((code) => code.categoryName === "BusinessCategoryType")
          .map((filtercode) => {
            filteeredOptions.push(filtercode.globalCodeId);
          });
        documentForm.documentBusinessCategoryType = filteeredOptions.filter(
          (item) => {
            return item !== 2 && item !== 490;
          }
        );
      }
      this.setState({ documentForm });
    } else if (
      documentForm.documentBusinessCategoryType.length <
      this.props.global.codes.filter(
        (code) =>
          code.categoryName === "BusinessCategoryType" &&
          code.codeName !== "Season Pass" &&
          code.codeName !== "Bundle"
      ).length -
        1
    ) {
      documentForm.documentAsDefaultToAllProduct = false;
      this.setState({ documentForm });
    } else {
      documentForm.documentAsDefaultToAllProduct = true;
      this.setState({ documentForm });
    }
  };

  editDocument = (document) => {
    this.state.documentForm = document;
    this.setState({
      documentForm: {
        ...this.state.documentForm,
        customField: JSON.parse(document.customField),
        documentBusinessCategoryType: JSON.parse(
          document["documentBusinessCategoryType"]
        ),

        onlineSignature: document.onlineSignature,
        actionPerformedBy: this.props.auth.userDetail.emailId,
      },
      displayButton: "Update",
    });
    this.setState({
      customfield: {
        ...this.state.customfield,
        customFieldRows: JSON.parse(document.customField),
      },
    });
  };
  onImageChange = (event) => {
    this.setState(
      {
        documentForm: {
          ...this.state.documentForm,
          image: event.target.files,
          documentFile: event.target.files[0]?.name,
          isImageUpdated: true,
        },
      },
      () => {
        event.target.file = "";
      }
    );
  };
  onChangeCopyLink = (documentId) => {
    const businessLocationId =
      this.props.global.locationSelected.BusinessLocationId;
    this.setState({ copyDocumentlink: documentId });
    navigator.clipboard.writeText(
      `https://${this.props.global.bussinessDetail.businessUrl}.ahoybooking.com/waiver?documentid=${documentId}&${businessLocationId}`
      //  for staging server need to run below code
      //`https://rehntitwidgetappstaging.azurewebsites.net/waiver?documentid=${documentId}&${businessLocationId} `
    );
    this.props.actions.showNotification(
      {
        message: "The link is copied",
        position: "br",
        autoDismiss: commonFunctions.notificationTime(),
      },
      "success"
    );
  };

  onHandleSubmitButton = (e) => {
                       ;
    let allFunction = this;
    allFunction.state.documentForm.customField = JSON.stringify(
      allFunction.state.documentForm.customField
    );
    if (e) {
      const allFunction = this;
      commonFunctions.onHandleSubmit(
        e,
        allFunction,
        "isDocumentgFormLoading",
        "documentForm",
        "ADDDOCUMENT",
        "UPDATEDOCUMENT",
        true
      );
    
      this.setState({
        documentForm: {
          ...this.state.documentForm,
          customField: JSON.parse(allFunction.state.documentForm.customField),
        },
      });
    } else {
      this.props.getDocumentsList();
    }
  };
  render() {
    const {
      documentForm,
      isCommonDeleteUpdateModalOpen,
      isDocumentgFormLoading,
      displayButton,
      buttonName,
      messageToShow,
    } = this.state;
    const fullState = this.state;
    const allFunction = this;
    return (
      <React.Fragment>
        <div className="cmn-shad" id="form_section">
          <Form>
            <Grid>
              <Grid.Column mobile={16} tablet={8} computer={8}>
                <Form.Field>
                  <Input
                    fluid
                    placeholder="Document Name"
                    name="txtDocumentName"
                    textAlign="left"
                    data="documentName"
                    onChange={this.onHandleChange}
                    value={documentForm.documentName}
                    error={this.validator.message(
                      "documentName",
                      documentForm.documentName,
                      "required"
                    )}
                  />
                  {this.validator.message(
                    "documentName",
                    documentForm.documentName,
                    "required"
                  )}
                </Form.Field>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={8} computer={8}>
                <div className="input-file">
                  <label htmlFor="myInput">Choose File</label>
                  <input
                    key={this.state.documentForm.isImageUpdated}
                    accept="application/pdf"
                    id="myInput"
                    style={{ display: "none" }}
                    type={"file"}
                    onChange={(event) => this.onImageChange(event)}
                  />
                  {documentForm.documentFile !== "" ? (
                    <span id="file-chosen">{documentForm.documentFile}</span>
                  ) : (
                    "No file chosen"
                  )}
                </div>
                {this.validator.message(
                  "documentFile",
                  documentForm.documentFile,
                  "required"
                )}
                <br />
              </Grid.Column>

              <Grid.Column
                mobile={16}
                tablet={9}
                computer={9}
                verticalAlign="middle"
                className="categoeryCheckboxWrapper"
              >
                <div className="categoeryCheckbox">
                  <Checkbox
                    className="custome-checkbox"
                    label="Document as default to all categories"
                    type="checkbox"
                    onChange={this.onHandleChange}
                    name="documentAsDefaultToAllProduct"
                    data="documentAsDefaultToAllProduct"
                    checked={documentForm.documentAsDefaultToAllProduct}
                    error={this.validator.message(
                      "documentAsDefaultToAllProduct",
                      documentForm.documentAsDefaultToAllProduct,
                      "required"
                    )}
                  />
                  {this.validator.message(
                    "documentAsDefaultToAllProduct",
                    documentForm.documentAsDefaultToAllProduct,
                    "required"
                  )}
                </div>
                <div className="onlineSignature">
                  {!this.props.hideToggleForModal && (
                    <Checkbox
                      label="OnlineSignature"
                      data="onlineSignature"
                      checked={documentForm.onlineSignature}
                      name="onlineSignature"
                      value={documentForm.onlineSignature}
                      onChange={this.onHandleChange}
                      className="custom-toggle"
                    />
                  )}
                  <Icon
                    name="linkify"
                    size="large"
                    className={"maroon-color"}
                    title="Copied Link"
                  />
                </div>
                <div className="activeSwitch">
                  {!this.props.hideToggleForModal && (
                    <Checkbox
                      label="Active"
                      data="isActive"
                      checked={documentForm.isActive}
                      value={documentForm.isActive}
                      onChange={this.onHandleChange}
                      className="custom-toggle"
                    />
                  )}
                </div>
              </Grid.Column>

              <Grid.Column
                mobile={16}
                tablet={7}
                computer={7}
                verticalAlign="middle"
              >
                <GlobalCodeMultiSelect
                  placeholder="Document assigned to"
                  name="ddmbusinessLocationCategories"
                  data="documentBusinessCategoryType"
                  categoryType="BusinessCategoryType"
                  value={documentForm.documentBusinessCategoryType}
                  error={this.validator.message(
                    "documentBusinessCategoryType",
                    documentForm.documentBusinessCategoryType,
                    "required"
                  )}
                  onChange={this.onHandleChange}
                />
                {this.validator.message(
                  "documentBusinessCategoryType",
                  documentForm.documentBusinessCategoryType,
                  "required"
                )}
              </Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <CommonCustomField
                  fullState={this.state}
                  allFunction={this}
                  stateForm="documentForm"
                  variableName="customField"
                  showRequired={true}
                />
              </Grid.Column>
            </Grid>
            <Grid reversed="computer tablet">
              <Grid.Column mobile={8} tablet={8} computer={8} textAlign="right">
                <Button
                  className="blue-btn"
                  onClick={() =>
                    commonFunctions.onCancelInitialState(allFunction)
                  }
                >
                  Clear
                </Button>
                <Button
                  className="orange-btn"
                  loading={isDocumentgFormLoading}
                  onClick={(e) => this.onHandleSubmitButton(e)}
                >
                  {displayButton}
                </Button>
              </Grid.Column>
              {this.props.showGrid && (
                <DocumentGrid
                  ref={this.child}
                  fullState={fullState}
                  allFunction={allFunction}
                />
              )}
              {this.props.showGrid && (
                <SignedDocumentGrid
                  ref={this.child}
                  fullState={fullState}
                  allFunction={allFunction}
                />
              )}
            </Grid>
          </Form>

          <CommonDeleteUpdateModal
            isModalOpen={isCommonDeleteUpdateModalOpen}
            onConfirm={() => {
              commonFunctions.onConfirmFunction(
                allFunction,
                "documentId",
                "gridDetail",
                "DELETEDOCUMENT",
                "UPDATEDOCUMENTTOGGLE"
              );

              // this.setState({ isCommonDeleteUpdateModalOpen: false });
            }}
            onClose={() =>
              commonFunctions.onCloseDeleteUpdateModal(
                allFunction,
                "documentId"
              )
            }
            confirmationButtonText={buttonName}
            header={messageToShow}
            cancelButtonText={"Cancel"}
          />
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    auth: state.auth,
    global: state.global,
    warnUserState: state.warnUserState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeWarnUser: bindActionCreators(actions.storeWarnUser, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DocumentForm);
