import React, { Component } from "react";
import {
  Button,
  Grid,
  Form,
  Header,
  Divider,
  Icon,
  Dimmer,
  Loader,
  Popup,
} from "semantic-ui-react";
// import { CommonPhysicalLocation } from "../../../../components";
import {
  commonFunctions,
  connect,
  bindActionCreators,
  actions,
  Notifications,
} from "../../../../functional/global-import";
import { TreeSelect } from "antd";
import "antd/dist/antd.css";
import {
  CommonChooseColor,
  CommonCustomField,
  //  CommonBookingConfirmation,  CommonTraits,
  CommonDocument,
  CommonRequirement,
  CommonAvailability,
  CommonBlackOutDates,
  CommonAvailableOn,
  CommonCustomerQuestions,
  CommonAddOns,
  CommonCancellationPolicy,
  CommonChooseTags,
  CommonMinTimeDuration,
} from "../../../organisms";

class ActivityGeneralInfo extends Component {
  // Put all key & values that need to reset on form submit and reset form.
  get initialState() {
    return {};
  }
  constructor(props) {
    super(props);

    this.state = {
      Addons: true,
      blackOutDatesList: [],
      createUI: [],
    };
    this.validator = commonFunctions.initializeSimpleValidator();
    this.validatorGeneral = commonFunctions.initializeSimpleValidator();
  }

  componentDidMount() {
    const { addActivityGeneralInfo, fullState } = this.props;
    fullState.addActivityGeneralInfo.businessId =
      this.props.auth.loggedIn.businessId;
    fullState.addActivityGeneralInfo.businessLocationId =
      this.props.global.locationSelected.BusinessLocationId;
    fullState.addActivityGeneralInfo.actionPerformedBy =
      this.props.auth.userDetail.emailId;
    this.setState({ addActivityGeneralInfo });
    this.getBlackOutDateList();
  }

  getBlackOutDateList = () => {
    this.props.actions.apiCall({
      urls: ["GETBLACKOUTDATE"],
      method: "GET",
      data: {
        businessId: this.props.global.bussinessDetail.businessId,
        blackOutDateId: -1,
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        PageNo: 1,
        PageSize: 100,
      },
      onSuccess: (response) => {
        const getBlackOut = response.map((singleBlackOut) => {
          return {
            value: singleBlackOut.blackOutDateId,
            text: singleBlackOut.title,
            blackoutStartDate: singleBlackOut.blackoutStartDate,
            blackOutEndDate: singleBlackOut.blackOutEndDate,
          };
        });
        this.setState({ blackOutDatesList: getBlackOut });
      },
    });
  };

  createUI() {
    return this.state.createUI.map((el, i) => (
      <Grid.Column width={16} key={i}>
        <Grid>
          <Grid.Column width={14} verticalAlign="middle">
            <Form>
              <Form.TextArea placeholder="Custom" rows="2" />
            </Form>
          </Grid.Column>
          <Grid.Column width={2} verticalAlign="middle" textAlign="center">
            <Icon
              link
              name="trash alternate"
              className="orange-color"
              size="big"
              onClick={() => this.handleMultipleDelete(i)}
            />
            {this.state.createUI[this.state.createUI.length - 1].id ===
              el.id && (
              <Icon
                link
                name="plus"
                className="orange-color"
                size="big"
                onClick={this.addClick.bind(this)}
              />
            )}
          </Grid.Column>
        </Grid>
      </Grid.Column>
    ));
  }

  handleMultipleDelete = (index) => {
    const { createUI } = this.state;
    createUI.splice(index, 1);
    this.setState({ createUI });
  };

  addClick() {
    this.setState((prevState) => ({
      createUI: [...prevState.createUI, ""],
      id: this.state.createUI.length,
    }));
  }

  showAddons = () => {
    this.setState({
      Addons: true,
    });
  };

  render() {
    const { fullState, allFunction, api } = this.props;
          
    return (
      <React.Fragment>
        <Grid>
          {api.isApiLoading && (
            <Dimmer active inverted>
              <Loader />
            </Dimmer>
          )}
          <Grid.Column width={16}>
            <Header as="h4">
              General Infomation &nbsp;
              <Popup
                trigger={
                  <Icon
                    name="info circle"
                    className="orange-color discount-css"
                  />
                }
              >
                <div>
                  All General Activity Information will not be displayed in the
                  receipt / reservation confirmation email. To display any of
                  this information in the receipt / confirmation email, please
                  create a custom field with the same information and check the
                  “confirmation email” box. Do not check the “website” box or
                  you will have two of the same displayed online. If the custom
                  field is unique and you want to display on your website, you
                  can check the “website” box.
                </div>
              </Popup>
            </Header>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={4}>
            <Form>
              <Form.Input
                placeholder="Activity Name"
                data="activityName"
                value={fullState.addActivityGeneralInfo.activityName}
                onChange={allFunction.onHandleChange}
                fluid
                error={allFunction.validatorGeneral.message(
                  "Activity Name",
                  fullState.addActivityGeneralInfo.activityName,
                  "required|min:0|max:100"
                )}
              />
            </Form>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={4}>
            <TreeSelect
              showSearch
              filterTreeNode={(search, item) => {
                return (
                  item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0
                );
              }}
              treeDefaultExpandedKeys={fullState.expandthisnode}
              value={
                !api.isApiLoading && fullState.addActivityGeneralInfo.categoryId
              }
              treeData={fullState.allCategoryList}
              placeholder="Choose Inventory Location"
              onChange={allFunction.onDropDownTreeChange}
            />
            {allFunction.validatorGeneral.message(
              "inventoryLocation",
              fullState.addActivityGeneralInfo.categoryId,
              "required"
            )}
          </Grid.Column>
          <Grid.Column mobile={16} tablet={4} computer={4}>
            <Form>
              <Form.Input
                placeholder="Min. Seats"
                fluid
                min="0"
                max="100000"
                type="number"
                name="MinimumNumberOfSeat"
                error={allFunction.validatorGeneral.message(
                  "minimumNumberOfSeats",
                  fullState.addActivityGeneralInfo.minimumNumberOfSeats,
                  `required|integer|numeric|min:0,num`
                )}
                data="minimumNumberOfSeats"
                value={fullState.addActivityGeneralInfo.minimumNumberOfSeats}
                onChange={allFunction.onHandleChange}
              ></Form.Input>
            </Form>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={4} computer={4}>
            <Form>
              <Form.Input
                placeholder="Max. Seats - Specific time only"
                fluid
                min="0"
                max="100000"
                type="text"
                name="MaximumNumberOfSeat"
                data="maximumNumberOfSeats"
                value={fullState.addActivityGeneralInfo.maximumNumberOfSeats}
                error={allFunction.validatorGeneral.message(
                  "maximumNumberOfSeats",
                  fullState.addActivityGeneralInfo.maximumNumberOfSeats,
                  `required|numeric|integer|min:0,num|validValues:${fullState.addActivityGeneralInfo.minimumNumberOfSeats}:Seats`
                )}
                onChange={allFunction.onHandleChange}
              />
            </Form>
          </Grid.Column>
          <CommonChooseTags
            fullState={fullState}
            allFunction={allFunction}
            stateForm="addActivityGeneralInfo"
            variableName="productTags"
          />
          <Grid.Column width={8}>
            <Form>
              <Form.TextArea
                rows={2}
                placeholder="Activity Description"
                type="textarea"
                fluid
                textAlign="left"
                name="txtActivityDescription"
                data="description"
                value={fullState.addActivityGeneralInfo.description}
                onChange={allFunction.onHandleChange}
                error={allFunction.validatorGeneral.message(
                  "description",
                  fullState.addActivityGeneralInfo.description,
                  "min:0|max:2000"
                )}
              />
            </Form>
          </Grid.Column>
          <Grid.Column width={8}>
            <Form>
              <Form.TextArea
                rows={2}
                placeholder="What is included?"
                type="textarea"
                name="txtIncluded"
                data="whatIsIncluded"
                value={fullState.addActivityGeneralInfo.whatIsIncluded}
                onChange={allFunction.onHandleChange}
                error={allFunction.validatorGeneral.message(
                  "whatIsIncluded",
                  fullState.addActivityGeneralInfo.whatIsIncluded,
                  "min:0|max:2000"
                )}
              />
            </Form>
          </Grid.Column>
          <Grid.Column width={8}>
            <Form>
              <Form.TextArea
                rows={2}
                placeholder="What to know and bring?"
                type="textarea"
                name="txtWhatToBring"
                data="whatToKnow"
                value={fullState.addActivityGeneralInfo.whatToKnow}
                onChange={allFunction.onHandleChange}
                error={allFunction.validatorGeneral.message(
                  "whatToKnow",
                  fullState.addActivityGeneralInfo.whatToKnow,
                  "min:0|max:2000"
                )}
              />
            </Form>
          </Grid.Column>
          <CommonChooseColor
            fullState={fullState}
            allFunction={allFunction}
            stateForm="addActivityGeneralInfo"
            variableName="color"
          />
          {/* <Grid.Column width={16}>
            <Header style={{ color: "red" }} as="h4">
              Per Order
            </Header>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={4}>
            <label>
              {" "}
              <b>Min</b>
            </label>
            <Form.Input
              placeholder="Min."
              data="minActivity"
              value={fullState.addActivityGeneralInfo.minActivity}
              onChange={allFunction.onHandleMinDuration}
              className="qty"
              error={allFunction.validatorGeneral.message("min PerOrder",fullState.addActivityGeneralInfo.minActivity, `integer|numeric|min:1,num`)} 
/>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={4}>
            <label>
              <b>Max</b>
            </label>
            <Form.Input
              placeholder="Max."
              data="maxActivity"
              value={fullState.addActivityGeneralInfo.maxActivity}
              onChange={allFunction.onHandleMaxDurationChange}
              type="number"
              className="qty"     
              error={allFunction.validatorGeneral.message("max PerOrder",fullState.addActivityGeneralInfo.maxActivity, `integer|numeric|min:1,num`)} 
            />
          </Grid.Column>{" "} */}
          <CommonCustomField
            fullState={fullState}
            allFunction={allFunction}
            stateForm="addActivityGeneralInfo"
            variableName="customField"
          />
          <Grid.Column
            mobile={8}
            tablet={8}
            computer={4}
            verticalAlign="middle">
    
            <Form.Checkbox
              label="In Confirmation Email"
              className="custome-checkbox"
              data="inConfirmationEmail"
              checked={fullState.addActivityGeneralInfo.inConfirmationEmail}
              value={fullState.addActivityGeneralInfo.inConfirmationEmail}
              onChange={allFunction.onHandleChange}
            />
          </Grid.Column>
          <Grid.Column
            mobile={8}
            tablet={8}
            computer={4}
            verticalAlign="middle"
          >
            <Form.Checkbox
              label="Website"
              className="custome-checkbox"
              data="webWidget"
              checked={fullState.addActivityGeneralInfo.webWidget}
              value={fullState.addActivityGeneralInfo.webWidget}
              onChange={allFunction.onHandleChange}
            />
          </Grid.Column>
          <CommonDocument
            isEdited={this.props.isEdited}
            fullState={this.props.fullState}
            allFunction={this.props.allFunction}
            stateForm="addActivityGeneralInfo"
            variableName="documents"
            productDocuments={[]}
          />
          {/* aa={this} isEdited={this.props.isEdited} */}
          <CommonRequirement
            isEdited={this.props.isEdited}
            fullState={this.props.fullState}
            allFunction={this.props.allFunction}
            stateForm="addActivityGeneralInfo"
            variableName="requirements"
          />
          <CommonAvailableOn
            fullState={fullState}
            allFunction={allFunction}
            stateForm="addActivityGeneralInfo"
          />
          {/* <CommonTraits fullState={fullState} allFunction={allFunction} stateForm="addActivityGeneralInfo" variableName="traits" /> */}
          <Grid.Column width={16}>
            <Divider />
          </Grid.Column>
          <CommonAvailability
            fullState={fullState}
            allFunction={allFunction}
            stateForm="addActivityGeneralInfo"
            variableName="activityAvailability"
            itemName={"availability"}
            validator={this.props.allFunction.validatorGeneral}
          />
          <CommonBlackOutDates
            fullState={fullState}
            allFunction={allFunction}
            stateForm="addActivityGeneralInfo"
            variableName="activityBlackOutDates"
            InDiscountAndCoupons={false}
          />
          {/* <CommonBookingConfirmation fullState={fullState} allFunction={allFunction} stateForm="addActivityGeneralInfo" variableName="bookingConfirmation" /> */}
          <CommonCustomerQuestions
            isEdited={this.props.isEdited}
            fullState={this.props.fullState}
            allFunction={this.props.allFunction}
            stateForm="addActivityGeneralInfo"
            variableName="activityCustomQuestion"
          />
           <CommonCancellationPolicy
            fullState={fullState}
            allFunction={allFunction}
            stateForm="addActivityGeneralInfo"
          />

          <Grid.Column width={16}>
            <Divider />
          </Grid.Column>
          <CommonAddOns
            fullState={this.props.fullState}
            allFunction={this.props.allFunction}
            stateForm="addActivityGeneralInfo"
          />
          {/* <Grid.Column width={16}>
            <Divider />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={12}>
            <Form.Checkbox className="custome-checkbox" label="Allow customers to add emails of friends or family also purchasing tickets" 
            data="tickets" name="tickets" value={fullState.addActivityGeneralInfo.tickets} onChange={allFunction.onHandleChange} checked = {fullState.addActivityGeneralInfo.tickets} />
          </Grid.Column> */}

         

          {/* <CommonPhysicalLocation fullState={fullState} allFunction={allFunction} stateForm="addActivityGeneralInfo" /> */}
        </Grid>

        <Grid>
          <Grid.Column width={16} textAlign="right">
            <Button
              loading={api.isApiLoading}
              className="orange-btn"
              onClick={() => allFunction.changeNext("continue", 1)}
            >
              Continue
            </Button>
          </Grid.Column>
        </Grid>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
      storeActivityId: bindActionCreators(actions.storeActivityId, dispatch),
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityGeneralInfo);
