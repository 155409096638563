import React, { Component } from "react";
import { Grid,  Icon, Checkbox } from "semantic-ui-react";
import { connect, bindActionCreators, actions, Notifications, commonFunctions, } from "../../../../functional/global-import";
import { CommonDeleteUpdateModal } from "../../../../../shared/components"; 
import BlackOutDateModal from '../../../organisms/modal/add-blackout-dates/index'; 
import { DataTable } from '../../../../components/organisms';
// '../add-black-out-dates-modal/index';
// import AddBlackOutDates from '../../../organisms/modal/black-out-date-modal/index';
// import moment from 'moment';


class AddBlackOutDatesDiscount extends Component {
    child = React.createRef();
    // Put all key & values that need to reset on form submit and reset form.
    constructor(props) {
        super(props);
        
        this.state = {
            ...this.initialState, gridDetail: [], isCommonDeleteUpdateModalOpen: false, blackOutDateId: "",
            customFields: false, open: false, advance: false, editBlackout: false, blackoutStatus: false,
            customHeader: false, isEditing: false, blackOutDates:[],
        };
        this.validator = commonFunctions.initializeSimpleValidator();
    }
    
    blackOutDateModalModal = (blackOutDates) => {
        this.setState({
            blackOutDateStatus:
                !this.state.blackOutDateStatus,
                blackOutDates, isEditing:true
        })
    }

    render() {
        const{ isCommonDeleteUpdateModalOpen,buttonName,messageToShow,gridDetail } = this.state;
        const allFunction = this;
        const allFormFunction = this.props;
        return (
            <React.Fragment>
                <Grid>
                    <DataTable
                        ref={this.child}
                        getApiName="GETBLACKOUTDATE"
                        searchOption={{ show: true, placeHolder: "Search Blackout Dates" }}
                        // defaultSort={{sortColumn:"modifiedDate",sortOrder:"desc"}}
                        // defaultPageSize={10}
                        getGridDataParamters={{ "blackOutDateId": -1, InDiscountAndCoupons: this.props.InDiscountAndCoupons === true ? true : false }}
                        fullState={this.state}
                        allFunction={this}
                        allFormFunction={allFormFunction}
                        gridName="blackoutGrid"
                        screenName="discount&coupons"
                        tableHeight="250px"
                        emptyGrid="No records to show"
                        columns={[
                            {
                                headerName: "Title",
                                fieldName: "title",
                                isSorting: true,
                                className: "textOverflowHidden",
                                Cell: (props) => {
                                    return (
                                        <span title={props.title}>{props.title}</span>
                                    );
                                },
                            },
                            {
                                headerName: "Dates",
                                fieldName: "blackoutStartDate",
                                isSorting: true,
                                Cell: (props) => {
                                    return (
                                        <span>{commonFunctions.formattedDate(props.blackoutStartDate)} <span>{" "} To {" "}</span> {commonFunctions.formattedDate(props.blackOutEndDate)}</span>
                                    );
                                },
                            },
                            {
                                headerName: "Active",
                                fieldName: "isActive",
                                isSorting: true,
                                Cell: (props) => {
                                    return (
                                        <Checkbox
                                            className="custom-toggle"
                                            checked={props.isActive}
                                            InDiscountAndCoupons={this.props.InDiscountAndCoupons === true ? true : false}
                                        onClick={(data, r) =>
                                            commonFunctions.activateDeactivateToggle(this, props.title, props.blackOutDateId, "blackOutDateId", 
                                            props.isActive)
                                        }
                                        ></Checkbox>
                                    )
                                }
                            },
                            {
                                headerName: "Action",
                                fieldName: "action",
                                isSorting: false,
                                Cell: (props) => {
                                    return (
                                        <React.Fragment>
                                            {" "}
                                            <Icon
                                                name="pencil alternate"
                                                size="large"
                                                title="Edit"
                                                className="deepviolet-color"
                                                onClick={() => this.blackOutDateModalModal(props)} />
                                            <Icon
                                                name="trash alternate"
                                                size="large"
                                                className="orange-color pointer"
                                                title="Delete"
                                                link
                                                onClick={() => commonFunctions.deleteModalMessage(this, props.title, props.blackOutDateId, "blackOutDateId",)}
                                            />
                                        </React.Fragment>
                                    );
                                },
                            },
                        ]}
                    />
                </Grid>
                
                {this.state.blackOutDateStatus &&
                    <BlackOutDateModal openModal={this.state.blackOutDateStatus} closeModal={this.blackOutDateModalModal}
                     blackOutDates={this.state.blackOutDates}
                      InDiscountAndCoupons={this.props.InDiscountAndCoupons}
                       getBlackOutDateDetail={() => this.child.current.getDefaultData()} isEditing={this.state.isEditing} />
                    }

                {isCommonDeleteUpdateModalOpen &&
                    <CommonDeleteUpdateModal
                        isModalOpen={isCommonDeleteUpdateModalOpen}
                        onConfirm={() => commonFunctions.onConfirmFunction(this, "blackOutDateId", "gridDetail", 
                        "DELETEBLACKOUTDATE",
                         "UPDATEBLACKOUTDATETOGGLE")}
                        onClose={() => commonFunctions.onCloseDeleteUpdateModal(this, "blackOutDateId")}
                        confirmationButtonText={buttonName}
                        header={messageToShow}
                        cancelButtonText={'Cancel'}
                    />
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
      api: state.api,
      global: state.global,
      auth: state.auth,
      warnUserState: state.warnUserState,
    };
  };
  const mapDispatchToProps = (dispatch) => {
    return {
      actions: {
        apiCall: bindActionCreators(actions.apiCall, dispatch),
        storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
        storeWarnUser: bindActionCreators(actions.storeWarnUser, dispatch),
        showNotification: bindActionCreators(Notifications.show, dispatch),
      },
    };
  };
// export default connect(mapStateToProps, mapDispatchToProps)(AddBlackOutDatesDiscount);
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(AddBlackOutDatesDiscount);
