import React, { Component } from "react";
import { Button, Grid, Modal, Header } from "semantic-ui-react";

class UndoModal extends Component {
  render() {
    return (
      <Modal
        open={this.props.openModal}
        closeIcon
        onClose={this.props.closeModal}
        size={"mini"}
      >
        <Modal.Content>
          <Grid>
            <Grid.Column width={14} textAlign="center">
              <Header as="h4" className="orange-color">
                Undo Last Action?
              </Header>
            </Grid.Column>

            <Grid.Column width={14} textAlign="center">
              <Button className="blue-btn"  onClick={this.props.closeModal}>Cancel</Button>
              <Button className="orange-btn"  onClick={this.props.closeModal}>Submit</Button>
            </Grid.Column>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}
export default UndoModal;
