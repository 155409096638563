import React, { Component } from "react";
import {
  Grid,
  Modal,
  Label,
  Form,
  Button,
  Header,
  Select,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import {
  commonFunctions,
  connect,
  bindActionCreators,
  actions,
  Notifications,
} from "../../../../../../functional/global-import";
import SplitSignatureModal from "../../../../modal/split-signature-modal";
import { orderCommonFunctions } from "../../order-common-function";

class GroupanModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      groupanModal: {
        balanceDue: 0,
        transactionAmount: null,
        balance: 0,
      },
      cashPaymentObj: {
        counterId: "",
      },
      countryOptions: [],
      isSignatureModalOpen: false,
      newTotalAmountPaid: null,
    };
    this.validator = commonFunctions.initializeSimpleValidator();
  }

  handleChange = (e, { value }) => {
    const { groupanModal } = this.state;
    const newAmount = parseFloat(value);
    if (newAmount >= 0) {
      groupanModal.transactionAmount = newAmount;
      groupanModal["balance"] = (groupanModal.balanceDue - newAmount).toFixed(
        2
      );
      this.setState({ ...groupanModal });
    } else {
      groupanModal.transactionAmount = null;
      groupanModal["balance"] = (groupanModal.balanceDue - 0).toFixed(2);
      this.setState({ ...groupanModal });
    }
  };

  componentDidMount() {
    const { propsState } = this.props;
    const { groupanModal } = this.state;
    if (propsState.orderState.currentOrder.pricings) {
      groupanModal["balanceDue"] =
        propsState.orderState.currentOrder.pricings.BalanceDue;
      groupanModal["transactionAmount"] =
        propsState.orderState.currentOrder.pricings.BalanceDue;
      groupanModal["balance"] = 0.0;
      this.setState({ groupanModal });
    }
    this.getCounterDetails();
  }

  // componentDidMount() {
  //   const { propsState } = this.props;
  //   const { groupanModal } = this.state;
  //   if (propsState.orderState.currentOrder.pricings) {
  //     groupanModal["balanceDue"] =
  //       propsState.orderState.currentOrder.pricings.BalanceDue;
  //     groupanModal["balance"] =
  //       propsState.orderState.currentOrder.pricings.BalanceDue -
  //       groupanModal.transactionAmount;
  //     this.setState({ groupanModal });
  //   }
  //   this.getCounterDetails();
  // }

  getCounterDetails = () => {
    //
    const { cashPaymentObj } = this.state;
    this.props.actions.apiCall({
      urls: ["GETCOUNTERNAME"],
      method: "GET",
      data: {
        businessId: this.props.global.bussinessDetail.businessId,
        PaymentMethod: -1,
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        PageNo: 1,
        PageSize: 100,
      },
      onSuccess: (response) => {
        const { defaultUserDetails } = this.props;
        const getCounter = response.map((singleCounter) => {
          return {
            value: singleCounter.counterId,
            key: singleCounter.counterId,
            text: singleCounter.counterName,
          };
        });

        const defaultCounterId = defaultUserDetails.defaultCounterId;
        const defaultCounter =
          getCounter.length > 0
            ? orderCommonFunctions.onSelectingDefaultDetail(
                getCounter,
                defaultCounterId,
                "value"
              )
            : null;
        if (getCounter.length > 0) {
          // cashPaymentObj["counterId"] = getCounter[0].value;
          cashPaymentObj["counterId"] = defaultCounter;
        }
        this.setState({ cashPaymentObj, countryOptions: getCounter });
      },
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const { propsState } = this.props;
    const { groupanModal } = this.state;
    if (prevProps.openModal !== this.props.openModal) {
      groupanModal["balanceDue"] =
        propsState.orderState.currentOrder.pricings.BalanceDue;
      groupanModal["balance"] =
        propsState.orderState.currentOrder.pricings.BalanceDue -
        groupanModal.transactionAmount;
      this.setState({ groupanModal });
      if (!this.props.openModal) {
        this.setState({
          groupanModal: {
            balanceDue: 0,
            transactionAmount: 0,
            balance: 0,
          },
        });
      }
    }
  }

  handleSubmit = (e) => {
    var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
    if (isFormValid) {
      const { selectedPaymentDetails } = this.props;
      let paymentTypeId;
      const { groupanModal } = this.state;
      if (!selectedPaymentDetails.paymentTypeId) {
        paymentTypeId = commonFunctions.getGlobalCodeDetails(
          this.props.propsState.global.codes,
          "PaymentMethod",
          selectedPaymentDetails.codeName
        ).globalCodeId;
      } else {
        paymentTypeId = selectedPaymentDetails.paymentTypeId;
      }
      const { propsState } = this.props;
      let data = {
        orderId: propsState.orderState.currentOrder.orderId,
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        IsAhoyShareONCash: propsState.orderState.currentOrder.isAhoyShareONCash,
        orderDetailId: null,
        paymentMethod: null,
        paymentTypeId: paymentTypeId,
        counterId: this.state.cashPaymentObj.counterId,
        amountPaid: parseFloat(groupanModal.transactionAmount),

        actionPerformedBy: propsState.auth.userDetail.emailId,
      };
      this.onPaymentFromPaymentTypes(data, selectedPaymentDetails);
    } else {
      return false;
    }
  };

  onPaymentFromPaymentTypes = (data, creditCardPayment, stateRef) => {
    this.props.actions.apiCall({
      urls: ["PAYMENTFROMPAYMENTTYPES"],
      method: "POST",
      data: data,
      showNotification: true,
      onSuccess: (response) => {
        this.props.actions.getOrderItems(
          this.props.orderState.currentOrder.orderId
        );
        this.props.closeModal();
        this.onSplitPaymentSubmit(data, response);
        return response;
      },
    });
  };

  onSplitPaymentSubmit = (data, response) => {
    this.setState({
      transactionDetails: { KeyId: response.keyId },
      isSignatureModalOpen: !this.state.isSignatureModalOpen,
      newTotalAmountPaid: data.amountPaid,
    });
  };

  onhandleChange = (e, { value }) => {
    const { cashPaymentObj } = this.state;
    cashPaymentObj.counterId = value;
    this.setState({ cashPaymentObj });
  };

  render() {
    const { selectedPaymentDetails, isApiLoading } = this.props;
                                               
    const {
      groupanModal,
      cashPaymentObj,
      countryOptions,
      isSignatureModalOpen,
      newTotalAmountPaid,
      transactionDetails,
    } = this.state;
                                               
    // *******************In new-order screen isAhoyShareONCash value  *************************//
    const AhoyShareONCash =
      this.props.orderState.currentOrder.isAhoyShareONCash;
    // ********** calculate the ahoy fee *************//
    const rehntitNewAmount =
      (this.props.orderState.currentOrder.rehntitshare / 100) *
      groupanModal.transactionAmount;
    const rehntitShareAmount1 =
      groupanModal.transactionAmount + rehntitNewAmount;
    const totalAmount = rehntitShareAmount1.toFixed(2);
    return (
      <>
        <Modal
          closeOnDimmerClick={false}
          open={this.props.openModal && !this.state.creditcardpaymentStatus}
          closeIcon
          onClose={this.props.closeModal}
          size={AhoyShareONCash ? "small" : "mini"}
        >
          <Modal.Content>
            {isApiLoading && commonFunctions.showLoader()}
            <Grid>
              <Grid.Column width={16}>
                <Header as="h3" className="orange-color">
                  {selectedPaymentDetails && selectedPaymentDetails.title}
                </Header>
              </Grid.Column>
              <Grid.Column width={6} verticalAlign="middle">
                <span>
                  {" "}
                  <b>Counter</b>
                </span>
              </Grid.Column>

              <Grid.Column width={10} verticalAlign="middle">
                <Select
                  placeholder="Select Cash Drawer"
                  value={cashPaymentObj.counterId}
                  name="counterId"
                  className="custom-select"
                  options={countryOptions}
                  fluid
                  onChange={this.onhandleChange}
                />
                {this.validator.message(
                  "Counter",
                  cashPaymentObj.counterId,
                  "required"
                )}
              </Grid.Column>

              <Grid.Column width={6}>
                <p className="orange-color">Balance Due</p>
              </Grid.Column>
              <Grid.Column width={10}>
                <p className="orange-color">${groupanModal.balanceDue}</p>
              </Grid.Column>
              <Grid.Column
                className="transactionAmount"
                width={4}
                verticalAlign="middle"
              >
                <p className="orange-color">Transaction Amount</p>
              </Grid.Column>
              <Grid.Column width={5}>
                <Form.Input
                  labelPosition="left"
                  type="number"
                  className="amountInput"
                  // name="amount"data="amount"
                  error={this.validator.message(
                    "amount",
                    groupanModal.transactionAmount,
                    "required|min:0,num"
                  )}
                  placeholder="Amount"
                  value={groupanModal.transactionAmount}
                  onChange={this.handleChange}
                >
                  <Label basic>$</Label>
                  <input />
                </Form.Input>
              </Grid.Column>

              {AhoyShareONCash ? (
                <>
                  {" "}
                  <Grid.Column width={2}>
                    <span className="plus-sign-credit-modal-right orange-color">
                      +
                    </span>
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <div className="rehntit-fee">
                      {/* <span className="plus-sign-credit-modal-right orange-color">+</span>  */}
                      {this.props.orderState.currentOrder.rehntitshare}% Booking
                      Fee
                    </div>
                  </Grid.Column>
                  <Grid.Column width={8} verticalAlign="middle">
                    <Link className="orange-color">Amount to Charge</Link>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Form.Input
                      name="totalPayment"
                      disabled={true}
                      labelPosition="left"
                      type="number"
                      className="amountInput"
                      placeholder="Amount"
                      // value={cashPaymentObj.totalPayment}
                      value={totalAmount}
                    ></Form.Input>
                  </Grid.Column>
                </>
              ) : null}
              <Grid.Column width={12}>
                <p className="deepviolet-color">Balance</p>
              </Grid.Column>
              <Grid.Column width={4}>
                <p className="deepviolet-color">$ {groupanModal.balance}</p>
              </Grid.Column>

              <Grid.Column width={16} textAlign="right">
                <Button
                  className="blue-btn"
                  disabled={isApiLoading}
                  onClick={this.props.closeModal}
                >
                  Cancel
                </Button>
                <Button
                  className="orange-btn"
                  disabled={groupanModal.balance < 0}
                  loading={isApiLoading}
                  onClick={(e) => this.handleSubmit(e)}
                >
                  Charge
                </Button>
              </Grid.Column>
            </Grid>
          </Modal.Content>
        </Modal>

        {isSignatureModalOpen && (
          <SplitSignatureModal
            transactionDetails={transactionDetails}
            onSignSubmit={() => this.props.closeModal()}
            splitAmount={newTotalAmountPaid}
            isModalOpen={isSignatureModalOpen}
            onClose={() => this.setState({ isSignatureModalOpen: false })}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    api: state.api,
    auth: state.auth,
    global: state.global,
    newOrderDetail: state.newOrderDetail,
    orderState: state.orderState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      getOrderItems: bindActionCreators(actions.getOrderItems, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(GroupanModal);
