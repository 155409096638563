import React, { Component } from "react";
import {
  Modal,
  Grid,
  Button,
  Form,
  Label,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import AddCustomTipModal from "../../../organisms/modal/add-custom-trip-modal";
class AddTipModal extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { fullState, allFunction, paymentSelectKey, showTipAmountActive ,showTipClass } =
      this.props;

    return (
      <div>
        <Modal
          open={this.props.openModal}
          onClose={this.props.onClose}
          size={"larger"}
          closeOnDimmerClick={false}
        >
          {fullState.isLoading && (
            <Dimmer active inverted>
              {" "}
              <Loader size="small"></Loader>{" "}
            </Dimmer>
          )}
          <Grid style={{ padding: "40px" }}>
            <Grid.Column
              mobile={16}
              tablet={16}
              computer={16}
              textAlign="center"
              className="mt-3"
            >
              <h1>
                {" "}
                <strong>Would you like to leave a tip? </strong>{" "}
              </h1>
              <h2 textAlign="center"></h2>
              <div className="tipAmountInput">
                <Form.Input
                  name="amountPaid"
                  readOnly
                  textAlign="center"
                  labelPosition="left"
                  className="amountInput padding-amount"
                  placeholder="Amount"
                  value={fullState.totalAmountCharge}
                >
                  <Label basic>$</Label>
                  <input />
                </Form.Input>
              </div>
              <div className="multiAmountInput">
                <Form.Input
                  name="amountPaid"
                  readOnly
                  textAlign="center"
                  labelPosition="left"
                  className="amountInput padding-amount"
                  placeholder="Amount"
                  value={this.props.rehntitShareAmount}
                >
                  <Label basic>$</Label>
                  <input />
                </Form.Input>{" "}
                <span className="plus-sign-credit-modal orange-color">+</span>
                <Form.Input
                  name="amountPaid"
                  readOnly
                  textAlign="center"
                  labelPosition="left"
                  className="amountInput padding-amount"
                  placeholder="Amount"
                  value={fullState.tipTotalValue}
                >
                  <Label basic>$</Label>
                  <input />
                </Form.Input>
              </div>
            </Grid.Column>
            <Grid.Column
              mobile={16}
              tablet={16}
              computer={16}
              textAlign="center"
            >
              {" "}
              <Grid className="special-tip-amount">
                <Grid.Column
                  className={showTipClass ?" amount-col showTipActiveClass firstCol" :"amount-col firstCol"}
                  onClick={() => allFunction.onClickNotipFunction()}
                >
                  No Tip
                </Grid.Column>
                {fullState.tipDetailData &&
                  fullState.tipDetailData.map((tipData, index) => {
                    const tipShowActive = fullState.tipDetailData.length > 1 ? "amount-col active" :"amount-col "
                    const removtheClass = index===1 ?tipShowActive :"amount-col"

                                           
                    return (
                      <Grid.Column className="multiple-tip">
                        <Button
                          className={showTipAmountActive?"amount-col":removtheClass}
                          onClick={() =>
                            allFunction.onClickTipAdded(tipData.DollarTip)
                          }
                        >
                          {tipData.IsShowTipType ? (
                            <span> {tipData.SingleValue}</span>
                          ) : (
                            <span>
                              <span>{tipData.LargerValue}</span>

                              <div className="smallValue"> {tipData.SmallerValue}</div>
                            </span>
                          )}
                        </Button>
                      </Grid.Column>
                    );
                  })}
                <Grid.Column
                  className="amount-col lastCol"
                  onClick={() => allFunction.onClickCustomTipAdded()}
                >
                  Custom Tip
                </Grid.Column>
              </Grid>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} textAlign="right">
              <Button
                className="blue-btn"
                onClick={this.props.onbackButton}
                style={{ marginTop: "15px" }}
              >
                Back
              </Button>
              <Button
                className="orange-btn"
                onClick={() => allFunction.onContinuePay()}
                style={{ marginTop: "15px" }}
              >
                Continue & Pay
              </Button>
            </Grid.Column>
          </Grid>
        </Modal>
        {fullState.customTipAdded && (
          <AddCustomTipModal
          closeModal={allFunction.onClickCustomTipAdded}
            openModal={fullState.customTipAdded}
            fullState={fullState}
            allFunction={allFunction}
          />
        )}
      </div>
    );
  }
}

export default AddTipModal;
