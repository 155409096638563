import React, { Fragment } from "react";
import { Image, Grid, Header } from "semantic-ui-react";
import PropTypes from "prop-types";
import {Link} from "../../../functional/global-import";
const SettingBox = ({ imageUrl, title,routeTo, onHandleMoreSetting }) => {
 
  return (
    <Fragment>
      <Grid.Column textAlign="center">
        <div className="cmn-shad">
        <Link to={routeTo}  onClick={onHandleMoreSetting}>
          <Image src={imageUrl} size="tiny" centered />
          <Header as="h4">{title}</Header>
          </Link>
        </div>
      </Grid.Column>
    </Fragment>
  );
};

SettingBox.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  routeTo: PropTypes.string.isRequired,
};

export default SettingBox;
