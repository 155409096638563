import React, { Component } from "react";
import { Grid, Form, Divider, Header } from "semantic-ui-react";
import { commonFunctions } from "../../../functional/global-import";

class CommonAvailableOn extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onHandleChange = (e, { name, value, type, checked, data }) => {
    const { fullState, stateForm, allFunction } = this.props;
    var cancellationPolicy = commonFunctions.onHandleChange(
      e,
      { name, value, type, checked, data },
      fullState[stateForm],
      allFunction
    );
    fullState[stateForm] = cancellationPolicy;
    allFunction.setState({ ...fullState });
  };

  render() {
    const { fullState, stateForm, isDivider } = this.props;
    return (
      <React.Fragment>
        <Grid.Column width={16}>
          {!isDivider === true && <Divider />}
        </Grid.Column>
        <Grid.Column width={16}>
          <Header as="h4">Available On</Header>
        </Grid.Column>
        <Grid.Column mobile={8} tablet={4} computer={4}>
          <Form.Checkbox
            label="In Store"
            data="inStore"
            checked={fullState[stateForm].inStore ? true : false}
            onChange={this.onHandleChange}
            className="custome-checkbox"
          />
        </Grid.Column>
        <Grid.Column mobile={8} tablet={8} computer={8}>
          <Form.Checkbox
            label="Website"
            data="websiteWidget"
            checked={fullState[stateForm].websiteWidget ? true : false}
            onChange={this.onHandleChange}
            className="custome-checkbox"
          />
        </Grid.Column>
      </React.Fragment>
    );
  }
}
export default CommonAvailableOn;
