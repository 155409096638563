import React, { Component } from "react";
import { Grid, Form, Label } from "semantic-ui-react";
import { Link } from "react-router-dom";
class AhoyShareOnCash extends Component {
  constructor(props) {
    super();
  }
  render() {
    const {
      AhoyShareONCash,
      rehntitShareAmount,
      orderState,
      customValue,
    } = this.props;
                   
    return (
        <>
          {AhoyShareONCash ? (
            <>
              <Grid.Column width={5} verticalAlign="middle">
                <Link className="orange-color">Amount to Charge</Link>
              </Grid.Column>
              <Grid.Column width={4}>
                <Form.Input
                  name="totalPayment"
                  disabled={customValue !== "Custom Amount" ? true : false}
                  labelPosition="left"
                  type="number"
                  className="amountInput"
                  placeholder="Amount"
                  value={rehntitShareAmount}
                >
                  <Label basic>$</Label>
                  <input />
                </Form.Input>
              </Grid.Column>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Grid.Column width={2}>
                <span className="plus-sign-credit-modal-right orange-color">
                  +
                </span>
              </Grid.Column>
              <Grid.Column width={4}>
                <div className="rehntit-fee">
                  {orderState.currentOrder.rehntitshare}% Booking Fee.
                </div>
              </Grid.Column>
            </>
          ) : (
            null
          )}
        </>
    
    );
  }
}

export default AhoyShareOnCash;
