import React from "react";
import propTypes from "prop-types";
// import { propTypes } from 'react-notification-system';
import { Button, Form, Grid, Modal, Header } from "semantic-ui-react";
import { connect } from "../../../../functional/global-import";

const CommonEmailUpdateModal = ({
  header,
  onConfirm,
  confirmationButtonText,
  confirmationText,
  isModalOpen,
  onConfirmEmail,
  onClose,
  api,
  subHeader,
  cancelButtonText,
}) => {
  return (
    <Modal open={isModalOpen} closeIcon={false} onClose={onClose} size={"tiny"}>
      <Modal.Content>
        <Form loading={api.isApiLoading}>
          <Grid>
            <Grid.Column width={16} textAlign="center">
              <Header as="h3" className="orange-color">
                {header}
              </Header>
              {subHeader && (
                <Modal.Content>
                  <Modal.Description className="orange-color">
                    {subHeader}
                  </Modal.Description>
                </Modal.Content>
              )}
            </Grid.Column>
            <Grid.Column width={16} textAlign="center">
              <Button className="blue-btn" onClick={onClose}>
                {cancelButtonText}
              </Button>{" "}
              &nbsp;&nbsp;
              <Button className="orange-btn" onClick={onConfirm}>
                {confirmationButtonText}
              </Button>
              <Button className="orange-btn" onClick={onConfirmEmail}>
                {confirmationText}
              </Button>
            </Grid.Column>
          </Grid>
        </Form>
      </Modal.Content>
    </Modal>
  );
};
const mapStateToProps = (state) => {
  return {
    api: state.api,
  }
};
CommonEmailUpdateModal.propTypes = {
  isModalOpen: propTypes.bool.isRequired,
  onClose: propTypes.bool.isRequired,
  onConfirm: propTypes.func.isRequired,
  onConfirmEmail:propTypes.func.isRequired,
  header: propTypes.string.isRequired,
  cancelButtonText: propTypes.string.isRequired,
  confirmationButtonText: propTypes.string.isRequired,
};
export default connect(mapStateToProps)(CommonEmailUpdateModal);
