import React, { Component } from "react";
import { Button, Form, Grid, Modal, Checkbox, Dimmer, Loader } from "semantic-ui-react";
import { connect, bindActionCreators, actions, Notifications, commonFunctions } from "../../../../functional/global-import";
import { DateFormatInput } from '../../../../components';
import moment from 'moment';
import AddBlackOutDatesDiscount from "../../../organisms/modal/add-blackout-dates/blackoutDateDiscount"
// "../../../shared/components/organisms/modal/add-blackout-dates/blackoutDateDiscount";

class AddBlackOutDates extends Component {
  // Put all key & values that need to reset on form submit and reset form.
  // child = React.createRef()
  get initialState() {
    return {
      blackOutdate: {
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId: this.props.global.locationSelected.BusinessLocationId,
        BlackOutStartDate: "", BlackOutEndDate: "",
        actionPerformedBy: this.props.auth.userDetail.emailId,
        isActive: true,
        InDiscountAndCoupons: this.props.commonBlackOut === false ? false : true,
        // this.props.blackOutDates.inDiscountAndCoupons === false ? false : true,
        title: "", blackOutDateId: -1, pageNo: 1, pageSize: 100
      },
      // todayDate: "",
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      ...this.initialState,
      blackOutDateDetail: [],
      blackOutUpdateButton: false,
      updated: true,
    };
    this.validator = commonFunctions.initializeSimpleValidator();

  }
  static getDerivedStateFromProps(props, state) {
    if (props.isEditing && props.blackOutDates.blackOutDateId !== undefined) {
      if (props.isEditing && props.blackOutDates.blackOutDateId !== state.blackOutdate.blackOutDateId) {
        return {
          blackOutdate: {
            ...state.blackOutdate,
            blackOutDateId: props.blackOutDates.blackOutDateId,
            BlackOutEndDate: commonFunctions.formattedDate(props.blackOutDates.blackOutEndDate),
            BlackOutStartDate: commonFunctions.formattedDate(props.blackOutDates.blackoutStartDate),
            businessId: props.blackOutDates.businessId,
            title: props.blackOutDates.title,
            // isActive: props.blackOutDates.isActive,
            businessLocationId: props.blackOutDates.businessLocationId,
          }
        }
      }
    }
    return null;
  }

  handleChangeDate = (e, { name, value }) => {
    e.preventDefault();
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  };
  onHandleChange = (e, { name, value, type, checked, data }) => {
    if (data === "BlackOutStartDate") {
      this.setState({ blackOutdate: { ...this.state.blackOutdate, BlackOutStartDate: value, BlackOutEndDate: value } });
    } else {
      this.setState({ blackOutdate: { ...this.state.blackOutdate, [data]: value } });
      // var blackOutdate = commonFunctions.onHandleChange(e, { name, value, type, checked, data }, this.state.blackOutdate);
      // this.setState({ blackOutdate });
    }
  }
  handleChangeMonth = (event, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  }
  editBlackoutDate = (blackOutData) => {
    this.state.blackOutdate = blackOutData;
    let sDate = blackOutData.blackoutStartDate;
    let eDate = blackOutData.blackOutEndDate;
    var startDate = new Date(sDate);
    var sDateFormat = moment(startDate).format('MMM DD, YYYY');
    var endDate = new Date(eDate);
    var eDateFormat = moment(endDate).format('MMM DD, YYYY');
    this.setState({
      ...this.state, blackOutdate: {
        ...this.state.blackOutdate,
        title: blackOutData.title,
        blackOutStartDate: sDateFormat,
        blackOutEndDate: eDateFormat
      }
    });
  }

  postBlackOutdate = (e) => {
    var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
    if (isFormValid) {
      let data = Object.assign({}, this.state.blackOutdate);
      data["BlackOutStartDate"] = commonFunctions.saveFormattedDate(data["BlackOutStartDate"]);
      data["BlackOutEndDate"] = commonFunctions.saveFormattedDate(data["BlackOutEndDate"]);
      if (data.BlackOutStartDate <= data.BlackOutEndDate) {
        this.props.actions.apiCall({
          urls: ["ADDBLACKOUTDATE"], method: "POST", data: data,
          onSuccess: (response) => {

            this.setState(this.initialState, this.props.closeModal);
            this.child.current.childsChild.current.getDefaultData();
            this.props.getBlackOutDateDetail();
          }, showNotification: true
        });
      }
      else {
        this.props.actions.showNotification({
          title: "Warning",
          message: 'Start Date is greater than end date',
          position: 'br', autoDismiss: commonFunctions.notificationTime()
        }, "error");
      }
    }
  }

  onCloseModal = () => {
    if (this.props.warnUserState.isFormChanged) {
      this.setState({ isCommonDeleteUpdateModalOpen: true })
      this.validator = commonFunctions.initializeSimpleValidator();
    } else {
      this.onModalCloseFormFalse();
    }
  }

  onModalCloseFormFalse = () => {
    this.props.closeModal();
    this.setState(this.initialState);
    this.validator = commonFunctions.initializeSimpleValidator();
  }

  deleteBlackOutDates = (i) => {
    const rows = [...this.state.blackOutRows.blackoutDates];
    rows.splice(i, 1)
    this.setState({ blackOutRows: { ...this.state.blackOutRows, blackoutDates: rows } });
    const rr = [...this.state.addBundal.productBlackOutDates];
    rr.splice(i, 1)
    this.setState({ addBundal: { ...this.state.addBundal, productBlackOutDates: rr } });
  }

  blackOutDateListUpdate = (e) => {
    const blackOutdate = this.state.blackOutdate;
    blackOutdate.forEach((blackOut) => {
      if (blackOut.blackOutDateId === e.keyId) {
        blackOut.isActive = !blackOut.isActive;
      }
    });
    this.setState({ blackOutdate: blackOutdate, isCommonDeleteUpdateModalOpen: !this.state.isCommonDeleteUpdateModalOpen })
  }

  updateBlackOutDate = (e) => {
    var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
    if (isFormValid) {
      const { blackOutdate } = this.state
      var data =
      {
        blackOutDateId: blackOutdate.blackOutDateId,
        businessId: blackOutdate.businessId,
        title: blackOutdate.title,
        isActive: blackOutdate.isActive,
        businessLocationId: blackOutdate.businessLocationId,
        BlackOutStartDate: commonFunctions.saveFormattedDate(blackOutdate.BlackOutStartDate),
        BlackOutEndDate: commonFunctions.saveFormattedDate(blackOutdate.BlackOutEndDate),
        InDiscountAndCoupons: blackOutdate.InDiscountAndCoupons,
        actionPerformedBy: blackOutdate.actionPerformedBy
      }

      if (data.BlackOutStartDate <= data.BlackOutEndDate) {
        this.props.actions.apiCall({
          urls: ["UPDATEBLACKOUTDATE"], method: "PUT", data: data, onSuccess: (response) => {
            this.setState(this.initialState, this.props.closeModal);
            this.props.getBlackOutDateDetail();
          }, isFormData: true, showNotification: true,
        });
      }
      else {
        this.props.actions.showNotification({
          title: "Warning",
          message: 'Start Date is greater than end date', position: 'br', autoDismiss: commonFunctions.notificationTime()
        }, "error");
      }
    }
  }

  closeModal = () => {
    this.props.closeModal();
    this.setState(this.initialState);
    this.setState({ updated: true });
  }

  isActiveToggal = (e, { checked }) => {
    const { blackOutdate } = this.state
    if (!checked) {
      blackOutdate.isActive = false
      this.setState({ blackOutdate })
    }
    if (checked) {
      blackOutdate.isActive = true
      this.setState({ blackOutdate })
    }
  }

  render() {
    const { blackOutdate, todayDate } = this.state
    const { api } = this.props;
    return (
      <>
        <Modal open={this.props.openModal} closeIcon onClose={this.closeModal} closeOnDimmerClick={false} size="small">
          <Modal.Header>Add Blackout Dates</Modal.Header>
          <Modal.Content>
            <Form>
              {api.isApiLoading && (
                <Dimmer active inverted>
                  <Loader />
                </Dimmer>)}
              <Grid>
                <Grid.Column computer={16} tablet={16}>
                  <Form.Input fluid textAlign="left" name="txtTitle" placeholder="Title" maxLength="50"
                    error={this.validator.message("title", blackOutdate.title, "required|min:0|max:100")} data="title"
                    value={blackOutdate.title} onChange={this.onHandleChange} />
                </Grid.Column>

                <Grid.Column computer={8} tablet={16}>
                  <DateFormatInput name="txtblackOutStartDate" placeholder="Start Blackout date"
                    value={blackOutdate.BlackOutStartDate} iconPosition="right" onChange={this.onHandleChange} data="BlackOutStartDate"
                    error={this.validator.message("startBlackoutDate", blackOutdate.BlackOutStartDate, "required")} />
                </Grid.Column>

                <Grid.Column computer={8} tablet={16}>
                  <DateFormatInput id={"endBlackoutDate"} name="txtblackOutEndDate" placeholder="End Blackout date"
                    minDate={blackOutdate.BlackOutStartDate ? blackOutdate.BlackOutStartDate : ""}
                    value={blackOutdate.BlackOutEndDate} iconPosition="right" onChange={this.onHandleChange} data="BlackOutEndDate"
                    error={this.validator.message("endBlackoutDate", blackOutdate.BlackOutEndDate, "required")} />
                </Grid.Column>

                <Grid.Column width={8} tablet={16} textAlign="right">
                  {/* <Button className="orange-btn">Clear</Button>
                 */}
                  <Button className="blue-btn" onClick={() => commonFunctions.onCancelInitialState(this)}>Clear</Button>
                  {blackOutdate.blackOutDateId > 0 ?
                    <Button className="orange-btn" onClick={this.updateBlackOutDate}>Update</Button>
                    : <Button className="orange-btn" onClick={this.postBlackOutdate}>Save</Button>}
                </Grid.Column>
              </Grid>
            </Form>
          </Modal.Content>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch)
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddBlackOutDates);