import React, { Component } from "react";
import { Button, Grid, Modal, Header, Form, Icon, Popup } from "semantic-ui-react";
import { commonFunctions } from "../../../../../../functional/global-import";

class CreatePaymentType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentTypeForm: {
        businessId: "", businessLocationId: "", isActive: true, paymentTypeTitle: "", paymentTypeDescription: "", actionPerformedBy: "",
      }
    };
    this.validator = commonFunctions.initializeSimpleValidator();
  }
  handleSubmit = (e) => {
    var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
    if (isFormValid) {
      const { paymentTypeForm } = this.state;


      paymentTypeForm["businessId"] = this.props.propsState.global.bussinessDetail.businessId;
      paymentTypeForm["businessLocationId"] = this.props.propsState.global.locationSelected.BusinessLocationId;
      paymentTypeForm["actionPerformedBy"] = this.props.propsState.auth.userDetail.emailId;


      this.props.apiCall({
        urls: ["ADDPAYMENTTYPE"], method: "POST", showNotification: true, data: paymentTypeForm, onSuccess: (response) => {
          this.props.getPaymentType();
          this.props.closeModal()
          return response
        },
      });
    }
    else {
      return false
    }
  }
  onHandleChange = (e, { name, value, type, checked, data, }) => {
    var paymentTypeForm = commonFunctions.onHandleChange(e, { name, value, type, checked, data }, this.state.paymentTypeForm, this);
    this.setState({ paymentTypeForm });
  };
  render() {
    const { paymentTypeForm } = this.state;
    return (
      <Modal closeOnDimmerClick={false} open={this.props.openModal} closeIcon onClose={this.props.closeModal} size={"mini"}>
        <Modal.Content>
          <Grid>
            <Grid.Column width={16}>
              <Header as="h4" className="orange-color"> Create Payment Type &nbsp;
                <Popup
                  trigger={<Icon name="info circle" />}
                  content={<p className="orange-color"> To edit “Payment Types” edit in the “Settings – Payment Types” section after saving.</p>}
                  basic
                />
              </Header>
            </Grid.Column>
            <Grid.Column width={16}>
              <Form>
                <Form.Input
                  placeholder="Title"
                  fluid
                  id="title"
                  value={paymentTypeForm.paymentTypeTitle}
                  name="txtPaymentTypeTitle"
                  data="paymentTypeTitle"
                  error={this.validator.message("paymentTypeTitle", paymentTypeForm.paymentTypeTitle, "required|max:100")}
                  onChange={this.onHandleChange}
                />
              </Form>
            </Grid.Column>
            <Grid.Column width={16}>
              <Form>
                <Form.Input
                  placeholder="Description"
                  fluid
                  id="description" value={paymentTypeForm.paymentTypeDescription} name="txtPaymentTypeDescription" data="paymentTypeDescription" error={this.validator.message("paymentTypeDescription", paymentTypeForm.paymentTypeDescription, "required|max:200")} onChange={this.onHandleChange} />
              </Form>
            </Grid.Column>
            <Grid.Column width={16} textAlign="right">
              <Button className="blue-btn" onClick={this.props.closeModal}>
                Cancel
              </Button>
              <Button className="orange-btn" loading={this.props.propsState.api.isApiLoading} onClick={(e) => this.handleSubmit(e)}>
                Save
              </Button>
            </Grid.Column>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}
export default CreatePaymentType