import React, { Component } from "react";
import { Icon } from "semantic-ui-react";
import { commonFunctions } from "../../../../shared/functional/global-import";
import { DataTable } from '../../../../shared/components/organisms';

class RentalGrid extends Component {
    childsChild = React.createRef();
    render() {
        
        const { fullState, allFunction } = this.props;
        return (
            <DataTable
                ref={this.childsChild}
                getApiName="GETRENTALPRODUCTPRICE"
                searchOption={{ show: false, placeHolder: "Search" }}
                getGridDataParamters={{ "productId": this.props.productId }}
                // defaultSort={{sortColumn:"modifiedDate",sortOrder:"desc"}}
                // defaultPageSize={10}
                hideEmptyGrid={true}
                gridName="RentalPricingGrid"
                screenName="pricing&taxes"
                fullState={fullState}
                allFunction={allFunction}
                tableHeight="250px"
                emptyGrid="No records to show"
                columns={[
                    {
                        headerName: "Type",
                        fieldName: "type",
                        isSorting: true,
                        title: "type"
                    },
                    {
                        headerName: "Title",
                        fieldName: "rentalPricingTitle",
                        isSorting: true,
                        className: "textOverflowHidden",
                        Cell: (props) => { 
                            return (
                                <span title={props.rentalPricingTitle}>{props.rentalPricingTitle}</span>
                              );
                           },
                    },
                    {
                        headerName: "Duration",
                        fieldName: "durationName",
                        className: "textOverflowHidden",
                        isSorting: true,
                        Cell: (props) => {
                            return (
                                props.durationName === null ? <span > - </span> : <span title={props.quantity + " " + props.durationName}> {props.quantity} {props.durationName}</span>
                            );
                        },
                    },
                    {
                        headerName: "Season",
                        fieldName: "timeFrame",
                        isSorting: true,
                        className: "textOverflowHidden",
                        Cell: (props) => { 
                            return (
                                <span title={props.timeFrame}>{props.timeFrame}</span>
                              );
                           },
                    },
                    {
                        headerName: "Price",
                        fieldName: "itemPrice ",
                        isSorting: true,
                        className: "rentalPriceAlign",
                        Cell: (props) => {
                            return (
                                props.itemPrice === null ? <div> - </div> : <div>{commonFunctions.isNumberInteger(props.itemPrice)}
                                  </div>
                            );
                        },
                    },
                    {
                        headerName: "Deposit",
                        fieldName: "deposit",
                        isSorting: true,
                        className: "rentalPriceAlign",
                        Cell: (props) => {
                            return (
                                props.deposit === null ? <div> - </div> : <div>{commonFunctions.isNumberInteger(props.deposit)}
                                 </div>
                            );
                        },
                    },
                    {
                        headerName: "Action",
                        fieldName: "action",
                        isSorting: false,
                        Cell: (props) => {
                            return (
                                <React.Fragment>
                                    <Icon
                                        name="pencil alternate"
                                        size="large"
                                        title="Edit"
                                        className="deepviolet-color"
                                        onClick={() => allFunction.editProductPrice(props)}
                                    />
                                    <Icon
                                        name="trash alternate"
                                        size="large"
                                        className="orange-color"
                                        title="Delete"
                                        link
                                        onClick={() => commonFunctions.deleteModalMessage(allFunction, props.rentalPricingTitle, props.rentalPricingId,
                                            "rentalPricingId")}
                                    />
                                </React.Fragment>
                            );
                        },
                    },
                ]}
            />
        );
    }
}

export default (RentalGrid);
