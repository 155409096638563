import React, { Component } from "react";
import { Grid, Form, Button, Checkbox, Header, GridColumn } from "semantic-ui-react";
import { commonFunctions, connect, actions, bindActionCreators, Notifications } from "../../../shared/functional/global-import";
import CommonCropImage from '../../../shared/components/atoms/common-crop-image';
import { CommonDeleteUpdateModal } from "../../../shared/components";
import AddOnsGrid from './add-ons-grid';

class AddOnsForm extends Component {
    child = React.createRef();
    // Put all key & values that need to reset on form submit and reset form.
    get initialState() {
        return {
            addOnsForm: {
                businessId: this.props.global.bussinessDetail.businessId, addOnTitle: "", addOnPrice: "",
                businessLocationId: this.props.global.locationSelected.BusinessLocationId, image: [],
                actionPerformedBy: this.props.auth.userDetail.emailId, addOnId: "", isActive: true,
                minAddOns: "", maxAddOns: ""
            },
            displayButton: "Save",
            isAddOnsFormLoading: false,
            addonsDataDetail: {
                businessId: this.props.global.bussinessDetail.businessId, addOnTitle: "", addOnPrice: "",
                businessLocationId: this.props.global.locationSelected.BusinessLocationId,
                image: [], actionPerformedBy: this.props.auth.userDetail.emailId, addOnId: "",
                isActive: true
            },
            fileList: [],
        };
    }
    constructor(props) {
        super(props);
        this.state = {
            ...this.initialState, customFields: false, customFieldsButton: true, gridDetail: [],
            isCommonDeleteUpdateModalOpen: false, addOnId: "", addOnsImageId: ""
        };
        this.validator = commonFunctions.initializeSimpleValidator();
    }
    // // This function is used to set handle change
    onHandleChange = (e, { name, value, type, checked, data }) => {
        var addOnsForm = commonFunctions.onHandleChange(e, {
            name, value, type, checked, data
        }, this.state.addOnsForm, this);
        this.setState({ addOnsForm });
    }
    editAddon = (addOndata) => {
        this.state.addOnsForm = addOndata
        const imageData = JSON.parse(addOndata.image)
        const imageDetail = [{
            uid: '2',
            status: 'done',
            url: imageData === null ? commonFunctions.concatenateImageWithAPIUrl(null) :
                commonFunctions.concatenateImageWithAPIUrl(imageData[0].ImageFile),
        }]
        this.setState({
            ...this.state, addOnsForm: { ...this.state.addOnsForm, actionPerformedBy: this.props.auth.userDetail.emailId },
            fileList: imageDetail, imageData, addOnsImageId: addOndata.addOnId, ImageId: imageData === null ? null : imageData[0].ImageId, displayButton: "Update"
        });
    }

    // this function is used to prevent Press Enter inside Input field  
    onEnterEventHandler = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    }
    onHandleMaxPerOrderChange = (e, { value, data }) => {
        const { minAddOns } = this.state.addOnsForm;
        var ex = /^(?:\d*\d{1,2}|\d+)$/;
        if (ex.test(value) === true) {
            this.setState({ addOnsForm: { ...this.state.addOnsForm, [data]: parseInt(value) } });
        }
        if (ex.test(value) === false) {
            this.setState({ addOnsForm: { ...this.state.addOnsForm, [data]: null } })
        }
    };

    onHandleMinPerOrder = (e, { name, value, type, checked, data }) => {
        if (data === "minAddOns") {
            var ex = /^(?:\d*\d{1,2}|\d+)$/;
            if (ex.test(value) === true) {
                this.setState({ addOnsForm: { ...this.state.addOnsForm, [data]: parseInt(value) } });
            }
        }
        if (ex.test(value) === false) {
            this.setState({ addOnsForm: { ...this.state.addOnsForm, [data]: null } });
        }
    };
    onHandleSubmit = (e) => {
        const { addOnsForm } = this.state;
        const isValid = addOnsForm.minAddOns <= addOnsForm.maxAddOns;
        if (isValid) {
            commonFunctions.onHandleSubmit(e,
                this,
                "isAddOnsFormLoading",
                "addOnsForm",
                "ADDADDONS",
                "UPDATEADDONS", true)
        }
        else {
            this.props.actions.showNotification(
                {
                    title: "Warning",
                    message: `Max Per Order item can not less then min per order.`,
                    position: "br",
                    autoDismiss: commonFunctions.notificationTime(),
                },
                "error"
            );
        }
    }

    render() {
        const { addOnsForm, isCommonDeleteUpdateModalOpen, messageToShow,
            buttonName, displayButton, isAddOnsFormLoading, commonAddons } = this.state;
        const fullState = this.state;
        const allFunction = this;
        return (
            <React.Fragment>
                <Form>
                <Grid>
                    <Grid.Column mobile={12} tablet={4} computer={4} textAlign="center">
                        <Grid>
                            <Grid.Column mobile={4} tablet={6} computer={6} textAlign="center">
                                <CommonCropImage
                                    ImageType={"AddOnImage"}
                                    fullState={this.state}
                                    cropId={this.state.addOnsImageId}
                                    StateRef={this}
                                    isDefaultImage={false}
                                    stateForm={this.state.addOnsForm} />
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column mobile={12} tablet={12} computer={12} textAlign="center">
                        <Grid>                        
                            <Grid.Column mobile={8} tablet={8} computer={8}>
                                <Form.Input placeholder="Title" onKeyPress={this.onEnterEventHandler} className="bordered-input"
                                    // maxLength="50"
                                    fluid onChange={this.onHandleChange}
                                    error={this.validator.message("addOnTitle", addOnsForm.addOnTitle, "required|alpha space|")}
                                    name="txtaddOnTitle" data="addOnTitle" value={addOnsForm.addOnTitle} />
                            </Grid.Column>
                            <Grid.Column mobile={8} tablet={8} computer={8}>
                                <Form.Input placeholder="Price" onKeyPress={this.onEnterEventHandler} className="bordered-input"
                                    type="number" maxLength="50" fluid onChange={this.onHandleChange}
                                    error={this.validator.message("addOnPrice", addOnsForm.addOnPrice,
                                        "required|min:0,num")} name="txtaddOnTitle" data="addOnPrice"
                                    value={addOnsForm.addOnPrice} />
                            </Grid.Column>
                            <Grid.Column mobile={16} tablet={12} computer={12}>
                                <Header className="perOrder " as="h4">Per Order Min/Max</Header>
                            </Grid.Column>
                            <Grid.Column mobile={7} tablet={7} computer={7}>
                                <label class="dFlex"> <b>Min.</b></label>
                                <Form.Input placeholder="Min."
                                    data="minAddOns"
                                    value={addOnsForm.minAddOns}
                                    onChange={this.onHandleMinPerOrder}
                                    className="qty"
                                    error={this.validator.message("addOnPrice", addOnsForm.minAddOns,
                                        `integer|numeric|min:1,num`)}

                                />
                            </Grid.Column>
                            <Grid.Column mobile={6} tablet={6} computer={6}>
                                <label class="dFlex"><b>Max.</b></label>
                                <Form.Input placeholder="Max."
                                    data="maxAddOns"
                                    value={addOnsForm.maxAddOns}
                                    type="number"
                                    onChange={this.onHandleMaxPerOrderChange}
                                    className="qty"
                                    error={this.validator.message("addOnPrice", addOnsForm.maxAddOns,
                                        `integer|numeric|min:1,num`)}
                                />
                            </Grid.Column>
                            {!this.props.hideToggleForModal &&
                               <Grid.Column mobile={3} tablet={3} computer={3}>
                                    <Grid.Column mobile={2} tablet={4} computer={3} textAlign="center" className="addOnsActive activeToggle">
                                        <Checkbox label="Active" data="isActive" name="chkisActive" checked={addOnsForm.isActive} value={addOnsForm.isActive} onChange={this.onHandleChange} className="custom-toggle" />
                                    </Grid.Column>
                                </Grid.Column>
                            }
                        </Grid>
                    </Grid.Column>
                    
                </Grid>
                   
                    <Grid reversed='computer tablet'>
                        <Grid.Column mobile={8} tablet={8} computer={8} textAlign="right">
                            <Button className="blue-btn" onClick={() => commonFunctions.onCancelInitialState(allFunction)}>Clear</Button>
                            <Button className="orange-btn" loading={isAddOnsFormLoading}
                                onClick={(e) => this.onHandleSubmit(e)}
                            // onClick={(e) => commonFunctions.onHandleSubmit(e,
                            //     allFunction,
                            //     "isAddOnsFormLoading",
                            //     "addOnsForm",
                            //     "ADDADDONS",
                            //     "UPDATEADDONS", true)}
                            >
                                {displayButton}
                            </Button>
                        </Grid.Column>
                        {/* ==========showGrid=========Grid Component===============  */}
                        {this.props.showGrid && <AddOnsGrid ref={this.child} fullState={fullState}
                            allFunction={allFunction} />}
                    </Grid>
                </Form>
                {isCommonDeleteUpdateModalOpen &&
                    <CommonDeleteUpdateModal
                        isModalOpen={isCommonDeleteUpdateModalOpen}
                        onConfirm={() => commonFunctions.onConfirmFunction(allFunction,
                            "addOnId",
                            "gridDetail",
                            "DELETEADDONS",
                            "UPDATEADDONTOGGLE")}
                        onClose={() => commonFunctions.onCloseDeleteUpdateModal(allFunction, "addOnId")}
                        confirmationButtonText={buttonName}
                        header={messageToShow}
                        cancelButtonText={'Cancel'}
                    />
                }
            </React.Fragment>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        api: state.api,
        global: state.global,
        auth: state.auth,
        warnUserState: state.warnUserState,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            apiCall: bindActionCreators(actions.apiCall, dispatch),
            storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
            storeWarnUser: bindActionCreators(actions.storeWarnUser, dispatch),
            showNotification: bindActionCreators(Notifications.show, dispatch),
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddOnsForm);