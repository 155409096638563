import React, { Component } from "react";
import {
  connect,
  bindActionCreators,
  actions,
  Notifications,
  commonFunctions,
} from "../../../../shared/functional/global-import";
import {
  Grid,
  Form,
  Button,
  Header,
  Checkbox,
  Divider,
  Input,
  GridColumn,
} from "semantic-ui-react";
import {
  CommonDeleteUpdateModal,
  CommonCustomField,
} from "../../../../shared/components/organisms";
import ActivityGrid from "./activity-grid";
import EmailNotification from "./email-notification";
// import SavedDiscountModal from "../../../../shared/components/organisms/modal/new-order-modal/payment/discount-and-coupon-modal/saved-discount-modal";

class ActivityForm extends Component {
  child = React.createRef();
  get initialState() {
    return {
      activityPrice: {
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        activityId: this.props.activityId !== "" ? this.props.activityId : null,
        activityPricingId: "",
        title: "",
        price: "",
        description: "",
        generalActivityPricingId: "",
        min: null,
        max: null,
        isActive: true,
        actionPerformedBy: this.props.auth.userDetail.emailId,
        pageNo: 1,
        pageSize: 10,
        timeFrameId: "",
        customField: [],

        // New Fields
        isEmailBooked: false,
        roleId: null,
        toEmail: [],
        singleGroupPrice: true,
        inStore: true,
        websiteWidget: true,
        // discountAndCouponsId: null,
        // discountAndCouponsTitle: null
      },
      displayButton: "Save",
      isActivityAddBtnLoading: false,
      showDiscountModal: false,
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      ...this.initialState,
      gridDetail: [],
      value: "this",
      advance: false,
      activityPriceDropdownList: [],
      onChangeFields: false,
      editButton: false,
      customField: [],
      activityTimeFrameList: [],
      activityPricing: [],
      isCommonDeleteUpdateModalOpen: false,
      addNewPrice: false,
      userDetails: [],
    };
    this.validator = commonFunctions.initializeSimpleValidator();
    this.validatorGeneral = commonFunctions.initializeSimpleValidator();
  }

  componentDidMount = () => {
    //
    //==============  get api call for title dropdown ==============
    commonFunctions.getFunction(
      this,
      "GETACTIVITYPRICE",
      "activityPricingId",
      "title",
      "activityPriceDropdownList"
    );
    //============== TimeFrame get api call ==============
    commonFunctions.getFunction(
      this,
      "GETTIMEFRAME",
      "timeFrameId",
      "timeFrameTitle",
      "activityTimeFrameList"
    );
  };
  componentDidUpdate = () => {
    const { activityPrice } = this.state;
    if (
      this.state.activityTimeFrameList.length > 0 &&
      activityPrice.timeFrameId === ""
    ) {
      const defaultSeason = this.state.activityTimeFrameList.filter(
        (code) => code.text === "All (Season)"
      );
      activityPrice.timeFrameId = defaultSeason[0] && defaultSeason[0].id;
      this.setState(activityPrice);
    }
  };
  handleAddition = (e, { value, name }) => {
    if (name === "activityPricingTitle") {
      // var { activityPriceDropdownList, activityPrice } = this.state;
      this.setState((prevState) => ({
        activityPriceDropdownList: [
          { text: value, id: value, value: value },
          ...prevState.activityPriceDropdownList,
        ],
        activityPrice: {
          ...prevState.activityPrice,
          activityPricingId: value,
          title: value,
        },
      }));
    }
    commonFunctions.onFormChange(this);
  };

  //=============== OnChange of Title dropdown ===============//
  onHandleChangeTitle = (e, { value, data, name }) => {
    this.onhandleClearPriceForm();
    const isNumber = isNaN(value);
    if (!isNumber) {
      this.props.actions.apiCall({
        urls: ["GETACTIVITYPRICE"],
        method: "GET",
        data: {
          businessId: this.props.global.bussinessDetail.businessId,
          businessLocationId:
            this.props.global.locationSelected.BusinessLocationId,
          PageNo: 1,
          PageSize: 100,
          activityPricingId: value,
        },
        onSuccess: (response) => {
          const { activityPrice } = this.state;
          activityPrice.actionPerformedBy = this.props.auth.userDetail.emailId;
          activityPrice.customField = JSON.parse(
            response[0].customField ?? "[]"
          );
          activityPrice.title = response[0].title;
          activityPrice.activityPricingId = response[0].activityPricingId;
          activityPrice.timeFrameId = response[0].timeFrameId;
          activityPrice.price = response[0].price;
          activityPrice.description = response[0].description;
          activityPrice.isEmailBooked = response[0].isEmailBooked;
          activityPrice.roleId = response[0].roleId;
          activityPrice.toEmail = JSON.parse(response[0].toEmail ?? "[]");
          activityPrice.min = response[0].min;
          activityPrice.max = response[0].max;
          activityPrice.singleGroupPrice = response[0].singleGroupPrice;
          activityPrice.inStore = response[0].inStore;
          activityPrice.websiteWidget = response[0].websiteWidget;

          if (this.props.activityId === undefined) {
            this.setState({ activityPrice, displayButton: "Update" });
          } else {
            this.setState({ activityPrice, displayButton: "Save" });
          }
        },
      });
    } else {
      this.onhandleClearPriceForm();
    }
  };

  //Edit Activity Function
  editActivityPrice = (price) => {
      
    var { activityPrice, activityPriceDropdownList } = this.state;
    let priceId = activityPriceDropdownList.filter(
      (item) => item.text === price.title
    );
    activityPrice = {
      businessId: price.businessId,
      businessLocationId: price.businessLocationId,
      activityId: price.activityId,
      activityPricingId: price.activityPricingId,
      generalActivityPricingId: price.generalActivityPricingId,
      title: price.title,
      value: price.title,
      text: price.title,
      id: priceId,
      timeFrameId: price.timeFrameId,
      price: price.price,
      description: price.description,
      min: price.min === null ? "" : price.min,
      max: price.max === null ? "" : price.max,
      isActive: price.isActive,
      isEmailBooked: price.isEmailBooked,
      roleId: price.roleId,
      toEmail: JSON.parse(price.toEmail ?? "[]"),
      singleGroupPrice: price.singleGroupPrice ?? true,
      inStore: price.inStore ?? true,
      websiteWidget: price.websiteWidget ?? true,
      customField: JSON.parse(price.customField),
      actionPerformedBy: this.props.auth.userDetail.emailId,
    };
    this.setState({ activityPrice, displayButton: "Update" });
  };

  onhandleClearPriceForm = () => {
    this.setState({
      activityPrice: {
        ...this.state.activityPrice,
        generalActivityPricingId: "",
        activityPricingId: "",
        title: "",
        price: "",
        description: "",
        min: "",
        max: "",
        isActive: true,
        timeFrameId: "",
        customField: [],
        isEmailBooked: false,
        roleId: null,
        toEmail: [],
        advance: false,
        inStore: true,
        websiteWidget: true,
      },
    });
    this.setState({ displayButton: "Save" });
  };

  onHandleSubmitButton = (e) => {
    let b = JSON.stringify(this.state.activityPrice, function (key, value) {
      return value === "" ? null : value;
    });
    if (this.validator.allValid() === false) {
      this.validator.showMessages();
      this.forceUpdate();
      this.props.actions.showNotification(
        {
          title: "Warning",
          message: "Please fill all required fields.",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
      this.setState({
        activityPrice: { ...this.state.activityPrice, valid: false },
      });
      return false;
    }
    if (this.state.displayButton === "Save") {
      this.props.actions.apiCall({
        urls: ["ADDACTIVITYPRICE"],
        method: "POST",
        data: JSON.parse(b),
        onSuccess: (response) => {
          this.setState({ activityPricingId: response.keyId });
          this.child.current.childsChild.current.getDefaultData();
          commonFunctions.getFunction(
            this,
            "GETACTIVITYPRICE",
            "activityPricingId",
            "title",
            "activityPriceDropdownList"
          );
          commonFunctions.onApiSucessFormChangedFalse(this);
          this.onhandleClearPriceForm();
        },
        showNotification: true,
      });
      this[e] = commonFunctions.initializeSimpleValidator();
    }
    if (this.state.displayButton === "Update") {
      this.props.actions.apiCall({
        urls: ["UPDATEACTIVITYPRICE"],
        method: "PUT",
        data: JSON.parse(b),
        onSuccess: (response) => {
          this.child.current.childsChild.current.getDefaultData();
          commonFunctions.onApiSucessFormChangedFalse(this);
          this.onhandleClearPriceForm();
        },
        showNotification: true,
      });
      this[e] = commonFunctions.initializeSimpleValidator();
    }
  };

  showadvance = () => {
    this.setState({ advance: !this.state.advance });
  };

  onHandleChangePrice = (e, { name, value, type, checked, data }) => {
    var ex = /^\d*\.?(?:\d{1,2})?$/;
    if (ex.test(value) === true) {
      var activityPrice = commonFunctions.onHandleChange(
        e,
        { name, value, type, checked, data },
        this.state.activityPrice,
        this
      );
      this.setState({ activityPrice });
    }
  };

  onHandleItemPricing = (e, { name, value, type, checked, data }) => {
    var ex = /^\d*\.?(?:\d{1,2})?$/;

    if (ex.test(value) === true) {
      e.preventDefault();
      var activityPrice = commonFunctions.onHandleChange(
        e,
        { name, value, type, checked, data },
        this.state.activityPrice
      );
      this.setState({ activityPrice });
    }
  };

  // This function is used to set handle change
  onHandleChange = (e, { name, value, type, checked, data }) => {
    if (name !== "txtMin" && name !== "txtMax") {
      if (name === "timeFrameId" && isNaN(value)) {
      } else {
        e.preventDefault();
        let activityPrice = commonFunctions.onHandleChange(
          e,
          { name, value, type, checked, data },
          this.state.activityPrice
        );
        this.setState({ activityPrice });
      }
    }
    if (name === "txtMin") {
      if (value.toString().length <= 9) {
        e.preventDefault();
        let activityPrice = commonFunctions.onHandleChange(
          e,
          { name, value, type, checked, data },
          this.state.activityPrice
        );
        this.setState({ activityPrice });
      }
    }
    if (name === "txtMax") {
      if (value.toString().length <= 9) {
        e.preventDefault();
        let activityPrice = commonFunctions.onHandleChange(
          e,
          { name, value, type, checked, data },
          this.state.activityPrice
        );
        this.setState({ activityPrice });
      }
    }
  };

  // Need to do after 31st
  // handleShowDiscountModal = () => {
  //   this.setState({ showDiscountModal: !this.state.showDiscountModal });
  // };

  // handleSubmitDiscountModal = (selectedDiscountCoupon,selectedDiscountCouponTitle) => {
  //   const { activityPrice } = this.state;
  //   activityPrice.discountAndCouponsId = selectedDiscountCoupon;
  //   activityPrice.discountAndCouponsTitle = selectedDiscountCouponTitle;
  //   this.setState(activityPrice)
  // }

  render() {
    const fullState = this.state;
    const allFunction = this;
    const {
      activityPrice,
      activityTimeFrameList,
      buttonName,
      isCommonDeleteUpdateModalOpen,
      messageToShow,
      activityPriceDropdownList,
      displayButton,
      isActivityAddBtnLoading,
      showDiscountModal,
    } = this.state;

    return (
      <div className="cmn-shad" id="form_section">
        <Grid>
          <Grid.Column width={"16"}>
            <Header as="h3">Activity Pricing</Header>
          </Grid.Column>
          <Grid.Column width={3}>
            <Form.Dropdown
              search
              fluid
              selection
              allowAdditions
              placeholder="Title"
              name="activityPricingTitle"
              value={
                activityPrice.activityId === null
                  ? activityPrice.activityPricingId
                  : activityPrice.generalActivityPricingId === ""
                    ? activityPrice.activityPricingId
                    : activityPrice.generalActivityPricingId
              }
              className="form-control"
              onAddItem={this.handleAddition}
              data="activityPricingId"
              options={activityPriceDropdownList}
              onChange={this.onHandleChangeTitle}
              error={this.validator.message(
                "title",
                activityPrice.activityPricingId,
                "required"
              )}
            />
          </Grid.Column>
          <Grid.Column width={3}>
            <Form.Select
              options={activityTimeFrameList}
              search
              data="timeFrameId"
              value={activityPrice.timeFrameId}
              type="select"
              fluid
              placeholder="Season"
              onChange={this.onHandleItemPricing}
            // error={this.flatFeeValidator.message("Season", aa && addItemPricing.timeFrameId,
            // "required")}
            />
          </Grid.Column>
          <Grid.Column width={3}>
            <Input
              placeholder="Price"
              data="price"
              value={activityPrice.price}
              fluid
              onChange={this.onHandleChangePrice}
              label={{ basic: true, content: "$" }}
              labelPosition="left"
            />
            {this.validator.message("price", activityPrice.price, "required")}
          </Grid.Column>

          <Grid.Column width={4}>
            <Form.Input
              fluid
              textAlign="left"
              type="text"
              name="txtDescription"
              placeholder="Description"
              data="description"
              onChange={this.onHandleChange}
              value={activityPrice.description}
            />
            {this.validator.message(
              "description",
              activityPrice.description,
              "min:0|max:500"
            )}
          </Grid.Column>

          {this.props.activityId === undefined && (
            <Grid.Column
              width={2}
              textAlign="center"
              className="generalTaxActive"
            >
              <Checkbox
                label="Active"
                data="isActive"
                name="chkisActive"
                checked={activityPrice.isActive}
                value={activityPrice.isActive}
                onChange={this.onHandleChange}
                className="custom-toggle"
              />
            </Grid.Column>
          )}

          {/* <CommonCustomerQuestions
            fullState={this.state}
            allFunction={this}
            stateForm="activityPrice"
            variableName="customField" /> */}
          <CommonCustomField
            fullState={this.state}
            allFunction={this}
            stateForm="activityPrice"
            variableName="customField"
            showRequired={true}
          />
          <Grid.Column computer={16} tablet={16} mobile={16}>
            <div className="chooseCheckOptionWrapper">
              {/* <Checkbox
                label="Single Group Price"
                data="singleGroupPrice"
                name="chksingleGroupPrice"
                checked={activityPrice.singleGroupPrice}
                value={activityPrice.singleGroupPrice}
                onChange={this.onHandleChange}
                className="custome-checkbox"
              /> */}
            </div>
          </Grid.Column>
          <Grid.Column width={16}>
            <Divider />
          </Grid.Column>
          <Grid.Column computer={16} className="bookedEmailWrappers">
            <Grid>
              <Grid.Column width={3}>
                <Button
                  className="deepviolet-button"
                  onClick={this.showadvance}
                >
                  Advance
                </Button>
              </Grid.Column>
              {this.state.advance && (
                <>
                  <Grid.Column width={13}>
                    <Grid>
                      <Grid.Column width={6}>
                        <Grid>
                          <Grid.Column width={8}>
                            <Checkbox
                              label="In Store"
                              data="inStore"
                              name="chkinStore"
                              checked={activityPrice.inStore}
                              value={activityPrice.inStore}
                              onChange={this.onHandleChange}
                              className="custome-checkbox"
                            />
                          </Grid.Column>
                          <Grid.Column width={8}>
                            <Checkbox
                              label="Website"
                              data="websiteWidget"
                              name="chkwebsiteWidget"
                              checked={activityPrice.websiteWidget}
                              value={activityPrice.websiteWidget}
                              onChange={this.onHandleChange}
                              className="custome-checkbox"
                            />
                          </Grid.Column>
                          <Grid.Column width={8}>
                            <Form.Input
                              fluid
                              textAlign="left"
                              type="number"
                              name="txtMin"
                              placeholder="Min"
                              data="min"
                              onChange={this.onHandleChange}
                              value={activityPrice.min}
                            />
                            {this.validator.message(
                              "min",
                              fullState.activityPrice.min,
                              `min:0,num`
                            )}
                          </Grid.Column>
                          <Grid.Column width={8}>
                            <Form.Input
                              fluid
                              textAlign="left"
                              type="number"
                              name="txtMax"
                              placeholder="Max"
                              data="max"
                              onChange={this.onHandleChange}
                              value={activityPrice.max}
                            />
                            {this.validator.message(
                              "max",
                              fullState.activityPrice.max,
                              `min:0,num|validMinMaxValues:${activityPrice.min}:Value`
                            )}
                          </Grid.Column>
                        </Grid>
                      </Grid.Column>
                      <Grid.Column width={10}>
                        <Grid>
                          <Grid.Column width={16}>
                            <div className="bookedEmail">
                              <EmailNotification
                                fullState={this.state}
                                stateForm="activityPrice"
                                allFunction={this}
                              />
                            </div>
                          </Grid.Column>
                        </Grid>
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                  {/* {!fullState.activityPrice.discountAndCouponsTitle ? <Button
                  type='button'
                  className="orange-btn noWrap"
                  loading={isActivityAddBtnLoading}
                  onClick={this.handleShowDiscountModal}
                >
                  Add Auto Discount
                </Button> : <div className="cross-icon-image">{fullState.activityPrice.discountAndCouponsTitle} <button onClick={() => this.handleSubmitDiscountModal(null,null)} type='button'>Close</button> </div>
                } */}
                </>
              )}
            </Grid>
          </Grid.Column>
          <Grid.Column width={16}>
            <Grid reversed="computer tablet">
              <Grid.Column tablet={16} computer={16} textAlign="right">
                <Button
                  className="blue-btn"
                  onClick={this.onhandleClearPriceForm}
                >
                  Clear
                </Button>
                <Button
                  className="orange-btn"
                  loading={isActivityAddBtnLoading}
                  onClick={(e) => this.onHandleSubmitButton(e)}
                >
                  {displayButton}
                </Button>
              </Grid.Column>
              {activityPrice && (
                <ActivityGrid
                  ref={this.child}
                  fullState={fullState}
                  allFunction={allFunction}
                  activityId={
                    this.props.activityId ? this.props.activityId : null
                  }
                />
              )}
            </Grid>
          </Grid.Column>
        </Grid>
        {isCommonDeleteUpdateModalOpen && (
          <CommonDeleteUpdateModal
            isModalOpen={isCommonDeleteUpdateModalOpen}
            onConfirm={() =>
              commonFunctions.onConfirmFunction(
                allFunction,
                "activityPricingId",
                "gridDetail",
                "DELETEACTIVITYPRICE",
                "ACTIVITYPRICINGTOGGLE",
                { activityId: this.props.activityId }
              )
            }
            confirmationButtonText={buttonName}
            header={messageToShow}
            onClose={() =>
              commonFunctions.onCloseDeleteUpdateModal(
                allFunction,
                "activityPricingId"
              )
            }
            cancelButtonText={"Cancel"}
          />
        )}
        {/* Use it after 31st march */}
        {/* {showDiscountModal && <SavedDiscountModal
          openModal={showDiscountModal}
          closeModal={this.handleShowDiscountModal}
          handleSubmit={this.handleSubmitDiscountModal}
        />} */}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ActivityForm);
