import React, { Component } from "react";
import { Grid, Form, Label } from "semantic-ui-react";
import { Link } from "react-router-dom";
class CustomOnCash extends Component {
  constructor(props) {
    super();
  }
  render() {
    const {cashPaymentObj,orderState,customCashValue,customValue} = this.props;
    return (
      
        <>
          <Grid.Column width={5} verticalAlign="middle">
            <Link className="orange-color">Before Fee</Link>
          </Grid.Column>
          <Grid.Column width={4}>
            <Form.Input
              name="totalPayment"
              disabled={customValue !== "Custom Amount" ? true : false}
              labelPosition="left"
              type="number"
              className="amountInput"
              placeholder="Amount"
              value={customCashValue}
            >
              <Label basic>$</Label>
              <input />
            </Form.Input>
          </Grid.Column>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Grid.Column width={2}>
            <span className="plus-sign-credit-modal-right orange-color">+</span>
          </Grid.Column>
          <Grid.Column width={4}>
            <div className="rehntit-fee">
              {/* <span className="plus-sign-credit-modal-right orange-color">+</span>  */}
              {orderState.currentOrder.rehntitshare}% Booking Fee.
            </div>
          </Grid.Column>
          <Grid.Column width={5} verticalAlign="middle">
            <Link className="orange-color">Amount to Charge</Link>
          </Grid.Column>
          <Grid.Column width={4}>
            <Form.Input
              name="totalPayment"
              disabled={customValue !== "Custom Amount" ? true : false}
              labelPosition="left"
              type="number"
              className="amountInput"
              placeholder="Amount"
              value={cashPaymentObj.totalPayment}
            >
              <Label basic>$</Label>
              <input />
            </Form.Input>
          </Grid.Column>
        </>
        
    );
  }
}

export default CustomOnCash;
