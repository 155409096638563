import React, { Fragment } from "react";
import { Image, Grid, Icon, Card, Divider } from "semantic-ui-react";
import PropTypes from "prop-types";
import { commonFunctions } from "../../../functional/global-import";

const CategoryGridCard = ({
  onClickItemDetail,
  handleShowGrid,
  imageUrl,
  onEditIconClick,
  onDeleteIconClick,
  categoryName,
  type,
  subCategoryItems,
  mainfiest,
  totalItems,
  onItemMove,
  onCategoryClick,
  categoryDetail,
  onCopyLinkClick,
  copyLinkId,
  index,
  color,
}) => {
                             
  // let productSpecificId = `product${categoryDetail.productId}`
  return (
    <Fragment>
      <Grid.Column key={index}>
        <Card fluid className="cmn-shad" key={index}>
          {/*  for rental in  new order only  */}
          {handleShowGrid &&
          categoryDetail.type === "Rental" &&
          categoryDetail.areAllProductsReserved === true ? (
            <span
              onClick={onClickItemDetail}
              className="inventoryItemAvailability"
            >
              Item might not be available
            </span>
          ) : (handleShowGrid &&
              categoryDetail.type === "Season Pass" &&
              categoryDetail.areAllProductsReserved === true) ||
            (handleShowGrid &&
              categoryDetail.type === "Rental" &&
              categoryDetail.available === "Product may not be available") ? (
            <span
              onClick={onClickItemDetail}
              className="inventoryItemAvailability"
            >
              Item might not be available
            </span>
          ) : null}

          {/*  for retail */}
          {
            // categoryDetail.type.indexOf("Retail") > -1 && categoryDetail.areAllProductsReserved === true ?
            //   <span className="inventoryItemAvailability">Out Of Stock.</span> :
            categoryDetail.type.indexOf("Retail") > -1 &&
            categoryDetail.retailAvailability !== null &&
            categoryDetail.retailAvailability === "Out Of Stock." ? (
              <span className="inventoryItemAvailability">Out Of Stock.</span>
            ) : null
          }

          {!handleShowGrid && (
            <div className="right-icons">
              {categoryDetail.type === "Activity" ? (
                <Icon
                  name="clipboard list"
                  size="large"
                  className="indigo-color"
                  title="Activity Manifest"
                  onClick={mainfiest}
                />
              ) : (
                false
              )}

              {categoryDetail.type === "Rental" ||
              categoryDetail.type === "Bundle" ||
              categoryDetail.type === "Activity" ||
              categoryDetail.type === "Retail-Bulk" ||
              categoryDetail.type === "Season Pass" ||
              categoryDetail.type === "Retail-Trackable" ? (
                <Icon
                  name="move"
                  size="large"
                  className="maroon-color"
                  title="Move to"
                  onClick={onItemMove}
                />
              ) : (
                false
              )}
              <Icon
                name="pencil alternate"
                size="large"
                className="deepviolet-color"
                title="Edit"
                onClick={onEditIconClick}
              />
              <Icon
                name="trash alternate"
                size="large"
                className="orange-color"
                title="Delete"
                onClick={onDeleteIconClick}
              />
            </div>
          )}

          <div
          // onClick={onCategoryClick}
          >
            {imageUrl === null ? (
              <span
                className="catergoryInventory"
                style={{ background: !color ?  "#f54952": color }}
              >
                {" "}
              </span>
            ) : (
              <div className="thumbnailImage">
                <Image
                  src={commonFunctions.concatenateImageWithAPIUrl(imageUrl)}
                  size="large"
                  centered
                  textAlign="center"
                  onClick={onCategoryClick}
                />
              </div>
            )}

            {(categoryDetail.type === "Rental" && categoryDetail.price === 0) ||
            (categoryDetail.type === "Season Pass" &&
              categoryDetail.price === 0) ||
            (categoryDetail.type === "Bundle" && categoryDetail.price === 0) ||
            (categoryDetail.type === "Activity" &&
              categoryDetail.price === 0) ||
            (categoryDetail.type === "Retail-Bulk" &&
              categoryDetail.price === 0) ||
            (categoryDetail.type === "Retail-Trackable" &&
              categoryDetail.price === 0) ? (
              <span className="inventoryItemPrice">Price is not available</span>
            ) : (
              false
            )}

            {categoryDetail.type === "Category" &&
            categoryDetail.isActive === false ? (
              <span className="inventoryCategory" onClick={onCategoryClick}>
                {" "}
                Category is not Active
              </span>
            ) : categoryDetail.type === "Subcategory" &&
              categoryDetail.isActive === false ? (
              <span className="inventoryCategory" onClick={onCategoryClick}>
                {" "}
                Subcategory is not Active
              </span>
            ) : (
              false
            )}

            {/* <Grid.Column>
              <Divider />
            </Grid.Column> */}
            <Card.Description className="order-description">
              <Grid columns="2">
                <Grid.Column largeScreen={8} onClick={onCategoryClick}>
                  <p className="deepviolet-color" title={categoryName}>
                    {" "}
                    {categoryName}
                  </p>
                </Grid.Column>
                <Grid.Column textAlign="right" largeScreen={8}>
                  {categoryDetail.type === "Category" &&
                  categoryDetail.count > 1 ? (
                    <p className="maroon-color" onClick={onCategoryClick}>
                      <b>{subCategoryItems} Subcategories</b>
                    </p>
                  ) : (
                    categoryDetail.type === "Category" &&
                    categoryDetail.count > -1 && (
                      <p className="maroon-color" onClick={onCategoryClick}>
                        <b>{subCategoryItems} Subcategory</b>
                      </p>
                    )
                  )}

                  {categoryDetail.type === "Subcategory" &&
                  categoryDetail.count > 1 ? (
                    <p className="maroon-color" onClick={onCategoryClick}>
                      <b>{subCategoryItems} Subcategories</b>
                    </p>
                  ) : (
                    categoryDetail.type === "Subcategory" &&
                    categoryDetail.count > -1 && (
                      <p className="maroon-color" onClick={onCategoryClick}>
                        <b>{subCategoryItems} Subcategory</b>
                      </p>
                    )
                  )}
                  {/* ============== */}
                  {type === "Rental" ||
                  type === "Retail-Trackable" ||
                  type === "Retail-Bulk" ||
                  type === "Activity" ||
                  type === "Bundle" ||
                  type === "Season Pass" ? (
                    <p className="maroon-color" onClick={onCopyLinkClick}>
                      <b>Copy Link</b> &nbsp;
                      {copyLinkId === categoryDetail.productId ? (
                        <Icon
                          name="copy"
                          size="large"
                          className="orange-color"
                          title="Copied Link"
                        />
                      ) : (
                        <Icon
                          name="copy outline"
                          size="large"
                          title="Copy Link"
                        />
                      )}
                    </p>
                  ) : null}
                </Grid.Column>
                <Grid.Column onClick={onCategoryClick}>
                  {type === "Rental" ? (
                    <p className="maroon-color">
                      <b>Item</b>
                    </p>
                  ) : type === "Retail-Trackable" ? (
                    <p className="maroon-color">
                      <b>Item</b>
                    </p>
                  ) : type === "Retail-Bulk" ? (
                    <p className="maroon-color">
                      <b>Item</b>
                    </p>
                  ) : null}
                  {type === "Season Pass" ? (
                    <p className="maroon-color">
                      <b>Passes</b>
                    </p>
                  ) : null}
                </Grid.Column>
                <Grid.Column textAlign="right" onClick={onCategoryClick}>
                  {categoryDetail.businessCategoryType === "Rental" &&
                  totalItems > 1 ? (
                    <p className="orange-color">
                      <b>{totalItems} Items</b>
                    </p>
                  ) : categoryDetail.businessCategoryType === "Rental" &&
                    totalItems === 1 ? (
                    <p className="orange-color">
                      <b> {totalItems} Item</b>
                    </p>
                  ) : (
                    categoryDetail.businessCategoryType === "Rental" && (
                      <p className="orange-color">
                        <b> {totalItems} Item</b>
                      </p>
                    )
                  )}
                  {categoryDetail.businessCategoryType === "Season Pass" &&
                  totalItems > 1 ? (
                    <p className="orange-color">
                      <b>{totalItems} Items</b>
                    </p>
                  ) : categoryDetail.businessCategoryType === "Season Pass" &&
                    totalItems === 1 ? (
                    <p className="orange-color">
                      <b> {totalItems} Item</b>
                    </p>
                  ) : (
                    categoryDetail.businessCategoryType === "Season Pass" && (
                      <p className="orange-color">
                        <b>{totalItems} Item</b>
                      </p>
                    )
                  )}

                  {categoryDetail.businessCategoryType === "Retail" &&
                  totalItems > 1 ? (
                    <p className="orange-color">
                      <b>{totalItems} Items</b>
                    </p>
                  ) : categoryDetail.businessCategoryType === "Retail" &&
                    totalItems === 1 ? (
                    <p className="orange-color">
                      <b> {totalItems} Item</b>
                    </p>
                  ) : (
                    categoryDetail.businessCategoryType === "Retail" && (
                      <p className="orange-color">
                        <b>{totalItems} Item</b>
                      </p>
                    )
                  )}

                  {categoryDetail.type === "Category" ||
                  categoryDetail.type === "Subcategory"
                    ? categoryDetail.businessCategoryType === "Activity" &&
                      totalItems > -1 && (
                        <p className="orange-color">
                          <b>{totalItems} Activity</b>
                        </p>
                      )
                    : null}

                  {categoryDetail.type === "Category" ||
                  categoryDetail.type === "Subcategory"
                    ? categoryDetail.businessCategoryType === "Bundle" &&
                      totalItems > -1 && (
                        <p className="orange-color">
                          <b>{totalItems} Bundle</b>
                        </p>
                      )
                    : categoryDetail.businessCategoryType === "Bundle" &&
                      categoryDetail.type === "Bundle" && (
                        <p
                          className="orange-color"
                          title={categoryDetail.bundleProducts}
                        >
                          <b>{categoryDetail.bundleProducts} </b>
                        </p>
                      )}
                </Grid.Column>
              </Grid>
            </Card.Description>
          </div>
        </Card>
      </Grid.Column>
    </Fragment>
  );
};

CategoryGridCard.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  categoryName: PropTypes.string.isRequired,
  subCategoryItems: PropTypes.string.isRequired,
  totalItems: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  routeTo: PropTypes.string.isRequired,
  onEditIconClick: PropTypes.func.isRequired,
  onDeleteIconClick: PropTypes.func,
};

export default CategoryGridCard;
