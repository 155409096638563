import React, { Component } from "react";
import {
  Button,
  Form,
  Grid,
  Modal,
  Dimmer,
  Loader,
  Label,
  GridColumn,
} from "semantic-ui-react";
import {
  commonFunctions,
  connect,
  bindActionCreators,
  actions,
  Notifications,
} from "../../../../functional/global-import";
import { orderCommonFunctions } from "../new-order-modal/order-common-function";
import SplitSignatureModal from "../split-signature-modal";
import { Link } from "react-router-dom";
import { CardElement, ElementsConsumer } from "@stripe/react-stripe-js";
import AddTipModal from "../../../organisms/modal/add-tipAmount-modal";
const months = [
  { key: "01", value: "01", text: "Jan" },
  { key: "02", value: "02", text: "Feb" },
  { key: "03", value: "03", text: "Mar" },
  { key: "04", value: "04", text: "Apr" },
  { key: "05", value: "05", text: "May" },
  { key: "06", value: "06", text: "Jun" },
  { key: "07", value: "07", text: "Jul" },
  { key: "08", value: "08", text: "Aug" },
  { key: "09", value: "09", text: "Sep" },
  { key: "10", value: "10", text: "Oct" },
  { key: "11", value: "11", text: "Nov" },
  { key: "12", value: "12", text: "Dec" },
];
class ManualCardModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addProductsTocart: {
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId: parseInt(
          this.props.global.locationSelected.BusinessLocationId
        ),
        orderId: this.props.orderState.currentOrder.orderId,
        amount: this.props.totalPayment,
        counterId: this.props.counterId,
        cardNumber: null,
        cvv: null,
        month: null,
        year: null,
        token: null,
        selectPaymentType: this.props.selectPaymentType,
        productItemPaid: null,
        paymentMethod: commonFunctions.getGlobalCodeDetails(
          this.props.global.codes,
          "PaymentMethod",
          "Card"
        ).globalCodeId,
        paymentMethodSubTypeId:this.props.selectedGlobalCodeId,
        actionPerformedBy: this.props.auth.userDetail.emailId,
      },
      yearDropdown: [],
      isLoading: false,
      splitSignatureModal: false,
      transactionDetails: null,
      newTotalAmountPaid: null,
      paymentConnectKey: null,
      stripeConnectButton: false,
      isAddtipModal: false,
      tipDetailData: [],
      tipTotalValue: null,
      customtipTotalValue: null,
      totalAmountCharge: null,
      isTipLoading: false,
      tipPrice: null,
      customTipAdded: false,
      showTipClass: false,
      showTipAmountActive: false,
    };
    this.validator = commonFunctions.initializeSimpleValidator();
                       
  }
  componentDidMount() {
    let currentYear = new Date().getFullYear();
    let allYears = Array(10)
      .fill("")
      .map((v, idx) => {
        return {
          key: currentYear + idx,
          value: currentYear + idx,
          text: currentYear + idx,
        };
      });
    this.setState({ yearDropdown: allYears });
    const paymentType = this.props.paymentSelect.find(
      (a) => a.value === this.props.selectPaymentType
    );
    const defaultPaymentType =
      this.props.paymentSelect?.length > 0
        ? this.props.paymentSelect?.find((a) => a.key === "Stripe") ||
          this.props.paymentSelect?.find((a) => a.key === "EdgePay")
        : null;
    this.setState({
      paymentSelectKey: defaultPaymentType.key,
      paymentConnectKey: paymentType.key,
    });
  }
  GlobalCodeCodeIdShorted = () => {
    const globalCodesPaymentSelection = this.props.global.codes
      .filter((code) => code.categoryName === "PaymentGateway")
      .map((filtercode) => {
        return {
          key: filtercode.codeName,
          value: filtercode.globalCodeId,
          text: filtercode.codeName,
        };
      });
    this.setState({ paymentSelect: globalCodesPaymentSelection });
  };

  onChangeProductDetails = (e, { name, value, type, checked, data }) => {
    var addProductsTocart = commonFunctions.onHandleChange(
      e,
      { name, value, type, checked, data },
      this.state.addProductsTocart,
      this
    );
    this.setState({ addProductsTocart });
  };

  // for hold payment capture
  onHoldDeposit = (e, stripeTokenId) => {
    var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
    if (isFormValid) {
      this.setState({ isLoading: true });
      const addProductsTocart = this.state.addProductsTocart;
      let totalItemsSelectedDetails = [];
      const { orderState } = this.props;
      let paymentDoneAlready =
        orderState.currentOrder.productItemPaid &&
        JSON.parse(orderState.currentOrder.productItemPaid);
      paymentDoneAlready =
        paymentDoneAlready === null ? [] : paymentDoneAlready;
      let selectedDepositDetails =
        orderCommonFunctions.getJsonOfSelectedDepositCheckboxes(orderState);
      totalItemsSelectedDetails = JSON.stringify([
        ...paymentDoneAlready,
        ...selectedDepositDetails,
      ]);
      const details = {
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId: parseInt(
          this.props.global.locationSelected.BusinessLocationId
        ),
        orderId: orderState.currentOrder.orderId,
        amount: this.props.totalPayment,
        counterId: addProductsTocart.counterId,
        cardNumber: addProductsTocart.cardNumber,
        cvv: addProductsTocart.cvv,
        month: addProductsTocart.month,

        year: addProductsTocart.year,
        token: stripeTokenId === undefined ? null : stripeTokenId,
        productItemPaid: totalItemsSelectedDetails,
        preAuthorizeItemDeposit: JSON.stringify(selectedDepositDetails),
        selectPaymentType: this.props.selectPaymentType,
        paymentMethod: commonFunctions.getGlobalCodeDetails(
          this.props.global.codes,
          "PaymentMethod",
          "Card"
        ).globalCodeId,
        actionPerformedby: addProductsTocart.actionPerformedBy,
      };
      this.props.actions.apiCall({
        urls: ["HOLDPAYMENT"],
        method: "POST",
        data: details,
        onSuccess: (response) => {
                                
          this.onHoldPaymentSucess(response);

          //  transactionDetailId
          //  will append new api here
          // let transactionId = JSON.parse(response.stripeobject).charges.data[0].id

          // this.props.closeCreditModal();
          this.props.actions.getOrderItems(
            this.props.orderState.currentOrder.orderId
          );
          return response;
        },
        onFinally: () => {
          this.setState({ isLoading: false });
        },
        showNotification: true,
      });
    }
  };

  onHoldPaymentSucess = (transactionDetails) => {
                                         
    let newTotalAmountPaid = this.props.totalPayment;
    this.setState({
      transactionDetails: { KeyId: transactionDetails.transactionDetailId },
      splitSignatureModal: !this.state.splitSignatureModal,
      newTotalAmountPaid,
    });
  };

  onConnectIdPayment = (aa) => {
    this.setState({ stripeTokenId: aa });
  };

  // -----------------New code add Stripe-Connect and Tip ------------------- //

  getTipAmount = (addProductsTocart) => {
    this.setState({ isLoading: true });
    this.props.actions.apiCall({
      urls: ["GETTIPFORPAYMNETS"],
      method: "GET",
      data: {
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        amount: addProductsTocart.amount,
      },
      onSuccess: (response) => {
        this.setState({ tipDetailData: JSON.parse(response.tipData) });
      },
      onFinally: () => {
        this.setState({ isLoading: false });
      },
    });
  };
  // for payment through card manually
  onSubmitDetails = (e, stripeTokenId) => {
    var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
    if (isFormValid) {
      const { orderState, selectedPaymentType } = this.props;
      let totalItemsSelectedDetails = [];
      if (selectedPaymentType === "Selected Item(s)") {
        let paymentDoneAlready =
          orderState.currentOrder.productItemPaid &&
          JSON.parse(orderState.currentOrder.productItemPaid);
        paymentDoneAlready =
          paymentDoneAlready === null ? [] : paymentDoneAlready;
        let selectedItemDetails =
          orderCommonFunctions.getJsonOfSelectedCheckboxes(orderState);

        totalItemsSelectedDetails = JSON.stringify([
          ...paymentDoneAlready,
          ...selectedItemDetails,
        ]);
      }
      const addProductsTocart = this.state.addProductsTocart;
      addProductsTocart.productItemPaid =
        selectedPaymentType === "Selected Item(s)"
          ? totalItemsSelectedDetails
          : orderState.currentOrder.productItemPaid;
      addProductsTocart.token =
        stripeTokenId === undefined ? null : stripeTokenId;
      this.onSubmitNext(addProductsTocart);
    }
  };

  onSubmitNext = (addProductsTocart) => {
    if (addProductsTocart.token === null) {
      this.props.actions.showNotification(
        {
          title: "Warning",
          message: `Please fill Card details`,
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    } else {
      this.setState(
        {
          isAddtipModal: !this.state.isAddtipModal,
          totalAmountCharge: this.props.rehntitShareAmount,
          tipTotalValue: parseFloat(0).toFixed(2),
        },
        () => this.getTipAmount(addProductsTocart)
      );
    }
  };
  onBackButtonTipModal = () => {
    this.setState({
      isAddtipModal: false,
    });
  };
  onClickTipAdded = (tipdata) => {
    const totalAmount = tipdata + parseFloat(this.props.rehntitShareAmount);
    const Amount = parseFloat(totalAmount).toFixed(2);
    this.setState({
      tipTotalValue: parseFloat(tipdata).toFixed(2),
      totalAmountCharge: Amount,
      showTipAmountActive: true,
      showTipClass: false,
    });
  };
  onClickNotipFunction = () => {
    this.setState({
      tipTotalValue: "0.00", // Use string instead of parseFloat.toFixed(2)
      totalAmountCharge: parseFloat(this.props.rehntitShareAmount).toFixed(2),
      showTipClass: true,
      showTipAmountActive: true,
    });
  };
  onClickCustomTipAdded = () => {
    this.setState({
      customTipAdded: !this.state.customTipAdded,
      customtipTotalValue: null,
    });
  };
  onHandleCustomPrice = (e, { value, data }) => {
    var ex = /^\d*\.?(?:\d{1,2})?$/;
    if (ex.test(value) == true) {
      this.setState({ [data]: e.target.value });
    }
  };
  onSubmitCustomTip = () => {
    const totalValue =
      this.state.customtipTotalValue === null
        ? "0.00"
        : this.state.customtipTotalValue;
    const customTipTotalValue = parseFloat(totalValue);
    const rehntitShareAmount = parseFloat(this.props.rehntitShareAmount);
    const totalAmount = customTipTotalValue + rehntitShareAmount;
    const tipTotalValue = customTipTotalValue.toFixed(2);
    const totalAmountCharge = totalAmount.toFixed(2);
    this.setState({
      tipTotalValue: tipTotalValue,
      customTipAdded: false,
      totalAmountCharge: totalAmountCharge,
    });
  };
  onContinuePay = () => {
    const { addProductsTocart } = this.state;
    this.setState({ isLoading: true });
    addProductsTocart.tipAmount = this.state.tipTotalValue !== "0.00" ? this.state.tipTotalValue : null;
    addProductsTocart.isTipAdded = !!addProductsTocart.tipAmount;
    this.props.actions.apiCall({
      urls: ["ADDPAYMENTMNUALLY"],
      method: "POST",
      data: addProductsTocart,
      onSuccess: (response) => {
        this.props.closeCreditModal(response);
        // this.props.onClose();|
        this.props.actions.getOrderItems(
          this.props.orderState.currentOrder.orderId
        );
        return response;
      },
      onFinally: () => {
        this.setState({ isLoading: false });
      },
      showNotification: true,
    });
  };

  render() {
    const {
      isModalOpen,
      onClose,
      holdDeposit,
      amount,
      rehntitShareAmount,
      rehntitSharePercentage,
      paymentSelectKey,
      totalPayment,
    } = this.props;
    const {
      addProductsTocart,
      splitSignatureModal,
      transactionDetails,
      newTotalAmountPaid,
      yearDropdown,
      paymentConnectKey,
      isAddtipModal,
      isLoading,
    } = this.state;
                               
    return (
      <>
        <Modal
          className="striprModalCss"
          closeOnDimmerClick={false}
          open={isModalOpen}
          closeIcon
          size={"small"}
          onClose={onClose}
        >
          <ElementsConsumer>
            {({ stripe, elements }) => (
              <>
                {isLoading && (
                  <Dimmer active inverted>
                    {" "}
                    <Loader size="small"></Loader>{" "}
                  </Dimmer>
                )}
                <Modal.Content>
                  <Form
                    onSubmit={async (event) => {
                      event.preventDefault();

                      if (elements == null) {
                        return;
                      }
                      if (!stripe || !elements) {
                        // Stripe.js has not loaded yet. Make sure to disable
                        // form submission until Stripe.js has loaded.
                        return;
                      }
                      const payload = await stripe.createToken(
                        elements.getElement(CardElement)
                      );
                      {
                        holdDeposit
                          ? this.onHoldDeposit(event, payload?.token?.id)
                          : this.onSubmitDetails(event, payload?.token?.id);
                      }
                    }}
                  >
                    {/* Added new changes */}
                    <Grid>
                      <>
                        {holdDeposit ? null : (
                          <>
                            <Grid.Column width={4}>
                              <Link className="orange-color">
                                {" "}
                                Total Amount
                              </Link>
                            </Grid.Column>
                            <Grid.Column width={2}>
                              <span className="plus-sign-credit-modal orange-color">
                                +
                              </span>
                            </Grid.Column>
                            <Grid.Column width={4}>
                              <Form.Input
                                name="totalPayment"
                                labelPosition="left"
                                type="number"
                                className="amountInput"
                                placeholder="Amount"
                                value={amount?.toFixed(2)}
                                readOnly
                              >
                                <Label basic>$</Label>
                                <input />
                              </Form.Input>
                            </Grid.Column>
                            {paymentSelectKey === "EdgePay" ||
                            paymentSelectKey === "Stripe" ? (
                              <>
                                <Grid.Column width={2}>
                                  <span className="plus-sign-credit-modal-right orange-color">
                                    +
                                  </span>
                                </Grid.Column>
                                <Grid.Column width={4}>
                                  <div className="rehntit-fee">
                                    {rehntitSharePercentage}% Booking Fee
                                  </div>
                                </Grid.Column>
                              </>
                            ) : null}
                          </>
                        )}
                        <>
                          <Grid.Column width={6} verticalAlign="middle">
                            <Link className="orange-color">
                              Amount to Charge
                            </Link>
                          </Grid.Column>
                          {holdDeposit ? (
                            <Grid.Column width={4}>
                              <Form.Input
                                name="amountPaid"
                                readOnly
                                labelPosition="left"
                                className="amountInput padding-amount"
                                placeholder="Amount"
                                value={totalPayment?.toFixed(2)}
                              >
                                <Label basic>$</Label>
                                <input />
                              </Form.Input>
                            </Grid.Column>
                          ) : (
                            <Grid.Column width={4}>
                              <Form.Input
                                name="amountPaid"
                                readOnly
                                labelPosition="left"
                                className="amountInput padding-amount"
                                placeholder="Amount"
                                value={rehntitShareAmount}>
                                <Label basic>$</Label>
                                <input />
                              </Form.Input>
                            </Grid.Column>
                          )}
                        </>
                      </>

                      {paymentConnectKey === "Stripe" ? (
                        <GridColumn width={16} className="payOption">
                          <p>Card Detail</p>
                          <div style={{ width: "600px", height: "130px" }}>
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "space-between",
                                marginBottom: "10px",
                              }}
                            >
                              <span style={{ color: "#085044" }} />
                            </div>
                            <CardElement
                              options={{
                                style: {
                                  base: {
                                    fontSize: "20px",
                                    color: "#424770",
                                    "::placeholder": {
                                      color: "#aab7c4",
                                    },
                                  },
                                  invalid: {
                                    color: "#9e2146",
                                  },
                                },
                              }}
                            />
                          </div>
                        </GridColumn>
                      ) : null}

                      {paymentConnectKey === "EdgePay" ? (
                        <>
                          <Grid.Column width={16}>
                            <Form.Input
                              className="cardNumber"
                              label="Credit Card Number"
                              placeholder="Enter Card Number"
                              name="txtCardNumber"
                              data="cardNumber"
                              onChange={this.onChangeProductDetails}
                              value={addProductsTocart.cardNumber}
                              error={this.validator.message(
                                "cardNumber",
                                addProductsTocart.cardNumber,
                                `required|card_num|max:16`
                              )}
                              fluid
                            />
                          </Grid.Column>
                          <Grid.Column width={6}>
                            <Form.Dropdown
                              label="Expiry Month"
                              placeholder="Expiry Month"
                              type="select"
                              data="month"
                              name="ddpMonths"
                              options={months}
                              className="custom-select manual-label-css"
                              search
                              selection
                              onChange={this.onChangeProductDetails}
                              value={addProductsTocart.month}
                              error={this.validator.message(
                                "month",
                                addProductsTocart.month,
                                `required`
                              )}
                              fluid
                            />
                          </Grid.Column>
                          <Grid.Column width={6}>
                            <Form.Dropdown
                              label="Expiry Year"
                              placeholder="Expiry Year"
                              type="select"
                              data="year"
                              name="ddpYears"
                              //  options={years}
                              options={yearDropdown}
                              className="custom-select manual-label-css"
                              selection
                              onChange={this.onChangeProductDetails}
                              value={addProductsTocart.year}
                              error={this.validator.message(
                                "year",
                                addProductsTocart.year,
                                `required`
                              )}
                              fluid
                            />
                          </Grid.Column>
                          <Grid.Column width={4}>
                            <Form.Input
                              label="CVV"
                              placeholder="CVV"
                              type="number"
                              data="cvv"
                              name="txtCVV"
                              className="custom-select manual-label-css"
                              search
                              selection
                              onChange={this.onChangeProductDetails}
                              value={addProductsTocart.cvv}
                              error={this.validator.message(
                                "cvv",
                                addProductsTocart.cvv,
                                `numeric|min:3|max:4|required`
                              )}
                              fluid
                            />
                          </Grid.Column>
                        </>
                      ) : null}

                      <Grid.Column width={16} textAlign="right">
                        <Button className="blue-btn" onClick={onClose}>
                          Cancel
                        </Button>{" "}
                        &nbsp;&nbsp;
                        {paymentConnectKey === "Stripe" ? (
                          <>
                            {holdDeposit ? (
                              <Button className="orange-btn" type="submit">
                                Pre-authorize/Hold
                              </Button>
                            ) : (
                              <Button className="orange-btn" type="submit">
                                Next
                              </Button>
                            )}
                          </>
                        ) : (
                          <>
                            {holdDeposit ? (
                              <Button
                                className="orange-btn"
                                onClick={(e) => this.onHoldDeposit(e)}
                              >
                                Pre-authorize/Hold
                              </Button>
                            ) : (
                              <Button
                                className="orange-btn"
                                onClick={(e) => this.onSubmitDetails(e)}
                              >
                                Next
                              </Button>
                            )}
                          </>
                        )}
                      </Grid.Column>
                    </Grid>
                  </Form>
                </Modal.Content>
              </>
            )}
          </ElementsConsumer>
        </Modal>
        {splitSignatureModal && (
          <SplitSignatureModal
            holdpaymnetModal={this.props.holdpaymnetModal}
            onClosedPaymentModal={() => this.props.onClosedPaymentModal()}
            onManuallyClose={onClose}
            onSignSubmitModal={() => this.props.onSignSubmitModal()}
            transactionDetails={transactionDetails}
            addDeposit={true}
            value={2}
            closeModalSignture={this.props.closeModal}
            SignatureClosePaymentModal={this.props.SignatureClosePaymentModal}
            splitAmount={newTotalAmountPaid}
            isModalOpen={splitSignatureModal}
            onClose={() => this.setState({ splitSignatureModal: false })}
            isPreAuthorize={true}
          />
        )}
        {isAddtipModal && (
          <AddTipModal
            onClose={this.onSubmitNext}
            openModal={this.state.isAddtipModal}
            fullState={this.state}
            allFunction={this}
            onbackButton={onClose}
            isLoading={this.state.isLoading}
            rehntitShareAmount={rehntitShareAmount}
            paymentSelectKey={paymentSelectKey}
            rehntitSharePercentage={rehntitSharePercentage}
            addProductsTocart={this.state.addProductsTocart}
            tipDetailData={this.state.tipDetailData}
            showTipClass={this.state.showTipClass}
            showTipAmountActive={this.state.showTipAmountActive}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
    orderState: state.orderState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
      getOrderItems: bindActionCreators(actions.getOrderItems, dispatch),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManualCardModal);
