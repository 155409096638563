import { Suspense, lazy } from "react";
import { Switch, Route, BrowserRouter as Redirect } from "react-router-dom";
import { commonFunctions, env, connect } from "./global-import";
import { AuthenticateTemplateRoutesConfing } from "./../../config/routes";
import PrivateRoute from "./private-route";
import { AuthenticateUserTemplate } from "../components";
import { Loader } from "semantic-ui-react";

const ProtectedRoute = ({
  render: Component,
  rolePermissionScreenList,
  auth,
  global,
  userTypes,
}) => {
  let rolepermission = AuthenticateTemplateRoutesConfing.filter(
    (permission) => {
      let data = rolePermissionScreenList?.filter((item) => {
        return (
          item.Access === 1 &&
          item.ModuleName?.toLowerCase() ===
            permission.moduleName?.toLowerCase()
        );
      });
      if (data?.length > 0 && data[0].permission !== null) {
        if (
          data[0].permission.filter(
            (item) =>
              item.Access === 1 &&
              item.ScreenName.toLowerCase() ===
                permission.screenName?.toLowerCase()
          ).length > 0
        ) {
          return permission;
        }
      }
      if (
        data?.length > 0 &&
        data[0].Access === 1 &&
        data[0].ModuleName?.toLowerCase() ===
          permission.screenName?.toLowerCase()
      ) {
        return permission;
      }
    }
  );
  return (
    <>
      {commonFunctions.isNonEmptyObject(auth.userDetail) ? (
        <AuthenticateUserTemplate>
          <Suspense fallback={<Loader active />}>
            <Switch>
              {rolepermission?.map((route, i) => {
                const Component = route.component;
                return route.private ? (
                  // handle private routes of the application   userTypes={route.userTypes}
                  <PrivateRoute
                    key={i}
                    exact={route.exact}
                    path={route.path}
                    route={route}
                    render={(props) => (
                      <Component {...props} rolepermission={rolepermission} />
                    )}
                  />
                ) : (
                  // handle public routes of the.e application
                  <Route
                    key={i}
                    exact={route.exact}
                    path={route.path}
                    render={(props) => <Component {...props} />}
                  />
                );
              })}
              <Route
                path="*"
                exact={true}
                component={lazy(() => import("../../pages/errors"))}
              />
            </Switch>
          </Suspense>
        </AuthenticateUserTemplate>
      ) : (
        <Redirect to={`${env.PUBLIC_URL}/passcode`} />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
export default connect(mapStateToProps, null)(ProtectedRoute);
