import React, { Component } from "react";
import { Button, Grid, Modal, Select, Header, Form, Radio, Input, Checkbox, Tab, Icon, } from "semantic-ui-react";
import { DateInput, MonthInput, DatesRangeInput, } from "semantic-ui-calendar-react";
import { Link } from "react-router-dom";
import AddCustomDuration  from "./customduration";
// import AddCustomDuration  from "./customduration";
import RepeatCustomTime from "./repeatcustomtime";
import AddCustomTime from "./customtime";
const timeRange = [
    { key: "Day", value: "Day", text: "	Day" },
    { key: "Week", value: "Week", text: "Week" },
    { key: "Month", value: "Month", text: "Month" },
  ];
  const countryOptions = [
    { key: "1", value: "1", text: "12" },
    { key: "2", value: "2", text: "1" },
    { key: "3", value: "3", text: "2" },
    { key: "4", value: "4", text: "3" },
    { key: "5", value: "5", text: "4" },
    { key: "6", value: "6", text: "5" },
    { key: "7", value: "7", text: "6" },
    { key: "8", value: "8", text: "7" },
    { key: "9", value: "9", text: "8" },
    { key: "10", value: "10", text: "9" },
    { key: "11", value: "11", text: "10" },
    { key: "12", value: "12", text: "11" },
  ];
  const countryOptions2 = [
    { key: "mo", value: "mo", text: "00" },
    { key: "m5", value: "m5", text: "05" },
    { key: "m10", value: "m10", text: "10" },
    { key: "m15", value: "m15", text: "15" },
    { key: "m20", value: "m20", text: "20" },
    { key: "m25", value: "m25", text: "25" },
    { key: "m30", value: "m30", text: "30" },
    { key: "m35", value: "m35", text: "35" },
    { key: "m40", value: "m40", text: "40" },
    { key: "m45", value: "m45", text: "45" },
    { key: "m50", value: "m50", text: "50" },
    { key: "m55", value: "m55", text: "55" },
  ];
  const countryOptions3 = [
    { key: "am", value: "am", text: "AM" },
    { key: "pm", value: "pm", text: "PM" },
  ];
  class AvailabilityModal extends Component {
    constructor() {
        super();
        this.state = {
          customFields: false,
          customFieldsButton: true,
          pricingtoggle: false,
          date: "02/02/2021",
          selectDate: "",
          monthDate: "",
          yearDate: "",
          datesRange: "",
          timeRangeSelected: "",
          selectDuration: "",
          customdurationStatus:"",
          selectTime: "",
          repeatTimeStatus:"",
          repeatTime:""
        };
      }

      handleChangeSelect = (e, { name, value }) => {
        this.setState({ timeRangeSelected: value });
      };
    
      handleSelectDuration = (e) => {
        this.setState({ selectDuration: e.target.name });
      };

      handleSelectTime = (e) => {
        this.setState({ selectTime: e.target.name });
      };

      handleRepeatTime = (e) => {
        this.setState({ repeatTime: e.target.name });
      };
    
      handleChangeDate = (e, { name, value }) => {
        e.preventDefault();
        if (this.state.hasOwnProperty(name)) {
          this.setState({ [name]: value });
        }
      };

      handleChangeDateOuter = (e, { name, value }) => {
        if (this.state.hasOwnProperty(name)) {
          this.setState({ [name]: value });
        }
      };

      handleChange = (e, { value }) => this.setState({ value });
    
      CustomTime = () => {
        this.setState({
          customPriceStatus: !this.state.customPriceStatus,
        });
      };

      CustomDuration = () => {
        this.setState({
          customdurationStatus: !this.state.customdurationStatus,
        });
      };

      RepeatTimeDuration = () => {
        this.setState({
          repeatTimeStatus: !this.state.repeatTimeStatus,
        });
      };

      handleChangeMonth = (event, { name, value }) => {
        if (this.state.hasOwnProperty(name)) {
          this.setState({ [name]: value });
        }
      };
      render() {
        const { value, customPriceStatus, customdurationStatus,repeatTimeStatus } = this.state;
        const panes = [
            {
              menuItem: "Time Range",
              render: () => (
                <Tab.Pane attached={false}>
                <Grid>
              <Grid.Column width={3} verticalAlign="middle">
                <p>From</p>
              </Grid.Column>

              <Grid.Column width={2}>
                <Select options={countryOptions} className="custom-select" defaultValue="9" fluid/>
              </Grid.Column>
              <Grid.Column width={3}>
                <Select options={countryOptions2} className="custom-select" defaultValue="mo" fluid/>
              </Grid.Column>
              <Grid.Column width={3}>
                <Select options={countryOptions3} className="custom-select" defaultValue="am" fluid/>
              </Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column width={3} verticalAlign="middle">
                <p>To</p>
              </Grid.Column>

              <Grid.Column width={2}>
                <Select options={countryOptions} className="custom-select" defaultValue="9" fluid/>
              </Grid.Column>
              <Grid.Column width={3}>
                <Select options={countryOptions2} className="custom-select" defaultValue="mo" fluid/>
              </Grid.Column>
              <Grid.Column width={3}>
                <Select options={countryOptions3} className="custom-select" defaultValue="pm" fluid/>
              </Grid.Column>
            </Grid>
          </Tab.Pane>
              ),
            },
            {
                menuItem: "Specific Times",
                render: () => (
                    <Tab.Pane attached={false}>
                      <Grid>
                        <Grid.Column width={16}>
                          <Link  onClick={this.RepeatTimeDuration} className="orange-color">
                          Repeat Time </Link>
                        </Grid.Column>
          
                        <Grid.Column width={16}>
                          <Button name="8am" basic className="select-btn mbt" active={this.state.selectTime === "8am"}
                            onClick={this.handleSelectTime}> 8:00 AM </Button>
                          <Button name="10am" basic className="select-btn mbt" active={this.state.selectTime === "10am"}
                            onClick={this.handleSelectTime}>10:00 AM</Button>
                          <Button name="12pm" basic className="select-btn mbt" active={this.state.selectTime === "12pm"}
                            onClick={this.handleSelectTime}>12:00 PM</Button>
                          <Button name="02pm" basic className="select-btn mbt" active={this.state.selectTime === "02pm"}
                            onClick={this.handleSelectTime}>02:00 PM</Button>
                          <Button name="04pm" basic className="select-btn mbt" active={this.state.selectTime === "04pm"}
                            onClick={this.handleSelectTime}>04:00 PM</Button>
                          <Button className="orange-button mbt" icon active={this.state.selectTime === "06pm"}
                             onClick={this.CustomTime}>
                            <Icon name="plus" /> Custom Time </Button>
                        </Grid.Column>

                      </Grid>
                    </Tab.Pane>
                  ),
            },
        ];
        return(
            <>
            <Modal open={this.props.openModal} closeIcon onClose={this.props.closeModal} size={"small"}>
          <Modal.Header>New Availability</Modal.Header>
          <Modal.Content scrolling>
            <Grid>
              <Grid.Column width={16}>
                <Header as="h4">Time Range</Header>
              </Grid.Column>
              <Grid.Column width={16} verticalAlign="middle">
                <Select onChange={(e, { name, value }) => this.handleChangeSelect(e, { name, value })}
                  placeholder="Select One" options={timeRange} name="timeRangeSelected" className="custom-select"
                  defaultValue="Day"/>
              </Grid.Column>
              <Grid.Column width={16}>
                {this.state.timeRangeSelected === "Month" && (
                  <DatesRangeInput name="monthDate" key="mo" placeholder="Select Date" icon="false"
                    iconPosition="right" value={this.state.monthDate}  
                    onChange={this.handleChangeMonth} inline />
                )}
                {this.state.timeRangeSelected === "Year" && (
                  <MonthInput name="yearDate" placeholder="Select Date" value={this.state.YearDate}
                    icon="false" iconPosition="right" onChange={(e, { name, value }) =>
                      this.handleChangeDateOuter(e, { name, value })
                    } inline />
                )}
                {this.state.timeRangeSelected === "Week" && (
                  <>
                    <Header as="h4">Repeat on</Header>
                    <Checkbox label="S" className="selected-checkbox" />
                    <Checkbox label="M" className="selected-checkbox" />
                    <Checkbox label="T" className="selected-checkbox" />
                    <Checkbox label="W" className="selected-checkbox" />
                    <Checkbox label="T" className="selected-checkbox" />
                    <Checkbox label="F" className="selected-checkbox" />
                    <Checkbox label="S" className="selected-checkbox" />
                  </>
                )}
              </Grid.Column>
              <Grid.Column width={8}>
              <Header as="h4">Ends</Header>
              <Form>
                <Form.Field>
                  <Radio label="Never" className="custom-radio-btn checked" value="never" 
                  checked={value === "never"} onChange={this.handleChange} defaultChecked={true} />
                </Form.Field>
                <Form.Field className="time-range">
                  <Radio label="On" className="custom-radio-btn availablity-on" value="on"
                    checked={value === "on"} onChange={this.handleChange} />
                  <DateInput name="selectDate" placeholder="Select Date" value={this.state.selectDate}
                    icon="false" iconPosition="right" onChange={(e, { name, value }) =>
                      this.handleChangeDate(e, { name, value })
                    } className="fee-pricing-date availablity-on-content" closable />
                </Form.Field>
                <Form.Field className="time-range">
                  <Radio label="After" className="custom-radio-btn availablity-after" value="after" 
                  checked={value === "after"} onChange={this.handleChange} inline />
                  <Input type="number" defaultValue="1" className="availablity-after-content"
                    label="occurrences" labelPosition="right" />
                </Form.Field>
              </Form>
            </Grid.Column>
            <Grid.Column width={16}>
                <Header as="h4">Select Duration</Header>
                <Button
                  name="12pm"
                  basic
                  className="select-btn mbt"
                  active={this.state.selectTime === "dur01"}
                  onClick={this.handleSelectTime}
                >
                  2 Hours
                </Button>
                <Button name="02pm" basic className="select-btn mbt"
                  active={this.state.selectTime === "dur02"} onClick={this.handleSelectTime}>
                  5 Hours
                </Button>
                <Button
                  className="orange-button mbt"
                  icon
                  onClick={this.CustomDuration}>
                  <Icon name="plus" /> Custom Duration
                </Button>
              </Grid.Column>
              <Grid.Column width={16}>
                <Header as="h4">Select Time Range</Header>
                <Tab menu={{ secondary: true }} panes={panes} className="availablity-tab" />
                
              </Grid.Column>
              <Grid.Column width={16} textAlign="right">
                <Button className="orange-btn" onClick={this.props.closeModal}>
                  Save
                </Button>
              </Grid.Column>
            </Grid>
            <AddCustomDuration openModal={customdurationStatus} closeModal={this.CustomDuration}/>
            <RepeatCustomTime openModal={repeatTimeStatus} closeModal={this.RepeatTimeDuration}/>
            <AddCustomTime openModal={customPriceStatus} closeModal={this.CustomTime}/>
            </Modal.Content>
            </Modal>
            </>
        )
        }
    }
  export default AvailabilityModal;