import React, { Component } from "react";
import {Modal} from "semantic-ui-react";
import RequirementForm from "../../../../../../src/pages/more-setting/requirement-setting/requirement-form";
class AddRequirementModal extends Component {

  render() {
    return (
      <Modal open={this.props.openModal} closeIcon onClose={this.props.closeModal} 
      size={"small"}>        
          <Modal.Content scrolling>
            <RequirementForm onClose={this.props.closeModal}
             getRequirementList={this.props.getRequirementList} hideToggleForModal={true} />
          </Modal.Content>
      </Modal>
    );
  }
}
export default AddRequirementModal;
