import React, { Component } from "react";
import { Input, Grid, Form, Icon } from "semantic-ui-react";

class IncreamentDecreamentField extends Component {
    state = {  }
    render() { 
        return ( 
            <Grid>
            <Grid.Column>
                <Form.Field className="add-numbers">
                    <Icon name="minus" className="orange-button"></Icon>
                    <Input defaultValue="1" fluid />
                    <Icon name="plus" className="orange-button plus-btn"></Icon>
                </Form.Field>
            </Grid.Column>
            </Grid>
         );
    }
}
 
export default IncreamentDecreamentField;