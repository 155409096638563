import React, { Component } from 'react';
import { Dropdown } from 'semantic-ui-react';

const options = [
    { key: 'Add-Ons 1', text: 'Add-Ons 1', value: 'Add-Ons 1' },
    { key: 'Add-Ons 2', text: 'Add-Ons 2', value: 'Add-Ons 2' },
    { key: 'Add-Ons 3', text: 'Add-Ons 3', value: 'Add-Ons 3' },
]

class AddOnSelect extends Component {
    state = { options }

    handleAddition = (e, { value }) => {
        this.setState((prevState) => ({
          options: [{ text: value, value }, ...prevState.options],
        }))
      }
    
      handleChange = (e, { value }) => {
        this.setState({ currentValues: value })
      }
    render() {
        const { currentValues } = this.state
        return (
        <Dropdown options={this.state.options} placeholder='Add Ons' search selection fluid value={currentValues} onAddItem={this.handleAddition} onChange={this.handleChange} className="tagger"/>
         );
    }
}
 
export default AddOnSelect;