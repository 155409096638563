import React, { Component } from "react";
import {
  Button,
  Grid,
  Modal,
  Dropdown,
  Checkbox,
  Table,
  Icon,
} from "semantic-ui-react";
const options = [
  { key: "Name", text: "Name", value: "Name" },
  { key: "Weight", text: "Weight", value: "Weight" },
];

class ItemCustomFieldModal extends Component {
  state = { options };

  handleAddition = (e, { value }) => {
    this.setState((prevState) => ({
      options: [{ text: value, value }, ...prevState.options],
    }));
  };

  handleChange = (e, { value }) => {
    this.setState({ options: value });
  };
  render() {
    const { options } = this.state;

    return (
      <Modal
        open={this.props.openModal}
        closeIcon
        onClose={this.props.closeModal}
        size={"small"}
      >
        <Modal.Header>Add Custom Field</Modal.Header>
        <Modal.Content>
          <Grid>
            <Grid.Column width={10}>
              <Dropdown options={this.state.options} placeholder="Field Name" search selection fluid multiple allowAdditions value={options} onAddItem={this.handleAddition} onChange={this.handleChange} className="tagger"/>
              {/* defaultValue={selected} */} 
            </Grid.Column>

            <Grid.Column width={6} verticalAlign="middle">
              <Checkbox className="custome-checkbox" label="Required" />
            </Grid.Column>

            <Grid.Column mobile={8} tablet={4} computer={4}>
              <Checkbox label="In Store" className="custome-checkbox" />
            </Grid.Column>

            <Grid.Column mobile={8} tablet={4} computer={4}>
              <Checkbox label=" Marketplace" className="custome-checkbox" />
            </Grid.Column>
            <Grid.Column mobile={8} tablet={8} computer={8}>
              <Checkbox label="Website" className="custome-checkbox" />
            </Grid.Column>
            <Grid.Column
              mobile={16}
              tablet={16}
              computer={16}
              textAlign="right"
            >
              <Button className="orange-btn">Add</Button>
            </Grid.Column>

            <Grid.Column width={16}>
              <div className="common-table">
                <Table basic>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Field</Table.HeaderCell>
                      <Table.HeaderCell>Required</Table.HeaderCell>
                      <Table.HeaderCell>In Store</Table.HeaderCell>
                      <Table.HeaderCell>Marketplace</Table.HeaderCell>
                      <Table.HeaderCell>Website</Table.HeaderCell>
                      <Table.HeaderCell textAlign="right">
                        Action
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>Name</Table.Cell>
                      <Table.Cell>Yes</Table.Cell>
                      <Table.Cell>Yes</Table.Cell>
                      <Table.Cell>Yes</Table.Cell>
                      <Table.Cell>Yes</Table.Cell>
                      <Table.Cell textAlign="right">
                        <Icon
                          name="pencil alternate"
                          size="large"
                          className="deepviolet-color"
                          link
                        />
                        <Icon
                          name="trash alternate"
                          size="large"
                          className="orange-color"
                          link
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Weight</Table.Cell>
                      <Table.Cell>Yes</Table.Cell>
                      <Table.Cell>No</Table.Cell>
                      <Table.Cell>Yes</Table.Cell>
                      <Table.Cell>No</Table.Cell>
                      <Table.Cell textAlign="right">
                        <Icon
                          name="pencil alternate"
                          size="large"
                          className="deepviolet-color"
                          link
                        />
                        <Icon
                          name="trash alternate"
                          size="large"
                          className="orange-color"
                          link
                        />
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </div>
            </Grid.Column>

            <Grid.Column width={16} textAlign="right">
              <Button className="blue-btn" onClick={this.props.closeModal}>
                Cancel
              </Button>
              <Button className="orange-btn" onClick={this.props.closeModal}>
                Save
              </Button>
            </Grid.Column>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}
export default ItemCustomFieldModal;
