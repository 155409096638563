import React, { Component } from "react";
import { Form, Button, Grid } from "semantic-ui-react";
import { connect, bindActionCreators, actions, withRouter, } from "../../../../functional/global-import";


class DescribeRole extends Component {

  // Put all key & values that need to reset on form submit and reset form.

  componentDidMount() {
    // const {propsData} = this.props;
    const { addRoleForm } = this.props.fullState;
    addRoleForm.businessId = this.props.auth.loggedIn.businessId
    addRoleForm.actionPerformedBy = this.props.auth.userDetail.emailId
    // addRoleForm.businessLocationId = this.props.global.locationSelected.BusinessLocationId
    this.setState({ addRoleForm })

  }

  // // This function is used to set handle change
  // onHandleChangeDescribeRole = (e, { name, value, type, checked, data }) => {
  // // This function is used to ADDROLE and routing
  // onHandleSubmitDescribeRole = (e) => {
  render() {
    const { allfunction, fullState, propsData, addRoleForm } = this.props;
    
    return (
      <Grid>
        <Grid.Column >
          <Form>
            <Form.Input placeholder="Role title" className="bordered-input"
              // readOnly={addRoleForm.roleName === "Admin" && !fullState.iconClicked ? true : false} 
              name="txtRoleName" data="roleName"
              disabled={addRoleForm.roleName === "Admin" && !fullState.iconClicked ? true : false}
              error={allfunction.validatorAddRoleForm.message("roleName", fullState.addRoleForm.roleName, "required|max:20")}
              onChange={allfunction.onHandleChangeDescribeRole} value={fullState.addRoleForm.roleName} />

            <Form.TextArea type="textarea" placeholder="Description" rows={2}
              name="tbxdescription"
              disabled={addRoleForm.roleName === "Admin" && !fullState.iconClicked ? true : false && addRoleForm.roleName === undefined ? true : false}
              error={allfunction.validatorAddRoleForm.message("roleDescription", fullState.addRoleForm.roleDescription, "required|max:50")}
              data="roleDescription" onChange={allfunction.onHandleChangeDescribeRole} value={fullState.addRoleForm.roleDescription} />
            <Grid>
              <Grid.Column textAlign="right">
                <Button className="blue-btn" onClick={() => allfunction.onPrevious(0)} > Back </Button>
                <Button loading={allfunction.isTagFormLoading} className="orange-btn" onClick={allfunction.onHandleSubmitDescribeRole}> Continue </Button>
              </Grid.Column>
            </Grid>
          </Form>
        </Grid.Column>
      </Grid>

    );
  }
}

const mapStateToProps = state => {
  return {
    api: state.api,
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
    }
  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DescribeRole));
