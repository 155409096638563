import { DELETE_CUSTOMER, SET_CUSTOMERS } from "../types/customer.types";

const customerInitialState = {
  allCustomers: [],
  selectedCustomer: {},
  isSaving: false,
  isLoading: false,
};

const customer = (state = customerInitialState, action) => {
  switch (action.type) {
    case SET_CUSTOMERS: {
      
      return {
        ...state,
        allCustomers: action.payload,
      };
    }
    case DELETE_CUSTOMER: {
      return {
        ...state,
        allCustomers: state.allCustomers.filter(
          (customer) => customer.customerId !== action.payload
        ),
      };
    }
    default:
      return state;
  }
};

export default customer;
