import React, { Component } from "react";
import ActivityTab from "./activity-tab";
import { Button, Grid } from "semantic-ui-react";
import CustomTime from "../../common/custom-time";
import {
  bindActionCreators,
  actions,
  connect,
  Notifications,
  commonFunctions,
} from "../../../../../../functional/global-import";
import moment from "moment";
import { DatePicker } from "react-trip-date";
import { orderCommonFunctions } from "../../order-common-function";

const handleResponsive = (setNumberOfMonth) => {
  let width = document.querySelector(".tp-calendar").clientWidth;
  if (width > 900) {
    setNumberOfMonth(3);
  } else if (width < 900 && width > 580) {
    setNumberOfMonth(2);
  } else if (width < 580) {
    setNumberOfMonth(1);
  }
};

const Day = ({ day }) => {
  return (
    <>
      <p className="date">{day.format("DD")}</p>
      <p className="date">7</p>
    </>
  );
};

class ChooseDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      monthDate: "",
      monthDate1: "",
      availableticket: true,
      count: 1,
      Adultcount: 1,
      selectedTime: "",
      nonAvalableDAtes: [],
      currentTime: null,
      bookingSlot: {
        startTimeHour: "",
        startTimeMinute: "",
        startTimeAm: "",
        endTimeHour: "",
        endTimeMinute: "",
        endTimeAm: "",
      },

      timeDetails: null,
      ticketsTable: false,
      firstAvailableDate: "",
      hasRendered: false,
    };
  }
  CustomTime = () => {
    this.setState({ customPriceStatus: !this.state.customPriceStatus });
  };

  handleSelectTime = (e) => {
    this.setState({ selectedTime: e.target.name }, () =>
      this.getActivityTickets(
        e.target.name,
        this.state.currentTime
          ? this.state.currentTime
          : this.state.firstAvailableDate
      )
    );
  };

  getActivityTickets = (timeChoosen, selectedDate) => {
    const { stateRefActivity } = this.props;
    const { selectedTime } = this.state;
    if (selectedDate === undefined || selectedTime === "") {
      stateRefActivity.state.ticketsTable = false;
      stateRefActivity.setState({ ...stateRefActivity.state });
    } else {
      this.props.stateRefActivity.getSlotsTickets(timeChoosen, selectedDate);
    }
  };

  onCheckActivityAvailability = (data) => {
    const { global, productCategoryDetail } = this.props;
    this.props.actions.apiCall({
      urls: ["GETORDERAVAILABLEACTIVITYTICKETS"],
      method: "GET",
      data: {
        ActivityId: productCategoryDetail.productId,
        BusinessId: global.bussinessDetail.businessId,
        BusinessLocationId: global.locationSelected.BusinessLocationId,
        businessCategoryType: commonFunctions.getGlobalCodeDetails(
          this.props.global.codes,
          "BusinessCategoryType",
          "Activity"
        ).globalCodeId,
        StartDate: `${data.startDate} ${data.timeRangeFrom}`,
        InWidget: false,
      },
      onSuccess: (response) => {
        this.props.showActivityTicket(data);
      },
      showNotification: true,
    });
  };

  onStartEndTimeContinue = () => {
    if (this.state.currentTime !== undefined || !!this.state.firstAvailableDate ) {
      const { bookingSlot } = this.state;
      let endTime =
        bookingSlot.endTimeHour +
        ":" +
        bookingSlot.endTimeMinute +
        bookingSlot.endTimeAm;
      let startTime =
        bookingSlot.startTimeHour +
        ":" +
        bookingSlot.startTimeMinute +
        " " +
        bookingSlot.startTimeAm;
      let data = {
        timeFrameRange: this.props.activityTimeSlots.timeRange,
        startDate: this.state.currentTime ? this.state.currentTime :this.state.firstAvailableDate,
        timeRangeFrom: startTime,
        timeRangeTo: endTime,
      };
      this.onCheckActivityAvailability(data);
    } else {
      this.onShowingerror();
    }
  };
  onShowingerror = () => {
    this.props.actions.showNotification(
      {
        title: "Error",
        message: "Please selecte date & time",
        position: "br",
        autoDismiss: commonFunctions.notificationTime(),
      },
      "error"
    );
  };
  showActivitySelectTicket = () => {
    const { orderState, activity } = this.props;
    // let selectedDate = { startDate: activity.startDate, endDate: activity.endDate }
    // this.props.actions.addSelectedDateRange(selectedDate);
    // const nextState = orderState.currentOrder.orderDetails.filter((item) => item.id === orderState.currentOrderDetails.id);

    if (
      this.state.currentTime !== undefined ||
      !!this.state.firstAvailableDate
    ) {
      const { bookingSlot } = this.state;
      // let selectedDate = { startDate: activity.startDate, endDate: activity.endDate }
      // this.props.actions.addSelectedDateRange(selectedDate);
      // const nextState = orderState.currentOrder.orderDetails.filter((item, idx) => item.id === orderState.currentOrderDetails.id);
      let data = {
        timeFrameRange: this.props.activityTimeSlots.timeRange,
        startDate: this.state.currentTime
          ? this.state.currentTime
          : this.state.firstAvailableDate,
        // startDate: "2021-10-20",
        // startDate: activity.startDate,
        timeRangeFrom:
          this.state.selectedTime === ""
            ? bookingSlot.startTimeHour +
              ":" +
              bookingSlot.startTimeMinute +
              bookingSlot.startTimeAm
            : this.state.selectedTime,
        timeRangeTo:
          this.state.selectedTime !== ""
            ? null
            : bookingSlot.endTimeHour +
              ":" +
              bookingSlot.endTimeMinute +
              bookingSlot.endTimeAm,
      };
      this.onCheckActivityAvailability(data);
    } else {
      this.onShowingerror();
    }
  };

  onRangeDateInScreen = (window) => {
    //
    const { initialMonth } = this.props;
    this.props.actions.apiCall({
      urls: ["GETPRODUCTAVAILAVILITY"],
      method: "GET",
      data: {
        StartDate: window.start,
        ProductId: this.props.productCategoryDetail.productId,
        businessCategoryType: commonFunctions.getGlobalCodeDetails(
          this.props.global.codes,
          "BusinessCategoryType",
          this.props.productCategoryDetail.businessCategoryType
        ).globalCodeId,
      },
      onSuccess: (response) => {
        if (response[0].notAvailableDays === null) {
          this.setState({ nonAvalableDAtes: [] });
        } else {
          let nonAvalableDAte = JSON.parse(response[0].notAvailableDays).map(
            (post) => {
              return post.dates;
            }
          );
          this.setState({ nonAvalableDAtes: nonAvalableDAte });

          var currentdate =
            this.props.currentDateFromApi === undefined
              ? new Date()
              : this.props.currentDateFromApi;
          let aa = moment(currentdate).format("YYYY-MM-DD");
          if (nonAvalableDAte.indexOf(aa) > -1) {
            this.setState({ currentTime: undefined });
          }
        }

        const today = moment().format("YYYY-MM-DD");
        const initialMoment =
          initialMonth === null
            ? today
            : moment(initialMonth).format("YYYY-MM-DD");
        if (
          this.state.nonAvalableDAtes.length === 0 &&
          !this.state.hasRendered
        ) {
          this.setState({
            firstAvailableDate: window.start < today ? today : window.start,
            hasRendered: true,
          });
        }

        if (!this.state.hasRendered) {
          let firstAvailableDate = commonFunctions.findFirstMissingDate(
            this.state.nonAvalableDAtes
          );
          if (firstAvailableDate) {
            this.setState({
              firstAvailableDate:
                firstAvailableDate <= initialMoment
                  ? initialMoment
                  : firstAvailableDate,
              hasRendered: true,
            });
          }
          if (!firstAvailableDate && this.state.nonAvalableDAtes !== null) {
            // this.setState({firstAvailableDate : today , hasRendered: true})
          }
        }
        // if (!this.state.isRendered) {
        //   setTimeout(() => {
        //     this.findFirstAvailability(
        //       this.state.nonAvalableDAtes,
        //       this.props.activityTimeFrameDetails
        //     );
        //   }, 500);
        // }
      },
    });
  };

  onChange = (date) => {
    this.getActivityTickets(this.state.selectedTime, date && date[0]);
    this.setState({ currentTime: date[0] });
    this.props.getNewActivityTimeSlots(date);
    setTimeout(() => this.onSettingPartialDates(date[0]), 1000);
  };

  onSettingPartialDates = (date) => {
    var partailOrderedDates = ["2021-10-26", "2021-10-30"];
    if (date) {
      partailOrderedDates = partailOrderedDates.filter((obh) => {
        return obh !== date;
      });
    }

    var calendarDateArray = document.getElementsByClassName("sc-iBPTik");
    Array.from(calendarDateArray).mafindFirstAvailabilityp((calendarObj) => {
      if (
        partailOrderedDates.indexOf(calendarObj.getAttribute("data-test")) > -1
      ) {
        calendarObj.classList.add("partial-ordered");
      }
    });
  };
  getMinutes = (number) => {
    if (number % 5 === 0) {
      return ("0" + number).slice(-2);
    } else {
      let numberValue = number - (number % 5);
      return ("0" + numberValue).slice(-2);
    }
  };
  componentDidMount() {
    // Current-hour//
    var hours = new Date().getHours();
    hours = hours % 12 || 12;
    let CurHour = ("0" + hours).slice(-2);

    // extended-hour//
    var ExtenndHours = new Date().getHours() + 1;
    ExtenndHours = ExtenndHours % 12 || 12;
    let CurHours = ("0" + ExtenndHours).slice(-2);
    // mintues//
    var Mintues = new Date().getMinutes();
    let MintuesGet = this.getMinutes(Mintues);
    let GetFinalMintues = MintuesGet.toString();
    // mintues//
    var Mintues = new Date().getMinutes() + 1;
    let MintuesExtended = this.getMinutes(Mintues);
    let GetExtendedMintues = MintuesExtended.toString();

    let am = new Date().getHours() >= 12 ? "pm" : "am";
    let pm = new Date().getHours() + 1 >= 12 ? "pm" : "am";

    this.setState({
      bookingSlot: {
        ...this.state.bookingSlot,
        startTimeHour: CurHour,
        startTimeAm: am,
        startTimeMinute: GetFinalMintues,
        endTimeHour: CurHours,
        endTimeMinute: GetExtendedMintues,
        endTimeAm: pm,
      },
    });

    const today =
      this.props.currentDateFromApi === undefined
        ? new Date()
        : this.props.currentDateFromApi;
    const timeConverted = moment(today, "MMM DD YYYY, hh:mm A").format(
      "YYYY-MM-DD"
    );
    // this.setState({ currentTime: timeConverted });
  }

  onTimeHandleChange = (e, { value, name }) => {
    this.setState({
      bookingSlot: { ...this.state.bookingSlot, [name]: value },
    });
  };
  // here
  render() {
    const {
      customPriceStatus,
      selectedTime,
      currentTime,
      bookingSlot,
      firstAvailableDate,
      isRendered,
    } = this.state;
    const {
      activityTimeSlots,
      monthDate,
      api,
      stateRefActivity,
      customfields,
      currentDateFromApi,
      initialMonthDate,
      activityId,
      activityTimeFrameDetails,
    } = this.props;

    const theme = orderCommonFunctions.themeColor();
    const today = moment().format("YYYY-MM-DD");

    return (
      <>
        <Grid>
          <Grid.Column width={8}>
            <DatePicker
              theme={theme}
              onRangeDateInScreen={this.onRangeDateInScreen}
              onChange={this.onChange}
              numberOfMonths={1}
              selectedDays={
                this.state.currentTime
                  ? this.state.currentTime
                  : firstAvailableDate
              }
              initialMonthAndYear={
                initialMonthDate < today ? today : initialMonthDate
              }
              responsive={handleResponsive}
              disabledBeforeToday={true}
              disabledBeforeDate={moment(currentDateFromApi).format(
                "YYYY-MM-DD"
              )}
              numberOfSelectableDays={1}
              disabledDays={this.state.nonAvalableDAtes}
              disabled={false}
              dayComponent={Day}
              titleComponent="{Title}"
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <Grid.Column width={16}>
              <ActivityTab
                api={api}
                customfields={customfields}
                ticketsDetails={stateRefActivity.state.ticketsDetails}
                ticketsTable={stateRefActivity.state.ticketsTable}
                menu={{ secondary: true }}
                className="availablity-tab"
                activityTimeSlots={activityTimeSlots}
                handleSelectTime={this.handleSelectTime}
                selectedTime={selectedTime}
                bookingSlot={bookingSlot}
                onTimeHandleChange={this.onTimeHandleChange}
              />
            </Grid.Column>
            <Grid.Column width={16} textAlign="right">
              <Button
                className="blue-btn"
                onClick={this.props.backBtnInventoryView}
              >
                Back
              </Button>
              {activityTimeSlots && activityTimeSlots.timeForProduct !== null && (
                <Button
                  className="orange-btn"
                  // disabled={
                  //   currentTime === undefined
                  //     ? true
                  //     : this.state.firstAvailableDate === ""
                  //     ? true
                  //     : false
                  // }
                  onClick={() => this.showActivitySelectTicket(monthDate)}
                >
                  Continue
                </Button>
              )}

              {activityTimeSlots && activityTimeSlots.slotsForProduct !== null && (
                <Button
                  className="orange-btn"
                  disabled={currentTime === undefined ? true : false}
                  onClick={() => this.onStartEndTimeContinue(monthDate)}
                >
                  Continue
                </Button>
              )}

              {activityTimeSlots &&
                activityTimeSlots.timeSlotsForProduct !== null && (
                  <Button
                    className="orange-btn"
                    disabled={currentTime === undefined ? true : false}
                    onClick={() => this.onStartEndTimeContinue(monthDate)}
                  >
                    Continue
                  </Button>
                )}
              {activityTimeSlots && activityTimeSlots.shopHours !== null && (
                <Button
                  className="orange-btn"
                  disabled={currentTime === undefined ? true : false}
                  onClick={() => this.onStartEndTimeContinue(monthDate)}
                >
                  Continue
                </Button>
              )}
            </Grid.Column>
          </Grid.Column>
        </Grid>
        <CustomTime
          openModal={customPriceStatus}
          closeModal={this.CustomTime}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    api: state.api,
    orderState: state.orderState,
    auth: state.auth,
    global: state.global,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      addSelectedDateRange: bindActionCreators(
        actions.addSelectedDateRange,
        dispatch
      ),
      storeInventoryDetail: bindActionCreators(
        actions.storeInventoryDetail,
        dispatch
      ),
      getOrderActivityPricingDetailId: bindActionCreators(
        actions.getOrderActivityPricingDetailId,
        dispatch
      ),
      showNotification: bindActionCreators(Notifications.show, dispatch),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ChooseDate);
