import React, { Component } from "react";
import { Dropdown } from "semantic-ui-react";

const options = [
  { key: "Student", text: "Student", value: "Student" },
  { key: "Senior Citizen", text: "Senior Citizen", value: "Senior Citizen" },
];

class AddDefinePrice extends Component {
  state = { options };

  handleAddition = (e, { value }) => {
    this.setState((prevState) => ({
      options: [{ text: value, value }, ...prevState.options],
    }));
  };

  handleChange = (e, { value }) => {
    this.setState({ currentValues: value });
  };
  render() {
    const { currentValues } = this.state;

    return (
      <Dropdown options={this.state.options} placeholder="Title" search selection fluid allowAdditions value={currentValues} onAddItem={this.handleAddition} onChange={this.handleChange} className="tagger"/>
    );
  }
}

export default AddDefinePrice;
