import React, { Component } from "react";
import {
  connect,
  bindActionCreators,
  actions,
  Notifications,
  commonFunctions,
} from "../../../../../../functional/global-import";
import RetailSelectItems from "../retail/retail-select-item";
import AddOnAllBusinessType from "../../common/addOn-all-business-type";
import CancelationCommonComponent from "../../common/cancelation-common";
import { Dimmer, Loader } from "semantic-ui-react";
import { orderCommonFunctions } from "../../order-common-function";

class Retail extends Component {
  state = {
    retailDescription: null,
    showRetailItems: this.props.customQuestionEdit.customQuestionEdit
      ? false
      : true,
    showCancellationComponent: false,
    editShowCancellationComponent:
      this.props.customQuestionEdit.customQuestionEdit,
    productCustomQuestion: this.props.customQuestionEdit.customQuestionEdit
      ? this.props.customQuestionEdit.productCustomQuestion
      : null,
    minItemPerOrder: "",
    maxItemPerOrder: "",
  };

  bulkOnContinue = (isAddMoreBtnClicked, quantity) => {
    const { orderState } = this.props;
    const nextState = orderState.currentOrder.orderDetails
      .filter(
        (orderDetail) => orderDetail.id === orderState.currentOrderDetails.id
      )
      .map((item) => {
        return {
          orderId: orderState.currentOrder.orderId,
          businessCategoryType: item.typeId,
          retailType: item.retailTypeId,
          productDetailId: null,
          itemQuantity: quantity,
          retailId: item.selectedItems[0].productid,
          actionPerformedBy: this.props.auth.userDetail.emailId,
        };
      });
    const { minItemPerOrder, maxItemPerOrder } = this.state;
    const isAbleToBook = orderCommonFunctions.onValidateMinMaxPerItem(
      nextState[0]?.itemQuantity,
      minItemPerOrder,
      maxItemPerOrder
    );
    if (isAbleToBook) {
      this.onAddOrderDetailId(nextState[0], isAddMoreBtnClicked);
    } else {
      this.props.actions.showNotification(
        {
          title: "Warning",
          message: `Please select minimum ${minItemPerOrder} or maximum ${maxItemPerOrder} item per-order`,
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    }
  };
  handleSubmit = (isAddMoreBtnClicked) => {
    const { orderState } = this.props;
    const nextState = orderState.currentOrder.orderDetails
      .filter(
        (orderDetail) => orderDetail.id === orderState.currentOrderDetails.id
      )
      .map((item) => {
        return {
          orderId: orderState.currentOrder.orderId,
          businessCategoryType: item.typeId,
          retailType: item.retailTypeId,
          productDetailId: item.selectedItems.map(
            (item) => item.productdetailid
          ),
          itemQuantity:
            item.retailTypeId === 137
              ? null
              : item.selectedItems.map((item) => item.productdetailid),
          retailId: item.selectedItems[0].productid,
          actionPerformedBy: this.props.auth.userDetail.emailId,
        };
      });
    const { minItemPerOrder, maxItemPerOrder } = this.state;
    const isAbleToBook = orderCommonFunctions.onValidateMinMaxPerItem(
      nextState[0]?.productDetailId.length,
      minItemPerOrder,
      maxItemPerOrder
    );
    if (isAbleToBook) {
      this.onAddOrderDetailId(nextState[0], isAddMoreBtnClicked);
    } else {
      this.props.actions.showNotification(
        {
          title: "Warning",
          message: `Please select minimum ${minItemPerOrder} or maximum ${maxItemPerOrder} item per-order`,
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    }
  };

  onAddOrderDetailId = (dataObj, isAddMoreBtnClicked) => {
    this.props.actions.apiCall({
      urls: ["ADDORDERRETAILSDETAILS"],
      method: "POST",
      data: dataObj,
      showNotification: true,
      onSuccess: (response) => {
        const { orderState } = this.props;
        if (response !== null) {
          if (isAddMoreBtnClicked) {
            this.props.onAddmoreBtnClick();
          } else if (this.state.isToShowAddon) {
            this.setState({
              showAddon: this.state.isToShowAddon,
              showRetailItems: false,
            });
          } else if (this.state.hasToShowCancellationComponent) {
            this.setState({
              showRetailItems: false,
              showCancellationComponent:
                this.state.hasToShowCancellationComponent,
            });
          } else {
            this.props.next(2);
          }
          this.props.actions.getOrderItems(orderState.currentOrder.orderId);
          this.setState({ orderDetailId: response.orderDetailId });
          return response;
        } else {
          this.props.actions.showNotification(
            {
              title: "Error",
              message: "Something Went Wrong in Create Customer Process!!",
              position: "br",
              autoDismiss: commonFunctions.notificationTime(),
            },
            "error"
          );
        }
      },
    });
  };

  getProductDetail = () => {
    // this.setState({ loading: true })
    this.props.actions.apiCall({
      urls: ["GETORDERRETAILDETAIL"],
      method: "GET",
      data: {
        retailId: this.props.retailEdit.retailEdit
          ? this.props.retailEdit.retailItem.RetailId
          : this.props.global.inventoryDetail.productId,
        inWebsiteWidget: false,
      },
      onSuccess: (response) => {
        var addonDetails = null;
        if (response[0].addOn) {
          addonDetails =
            response[0].addOn &&
            JSON.parse(response[0].addOn).map(function (el) {
              var addOnObj = Object.assign({}, el);
              addOnObj.qunatity = 0;
              addOnObj.minvalue = el.minAddOns;
              return addOnObj;
            });
        }
        const description = response[0].info;
        const briefDetails = JSON.parse(response[0].briefDetails)[0];

        const productCustomQuestion =
          briefDetails.ProductCustomQuestion &&
          JSON.parse(briefDetails.ProductCustomQuestion);

        const questiondetails =
          productCustomQuestion &&
          productCustomQuestion.map((customObj) => {
            if (customObj.associateAddOn) {
              customObj.associateAddOn = JSON.parse(customObj.associateAddOn);
            }
            return { ...customObj };
          });

        const isToShowAddon =
          response[0].addOn && JSON.parse(response[0].addOn).length > 0
            ? true
            : false;
        const hasToShowCancellationComponent =
          briefDetails.CancellationPolicy !== null ||
          briefDetails.ProductCustomQuestion !== null
            ? true
            : false;
        this.setState({
          retailDescription: description,
          isToShowAddon: isToShowAddon,
          addOnDetails: addonDetails,
          briefDetails: briefDetails,
          hasToShowCancellationComponent: hasToShowCancellationComponent,
          productCustomQuestion: questiondetails,
          minItemPerOrder: response && response[0]?.minItemPerOrder,
          maxItemPerOrder: response && response[0]?.maxItemPerOrder,
        });
        return response;
      },
    });
  };

  componentDidMount() {
    !this.props.customQuestionEdit.customQuestionEdit &&
      this.getProductDetail();
  }

  onAddOnContinueClick = (isAddMoreCLicked) => {
    if (isAddMoreCLicked) {
      this.props.onAddmoreBtnClick();
      // this.props.actions.getOrderItems(this.props.orderState.currentOrder.orderId);
    } else {
      if (this.state.hasToShowCancellationComponent) {
        this.setState({
          showAddon: false,
          showCancellationComponent: this.state.hasToShowCancellationComponent,
        });
      } else {
        this.props.next(2);
      }
    }
    // this.props.actions.getOrderItems(this.props.orderState.currentOrder.orderId);
  };

  onCancelContinueClick = (isAddMoreClicked) => {
    if (isAddMoreClicked) {
      // this.props.actions.getOrderItems(this.props.orderState.currentOrder.orderId);
      this.props.onAddmoreBtnClick();
    } else {
      this.props.next(2);
    }
    this.props.actions.getOrderItems(
      this.props.orderState.currentOrder.orderId
    );
  };

  render() {
    const {
      orderDetailId,
      addOnDetails,
      hasToShowCancellationComponent,
      showRetailItems,
      showAddon,
      showCancellationComponent,
      briefDetails,
      isToShowAddon,
      productCustomQuestion,
      editShowCancellationComponent,
      retailDescription,
    } = this.state;
    const { retailEdit, allFunction, api } = this.props;

    return (
      <>
        {api.isApiLoading && (
          <Dimmer active inverted>
            {" "}
            <Loader size="small"></Loader>{" "}
          </Dimmer>
        )}
        {showRetailItems && (
          <RetailSelectItems
            retailStateRef={this}
            next={this.props.next}
            allFunction={allFunction}
            retailEdit={retailEdit}
            backBtnInventoryView={this.props.backBtnInventoryView}
            onAddmoreBtnClick={this.props.onAddmoreBtnClick}
            categoryViewBackRetail={this.props.categoryViewBackRetail}
            isToShowAddon={isToShowAddon}
            hasToShowCancellationComponent={hasToShowCancellationComponent}
            moveToNextStep={this.handleSubmit}
            bulkOnContinue={this.bulkOnContinue}
            retailDescription={retailDescription}
            productCategoryDetailsFromParent={this.props.productCategoryDetail}
          />
        )}

        {showAddon && (
          <AddOnAllBusinessType
            stateRef={this}
            orderDetailId={orderDetailId}
            onContinueClick={this.onAddOnContinueClick}
            addOnDetails={addOnDetails}
            hasToShowCancellationComponent={hasToShowCancellationComponent}
          />
        )}

        {editShowCancellationComponent && (
          <CancelationCommonComponent
            businessTypeName="Retail"
            allFunction={this}
            orderDetailId={this.props.customQuestionEdit.questionOrderDetailId}
            productId={this.props.customQuestionEdit.questionProductId}
            productCustomQuestion={productCustomQuestion}
            showAddon={false}
            briefDetails={false}
            editQuestion={true}
            onEditQuestionNext={() => this.props.next(3)}
          />
        )}

        {showCancellationComponent && (
          <CancelationCommonComponent
            businessTypeName="Retail"
            allFunction={this}
            orderDetailId={orderDetailId}
            productCustomQuestion={productCustomQuestion}
            productId={this.props.global.inventoryDetail.productId}
            briefDetails={briefDetails}
            showAddon={isToShowAddon}
            onNext={this.onCancelContinueClick}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
    orderState: state.orderState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      AddOrderPaymentStatus: bindActionCreators(
        actions.AddOrderPaymentStatus,
        dispatch
      ),
      getOrderItems: bindActionCreators(actions.getOrderItems, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Retail);
