import React, { Component } from "react";
import { Grid, Form, Divider, Header, Button, Popup, Icon, Modal } from "semantic-ui-react";
import AddItemTraitModal from "../../organisms/modal/additem-trait-modal";
import { commonFunctions } from "../../../functional/global-import";
class CommonTraits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      traitsStatus: false,
      localcustomTraits: []
    }
    this.addValidator = commonFunctions.initializeSimpleValidator();
    this.validatorGeneral = commonFunctions.initializeSimpleValidator();
  }

  traitModal = (click) => {

    this.setState({
      traitsStatus: !this.state.traitsStatus,
      click: click,
    });
  };

  addClickCustomTraits = (e) => {
    const { fullState, allFunction } = this.props;
    const arr = [...fullState.customTraits.traits];
    arr.push({ traitName: "", traitDescription: "", isUpdate: true, isDeleted: false, disabled: true });
    allFunction.setState({ customTraits: { ...fullState.customTraits, traits: arr } });
    commonFunctions.onFormChange(this);
  }

  onHandleTraits = (e, { value, id, checked, data }, index) => {
    const { fullState, allFunction } = this.props;
    const traitsData = [...fullState.customTraits.traits];
    const check = index
    if (checked) {
      traitsData[index].isUpdate = true;
      this.setState({ open: true });
    }
    else if (checked === false) {
      traitsData[index].isUpdate = false;
    }
    else {
      traitsData[id][data] = value;
    }
    this.setState({ check })
    allFunction.setState({ customTraits: { ...fullState.customTraits, traits: traitsData } });
    commonFunctions.onFormChange(allFunction);
  }

  openCategory = (a) => {
    this.setState({ open: true })
  }

  closeCategory = (click) => {
    if (click === true) {
      const { fullState, allFunction } = this.props;
      const traitsData = [...fullState.customTraits.traits];
      const index = this.state.check
      traitsData[index].isUpdate = false;
      allFunction.setState({ customTraits: { ...fullState.customTraits, traits: traitsData }, open: false });
    }
    this.setState({ open: false })
  }

  onHandleTraitsDescriptions = (e, { value, id, data }) => {
    const { fullState, allFunction, stateForm, variableName } = this.props;
    const traitsData = [...fullState[stateForm][variableName]];
    traitsData[id][data] = value;
    fullState[stateForm][variableName] = traitsData;
    allFunction.setState({ customTraits: { ...fullState.customTraits, traits: traitsData } });
    commonFunctions.onFormChange(allFunction);
  }

  onHandleAddTraitsField = (closeModal, bb) => {
                                   
    const { fullState, stateForm, allFunction, variableName } = this.props;
    if (allFunction.validatorTraits.allValid() === false) {
      allFunction.validatorTraits.showMessages();
      allFunction.forceUpdate();
    } else {
      fullState[stateForm][variableName] = fullState.customTraits.traits;
      allFunction.setState({ ...fullState })
      allFunction.props.actions.showNotification({
        title: "Success",
        message: 'Traits Added Successfully.', position: 'br',
        autoDismiss: commonFunctions.notificationTime()
      }, "success")
      closeModal();
      commonFunctions.onFormChange(allFunction);
    }
  }

  handleRemoveTraitsRow = (index) => {
                                       
    const { fullState, allFunction } = this.props;
    const rows = [...fullState.customTraits.traits];
    rows[index].isDeleted = true;
    rows[index].traitDescription = "";
    allFunction.setState({ customTraits: { ...fullState.customTraits, traits: rows } });
  }

  render() {
    const { fullState, allFunction, stateForm, variableName } = this.props;
    return (<React.Fragment>
      <Grid.Column width={16}>
        <Divider />
      </Grid.Column>
      <Grid.Column mobile={16} tablet={16} computer={4}>
        <Header as="h4">Traits
          <Popup content="Traits will be the columns on the Item List
                      View. This will enable you to customize each individual item for
                      each item type you create. Example: Color, Size, Guide Name,
                      etc."
            trigger={<Icon name="info circle" className="orange-color" />}
          /></Header>
      </Grid.Column>
      <Grid.Column mobile={16} tablet={16} computer={4}>
        <Button className="orange-btn" onClick={() => this.traitModal(true)}>
          Add Custom Traits
        </Button>
      </Grid.Column>
      {fullState[stateForm][variableName] && fullState[stateForm][variableName].length > 0 &&
        fullState[stateForm][variableName].map((singleData, i) => {
                                             
          return (
            <>
              <Grid.Column width={16} key={i}>
                <Grid>
                  <Grid.Column width={8}>
                    <Form>
                      {singleData.traitDescription !== "" && <Form.TextArea fluid placeholder={singleData.traitDescription} id={i}
                        data="traitDescription"
                        onChange={this.onHandleTraitsDescriptions} value={singleData.traitDescription}
                        error={allFunction.validatorGeneral.message(singleData.traitName, singleData.traitDescription, "min:0|max:500")} />}
                    </Form>
                  </Grid.Column>
                  <Grid.Column width={6}></Grid.Column>
                </Grid>
              </Grid.Column>
            </>)
        }
        )}
      <Modal
        open={this.state.open}
        closeIcon
        onClose={this.closeCategory}
        size={"mini"}>
        <Modal.Content>
          <Grid>
            <Grid.Column width={16} textAlign="center">
              <Header as="h4" className="orange-color">
                Are you sure you want to change description in all fields?
              </Header>
            </Grid.Column>

            <Grid.Column width={16} textAlign="center">
              <Button className="blue-btn" onClick={() => this.closeCategory(true)}>No</Button>
              <Button className="orange-btn" onClick={() => this.closeCategory(false)}>Yes</Button>
            </Grid.Column>
          </Grid>
        </Modal.Content>
      </Modal>

      {this.state.traitsStatus &&
        <AddItemTraitModal openModal={this.state.traitsStatus} closeModal={this.traitModal}
          editClick={this.state.click} onHandleAddTraitsField={this.onHandleAddTraitsField}
          addClickCustomTraits={this.addClickCustomTraits} onHandleTraits={this.onHandleTraits}
          handleRemoveTraitsRow={this.handleRemoveTraitsRow}
          addTraits={fullState.customTraits.traits}
          validatorTraits={allFunction.validatorTraits} />}
    </React.Fragment>
    )
  }
}

export default CommonTraits;