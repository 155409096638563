import React, { Component, Fragment } from "react";
import { Divider, Table, Icon, Popup, Checkbox } from "semantic-ui-react";
import {
  connect,
  bindActionCreators,
  actions,
  commonFunctions,
} from "../../../../../../shared/functional/global-import";
import {
  CommonDeleteUpdateModal,
  GlobalCodeSelect,
} from "../../../../../components";
import { removeDiscountCoupon } from "../../../../../../store/actions/order.actions";

class PaymentStatusTable extends Component {
  get initialState() {
    return {
      isCommonDeleteUpdateModalOpen: false,
      orderDiscountCouponId: null,
      orderCustomChargeId: null,
      seasonPassUsedId: null,
      isBalanceDue: false,
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      ...this.initialState,
      // deleteDiscountCoupon: {
      //     orderId: "", orderDiscountCouponId: "", actionPerformedBy: this.props.auth.userDetail.emailId
      // },
      // isCommonDeleteUpdateModalOpen: false,
      // orderDiscountCouponId:
    };
  }

  onConfirmWarnUser = () => {
    if (this.state.orderDiscountCouponId !== null) {
      this.onDeleteDiscountCoupon();
    }
    if (this.state.orderCustomChargeId !== null) {
      this.onDeleteExtraCharge();
    }
    if (this.state.seasonPassUsedId !== null) {
      this.onDeleteSeasonPassApplied();
    }
  };

  onDeleteExtraCharge = () => {
    this.props.actions.apiCall({
      urls: ["DELETEORDERCUSTOMCHARGES"],
      method: "DELETE",
      data: {

        orderId: this.props.orderState.currentOrder.orderId,
        orderCustomChargeId: this.state.orderCustomChargeId,
        actionPerformedBy: this.props.auth.userDetail.emailId,
      },
      onSuccess: (response) => {
        const updatedExtraCharge =
          this.props.orderState.currentOrder.pricings.ExtraCharges.filter(
            (extraChargeObj) => {
              return (
                extraChargeObj.OrderCustomChargeId !==
                this.state.orderCustomChargeId
              );
            }
          );
        const pricingDetails = {
          updatedExtraCharge: updatedExtraCharge,
          orderTotal: response.orderTotal,
          balanceDue: response.balanceDue,
        };
        this.props.actions.removeExtraCharge(pricingDetails);
        this.setState(this.initialState);
        this.props.actions.getOrderItems(this.props.orderState.currentOrder.orderId)
        // this.setState({ isCommonDeleteUpdateModalOpen: !this.state.isCommonDeleteUpdateModalOpen })
        // this.props.actions.removeDiscountCoupon(response);
      },
      showNotification: true,
    });
  };
  componentDidMount = () => {
                        
    this.setState({ isBalanceDue:this.props.orderState.currentOrder.pricings.IsBalanceDue })
  }
  onDeleteDiscountCoupon = () => {

    this.props.actions.apiCall({
      urls: ["DELETEORDERDISCOUNTANDCOUPONS"],
      method: "DELETE",
      data: {
        orderId: this.props.orderState.currentOrder.orderId,
        orderDiscountCouponId: this.state.orderDiscountCouponId,
        actionPerformedBy: this.props.auth.userDetail.emailId,
      },
      onSuccess: (response) => {

        this.setState(this.initialState);
        this.props.actions.removeDiscountCoupon(response);
        this.props.actions.getOrderItems(
          this.props.orderState.currentOrder.orderId
        );
      },
      showNotification: true,
    });
  };
  onDeleteSeasonPassApplied = () => {
    this.props.actions.apiCall({
      urls: ["DELETEORDERSEASONPASSCOUPONS"],
      method: "DELETE",
      data: {
        orderId: this.props.orderState.currentOrder.orderId,
        orderSeasonPassCouponId: this.state.seasonPassUsedId,
        actionPerformedBy: this.props.auth.userDetail.emailId,
      },
      onSuccess: (response) => {
        // need to work on here
        this.setState(this.initialState);
        this.props.actions.getOrderItems(
          this.props.orderState.currentOrder.orderId
        );
        // this.props.actions.removeDiscountCoupon(response);
      },
      showNotification: true,
    });
  };

  onOpenDeleteModal = (OrderDiscountCouponId, orderId) => {
    const { deleteDiscountCoupon } = this.state;
    deleteDiscountCoupon.orderId = orderId;
    deleteDiscountCoupon.orderDiscountCouponId = OrderDiscountCouponId;

    this.setState({
      deleteDiscountCoupon,
      isCommonDeleteUpdateModalOpen: !this.state.isCommonDeleteUpdateModalOpen,
    });
  };

  onUpdateDepositStatus = (e, { value }) => {
    this.props.actions.apiCall({
      urls: ["UPDATEDEPOSITSTATUSINORDER"],
      method: "PUT",
      data: {
        orderId: this.props.orderState.currentOrder.orderId,
        depositStatus: value,
        actionPerformedBy: this.props.auth.userDetail.emailId,
      },
      onSuccess: (response) => {
        const { orderState } = this.props;
        orderState.currentOrder.depositStatusId = value;
        this.props.actions.updateOrderState(orderState);
      },
      showNotification: true,
    });
  };
  onUpdateToggleStatus = () => {
                       
    this.props.actions.apiCall({
      urls: ["UPDATESHOWHIDEBALANCEDUETOGGLE"],
      method: "PUT",
      data: {
        BusinessLocationId:
          this.props.global.bussinessLocationId,
        orderId: this.props.orderState.currentOrder.orderId,
        isBalanceDue: this.state.isBalanceDue,
        actionPerformedBy: this.props.auth.userDetail.emailId,
      },
      onSuccess: (response) => {
      },
      showNotification: true,
    });
  };
  onHandleChange = (e, { checked, data }) => {
                       
    this.setState({ [data]: checked }, () => this.onUpdateToggleStatus());
  };

  render() {
    const { orderState } = this.props;
                       
    const { isCommonDeleteUpdateModalOpen, messageToShow,
      isBalanceDue } = this.state;

    return (
      <Fragment>
        <Divider />
        <Table
          basic="very"
          className="borderlessTable cartTable"
          compact="very"
          size="small"
        >
          {orderState.currentOrder.pricings &&
            orderState.currentOrder.pricings.Subtotal !== undefined && (
              <Table.Row>
                <Table.Cell>Subtotal</Table.Cell>
                <Table.Cell textAlign="right">
                  {" "}
                  {commonFunctions.isNumberInteger(
                    orderState.currentOrder.pricings.Subtotal
                  )}
                </Table.Cell>
              </Table.Row>
            )}

          {orderState.currentOrder.pricings &&
            orderState.currentOrder.pricings.DiscountAndCoupons &&
            orderState.currentOrder.pricings.DiscountAndCoupons.map(
              (discountObj) => {
                return (
                  <Table.Row>
                    <Table.Cell>
                      {discountObj.Title}
                      <span className="discounticonStyle">
                        {" "}
                        <i
                          title="Delete"
                          id={discountObj.DiscountAndCouponsId}
                          onClick={() =>
                            commonFunctions.deleteModalMessage(
                              this,
                              discountObj.Title,
                              discountObj.OrderDiscountCouponId,
                              "orderDiscountCouponId"
                            )
                          }
                          // onClick={() => this.onOpenDeleteModal(discountObj.OrderDiscountCouponId, orderState.currentOrder.orderId)}
                          class="window close icon"
                        ></i>
                      </span>
                    </Table.Cell>
                    <Table.Cell textAlign="right" className="deepviolet-color">
                      -{" "}
                      {commonFunctions.isNumberInteger(
                        discountObj.DiscountAmount
                      )}
                    </Table.Cell>
                  </Table.Row>
                );
              }
            )}

          {/*  season pass details */}
          {orderState.currentOrder.pricings &&
            orderState.currentOrder.pricings.SeasonPassCoupons &&
            orderState.currentOrder.pricings.SeasonPassCoupons.map(
              (seasonPassObj) => {
                return (
                  <Table.Row>
                    <Table.Cell>
                      {seasonPassObj.MemberCode}
                      <span className="discounticonStyle">
                        {" "}
                        <i
                          title="Delete"
                          id={seasonPassObj.SeasonPassUsedId}
                          onClick={() =>
                            commonFunctions.deleteModalMessage(
                              this,
                              seasonPassObj.MemberCode,
                              seasonPassObj.SeasonPassUsedId,
                              "seasonPassUsedId"
                            )
                          }
                          class="window close icon"
                        ></i>
                      </span>
                    </Table.Cell>
                    <Table.Cell textAlign="right" className="deepviolet-color">
                      -{" "}
                      {commonFunctions.isNumberInteger(
                        seasonPassObj.DiscountAmount
                      )}
                    </Table.Cell>
                  </Table.Row>
                );
              }
            )}

          {/* added new parameter NewSubTotal*/}
          {orderState.currentOrder.pricings &&
            orderState.currentOrder.pricings.Subtotal !== undefined &&
            orderState.currentOrder.pricings.Subtotal !==
            orderState.currentOrder.pricings.NewSubTotal && (
              <Table.Row>
                <Table.Cell>New SubTotal</Table.Cell>
                <Table.Cell textAlign="right">
                  {" "}
                  {commonFunctions.isNumberInteger(
                    orderState.currentOrder.pricings.NewSubTotal
                  )}
                </Table.Cell>
              </Table.Row>
            )}

          {/* {orderState.currentOrder.pricings && orderState.currentOrder.pricings.Taxes && */}
          {orderState.currentOrder.pricings && (
            <Table.Row>
              <Table.Cell>
                Taxes & Fees &nbsp;
                <Popup
                  position="center"
                  trigger={
                    <Icon
                      name="info circle"
                      className="orange-color discount-css"
                    />
                  }
                >
                  <div>
                    <p className="setTaxesPop">
                    Taxes & fees are set in settings. This total does not include the Ahoy Fee, which is applied if a card payment is used. 


                    </p>
                  </div>
                </Popup>
              </Table.Cell>
              <Table.Cell textAlign="right">
                {" "}
                {commonFunctions.isNumberInteger(
                  orderState.currentOrder.pricings.Taxes
                )}
              </Table.Cell>
            </Table.Row>
          )}

          {orderState.currentOrder.pricings &&
            orderState.currentOrder.pricings.ExtraCharges &&
            orderState.currentOrder.pricings.ExtraCharges.length > 0 &&
            orderState.currentOrder.pricings.ExtraCharges.map(
              (extraChargeObj) => {
                return (
                  <Table.Row>
                    <Table.Cell className="fontColorBlue">
                      {extraChargeObj.Descriptions
                        ? extraChargeObj.Descriptions
                        : extraChargeObj.FeeTitle}
                      <span className="discounticonStyle">
                        {" "}
                        <i
                          title="Delete"
                          id={extraChargeObj.OrderCustomChargeId}
                          onClick={() =>
                            commonFunctions.deleteModalMessage(
                              this,
                              extraChargeObj.Descriptions
                                ? extraChargeObj.Descriptions
                                : extraChargeObj.FeeTitle,
                              extraChargeObj.OrderCustomChargeId,
                              "orderCustomChargeId"
                            )
                          }
                          //  onClick={() => this.onOpenDeleteModal(item.OrderDiscountCouponId, orderState.currentOrder.orderId)}
                          class="window close icon"
                        ></i>
                      </span>
                    </Table.Cell>
                    <Table.Cell textAlign="right" className="fontColorBlue">
                      {commonFunctions.isNumberInteger(extraChargeObj.Price)}
                    </Table.Cell>
                  </Table.Row>
                );
              }
            )}

          {orderState.currentOrder.pricings &&
            orderState.currentOrder.pricings.OrderTotal !== undefined && (
              <>
                <Table.Row className="orange-color">
                  <Table.Cell>Order Total</Table.Cell>
                  <Table.Cell textAlign="right">
                    {" "}
                    {commonFunctions.isNumberInteger(
                      orderState.currentOrder.pricings.OrderTotal
                    )}
                  </Table.Cell>
                </Table.Row>

                {orderState.currentOrder.pricings &&
                  orderState.currentOrder.totalDeposit !== 0 && (
                    <Table.Row>
                      <Table.Cell>Deposit</Table.Cell>
                      <Table.Cell textAlign="right">
                        {commonFunctions.isNumberInteger(
                          orderState.currentOrder.totalDeposit
                        )}
                      </Table.Cell>
                    </Table.Row>
                  )}

                {orderState.currentOrder.totalDeposit !== 0 && (
                  <Table.Row>
                    <Table.Cell>Deposit Status</Table.Cell>
                    <div className="depositStatus">
                      <GlobalCodeSelect
                        className="custom-select bordered-input"
                        placeholder="Deposit Status"
                        name="deposit"
                        data="depositStatus"
                        value={orderState.currentOrder.depositStatusId}
                        onChange={this.onUpdateDepositStatus}
                        categoryType="Deposit Status"
                      />
                    </div>
                    {/* <Table.Cell textAlign="right" className="orange-color">{orderState.currentOrder.depositStatus}</Table.Cell> */}
                  </Table.Row>
                )}

                {orderState.currentOrder.pricings &&
                  orderState.currentOrder.pricings.TipAmount !== null && (
                    <Table.Row className="orange-color">
                      <Table.Cell>Paid Tip</Table.Cell>
                      <Table.Cell textAlign="right">
                        {" "}
                        {commonFunctions.isNumberInteger(
                          orderState.currentOrder.pricings.TipAmount
                        )}
                      </Table.Cell>
                    </Table.Row>
                  )}

                {orderState.currentOrder.pricings.AmountPaid >= 0 && (
                  <Table.Row>
                    <Table.Cell>Paid Total</Table.Cell>
                    <Table.Cell textAlign="right">
                      {commonFunctions.isNumberInteger(
                        orderState.currentOrder.pricings.AmountPaid
                      )}
                    </Table.Cell>
                  </Table.Row>
                )}
                <Table.Row>
                  <Table.Cell colspan="2">
                    <Divider fitted />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Balance Due</Table.Cell>
                  {isBalanceDue ? null :
                    <Table.Cell textAlign="right" className="orange-color">
                      {commonFunctions.isNumberInteger(
                        orderState.currentOrder.pricings.BalanceDue)}
                    </Table.Cell>}
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Hide Ahoy Fee</Table.Cell>
                  <Table.Cell textAlign="" className="orange-color ahoyFeeClass">
                    <Checkbox
                      data="isBalanceDue"
                      name="isBalanceDue"
                      checked={isBalanceDue}
                      value={isBalanceDue}
                      onChange={this.onHandleChange}
                      className="custom-toggle" />
                  </Table.Cell>
                </Table.Row>
                {isBalanceDue &&
                  <>
                    <Table.Row className="feeRowData">
                      <Table.Cell>Ahoy Fee</Table.Cell>
                      <Table.Cell textAlign="right" className="orange-color">
                        {commonFunctions.isNumberInteger(
                          orderState.currentOrder.pricings.AhoyFee
                        )}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row className="feeRowData">
                      <Table.Cell>Card Total</Table.Cell>
                      <Table.Cell textAlign="right" className="orange-color">
                        {commonFunctions.isNumberInteger(
                          orderState.currentOrder.pricings.CardTotal
                        )}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row className="feeRowData">
                      <Table.Cell>Cash Total</Table.Cell>
                      <Table.Cell textAlign="right" className="orange-color">
                        {commonFunctions.isNumberInteger(
                          orderState.currentOrder.pricings.CashTotal
                        )}
                      </Table.Cell>
                    </Table.Row>

                  </>}
              </>
            )}
        </Table>

        {isCommonDeleteUpdateModalOpen && (
          <CommonDeleteUpdateModal
            isModalOpen={isCommonDeleteUpdateModalOpen}
            onConfirm={this.onConfirmWarnUser}
            // onClose={() => this.setState({ isCommonDeleteUpdateModalOpen: !this.state.isCommonDeleteUpdateModalOpen })}
            onClose={() => this.setState(this.initialState)}
            confirmationButtonText={"Yes"}
            header={messageToShow}
            cancelButtonText={"Cancel"}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    api: state.api,
    auth: state.auth,
    global: state.global,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      removeDiscountCoupon: bindActionCreators(removeDiscountCoupon, dispatch),
      removeExtraCharge: bindActionCreators(
        actions.removeExtraCharge,
        dispatch
      ),
      getOrderItems: bindActionCreators(actions.getOrderItems, dispatch),
      updateOrderState: bindActionCreators(actions.updateOrderState, dispatch),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentStatusTable);
