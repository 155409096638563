//   Apply Season Pass used component

import React, { Component } from "react";
import { Button, Grid, Modal, Header, Form } from "semantic-ui-react";
import {
  withRouter,
  connect,
  bindActionCreators,
  actions,
  commonFunctions,
} from "../../../../../../functional/global-import";

class SeasonPassModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      seasonPassForm: {
        businessId: 0,
        businessLocationId: 0,
        orderId: 0,
        amount: 0,
        memberCode: "",
        subTotal: this.props.seasonPassSubtotal,
        actionPerformedBy: "",
      },
    };

    this.validator = commonFunctions.initializeSimpleValidator();
  }
  componentDidMount() {
    this.onSettingDefaultValues();
  }

  onSettingDefaultValues = () => {
    //
    const { seasonPassForm } = this.state;
    seasonPassForm.businessId = this.props.global.bussinessDetail.businessId;
    seasonPassForm.businessLocationId =
      this.props.global.locationSelected.BusinessLocationId;
    seasonPassForm.orderId = this.props.orderState?.currentOrder?.orderId;
    seasonPassForm.amount =
      this.props.orderState.currentOrder.pricings.BalanceDue;
    seasonPassForm.actionPerformedBy = this.props.auth.userDetail.emailId;
    this.setState({ seasonPassForm });
  };

  onHandleChange = (e, { name, value, type, data }) => {
    var seasonPassForm = commonFunctions.onHandleChange(
      e,
      { name, value, type, data },
      this.state.seasonPassForm
    );
    this.setState({ seasonPassForm });
  };

  onApplySeasonPass = (e) => {
    var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
    if (isFormValid) {
      this.props.actions.apiCall({
        urls: ["ADDORDERSEASONPASSCOUPONS"],
        method: "POST",
        data: this.state.seasonPassForm,
        onSuccess: (response) => {
          this.props.closeModal();
          this.props.onApplySeasonPass();
        },
        showNotification: true,
      });
    }
  };
  render() {
    const { seasonPassForm } = this.state;
    const { api } = this.props;
    return (
      <>
        <Modal
          closeOnDimmerClick={false}
          open={this.props.openModal}
          closeIcon
          onClose={this.props.closeModal}
          size={"mini"}
        >
          <Modal.Content>
            {api.isApiLoading && commonFunctions.showLoader()}
            <Grid>
              <Grid.Column width={14}>
                <Header as="h4" className="orange-color">
                  Apply Passes
                </Header>
              </Grid.Column>
              <Grid.Column width={16}>
                <Form>
                  <Form.Field>
                    <Form.Input
                      fluid
                      textAlign="left"
                      name="txtTitle"
                      placeholder="Member Code"
                      maxLength="50"
                      error={this.validator.message(
                        "memberCode",
                        seasonPassForm.memberCode,
                        "required"
                      )}
                      data="memberCode"
                      type="text"
                      value={seasonPassForm.memberCode}
                      onChange={this.onHandleChange}
                    />
                  </Form.Field>
                </Form>
              </Grid.Column>
              <Grid.Column width={16} textAlign="right">
                <Button className="blue-btn" onClick={this.props.closeModal}>
                  Cancel
                </Button>
                <Button className="orange-btn" onClick={this.onApplySeasonPass}>
                  Apply
                </Button>
              </Grid.Column>
            </Grid>
          </Modal.Content>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    api: state.api,
    auth: state.auth,
    global: state.global,
    orderState: state.orderState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeWarnUser: bindActionCreators(actions.storeWarnUser, dispatch),
    },
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SeasonPassModal)
);
