import React, { Component } from "react";
import CreditCardModal from "./credit-card-modal/credit-card-modal";
import CreditCardPaymentModal from "./credit-card-modal/credit-card-payment-modal";
import {
  commonFunctions,
  bindActionCreators,
  Notifications,
  connect,
  actions,
} from "../../../../../../functional/global-import";
class CreditCard extends Component {
  constructor(props) {
    super();

    this.state = {
      activeStep: 0,
      value: "Balance Due",
      selectPaymentType: null,
      counterDetails: null,
      selectedGlobalCodeId:null,
    };
    this.validator = commonFunctions.initializeSimpleValidator();
  }
  handleChange = (e, { value }) => {
    this.setState({ value: value });
  };

  handleChangeSelection = (e, { value }) => {
    this.setState({ selectPaymentType: value });
  };
  handleSubmit = (e) => {
    const { value, selectPaymentType } = this.state;
    const orderTypeJson =this.props.orderTypeData;
    const customCodeId = commonFunctions.getGlobalCodeIdByCodeName(value, orderTypeJson);
    const stripepayment = selectPaymentType || null;
    if (value === "") {;
      this.props.actions.showNotification(
        {
          title: "Error",
          message: "Please select anyone payment option ",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error");
    } else if (stripepayment === null) {
      this.props.actions.showNotification(
        {
          title: "Error",
          message: "Please select anyone payment option",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
 );
    } 
    else {
      const { orderState } = this.props;
      if (value === "Selected Item(s)") {
        let amountSlected = 0;
        orderState.currentOrder.selectedAmountItem.map((singleObj) => {
          amountSlected = amountSlected + singleObj.amount;
        });
        if (orderState.currentOrder.selectedAmountItem.length > 0) {
          this.props.CreditCardPaymentModal(amountSlected);
          this.setState({selectedGlobalCodeId:customCodeId})
        } else {
          this.props.actions.showNotification(
            {
              title: "Error",
              message: "Please select atleast one Item ",
              position: "br",
              autoDismiss: commonFunctions.notificationTime(),
            },
            "error"
          );
        }
      } else {
        this.props.CreditCardPaymentModal(
          orderState.currentOrder.pricings.BalanceDue
        );
        this.setState({selectedGlobalCodeId:customCodeId})
      }
    }
  };
  handleClose = () => {
    this.setState({
      value: "",
      selectPaymentType: "",
    });
    this.props.CreditCardModal();
  };
  onGetStripeDetail = () => {
    this.props.actions.apiCall({
      urls: ["GETSTRIPEDETAIL"],
      method: "GET",
      data: {
        BusinessId: this.props.global.bussinessDetail.businessId,
        BusinessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
      },
      onSuccess: (response) => {
        this.setState({ stripelocationDetails: response });
        return response;
      },
    });
  };

  componentDidMount = () => {
    this.onGetStripeDetail();
    this.getCounterDetails();
    const defaultPaymentType =
     this.props.paymentSelect?.length > 0 ? 
    this.props.paymentSelect?.find((a) => a.key === "Stripe")||
    this.props.paymentSelect?.find((a) => a.key === "EdgePay")
        : null;
    this.setState({
      selectPaymentType: defaultPaymentType === null ?
        null : defaultPaymentType.value,
    });
  };
  getCounterDetails = () => {
    const { global } = this.props;
    this.props.actions.apiCall({
      urls: ["GETCOUNTERNAME"],
      method: "GET",
      data: {
        businessId: global.bussinessDetail.businessId,
        businessLocationId: global.locationSelected.BusinessLocationId,
        PageNo: 1,
        PageSize: 100,
      },
      onSuccess: (response) => {
        this.setState({ counterDetails: response });
      },
    });
  };

  render() {
    const { creditcardStatus, creditcardpaymentStatus, amountSlected } = this.props.creditCardModal;
    const { value, stripelocationDetails, gettrxValue, selectPaymentType } = this.state;
    const { defaultUserDetails } = this.props;            
    return (
      <div>
        {creditcardStatus && (
          <CreditCardModal
            paymentSelect={this.props.paymentSelect}
            isShowGettrixPayment={this.props.isShowGettrixPayment}
            openModal={creditcardStatus}
            closeModal={this.props.CreditCardModal}
            onNextClick={this.props.CreditCardPaymentModal}
            value={value}
            CreditCard={this.props.CreditCard}
            selectPaymentType={selectPaymentType}
            gettrxValue={gettrxValue}
            gettrxTittle="Select the Payment"
            title="Credit & Debit Card"
            paymentTittle="Make payment for"
            handleChange={this.handleChange}
            handleChangeSelection={this.handleChangeSelection}
            handleSubmit={this.handleSubmit}
            handleClose={this.handleClose}
            itemDetailsGridState={this.props.itemDetailsGridState}
          />
        )}
        {creditcardpaymentStatus && (
          <CreditCardPaymentModal
            paymentSelect={this.props.paymentSelect}
            allFunction={this}
            CreditCard={this.props.CreditCard}
            openModal={creditcardpaymentStatus}
            handleClose={this.handleCloseds}
            amountSlected={amountSlected}
            title="Credit & Debit Card"
            paymentType="Card"
            fullState={this.state}
            selectPaymentType={selectPaymentType}
            stripelocationDetails={stripelocationDetails}
            closeModal={this.props.CreditCardPaymentModal}
            onNextClick={this.props.TapInsertModal}
            value={value}
            itemDetailsGridState={this.props.itemDetailsGridState}
            defaultUserDetails={defaultUserDetails}
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    auth: state.auth,
    orderState: state.orderState,
    global: state.global,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditCard);
