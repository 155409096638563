import React from "react";
import { Grid, Input } from "semantic-ui-react";

// this component create as an clsss component for now in next version we add debounce in this comp too
class SearchInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {
            placeholder,
            hasSearchIocn,
            searchValue,
            iconPosition,
            data,
            fluid,
            className,
            searchHandler
        } = this.props;

        return (
            <Grid.Column width={10}>
                <Input
                    fluid={fluid}
                    icon={hasSearchIocn ? "search" : ""}
                    iconPosition={iconPosition}
                    value={searchValue}
                    placeholder={placeholder}
                    className={className}
                    data={data}
                    onChange={(e) => searchHandler(e)}
                />
            </Grid.Column>
        );
    }
}

export default SearchInput;