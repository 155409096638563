import React, { Component } from 'react'
import { Dropdown } from 'semantic-ui-react'

const options = [
  { key: 'Location 1', text: 'Location 1', value: 'Location 1' },
  { key: 'Location 2', text: 'Location 2', value: 'Location 2' },
  { key: 'Location 3', text: 'Location 3', value: 'Location 3' },
  { key: 'Location 4', text: 'Location 4', value: 'Location 4' },
  { key: 'Location 5', text: 'Location 5', value: 'Location 5' },
]

class LocationTagger extends Component {

  state = { options }

  handleAddition = (e, { value }) => {
    this.setState((prevState) => ({
      options: [{ text: value, value }, ...prevState.options],
    }))
  }

  handleChange = (e, { value }) => this.setState({ currentValues: value })

  render() {
    const { currentValues } = this.state

    return (
      <Dropdown options={this.state.options} placeholder='Choose Location' search selection fluid multiple allowAdditions value={currentValues} onAddItem={this.handleAddition} onChange={this.handleChange} className="tagger"/>
    )
  }
}

export default LocationTagger
