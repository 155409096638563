import React, { Component } from "react";
import { Button, Input, Grid, Modal,Form,Icon ,Select } from "semantic-ui-react";

const Hours = [
	{ key: "Minute", value: "Minute", text: "Minute" },
	{ key: "hour", value: "hour", text: "Hour" },
	{ key: "Day", value: "Day", text: "Day" },
  ];

class AddCustomDuration extends Component {
    // state = { options }
    render() { 
        return (
        <Modal open={this.props.openModal} closeIcon onClose={this.props.closeModal} size={"mini"}>
        <Modal.Header>Add Custom Duration</Modal.Header>
        <Modal.Content>
          <Grid>
            <Grid.Column width={8}>
            <Form.Field className="add-numbers">
                <Icon name="minus" className="orange-button"></Icon>
                <Input defaultValue="1" fluid />
                <Icon name="plus" className="orange-button plus-btn"></Icon>
            </Form.Field>
            </Grid.Column>
            <Grid.Column width={8}>
              <Select options={Hours} className="custom-select" defaultValue="hour" fluid/>
            </Grid.Column>
            <Grid.Column width={16} textAlign="right">
              <Button className="blue-btn" onClick={this.props.closeModal}>Cancel</Button>
              <Button className="orange-btn" onClick={this.props.closeModal}>Save</Button>
            </Grid.Column>
          </Grid>
        </Modal.Content>
      </Modal>
         );
    }
}
 
export default AddCustomDuration;