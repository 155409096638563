import React, { Component } from "react";
import { Button, Grid, Modal, Form, TextArea } from "semantic-ui-react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { connect, bindActionCreators, actions, commonFunctions } from "../../../../functional/global-import";
import { IntegrationImg } from "../../../../functional/global-image-import";

class WebsitePageAddTexModal extends Component {
  get initialState() {
    return {
      businessId: this.props.global.bussinessDetail.businessId,
      description: "",
      editorState: EditorState.createEmpty(),
      isContentLoading: false,
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      ...this.initialState,
      businessId: this.props.global.bussinessDetail.businessId,
      websiteDetailType: "",
      knowaboutscreen: ""
    };
  }

  onEditorStateChange = (editorState) => {
    let { description } = this.state;
    description = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
    this.setState({ editorState, description });
  };

  getIntegration = () => {
    this.props.actions.apiCall({
      urls: ["GETINTEGRATIONS"], method: "GET", data: {
        "businessId": this.props.global.bussinessDetail.businessId,
        "integrationType": this.state.knowaboutscreen
      }, onSuccess: (response) => {
          
        this.setState({
          editorState: EditorState.createWithContent(convertFromRaw(JSON.parse(response[0].description))),
          knowaboutscreen: response[0].integrationType,
          description: response[0].description
        })
      }
    });
  }

  componentDidMount() {
    switch (this.props.widgetScreen) {
      case "About us screen":
        this.setState({ knowaboutscreen: "A" }, this.getIntegration);
        break;
      case "Contact us screen":
        this.setState({ knowaboutscreen: "C" }, this.getIntegration);
        break;
      case "Terms & Conditions screen":
        this.setState({ knowaboutscreen: "T" }, this.getIntegration);
        break;
      case "Policy":
        this.setState({ knowaboutscreen: "P" }, this.getIntegration);
        break;
    }
  }

  // POST API for Post Data
  postData = () => {
      
    this.props.actions.apiCall({
      urls: ["POSTINTEGRATION"], method: "POST",
      data: {
        businessId: this.props.global.bussinessDetail.businessId,
        integrationType: this.state.knowaboutscreen,
        description: this.state.description,
        actionPerformedBy: this.props.auth.userDetail.emailId,
      }, onSuccess: (response) => {

        if (response.responseCode == 200) {
          this.onhandleClearForm();
          EditorState.createEmpty();
          this.props.closeModal();
        }
      }, showNotification: true,
    });
  }

  onHandleChange = (e, { name, value, type, checked, data }) => {
      
   
    this.setState({ description: value });
  }

  onhandleClearForm = () => {
    this.setState({ description: "" });
  }

  render() {
    const { editorState, isContentLoading } = this.state;
    const { api } = this.props
    return (
      <Modal
        open={this.props.openModal}
        closeIcon
        onClose={this.props.closeModal}
        closeOnDimmerClick={false}
        size={"small"}>
        {api.isApiLoading && commonFunctions.showLoader()}
        <Modal.Header>Add Text</Modal.Header>
        <Modal.Content>
          <Grid>
            <Grid.Column width={16}>
              <Form>
                <Editor
                  editorState={editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  toolbar={{
                    options: ["inline", "blockType", "fontSize", "fontFamily", "textAlign", "colorPicker", "remove",],
                    inline: {
                      inDropdown: false,
                      className: "test",
                      component: undefined,
                      dropdownClassName: undefined,
                      options: ["bold", "italic", "underline"],
                      // options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 
                      // 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
                      bold: { className: "test", style: { color: "red" } },
                      italic: { className: undefined },
                      underline: { className: undefined },
                    },
                  }}
                  onEditorStateChange={this.onEditorStateChange} />
              </Form>
            </Grid.Column>
            <Grid.Column width={16} textAlign="right">
              <Button className="blue-btn" onClick={this.props.closeModal}>
                Cancel
              </Button>
              <Button className="orange-btn" onClick={this.postData}>
                Save
              </Button>
            </Grid.Column>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}
const mapStateToProps = state => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch)
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WebsitePageAddTexModal);