import React, { Component } from "react";
import { Header, Modal, Grid, Select, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";


const ChangeLocation = [
  { key: 'af', value: 'af', text: 'Rentals' },
  { key: 'ax', value: 'ax', text: 'Rentals > All Bikes' },
  { key: 'al', value: 'al', text: 'Rentals > Surfboards' },
]
class LocationChangeModal extends Component {
  render() {
    return (
      <Modal
        open={this.props.openModal}
        closeIcon
        onClose={this.props.closeModal}
        size={"mini"}
      >
        <Header content="Add Location" />
        <Modal.Content>
          <Grid textAlign="center">
            <Grid.Column width={16}>
              <Select placeholder="Select Location" options={ChangeLocation} className="custom-select" />
            </Grid.Column>
            <Grid.Column width={16}>
              <Link as={Link} className="orange-color">Item Detail</Link>
            </Grid.Column>
            <Grid.Column width={16}>
              <Button className="blue-btn" onClick={this.props.closeModal}>
                Cancel
              </Button>
              <Button className="orange-btn" onClick={this.props.closeModal}>
                Save
              </Button>

            </Grid.Column>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}

export default LocationChangeModal;
