import React, { Component } from "react";
import { Button, Form, Grid, Modal, } from "semantic-ui-react";
import { DateFormatInput } from '../../..';
import { connect, bindActionCreators, actions, Notifications, commonFunctions } from "../../../../functional/global-import";
import moment from 'moment';


class BlackOutDateModal extends Component {
  get initialState() {
    return {
    blackOutDates : {
          title:"",
          blackOutStartDate:"",
          blackOutEndDate:"",
          InDiscountAndCoupons: this.props.commonBlackOut === false ? false : true,
          businessId: this.props.global.bussinessDetail.businessId,
          businessLocationId: this.props.global.locationSelected.BusinessLocationId,
          BlackOutStartDate: "", BlackOutEndDate: "",
          actionPerformedBy: this.props.auth.userDetail.emailId,
          isActive: true,
          InDiscountAndCoupons: this.props.commonBlackOut === false ? false : true,
          blackOutDateId: -1, pageNo: 1, pageSize: 100
        }
        // blackOutdate: {
        //   businessId: this.props.global.bussinessDetail.businessId,
        //   businessLocationId: this.props.global.locationSelected.BusinessLocationId,
        //   BlackOutStartDate: "", BlackOutEndDate: "",
        //   actionPerformedBy: this.props.auth.userDetail.emailId,
        //   isActive: true,
        //   InDiscountAndCoupons: this.props.commonBlackOut === false ? false : true,
        //   // InDiscountAndCoupons: false,
        //   title: "", blackOutDateId: -1, pageNo: 1, pageSize: 100
        // }
      }
    }

  constructor(props) {
    
    super(props);
    this.state = {
      ...this.initialState,
      blackOutStartDate:"",
      blackOutEndDate:"",

      blackOutData:"",
      blackOutDateDetails:[],
      updateButton:false,
    };
    this.validator = commonFunctions.initializeSimpleValidator();
  }

  onHandleChange = (e, { name, value, type, checked, data }) => {
      if (data === "BlackOutStartDate") {
        this.setState({ blackOutDates: { ...this.state.blackOutDates, BlackOutStartDate: value, BlackOutEndDate: value } });
      } else {
        var blackOutDates = commonFunctions.onHandleChange(e, { name, value, type, checked, data }, this.state.blackOutDates);
        this.setState({ blackOutDates });
      }
  }
//   onHandleChange = (e, { name, value, type, checked, data, }) => {
//     
//     var blackOutDates = commonFunctions.onHandleChange(e, { name, value, type, checked, data }, this.state.paymentTypeForm, this);
//     this.setState({ blackOutDates });
// };
      onHandleSubmit = (e) => {
        var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
        if (isFormValid) {
          this.props.closeModal(isFormValid);
        }
      }
      updateBlackOutDate = (e) => {
        var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
        if (isFormValid) {
          const { blackOutdate } = this.state
          var data =
          {
            blackOutDateId: blackOutdate.blackOutDateId,
            businessId: blackOutdate.businessId,
            title: blackOutdate.title,
            isActive: blackOutdate.isActive,
            businessLocationId: blackOutdate.businessLocationId,
            BlackOutStartDate: commonFunctions.saveFormattedDate(blackOutdate.BlackOutStartDate),
            BlackOutEndDate: commonFunctions.saveFormattedDate(blackOutdate.BlackOutEndDate),
            actionPerformedBy: blackOutdate.actionPerformedBy
          }
          this.props.actions.apiCall({
            urls: ["UPDATEBLACKOUTDATE"], method: "PUT", data: data, onSuccess: (response) => {
              this.setState(this.initialState, this.props.closeModal)
              this.props.child.current.childsChild.current.getDefaultData()
            }, isFormData: true, showNotification: true,
          });
        }
      }
      // blackOutDateModalModal = (blackOutDates) => {
      
      //   this.state.blackOutDates = blackOutDates;
      //   let sDate = blackOutDates.blackOutDates;
      //   let eDate = blackOutDates.blackOutEndDate;
      //   var startDate = new Date(sDate);
      //   var sDateFormat = moment(startDate).format('MMM DD, YYYY');
      //   var endDate = new Date(eDate);
      //   var eDateFormat = moment(endDate).format('MMM DD, YYYY');
      //   this.setState({
      //     ...this.state, blackOutDates: {
      //       ...this.state.blackOutDates,
      //       title: blackOutDates.title,
      //       blackOutStartDate: sDateFormat,
      //       blackOutEndDate: eDateFormat
      //     }
      //   });
      // }
      
  render() {
  //  const { blackOutDates } = this.state;
   const{ blackOutDates}= this.props;
    

    return (
      <Modal open={this.props.openModal} closeIcon onClose={this.props.closeModal} closeOnDimmerClick={false} size={'small'}>
        <Modal.Header>Edit Blackout Dates</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Grid>
            <Grid.Column computer={16} tablet={16}>
                <Form.Input fluid textAlign="left" name="txtTitle" placeholder="Title" maxLength="50"
                 data="title"
                  value={blackOutDates.title} onChange={this.onHandleChange} />
              </Grid.Column>
              <Grid.Column computer={8} tablet={16}>
                <DateFormatInput name="txtblackOutStartDate" placeholder="Start Blackout date" 
                  value={blackOutDates.blackoutStartDate} iconPosition="right" onChange={this.onHandleChange}
                  data="BlackOutStartDate" />
              </Grid.Column>
              <Grid.Column computer={8} tablet={16}>
                <DateFormatInput name="txtblackOutEndDate" placeholder="End Blackout date"
                  value={blackOutDates.blackoutStartDate} 
                   iconPosition="right" onChange={this.onHandleChange} data="BlackOutEndDate" />
              </Grid.Column>

              <Grid.Column width={16} textAlign="right">
              <Button className="blue-btn" onClick={this.props.closeModal}>Cancel</Button>
              <Button className="orange-btn" onClick = {this.updateBlackOutDate}>Update</Button>
              </Grid.Column>
            </Grid>
        </Modal.Description>
        </Modal.Content>
      </Modal>
    
      );
    }
  }

const mapStateToProps = state => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch)
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BlackOutDateModal);