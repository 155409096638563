import { Component } from 'react';
import AddOnsForm from '../../../../../pages/more-setting/add-ons-setting/add-ons-form';
import { Modal } from "semantic-ui-react";

class AddOnsModal extends Component {
  state = {
  }
  render() {
    return (
      <Modal open={this.props.openModal} closeIcon onClose={this.props.closeModal} closeOnDimmerClick={false}
        size={"small"}>
        <Modal.Content scrolling>
          <AddOnsForm hideToggleForModal={true}
            onClose={this.props.closeModal} />
        </Modal.Content>
      </Modal>
    );
  }
}

export default AddOnsModal;