import React, { Component } from "react";
import { Button, Modal, Form, Grid, Checkbox } from "semantic-ui-react";
import { commonFunctions, connect, bindActionCreators, actions, withRouter, } from "../.././../../functional/global-import";
import { CommonDeleteUpdateModal } from "../../../../components";

class ResetPasscodePasswordModal extends Component {
  get initialState() {
    return {
      resetPassword: {
        password: "",
        confirmPassword: "",
        passcode: "",
        confirmPasscode: "",
        isActive: true,
        userId: "",
        actionPerformedBy: this.props.auth.userDetail.emailId
      },
      isCommonDeleteUpdateModalOpen: false,
    };
  }

  constructor(props) {
    super(props);
    this.state = { ...this.initialState };
    this.validator = commonFunctions.initializeSimpleValidator();
  }


  static getDerivedStateFromProps(props, state) {
    ;
    if (props.userId && props.openModal && props.userId !== state.userId) {
      return {
        resetPassword: {
          ...state.resetPassword,
          userId: props.userId
        }
      };
    }
  }

  // This function is used to set handle change
  onHandleChange = (e, { name, value, type, checked, data }) => {
    var resetPassword = commonFunctions.onHandleChange(e, { name, value, type, checked, data }, this.state.resetPassword, this);
    if (data === 'isActive') {
      this.setState(() => ({
        resetPassword: {
          ...this.state.resetPassword, password: "",
          confirmPassword: "",
          passcode: "",
          confirmPasscode: "",
          isActive: checked
        }
      }));
      this.validator = commonFunctions.initializeSimpleValidator();
    }
    else {
      this.setState({ resetPassword });
    }
  }

  // This function is used to set handle submit
  onHandleSubmit = (e) => {
    var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
    if (isFormValid) {
      const { resetPassword } = this.state;
      this.props.actions.apiCall({
        urls: ["UPDATEPASSCODEANDPASSWORD"], method: "PUT", data: resetPassword,
        onSuccess: (response) => {
          commonFunctions.onApiSucessFormChangedFalse(this)
          this.onCloseModal();
        }, showNotification: true
      });
    }
  }

  // onCloseModal = () => {
  //   this.props.closeModal();
  //   this.setState(this.initialState);
  // }


  onCloseModal = () => {
    if (this.props.warnUserState.isFormChanged) {
      this.setState({ isCommonDeleteUpdateModalOpen: true })
    }
    else {
      this.onModalCloseFormFalse();
    }
  }


  onModalCloseFormFalse = () => {
    this.props.closeModal();
    this.setState(this.initialState);
    this.validator = commonFunctions.initializeSimpleValidator();
  }


  onConfirmWarnUser = () => {
    this.setState({ isCommonDeleteUpdateModalOpen: false })
    commonFunctions.onApiSucessFormChangedFalse(this);
    this.onModalCloseFormFalse();
  }


  render() {
    const { resetPassword, isCommonDeleteUpdateModalOpen } = this.state;
    return (
      <>
        <Modal open={this.props.openModal} closeIcon onClose={this.onCloseModal} size="tiny" >
          <Modal.Header>Reset Password & Passcode
          </Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Form>
                <Grid>
                  <Grid.Column mobile={16} tablet={8} computer={8}>
                    <Form.Group inline>
                      <span>Passcode &nbsp;&nbsp;</span>
                      <div className="amount-percentage">
                        <Checkbox
                          label="Password"
                          className="custom-toggle"
                          name="chkPasswordPasscode"
                          data="isActive"
                          checked={resetPassword.isActive}
                          onChange={this.onHandleChange}
                          value={resetPassword.isActive}
                        />
                      </div>
                    </Form.Group>
                  </Grid.Column>

                  {resetPassword.isActive === true && <Grid.Row columns="2">
                    <Grid.Column>
                      <Form.Input type="password" fluid textAlign="left" placeholder="Enter New Password"
                        name="txtPassword" data="password" onChange={this.onHandleChange}
                        error={resetPassword.isActive === true || resetPassword.isActive === false ?
                          this.validator.message('password', resetPassword.password,
                            'required|min:6|max:20') : ""} value={resetPassword.password}>
                      </Form.Input>
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Input type="password" fluid textAlign="left" placeholder="Confirm New Password"
                        name="txtConfirmPassword" data="confirmPassword" onChange={this.onHandleChange}
                        error={this.validator.message('confirmPassword', resetPassword.confirmPassword,
                          `required|validConfirmPassword:${resetPassword.password}`)} value={resetPassword.confirmPassword}>
                      </Form.Input>
                    </Grid.Column>
                  </Grid.Row>}
                  {resetPassword.isActive === false && <Grid.Row columns="2">
                    <Grid.Column>
                      <Form.Input type="password" fluid textAlign="left" placeholder="New Passcode"
                        name="txtPasscode" data="passcode" onChange={this.onHandleChange}
                        error={this.validator.message('passcode', resetPassword.passcode,
                          'required|integer|min:6|max:6')} value={resetPassword.passcode}></Form.Input>
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Input type="password" fluid textAlign="left" placeholder="Confirm New Passcode"
                        name="txtConfirmPasscode" data="confirmPasscode" onChange={this.onHandleChange}
                        error={this.validator.message('confirmPasscode', resetPassword.confirmPasscode,
                          `required|validConfirmPasscode:${resetPassword.passcode}`)} value={resetPassword.confirmPasscode}>
                      </Form.Input>
                    </Grid.Column>
                  </Grid.Row>}
                </Grid>
              </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.onCloseModal} className="blue-btn"> Cancel </Button>
            <Button onClick={this.onHandleSubmit} className="orange-btn" loading={this.props.api.isApiLoading}> Update </Button>
          </Modal.Actions>
        </Modal>
        {isCommonDeleteUpdateModalOpen &&
          <CommonDeleteUpdateModal
            isModalOpen={isCommonDeleteUpdateModalOpen}
            onConfirm={this.onConfirmWarnUser}
            onClose={() => this.setState({ isCommonDeleteUpdateModalOpen: false })}
            confirmationButtonText={"Yes"}
            header={"Are you sure you want to discard your changes ?"}
            cancelButtonText={"Cancel"}
          />
        }
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
    warnUserState: state.warnUserState,
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeWarnUser: bindActionCreators(actions.storeWarnUser, dispatch),
    }
  };
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPasscodePasswordModal));

