import React, { Component } from "react";
import {
  Grid,
  Form,
  Header,
  Button,
  Table,
  Icon,
  Image,
  Input,
} from "semantic-ui-react";
import {
  commonFunctions,
  connect,
  bindActionCreators,
  actions,
  Notifications,
  Link,
} from "../../../functional/global-import";
import AddNewAddOnsModal from "../../../components/organisms/modal/add-new-add-ons-modal/index";

class CommonAddOns extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addOnsList: [],
      updatebuttonAddOns: false,
      Price: "",
      AddOnId: "",
      AddOnTitle: "",
      Image: "",
      addOnInStore: true,
      addOnWebsiteWidget: true,
      addOnsModalStatus: false,
      MaxAddOns: null,
      MinAddOns: null,
    };
    this.validator = commonFunctions.initializeSimpleValidator();
  }

  addOnsModal = () => {
    this.setState({
      addOnsModalStatus: !this.state.addOnsModalStatus,
    });
  };

  addOnsClosedModal = () => {
    this.setState({
      addOnsModalStatus: false,
    });
    this.getAddOns();
  };

  getAddOns = () => {
    this.props.actions.apiCall({
      urls: ["GETADDONS"],
      method: "GET",
      data: {
        addOnId: -1,
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        PageNo: 1,
        PageSize: 100,
        IsActive: true,
      },
      onSuccess: (response) => {
        const allAddOns = response.map((singleAddons) => {
          const image = JSON.parse(singleAddons.image);
          return {
            value: singleAddons.addOnId,
            text: singleAddons.addOnTitle,
            image: commonFunctions.concatenateImageWithAPIUrl(
              image ? image[0].ImageFile : null
            ),
            Image: singleAddons.image,
            Price: singleAddons.addOnPrice,
            MaxAddOns:
              singleAddons.maxAddOns === 0 ? null : singleAddons.maxAddOns,
            MinAddOns:
              singleAddons.minAddOns === 0 ? null : singleAddons.minAddOns,
          };
        });
        this.setState({ addOnsList: allAddOns });
      },
    });
  };
  componentDidMount() {
    this.getAddOns();
  }
  onHandleUpdatedOnsPush = (e) => {
    const { stateForm, fullState, allFunction } = this.props;
    const {
      AddOnId,
      Price,
      AddOnTitle,
      Image,
      addOnInStore,
      addOnWebsiteWidget,
      MinAddOns,
      MaxAddOns,
    } = this.state;
    var isFormValid = commonFunctions.onHandleFormSubmit(e, this);

    if (isFormValid) {
      if (MinAddOns || MaxAddOns ? MinAddOns >= MaxAddOns : null) {
        this.props.actions.showNotification(
          {
            title: "Warning",
            message: `Max Per Order item can not less then min per order.`,
            position: "br",
            autoDismiss: commonFunctions.notificationTime(),
          },
          "error"
        );
      } else {
        const items = fullState[stateForm].addOns;
        items[this.state.addOnsSelected] = {
          AddOnId: AddOnId,
          Price: Price,
          AddOnTitle: AddOnTitle,
          Image: Image,
          addOnInStore: addOnInStore,
          addOnWebsiteWidget: addOnWebsiteWidget,
          MinAddOns: MinAddOns,
          MaxAddOns: MaxAddOns,
        };
        allFunction.setState({ ...fullState });
        this.setState({
          Price: "",
          AddOnId: "",
          AddOnTitle: "",
          Image: "",
          addOnInStore: true,
          addOnWebsiteWidget: true,
          updatebuttonAddOns: false,
          MaxAddOns: "",
          MinAddOns: "",
        });
        this.validator = commonFunctions.initializeSimpleValidator();
        commonFunctions.onFormChange(this.props.allFunction);
      }
    }
    if (AddOnId === "") {
      allFunction.props.actions.showNotification(
        {
          title: "Warning",
          message: "Please Select any add Ons.",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    } else if (Price === "") {
      allFunction.props.actions.showNotification(
        {
          title: "Warning",
          message: "Please fill add Ons price.",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    }
  };

  deleteAddOns = (singleAddOns, index) => {
    const { allFunction, stateForm, fullState } = this.props;
    const arr = fullState[stateForm].addOns;
    arr.splice(index, 1);
    allFunction.setState(() => ({
      addItemGeneral: { ...fullState[stateForm], addOns: arr },
    }));
    this.setState({ updatebuttonAddOns: false });
    commonFunctions.onFormChange(this.props.allFunction);
  };

  onHandleAdddOnsPush = (e) => {
    const { allFunction, stateForm, fullState } = this.props;
    const {
      AddOnId,
      Price,
      AddOnTitle,
      Image,
      addOnInStore,
      addOnWebsiteWidget,
      MinAddOns,
      MaxAddOns,
    } = this.state;
    if (Price !== "" && AddOnId !== "") {
      if (MinAddOns === "0") {
        allFunction.props.actions.showNotification(
          {
            title: "Warning",
            message: "The min addons must be at least 1.",
            position: "br",
            autoDismiss: commonFunctions.notificationTime(),
          },
          "error"
        );
      } else if (MaxAddOns === "0") {
        allFunction.props.actions.showNotification(
          {
            title: "Warning",
            message: "The max addons must be at least 1.",
            position: "br",
            autoDismiss: commonFunctions.notificationTime(),
          },
          "error"
        );
      } else if (MinAddOns || MaxAddOns ? MinAddOns >= MaxAddOns : null) {
        this.props.actions.showNotification(
          {
            title: "Warning",
            message: `Max Per Order item can not less then min per order.`,
            position: "br",
            autoDismiss: commonFunctions.notificationTime(),
          },
          "error"
        );
      } else {
        fullState[stateForm].addOns.push({
          AddOnId: AddOnId,
          Price: Price,
          AddOnTitle: AddOnTitle,
          Image: Image,
          addOnInStore: addOnInStore,
          addOnWebsiteWidget: addOnWebsiteWidget,
          MinAddOns: MinAddOns,
          MaxAddOns: MaxAddOns,
        });
        allFunction.setState({ ...fullState });
        this.setState({
          Price: "",
          AddOnId: "",
          AddOnTitle: "",
          Image: "",
          addOnInStore: true,
          addOnWebsiteWidget: true,
          MinAddOns: "",
          MaxAddOns: "",
        });
        this.validator = commonFunctions.initializeSimpleValidator();
        commonFunctions.onFormChange(this.props.allFunction);
      }
    } else if (AddOnId === "") {
      allFunction.props.actions.showNotification(
        {
          title: "Warning",
          message: "Please Select any add Ons.",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    } else if (Price === "") {
      allFunction.props.actions.showNotification(
        {
          title: "Warning",
          message: "Please fill add Ons price.",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    } else {
      if (Price !== "" && AddOnId !== "") {
        fullState[stateForm].addOns.push({
          AddOnId: AddOnId,
          Price: Price,
          AddOnTitle: AddOnTitle,
          Image: Image,
          addOnInStore: addOnInStore,
          addOnWebsiteWidget: addOnWebsiteWidget,
          MinAddOns: MinAddOns,
          MaxAddOns: MaxAddOns,
        });
        allFunction.setState({ ...fullState });
        this.setState({
          Price: "",
          AddOnId: "",
          AddOnTitle: "",
          Image: "",
          addOnInStore: true,
          addOnWebsiteWidget: true,
          MinAddOns: "",
          MaxAddOns: "",
        });
        this.validator = commonFunctions.initializeSimpleValidator();
        commonFunctions.onFormChange(this.props.allFunction);
      }
    }
  };

  editAddOns = (selectedAddOns, index) => {
    this.setState({
      Price: selectedAddOns.Price,
      AddOnId: selectedAddOns.AddOnId,
      AddOnTitle: selectedAddOns.AddOnTitle,
      Image: selectedAddOns.Image,
      addOnsSelected: index,
      addOnInStore: selectedAddOns.addOnInStore,
      addOnWebsiteWidget: selectedAddOns.addOnWebsiteWidget,
      updatebuttonAddOns: true,
      MinAddOns: selectedAddOns.MinAddOns,
      MaxAddOns: selectedAddOns.MaxAddOns,
    });
  };

  onHandleAddOns = (e, { data, value, checked, type }) => {
    if (type === "checkbox") {
      this.setState({ [data]: checked });
    } else {
      this.setState({ [data]: value });
    }
  };

  onHandleAddonDropdown = (e, data) => {
    const { value } = data;
    const key = data.options.find((o) => o.value === value);
    this.setState({
      AddOnTitle: key.text,
      Image: key.Image,
      AddOnId: key.value,
      Price: key.Price,
      MaxAddOns: key.MaxAddOns,
      MinAddOns: key.MinAddOns,
    });
  };
  onHandleChangePrice = (e, { name, value, type, checked, data }) => {
    var ex = /^\d*\.?(?:\d{1,2})?$/;
    if (ex.test(value) == true) {
      this.setState({ Price: value });
      var allPrice = commonFunctions.onHandleChange(
        e,
        { name, value, type, checked, data },
        this.state.Price,
        this
      );
    }
  };
  onHandleMaxPerOrderChange = (e, { value, data }) => {
    const { MinAddOns } = this.state;
    var ex = /^(?:\d*\d{1,2}|\d+)$/;
    if (ex.test(value) === true) {
      if (data === "MaxAddOns") {
        this.setState({ MaxAddOns: value });
      }
    }
    if (ex.test(value) === false) {
      this.setState({ MaxAddOns: null });
    }
  };
  onHandleMinPerOrder = (e, { name, value, type, checked, data }) => {
    if (data === "MinAddOns") {
      var ex = /^(?:\d*\d{1,2}|\d+)$/;
      if (ex.test(value) === true) {
        this.setState({ MinAddOns: value });
      }
    }
    if (ex.test(value) === false) {
      this.setState({ MinAddOns: null });
    }
  };

  onHandleClearAddons = () => {
    this.setState({
      Price: "",
      AddOnId: "",
      AddOnTitle: "",
      Image: "",
      addOnInStore: true,
      addOnWebsiteWidget: true,
      updatebuttonAddOns: false,
      MinAddOns: "",
      MaxAddOns: "",
    });
  };

  render() {
    const { stateForm, fullState } = this.props;
    return (
      <React.Fragment>
        <Grid.Column width={16}>
          <Header as="h4">Optional Add Ons</Header>
        </Grid.Column>
        <Grid.Column width={16}>
          <Header className="perOrderAddons" as="h4">
            Per Order Min/Max
          </Header>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={5} computer={4}>
          <Form.Select
            className="custom-select"
            placeholder="Add Ons"
            options={this.state.addOnsList}
            value={this.state.AddOnId}
            type="select"
            onChange={this.onHandleAddonDropdown}
            search
            error={this.validator.message(
              "addOns",
              this.state.AddOnTitle,
              "required"
            )}
            fluid
          />
        </Grid.Column>
        <Grid.Column mobile={16} tablet={4} computer={5}>
          <div className="numberWithMaxMin">
            <Input
              label={{ basic: true, content: "$" }}
              type="number"
              labelPosition="left"
              data="Price"
              step="any"
              placeholder="Price"
              fluid
              name="Price"
              required
              value={this.state.Price}
              className="form-control"
              onChange={this.onHandleChangePrice}
            />
            <Form.Input
              placeholder="Min."
              data="MinAddOns"
              value={this.state.MinAddOns}
              onChange={this.onHandleMinPerOrder}
              className="qty"
              fluid
            />
            <Form.Input
              placeholder="Max."
              data="MaxAddOns"
              value={this.state.MaxAddOns}
              type="number"
              onChange={this.onHandleMaxPerOrderChange}
              className="qty"
              fluid
            />
          </div>
        </Grid.Column>
        <Grid.Column
          mobile={4}
          tablet={6}
          computer={4}
          verticalAlign="middle"
          textAlign="right"
        >
          {!this.state.updatebuttonAddOns ? (
            <Button className="orange-btn" onClick={this.onHandleAdddOnsPush}>
              Save
            </Button>
          ) : (
            <>
              <Button className="orange-btn" onClick={this.onHandleClearAddons}>
                Cancel
              </Button>
              <Button
                className="orange-btn"
                onClick={this.onHandleUpdatedOnsPush}
              >
                Update
              </Button>
            </>
          )}
        </Grid.Column>
        <Grid.Column mobile={4} tablet={5} computer={3} textAlign="right">
          <Button className="deepviolet-button" onClick={this.addOnsModal}>
            Create Add-Ons
          </Button>
        </Grid.Column>

        <Grid.Column mobile={4} tablet={4} computer={4} verticalAlign="middle">
          <Form.Checkbox
            label="In Store"
            className="custome-checkbox"
            data="addOnInStore"
            checked={this.state.addOnInStore ? true : false}
            onChange={this.onHandleAddOns}
          />
        </Grid.Column>
        <Grid.Column mobile={4} tablet={5} computer={4} verticalAlign="middle">
          <Form.Checkbox
            label="Website"
            className="custome-checkbox"
            data="addOnWebsiteWidget"
            checked={this.state.addOnWebsiteWidget ? true : false}
            onChange={this.onHandleAddOns}
          />
        </Grid.Column>

        <Grid.Column width={16}>
          <React.Fragment>
            {fullState[stateForm].addOns &&
              fullState[stateForm].addOns.length > 0 && (
              
                <Table fixed>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Image</Table.HeaderCell>
                      <Table.HeaderCell>Title</Table.HeaderCell>
                      <Table.HeaderCell textAlign="right">
                        Price
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="right">
                        MinAddOns
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="right">
                        MaxAddOns
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="right">
                        Action
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {fullState[stateForm].addOns &&
                      fullState[stateForm].addOns.map((singleAddOns, index) => {
                        let image;
                        if (singleAddOns.Image !== null) {
                          image =
                            singleAddOns.Image[0] !== "["
                              ? singleAddOns.Image
                              : JSON.parse(singleAddOns.Image);
                        }
                        return (
                          <Table.Row key={index}>
                            <Table.Cell>
                              <Image
                                src={commonFunctions.concatenateImageWithAPIUrl(
                                  image ? image[0].ImageFile : null
                                )}
                                size="mini"
                              />
                            </Table.Cell>
                            {/* <Table.Cell><Image src={commonFunctions.concatenateImageWithAPIUrl(image.ImageFile)} size="mini" /></Table.Cell> */}
                            <Table.Cell
                              title={singleAddOns.AddOnTitle}
                              className="textOverflowHidden"
                            >
                              {singleAddOns.AddOnTitle}
                            </Table.Cell>
                            <Table.Cell textAlign="right">
                              {commonFunctions.isNumberInteger(
                                singleAddOns.Price
                              )}
                            </Table.Cell>
                            <Table.Cell textAlign="right">
                              {singleAddOns.MinAddOns === 0
                                ? "-"
                                : singleAddOns.MinAddOns}
                            </Table.Cell>
                            <Table.Cell textAlign="right">
                              {singleAddOns.MaxAddOns === 0
                                ? "-"
                                : singleAddOns.MaxAddOns}
                            </Table.Cell>
                            <Table.Cell textAlign="right">
                              <Icon
                                name="pencil alternate"
                                title="Edit"
                                size="large"
                                className="deepviolet-color"
                                link
                                onClick={() =>
                                  this.editAddOns(singleAddOns, index)
                                }
                              />
                              <Icon
                                name="trash alternate"
                                title="Delete"
                                size="large"
                                className="orange-color"
                                link
                                onClick={() =>
                                  this.deleteAddOns(singleAddOns, index)
                                }
                              />
                            </Table.Cell>
                          </Table.Row>
                        );
                      })}
                  </Table.Body>
                </Table>
              )}
          </React.Fragment>
        </Grid.Column>
        {this.state.addOnsModalStatus && (
          <AddNewAddOnsModal
            openModal={this.state.addOnsModalStatus}
            closeModal={this.addOnsClosedModal}
          />
        )}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CommonAddOns);
