import React, { Component } from "react";
import { Image, Grid, Container, Header, Icon, Table } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { TeamMemberModal } from "../../shared/components"
import { connect, bindActionCreators, actions, Notifications, commonFunctions, } from "../../shared/functional/global-import";

class ClientPersonalDetail extends Component {
  get initialState() {
    return {
      teamMemberDetail: {
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId: this.props.global.locationSelected.BusinessLocationId,
        userId: this.props.match.params.userId,
        pageNo: 1,
        pageSize: 100,
      },
    }
  }
  constructor(props) {
    super(props);
    this.state = {
      ...this.initialState, teamMemberDetails: [], teamMemberData: [],
    };
  }

  componentDidMount = () => {
    const { teamMemberDetail } = this.state;
    if (this.props.userNotes) {
      teamMemberDetail.userId = this.props.userNotes.userId
      this.setState({ teamMemberDetail })
    }
    if (this.props.billingInfo) {
      teamMemberDetail.userId = this.props.billingInfo.UserId
      this.setState({ teamMemberDetail });
    }
    this.getTeamMemberDetails();
  }
  componentDidUpdate(prevProps, prevState) {
    
    if (prevProps.match.params.userId !== this.props.match.params.userId){
      this.getTeamMemberDetails();
    }
  }
  getTeamMemberDetails = () => {
    // const { teamMemberDetail } = this.state
    // if (this.props.editUser === true) {
    //   teamMemberDetail.userId = this.props.auth.editUser.keyId
    //   this.setState({ teamMemberDetail })
    // }
    this.props.actions.apiCall({
      urls: ["GETUSER"], method: "GET", data: {
        "businessId": this.props.global.bussinessDetail.businessId,
        "businessLocationId": this.props.global.locationSelected.BusinessLocationId,
        "userId": this.props.match.params.userId,
        "pageNo": 1,
        "pageSize": 100,
      }, onSuccess: (response) => {
        
        const businessLocations = JSON.parse(response[0].businessLocations)
        const locationName = businessLocations.map((x, i) =>
          (i === businessLocations.length - 1) ? x.BusinesslocationName : x.BusinesslocationName + ", "
        )
        this.setState({ teamMemberDetails: response, locationName });
        // response.businessLocations
      }
    });
  }

  TeamMemberModal = () => {
    this.setState({
      teamMemberStatus: !this.state.teamMemberStatus,
    });
  };

  EditMember = (e) => {
    this.setState({ teamMemberData: e });
    this.TeamMemberModal();
  }

  render() {
    const { teamMemberDetails, locationName } = this.state
    return (
      <Container fluid>
        <Grid>
          <Grid.Row>
            <Grid.Column textAlign="center">
              {teamMemberDetails.length === 0 && <div style={{ height: '400px' }}>
              </div>}
              {teamMemberDetails.map((teamMembers, index) => {
                let imageData = JSON.parse(teamMembers.image)
                imageData = imageData === null ? imageData : imageData[0].ImageFile
                return (
                  <>
                    <div key={index} className="cmn-shad profile-box">
                      <div icon textAlign="center" className="client-card">
                        <Image src={commonFunctions.concatenateImageWithAPIUrl(imageData)} centered size="tiny" className="mbt" />
                        <Header.Content className="orange-color">
                          <b>{teamMembers.firstName} {teamMembers.lastName}</b>   {this.props.showEdit && <Icon name="edit outline" onClick={() => this.EditMember(teamMembers)} className="deepviolet-color" />}
                        </Header.Content>
                        {/* <p className="orange-color">{teamMembers.roleName}</p> */}
                        <p className="orange-color">{teamMembers.customeSince}</p>
                        {/* <a className="deepviolet-color" title="Phone" href={`tel:${teamMembers.phoneNumber}`}><span class="iconify" data-icon="bx:bxs-phone" data-inline="false"></span></a> */}
                        {/* <a className="deepviolet-color" title="Mail" href={`mailto:${teamMembers.emailId}`} ><span class="iconify" data-icon="entypo:mail" data-inline="false"></span></a> */}
                      </div>
                      <div>
                        <Grid>
                          {teamMemberDetails.map((teamMembers) => <Grid.Column width={16}>
                            <Table>
                              <Table.Body>
                                <Table.Row>
                                  <Table.Cell>
                                    <p>Name</p>
                                  </Table.Cell>
                                  <Table.Cell textAlign="right" className="textOverflowHidden">
                                    <p><strong >{teamMembers.firstName}{" "}{teamMembers.lastName} </strong></p>
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell>
                                    <p>Email</p>
                                  </Table.Cell>
                                  <Table.Cell textAlign="right" className="textOverflowHidden">
                                    <p><strong title={teamMembers.emailAddressId}>{teamMembers.emailAddressId}</strong></p>
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell>
                                    <p>Phone</p>
                                  </Table.Cell>
                                  <Table.Cell textAlign="right">
                                    <p><strong>{teamMembers.contactNumber}</strong></p>
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell>
                                    <p>Designation</p>
                                  </Table.Cell>
                                  <Table.Cell textAlign="right" className="textOverflowHidden">
                                    <p><strong title={teamMembers.designation}>{teamMembers.designation}</strong></p>
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell>
                                    <p>Role</p>
                                  </Table.Cell>
                                  <Table.Cell textAlign="right">
                                    <p><strong>{teamMembers.roleName}</strong></p>
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell>
                                    <p>Locations</p>
                                  </Table.Cell>
                                  <Table.Cell className="textOverflowHidden">
                                    <p><strong title={locationName}>{locationName}</strong></p>
                                  </Table.Cell>
                                </Table.Row>
                              </Table.Body>
                            </Table>
                          </Grid.Column>
                          )}
                        </Grid>

                      </div>
                    </div>
                  </>
                )
              })}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <TeamMemberModal memberData={this.state.teamMemberData} openModal={(this.state.teamMemberStatus)} closeModal={this.TeamMemberModal} getTeamDetails={this.getTeamMemberDetails} />
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch)
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ClientPersonalDetail));

