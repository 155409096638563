import React, { Component } from "react";
import { connect, Link, commonFunctions, bindActionCreators, actions, withRouter, Notifications } from "../../../functional/global-import";
import { List, Image, Menu, Icon, } from "semantic-ui-react";
import BreakTimeModal from "./break-time";
import * as env from "../../../../config/env.config";
import { DashboardImg, DashboardImg2, InventoryImg1, InventoryImg2, OrderImg1, OrderImg2, CalendarImg1, CalendarImg2, UserImg1, UserImg2 } from "../../../functional/global-image-import";

class Sidebar extends Component {
  get initialState() {
    return {
    }
  }
  constructor(props) {
    super(props);
    this.state = {
      breakTypeData: [],
    };
  }

  BreakTimeModal = () => {
    this.props.actions.storeUserBreak(true);
  };

  componentDidMount = () => {
    let activeStatus = window.location.href.substring(window.location.href.lastIndexOf('/') + 1)
    this.setState({ activeItem: activeStatus });
  }

  handleItemClick = (e, { name }) => {
    const roleAndPermission = this.props.auth.userDetail.roleAndPermission
    let data = roleAndPermission?.filter((item) => item.Access && item.ModuleName?.toLowerCase() === name)
    if (data?.length > 0 && data[0].Access === 1) {
      this.props.history.push({
        pathname: `${env.PUBLIC_URL}/${data[0].ModuleName?.toLowerCase()}`
      });
      this.setState({ activeItem: name });
    }
    else if (name !== "profile") {
      roleAndPermission?.filter((item) => item.Access === 0 && item.ModuleName?.toLowerCase() === name)
      this.props.actions.showNotification(
        {
          title: "Security",
          message: "Page is not accessible ",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        }, "error");
    }

    let inventoryDetail = this.props.global.inventoryDetail
    let obj = {
      inventoryBreadcrumb: []
    }
    
    // inventoryDetail.inventoryBreadcrumb = [];
    this.props.actions.storeInventoryDetail(obj);
  };

  handleLogout = async () => {
    this.props.actions.apiCall({
      urls: ["LOGOUT"], method: "POST", data: {
        "userId": this.props.auth.userDetail.keyId,
        "businessLocationId": this.props.global.locationSelected.BusinessLocationId,
        "actionPerformedBy": this.props.auth.userDetail.emailId
      }, onSuccess: (response) => { }
    });
    this.props.actions.storeUserDetail();
  }

  render() {
    const { activeItem } = this.state;
    const { global, auth } = this.props;
                    
    return (
      <div className="left-sidebar">
        <div className="logo-menu">
          <List horizontal>
            <List.Item as={Link} to={`${env.PUBLIC_URL}/more-setting/general`} className="company-logo">
              <Image src={commonFunctions.concatenateImageWithAPIUrl(global.bussinessDetail.businessLogo)} title={global.bussinessDetail.businessName} size="tiny" centered />
            </List.Item>

            <List.Item as={Link}>
              <Icon name="bars" onClick={this.props.onToggle} size='large' />
            </List.Item>
          </List>
        </div>
        <div className="main-menu scrolling">
          <Menu vertical>
            <Menu.Item className="dashboard-list"
              name="dashboard" active={activeItem === "dashboard"} onClick={(e, { name }) => { this.props.onTabToggle(); this.handleItemClick(e, { name }); }}>
              {/* <Icon name="home"/> */}
              <Image src={DashboardImg} className="black-image" size="mini" />
              <Image src={DashboardImg2} className="orange-image" size="mini" />
              <span>Dashboard</span>
            </Menu.Item>
            <Menu.Item className="dashboard-list"
              // as={Link} to={`${env.PUBLIC_URL}/inventory`} 
              name="inventory" active={activeItem === "inventory"}
              onClick={(e, { name }) => {
                let inventoryDetail = this.props.global.inventoryDetail;
                this.props.onTabToggle();
                this.handleItemClick(e, { name });
                if (name === "inventory") {
                  inventoryDetail.categoryId = 0;
                  inventoryDetail.productId = 0;
                  inventoryDetail.businessCategoryId = 0;
                  inventoryDetail.inventoryBreadcrumb = [];
                  this.props.actions.storeInventoryDetail(inventoryDetail);
                }
              }}>
              {/* <Icon name="clipboard list" /> */}
              <Image src={InventoryImg1} className="black-image" size="mini" />
              <Image src={InventoryImg2} className="orange-image" size="mini" />
              <span> Inventory</span>
            </Menu.Item>

            <Menu.Item className="dashboard-list"
              // as={Link} to={`${env.PUBLIC_URL}/calender`} 
              name="calendar" active={activeItem === "calendar"}
              onClick={(e, { name }) => {
                this.props.onTabToggle();
                this.handleItemClick(e, { name });
              }}>
              <Image src={CalendarImg1} className="black-image" size="mini" />
              <Image src={CalendarImg2} className="orange-image" size="mini" />
              <span> Calendar</span>
            </Menu.Item>

            <Menu.Item
              // as={Link} to={`${env.PUBLIC_URL}/order`}
              className="dashboard-list" name="order" active={activeItem === "order"} onClick={(e, { name }) => { this.props.onTabToggle(); this.handleItemClick(e, { name }); }}>
              {/* <Icon name="first order"/> */}
              <Image src={OrderImg1} className="black-image" size="mini" />
              <Image src={OrderImg2} className="orange-image" size="mini" />
              <span> Orders</span>
            </Menu.Item>
            <Menu.Item
              //  as={Link} to={`${env.PUBLIC_URL}/customers-details`}
              className="dashboard-list" name="customers-details" active={activeItem === "customers-details"}
              onClick={(e, { name }) => {
                this.props.onTabToggle();
                this.handleItemClick(e, { name });
              }}
            >
              {/* <Icon name="user" />   */}
              <Image src={UserImg1} className="black-image" size="mini" />
              <Image src={UserImg2} className="orange-image" size="mini" />

              <span> Customers</span>
            </Menu.Item>
          </Menu>
        </div>
        <div className="profile-menu main-menu">
          <List selection verticalAlign="middle" className="profile-dropdown">
            <List.Item
              as={Link} to={`${env.PUBLIC_URL}/user-setting`}
              name="user-settings"
              onClick={() => { this.props.onTabToggle(); }}>
              <Icon name="user" />
              <List.Content>User Settings</List.Content>
            </List.Item>
            <List.Item onClick={() => this.BreakTimeModal()}>
              <Icon name="coffee" />
              <List.Content>Break Time</List.Content>
            </List.Item>
            <List.Item as={Link} to={`${env.PUBLIC_URL}/passcode`}
             onClick={() => this.handleLogout()}>
              <Icon name="user plus" />
              <List.Content>Switch User</List.Content>
            </List.Item>
            <List.Item as={Link} to={`${env.PUBLIC_URL}/passcode`} onClick={() => { this.props.onTabToggle(); this.handleLogout() }}>
              <Icon name="log out" />
              <List.Content>Log Out</List.Content>
            </List.Item>
          </List>
          <Menu vertical>
            <Menu.Item name="profile" onClick={this.handleItemClick}>
              <span>{this.props.auth.userDetail.userName}</span>
              <Image src={commonFunctions.concatenateImageWithAPIUrl(auth.userDetail.userImage)} size="tiny" verticalAlign="top" />
            </Menu.Item>
          </Menu>
        </div>
        <BreakTimeModal fullState={this.state} allFunction={this} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      logout: bindActionCreators(actions.logout, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
      storeUserDetail: bindActionCreators(actions.storeUserDetail, dispatch),
      storeUserBreak: bindActionCreators(actions.storeUserBreak, dispatch),
      storeInventoryDetail: bindActionCreators(actions.storeInventoryDetail, dispatch),
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar));
