import React, { Component } from "react";
import { Grid, Header, Form, Radio } from "semantic-ui-react";

class BookingConfirmation extends Component {
    state = {
        value: "one"
    }

    handleChange = (e, { value }) => this.setState({ value });

    render() {
        const { value } = this.state;
        return (
            <Grid>
                <Grid.Column width={16}>
                    <Header as="h4">Booking Confirmation</Header>
                </Grid.Column>

                <Grid.Column width={16} verticalAlign="middle">
                    <Form className="radio-button">
                        <Form.Group inline>
                            <Form.Field>
                                <Radio label="Auto Confirm" className="custom-radio-btn" value="one"
                                    checked={value === "one"} onChange={this.handleChange} />
                            </Form.Field>
                            <Form.Field>
                                <Radio label="Manual" className="custom-radio-btn" value="two"
                                    checked={value === "two"} onChange={this.handleChange} />
                            </Form.Field>
                        </Form.Group>
                    </Form>
                </Grid.Column>
            </Grid>
        )
    }
}
export default BookingConfirmation;