import { lazy } from "react";
import { env } from "../../shared/functional/global-import";

// Configure all routes of the application that contain authenticate template
const AuthenticateTemplateRoutesConfing = [
  {
    exact: true,
    private: true,
    moduleName: "dashboard",
    screenName: "dashboard",
    path: `${env.PUBLIC_URL}/dashboard`,
    component: lazy(() => import("../../pages/dashboard")),
  },
  {
    exact: true,
    private: true,
    path: `${env.PUBLIC_URL}/customers-details-sample`,
    component: lazy(() => import("../../pages/customer-details")),
  },

  {
    exact: true,
    private: true,
    moduleName: "customers-details",
    screenName: "customers-details",
    path: `${env.PUBLIC_URL}/customers-details`,
    component: lazy(() => import("../../pages/customer-details")),
  },
  {
    exact: true,
    private: true,
    moduleName: "customers-details",
    screenName: "customer-summary",
    path: `${env.PUBLIC_URL}/customer-summary`,
    component: lazy(() => import("../../pages/customer-summary")),
  },
  {
    exact: true,
    private: true,
    path: `${env.PUBLIC_URL}/item-status`,
    component: lazy(() => import("../../pages/item-status")),
  },
  {
    exact: true,
    private: true,
    moduleName: "order",
    screenName: "order-summary",
    path: `${env.PUBLIC_URL}/order-summary/:orderId`,
    component: lazy(() => import("../../pages/order-summary")),
  },
  {
    exact: true,
    private: true,
    moduleName: "order",
    screenName: "order-summary",
    path: `${env.PUBLIC_URL}/order-summary`,
    component: lazy(() => import("../../pages/order-summary")),
  },
  {
    exact: true,
    private: true,
    moduleName: "order",
    screenName: "order",
    path: `${env.PUBLIC_URL}/order`,
    component: lazy(() => import("../../pages/order")),
  },
  {
    exact: true,
    private: true,
    moduleName: "customers-details",
    screenName: "customer-summary",
    path: `${env.PUBLIC_URL}/customer-summary/:customerId`,
    component: lazy(() => import("../../pages/customer-summary")),
  },
  {
    exact: true,
    private: true,
    moduleName: "more-setting",
    screenName: "more-setting",
    path: `${env.PUBLIC_URL}/more-setting`,
    component: lazy(() => import("../../pages/more-setting")),
  },
  {
    exact: true,
    private: true,
    moduleName: "more-setting",
    screenName: "general",
    path: `${env.PUBLIC_URL}/more-setting/general`,
    component: lazy(() => import("../../pages/more-setting/general-setting")),
  },
  {
    exact: true,
    private: true,
    moduleName: "more-setting",
    screenName: "locations",
    path: `${env.PUBLIC_URL}/more-setting/locations`,
    component: lazy(() => import("../../pages/more-setting/location-setting")),
  },
  {
    exact: true,
    private: true,
    moduleName: "more-setting",
    screenName: "team-members",
    path: `${env.PUBLIC_URL}/more-setting/team-members`,
    component: lazy(() =>
      import("../../pages/more-setting/team-member-setting")
    ),
  },
  {
    exact: true,
    private: true,
    moduleName: "more-setting",
    screenName: "permissions",
    path: `${env.PUBLIC_URL}/more-setting/permissions`,
    component: lazy(() =>
      import("../../pages/more-setting/permissions-setting")
    ),
  },
  {
    exact: true,
    private: true,
    moduleName: "more-setting",
    screenName: "bank&billing",
    path: `${env.PUBLIC_URL}/more-setting/bank&billing`,
    component: lazy(() =>
      import("../../pages/more-setting/bank-billing-setting")
    ),
  },
  {
    exact: true,
    private: true,
    moduleName: "more-setting",
    screenName: "pricing&taxes",
    path: `${env.PUBLIC_URL}/more-setting/pricing&taxes`,
    component: lazy(() =>
      import("../../pages/more-setting/pricing-taxes-setting")
    ),
  },
  {
    exact: true,
    private: true,
    moduleName: "more-setting",
    screenName: "documents",
    path: `${env.PUBLIC_URL}/more-setting/documents`,
    component: lazy(() => import("../../pages/more-setting/document-setting")),
  },
  {
    exact: true,
    private: true,
    moduleName: "more-setting",
    screenName: "analytics",
    path: `${env.PUBLIC_URL}/more-setting/analytics`,
    component: lazy(() => import("../../pages/more-setting/analytics-setting")),
  },
  {
    exact: true,
    private: true,
    moduleName: "more-setting",
    screenName: "email",
    path: `${env.PUBLIC_URL}/more-setting/email`,
    component: lazy(() => import("../../pages/more-setting/email-setting")),
  },
  {
    exact: true,
    private: true,
    moduleName: "more-setting",
    screenName: "transactions",
    path: `${env.PUBLIC_URL}/more-setting/transactions`,
    component: lazy(() =>
      import("../../pages/more-setting/transaction-setting")
    ),
  },
  {
    exact: true,
    private: true,
    moduleName: "more-setting",
    screenName: "time-frame",
    path: `${env.PUBLIC_URL}/more-setting/time-frame`,
    component: lazy(() =>
      import("../../pages/more-setting/time-frame-setting")
    ),
  },
  {
    exact: true,
    private: true,
    moduleName: "more-setting",
    screenName: "discount&coupons",
    path: `${env.PUBLIC_URL}/more-setting/discount&coupons`,
    component: lazy(() =>
      import("../../pages/more-setting/discount-coupon-setting")
    ),
  },
  {
    exact: true,
    private: true,
    moduleName: "more-setting",
    screenName: "add-ons",
    path: `${env.PUBLIC_URL}/more-setting/add-ons`,
    component: lazy(() => import("../../pages/more-setting/add-ons-setting")),
  },
  {
    exact: true,
    private: true,
    moduleName: "more-setting",
    screenName: "fee",
    path: `${env.PUBLIC_URL}/more-setting/fee`,
    component: lazy(() => import("../../pages/more-setting/fee-setting")),
  },
  {
    exact: true,
    private: true,
    moduleName: "more-setting",
    screenName: "break-type",
    path: `${env.PUBLIC_URL}/more-setting/break-type`,
    component: lazy(() =>
      import("../../pages/more-setting/break-type-setting")
    ),
  },
  {
    exact: true,
    private: true,
    moduleName: "more-setting",
    screenName: "requirements",
    path: `${env.PUBLIC_URL}/more-setting/requirements`,
    component: lazy(() =>
      import("../../pages/more-setting/requirement-setting")
    ),
  },
  {
    exact: true,
    private: true,
    moduleName: "more-setting",
    screenName: "payment-type",
    path: `${env.PUBLIC_URL}/more-setting/payment-type`,
    component: lazy(() =>
      import("../../pages/more-setting/payment-type-setting")
    ),
  },
  {
    exact: true,
    private: true,
    moduleName: "more-setting",
    screenName: "integration",
    path: `${env.PUBLIC_URL}/more-setting/integration`,
    component: lazy(() =>
      import("../../pages/more-setting/integration-setting")
    ),
  },
  {
    exact: true,
    private: true,
    moduleName: "more-setting",
    screenName: "new-order",
    path: `${env.PUBLIC_URL}/more-setting/new-order`,
    component: lazy(() => import("../../pages/more-setting/new-order")),
  },
  {
    exact: true,
    private: true,
    moduleName: "more-setting",
    screenName: "blackoutdates",
    path: `${env.PUBLIC_URL}/more-setting/blackoutdates`,
    component: lazy(() =>
      import("../../pages/more-setting/black-out-dates-setting")
    ),
  },
  {
    exact: true,
    private: true,
    moduleName: "more-setting",
    screenName: "help-center",
    path: `${env.PUBLIC_URL}/more-setting/help-center`,
    component: lazy(() => import("../../pages/more-setting/help-center-email")),
  },
  {
    exact: true,
    private: true,
    moduleName: "more-setting",
    screenName: "tags",
    path: `${env.PUBLIC_URL}/more-setting/tags`,
    component: lazy(() => import("../../pages/more-setting/tag-setting")),
  },
  {
    exact: true,
    private: true,
    moduleName: "more-setting",
    screenName: "tips",
    path: `${env.PUBLIC_URL}/more-setting/tips`,
    component: lazy(() => import("../../pages/more-setting/tip-amount-setting")),
  },
  {
    exact: true,
    private: true,
    moduleName: "dashboard",
    screenName: "user-setting",
    path: `${env.PUBLIC_URL}/user-setting`,
    component: lazy(() => import("../../pages/user-setting")),
  },
  {
    exact: true,
    private: true,
    moduleName: "calendar",
    screenName: "calendar",
    path: `${env.PUBLIC_URL}/calendar`,
    component: lazy(() => import("../../pages/full-calendar")),
  },
  {
    exact: true,
    private: true,
    moduleName: "inventory",
    screenName: "inventory",
    // path:"localhost:3000/RehntitBoatServices/inventory(/:name)",
    path: `${env.PUBLIC_URL}/add-category/inventory`,
    component: lazy(() => import("../../pages/inventory")),
  },
  {
    exact: true,
    private: true,
    moduleName: "inventory",
    screenName: "inventory",
    path: `${env.PUBLIC_URL}/inventory`,
    component: lazy(() => import("../../pages/inventory")),
  },
  {
    exact: true,
    private: true,
    moduleName: "inventory",
    screenName: "category-view",
    path: `${env.PUBLIC_URL}/inventory/category-view`,
    component: lazy(() =>
      import("../../pages/inventory/inventory-rental/rental-category-view")
    ),
  },
  {
    exact: true,
    private: true,
    moduleName: "dashboard",
    screenName: "activity-view-all",
    path: `${env.PUBLIC_URL}/activity-view-all`,
    component: lazy(() => import("../../pages/activity-view-all")),
  },
  {
    exact: true,
    private: true,
    moduleName: "inventory",
    screenName: "inventory-item-list",
    path: `${env.PUBLIC_URL}/inventory/inventory-item-list`,
    component: lazy(() => import("../../pages/inventory/inventory-inner-tab")),
  },
  // {
  //   exact: true,
  //   private: true,
  //   moduleName: "inventory",
  //   screenName: "inventory-item-list",
  //   // moduleName: "order",
  //   // screenName: "item-list",
  //   path: `${env.PUBLIC_URL}/item-list/:productId`,
  //   component: lazy(() => import('../../pages/inventory/inventory-inner-tab'))
  // },
  {
    exact: true,
    private: true,
    moduleName: "inventory",
    screenName: "inventory-orders",
    path: `${env.PUBLIC_URL}/inventory/inventory-orders`,
    component: lazy(() => import("../../pages/inventory/inventory-inner-tab")),
  },
  // {
  //   exact: true,
  //   private: true,
  //   path: `${env.PUBLIC_URL}/road-bikes`,
  //   component: lazy(() => import('../../shared/components/organisms/modal/new-order-modal/common/order-all-bike-type'))

  // },
  {
    exact: true,
    private: true,
    moduleName: "order",
    screenName: "activity-manifest",
    path: `${env.PUBLIC_URL}/activity-manifest`,
    component: lazy(() => import("../../pages/order/activity-manifest")),
  },
  {
    exact: true,
    private: true,
    path: `${env.PUBLIC_URL}/item-detail/:productDetailId :productId :businessId :businessLocationId`,
    component: lazy(() => import("../../pages/item-detail")),
  },
  {
    exact: true,
    private: true,
    moduleName: "more-setting",
    screenName: "google-calendar",
    path: `${env.PUBLIC_URL}/more-setting/google-calendar`,
    component: lazy(() => import("../../pages/more-setting/google-calendar")),
  },
  {
    exact: true,
    private: true,
    moduleName: "more-setting",
    screenName: "Team-member-profile",
    path: `${env.PUBLIC_URL}/team-member-profile/:userId`,
    component: lazy(() => import("../../pages/client-summary")),
  },
];

export default AuthenticateTemplateRoutesConfing;
