import React, { Component } from "react";
import { Grid, Form, Divider, Header, Button, Table, Icon } from "semantic-ui-react";
import { commonFunctions, connect, bindActionCreators, actions, Notifications } from "../../../functional/global-import";
import CustomerQuestions from "../../organisms/create-customer-questions";
import { AssociateAddOnsModal } from '../../../components';

const optionValue = [{ "CustomQuestionOptionValue": "Yes" }, { "CustomQuestionOptionValue": "No" }]
class CommonCustomerQuestions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customerQuestions: [],
            cutomerQuestionsStatus: false,
            customQuestionType: "",
            customQuestion: "",
            updateQuestion: false,
            questionSelected: "",
            addonsquestionStatus: false,
            customQuestionId: "",
            required: true,
            questionInStore: true,
            questionWebsiteWidget: true,
            questionTypeId: "",
            customQuestionOptionValue: [],
            updatebuttonAddOns: false,
            Price: "",
            addOnId: "",
            AddOnTitle: "",
            Image: "",
            addOns: [],
            addOnsModalStatus: false,
            addOnsSelected: "",
            addOnsList: [],
            addOnsSave: [],

            productcustomquestionId: null
        }
        this.validator = commonFunctions.initializeSimpleValidator();
    }

    customerQuestionsModal = () => {
        this.setState({
            cutomerQuestionsStatus: !this.state.cutomerQuestionsStatus,
        });
        // this.getcustomerQuestion()
    }

    addOnsQuestionModal = () => {
        this.setState({
            addonsquestionStatus: !this.state.addonsquestionStatus,
            //  addOnss : this.state.addOnsSaves 
        });
    };

    getcustomerQuestion = (value) => {

        this.props.actions.apiCall({
            urls: ["GETCUSTOMQUESTION"],
            method: "GET",
            data: {
                businessId: this.props.global.bussinessDetail.businessId,
                businessLocationId: this.props.global.locationSelected.BusinessLocationId,
                PageNo: 1, PageSize: 100, customQuestionId: -1, customQuestionType: value
            },
            onSuccess: (response) => {
                const customerQuestion = response.map((singleQuestion) => {
                    return {
                        value: singleQuestion.customQuestionId, text: singleQuestion.customQuestion,
                        customQuestionOptionValue: JSON.parse(singleQuestion.customQuestionOptionValue)
                    }
                })
                this.setState({ customerQuestions: customerQuestion })
            },
        });
    }

    onHandleQuestionType = (e, dropDownData) => {
        this.setState({ customQuestionId: "", customQuestion: "", customQuestionOptionValue: [], addOnsSaves: [] })
        const { value } = dropDownData;
        const key = dropDownData.options.find(o => o.value === value);
        this.setState({ customQuestionType: key.text, questionTypeId: key.value })
        this.getcustomerQuestion(key.value);
    }

    onHandleCustomerQuestion = (e, data) => {
        const { value } = data;
        const key = data.options.find(o => o.value === value);
        this.setState({ customQuestionId: key.value, customQuestion: key.text, customQuestionOptionValue: key.customQuestionOptionValue, customQuestionOptionId: key.customQuestionOptionId });
    }

    addCustomerQuestions = (e) => {
        var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
        const singleChoiceAddOnId = (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "CustomQuestionType", "Single Choice AddOns (Yes/No)"))?.globalCodeId



        if (isFormValid) {
            const addOnsDetail = this.state.questionTypeId === singleChoiceAddOnId && this.state.addOns.map((x) => {
                return {
                    addOnId: x.addOnId,
                    Price: x.Price,
                    AddOnTitle: x.AddOnTitle,
                    Image: x.Image
                }
            })
            const { fullState, allFunction, stateForm, variableName } = this.props;
            const { customQuestionType, customQuestion, customQuestionId, required, questionInStore, questionWebsiteWidget, questionTypeId, customQuestionOptionValue, customQuestionOptionId } = this.state;
            if (questionTypeId === singleChoiceAddOnId && this.state.addOns.length === 0) {
                this.props.actions.showNotification({
                    title: "Warning",
                    message: 'Please select atleast one Associate Add On', position: 'br', autoDismiss: commonFunctions.notificationTime()
                }, "error");
            }
            else {
                const rows = fullState[stateForm][variableName];
                rows.push({
                    customQuestionType: customQuestionType, customQuestion: customQuestion, customQuestionId: customQuestionId, required: required, questionInStore: questionInStore, questionWebsiteWidget: questionWebsiteWidget, questionTypeId: questionTypeId,
                    customQuestionOptionId: customQuestionOptionId,
                    customQuestionOptionValue: questionTypeId === singleChoiceAddOnId ? optionValue : customQuestionOptionValue,
                    associateAddOn: addOnsDetail === false ? [] : addOnsDetail
                });
                allFunction.setState({ ...fullState });
                this.onHandleClearQuestions();
                this.validator = commonFunctions.initializeSimpleValidator();
                commonFunctions.onFormChange(this);
            }
        }
    }

    updateCustomerQuestions = (e) => {

        const { fullState, allFunction, stateForm, variableName } = this.props;
        var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
        const singleChoiceAddOnId = (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "CustomQuestionType", "Single Choice AddOns (Yes/No)"))?.globalCodeId
        if (isFormValid) {
            const addOnsDetail = this.state.addOns?.map((x) => {
                return {
                    addOnId: x.addOnId,
                    Price: x.Price,
                    AddOnTitle: x.AddOnTitle,
                    Image: x.Image
                }
            });
            const { customQuestionType, questionTypeId, customQuestion, customQuestionId, required, questionInStore, questionWebsiteWidget,
                customQuestionOptionValue, productcustomquestionId } = this.state;
            const items = fullState[stateForm][variableName];
            items[this.state.questionSelected] = {
                customQuestionType: customQuestionType, customQuestion: customQuestion, customQuestionId: customQuestionId,
                customQuestion: customQuestion, required: required, questionInStore: questionInStore, questionWebsiteWidget: questionWebsiteWidget,
                questionTypeId: questionTypeId, associateAddOn: addOnsDetail === false ? [] : addOnsDetail,
                // customQuestionOptionId: 210,
                customQuestionOptionValue: questionTypeId === singleChoiceAddOnId ? optionValue : customQuestionOptionValue,
                productcustomquestionId: productcustomquestionId
            }
        }
        if (fullState.updateRentalGeneral) {
            const addOnsSave = this.state.addOnsSaves
            const { customQuestionType, questionTypeId, customQuestion, customQuestionId, required, questionInStore, questionWebsiteWidget, customQuestionOptionValue, customQuestionOptionId, productcustomquestionId } = this.state;
            const items = fullState[stateForm][variableName];
            items[this.state.questionSelected] = {
                customQuestionType: customQuestionType, customQuestionId: customQuestionId, customQuestion: customQuestion, required: required, questionInStore: questionInStore, questionWebsiteWidget: questionWebsiteWidget, questionTypeId: questionTypeId, associateAddOn: addOnsSave,
                //  customQuestionOptionId: 210,
                customQuestionOptionValue: customQuestionOptionValue,
                productcustomquestionId: productcustomquestionId
            }
        }
        allFunction.setState({ ...fullState });
        this.onHandleClearQuestions();
        commonFunctions.onFormChange(this);
        this.validator = commonFunctions.initializeSimpleValidator();
    }

    editCustomerQuestion = (data, i) => {

        this.setState({
            customQuestionType: data.customQuestionType, customQuestion: data.customQuestion, updateQuestion: true, questionSelected: i, customQuestionId: data.customQuestionId, required: data.required,
            questionInStore: data.questionInStore,
            questionWebsiteWidget: data.questionWebsitewidget === undefined ? data.questionWebsiteWidget : data.questionWebsitewidget,
            questionTypeId: data.questionTypeId, customQuestionOptionValue: data.customQuestionOptionValue,
            addOns: data.associateAddOn, addOnsSaves: data.associateAddOn, productcustomquestionId: data.productcustomquestionId
        });
        this.getcustomerQuestion(data.questionTypeId)
        if (data.associateAddOn) {
            this.editAddOns(data.associateAddOn)
        }
    }

    onHandleClearQuestions = () => {
        this.setState({
            customQuestionType: "", customQuestion: "", updateQuestion: false, questionSelected: "", customQuestionId: "", required: true,
            questionInStore: true, questionWebsiteWidget: true, questionTypeId: "", customQuestionOptionValue: [], addOns: []
        });
    }

    deleteCustomerQuestion = (i) => {
        const { fullState, allFunction, stateForm, variableName } = this.props;
        const rows = fullState[stateForm][variableName];
        rows.splice(i, 1)
        allFunction.setState({ ...fullState });
        this.setState({ updateQuestion: false, addOns: [], addOnsSave: [] });
        commonFunctions.onFormChange(this);
    }

    onHandleChange = (e, { checked, data }) => {
        this.setState({ [data]: checked });
    };

    // FOR ADD ADDONS 

    onHandleUpdatedOnsPush = (e) => {
        const { fullState } = this.props;
        const { addOnId, Price, AddOnTitle, Image, addOnInStore, addOnWebsiteWidget } = this.state;
        var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
        if (isFormValid) {
            const items = this.state.addOns;
            items[this.state.addOnsSelected] = { "addOnId": addOnId, "Price": Price, "AddOnTitle": AddOnTitle, "Image": Image, "addOnInStore": addOnInStore, "addOnWebsiteWidget": addOnWebsiteWidget }
            this.setState({ Price: "", addOnId: "", AddOnTitle: "", Image: "", addOnInStore: true, addOnWebsiteWidget: false, updatebuttonAddOns: false });
            this.validator = commonFunctions.initializeSimpleValidator();
        }
        if (fullState.updateRentalGeneral) {
            const items = this.state.addOnsSave;
            items[this.state.addOnsSelected] = { "addOnId": addOnId, "Price": Price, "AddOnTitle": AddOnTitle, "Image": Image, "addOnInStore": addOnInStore, "addOnWebsiteWidget": addOnWebsiteWidget }
            this.setState({ Price: "", addOnId: "", AddOnTitle: "", Image: "", addOnInStore: true, addOnWebsiteWidget: false, updatebuttonAddOns: false });
            this.validator = commonFunctions.initializeSimpleValidator();
        }
    }

    deleteAddOns = (singleAddOns, index) => {
        const { fullState } = this.props;
        const { addOnsSaves, addOns } = this.state;
        if (addOnsSaves && fullState.updateRentalGeneral) {
            const arr = addOnsSaves;
            arr.splice(index, 1);
            this.setState({ addOnsSave: arr, updatebuttonAddOns: false })
        }
        else {
            const arr = addOns;
            arr.splice(index, 1);
            this.setState({ addOns: arr, updatebuttonAddOns: false })
        }
    }

    onHandleAdddOnsPush = (e) => {
        const { fullState } = this.props;
        const { addOnId, Price, AddOnTitle, Image, addOns, addOnInStore, addOnWebsiteWidget } = this.state;
        if (Price !== "" && addOnId !== "") {
            addOns.push({ "addOnId": addOnId, "Price": Price, "AddOnTitle": AddOnTitle, "Image": Image, addOnInStore: addOnInStore, addOnWebsiteWidget: addOnWebsiteWidget });
            this.setState({ Price: "", addOnId: "", AddOnTitle: "", Image: "", addOnInStore: true, addOnWebsiteWidget: false });
            this.validator = commonFunctions.initializeSimpleValidator();
        }
        if (fullState.updateRentalGeneral) {
            const addOnsSave = [...this.state.addOnsSave]
            addOnsSave.push({ "addOnId": addOnId, "Price": Price, "AddOnTitle": AddOnTitle, "Image": Image, addOnInStore: addOnInStore, addOnWebsiteWidget: addOnWebsiteWidget });
            this.setState({ addOnsSave });
            this.setState({ Price: "", addOnId: "", AddOnTitle: "", Image: "", addOnInStore: true, addOnWebsiteWidget: false });
        }
    }

    editAddOns = (selectedAddOns, index, click) => {
        this.setState({
            Price: selectedAddOns.Price, addOnId: selectedAddOns.addOnId, AddOnTitle: selectedAddOns.AddOnTitle, Image: selectedAddOns.Image, addOnsSelected: index,
            addOnInStore: selectedAddOns.addOnInStore, addOnWebsiteWidget: selectedAddOns.addOnWebsiteWidget, updatebuttonAddOns: click
        });
    }

    onHandleAddonDropdown = (e, data) => {
        const { value } = data;
        const key = data.options.find(o => o.value === value);
        this.setState({ AddOnTitle: key.text, Image: key.image, addOnId: key.value, Price: key.Price });
    }

    onHandleChangePrice = (e, { name, value, type, checked, data }) => {
        var ex = /^\d*\.?(?:\d{1,2})?$/;
        if (ex.test(value) == true) {
            this.setState({ Price: value });
            var allPrice = commonFunctions.onHandleChange(e, { name, value, type, checked, data },
                this.state.Price, this);
        }
    }

    onHandleClearAddons = (closeModal) => {
        this.setState({
            Price: "", addOnId: "", AddOnTitle: "", Image: "",
            addOnInStore: true, addOnWebsiteWidget: false, updatebuttonAddOns: false
        });
        closeModal();
    }

    onHandleAddonsField = (closeModal, bb) => {
        const { fullState } = this.props;
        const addOnsSaves = [...this.state.addOns]
        this.setState({ addOnsSaves })
        closeModal();
    }

    render() {
        const { fullState, stateForm, variableName } = this.props;
        const { questionTypeId, customQuestionOptionValue } = this.state;
        let globalCodesCustomerQuestion = this.props.global.codes.filter(code => code.categoryName === "CustomQuestionType").map((filtercode) => {
            return { key: filtercode.codeName, value: filtercode.globalCodeId, text: filtercode.codeName }
        });
        const singleChoiceAddOnId = (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "CustomQuestionType", "Single Choice AddOns (Yes/No)"))?.globalCodeId
        const singleChoiceQuestionId = (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "CustomQuestionType", "Single Choice"))?.globalCodeId


        const multipleChoiceQuestionId = (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "CustomQuestionType", "Multiple Choice"))?.globalCodeId

        return (<React.Fragment>
            <Grid.Column width={16}>
                <Divider />
            </Grid.Column>
            <Grid.Column width={16}>
                <Header as="h4">Create Customer Questions</Header>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={6} computer={6}>
                <Form.Select
                    options={globalCodesCustomerQuestion} value={this.state.questionTypeId}
                    data="customQuestionType" type="select" placeholder="Choose Field Type"
                    onChange={this.onHandleQuestionType}
                    className="custom-select" fluid error={this.validator.message("customQuestionType",
                        this.state.customQuestionType, "required")} />
            </Grid.Column>

            <Grid.Column mobile={16} tablet={6} computer={6}>
                <Form.Select
                    placeholder="Question Name" className="custom-select"
                    search value={this.state.customQuestionId}
                    options={this.state.customerQuestions} fluid
                    onChange={this.onHandleCustomerQuestion}
                    type="select" error={this.validator.message("Question", this.state.customQuestionId, "required")} />
            </Grid.Column>

            <Grid.Column mobile={16} tablet={8} computer={4}>
                <span onClick={this.customerQuestionsModal} className="orange-color"> Manage Customer Questions </span>
            </Grid.Column>

            {questionTypeId === singleChoiceAddOnId && (
                <React.Fragment>
                    <Grid.Column width={1} verticalAlign="middle">
                        <Form.Radio className="custom-Form.Radio-btn" disabled />
                    </Grid.Column>

                    <Grid.Column width={10}>
                        <Form.Input fluid placeholder="Yes" disabled />
                    </Grid.Column>
                    <Grid.Column width={5} verticalAlign="middle">
                        {this.state.addOnsSave.length > 0 ?
                            <span className="orange-color" onClick={this.addOnsQuestionModal}>
                                Associated Add Ons :
                            </span> :
                            <span className="orange-color" onClick={this.addOnsQuestionModal}>
                                Associate Add Ons
                            </span>}
                        <>
                            {this.state.addOnsSaves && this.state.addOnsSaves.map((addOn) => {
                                return (
                                    <span> {addOn.AddOnTitle}, </span>
                                )
                            })}
                        </>
                    </Grid.Column>
                    <Grid.Column width={1} verticalAlign="middle">
                        <Form.Radio className="custom-Form.Radio-btn" disabled />
                    </Grid.Column>

                    <Grid.Column width={10}>
                        <Form.Input fluid placeholder="No" disabled />
                    </Grid.Column>
                    <Grid.Column width={5}></Grid.Column>
                </React.Fragment>
            )}

            {questionTypeId === singleChoiceQuestionId && customQuestionOptionValue.map((questionOption, i) => {

                return (<><Grid.Column mobile={16} tablet={16} computer={16}>
                    <Grid>
                        <Grid.Column computer={1} verticalAlign="middle">
                            <Form.Radio className="custom-Form.Radio-btn" disabled />
                        </Grid.Column>
                        <Grid.Column computer={7}>
                            <Form.Input fluid placeholder="Label" id={i} disabled value={questionOption.CustomQuestionOptionValue} />
                        </Grid.Column>
                        <Grid.Column computer={8} ><Divider hidden></Divider> </Grid.Column>
                    </Grid>
                </Grid.Column>
                </>)
            })
            }

            {questionTypeId === multipleChoiceQuestionId && customQuestionOptionValue.map((questionOption, i) => {

                return (<><Grid.Column mobile={16} tablet={16} computer={16}>
                    <Grid>
                        <Grid.Column computer={1} verticalAlign="middle">
                            <Form.Checkbox className="custome-Form.Checkbox" disabled />
                        </Grid.Column>
                        <Grid.Column computer={7}>
                            <Form.Input fluid placeholder="Label" id={i} disabled value={questionOption.CustomQuestionOptionValue} />
                        </Grid.Column>
                        <Grid.Column computer={8} ><Divider hidden></Divider> </Grid.Column>
                    </Grid>
                </Grid.Column>
                </>)
            })
            }
            <Grid.Column mobile={16} tablet={4} computer={4}>
                <Form.Checkbox label="Required" data="required" className="custome-checkbox" onChange={this.onHandleChange} checked={this.state.required ? true : false} />
            </Grid.Column>

            <Grid.Column mobile={8} tablet={4} computer={4} verticalAlign="middle">
                <Form.Checkbox label="In Store" className="custome-checkbox" data="questionInStore" onChange={this.onHandleChange} checked={this.state.questionInStore ? true : false} />
            </Grid.Column>

            <Grid.Column mobile={8} tablet={8} computer={4} verticalAlign="middle">
                <Form.Checkbox label="Website" className="custome-checkbox" data="questionWebsiteWidget" onChange={this.onHandleChange} checked={this.state.questionWebsiteWidget ? true : false} />
            </Grid.Column>

            <Grid.Column mobile={16} tablet={8} computer={16} textAlign="right">
                {!this.state.updateQuestion ? <Button className="orange-btn" onClick={this.addCustomerQuestions} >Add</Button> : <> <Button className="orange-btn" onClick={this.onHandleClearQuestions} >Cancel</Button>
                    <Button className="orange-btn" onClick={this.updateCustomerQuestions} >Update</Button>
                </>
                }
            </Grid.Column>



            <Grid.Column width={16}>
                {fullState[stateForm][variableName].length > 0 &&
                    <Table fixed celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell> Question Type </Table.HeaderCell>
                                <Table.HeaderCell> Question </Table.HeaderCell>
                                <Table.HeaderCell> Labels </Table.HeaderCell>
                                <Table.HeaderCell textAlign="right"> Action </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {fullState[stateForm][variableName] && fullState[stateForm][variableName].length > 0
                                && fullState[stateForm][variableName].map((singleQuestion, i) => {
                                    return (
                                        <Table.Row>
                                            <Table.Cell title={singleQuestion.customQuestionType} className="textOverflowHidden">
                                                {singleQuestion.customQuestionType}</Table.Cell>
                                            <Table.Cell title={singleQuestion.customQuestion} className="textOverflowHidden">
                                                {singleQuestion.customQuestion}</Table.Cell>
                                            <Table.Cell>
                                                {singleQuestion.associateAddOn && singleQuestion.questionTypeId === singleChoiceAddOnId &&
                                                    <>
                                                        <p>Yes :</p>
                                                        {singleQuestion.associateAddOn && singleQuestion.associateAddOn.map((singleQuestions, i) => {
                                                            return (
                                                                <>
                                                                    ({singleQuestions.AddOnTitle}),
                                                                </>
                                                            )
                                                        })}
                                                        <Divider></Divider>No
                                                    </>}
                                                {singleQuestion.customQuestionOptionValue && singleQuestion.questionTypeId === singleChoiceQuestionId
                                                    && singleQuestion.customQuestionOptionValue.map((data, i) => {
                                                                  
                                                        return (
                                                            <>
                                                                <Form.Input fluid placeholder="Label" id={i} disabled value={data.CustomQuestionOptionValue} />
                                                                {/* singleQuestions.CustomQuestionOptionValue */}
                                                            </>
                                                        )
                                                    })}
                                                {singleQuestion.customQuestionOptionValue && singleQuestion.questionTypeId === multipleChoiceQuestionId
                                                    && singleQuestion.customQuestionOptionValue.map((data, i) => {

                                                        return (
                                                            <>
                                                                <Form.Input fluid placeholder="Label" id={i} disabled value={data.CustomQuestionOptionValue} />
                                                                {/* singleQuestions.CustomQuestionOptionValue */}
                                                            </>
                                                        )
                                                    })}
                                            </Table.Cell>

                                            <Table.Cell textAlign="right">
                                                <Icon name="pencil alternate" size="large" title="Edit" className="deepviolet-color" link onClick={() => this.editCustomerQuestion(singleQuestion, i)} />
                                                <Icon name="trash alternate" size="large" title="Delete" className="orange-color" link onClick={() => this.deleteCustomerQuestion(i)} />
                                            </Table.Cell>
                                        </Table.Row>)
                                })}
                        </Table.Body>
                    </Table>
                }
            </Grid.Column>
            {(this.state.cutomerQuestionsStatus) &&
                <CustomerQuestions openModal={this.state.cutomerQuestionsStatus} closeModal={this.customerQuestionsModal} allFunction={this} customerQuestions={this.state.customerQuestions} />}
            {(this.state.addonsquestionStatus) &&
                <AssociateAddOnsModal openModal={this.state.addonsquestionStatus} closeModal={this.addOnsQuestionModal}
                    addOns={this.state.addOnsSaves}
                    updateRentalGeneral={fullState.updateRentalGeneral}
                    fullState={this.state} allFunction={this} />
            }
        </React.Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        api: state.api,
        global: state.global,
        auth: state.auth,
        warnUserState: state.warnUserState
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            apiCall: bindActionCreators(actions.apiCall, dispatch),
            storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
            showNotification: bindActionCreators(Notifications.show, dispatch),
            storeWarnUser: bindActionCreators(actions.storeWarnUser, dispatch)
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CommonCustomerQuestions);