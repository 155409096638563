import React, { Component } from 'react'
import { Form, Icon,  Grid, Header, Button } from "semantic-ui-react";

class BundleCount extends Component {
    state = {
        count: this.props.bundleEdit.bundleEdit ? this.props.bundleEdit.bundleItem.NumberOfBookings : 0,
    }
    countHandleChangeMinus = () => {
        const { count } = this.state;
        if (count >= 1) {
            this.setState({ count: count - 1 });
        }
    };

    countHandleChange = () => {
        const { count } = this.state;
        this.setState({ count: count + 1 });
    };

    oncountChange = (e, { value }) => {
        this.setState({ count: value === "" ? 0 : parseInt(value) });
    }

    onCancelBtnCLick = () => {
        this.props.next(3)
        this.props.allFunction.handleSelectedBusinessCategoryEmpty()
    }

    onUpdateBundleDetail = (count) => {
        

        const { bundleEdit, bundleStateRef } = this.props;

        bundleStateRef.props.actions.apiCall({
            urls: ["BUNDLEUPDATEORDERDETAIL"],
            method: "PUT",
            data: {
                orderId: bundleStateRef.props.orderState.currentOrder.orderId,
                orderDetailId: bundleEdit.bundleItem.OrderDetailId,
                bundleId: bundleEdit.bundleItem.BundleId,
                numberOfBookings: count,
                actionPerformedBy: bundleStateRef.props.auth.userDetail.emailId,
            },
            onSuccess: (response) => {

                this.props.next(3)
                this.props.allFunction.handleSelectedBusinessCategoryEmpty()
                bundleStateRef.props.actions.getOrderItems(bundleStateRef.props.orderState.currentOrder.orderId);
                return response
            },showNotification: true
        });
    }



    render() {
        const {  hasToShowCancellationComponent, bundleDetails,  bundleEdit } = this.props;
        const { count } = this.state;
        return (
            <div>
                <Grid columns={3}>
                    <Grid.Row>
                        <Grid.Column style={{ "padding": "10px" }}>
                            <Header as="h4">Number of bundles</Header>
                            {bundleDetails && bundleDetails.map(item => {
                                return (<p style={{ "padding-left": "10px" }}> {item.quantity} X <span className="orange-color"> <b>{item.ProductName}</b></span></p>)
                            })}
                        </Grid.Column>
                        <Grid.Column>
                            <Form.Field className="add-numbers">
                                <Icon name="minus" onClick={this.countHandleChangeMinus} className="orange-button" />
                                <Form.Input value={count} onChange={this.oncountChange} fluid />
                                <Icon name="plus" className="orange-button plus-btn" onClick={this.countHandleChange} />
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        {!bundleEdit.bundleEdit ?
                            <Grid.Column width={16} textAlign="right">
                                <Button className="blue-btn" onClick={() => this.props.handleBundleCountBack()}>Back</Button>
                                {!hasToShowCancellationComponent &&
                                    <Button className="blue-btn" disabled={count === 0} onClick={() => this.props.handleBundleCountContinue({ count: count, isAddmoreClicked: true })}>Add more items</Button>
                                }
                                <Button className="orange-btn" disabled={count === 0} onClick={() => this.props.handleBundleCountContinue({ count: count, isAddmoreClicked: false })}>Continue</Button>
                            </Grid.Column>
                            :
                            <Grid.Column width={16} textAlign="right">
                                <Button className="blue-btn"
                                    onClick={this.onCancelBtnCLick}
                                >
                                    Cancel
                                </Button>
                                <Button className="orange-btn" disabled={count === 0}
                                    onClick={() => this.onUpdateBundleDetail(count)}
                                >
                                    Update
                                </Button>
                            </Grid.Column>
                        }

                    </Grid.Row>
                </Grid>

            </div>
        )
    }
}

export default BundleCount;
