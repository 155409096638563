import React, { Component } from "react";
import ImageUploading from "react-images-uploading";
import { Image, Icon, Button, Radio, Dimmer, Loader } from "semantic-ui-react";
import {
  commonFunctions,
  connect,
  bindActionCreators,
  actions,
  Notifications,
} from "../../../functional/global-import";

class CommonImage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      image: [],
      defaultImage: [],
    };
  }

  onErrorShow = () => {
    this.props.actions.showNotification(
      {
        title: "Error",
        message:
          "Please choose a PNG or JPG file only. Images must be smaller than 5MB.",
        position: "br",
        autoDismiss: commonFunctions.notificationTime(),
      },
      "error"
    );
  };

  onImageChangeCommon = (imageList) => {
    if (imageList && imageList.length > 0) {
      let checkValidImag = true;
      imageList.map((singleImageObj) => {
        if (checkValidImag && singleImageObj.file) {
          checkValidImag =
            singleImageObj.file.name.match(/\.(jpg|png)$/) === null
              ? false
              : true;
        }
      });
      checkValidImag && this.props.onImageChange(imageList);
      !checkValidImag && this.onErrorShow();
    }
  };

  render() {
    const maxNumber = 10;
    const { imagelist, api } = this.props;
    return (
      <React.Fragment>
        {api.isApiLoading && (
          <Dimmer active inverted>
            {" "}
            <Loader size="small">Loading</Loader>{" "}
          </Dimmer>
        )}
        <ImageUploading
          value={imagelist}
          multiple={this.props.multiple ? true : false}
          onChange={this.onImageChangeCommon}
          maxNumber={maxNumber}
          dataURLKey="data_url"
          maxFileSize={5242880}
        >
          {({
            imageList,
            onImageUpload,
            isDragging,
            dragProps,
            onImageRemove,
            errors,
          }) => (
            // write your building UI
            <div className="upload__image-wrapper">
              <div className="rotate-image">
                {imagelist.length <= 0 && (
                  <div className="cross-icon-image">
                    <Image
                      src={commonFunctions.concatenateImageWithAPIUrl(null)}
                      alt="image"
                      className="sideBorder"
                    />
                  </div>
                )}
                {imagelist &&
                  imagelist.map((image, index) => (
                    <div key={index} className="cross-icon-image">
                      {image["data_url"] ? (
                        <Image
                          src={image["data_url"]}
                          alt="image"
                          className="rotate-image"
                        />
                      ) : (
                        <Image
                          src={image}
                          alt="image"
                          className="rotate-image"
                        />
                      )}
                      {this.props.onImageRemove !== undefined ? (
                        <Icon
                          title="close"
                          name="close"
                          onClick={() => this.props.onImageRemove(index)}
                        />
                      ) : (
                        <Icon
                          name="close"
                          onClick={() => onImageRemove(index)}
                        />
                      )}

                      {this.props.isRadio && (
                        <Radio
                          className="custom-radio-btn imageRadioBtn"
                          label="Default Image "
                          checked={
                            image === this.props.defaultImage[0] ||
                            this.props.DefaultImageId ===
                              this.props.imageId.filter(
                                (id) => id === this.props.DefaultImageId
                              )
                              ? true
                              : false
                          }
                          onChange={(e) =>
                            this.props.handleChangeDefaultImage(image, index)
                          }
                        />
                      )}
                    </div>
                  ))}
              </div>
              <Button
                className="orange-btn"
                onClick={onImageUpload}
                {...dragProps}
              >
                Browse Image
              </Button>
              {errors && errors.maxNumber && (
                <span className="orange-color error-message">
                  (Maximum 10 images allowed to upload.)
                </span>
              )}
              {errors && errors.maxFileSize && (
                <span className="orange-color">
                  (Selected file size exceed maxFileSize.)
                </span>
              )}
            </div>
          )}
        </ImageUploading>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommonImage);
