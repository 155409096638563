import React, { Component } from "react";
import { Button, Grid, Modal, Radio, Table } from "semantic-ui-react";
import { DataTable } from "../../../../../../components/organisms";
import CreateNewDiscountCouponModal from "./create-new-discount-coupon-modal";
import {
  commonFunctions,
  bindActionCreators,
  actions,
  connect,
} from "../../../../../../functional/global-import";
import moment from "moment";

class SavedDiscountModal extends Component {
  child = React.createRef();
  get initialState() {
    return {
      discountAndCoupon: {
        title: "",
        discountType: "A",
        value: "",
        isActive: true,
        discountValue: "",
        startDate: null,
        endDate: null,
        allowNoOfUse: 1,
        maximumAllowedDiscount: null,
        minimumCartValue: 0,
        subTotal: this.props.discountSubtotal,
      },
    };
  }
  constructor(props) {
    super(props);
    this.state = {
      ...this.initialState,
      creatediscount: true,
      saveddiscount: false,
      createDiscount: {},
      gridDetail: [],
      openCreateNewDiscountCoupon: false,
      showAmount: true,
      showPercent: false,
      selectedDiscountCoupon: "",
      selectedDiscountCouponTitle: "",
      discountCouponLoader: false,
      savedDiscount: {
        orderId: this.props.orderState.currentOrder.orderId,
        discountAndCouponsId: 0,
        amount: 0,
        actionPerformedBy: this.props.auth.userDetail.emailId,
      },
    };
    this.validator = commonFunctions.initializeSimpleValidator();
  }

  showsavediscount = () => {
    this.setState({ creatediscount: false, saveddiscount: true });
  };

  showcreatediscount = () => {
    this.setState({ saveddiscount: false, creatediscount: true });
  };

  handleChange = (item) => {
    this.setState({
      selectedDiscountCoupon: item.discountAndCouponsId,
      selectedDiscountCouponTitle: item.title,
    });
  };

  handleCreateNewDiscountCoupon = () => {
    const { openCreateNewDiscountCoupon } = this.state;
    this.setState({
      showAmount: true,
      openCreateNewDiscountCoupon: !openCreateNewDiscountCoupon,
    });
  };

  handleCreateNewDiscountCouponModalClose = () => {
    this.setState(this.initialState);
    this.setState({ openCreateNewDiscountCoupon: false, showAmount: false });
  };

  handleChangeToggle = (e, { name, checked, data }) => {
    this.setState({ showAmount: !checked, showPercent: checked });
  };

  onHandleChange = (e, { name, value, type, checked, data }) => {
    var discountAndCoupon = commonFunctions.onHandleChange(
      e,
      { name, value, type, checked, data },
      this.state.discountAndCoupon
    );
    this.setState({ discountAndCoupon });
  };

  handleNewDiscountCouponSubmit = (e) => {
    const { discountAndCoupon, showAmount, showPercent } = this.state;
    var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
    discountAndCoupon["businessId"] =
      this.props.global.bussinessDetail.businessId;
    discountAndCoupon["businessLocationId"] =
      this.props.global.locationSelected.BusinessLocationId;
    discountAndCoupon["actionPerformedBy"] = this.props.auth.userDetail.emailId;
    discountAndCoupon["discountType"] = showAmount
      ? "A"
      : showPercent
      ? "P"
      : "";
    if (isFormValid) {
      this.setState({ discountCouponLoader: true });
      this.props.actions.apiCall({
        urls: ["ADDDISCOUNTANDCOUPONS"],
        method: "POST",
        showNotification: true,
        data: discountAndCoupon,
        onSuccess: (response) => {
          this.handleCreateNewDiscountCouponModalClose();
          this.child.current.getDefaultData();
          return response;
        },
        onFinally: () => {
          this.setState({ discountCouponLoader: false });
        },
      });
    } else {
      return false;
    }
  };

  handleSubmit = () => {
    if (this.props.handleSubmit) {
      const { selectedDiscountCoupon, selectedDiscountCouponTitle } =
        this.state;
      this.props.handleSubmit(
        selectedDiscountCoupon,
        selectedDiscountCouponTitle
      );
      this.props.closeModal();
    } else {
      const today = new Date();

      const timeConverted = moment(today, "MMM DD YYYY, hh:mm A").format(
        "YYYY-MM-DD"
      );

      const { selectedDiscountCoupon } = this.state;
      let orderId = this.props.orderState.currentOrder.orderId;

      let data = {
        orderId: orderId,
        amount: this.props.discountModalAmount,
        discountAndCouponsId: selectedDiscountCoupon,
        actionPerformedBy: this.props.auth.userDetail.emailId,
        discountDate: timeConverted,
        subTotal: this.props.newSubTotal,
      };

      this.props.actions.apiCall({
        urls: ["POSTADDORDERDISCOUNTANDCOUPONS"],
        method: "POST",
        showNotification: true,
        data: data,
        onSuccess: (response) => {
          this.props.closeDiscountModal();
          this.handleSubmitModal();
          this.props.actions.getOrderItems(orderId);
          return response;
        },
      });
    }
  };

  handleSubmitModal = () => {
    this.setState({
      selectedDiscountCoupon: "",
      selectedDiscountCouponTitle: "",
    });
    this.props.closeModal();
  };
  onTitleChange = (e, { value }) => {
    const { discountAndCoupon } = this.state;

    discountAndCoupon.title = value.trim();
    this.setState({ discountAndCoupon });
  };

  // componentDidMount = ()=>{
  //
  //   const { discountModalAmount } = this.props;
  //   this.setState({ savedDiscount: { ...this.state.savedDiscount, amount: discountModalAmount} });
  // }

  render() {
    const {
      selectedDiscountCoupon,
      openCreateNewDiscountCoupon,
      discountCouponLoader,
    } = this.state;
    const fullState = this.state;
    const allFunction = this;
    const { orderState } = this.props;
    const { api } = this.props;

    return (
      <Modal
        closeOnDimmerClick={false}
        open={this.props.openModal}
        closeIcon
        onClose={() => this.handleSubmitModal()}
        size={"small"}
      >
        <Modal.Content scrolling className="widthAuto">
          {api.isApiLoading && commonFunctions.showLoader()}

          {orderState.currentOrder.pricings &&
            orderState.currentOrder.pricings.DiscountAndCoupons &&
            orderState.currentOrder.pricings.DiscountAndCoupons.length > 0 && (
              <h4 className="deepviolet-color">
                Applied Discount:-{" "}
                {orderState.currentOrder.pricings.DiscountAndCoupons[0].Title}
              </h4>
            )}
          <Grid reversed="computer tablet">
            <Grid.Column mobile={8} tablet={8} computer={8} textAlign="right">
              <Button
                className="orange-btn"
                onClick={(e) => this.handleCreateNewDiscountCoupon()}
              >
                Create New Discount & Coupon
              </Button>
            </Grid.Column>
            <DataTable
              ref={this.child}
              getApiName="GETDISCOUNTANDCOUPONS"
              searchOption={{
                show: true,
                placeHolder: "Search discount and coupons",
              }}
              getGridDataParamters={{ isActive: true }}
              className="noClass"
              gridName="discountCouponsGrid"
              screenName="discount&coupons"
              fullState={fullState}
              allFunction={allFunction}
              tableHeight="150px"
              emptyGrid="No records to show"
              columns={[
                {
                  headerName: "",
                  fieldName: "selected",
                  isSorting: false,
                  Cell: (props) => {
                    return (
                      <Radio
                        className="custom-radio-btn"
                        checked={
                          selectedDiscountCoupon &&
                          selectedDiscountCoupon === props.discountAndCouponsId
                        }
                        onChange={() => this.handleChange(props)}
                      ></Radio>
                    );
                  },
                },
                {
                  headerName: "Title",
                  fieldName: "title",
                  isSorting: true,
                  className: "textOverflowHidden",
                  Cell: (props) => {
                    return <span title={props.title}>{props.title}</span>;
                  },
                },
                {
                  headerName: "Value",
                  fieldName: "value",
                  isSorting: true,
                  headerClass: "textRight",
                  className: "textRight",
                  Cell: (props) => {
                    return (
                      <Table.Cell textAlign="right">
                        {" "}
                        {props.value === 0
                          ? "-"
                          : commonFunctions.isNumberInteger(props.value)}
                      </Table.Cell>
                    );
                  },
                },
                {
                  headerName: "Percentage %",
                  fieldName: "discountPercentage",
                  isSorting: true,
                  className: "textCenter",
                  Cell: (props) => {
                    return props.discountPercentage === 0
                      ? "-"
                      : `${props.discountPercentage}%`;
                  },
                },
              ]}
            />

            {openCreateNewDiscountCoupon && (
              <CreateNewDiscountCouponModal
                openModal={openCreateNewDiscountCoupon}
                discountAndCoupon={fullState.discountAndCoupon}
                showAmount={fullState.showAmount}
                showPercent={fullState.showPercent}
                handleChangeToggle={this.handleChangeToggle}
                // closeModal={this.handleCreateNewDiscountCoupon}
                closeModal={this.handleCreateNewDiscountCouponModalClose}
                onHandleChange={this.onHandleChange}
                onTitleChange={this.onTitleChange}
                discountCouponLoader={discountCouponLoader}
                handleNewDiscountCouponSubmit={
                  this.handleNewDiscountCouponSubmit
                }
                validator={this.validator}
                closeDiscountModal={this.props.closeDiscountModal}
              />
            )}

            <Grid.Column width={16} textAlign="right">
              <Button
                className="blue-btn"
                onClick={() => this.handleSubmitModal()}
              >
                Cancel
              </Button>
              <Button
                className="orange-btn"
                disabled={selectedDiscountCoupon === ""}
                onClick={(e) => this.handleSubmit(e)}
              >
                Save
              </Button>
            </Grid.Column>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
    orderState: state.orderState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      addFeePayment: bindActionCreators(actions.addFeePayment, dispatch),
      getOrderItems: bindActionCreators(actions.getOrderItems, dispatch),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SavedDiscountModal);
