import React, { Component } from "react";
import {Modal } from "semantic-ui-react";
// import {GeneralTaxFormGrid} from "../../../../../pages/more-setting/pricing-taxes-setting/pricing-taxes-general";
import GeneralTaxFormGrid from "../../../../../pages/more-setting/pricing-taxes-setting/general-tax-retail/general-tax-form";
class AddNewTaxModal extends Component {
 state={}
  render(){
    return (
      <Modal open={this.props.openModal}  closeIcon onClose={this.props.closeModal} size={"medium"}>
        <Modal.Header>Add New Tax</Modal.Header>
        <Modal.Content scrolling>
        <GeneralTaxFormGrid showGrid={false}/>
        </Modal.Content>
      </Modal>
    );
  }
}
export default AddNewTaxModal;