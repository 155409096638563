import React, { Component } from "react";
import { Button, Modal, Grid, Table, Header, Loader, Dimmer } from "semantic-ui-react";
import { connect, bindActionCreators, actions, commonFunctions } from "../../../../functional/global-import";
import moment from "moment";

class ItemAvailableModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      availablityDetails: []
    };
  }


  getRentalAvailabilityDetail = () => {
    const { productAvailabilityDetails, orderInventoryStateRef } = this.props;
    
    this.props.actions.apiCall({
      urls: ["GETITEMAVAILABILITYDETAIL"], method: "GET",
      data: {
        ProductId: productAvailabilityDetails.productId,
        StartDate: `${orderInventoryStateRef.state.dateTime.startDate} ${orderInventoryStateRef.state.startTime}`,
        EndDate: `${orderInventoryStateRef.state.dateTime.endDate} ${orderInventoryStateRef.state.endTime}`,
        BusinessCategoryType: (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "BusinessCategoryType", productAvailabilityDetails.businessCategoryType)).globalCodeId,
      }, onSuccess: (response) => {

        this.setState({ availablityDetails: response })
      }, showNotification: true,
    });

  }


  componentDidMount = () => {
    if (this.props.orderInventoryStateRef) {
      this.getRentalAvailabilityDetail();
    }
    else {
      const { partialDetails } = this.props;
      let partialDates = []

      partialDetails.map((partialObj) => {
        if (partialObj.availability === "Partially Unavailable") {
          partialDates.push(partialObj)
          return partialObj
        }
        else if (partialObj.availability === "Unavailable") {
          partialDates.push(partialObj)
          return partialObj
        }
        else {
          return false
        }
      })

      
      let availablityDetails = partialDates.length === 0 ? [] :
        [{
          productDetails: JSON.stringify(partialDates),
          blackOutDates: null

        }]
      this.props.partialDetails && this.setState({ partialDetails: this.props.partialDetails, availablityDetails })
    }

  }

  onConvertDate = (blackOutDate) => {
    const newDateFormat = (moment(blackOutDate, 'YYYY-MM-DD').format('MMM DD, YYYY'))
    return newDateFormat
  }

  render() {
    const { availablityDetails } = this.state;
    const { api } = this.props;

    return (
      <Modal open={this.props.openModal} onClose={this.props.closeModal} closeIcon size={"small"} >
        {api.isApiLoading &&
          <Dimmer active inverted> <Loader size='small'></Loader> </Dimmer>
        }
        <Modal.Header>Item might not be available for mentioned date/timeslot</Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            <Grid>
              {availablityDetails[0] && availablityDetails[0].blackOutDates && availablityDetails[0].blackOutDates !== null &&
                <Grid.Column width={16}>
                  <Header as="h3">Blackout Dates</Header>
                  {JSON.parse(availablityDetails[0].blackOutDates).map((blackoutObj) => {
                    return (
                      <p>- {this.onConvertDate(blackoutObj.days)}</p>
                    )
                  })
                  }
                </Grid.Column>
              }

              {availablityDetails[0] && availablityDetails[0].notAvailableDates && availablityDetails[0].notAvailableDates !== null &&
                <Grid.Column width={16}>
                  <Header as="h3">Not Available Dates</Header>
                  {JSON.parse(availablityDetails[0].notAvailableDates).map((blackoutObj) => {
                    return (
                      <p>- {this.onConvertDate(blackoutObj.days)}</p>
                    )
                  })
                  }
                </Grid.Column>
              }

              {availablityDetails[0] && availablityDetails[0].productDetails &&
                <Grid.Column width={16}>
                  <Header as="h3">Item already booked for mentioned slots</Header>
                  <Table singleLine>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Order Id</Table.HeaderCell>
                        <Table.HeaderCell>Start Date</Table.HeaderCell>
                        <Table.HeaderCell>End Date</Table.HeaderCell>
                        <Table.HeaderCell>Count of item booked</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body >
                      {JSON.parse(availablityDetails[0].productDetails).map((productDetailObj) => {
                        
                        return (
                          <Table.Row verticalAlign='top'>
                            <Table.Cell>#{productDetailObj.OrderId !== undefined ? productDetailObj.OrderId : productDetailObj.orderId}</Table.Cell>

                            <Table.Cell>
                              {productDetailObj.UnAvailabilityStartDate !== undefined ?

                                `${(moment(`${productDetailObj.UnAvailabilityStartDate}`, 'YYYY-MM-DD hh:mm A').format('MMM DD, YYYY, hh:mm A'))}`
                                :
                                `${(moment(`${productDetailObj.unAvailabilityStartDate}`, 'MM/DD/YYYY hh:mm A').format('MMM DD, YYYY, hh:mm A'))}`

                              }
                            </Table.Cell>

                            <Table.Cell>

                              {productDetailObj.UnAvailabilityEndDate !== undefined ?

                                `${(moment(`${productDetailObj.UnAvailabilityEndDate}`, 'YYYY-MM-DD hh:mm A').format('MMM DD, YYYY, hh:mm A'))}`
                                :
                                `${(moment(`${productDetailObj.unAvailabilityEndDate}`, 'MM/DD/YYYY hh:mm A').format('MMM DD, YYYY, hh:mm A'))}`

                              }
                               </Table.Cell>



                            <Table.Cell>
                              <div className="itemCount">
                                <div className="totalItems">
                                  <p>{productDetailObj.Count}</p>
                                </div>
                                <div className="eachItems">
                                  <p>- {productDetailObj.ProductsBooked !== undefined ? productDetailObj.ProductsBooked : productDetailObj.productPrefix}</p>
                                </div>
                              </div>
                            </Table.Cell>

                          </Table.Row>
                        )
                      })}
                    </Table.Body>

                  </Table>
                </Grid.Column>
              }

            </Grid>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.props.closeModal} className="primaryBtn">Ok</Button>
        </Modal.Actions>
      </Modal >
    );
  }
}


const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    newOrderDetail: state.newOrderDetail,
    orderState: state.orderState
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      addSelectedDateRange: bindActionCreators(actions.addSelectedDateRange, dispatch),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemAvailableModal);