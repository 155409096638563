import React, { Component } from "react";
import { Grid, Card, Icon, Dimmer, Loader } from "semantic-ui-react";
import CreditCard from "./credit-card/credit-card";
import CashModal from "./cash-modal";
import DepositPaymentType from "./deposit-payment-modal";
import AddDepositAmount from "./add-deposit-modal";
import CustomCharges from "./custom-charge-modal";
import CreatePaymentType from "./add-new-payment-type";
import GroupanModal from "./group-on-modal";
import AddOnsModal from "./add-ons-modal";
import FeeModal from "./fee-modal";
import DiscountModal from "./discount-and-coupon-modal";
import SavedDiscountModal from "./discount-and-coupon-modal/saved-discount-modal";
import { orderCommonFunctions } from "../order-common-function";
import {
  connect,
  bindActionCreators,
  actions,
  commonFunctions,
  Notifications,
} from "../../../../../functional/global-import";
import SeasonPassModal from "./season-pass-modal";

let CreditCardDetail = [
  { label: "Selected Item(s)", value: 1 },
  { label: "Balance Due", value: 2 },
  { label: "Custom Amount", value: 3 },
];
class PaymentBoxes extends Component {
  constructor() {
    super();
    this.state = {
      openSeasonPassModal: false,
      activeStep: 0,
      singlePayment: false,
      creditCardModal: {
        amountSlected: null,
        creditcardStatus: false,
        creditcardpaymentStatus: false,
        tabinsertStatus: false,
        tipsignStatus: false,
      },
      paymentType: [],
      selectedPaymentDetails: "",
      itemOrBalDue: "",
      errorMsgNullBal: `Balance Due Is "0" , Please add Item in Cart`,
      selectedAmount: null,
      depositModalStatus: false,
      isAddDepositModalOpen: false,
      isShowGettrixPayment: false,
      paymentSelect: null,
      defaultUserDetails: { defaultCounterId: null, readerSerialNumber: "" },
      counterDetails: null,
      orderTypeData :[]
    };
       
  }
  CreditCardModal = () => {
       
    const { creditCardModal } = this.state;
    this.setState({
      creditCardModal: {
        ...creditCardModal,
        creditcardStatus: !this.state.creditCardModal.creditcardStatus,
      },
    });
  };

  CreditCardPaymentModal = (amountSlected) => {
    const { creditCardModal } = this.state;
    this.setState({
      creditCardModal: {
        ...creditCardModal,
        amountSlected: amountSlected,
        creditcardStatus: false,
        creditcardpaymentStatus:
          !this.state.creditCardModal.creditcardpaymentStatus,
      },
    });
  };

  TapInsertModal = (creditCardPayment, stateRef = undefined) => {
    const { itemDetailsGridState, auth, orderState, global } = this.props;
    let totalItemsSelectedDetails = [];
    if (creditCardPayment.isSelectedItem) {
      let paymentDoneAlready =
        orderState.currentOrder.productItemPaid &&
        JSON.parse(orderState.currentOrder.productItemPaid);
      paymentDoneAlready =
        paymentDoneAlready === null ? [] : paymentDoneAlready;
      let selectedItemDetails =
        orderCommonFunctions.getJsonOfSelectedCheckboxes(orderState);
      totalItemsSelectedDetails = JSON.stringify([
        ...paymentDoneAlready,
        ...selectedItemDetails,
      ]);
    }
    const paymentMethod = commonFunctions.getGlobalCodeDetails(
      this.props.global.codes,
      "PaymentMethod",
      creditCardPayment.paymentType
    ).globalCodeId;

    const cashGlobalCodeId = commonFunctions.getGlobalCodeDetails(
      this.props.global.codes,
      "PaymentMethod",
      "Cash"
    ).globalCodeId;
    let data = {
      businessId: global.bussinessDetail.businessId,
      businessLocationId: global.locationSelected.BusinessLocationId,
      orderId: orderState.currentOrder.orderId,
      IsAhoyShareONCash: orderState.currentOrder.isAhoyShareONCash,
      totalCashGiven: creditCardPayment.totalCashGiven,
      paymentMethod: paymentMethod,
      counterId: creditCardPayment.counterId ? creditCardPayment.counterId : 31,
      orderDetailId: null,
      isTipAdded: creditCardPayment.tipPrice > 0 ? true : false,
      tipAmount:creditCardPayment.tipPrice > 0 ? creditCardPayment.tipPrice : 0,
      amountPaid: creditCardPayment.totalPayment,
      actionPerformedBy: auth.userDetail.emailId,
      paymentMethodSubTypeId:creditCardPayment.paymentMethodSubTypeId,
      amountTypes:itemDetailsGridState.itemDesposit && orderState.currentOrder.depositStatus !== "Paid" ? "ItemDeposit": null,
      paymentMethodName: paymentMethod === cashGlobalCodeId ? "Cash" : "Credit",
      stripeTransactionId:paymentMethod === cashGlobalCodeId ? null: creditCardPayment.transactionId,
      isSplitPayment: creditCardPayment.isSplitPayment,
      productItemPaid: creditCardPayment.isSelectedItem
        ? totalItemsSelectedDetails
        : orderState.currentOrder.productItemPaid,
    };
    this.handlePayment(data, creditCardPayment.paymentType, stateRef);
  };

  handlePayment = (data, creditCardPayment, stateRef) => {
    this.props.actions.apiCall({
      urls: ["ADDPAYMENTS"],
      method: "POST",
      data: data,
      showNotification: true,
      onSuccess: (response) => {
        if (response !== null) {
          stateRef &&
            stateRef.modalName === "credit" &&
            stateRef.stateRef.setState({ isStripeModal: false });
          if (data.isSplitPayment) {
            stateRef.stateRef.onSplitPaymentSubmit(response, data);
          } else {
            stateRef.stateRef.onSinglePaymentSubmit(response, data);
          }
          this.props.actions.getOrderItems(
            this.props.orderState.currentOrder.orderId
          );
          return response;
        }
      },
    });
  };

  handleCloseAfterPayment = (creditCardPayment) => {
    if (creditCardPayment === "Card") {
      this.setState({
        creditCardModal: {
          ...this.state.creditCardModal,
          creditcardStatus: false,
          creditcardpaymentStatus: false,
        },
      });
    } else if (creditCardPayment === "Cash") {
      this.setState({
        cashStatus: false,
        cashpaymentStatus: false,
        CreditCardPaymentModal: false,
      });
    } else if (creditCardPayment === "CustomCharge") {
      this.CustomChargeModal();
    } else if (creditCardPayment === "FeeCharge") {
      this.FeeModal();
    } else if (creditCardPayment.title) {
      this.GroupanModal();
    }
  };

  handlePostAPIForCustomChagre = (data) => {
    const { auth, orderState } = this.props;
    let orderCharges = data.orderChargeType;
    data["orderId"] = orderState.currentOrder.orderId;
    data["actionPerformedBy"] = auth.userDetail.emailId;
    data["orderChargeType"] = commonFunctions.getGlobalCodeDetails(
      this.props.global.codes,
      "OrderCharges",
      data.orderChargeType
    ).globalCodeId;

    this.props.actions.apiCall({
      urls: ["POSTADDORDERCUSTOMERCHARGE"],
      method: "POST",
      data: data,
      showNotification: true,
      onSuccess: (response) => {
        this.handleCloseAfterPayment(orderCharges, response);
        this.props.actions.getOrderItems(
          this.props.orderState.currentOrder.orderId
        );
        return response;
      },
    });
  };

  closeModalTabInsertStatus = () => {
    this.setState({
      creditCardModal: {
        ...this.state.creditCardModal,
        creditcardStatus: false,
        creditcardpaymentStatus: false,
        tabinsertStatus: false,
      },
    });
  };

  CashModal = () => {
    this.setState({ cashStatus: !this.state.cashStatus });
  };

  addDepositModal = () => {
    const { orderState } = this.props;
    if (orderState.currentOrder.paidOrderDepositAmount) {
      this.props.actions.showNotification(
        {
          title: "Error",
          message:
            "Order Deposit is already pre-authorized, If you want to add/update order deposit then cancel pre- authorized deposit amount first.",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    } else {
      this.setState({ isAddDepositModalOpen: true });
    }
  };

  onGetStripeDetail = () => {
    this.props.actions.apiCall({
      urls: ["GETSTRIPEDETAIL"],
      method: "GET",
      data: {
        BusinessId: this.props.global.bussinessDetail.businessId,
        BusinessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
      },
      onSuccess: (response) => {
        this.setState({ stripelocationDetails: response });
        return response;
      },
    });
  };

  depositModal = () => {
    const { orderState } = this.props;
    if (orderState.currentOrder.selectedDepositAmount.length > 0) {
      let amountSlected = 0;
      orderState.currentOrder.selectedDepositAmount.map((singleObj) => {
        amountSlected = amountSlected + singleObj.amount;
      });
      this.setState({
        depositModalStatus: !this.state.depositModalStatus,
        depositAmount: amountSlected,
      });
    } else if (orderState.currentOrder.selectedDepositAmount.length === 0) {
      this.props.actions.showNotification(
        {
          title: "Error",
          message: "Please select deposit amount",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    }
  };

  CashPaymentModal = (selectedAmount) => {
    this.setState({
      cashStatus: false,
      cashpaymentStatus: !this.state.cashpaymentStatus,
      selectedAmount: selectedAmount,
    });
  };

  BalanceDueModal = () => {
    this.setState({
      cashpaymentStatus: false,
      singlePayment: false,
      balancedueStatus: !this.state.balancedueStatus,
    });
  };
  BalanceDueSinglePaymentModal = () => {
    this.setState({
      cashpaymentStatus: false,
      singlePayment: true,
      balancedueStatus: !this.state.balancedueStatus,
    });
  };
  CustomChargeModal = () => {
    this.setState({ customchargeStatus: !this.state.customchargeStatus });
  };
  CreatePaymentTypeModal = () => {
    this.setState({ createpaymentStatus: !this.state.createpaymentStatus });
  };
  GroupanModal = (item) => {
    this.setState({
      groupanStatus: !this.state.groupanStatus,
      selectedPaymentDetails: item,
    });
  };
  AddOnsModal = () => {
    this.setState({ addonsStatus: !this.state.addonsStatus });
  };
  FeeModal = () => {
    this.setState({ feeStatus: !this.state.feeStatus });
  };
  DiscountModal = () => {
    if (!this.props.itemDetailsGridState.itemDesposit) {
      this.setState({ discountStatus: !this.state.discountStatus });
    }
  };
  onApplySeasonPass = () => {
    this.props.actions.getOrderItems(
      this.props.orderState.currentOrder.orderId
    );
  };
  onClickApplySeasonPass = () => {
    this.setState({ openSeasonPassModal: true });
  };
  SavedDiscountModal = (itemOrBalDue) => {
    if (itemOrBalDue === "") {
      this.props.actions.showNotification(
        {
          title: "Error",
          message: "Please select anyone payment option ",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    } else {
      const { orderState } = this.props;
      if (itemOrBalDue === "SelectedItem") {
        let amountSlected = 0;
        orderState.currentOrder.selectedAmountItem.map((singleObj) => {
          amountSlected = amountSlected + singleObj.amount;
        });
        if (orderState.currentOrder.selectedAmountItem.length > 0) {
          this.setState({
            discountModalAmount: amountSlected,
            itemOrBalDue: itemOrBalDue,
            discountStatus: false,
            saveddiscountStatus: !this.state.saveddiscountStatus,
          });
        } else {
          this.props.actions.showNotification(
            {
              title: "Error",
              message: "Please select atleast one Item ",
              position: "br",
              autoDismiss: commonFunctions.notificationTime(),
            },
            "error"
          );
        }
      } else {
        this.setState({
          discountModalAmount: orderState.currentOrder.pricings.BalanceDue,
          itemOrBalDue: itemOrBalDue,
          discountStatus: false,
          saveddiscountStatus: !this.state.saveddiscountStatus,
        });
      }
    }
  };

  getPaymentType = () => {
    this.props.actions.apiCall({
      urls: ["GETPAYMENTTYPE"],
      method: "GET",
      data: {
        isActive: true,
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        pageNo: 1,
        pageSize: 10,
        PaymentTypeId: -1,
      },
      onSuccess: (response) => {
        if (response !== null) {
          let data = response.map((item) => {
            return {
              ...item,
              title: item.paymentTypeTitle,
              onClick: this.GroupanModal,
            };
          });
          this.setState({ paymentType: data });
        }
      },
    });
  };

  onHandleChange = (e, { name, value, type, checked, data }) => {
    var paymentTypeForm = commonFunctions.onHandleChange(
      e,
      { name, value, type, checked, data },
      this.state.paymentTypeForm,
      this
    );
    this.setState({ paymentTypeForm });
  };
  getGlobalCode = () => {
       
    this.props.actions.apiCall({
      urls: ["GLOBALCODE"],
      method: "GET",
      data: {CategoryName:"PaymentMethodSubTypes",
             GlobalCodeId: -1 },
      onSuccess: (response) => {
      this.setState({orderTypeData:response})},
      showNotification: false,
    });
  };

  componentDidMount() {
    this.getPaymentType();
    this.onGetStripeDetail();
    this.paymentSelection();
    this.getDeafaultCounterReader();
    this.getGlobalCode();
  }
  getDeafaultCounterReader = () => {
    const { auth, global } = this.props;
    this.props.actions.apiCall({
      urls: ["GETDEFAULTCOUNTEANDREADER"],
      method: "GET",
      data: {
        businessId: global.bussinessDetail.businessId,
        businessLocationId: global.locationSelected.BusinessLocationId,
        userId: auth?.userDetail?.keyId,
      },
      onSuccess: (response) => {
        this.setState({
          defaultUserDetails: response,
        });
      },
    });
  };
  paymentSelection = () => {
    this.props.actions.apiCall({
      urls: ["GETPAYMENTTYPEFORORDER"],
      method: "GET",
      data: {
        BusinessId: this.props.global.bussinessDetail.businessId,
        BusinessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
      },
      onSuccess: (response) => {
        const paymentSelection = response.map((data) => {
          return {
            key: data.paymentMethodName,
            value: data.paymentTypeId,
            text: data.paymentMethodName,
          };
        });
        this.setState({ paymentSelect: paymentSelection });
      },
    });
  };
  render() {
    const {
      cashStatus,
      paymentType,
      customchargeStatus,
      createpaymentStatus,
      groupanStatus,
      addonsStatus,
      discountStatus,
      saveddiscountStatus,
      feeStatus,
      itemOrBalDue,
      selectedAmount,
      depositModalStatus,
      depositAmount,
      isAddDepositModalOpen,
      discountModalAmount,
      paymentSelect,
      defaultUserDetails,
      openSeasonPassModal,
    } = this.state;
    const {
      creditcardStatus,
      creditcardpaymentStatus,
      tabinsertStatus,
      tipsignStatus,
      ReceiptStatus,
    } = this.state.creditCardModal;
    const { orderState } = this.props;
    let fixedPayment = [
      { title: "Credit & Debit Card", onClick: this.CreditCardModal },
      { title: "Cash", onClick: this.CashModal },
      { title: "Pre-Authorize", onClick: this.depositModal },
      { title: "Add-Deposit", onClick: this.addDepositModal },
      { name: "plus", size: "huge", onClick: this.CreatePaymentTypeModal },
      { title: "Discount & Coupon", onClick: this.DiscountModal },
      { title: "Apply Pass Code", onClick: this.onClickApplySeasonPass },
      { title: "Add-Ons", onClick: this.AddOnsModal },
      {
        title: "Custom Charge",
        onClick: this.CustomChargeModal,
        value: "CustomCharge",
      },
      { title: "Fee", onClick: this.FeeModal },
    ];

    let bothPaymentFixed = fixedPayment.concat(paymentType);
    return (
      <>
        {this.props.api.isApiLoading && (
          <Dimmer active inverted>
            <Loader />
          </Dimmer>
        )}

        <Grid>
          {bothPaymentFixed.map((item) => {
            if (item.name) {
              return (
                <Grid.Column mobile={8} tablet={4} computer={4}>
                  <Card
                    className="payment-boxes"
                    onClick={() => this.CreatePaymentTypeModal()}
                  >
                    <Card.Content>
                      <Icon name={item.name} size={item.size} />
                    </Card.Content>
                  </Card>
                </Grid.Column>
              );
            } else {
              return (
                <Grid.Column mobile={8} tablet={4} computer={4}>
                  <Card
                    className="payment-boxes"
                    onClick={() => item.onClick(item)}
                  >
                    <Card.Content>
                      {item.paymentTypeTitle
                        ? item.paymentTypeTitle
                        : item.title}
                    </Card.Content>
                  </Card>
                </Grid.Column>
              );
            }
          })}
        </Grid>

        {/*  modal's to open in payment box component  */}
        {(creditcardStatus ||
          creditcardpaymentStatus ||
          tabinsertStatus ||
          tipsignStatus ||
          ReceiptStatus) && (
          <CreditCard
            paymentSelect={paymentSelect}
            isShowGettrixPayment={true}
            creditCardModal={this.state.creditCardModal}
            closeModalTabInsertStatus={this.closeModalTabInsertStatus}
            CreditCardPaymentModal={this.CreditCardPaymentModal}
            CreditCardModal={this.CreditCardModal}
            CreditCard={CreditCardDetail}
            orderTypeData={this.state.orderTypeData}
            TapInsertModal={this.TapInsertModal}
            itemDetailsGridState={this.props.itemDetailsGridState}
            defaultUserDetails={defaultUserDetails}
          />
        )}

        {(cashStatus || this.state.cashpaymentStatus) && (
          <CashModal
            isShowGettrixPayment={false}
            selectedAmount={selectedAmount}
            stateRef={this}
            openModal={cashStatus}
            closeCashModal={this.CashModal}
            creditCardModal={this.state.cashpaymentStatus}
            CashPaymentModal={this.CashPaymentModal}
            CreditCard={CreditCardDetail}
            orderTypeData={this.state.orderTypeData}
            onChargeClick={this.TapInsertModal}
            itemDetailsGridState={this.props.itemDetailsGridState}
            defaultUserDetails={defaultUserDetails}
          />
        )}

        {depositModalStatus && (
          <DepositPaymentType
            isShowGettrixPayment={true}
            paymentSelect={paymentSelect}
            depositAmount={depositAmount}
            openModal={depositModalStatus}
            closeModal={() => this.setState({ depositModalStatus: false })}
            defaultUserDetails={defaultUserDetails}
          />
        )}

        {isAddDepositModalOpen && (
          <AddDepositAmount
            openModal={isAddDepositModalOpen}
            closeModal={() => this.setState({ isAddDepositModalOpen: false })}
          />
        )}
        {/*  not used so commented it 25 april 2023 */}
        {/* {balancedueStatus && (
          <BalanceDueModal
            openModal={balancedueStatus}
            singlePayment={this.state.singlePayment}
            closeModal={this.BalanceDueModal}
          />
        )} */}

        {createpaymentStatus && (
          <CreatePaymentType
            getPaymentType={this.getPaymentType}
            propsState={this.props}
            openModal={createpaymentStatus}
            closeModal={this.CreatePaymentTypeModal}
            apiCall={this.props.actions.apiCall}
          />
        )}

        {discountStatus && (
          <DiscountModal
            openModal={discountStatus}
            closeModal={this.DiscountModal}
            itemDetailsGridState={this.props.itemDetailsGridState}
            onNextClick={this.SavedDiscountModal}
          />
        )}

        {saveddiscountStatus && (
          <SavedDiscountModal
            discountModalAmount={discountModalAmount}
            newSubTotal={orderState.currentOrder.pricings.NewSubTotal}
            itemOrBalDue={itemOrBalDue}
            openModal={saveddiscountStatus}
            discountSubtotal={orderState.currentOrder.pricings.Subtotal}
            closeDiscountModal={this.DiscountModal}
            closeModal={() =>
              this.setState({
                saveddiscountStatus: false,
                discountStatus: false,
              })
            }
            itemDetailsGridState={this.props.itemDetailsGridState}
          />
        )}
        {openSeasonPassModal && (
          <SeasonPassModal
            openModal={openSeasonPassModal}
            seasonPassSubtotal={orderState.currentOrder.pricings.NewSubTotal}
            closeModal={() => this.setState({ openSeasonPassModal: false })}
            onApplySeasonPass={this.onApplySeasonPass}
          />
        )}

        {addonsStatus && (
          <AddOnsModal openModal={addonsStatus} closeModal={this.AddOnsModal} />
        )}

        {customchargeStatus && (
          <CustomCharges
            openModal={customchargeStatus}
            closeModal={this.CustomChargeModal}
            handlePostAPIForCustomChagre={this.handlePostAPIForCustomChagre}
          />
        )}

        {feeStatus && (
          <FeeModal
            openModal={feeStatus}
            closeModal={this.FeeModal}
            handlePostAPIForCustomChagre={this.handlePostAPIForCustomChagre}
          />
        )}

        {groupanStatus && (
          <GroupanModal
            isApiLoading={this.props.api.isApiLoading}
            openModal={groupanStatus}
            closeModal={this.GroupanModal}
            propsState={this.props}
            creditcardpaymentStatus
            selectedPaymentDetails={this.state.selectedPaymentDetails}
            defaultUserDetails={defaultUserDetails}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    api: state.api,
    auth: state.auth,
    global: state.global,
    newOrderDetail: state.newOrderDetail,
    orderState: state.orderState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      getOrderItems: bindActionCreators(actions.getOrderItems, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PaymentBoxes);
