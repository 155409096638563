import React, { Component } from "react";
import { Button, Modal, Table, Dimmer, Loader } from "semantic-ui-react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import {
  connect,
  bindActionCreators,
  actions,
  env,
} from "../../../../../shared/functional/global-import";
class CalenderModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allData: [],
    };
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.getAllEvents !== prevProps.getAllEvents) {
      this.getAllevents();
    }
  };

  getAllevents = () => {
    if (this.props.Calender === true) {
      this.props.actions.apiCall({
        urls: ["GETCALENDARITEMLIST"],
        method: "GET",
        data: {
          ItemId: this.props.global.inventoryDetail.productId,
          BusinessCategoryType:
            this.props.global.inventoryDetail.businessCategoryId,
          BusinessId: this.props.global.bussinessDetail.businessId,
          OrderId: this.props.getAllEvents,
          DateRangeType: this.props.DateRangeType,
          Start: this.props.startTime,
          BusinessLocationId:
            this.props.global.locationSelected.BusinessLocationId,
        },
        onSuccess: (response) => {
          this.setState({ allData: response });
        },
      });
    } else {
      this.props.actions.apiCall({
        urls: ["GETCALENDARLISTVIEW"],
        method: "GET",
        data: {
          BusinessId: this.props.global.bussinessDetail.businessId,
          OrderId: this.props.getAllEvents,
          DateRangeType: this.props.DateRangeType,
          Start: this.props.startTime,
          BusinessLocationId:
            this.props.global.locationSelected.BusinessLocationId,
        },
        onSuccess: (response) => {
          this.setState({ allData: response });
        },
      });
    }
  };

  render() {
    const { api, showViewAllBtn } = this.props;
    return (
      <Modal
        open={this.props.openModal}
        closeIcon
        onClose={this.props.hideModal}
        size={"large"}
        centered={true}
      >
        {api.isApiLoading && (
          <Dimmer active inverted>
            <Loader />
          </Dimmer>
        )}
        <Modal.Header>
          Recent Orders
          {showViewAllBtn && (
            <Link
              to={{
                pathname: `${process.env.REACT_APP_PUBLIC_URL}/order`,
                state: { DateRangeType: this.props.DateRangeType },
                // Component:{MonthViewFullcalandar}
              }}
            >
              <Button className="lefta">View all</Button>
            </Link>
          )}
        </Modal.Header>
        <Modal.Content className="RecentOrderslarge">
          <Modal.Header></Modal.Header>
          <Modal.Description>
            <div>
              <Table singleLine>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell className="numeric1">
                      Order ID{" "}
                    </Table.HeaderCell>
                    <Table.HeaderCell>Created </Table.HeaderCell>
                    <Table.HeaderCell>Customer</Table.HeaderCell>
                    <Table.HeaderCell>Date & Time </Table.HeaderCell>
                    <Table.HeaderCell className="numeric1">
                      Items{" "}
                    </Table.HeaderCell>
                    <Table.HeaderCell className="numeric1">
                      Amount
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.state.allData.map((allData, index) => {
                         
                    return (
                      <Table.Row>
                        <Table.Cell className="numeric">
                          <Link
                            to={`${env.PUBLIC_URL}/order-summary/${allData.orderId}`}
                            className="orange-color"
                          >
                            {"#"}
                            {allData.orderId}
                          </Link>
                        </Table.Cell>
                        <Table.Cell
                          title={allData.created}
                          className="textOverflowHidden"
                        >
                          {allData.created}
                        </Table.Cell>
                        <Table.Cell
                          title={allData.customer}
                          className="textOverflowHidden"
                        >
                          {allData.customer}
                        </Table.Cell>
                        <Table.Cell>
                          <Moment format="MMMM DD,YYYY">{allData.date}</Moment>
                          <Moment format=" hh:mm A">{allData.date}</Moment>
                        </Table.Cell>
                        <Table.Cell className="numeric1">
                          {allData.items}
                        </Table.Cell>
                        <Table.Cell className="numeric">
                          {"$"}
                          {allData.value}
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            </div>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.props.hideModal} className="blue-btn">
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CalenderModal);
