import React, { Component } from "react";
import {
  Button,
  Grid,
  Modal,
  Dimmer,
  Loader,
  Card,
  Input,
  Header,
} from "semantic-ui-react";
import {
  commonFunctions,
  connect,
  bindActionCreators,
  actions,
  Notifications,
} from "../../../../functional/global-import";
import SignaturePad from "react-signature-canvas";
import AddCommentModal from "../../../organisms/modal/add-comment-modal";
import moment from "moment";

class SplitSignatureModal extends Component {
  sigPad = React.createRef();

  constructor(props) {
    super(props);
                                          
    this.state = {
      candidateName: null,
      isLoading: false,
      commentStatus: false,
    };
  }

  onSubmitDetails = async (e) => {
    const { transactionDetails, auth } = this.props;
    let isEmpty = await this.isEmpty();
    let data = await this.handleSign();
    if (!isEmpty) {
      this.setState({ isLoading: true });
      this.props.actions.apiCall({
        urls: ["UPDATETRANSACTIONSIGN"],
        method: "PUT",
        data: {
          transactionDetailId: transactionDetails.KeyId,
          customerName: this.state.candidateName,
          customerSignature: data,
          actionPerformedBy: auth.userDetail.emailId,
        },
        onSuccess: (response) => {                                        
          this.props.onClose();
          if(this.props.addDeposit){
          this.props.onSignSubmitModal();
          }
          this.props.onClosedPayment();
          return response;
        },
        onFinally: () => {
          this.setState({ isLoading: false });
        },
        showNotification: true,
      });
    } else {
      this.props.onClose();
      if(this.props.addDeposit){
      this.props.onSignSubmitModal();
      }
      this.props.onClosedPayment();
    }
  };

  isEmpty = () => {
    return this.sigPad.current.isEmpty();
  };

  clear = () => {
    this.sigPad.current.clear();
    
  };

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  handleSign = () => {
    this.sigPad.current.toDataURL();
    return this.sigPad.current.toDataURL();
  };
  onSkipBtnClick = () => {
    this.props.onClose();
    // this.props.onSignSubmit();
    this.props.onClosedPayment();
  };
  addCommentModal = () => {
    this.setState({ commentStatus: !this.state.commentStatus });
  };
  componentDidUpdate() {
    const customer = this.props.orderState.currentOrder.customerDetail;
    let customerName = customer.firstName + "  " + customer.lastName;
    this.state.candidateName = customerName;
  }
  render() {
    const { isModalOpen, splitAmount, isPreAuthorize, transactionDetails } = this.props;
    const { candidateName, isLoading, commentStatus } = this.state;
    let newSplitAmount = parseFloat(splitAmount).toFixed(2);
                                                                      
    return (
      <>
        <Modal
          className="signature-modal"
          closeOnDimmerClick={false}
          open={isModalOpen}
          closeIcon
          // size={"small"}
          onClose={this.onSkipBtnClick}
        >
          {isLoading && (
            <Dimmer active inverted>
              {" "}
              <Loader size="small"></Loader>{" "}
            </Dimmer>
          )}
          <Modal.Content>
            <Grid>
              <Grid.Column width={11}>
                <Button className="blue-btn" onClick={this.onSkipBtnClick}>
                  Skip
                </Button>
              </Grid.Column>
              <Grid.Column width={3} textAlign="right">
                <Header>Add Notes</Header>
              </Grid.Column>
              <Grid.Column width={2} textAlign="left">
                <a
                  href="#"
                  title="Add Notes"
                  onClick={() => this.addCommentModal()}
                >
                  <span
                    class="iconify maroon-color"
                    data-icon="mdi:comment-plus"
                    data-inline="false"
                    data-flip="horizontal"
                    data-width="25px"
                    data-height="25px"
                  />
                </a>
              </Grid.Column>
              <Grid.Column width={16} textAlign="center">
                <Header className="orange-color">
                  {" "}
                  Current date/time: {moment().format("MMM DD,  YYYY hh:mm A")}
                </Header>
              </Grid.Column>

              <Grid.Column width={16} textAlign="center">
                <h2>
                  {" "}
                  {isPreAuthorize === undefined
                    ? "Charged"
                    : "Pre-Authorized"}{" "}
                  - {commonFunctions.isNumberInteger(newSplitAmount)}
                </h2>
              </Grid.Column>
              <Grid.Column width={16}>
                <Card fluid className="signature-box">
                  <Card.Content>
                    <Input
                      value={candidateName}
                      name="candidateName"
                      placeholder="Type Full Name"
                      fluid
                      onChange={(e, { name, value }) =>
                        this.handleChange(e, { name, value })
                      }
                    />
                  </Card.Content>
                  <Card.Content>
                    <SignaturePad
                      canvasProps={{
                        className: "sigCanvas",
                        height: this.props.height,
                      }}
                      ref={this.sigPad}
                    />
                  </Card.Content>
                </Card>
              </Grid.Column>
              <Grid.Column width={16} textAlign="right">
                <Button className="blue-btn" onClick={this.clear}>
                  Clear Signature
                </Button>{" "}
                &nbsp;&nbsp;
                <Button className="orange-btn" onClick={this.onSubmitDetails}>
                  Done Signing
                </Button>
              </Grid.Column>
            </Grid>
          </Modal.Content>
        </Modal>

        <AddCommentModal
          openModal={commentStatus}
          noteType={"TransactionDetail"}
          noteRefrenceId={transactionDetails && transactionDetails.KeyId}
          closeModal={this.addCommentModal}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    api: state.api,
    auth: state.auth,
    // global: state.global,
    orderState: state.orderState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
      getOrderItems: bindActionCreators(actions.getOrderItems, dispatch),
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SplitSignatureModal);
