import React, { Component } from "react";
import { Button, Grid, Modal, Input, Table, Checkbox } from "semantic-ui-react";
import { DataTable } from "../../../../../organisms";
import CreateAddOnsModal from "./create-add-ons-modal";
import {
  commonFunctions,
  actions,
  bindActionCreators,
  connect,
} from "../../../../../../functional/global-import";

class AddOnsModal extends Component {
  child = React.createRef();

  get initialState() {
    return {
      selectedList: [],
      onSaving: false,
    };
  }

  constructor() {
    super();
    this.state = {
      ...this.initialState,
      createaddons: true,
      savedaddons: false,
      gridDetail: [],
      openAddOnsModal: false,
      AddOnsLoader:false,
      addOnsForm: {
        businessId: "",
        addOnTitle: "",
        addOnPrice: "",
        businessLocationId: "",
        image: [],
        actionPerformedBy: "",
        addOnId: "",
        IsImageUpdated: false,
        isActive: true,
      },
    };
    this.validator = commonFunctions.initializeSimpleValidator();
  }

  showsavedons = () => {
    this.setState({
      createaddons: false,
      savedaddons: true,
    });
  };
  showcreateons = () => {
    this.setState({
      savedaddons: false,
      createaddons: true,
    });
  };

  handleChange = (e, { checked }, selectedProduct) => {
    const { selectedList } = this.state;
    let data = selectedList;
    if (checked !== false) {
      data.push(selectedProduct);
    } else {
      data = data.filter((item) => item.addOnId !== selectedProduct.addOnId);
    }
    this.setState({ selectedList: data });
  };

  handleOpenCreateAddOnsModal = () => {
    const { openAddOnsModal } = this.state;
    this.setState({ openAddOnsModal: !openAddOnsModal });
  };

  handleCreateAddOnsModal = (e) => {
    const { addOnsForm } = this.state;
    addOnsForm["businessId"] = this.props.global.bussinessDetail.businessId;
    addOnsForm["businessLocationId"] =
      this.props.global.locationSelected.BusinessLocationId;
    addOnsForm["actionPerformedBy"] = this.props.auth.userDetail.emailId;
    var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
    if (isFormValid) {
      this.setState({ AddOnsLoader: true });
      const formdata = commonFunctions.getFormData(addOnsForm);
      this.props.actions.apiCall({
        urls: ["ADDADDONS"],
        method: "POST",
        showNotification: true,
        data: formdata,
        onSuccess: (response) => {
          this.handleOpenCreateAddOnsModal();
          this.child.current.getDefaultData();
          return response;
        },
        onFinally: () => {
          this.setState({ AddOnsLoader: false });
        },
      });
    } else {
      return false;
    }
  };

  onHandleChange = (e, { name, value, type, checked, data }) => {
    var addOnsForm = commonFunctions.onHandleChange(
      e,
      { name, value, type, checked, data },
      this.state.addOnsForm
    );
    this.setState({ addOnsForm });
  };

  handleChangeQty = (e, { value }, item) => {
    const { gridDetail } = this.state;
    let data = gridDetail.findIndex(
      (detail) => detail.addOnId === item.addOnId
    );
    const newAmount = parseInt(value);
    if (newAmount >= 0) {
      gridDetail[data].Qty = newAmount;
      this.setState({ gridDetail });
    } else {
      gridDetail[data].Qty = null;
      this.setState({ gridDetail });
    }
  };

  componentDidMount() {
    this.setState(this.initialState);
    if (this.state.gridDetail.length > 0) {
      let data = this.state.gridDetail.map((item) => {
        return { ...item, Qty: 1 };
      });
      this.setState({ gridDetail: data });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.gridDetail.length !== this.state.gridDetail.length) {
      let data = this.state.gridDetail.map((item) => {
        return { ...item, Qty: 0 };
      });
      this.setState({ gridDetail: data });
    }
  }

  handleSubmit = () => {
    this.setState({ onSaving: true });
    const { orderState } = this.props;

    const selectedData = this.state.selectedList.map((item) => {
      return {
        orderId: orderState.currentOrder.orderId,
        addOnId: item.addOnId,
        price: item.addOnPrice,
        quantity: item.Qty,
      };
    });

    this.props.actions.apiCall({
      urls: ["POSTADDORDERADDON"],
      method: "POST",
      showNotification: true,
      data: {
        orderId: orderState.currentOrder.orderId,
        addOns: selectedData,
        actionPerformedBy: this.props.auth.userDetail.emailId,
      },
      onSuccess: (response) => {
        this.props.actions.getOrderItems(
          this.props.orderState.currentOrder.orderId
        );
        this.handleClose();
        this.setState(this.initialState);
        return response;
      },
      onFinally: () => {
        this.setState({ onSaving: false });
      },
    });
  };

  handleClose = () => {
    this.setState({ gridDetail: [] });
    this.props.closeModal();
  };

  render() {
    const { openAddOnsModal, addOnsForm, selectedList, onSaving,AddOnsLoader } = this.state;
    const fullState = this.state;
    const allFunction = this;
    const { api } = this.props;

    return (
      <Modal
        closeOnDimmerClick={false}
        open={this.props.openModal}
        closeIcon
        onClose={() => this.handleClose()}
        size={"small"}
      >
        <Modal.Content scrolling className="widthAuto">
        {api.isApiLoading && commonFunctions.showLoader()}
          <Grid reversed="computer tablet">
            <Grid.Column mobile={8} tablet={8} computer={8} textAlign="right">
              <Button
                className="orange-btn"
                onClick={(e) => this.handleOpenCreateAddOnsModal()}
              >
                Create Add Ons Charge
              </Button>
            </Grid.Column>
            <DataTable
              ref={this.child}
              getApiName="GETADDONS"
              className="grid "
              searchOption={{ show: true, placeHolder: "Search Add-Ons" }}
              getGridDataParamters={{ isActive: true }}
              gridName="addOnsGrid"
              screenName="add-ons"
              fullState={fullState}
              allFunction={allFunction}
              tableHeight="250px"
              emptyGrid="No records to show"
              columns={[
                {
                  headerName: "",
                  fieldName: "selected",
                  isSorting: false,
                  Cell: (props) => {
                    return (
                      <Checkbox
                        value={
                          this.state.selectedList.filter(
                            (Detail) => Detail.addOnId === props.addOnId
                          ).length > 0
                        }
                        className="custome-checkbox"
                        onChange={(e, { checked }) =>
                          this.handleChange(e, { checked }, props)
                        }
                      />
                    );
                  },
                },
                {
                  headerName: "Title",
                  fieldName: "addOnTitle",
                  isSorting: true,
                  className: "textOverflowHidden",
                  Cell: (props) => {
                    return (
                      <span title={props.addOnTitle}>{props.addOnTitle}</span>
                    );
                  },
                },
                {
                  headerName: "Price",
                  fieldName: "addOnPrice",
                  isSorting: true,
                  headerClass: "textRight",
                  className: "textRight",
                  Cell: (props) => {
                    return (
                      <Table.Cell textAlign="right">
                        {" "}
                        {props.addOnPrice === 0
                          ? "-"
                          : commonFunctions.isNumberInteger(props.addOnPrice)}
                      </Table.Cell>
                    );
                  },
                },
                {
                  headerName: "Qty",
                  fieldName: "Qty",
                  isSorting: true,
                  Cell: (props, index) => {
                    return (
                      <Input
                        value={props.Qty}
                        className="bordered-input text-center-input"
                        name="Qty"
                        pattern="[0-9]*"
                        fluid
                        onChange={(e, { name, value, type, checked }) =>
                          this.handleChangeQty(
                            e,
                            { name, value, type, checked },
                            props
                          )
                        }
                      />
                    );
                  },
                },
              ]}
            />
            <CreateAddOnsModal
              openModal={openAddOnsModal}
              addOnsForm={addOnsForm}
              validator={this.validator}
              closeModal={this.handleOpenCreateAddOnsModal}
              onHandleChange={this.onHandleChange}
              AddOnsLoader={AddOnsLoader}
              handleCreateAddOnsModal={this.handleCreateAddOnsModal}
            />
            <Grid.Column width={16} textAlign="right">
              <Button className="blue-btn" onClick={() => this.handleClose()}>
                Cancel
              </Button>
              <Button
                className="orange-btn"
                loading={onSaving}
                disabled={selectedList.length === 0 ? true : false}
                onClick={() => this.handleSubmit()}
              >
                Save
              </Button>
            </Grid.Column>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
    orderState: state.orderState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      addFeePayment: bindActionCreators(actions.addFeePayment, dispatch),
      getOrderItems: bindActionCreators(actions.getOrderItems, dispatch),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddOnsModal);
