import React from 'react';
import { Card, Feed } from 'semantic-ui-react';
import { PropTypes } from 'prop-types';

const SaleBox = ({ percentage, price, cardType, color }) => {
                                                              
    return (
        <Card>
            <Card.Content>
                <Feed >
                    <Feed.Event className="rental"><span style={{ backgroundColor: color }}>{percentage}%</span><Feed.Content>
                        <Feed.Date content={cardType} />
                        <Feed.Summary style={{ color: color }}>${price}</Feed.Summary>
                    </Feed.Content>
                    </Feed.Event>
                </Feed>
            </Card.Content>
        </Card>
    );
};

SaleBox.propTypes = {

    percentage: [PropTypes.element.isRequired, PropTypes.string],
    price: [PropTypes.element.isRequired, PropTypes.string],
    cardType: [PropTypes.element.isRequired, PropTypes.string]
};

export default SaleBox;
