

import React, { Component } from "react";
import { Grid,  Button } from "semantic-ui-react";
import { commonFunctions } from "../../../functional/global-import";
import { ChromePicker } from "react-color";

class CommonChooseColor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayColorPicker: false
        }
    }

    onHandleChangeColor = (selectedColor) => {   
        const { fullState, stateForm, allFunction, variableName } = this.props;
        fullState[stateForm][variableName] = selectedColor.hex
        allFunction.setState({ ...fullState });
        commonFunctions.onFormChange(allFunction);
    }

    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker });
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false });
    };

    render() {
        const { fullState, stateForm, variableName } = this.props;
       
        const popover = { position: "absolute", zIndex: "2" };
        const cover = { position: "fixed", top: "0px", right: "0px", bottom: "0px", left: "0px" };
                                  
        return (
            <React.Fragment>
                <Grid.Column width={6} verticalAlign="middle">
                    <div>
                        <Button className="orange-btn" onClick={this.handleClick}>
                            Choose Calendar color
                        </Button>
                        {this.state.displayColorPicker ? (
                            <div style={popover}>
                                <div style={cover} onClick={this.handleClose} />
                                <ChromePicker color={fullState[stateForm][variableName]} onChangeComplete={this.onHandleChangeColor} />
                            </div>) : null}
                    </div>
                </Grid.Column>
                <Grid.Column width={6} verticalAlign="middle">
                    {fullState[stateForm][variableName] !== "" ? <div className="colorShow"
                     style={{ backgroundColor: fullState[stateForm][variableName] }}></div> : ""}
                </Grid.Column>
            </React.Fragment>
        )
    }
}
export default CommonChooseColor;