import React, { Component } from "react";
import { Icon, Checkbox } from "semantic-ui-react";
import { commonFunctions } from "../../../shared/functional/global-import";
import { DataTable } from "../../../shared/components/organisms";

class DocumentGrid extends Component {
  childsChild = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      gridDetail: [],
    };
    this.validator = commonFunctions.initializeSimpleValidator();
  }
  render() {
    const { fullState, allFunction } = this.props;
    return (
      <DataTable
        gridName="documentGrid"
        screenName="documents"
        ref={this.childsChild}
        getApiName="GETDOCUMENT"
        searchOption={{ show: true, placeHolder: "Search document" }}
        fullState={this.state}
        allFunction={this}
        tableHeight="250px"
        emptyGrid="No records to show"
        columns={[
          {
            headerName: "Document Name",
            fieldName: "documentName",
            isSorting: true,
            className: "textOverflowHidden",
            Cell: (props) => {
              return (
                <span
                  className="document-grid-color"
                  title={props.documentName}
                >
                  {props.documentName}
                </span>
              );
            },
          },
          {
            headerName: "Document File",
            fieldName: "documentFile",
            className: "textOverflowHidden",
            isSorting: true,
            Cell: (props) => {
              return (
                <a
                  target="_blank"
                  title={props.documentFile}
                  className="orange-color"
                  href={commonFunctions.concatenateImageWithAPIUrl(props.image)}
                >
                  {props.documentFile}
                </a>
              );
            },
          },
          {
            headerName: "Default to all categories",
            fieldName: "documentAsDefaultToAllProduct",
            isSorting: true,
            Cell: (props) => {
              return (
                <div textAlign="center">
                  {props.documentAsDefaultToAllProduct === false ? "NO" : "YES"}
                </div>
              );
            },
          },
          {
            headerName: "Assigned to",
            fieldName: "asignTo",
            isSorting: true,
          },
          {
            headerName: "Active",
            fieldName: "IsActive",
            isSorting: true,
            Cell: (props) => {
              return (
                <Checkbox
                  className="custom-toggle"
                  checked={props.isActive}
                  onClick={(data, r) =>
                    commonFunctions.activateDeactivateToggle(
                      allFunction,
                      props.documentName,
                      props.documentId,
                      "documentId",
                      props.isActive
                    )
                  }
                ></Checkbox>
              );
            },
          },
          {
            headerName: "Action",
            fieldName: "action",
            isSorting: false,
            Cell: (props) => {
              return (
                <React.Fragment>
                  <span>
                    <Icon
                      name="linkify"
                      size="large"
                      disabled={!props.onlineSignature}
                      className={
                        allFunction.state.copyDocumentlink === props.documentId
                          ? "maroon-color"
                          : "orange-color"
                      }
                      // title="Copied Link"
                      title={
                        allFunction.state.copyDocumentlink === props.documentId
                          ? "Copied Link"
                          : "Copy Link"
                      }
                      onClick={() =>
                        allFunction.onChangeCopyLink(props.documentId)
                      }
                    />
                  </span>
                  <Icon
                    name="pencil alternate"
                    size="large"
                    title="Edit"
                    className="deepviolet-color pointer"
                    onClick={() => allFunction.editDocument(props)}
                  />
                  <Icon
                    name="trash alternate"
                    size="large"
                    className="orange-color pointer"
                    title="Delete"
                    link
                    onClick={() =>
                      commonFunctions.deleteModalMessage(
                        allFunction,
                        props.documentName,
                        props.documentId,
                        "documentId"
                      )
                    }
                  />
                </React.Fragment>
              );
            },
          },
        ]}
      />
    );
  }
}
export default DocumentGrid;
