import {
  actions,
  commonFunctions,
} from "../../shared/functional/global-import";
import { SET_CUSTOMERS, DELETE_CUSTOMER } from "../types/customer.types";

// const dataToGetAllCustomers = {
//   BusinessId: 1,
//   CustomerId: -1,
//   PageNo: 1,
//   PageSize: 100,
//   SearchValue: "",
// };

function setCustomers(customers) {
  return {
    type: SET_CUSTOMERS,
    payload: customers,
  };
}
export function loadCustomers(CutstomerDetailList) {
  return async (dispatch) => {
    dispatch(
      actions.apiCall({
        urls: ["GETCUSTOMER"],
        method: "GET",
        data: CutstomerDetailList,
        onSuccess: (response) => {
          if (response !== null) {
            dispatch(setCustomers(response));
            return response;
          } else {
            // change this alert msg to error message
            alert("Something Went Wrong in loadCustomers!!");
          }
        },
      })
    );
  };
}
function removeCustomer(customerId) {
  return {
    type: DELETE_CUSTOMER,
    payload: customerId,
  };
}

export function deleteCustomer(customerId) {
  return async (dispatch) => {
    dispatch(
      actions.apiCall({
        urls: ["DELETECUSTOMER"],
        method: "DELETE",
        data: {
          CustomerId: customerId,
          DeletedBy: "admin",
        },
        onSuccess: (response) => {
          if (response !== null) {
            dispatch(removeCustomer(customerId));
          } else {
            // change this alert msg to error message
            alert("Something Went Wrong in deleteCustomer!!");
          }
        },
        showNotification: true,
      })
    );
  };
}

export function createCustomer(data, dataToGet, allState) {
  let CustomFields = JSON.stringify(data?.CustomFieldDisplay);
  data.CustomField = CustomFields;
  const formdata = commonFunctions.getFormData(data);
  return async (dispatch) => {
    dispatch(
      actions.apiCall({
        urls: ["ADDCUSTOMER"],
        method: "POST",
        data: formdata,
        onSuccess: (response) => {
          if (response !== null) {
            allState?.onCustomerCreation(response);
            dispatch(loadCustomers(dataToGet));
            return response;
          } else {
            // change this alert msg to error message
            alert("Something Went Wrong in Create Customer Process!!");
          }
        },
        isFormData: true,
        showNotification: true,
      })
    );
  };
}
export function createUpdateCustomer(data, dataToGet, allState) {
  let CustomFields = JSON.stringify(data.CustomFieldDisplay);
  data.CustomField = CustomFields;
  return async (dispatch) => {
    dispatch(
      actions.apiCall({
        urls: ["UPDATECUSTOMER"],
        method: "PUT",
        data: data,
        onSuccess: (response) => {
          if (response !== null) {
            allState?.onCustomerCreation(response);
            dispatch(loadCustomers(dataToGet));
            return response;
          } else {
            // change this alert msg to error message
            alert("Something Went Wrong in Create Customer Process!!");
          }
        },
        isFormData: true,
        showNotification: true,
      })
    );
  };
}
