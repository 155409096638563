import React from 'react';
import ContentLoader from "react-content-loader";



const OrderStatusCountSkeleton = (props) => {
  return (
    <ContentLoader height={100} width={1300}{...props}>
      <rect x="10" y="10" rx="8" ry="8" width="150" height="80" />
      <rect x="170" y="10" rx="8" ry="8" width="150" height="80" />
      <rect x="330" y="10" rx="8" ry="8" width="150" height="80" />
      <rect x="490" y="10" rx="8" ry="8" width="150" height="80" />
      <rect x="650" y="10" rx="8" ry="8" width="150" height="80" />
      {/* <rect x="810" y="10" rx="8" ry="8" width="150" height="80" />
      <rect x="910" y="250" rx="0" ry="0" width="200" height="18" /> */}
    </ContentLoader>
  );
};
OrderStatusCountSkeleton.propTypes = {

};


export default OrderStatusCountSkeleton;
