import React, { Component } from "react";
import {
  Button,
  Header,
  Modal,
  Grid,
  Form,
  Loader,
  Dimmer,
} from "semantic-ui-react";
import CommonCropImage from "../../../atoms/common-crop-image";
import {
  connect,
  bindActionCreators,
  actions,
  commonFunctions,
  Notifications,
} from "../../../../functional/global-import";
import { withRouter } from "react-router-dom";
class AddCategoryInventoryModal extends Component {
  get initialState() {
    return {
      addCategory: {
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        businessCategoryId: this.props.addBusinessDetail.businessCategoryId,
        title: "",
        ActionPerformedBy: this.props.auth.userDetail.emailId,
      },
    };
  }
  constructor(props) {
    super(props);
    this.state = {
      ...this.initialState,
      isImageUpdated: false,
      addOrEdit: this.props.addOrEdit,
      defaultcategoryId: "",
      fileList: [],
      imageData: [],
      categoryImageId: "",
    };
    this.validator = commonFunctions.initializeSimpleValidator();
  }

  onHandleChange = (e, { name, value, type, checked, data }) => {
    var addCategory = commonFunctions.onHandleChange(
      e,
      { name, value, type, checked, data },
      this.state.addCategory
    );
    this.setState({ addCategory });
  };

  onHandleSubmit = (e) => {
    var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
    if (isFormValid) {
      var FormData = commonFunctions.getFormData(this.state.addCategory);
      this.props.actions.apiCall({
        urls: ["UPDATEMASTERCATEGORY"],
        method: "PUT",
        data: FormData,
        onSuccess: (response) => {
          this.onCloseModal();
          this.props.getBusinessType(
            this.props.global.locationSelected.BusinessLocationId
          );
          this.props.actions.storeInventoryDetail({});
        },
        isFormData: true,
        showNotification: true,
      });
    }
  };
  getCategoryDetail = () => {
                       
    const { addBusinessDetail } = this.props;
    const imageData = JSON.parse(addBusinessDetail.image);
    const imageDetail = [
      {
        uid: "2",
        status: "done",
        url:
          imageData === null
            ? commonFunctions.concatenateImageWithAPIUrl(null)
            : commonFunctions.concatenateImageWithAPIUrl(
                imageData[0].ImageFile
              ),
      },
    ];
    this.setState(() => ({
      fileList: addBusinessDetail.isDefaultImage ? [] : imageDetail,
      imageData,
      categoryImageId: addBusinessDetail.businessCategoryId,
      ImageId: imageData === null ? null : imageData[0].ImageId,
      addCategory: {
        ...this.state.addCategory,
        title: addBusinessDetail.description,
      },
    }));
  };
  componentDidMount = () => {
    this.getCategoryDetail();
  };

  onImageChange = (imageList) => {
    this.setState(() => ({
      addCategory: { ...this.state.addCategory, image: imageList },
      isImageUpdated: true,
    }));
  };
  onImageRemove = () => {
    this.setState(() => ({
      addCategory: { ...this.state.addCategory, image: [] },
      isImageUpdated: true,
    }));
  };

  onCloseModal = () => {
    this.props.closeModal();
    this.setState({
      defaultcategoryId: null,
      fileList: [],
      imageData: [],
      image: [],
    });
    this.setState(this.initialState);
  };

  render() {
    const { api, addBusinessDetail } = this.props;
    const { addCategory } = this.state;
    const [addCatergoryImageId] = JSON.parse(addBusinessDetail.image);
    return (
      <Modal
        open={this.props.openModal}
        closeIcon
        onClose={this.onCloseModal}
        closeOnDimmerClick={false}
        size={"tiny"}
      >
        <Header content={`${addBusinessDetail.businessCategoryName}`} />
        <Modal.Content>
          {this.props.editCategoryModal && api.isApiLoading && (
            <Dimmer active inverted>
              <Loader />
            </Dimmer>
          )}
          <Grid>
            <Grid.Column mobile={12} tablet={7} computer={6} textAlign="center">
              <CommonCropImage
                ImageType={"MasterCatergoryImage"}
                fullState={this.state}
                cropId={addCatergoryImageId.ImageId}
                StateRef={this}
                stateForm={this.state.addCategory}
                masterCatergoryImage={true}
                businessLocationId={
                  this.props.global.locationSelected.BusinessLocationId
                }
                addBusinessDetail={addBusinessDetail}
                isDefaultImage={addBusinessDetail.isDefaultImage}
                businessCategoryId={addCatergoryImageId.ImageId}
              />
            </Grid.Column>

            <Grid.Column mobile={12} tablet={9} computer={10}>
              <label>
                <b>Title</b>
              </label>
              <Form>
                <Form.Input
                  fluid
                  id="form-subcomponent-shorthand-input-first-name"
                  placeholder="Name"
                  value={addCategory.title}
                  name="txtcategoryName"
                  data="title"
                  error={this.validator.message(
                    "title",
                    addCategory.title,
                    "required||max:100"
                  )}
                  onChange={this.onHandleChange}
                />
              </Form>
              <br />
            </Grid.Column>
            <Grid.Column width={16}>
              <Button
                className="orange-btn"
                floated="right"
                onClick={this.onHandleSubmit}
                loading={api.isApiLoading}
              >
                {" "}
                Update{" "}
              </Button>
              <Button
                className="blue-btn"
                floated="right"
                onClick={this.onCloseModal}
              >
                {" "}
                Cancel{" "}
              </Button>
            </Grid.Column>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AddCategoryInventoryModal));
