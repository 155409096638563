import React, { Component } from "react";
import { Icon, Checkbox } from "semantic-ui-react";
import { commonFunctions, } from "../../../shared/functional/global-import";
import { DataTable } from '../../../shared/components/organisms';

class HelpCenterGrid extends Component {
  childsChild = React.createRef();
  render() {
    const { fullState, allFunction } = this.props;
    return (
      <DataTable
        ref={this.childsChild}
        getApiName="GETFAQLIST"
        // searchOption={{ show: true, placeHolder: "Search Tag" }}
        // defaultSort={{sortColumn:"modifiedDate",sortOrder:"desc"}}
        // defaultPageSize={10}
        getGridDataParamters={{ "faqCategoryId": fullState.faqCategoryId , "applicationId": fullState.applicationId}}
        gridName="HelpCenterGrid"
        screenName="help-center"
        fullState={fullState}
        allFunction={allFunction}
        tableHeight="250px"
        emptyGrid="No records to show"
        columns={[
          {
            headerName: "Category",
            fieldName: "category",
            isSorting: true,
          },
          {
            headerName: "Question",
            fieldName: "question",
            isSorting: true,
            className: "textOverflowHidden",
            Cell: (props) => {
              return (
                <div title={props.question}>{props.question}</div>
              );
            },
          },
          {
            headerName: "Answer",
            fieldName: "answer",
            isSorting: true,
            className: "textOverflowHidden",
            Cell: (props) => {
              return (
                <div title={props.answer && JSON.parse(props.answer).blocks[0].text} className="textOverflowHidden">{props.answer && JSON.parse(props.answer).blocks[0].text}</div>
              );
            },
          },
          {
            headerName: "Active",
            fieldName: "isActive",
            isSorting: true,
            Cell: (props) => {
              return (
                <Checkbox
                  className="custom-toggle"
                  checked={props.isActive}
                  onClick={(data, r) =>
                    commonFunctions.activateDeactivateToggle(allFunction, props.category, props.faqId, "faqId", props.isActive)
                  }
                ></Checkbox>
              );
            },
          },
          {
            headerName: "Action",
            fieldName: "action",
            isSorting: false,
            Cell: (props) => {
              
              return (
                <React.Fragment>
                  <Icon
                    name="pencil alternate"
                    size="large"
                    className="deepviolet-color pointer"
                    title="Edit"
                    onClick={() => allFunction.faqUpdate(props)}
                  />
                  <Icon
                    name="trash alternate"
                    size="large"
                    className="orange-color pointer"
                    title="Delete"
                    link
                    onClick={() => commonFunctions.deleteModalMessage(allFunction, props.question, props.faqId, "faqId")}
                  />
                </React.Fragment>
              );
            },
          },
        ]}
      />
    );
  }
}
export default HelpCenterGrid;
