import React, { Component } from "react";
import { Grid, Form, Divider, Header, Button, Table, Icon } from "semantic-ui-react";
import { connect, bindActionCreators, actions, Notifications, commonFunctions } from "../../../functional/global-import";
import AddBlackOutDates from "../../../components/organisms/modal/add-blackout-dates/index"
import Moment from "react-moment";
// import { commonFunctions } from "../../../functional/global-import";
class CommonBlackOutDates extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blackOutDatesList: [],
            blackOutDateStatus: "",
            blackOutTitle: "",
            blackOutId: "",
            commonBlackOut: false,
            BlackOutStartDate: "",
            BlackOutEndDate: ""
        }
    }
    blackOutDateModalModal = () => {
        this.setState({
            blackOutDateStatus: !this.state.blackOutDateStatus,
        });
        this.getBlackOutDateList();
    };

    getBlackOutDateList = () => {
        this.props.actions.apiCall({
            urls: ["GETBLACKOUTDATE"],
            method: "GET",
            data: {
                businessId: this.props.global.bussinessDetail.businessId, blackOutDateId: -1, InDiscountAndCoupons: this.props.InDiscountAndCoupons === true ? true : false,
                businessLocationId: this.props.global.locationSelected.BusinessLocationId, PageNo: 1, PageSize: 100
            },
            onSuccess: (response) => {
                const getBlackOut = response.map((singleBlackOut) => {

                    return { value: singleBlackOut.blackOutDateId, text: singleBlackOut.title, BlackOutStartDate: singleBlackOut.blackoutStartDate, BlackOutEndDate: singleBlackOut.blackOutEndDate }
                });
                this.setState({ blackOutDatesList: getBlackOut });
            },
        });
    };
    componentDidMount() {
        this.getBlackOutDateList();
    }
    addBlackOutDates = (e) => {
        const { fullState, stateForm, allFunction, variableName } = this.props;
        const { blackOutTitle, blackOutId, BlackOutStartDate, BlackOutEndDate } = this.state;
        if (blackOutTitle !== "" && blackOutId !== "") {
            const matchValue = fullState[stateForm][variableName];
            if (matchValue.indexOf(blackOutId) === -1) {
                const rows = fullState.blackOutRows.blackoutDates;
                rows.push({
                    blackOutTitle: blackOutTitle, blackOutId: blackOutId,
                    BlackOutStartDate: BlackOutStartDate,
                    BlackOutEndDate: BlackOutEndDate
                });
                const blackOutDateAdd = fullState[stateForm][variableName];
                blackOutDateAdd.push(blackOutId);

                allFunction.setState({ ...fullState });
                commonFunctions.onFormChange(allFunction);
            }
            else {
                allFunction.props.actions.showNotification({
                    title: "Warning",
                    message: "This blackout date is already selected.", position: 'br', autoDismiss: commonFunctions.notificationTime()
                }, "error");
            }
        }
        else {
            allFunction.props.actions.showNotification({
                title: "Warning",
                message: "Please select any blackout date.", position: 'br', autoDismiss: commonFunctions.notificationTime()
            }, "error");
        }
        this.setState({ blackOutId: "" })
    }
    onHandleBlackOutDates = (e, data) => {
        const { value } = data;
        const key = data.options.find(o => o.value === value);
        this.setState({
            blackOutTitle: key.text, blackOutId: key.value,
            BlackOutStartDate: key.BlackOutStartDate,
            BlackOutEndDate: key.BlackOutEndDate
        })
    }

    deleteBlackOutDates = (i) => {
        const { fullState, stateForm, allFunction, variableName } = this.props;
        const rows = fullState.blackOutRows.blackoutDates;
        rows.splice(i, 1);
        const rr = fullState[stateForm][variableName];
        rr.splice(i, 1);
        allFunction.setState({ ...fullState });
         commonFunctions.onFormChange(allFunction);
    }

    render() {
        const { fullState } = this.props;
        return (
            <React.Fragment>
                <Grid.Column width={16}>
                    <Divider />
                </Grid.Column>
                <Grid.Column width={16}>
                    <Header as="h4"> Blackout Dates</Header>
                </Grid.Column>
                <Grid.Column width={8}>
                    <Form.Select placeholder="Select Blackout Date" search className="custom-select" options={this.state.blackOutDatesList}
                        fluid onChange={this.onHandleBlackOutDates} type="select" value={this.state.blackOutId} />
                </Grid.Column>
                <Grid.Column width={8} textAlign="right">
                    <Button className="orange-btn" onClick={this.addBlackOutDates} >Add</Button>
                    <Button className="orange-btn" onClick={this.blackOutDateModalModal}>
                        <Icon name="plus" />
                        Select Blackout Dates
                    </Button>
                </Grid.Column>
                <Grid.Column width={16}>
                    {fullState.blackOutRows.blackoutDates.length > 0 && <Table singleLine>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>
                                    Title
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Dates
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign="right">
                                    Action
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {fullState.blackOutRows.blackoutDates && fullState.blackOutRows.blackoutDates.map((singleDate, i) => {

                                return (
                                    <Table.Row>
                                        <Table.Cell title={singleDate.blackOutTitle} className="textOverflowHidden">{singleDate.blackOutTitle}</Table.Cell>
                                        <Table.Cell><Moment format="MMM DD,YYYY">{singleDate.BlackOutStartDate}</Moment>{"-"}<Moment format="MMM DD,YYYY">{singleDate.BlackOutEndDate}</Moment></Table.Cell>
                                        <Table.Cell textAlign="right">
                                            <Icon name="trash alternate" size="large" className="orange-color" title="Delete" link onClick={() => this.deleteBlackOutDates(i)} />
                                        </Table.Cell>
                                    </Table.Row>)
                            })}
                        </Table.Body>

                    </Table>}
                </Grid.Column>
                {this.state.blackOutDateStatus &&
                    <AddBlackOutDates getBlackOutDateList={this.getBlackOutDateList}
                        commonBlackOut={this.state.commonBlackOut}
                        openModal={this.state.blackOutDateStatus}
                        closeModal={this.blackOutDateModalModal}
                    // getBlackOutDateList={ this.child.current.getDefaultData()} .
                    />}
            </React.Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        api: state.api,
        global: state.global,
        auth: state.auth
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            apiCall: bindActionCreators(actions.apiCall, dispatch),
            storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
            showNotification: bindActionCreators(Notifications.show, dispatch),
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CommonBlackOutDates);