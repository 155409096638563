import React, { Component } from "react";
import { Grid, Button } from "semantic-ui-react";
import {
  commonFunctions,
  connect,
  bindActionCreators,
  actions,
  Notifications,
} from "../../../../functional/global-import";
import CommonImage from "../../../atoms/common-image";

class UploadRetailImages extends Component {
  constructor(props) {
    super(props);
  }
  backStep = (e) => {
    e.preventDefault();
    const { fullState } = this.props;
    if (
      (this.props.uploadRetailImage.images[0] !== undefined &&
        this.props.uploadRetailImage.DefaultImage[0] !== undefined) ||
      (this.props.uploadRetailImage.images[0] === undefined &&
        this.props.uploadRetailImage.DefaultImage[0] === undefined)
    ) {
      this.props.onPrev(this.props.back);
    } else if (
      this.props.uploadRetailImage.images[0] !== undefined &&
      this.props.uploadRetailImage.DefaultImage[0] === undefined
    ) {
      this.props.actions.showNotification(
        {
          title: "Error",
          message: "Please select default image.",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    }
  };
  changeStep = () => {
    const { fullState } = this.props;
    // if (this.props.fullState.uploadRetailImage.images.length === 1) {
    //
    //   let data = this.props.fullState.uploadRetailImage.images
    //   fullState.uploadRetailImage["DefaultImage"] = data
    //   this.setState({ fullState })
    // }
    if (
      (this.props.uploadRetailImage.images[0] !== undefined &&
        this.props.uploadRetailImage.DefaultImage[0] !== undefined &&
        this.props.uploadRetailImage.images.length <= 10) ||
      (this.props.uploadRetailImage.images[0] === undefined &&
        this.props.uploadRetailImage.DefaultImage[0] === undefined &&
        this.props.uploadRetailImage.images.length <= 10)
    ) {
      this.props.allFunction.changeStep(3);
      // this.props.allFunction.changedNext();
    } else if (
      this.props.uploadRetailImage.images[0] !== undefined &&
      this.props.uploadRetailImage.DefaultImage[0] === undefined
    ) {
      this.props.actions.showNotification(
        {
          title: "Error",
          message: "Please select default image.",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    } else {
      this.props.actions.showNotification(
        {
          title: "Error",
          message: "Maximum 10 images allowed to upload.",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    }
  };
  render() {
    const {
      api,
      changedNext,
      changeEditNext,
      secondStepCompleted,
      allFunction,
    } = this.props;
    // const { api, changedNext, changeEditNext, secondStepCompleted, allFunction } = this.props;
    // const maxNumber = 69;
    return (
      <React.Fragment>
        <Grid>
          <Grid.Column
            mobile={16}
            tablet={16}
            computer={16}
            textAlign="center"
            className="controlField"
          >
            <CommonImage
              uploadRetailImage={this.props.uploadRetailImage}
              imagelist={this.props.uploadRetailImage.images}
              onImageChange={this.props.onImageChange}
              onImageRemove={this.props.onImageRemoved}
              multiple={true}
              imageId={this.props.uploadRetailImage.imageId}
              defaultImage={this.props.uploadRetailImage.DefaultImage}
              isRadio={true}
              handleChangeDefaultImage={this.props.handleChangeDefaultImage}
              DefaultImageId={this.props.uploadRetailImage.DefaultImageId}
            />
          </Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column width={16} textAlign="right">
            <Button className="blue-btn" onClick={this.backStep}>
              Back
            </Button>
            <Button
              className="orange-btn"
              onClick={this.changeStep}
              loading={api.isApiLoading}
            >
              Continue
            </Button>
          </Grid.Column>
        </Grid>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UploadRetailImages);
