import React from 'react';
import { Modal, Grid, Button, Input } from 'semantic-ui-react';
import {commonFunctions} from "../../../../../../functional/global-import";

const CreateFeeModal = ({ openModal, closeModal, feeForm, handleFeeSubmit, onHandleChange, validator, feeModalLoader }) => {
    return (
        <Modal open={openModal} closeIcon onClose={closeModal} size={"mini"}>
            <Modal.Content scrolling>
                {feeModalLoader && commonFunctions.showLoader()}
                <Grid>
                    <Grid.Column width={16}>
                        <div class="field">
                            <label>Fee Title</label>
                            <Input
                                fluid
                                value={feeForm.feeTitle}
                                name="txtfeeTitle"
                                data="feeTitle"
                                error={validator.message("Title", feeForm.feeTitle, "required")}
                                onChange={(e, { name, value, type, checked, data }) => onHandleChange(e, { name, value, type, checked, data })}
                            />
                        </div>
                        <div class="field" style={{ 'paddingTop': "10px" }}>
                            <label>Amount</label>
                            <Input
                                label={{ basic: true, content: '$' }}
                                labelPosition='left'
                                pattern="[0-9]*"
                                name="txtfeePrice"
                                data="feePrice"
                                onChange={(e, { name, value, type, checked, data }) => onHandleChange(e, { name, value, type, checked, data })}
                                error={validator.message("amount", feeForm.feePrice, "required|numeric|min:0,num")}
                                value={feeForm.feePrice}
                                fluid
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Column width={16} textAlign="right">
                        <Button className="blue-btn" onClick={closeModal}> Cancel </Button>
                        <Button className="orange-btn" onClick={(e) => handleFeeSubmit(e)}> Save  </Button>
                    </Grid.Column>
                </Grid>
            </Modal.Content>
        </Modal>
    )
}

export default CreateFeeModal;
