import { Suspense, Component, lazy } from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import {
  NoTemplateRoutesConfing,
  AuthenticateTemplateRoutesConfing,
} from "./config/routes";
import PrivateRoute from "./shared/functional/private-route";
import ProtectedRoute from "./shared/functional/protected-routes";
import "./assets/scss/app.scss";
import "semantic-ui-css/semantic.min.css";
import { NotificationAlert } from "./shared/components";
import { commonFunctions } from "./shared/functional/global-import";
import {
  connect,
  bindActionCreators,
  actions,
  env,
} from "./shared/functional/global-import";
import AppContainer from "./app-container";
import {
  ElementsConsumer,
} from '@stripe/react-stripe-js';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { environment: env.NODE_ENV };

  }
  componentDidMount() {
    setTimeout(() => this.renderFunction(), 1000);
  }
  renderFunction = () => {
    const { environment } = this.state;
    if (environment === "development") {
      setTimeout(() => commonFunctions.iframeRemove(), 1000);
    }
  };
                        
  render() {
    return (
      <div className="app">
        <Router
          getUserConfirmation={() => {
            // getUserConfirmation={(message, callback) => {
            // const allowTransition = window.confirm(message);
            // callback(allowTransition);
          }}
        >
          
          <AppContainer>
            <Suspense fallback={<></>}>
              <Switch>
                {NoTemplateRoutesConfing.map((route, i) => {
                  const Component = route.component;
                  return route.private ? (
                    // handle private routes of the application   userTypes={route.userTypes}
                    <PrivateRoute
                      key={i}
                      exact={route.exact}
                      path={route.path}
                      render={(props) => <Component {...props} />}
                    />
                  ) : (
                    // handle public routes of the application
                    <Route
                      key={i}
                      exact={route.exact}
                      path={route.path}
                      render={(props) => <Component {...props} />}
                    />
                  );
                })}
                <Route
                  path={[
                    AuthenticateTemplateRoutesConfing.map((route, i) => {
                      return route.path;
                    }),
                  ]}
                  exact
                >
                  {this.props.auth.userDetail !== undefined && (
                    <ProtectedRoute
                      rolePermissionScreenList={
                        this.props.auth.userDetail.roleAndPermission
                      }
                    >
                      {" "}
                    </ProtectedRoute>
                  )}
                </Route>
                <Route
                  path={`/`}
                  exact={true}
                  component={lazy(() => import("./pages/login"))}
                />
              </Switch>
            </Suspense>
          </AppContainer>
        </Router>
        <NotificationAlert></NotificationAlert>
        
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      storeUserDetail: bindActionCreators(actions.storeUserDetail, dispatch),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
