import React, { Component } from "react";
import { Grid, Form, Divider, Header, Button, Table, Icon, Modal } from "semantic-ui-react";
import { connect, bindActionCreators, actions, Notifications, Link } from "../../../functional/global-import";
import TimeFrame from "../../../../pages/more-setting/time-frame-setting/time-frame";
import { commonFunctions } from "../../../functional/global-import";

class CommonAvailability extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      timeFrameList: [],
      availabilityStatus: "",
      TimeFrameTitle: "", timeframeId: "", Timing: "", timeFrameEventName: "", range: ""
    }
  }

  AvailabilityModal = () => {
    this.setState({
      availabilityStatus: !this.state.availabilityStatus,
    });
    this.getTimeFrameDetails();
  };

  getTimeFrameDetails = () => {
    this.props.actions.apiCall({
      urls: ["GETTIMEFRAMEDETAIL"],
      method: "GET",
      data: {
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId: this.props.global.locationSelected.BusinessLocationId,
        PageNo: 1, PageSize: 1000, isActive: true
      },
      onSuccess: (response) => {
        const timeFrame = response.map((singleTime) => {
          return {
            value: singleTime.timeFrameId,
            text: singleTime.timeFrameTitle,
            range: singleTime.range,
            timeFrameTitle: singleTime.timeFrameTitle,
            timeFrameEventName: singleTime.timeFrameEventName,
            timing: singleTime.timing
          }
        });
        this.setState({ timeFrameList: timeFrame });
      },
    });
  };
  componentDidMount() {
    this.getTimeFrameDetails();
  }
  onHandleNewAvalavilty = (e, data) => {
    const { value } = data;
    const key = data.options.find(o => o.value === value);
    this.setState({
      TimeFrameTitle: key.text, timeframeId: key.value, range: key.range, timeFrameEventName: key.timeFrameEventName, Timing: key.timing
    })
  }

  availability = (e) => {  
    const { TimeFrameTitle, timeframeId, Timing, timeFrameEventName, range } = this.state;
    const { fullState, stateForm, allFunction, variableName } = this.props;
    if (fullState[stateForm][variableName].length === 1) {
      allFunction.props.actions.showNotification({ title: "Warning", message: "Only one availability can be selected.", position: 'br', autoDismiss: commonFunctions.notificationTime() }, "error");
    }
    else {
      if (TimeFrameTitle !== "" && timeframeId !== "") {
        const matchValue = fullState[stateForm][variableName];
        if (matchValue.indexOf(timeframeId) === -1) {
          const rows = fullState.availability.availabilityNew;
          rows.push({ TimeFrameTitle: TimeFrameTitle, timeframeId: timeframeId, Timing: Timing, timeFrameEventName: timeFrameEventName, range: range });
          const productAvailability = fullState[stateForm][variableName];
          productAvailability.push(timeframeId);
          allFunction.setState({ ...fullState });
          commonFunctions.onFormChange(allFunction);
        }
        else {
          allFunction.props.actions.showNotification({ title: "Warning", message: "This availability is already selected.", position: 'br', autoDismiss: commonFunctions.notificationTime() }, "error");
        }
      }
      else {
        allFunction.props.actions.showNotification({ title: "Warning", message: "Please Select any availability.", position: 'br', autoDismiss: commonFunctions.notificationTime() }, "error");
      }
      this.setState({ timeframeId: "" })
    }

  }

  deleteAvailability = (e) => {
    const { fullState, stateForm, allFunction, variableName } = this.props;
    const rows = fullState.availability.availabilityNew;
    rows.splice(e, 1)
    const avail = fullState[stateForm][variableName];
    avail.splice(e, 1)
    allFunction.setState({ ...fullState });
    commonFunctions.onFormChange(allFunction);
  }

  render() {
    
    const { fullState, allFunction } = this.props;
    const availabilityError = fullState.availability.availabilityNew.length > 0 ? "36" : ""
    return (<React.Fragment>
      <Grid.Column width={16}>
        <Header as="h4">Availability</Header>
      </Grid.Column>
      <Grid.Column width={8}>
        <Form.Dropdown placeholder="Availability" search selection fluid options={this.state.timeFrameList}
          onChange={this.onHandleNewAvalavilty} type="select" className="custom-select" value={this.state.timeframeId}
          error={ allFunction.validatorGeneral.message("Availability", availabilityError, "required")}/>

      </Grid.Column>
      <Grid.Column width={8} textAlign="right">
        <Button className="orange-btn" onClick={this.availability} >Add</Button>
        <Button className="orange-btn" onClick={this.AvailabilityModal}>
          <Icon name="plus" />
          Add New Availability
        </Button>
      </Grid.Column>
      <Grid.Column width={16}>
        {fullState.availability.availabilityNew.length > 0 && <Table fixed>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell> Title </Table.HeaderCell>
              <Table.HeaderCell> Time Range  </Table.HeaderCell>
              <Table.HeaderCell> Range </Table.HeaderCell>
              <Table.HeaderCell> Timing </Table.HeaderCell>
              <Table.HeaderCell textAlign="right"> Action </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {fullState.availability.availabilityNew && fullState.availability.availabilityNew.map((singleDate, e) => {
              return (
                <Table.Row>
                  <Table.Cell title={singleDate.TimeFrameTitle} className="textOverflowHidden">{singleDate.TimeFrameTitle}</Table.Cell>
                  <Table.Cell title={singleDate.timeFrameEventName} className="textOverflowHidden">{singleDate.timeFrameEventName}</Table.Cell>
                  <Table.Cell>{singleDate.range}</Table.Cell>
                  <Table.Cell>{singleDate.Timing}</Table.Cell>
                  <Table.Cell textAlign="right">
                    <Icon name="trash alternate" size="large" className="orange-color" title="Delete" link onClick={() => this.deleteAvailability(e)} />
                  </Table.Cell>
                </Table.Row>)
            })}
          </Table.Body>
        </Table>}
      </Grid.Column>
      {(this.state.availabilityStatus &&
        <Modal open={this.state.availabilityStatus} closeIcon onClose={this.AvailabilityModal}
          size={"small"}>
          <Modal.Content scrolling>
            <TimeFrame openModal={this.state.availabilityStatus} closeModal={this.AvailabilityModal} />
          </Modal.Content>
        </Modal>
      )}
    </React.Fragment>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CommonAvailability);