import React, { Component } from 'react';
import { Form, Grid, Icon, Button, Modal } from 'semantic-ui-react';
import { connect, bindActionCreators, actions, commonFunctions } from "../../../functional/global-import";
// import { DataTable } from "../../organisms";
import CustomerQuestionGrid from "./customer-questions-grid"
import { CommonDeleteUpdateModal } from "../../../components";


class CustomerQuestions extends Component {
  child = React.createRef()
  get initialState() {
    return {
      customerQuestion: {
        businessId: this.props.global.bussinessDetail.businessId, businessLocationId: this.props.global.locationSelected.BusinessLocationId, customQuestionType: "", customQuestion: "", customQuestionId: "",
        customQuestionOption: [{ customQuestionOptionValue: "" }], actionPerformedBy: this.props.auth.userDetail.emailId
      },
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      ...this.initialState,
      multipleQuestionUI: [],
      singleQuestionUI: [],
      filteredGlobalCodes: [],
      gridDetail: [],
      isCommonDeleteUpdateModalOpen: false,
    }
    this.validator = commonFunctions.initializeSimpleValidator();
  }

  onHandleChangeRows = (e, { value, id, data }) => {

    const customFieldData = [...this.state.customerQuestion.customQuestionOption]
    this.state.customerQuestion.customQuestionOption[id][data] = value;
    this.setState({ customerQuestion: { ...this.state.customerQuestion, customQuestionOption: customFieldData } });
  }


  singleQuestionUI() {
    
    const { customerQuestion } = this.state;
    return customerQuestion.customQuestionOption && customerQuestion.customQuestionOption.map((el, i) => (

      <Grid.Column width={16} key={i}>
        {customerQuestion.customQuestionType && (customerQuestion.customQuestionType === 164 && <Grid>
          <Grid.Column width={1} verticalAlign="middle">
            <Form.Radio className="custom-Form.Radio-btn" disabled />
          </Grid.Column>
          <Grid.Column width={11}>
            <Form.Input fluid placeholder="Label" error={this.validator.message("oneLabel", el.customQuestionOptionValue, "required")} id={i} data="customQuestionOptionValue" value={el.customQuestionOptionValue} onChange={this.onHandleChangeRows} />
          </Grid.Column>
          {i !== 0 &&
            <Grid.Column width={2} verticalAlign="middle" textAlign="center">
              <Icon link
                name="trash alternate"
                className="orange-color"
                onClick={() => this.handleRemoveSpecificRow(i)}
                size="big" />
            </Grid.Column>}
          {customerQuestion.customQuestionOption.length - 1 === i &&
            <Grid.Column width={2} verticalAlign="middle" textAlign="center">
              <Button icon className="orange-button" onClick={this.addSingleQuesRow} title="Add Another">
                <Icon name="plus" />
              </Button>
            </Grid.Column>}
        </Grid>)}

        {customerQuestion.customQuestionType && (customerQuestion.customQuestionType === 165 && <Grid>
          <Grid.Column width={1} verticalAlign="middle">
            <Form.Checkbox className="custome-Form.Checkbox" disabled />
          </Grid.Column>
          <Grid.Column width={11}>
            <Form.Input fluid placeholder="Label" id={i} data="customQuestionOptionValue" error={this.validator.message("oneLabel", el.customQuestionOptionValue, "required")} value={el.customQuestionOptionValue} onChange={this.onHandleChangeRows} />
          </Grid.Column>
          {i !== 0 &&
            <Grid.Column width={2} verticalAlign="middle" textAlign="center">
              <Icon link name="trash alternate" className="orange-color" size="big" onClick={() => this.handleRemoveSpecificRow(i)} />
            </Grid.Column>}
          {customerQuestion.customQuestionOption.length - 1 === i &&
            <Grid.Column width={2} verticalAlign="middle" textAlign="center">
              <Button icon className="orange-button" onClick={this.addSingleQuesRow} title="Add Another">
                <Icon name="plus" />
              </Button>
            </Grid.Column>}
        </Grid>)}
      </Grid.Column>
    ));
  }

  handleRemoveSpecificRow = (index) => {
    const rows = [...this.state.customerQuestion.customQuestionOption];
    rows.splice(index, 1)
    this.setState({ customerQuestion: { ...this.state.customerQuestion, customQuestionOption: rows } });
  }

  addSingleQuesRow = () => {
    const arr = [...this.state.customerQuestion.customQuestionOption];
    arr.push({ customQuestionOptionValue: "" });
    this.setState({ customerQuestion: { ...this.state.customerQuestion, customQuestionOption: arr } });
  }

  onHandleChange = (e, { name, value, type, checked, data }) => {
    if (data === "customQuestionType" && value === 166) {
      const { customerQuestion } = this.state;
      customerQuestion.customQuestion = ""
      this.setState({ customerQuestion });
      customerQuestion.customQuestionOption = [{ customQuestionOptionValue: "Yes" }, { customQuestionOptionValue: "No" }];
      this.setState(() => ({ customerQuestion: { ...this.state.customerQuestion, customQuestionOption: customerQuestion.customQuestionOption } }), () => this.child.current.childsChild.current.getDefaultData());
    }
    else if (data === "customQuestionType") {
      
      const { customerQuestion } = this.state;
      customerQuestion.customQuestion = ""
      this.setState({ customerQuestion });
      customerQuestion.customQuestionOption = this.initialState.customerQuestion.customQuestionOption;
      this.setState(() => ({ customerQuestion: { ...this.state.customerQuestion, customQuestionOption: customerQuestion.customQuestionOption } }), () => this.child.current.childsChild.current.getDefaultData());

    }
    var customerQuestion = commonFunctions.onHandleChange(e, { name, value, type, checked, data }, this.state.customerQuestion);
    this.setState({ customerQuestion });
  };
  // getcustomerQuestion = () => {
  //   this.props.actions.apiCall({
  //     urls: ["GETCUSTOMQUESTION"], method: "GET", data: this.state.customerQuestion, onSuccess: (response) => {
  //       
  //     }
  //   });
  // }
  getcustomerQuestion = (value) => {
    const { customerQuestionList } = this.state;
    this.props.actions.apiCall({
      urls: ["GETCUSTOMQUESTION"],
      method: "GET",
      data: {
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId: this.props.global.locationSelected.BusinessLocationId,
        PageNo: 1, PageSize: 100, customQuestionId: -1, customQuestionType: value
      },
      onSuccess: (response) => {
        this.setState({ customerQuestionList: response })
        // // const customerQuestion = response.map((singleQuestion) => {
        // //     return { value: singleQuestion.customQuestionId, text: singleQuestion.customQuestion, customQuestionOptionValue: JSON.parse(singleQuestion.customQuestionOptionValue) }
        // // })
        // this.setState({ customerQuestions: customerQuestion })
      },
    });
  }

  onHandleSubmit = (e) => {
    var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
    if (isFormValid) {
      this.props.actions.apiCall({
        urls: ["POSTCUSTOMERQUESTION"],
        method: "POST",
        data: this.state.customerQuestion,
        onSuccess: (response) => {
          this.child.current.childsChild.current.getDefaultData();
          this.setState(this.initialState);
        },
        showNotification: true,
      });
      this.validator = commonFunctions.initializeSimpleValidator();
    }
    // const { allFunction } = this.props;
    // allFunction.getcustomerQuestion(this.state.customerQuestion.customQuestionType);
  }

  customerQuestionsUpdate = (selectedEditRow) => {
    
    this.state.customerQuestion = selectedEditRow
    const option = JSON.parse(selectedEditRow.customQuestionOptionValue).map((x) => x.CustomQuestionOptionValue);
    let data = [];
    option.forEach(element => {
      let myobj = { customQuestionOptionValue: element };
      data.push(myobj)
    });
    this.setState({
      ...this.state, customerQuestion: {
        ...this.state.customerQuestion, actionPerformedBy:
          this.props.auth.userDetail.emailId, businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId: this.props.global.locationSelected.BusinessLocationId, customQuestionOption: data,
      },
    },
      // () => this.getEditFaq(selectedEditRow.faqId)
    );

  }
  onHandleUpdate = (e) => {
    
    var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
    if (isFormValid) {
      this.props.actions.apiCall({
        urls: ["UPDATECUSTOMERQUESTION"],
        method: "PUT",
        data: this.state.customerQuestion,
        onSuccess: (response) => {
          this.child.current.childsChild.current.getDefaultData();
          this.setState(this.initialState);
        },
        showNotification: true,
      });
      this.validator = commonFunctions.initializeSimpleValidator();
    }
    // const { allFunction } = this.props;
    // allFunction.getcustomerQuestion(this.state.customerQuestion.customQuestionType);
  }
  render() {
    const { customerQuestion, isCommonDeleteUpdateModalOpen, buttonName, messageToShow } = this.state;
    let globalCodes = this.props.global.codes.filter(code => code.categoryName === "CustomQuestionType").map((filtercode) => {
      return { key: filtercode.codeName, value: filtercode.globalCodeId, text: filtercode.codeName }
    });
    const fullState = this.state;
    const allFunction = this;

    return (
      <>
        <Modal open={this.props.openModal} closeIcon onClose={this.props.closeModal} size={"medium"}>
          <Modal.Header> {customerQuestion.customQuestionId > 0 ? "Update Customer Questions" : "Create Customer Questions"} </Modal.Header>
          <Modal.Content>
            <Grid>
              <Grid.Column mobile={16} tablet={6} computer={6}>
                <Form.Select
                  placeholder="Choose Field Type" data="customQuestionType" type="select" options={globalCodes}
                  value={customerQuestion.customQuestionType} className="custom-select" fluid onChange={this.onHandleChange}
                  error={this.validator.message("questionType", customerQuestion.customQuestionType, "required")} />
              </Grid.Column>

              <Grid.Column mobile={16} tablet={8} computer={10}>
                <Form.Input placeholder="Question Name" error={this.validator.message("question", customerQuestion.customQuestion, "required|min:0|max:500")} value={customerQuestion.customQuestion} data="customQuestion" fluid onChange={this.onHandleChange} />
              </Grid.Column>
              <div className="customerQuestionLabel">
                {customerQuestion.customQuestionType === 164 && (
                  <React.Fragment>
                    {this.singleQuestionUI()}
                  </React.Fragment>
                )}

                {customerQuestion.customQuestionType === "Add-Ons Question" && (
                  <React.Fragment>
                    <Grid.Column width={1} verticalAlign="middle">
                      <Form.Radio className="custom-Form.Radio-btn" />
                    </Grid.Column>

                    <Grid.Column width={15}>
                      <Form.Input fluid defaultValue="Yes" disabled />
                    </Grid.Column>
                    <Grid.Column width={1} verticalAlign="middle">
                      <Form.Radio className="custom-Form.Radio-btn" />
                    </Grid.Column>

                    <Grid.Column width={15}>
                      <Form.Input fluid defaultValue="No" disabled />
                    </Grid.Column>
                  </React.Fragment>
                )}

                {customerQuestion.customQuestionType === 165 && (
                  <React.Fragment>
                    {this.singleQuestionUI()}
                  </React.Fragment>
                )}
                <Grid.Column width={16}></Grid.Column>
              </div>
            </Grid>
            <Grid>
              <Grid.Column width={16} textAlign="right" className="modalButtons">
                {this.state.customerQuestion.customQuestionId ? <Button className="orange-btn" onClick={(e) => this.onHandleUpdate(e)} >Update</Button> : <Button className="orange-btn" onClick={this.onHandleSubmit} >Save</Button>}
              </Grid.Column>
            </Grid>

            <CustomerQuestionGrid ref={this.child} fullState={fullState} allFunction={allFunction} />
            {/* <Grid>
            <Grid.Column width={16}>
              <div className="common-tableRefund">
                {this.state.customerQuestionList && this.state.customerQuestionList.length > 0 &&
                  <Table singleLine>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>
                          Question Type
                          <Icon link name="sort" className="orange-color" />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          Question
                          <Icon link name="sort" className="orange-color" />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          Lable
                          <Icon link name="sort" className="orange-color" />
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {this.state.customerQuestionList && this.state.customerQuestionList.map((data, index) => {
                        return (
                          <Table.Row>
                            <Table.Cell textAlign="right">{data.customquestionTypeValue}</Table.Cell>
                            <Table.Cell> <Moment format="MMMM DD,YYYY">{data?.RefundedDate}</Moment></Table.Cell>
                            <Table.Cell>{data?.customQuestion}</Table.Cell>
                            <Table.Cell>{data?.Notes}</Table.Cell>
                          </Table.Row>
                        )
                      })}
                    </Table.Body>
                  </Table>
                }
              </div>
            </Grid.Column>
          </Grid> */}
          </Modal.Content>
        </Modal>
        {isCommonDeleteUpdateModalOpen &&
          <CommonDeleteUpdateModal
            isModalOpen={isCommonDeleteUpdateModalOpen}
            onConfirm={() => commonFunctions.onConfirmFunction(allFunction, "customQuestionId", "gridDetail",
              "DELETECUSTOMQUESTION", "")}
            onClose={() => commonFunctions.onCloseDeleteUpdateModal(allFunction, "customQuestionId")}
            confirmationButtonText={buttonName}
            header={messageToShow}
            cancelButtonText={'Cancel'}
          />}
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CustomerQuestions);