import React, { Component } from "react";
import { Dimmer, Loader } from "semantic-ui-react";

class LoaderComponent extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Dimmer active inverted>
        {" "}
        <Loader size="small">Loading</Loader>{" "}
      </Dimmer>
    );
  }
}
export default LoaderComponent;
