// ********************************************************************************************************************
// ********************************* Choose rental date range after selecting item **********************************
// ********************************************************************************************************************

import React, { Component } from "react";
import {
  Button,
  Grid,
  Header,
  Select,
  Form,
  Input,
  Icon,
  Tab,
  List,
  Radio,
  Table,
  Popup,
} from "semantic-ui-react";
import "../../../../../../../assets/scss/new-order.scss";
import {
  connect,
  bindActionCreators,
  actions,
  commonFunctions,
  Notifications,
} from "../../../../../../functional/global-import";
import moment from "moment";
import { GlobalCodeSelect } from "../../../../../../components";
import { RangePicker } from "react-trip-date";
import { ItemAvailableModal } from "../../../../../../components";
import { orderCommonFunctions } from "../../order-common-function";
import RentalAvailabilityModal from "./rental-avaiblity -modal";
const handleResponsive = (setNumberOfMonth) => {
  let width = document.querySelector(".tp-calendar").clientWidth;
  if (width > 900) {
    setNumberOfMonth(3);
  } else if (width < 900 && width > 580) {
    setNumberOfMonth(2);
  } else if (width < 580) {
    setNumberOfMonth(1);
  }
};

const Day = ({ day }) => {
  return (
    <>
      <p className="date">{day.format("DD")}</p>
      <p className="date">7</p>
    </>
  );
};

// const countryOptions2 = [
//     { key: "mi", value: "mi", text: "Minute(s)" },
//     { key: "hr", value: "hr", text: "Hour(s)" },
//     { key: "da", value: "da", text: "Day(s)" },
//     { key: "we", value: "we", text: "Week(s)" },
// ];
const CustomTimefirst = [
  { key: "01", value: "01", text: "01" },
  { key: "02", value: "02", text: "02" },
  { key: "03", value: "03", text: "03" },
  { key: "04", value: "04", text: "04" },
  { key: "05", value: "05", text: "05" },
  { key: "06", value: "06", text: "06" },
  { key: "07", value: "07", text: "07" },
  { key: "08", value: "08", text: "08" },
  { key: "09", value: "09", text: "09" },
  { key: "10", value: "10", text: "10" },
  { key: "11", value: "11", text: "11" },
  { key: "12", value: "12", text: "12" },
];

const CustomTimetwo = [
  { key: "00", value: "00", text: "00" },
  { key: "05", value: "05", text: "05" },
  { key: "10", value: "10", text: "10" },
  { key: "15", value: "15", text: "15" },
  { key: "20", value: "20", text: "20" },
  { key: "25", value: "25", text: "25" },
  { key: "30", value: "30", text: "30" },
  { key: "35", value: "35", text: "35" },
  { key: "40", value: "40", text: "40" },
  { key: "45", value: "45", text: "45" },
  { key: "50", value: "50", text: "50" },
  { key: "55", value: "55", text: "55" },
];

const CustomTimethree = [
  { key: "am", value: "am", text: "AM" },
  { key: "pm", value: "pm", text: "PM" },
];

class RentalChooseDates extends Component {
  constructor(props) {
    super(props);

    this.state = {
      monthDate: "",
      monthDate1: "",
      availableticket: false,
      count: 1,
      bookingSlot: {
        startTimeHour: "07",
        startTimeMinute: "00",
        startTimeAm: "am",
        endTimeHour: "09",
        endTimeMinute: "00",
        endTimeAm: "am",
      },
      currentTime: { from: "", to: "" },
      nonAvalableDAtes: [],
      value: "custom",
      selectedFlatFee: null,
      //durationId:null
      durationId: commonFunctions.getGlobalCodeDetails(
        this.props.global.codes,
        "Duration",
        "Minute"
      ).globalCodeId,
      partialDetailModal: false,
      rentalAvailabilityModal: false,
      isAddMoreCLicked: null,
      isSelectedDatesRemoved: false,
      rentalAvailabilty: null,
      customDurationForm: {
        selectedCustomDurationId: null,
        selectedCustomDurationSlots: null,
      },
      slotDetails: [],
      selectedDurationDetails: null,
      initialMonthYear: "",
      firstAvailableDate: "",
      hasRendered: false,
    };
                                         
    this.validator = commonFunctions.initializeSimpleValidator();
  }

  handleChangeDate = (e, { name, value }) => {
    e.preventDefault();
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  };

  handleChangeMonth = (event, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  };

  handleSelectTime = (e) => {
    this.setState({ selectTime: e.target.name, availableticket: true });
  };

  handleChange = (e, { value }) => {
    this.setState({ value: value });
  };

  countHandleChange = () => {
    const { count } = this.state;
    this.setState({ count: count + 1 });
  };

  countHandleChangeMinus = () => {
    const { count } = this.state;
    if (count >= 1) {
      this.setState({ count: count - 1 });
    }
  };
  onTimeHandleChange = (e, { value, name }) => {
    const { bookingSlot } = this.state;
    this.setState({
      bookingSlot: { ...this.state.bookingSlot, [name]: value },
    });

    let data = {
      startTime:
        bookingSlot.startTimeHour +
        ":" +
        bookingSlot.startTimeMinute +
        bookingSlot.startTimeAm,
      endTime:
        bookingSlot.endTimeHour +
        ":" +
        bookingSlot.endTimeMinute +
        bookingSlot.endTimeAm,
    };

    this.props.actions.addSelectedDateRange(data);
  };

  getPartialDates = (currentMonthDates, toOpenPartailModal = false) => {
    const { rentalDatesSelected, selectedItemsDetails } = this.props;
    let productdetailID = selectedItemsDetails.map(
      (item) => item.productdetailid
    );
    //
    this.props.actions.apiCall({
      urls: ["GETPARTIALDATES"],
      method: "GET",
      data: {
        ProductId: this.props.productId,
        StartDate: currentMonthDates.start,
        EndDate: currentMonthDates.end,
        BusinessCategoryType: commonFunctions.getGlobalCodeDetails(
          this.props.global.codes,
          "BusinessCategoryType",
          "Rental"
        ).globalCodeId,
        ProductDetailIds: productdetailID,
      },
      onSuccess: (response) => {
        let partialDates = [];
        response.map((partialObj) => {
          if (partialObj.availability === "Partially Unavailable") {
            JSON.parse(partialObj.dates).map((dateObj) => {
              partialDates.push(dateObj.Days);
              return dateObj.Days;
            });
          }
        });
        !toOpenPartailModal &&
          this.setState({ partialDates, partialDetails: response }, () =>
            this.onSettingPartialDates()
          );
        toOpenPartailModal &&
          this.setState({ partialDetails: response, partialDetailModal: true });
      },
    });
  };

  onSettingPartialDates = (date) => {
    const partailOrderedDates = this.state.partialDates;
    if (date) {
      partailOrderedDates = partailOrderedDates.filter((obh) => {
        return obh !== date;
      });
    }
    //
    let unAvailableDates = [];
    let unAvailableDatesDetails = [];
    this.state.partialDetails &&
      this.state.partialDetails.map((objDetail) => {
        if (objDetail.availability === "Unavailable") {
          JSON.parse(objDetail.dates).map((dateObj) => {
            unAvailableDates.push(dateObj.Days);
            return dateObj;
          });
          unAvailableDatesDetails.push(objDetail);
          return objDetail;
        }
      });

    var calendarDateArray = document.getElementsByClassName("sc-gKseQn");
    Array.from(calendarDateArray).map((calendarObj) => {
      if (
        partailOrderedDates.indexOf(calendarObj.getAttribute("data-test")) > -1
      ) {
        calendarObj.classList.add("partial-ordered");
      }
    });

    Array.from(calendarDateArray).map((calendarObj) => {
      if (
        unAvailableDates.indexOf(calendarObj.getAttribute("data-test")) > -1
      ) {
        calendarObj.classList.add("unavailable-date");
      }
    });

    this.setState({ unAvailableDates: unAvailableDates });
  };

  componentDidMount() {
    const { rentalDatesSelected, bookingSlot } = this.props;

    const { currentTime } = this.state;

    this.setState({ currentTime, bookingSlot: bookingSlot });

    const { orderState } = this.props;
    if (orderState) {
      const nextState = orderState.currentOrder.orderDetails.filter(
        (item) => item.id === orderState.currentOrderDetails.id
      );
      let selectedRange = nextState[0]["selectedDateRange"];
      if (selectedRange.startDate) {
        this.setState({
          monthDate: `${moment(selectedRange.startDate, "MM-DD-YYYY").format(
            "DD.MM.YYYY"
          )} - ${moment(selectedRange.endDate, "MM-DD-YYYY").format(
            "DD.MM.YYYY"
          )}`,
        });
      }
    }

    this.SetDateRange();
  }

  //find the first available date according to timeFrameEventName

  SetDateRange = () => {
    const { productDateDetails } = this.props;
    const today = moment().format("YYYY-MM-DD");
    this.setState({
      initialMonthYear:
        productDateDetails?.availability?.EventStartDate < today
          ? today
          : productDateDetails?.availability?.EventStartDate,
    });
  };

  onRangeDateInScreen = (window) => {
    const { initialMonth } = this.props;
    this.props.actions.apiCall({
      urls: ["GETPRODUCTAVAILAVILITY"],
      method: "GET",
      data: {
        StartDate: window.start,
        ProductId: this.props.productId,
        businessCategoryType: commonFunctions.getGlobalCodeDetails(
          this.props.global.codes,
          "BusinessCategoryType",
          "Rental"
        ).globalCodeId,
      },
      onSuccess: (response) => {
        const { currentTime } = this.state;
        const selectedDate = localStorage.getItem("availableItemDate");

        if (selectedDate) {
          const byDefaultSelectedDate = selectedDate.split(" - ")[0];
          const byDefaultSelectedEndDate = selectedDate.split(" - ")[1];

          this.setState({
            currentTime: {
              from: byDefaultSelectedDate,
              to: byDefaultSelectedEndDate
                ? byDefaultSelectedEndDate
                : byDefaultSelectedDate,
            },
          });
        } else {
          if (response[0].notAvailableDays === null) {
            this.setState({ nonAvalableDAtes: [] });
          } else {
            let nonAvalableDAte = JSON.parse(response[0].notAvailableDays).map(
              (post) => {
                return post.dates;
              }
            );
            let newNonAvalableDAte = [
              // ...this.state.nonAvalableDAtes,
              ...nonAvalableDAte,
            ];

            if (
              nonAvalableDAte.indexOf(currentTime.from) > -1 ||
              nonAvalableDAte.indexOf(currentTime.to) > -1
            ) {
              currentTime.from = "";
              currentTime.to = "";
              this.setState({
                nonAvalableDAtes: newNonAvalableDAte,
                currentTime,
                isSelectedDatesRemoved: true,
              });
            } else {
              this.setState({ nonAvalableDAtes: newNonAvalableDAte });
            }
          }
          const today = moment().format("YYYY-MM-DD");
          const initialMoment =
            initialMonth === null
              ? today
              : moment(initialMonth).format("YYYY-MM-DD");
          if (
            this.state.nonAvalableDAtes.length === 0 &&
            !this.state.hasRendered
          ) {
            this.setState({
              currentTime: {
                from: window.start < today ? today : window.start,
              },
              hasRendered: true,
            });
          }

          if (!this.state.hasRendered) {
            let firstAvailableDate = commonFunctions.findFirstMissingDate(
              this.state.nonAvalableDAtes
            );
            if (firstAvailableDate) {
              this.setState({
                currentTime: {
                  from:
                    firstAvailableDate <= initialMoment
                      ? initialMoment
                      : firstAvailableDate,
                },
                hasRendered: true,
              });
            }
            if (!firstAvailableDate && this.state.nonAvalableDAtes !== null) {
              // this.setState({firstAvailableDate : today , hasRendered: true})
            }
          }

          this.onCheckPartialDates(window);
        }
      },
    });
  };

  onCheckPartialDates = (window) => {
    const { rentalDatesSelected } = this.props;
    let PrevstartDate = moment(
      rentalDatesSelected.startDate,
      "MM DD YYYY, hh:mm A"
    ).format("YYYY-MMM");
    let newStartDate = moment(window.start, "YYYY-MM-DD").format("YYYY-MMM");

    let olddate = new Date(rentalDatesSelected.startDate);
    let newlydate = new Date(window.start);

    if (PrevstartDate === newStartDate) {
      const timeConverted = moment(new Date(), "MMM DD YYYY, hh:mm A").format(
        "YYYY-MM-DD"
      );
      window.start = timeConverted;

      this.getPartialDates(window);
    } else if (newlydate > olddate) {
      this.getPartialDates(window);
    } else {
      return false;
    }
  };

  handleChangeFlat = (item) => {
    this.setState({ selectedFlatFee: item.RentalPricingId });
  };

  onHandleChange = (e, { value }) => {
    this.setState({ durationId: value });
  };

  onChange = (date) => {
    // this.getActivityTickets(this.state.selectedTime, date && date[0])
    this.setState({ currentTime: date, isSelectedDatesRemoved: false });
    // this.onSettingPartialDates()

    // this.props.getNewActivityTimeSlots(date);

    // setTimeout(
    //     () => this.onSettingPartialDates(date[0]),
    //     1000
    // );
  };

  checkAvailability = () => {
    const { currentTime, bookingSlot } = this.state;
    // const { startTime, endTime } = this.props

    let data = {
      startTime:
        bookingSlot.startTimeHour +
        ":" +
        bookingSlot.startTimeMinute +
        bookingSlot.startTimeAm,
      endTime:
        bookingSlot.endTimeHour +
        ":" +
        bookingSlot.endTimeMinute +
        bookingSlot.endTimeAm,
    };

    if (currentTime.from === "") {
      this.props.actions.showNotification(
        {
          title: "Error",
          message: "Please select date first to check availability.",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    } else {
      let newDate = {
        start: `${currentTime.from} ${data.startTime}`,
        end:
          currentTime.to === ""
            ? `${currentTime.from} ${data.endTime}`
            : `${currentTime.to} ${data.endTime}`,
      };
      this.getPartialDates(newDate, true);
    }
  };

  onHandleChangeSlotSelected = (e, { value, type, data }) => {
    var customDurationForm = commonFunctions.onHandleChange(
      e,
      { value, type, data },
      this.state.customDurationForm
    );
    this.setState({ customDurationForm });
  };

  onHandleChangeSlot = (e, { value, type, data }) => {
    var customDurationForm = commonFunctions.onHandleChange(
      e,
      { value, type, data },
      this.state.customDurationForm
    );
    customDurationForm.selectedCustomDurationSlots = null;
    this.setState(
      { customDurationForm, slotDetails: [], selectedDurationDetails: null },
      () => this.onGetProductSlots()
    );
  };

  onGetProductSlots = () => {
    this.props.actions.apiCall({
      urls: ["GETPRODUCTDURATIONSLOTS"],
      method: "GET",
      data: {
        CustomDurationId:
          this.state.customDurationForm.selectedCustomDurationId,
        StartDate: this.state.currentTime.from,
        EndDate: this.state.currentTime.to,
      },
      onSuccess: (response) => {
        let slotDetails = JSON.parse(response[0].slots).map((slotObj) => {
          return {
            text: slotObj,
            value: slotObj,
          };
        });
        this.setState({ slotDetails, selectedDurationDetails: response });
      },
    });
  };

  onSaveAllDetails = (e, isAddMoreCLicked) => {
    const selectedProductDetails =
      this.props.orderState.currentOrder.orderDetails &&
      this.props.orderState.currentOrder.orderDetails.filter((item) => {
        return item.id == this.props.orderState.currentOrderDetails.id;
      });
    const slotBasedPrice =
      selectedProductDetails[0] &&
      selectedProductDetails[0].selectedProductDetails.slotBasedPrice;
    if (slotBasedPrice && slotBasedPrice.length > 0) {
      var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
      if (isFormValid) {
        this.setState({
          rentalAvailabilityModal: !this.state.rentalAvailabilityModal,
          isAddMoreCLicked: isAddMoreCLicked,
        });
      }
    } else {
      this.setState({
        rentalAvailabilityModal: !this.state.rentalAvailabilityModal,
        isAddMoreCLicked: isAddMoreCLicked,
      });
    }
  };
  onHandleRentalInventory = (e, isAddMoreCLicked) => {
    const {
      selectedFlatFee,
      value,
      durationId,
      count,
      currentTime,
      customDurationForm,
      selectedDurationDetails,
    } = this.state;
    const selectedProductDetails =
      this.props.orderState.currentOrder.orderDetails &&
      this.props.orderState.currentOrder.orderDetails.filter((item) => {
        return item.id == this.props.orderState.currentOrderDetails.id;
      });
    const slotBasedPrice =
      selectedProductDetails[0] &&
      selectedProductDetails[0].selectedProductDetails.slotBasedPrice;
    if (slotBasedPrice && slotBasedPrice.length > 0) {
      this.props.moveToSignature({
        bookingslot: this.state.bookingSlot,
        selectedDate: this.state.monthDate,
        selectedFlatFee,
        addMoreItem: isAddMoreCLicked,
        value,
        durationId,
        count,
        newTimeRange: currentTime,
        selectedDurationDetails,
        customDurationForm,
        rentalAviablity: true,
      });
    } else {
      this.props.moveToSignature({
        bookingslot: this.state.bookingSlot,
        selectedDate: this.state.monthDate,
        selectedFlatFee,
        addMoreItem: isAddMoreCLicked,
        value,
        durationId,
        count,
        newTimeRange: currentTime,
        selectedDurationDetails,
        customDurationForm,
        rentalAviablity: true,
      });
    }
  };
  onContinueAllDetails = (e, isAddMoreCLicked) => {
    const {
      selectedFlatFee,
      value,
      durationId,
      count,
      currentTime,
      customDurationForm,
      selectedDurationDetails,
    } = this.state;
    const selectedProductDetails =
      this.props.orderState.currentOrder.orderDetails &&
      this.props.orderState.currentOrder.orderDetails.filter((item) => {
        return item.id == this.props.orderState.currentOrderDetails.id;
      });
    const slotBasedPrice =
      selectedProductDetails[0] &&
      selectedProductDetails[0].selectedProductDetails.slotBasedPrice;
    if (slotBasedPrice && slotBasedPrice.length > 0) {
      var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
      if (isFormValid) {
        this.props.moveToSignature({
          bookingslot: this.state.bookingSlot,
          rentalAviablity: false,
          selectedDate: this.state.monthDate,
          selectedFlatFee,
          addMoreItem: isAddMoreCLicked,
          value,
          durationId,
          count,
          newTimeRange: currentTime,
          selectedDurationDetails,
          customDurationForm,
        });
      }
    } else {
      this.props.moveToSignature({
        bookingslot: this.state.bookingSlot,
        rentalAviablity: false,
        selectedDate: this.state.monthDate,
        selectedFlatFee,
        addMoreItem: isAddMoreCLicked,
        value,
        durationId,
        count,
        newTimeRange: currentTime,
        selectedDurationDetails,
        customDurationForm,
      });
    }
  };
  render() {
    const {
      bookingSlot,
      selectedFlatFee,
      durationId,
      count,
      currentTime,
      nonAvalableDAtes,
      partialDetailModal,
      partialDetails,
      isSelectedDatesRemoved,
      customDurationForm,
      slotDetails,
    } = this.state;

    const {
      hasToShowCancellationComponent,
      showAddon,
      customfields,
      currentDateFromApi,
    } = this.props;
    const selectedProductDetails =
      this.props.orderState.currentOrder.orderDetails &&
      this.props.orderState.currentOrder.orderDetails.filter((item) => {
        return item.id == this.props.orderState.currentOrderDetails.id;
      });
    const info =
      selectedProductDetails[0] &&
      selectedProductDetails[0].selectedProductDetails.info[0];

    const slotBasedPrice =
      selectedProductDetails[0] &&
      selectedProductDetails[0].selectedProductDetails.slotBasedPrice;

    const price =
      selectedProductDetails[0] &&
      selectedProductDetails[0].selectedProductDetails.price;

    const rate =
      selectedProductDetails[0] &&
      selectedProductDetails[0].selectedProductDetails.rate;

    const specialPrice =
      selectedProductDetails[0] &&
      selectedProductDetails[0].selectedProductDetails.specialPrice;

    const theme = orderCommonFunctions.themeColor();

    const panes = [
      {
        menuItem: "Book Now",
        render: () => (
          <Tab.Pane attached={false}>
            {" "}
            <Grid>
              <Grid.Column width={16}>
                {isSelectedDatesRemoved && (
                  <p className="orange-color">
                    Select Dates Again as you have selected not available start
                    or end date
                  </p>
                )}
                <Button className="orange-btn" onClick={this.checkAvailability}>
                  Availability
                </Button>

                <Popup
                  trigger={<Icon name="info circle" className="orange-color" />}
                >
                  {/* <div>
                     <input className="orangeBox" ></input>Partially booked dates
                                    </div>
                                    &nbsp;&nbsp; */}
                  {/* <div>
                                        <input className="purpleBox" ></input>Unavailable dates
                                    </div> */}
                  &nbsp;&nbsp;
                  <div>
                    <input className="blueBox"></input>Selected date
                  </div>
                  &nbsp;&nbsp;
                  <div>
                    <input className="blankBox" value=" / "></input>Not
                    available date
                  </div>
                </Popup>
              </Grid.Column>

              {/*  */}

              {/* for  flat fee */}
              {price.length > 0 && (
                <Grid.Column width={16}>
                  <Header as="h2" className="maroon-color">
                    Select Flat Fee
                  </Header>
                  <Table className="flatfee">
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell></Table.HeaderCell>
                        <Table.HeaderCell>Title</Table.HeaderCell>
                        <Table.HeaderCell>Price </Table.HeaderCell>
                        <Table.HeaderCell>Deposit</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {price &&
                        price.map((flatFeeDetail) => {
                          return (
                            <Table.Row>
                              <Table.Cell>
                                <Radio
                                  className="custom-radio-btn"
                                  id={flatFeeDetail.RentalPricingId}
                                  checked={
                                    flatFeeDetail.RentalPricingId &&
                                    selectedFlatFee ===
                                      flatFeeDetail.RentalPricingId
                                  }
                                  onChange={() =>
                                    this.handleChangeFlat(flatFeeDetail)
                                  }
                                ></Radio>
                              </Table.Cell>
                              <Table.Cell
                                title={flatFeeDetail.RentalPricingTitle}
                                className="textOverflowHidden flatTitle"
                              >
                                {flatFeeDetail.RentalPricingTitle}
                              </Table.Cell>
                              <Table.Cell className="textRight">
                                {commonFunctions.isNumberInteger(
                                  flatFeeDetail.ItemPrice
                                )}
                              </Table.Cell>
                              <Table.Cell className="textRight">
                                {commonFunctions.isNumberInteger(
                                  flatFeeDetail.Deposit
                                )}
                              </Table.Cell>
                            </Table.Row>
                          );
                        })}
                    </Table.Body>
                  </Table>
                </Grid.Column>
              )}

              {slotBasedPrice && slotBasedPrice.length > 0 ? (
                <>
                  <Grid.Column width={16}>
                    <Header as="h2">Select Duration</Header>
                    <Form.Select
                      fluid
                      options={slotBasedPrice}
                      data="selectedCustomDurationId"
                      value={customDurationForm.selectedCustomDurationId}
                      type="select"
                      className="selectedCustomDurationId"
                      placeholder="Select Duration"
                      onChange={this.onHandleChangeSlot}
                      error={this.validator.message(
                        "Slot Duration",
                        customDurationForm.selectedCustomDurationId,
                        "required"
                      )}
                    />
                  </Grid.Column>
                  <Grid.Column width={16}>
                    {slotDetails && slotDetails.length > 0 && (
                      <Header as="h2">Select Slots</Header>
                    )}
                    {slotDetails && slotDetails.length > 0 && (
                      <Form.Select
                        fluid
                        options={slotDetails}
                        data="selectedCustomDurationSlots"
                        value={customDurationForm.selectedCustomDurationSlots}
                        type="select"
                        placeholder="Slot Duration"
                        onChange={this.onHandleChangeSlotSelected}
                        error={this.validator.message(
                          "Slot's",
                          customDurationForm.selectedCustomDurationSlots,
                          "required"
                        )}
                      />
                    )}
                  </Grid.Column>
                </>
              ) : (
                <>
                  <Grid.Column width={16}>
                    <Header as="h2" className="maroon-color">
                      Start Time
                    </Header>
                  </Grid.Column>
                  <Grid.Column width={5}>
                    <Select
                      options={CustomTimefirst}
                      value={bookingSlot.startTimeHour}
                      className="custom-select"
                      defaultValue="9"
                      name="startTimeHour"
                      fluid
                      onChange={this.onTimeHandleChange}
                    />
                  </Grid.Column>
                  <Grid.Column width={5}>
                    <Select
                      options={CustomTimetwo}
                      value={bookingSlot.startTimeMinute}
                      className="custom-select"
                      defaultValue="m00"
                      name="startTimeMinute"
                      fluid
                      onChange={this.onTimeHandleChange}
                    />
                  </Grid.Column>
                  <Grid.Column width={5}>
                    <Select
                      options={CustomTimethree}
                      value={bookingSlot.startTimeAm}
                      className="custom-select"
                      name="startTimeAm"
                      defaultValue="am"
                      fluid
                      onChange={this.onTimeHandleChange}
                    />
                  </Grid.Column>

                  <Header as="h2" className="maroon-color">
                    End Time
                  </Header>

                  {price.length === 0 && (
                    <Grid.Column width={16}>
                      <Radio
                        className="custom-radio-btn"
                        label="Open"
                        name="radioGroup"
                        value="open"
                        checked={this.state.value === "open"}
                        onChange={this.handleChange}
                      />
                    </Grid.Column>
                  )}
                  <Grid.Column width={16}>
                    <div className="end-time-select">
                      <Radio
                        className="custom-radio-btn"
                        name="radioGroup"
                        value="duration"
                        checked={this.state.value === "duration"}
                        onChange={this.handleChange}
                      />
                      <Form.Field
                        className="add-numbers"
                        disabled={this.state.value !== "duration"}
                      >
                        <Icon
                          name="minus"
                          className="orange-button"
                          disabled={this.state.value !== "duration"}
                          onClick={() => this.countHandleChangeMinus()}
                        ></Icon>

                        <Input
                          value={count}
                          disabled={this.state.value !== "duration"}
                        />
                        <Icon
                          name="plus"
                          disabled={this.state.value !== "duration"}
                          className="orange-button plus-btn"
                          onClick={() => this.countHandleChange()}
                        ></Icon>
                      </Form.Field>

                      <GlobalCodeSelect
                        placeholder="Time Period"
                        name="rentalOrderDropdown"
                        categoryType="Duration"
                        value={durationId}
                        onChange={this.onHandleChange}
                      />
                    </div>
                  </Grid.Column>

                  <Grid.Column width={16} verticalAlign="middle">
                    <Radio
                      className="custom-radio-btn"
                      name="radioGroup"
                      value="custom"
                      checked={this.state.value === "custom"}
                      onChange={this.handleChange}
                      label="Custom time"
                    />
                  </Grid.Column>
                  <Grid.Column width={5}>
                    <Select
                      value={bookingSlot.endTimeHour}
                      options={CustomTimefirst}
                      name="endTimeHour"
                      className="custom-select"
                      defaultValue="9"
                      fluid
                      onChange={this.onTimeHandleChange}
                      checked={this.state.value === "custom"}
                      disabled={this.state.value !== "custom"}
                    />
                  </Grid.Column>
                  <Grid.Column width={5}>
                    <Select
                      value={bookingSlot.endTimeMinute}
                      options={CustomTimetwo}
                      name="endTimeMinute"
                      className="custom-select"
                      defaultValue="00"
                      fluid
                      onChange={this.onTimeHandleChange}
                      disabled={this.state.value !== "custom"}
                    />
                  </Grid.Column>
                  <Grid.Column width={5}>
                    <Select
                      value={bookingSlot.endTimeAm}
                      options={CustomTimethree}
                      name="endTimeAm"
                      className="custom-select"
                      defaultValue="pm"
                      fluid
                      onChange={this.onTimeHandleChange}
                      disabled={this.state.value !== "custom"}
                    />
                  </Grid.Column>
                </>
              )}
            </Grid>
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Info",
        render: () => (
          <Tab.Pane attached={false}>
            <Grid>
              {info.ItemDescription !== "" && (
                <Grid.Column width={16}>
                  <p>
                    <strong>Description :</strong> {info.ItemDescription}
                  </p>
                </Grid.Column>
              )}
              {info.PickUpInstructions !== "" && (
                <Grid.Column width={16}>
                  <p>
                    <strong>Pick Up Instructions :</strong>{" "}
                    {info.PickUpInstructions}
                  </p>
                </Grid.Column>
              )}
              {info.DropOffInstructions !== "" && (
                <Grid.Column width={16}>
                  <p>
                    <strong>Drop Off Instructions :</strong>{" "}
                    {info.DropOffInstructions}
                  </p>
                </Grid.Column>
              )}
              {info.WhattoKnow !== "" && (
                <Grid.Column width={16}>
                  <p>
                    <strong>What to know? :</strong> {info.WhattoKnow}
                  </p>
                </Grid.Column>
              )}
              {info.WhatisIncluded !== "" && (
                <Grid.Column width={16}>
                  <p>
                    <strong>What is included? :</strong> {info.WhatisIncluded}
                  </p>
                </Grid.Column>
              )}

              <br></br>
              {customfields &&
                customfields.length > 0 &&
                customfields.map((customObj) => {
                  return (
                    <>
                      <p>
                        <strong>{customObj.customField} :</strong>
                      </p>
                      <p>{customObj.customDescriptions}</p>
                      <br></br>
                    </>
                  );
                })}
            </Grid>
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Rate",
        render: () => (
          <Tab.Pane attached={false}>
            {price.length > 0 && price && (
              <>
                <Header className="orange-color">Flat Price</Header>
                {price.map((flatFeeDetail) => {
                  return (
                    <List>
                      <List.Item>
                        {" "}
                        {commonFunctions.isNumberInteger(
                          flatFeeDetail.ItemPrice
                        )}{" "}
                        / {flatFeeDetail.RentalPricingTitle}
                      </List.Item>
                    </List>
                  );
                })}
              </>
            )}

            {rate.length > 0 && rate && (
              <>
                <Header className="orange-color">Duration Price</Header>
                {rate[0].day && (
                  <List>
                    <List.Item>
                      {commonFunctions.isNumberInteger(rate[0].day)} / day
                    </List.Item>
                  </List>
                )}
                {rate[0].hour && (
                  <List>
                    <List.Item>
                      {commonFunctions.isNumberInteger(rate[0].hour)} / hour
                    </List.Item>
                  </List>
                )}
                {rate[0].minute && (
                  <List>
                    <List.Item>
                      {commonFunctions.isNumberInteger(rate[0].minute)} / minute
                    </List.Item>
                  </List>
                )}
                {rate[0].week && (
                  <List>
                    <List.Item>
                      {commonFunctions.isNumberInteger(rate[0].week)} / week
                    </List.Item>
                  </List>
                )}
              </>
            )}

            {specialPrice && specialPrice.length > 0 && (
              <>
                <Header className="orange-color">Special Price</Header>

                {specialPrice.map((specailPriceObj) => {
                  return (
                    <List>
                      <List.Item>
                        {commonFunctions.isNumberInteger(
                          specailPriceObj.ItemPrice
                        )}{" "}
                        / {specailPriceObj.Duration}
                      </List.Item>
                    </List>
                  );
                })}
              </>
            )}
          </Tab.Pane>
        ),
      },
    ];

    const { initialMonthDate, productDateDetails } = this.props;
    const byDefaultMonth = localStorage.getItem("availableItemDate");
    const firstDate = byDefaultMonth?.split(" - ")[0];
    const today = moment().format("YYYY-MM-DD");
    let selectedInitialMonth =
      initialMonthDate < today ? today : initialMonthDate;
    if (byDefaultMonth) {
      selectedInitialMonth = firstDate;
    }

    return (
      <>
        <Grid>
          <Grid.Column width={8}>
            {/* {currentTime !== null && ( */}
            <RangePicker
              theme={theme}
              onRangeDateInScreen={this.onRangeDateInScreen}
              onChange={this.onChange}
              numberOfMonths={1}
              initialMonthAndYear={selectedInitialMonth}
              selectedDays={currentTime}
              responsive={handleResponsive}
              disabledBeforeToday={true}
              disabledBeforeDate={moment(currentDateFromApi).format(
                "YYYY-MM-DD"
              )}
              numberOfSelectableDays={1}
              disabledDays={nonAvalableDAtes}
              allowDisabledDaysSpan={true}
              // disabled={false}
              dayComponent={Day}
              titleComponent="{Title}"
            />
            {/* )} */}
          </Grid.Column>
          <Grid.Column width={8}>
            <Tab
              menu={{ secondary: true }}
              panes={panes}
              className="availablity-tab chooseDateRental"
            />
          </Grid.Column>
          <Grid.Column width={16} textAlign="right">
            <Button className="blue-btn" onClick={this.props.backToRentalItems}>
              Back
            </Button>
            {/* onChange={this.props.productId !== undefined ? this.onHandlePriceType : this.onHandlePriceTypeMoreSetting}  */}
            {!showAddon && !hasToShowCancellationComponent && (
              <Button
                className="blue-btn"
                disabled={
                  price && price.length > 0 && selectedFlatFee === null
                    ? true
                    : currentTime.from === ""
                    ? true
                    : false
                }
                onClick={(e) => this.onSaveAllDetails(e, true)}
              >
                Add more items
              </Button>
            )}

            <Button
              className="orange-btn"
              disabled={
                price.length > 0 && selectedFlatFee === null
                  ? true
                  : currentTime.from === ""
                  ? true
                  : false
              }
              onClick={(e) => this.onContinueAllDetails(e, false)}
            >
              Continue
            </Button>
          </Grid.Column>

          {partialDetailModal && (
            <ItemAvailableModal
              openModal={partialDetailModal}
              partialDetails={partialDetails}
              closeModal={() => this.setState({ partialDetailModal: false })}
            />
          )}
          <RentalAvailabilityModal
            openModal={this.state.rentalAvailabilityModal}
            closeModal={this.onSaveAllDetails}
            onHandleRentalInventory={this.onHandleRentalInventory}
            onHandleRentalInventoryType={this.onHandleRentalInventoryType}
            isAddMoreCLicked={this.state.isAddMoreCLicked}
          />
        </Grid>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    newOrderDetail: state.newOrderDetail,
    orderState: state.orderState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      addSelectedDateRange: bindActionCreators(
        actions.addSelectedDateRange,
        dispatch
      ),
      showNotification: bindActionCreators(Notifications.show, dispatch),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RentalChooseDates);
