import React, { Component } from "react";
import BundleDate from "./bundle-date";
import BundleCount from "./bundle-count";
import { actions, bindActionCreators, connect, Notifications, commonFunctions } from "../../../../../../functional/global-import";
import CancelationCommonComponent from "../../common/cancelation-common";
import moment from "moment";
import { Dimmer, Loader } from "semantic-ui-react";

class Bundle extends Component {
    state = {
        showDate: this.props.bundleEdit.bundleEdit ? false : true,
        showCount: this.props.bundleEdit.bundleEdit ? true : false,
        bookingSlot: {
            startDate: '',
            startTime: ''
        },
        bundleDescription: null,
        showCancellationComponent: false,
        rate: [],
    }

    handleSubmitShowDate = (Datetime) => {

        let startTime = `${Datetime.startTimeHour}:${Datetime.startTimeMinute} ${Datetime.startTimeAm}`
        this.setState({ bookingSlot: { ...this.state.bookingSlot, startDate: Datetime.startDate, startTime: startTime }, showCount: true, showDate: false })
    }
    handleBundleCountBack = () => {
        this.setState({ showCount: false, showDate: true })
    }

    onCheckOfSlotAvailability = (bookingSlot) => {

        const { orderState, auth } = this.props;
        let startTime = `${bookingSlot.startTimeHour}:${bookingSlot.startTimeMinute} ${bookingSlot.startTimeAm}`
        const nextState = orderState.currentOrder.orderDetails.filter(orderDetail => orderDetail.id === orderState.currentOrderDetails.id).map((item,) => {
            return {
                "orderId": orderState.currentOrder.orderId,
                "businessCategoryType": item.typeId,
                "bundleId": item.selectedItems[0].productId,
                "startDate": moment(bookingSlot.startDate, 'YYYY-MM-DD').format('YYYY-MM-DD'),
                "startTime": startTime,
                "actionPerformedBy": auth.userDetail.emailId
            }
        })

        this.props.actions.apiCall({
            urls: ["ADDORDERCHECKTIMEFORBUNDLEORDER"], method: "POST",
            data: nextState[0],
            onSuccess: (response) => {

                this.handleSubmitShowDate(bookingSlot)
            }, showNotification: true
        });


    }





    handleBundleCountContinue = (dataObj) => {

        const { orderState, auth } = this.props;
        const { bookingSlot } = this.state;
        const nextState = orderState.currentOrder.orderDetails.filter(orderDetail => orderDetail.id === orderState.currentOrderDetails.id).map((item, idx) => {
            return {
                "orderId": orderState.currentOrder.orderId,
                "businessCategoryType": item.typeId,
                "bundleId": item.selectedItems[0].productId,
                "startDate": moment(bookingSlot.startDate, 'YYYY-MM-DD').format('YYYY-MM-DD'),
                "startTime": bookingSlot.startTime,
                "numberOfBookings": dataObj.count,
                "actionPerformedBy": auth.userDetail.emailId
            }
        })

        this.props.actions.apiCall({
            urls: ["POSTADDORDERBUNDLEDETAILS"], method: "POST", data: nextState[0], showNotification: true,
            onSuccess: (response) => {
                if (response !== null) {
                    if (dataObj.isAddmoreClicked) {
                        this.props.onAddmoreBtnClick()
                    }
                    else if (this.state.hasToShowCancellationComponent) {
                        this.setState({ showCount: false, showCancellationComponent: this.state.hasToShowCancellationComponent })
                    }
                    else {
                        this.props.next(2, false, { BusinessCategoryType: nextState[0].businessCategoryType, ProductId: nextState[0].bundleId })
                    }
                    this.props.actions.getOrderItems(orderState.currentOrder.orderId);

                    return response;
                }
                else {
                    this.props.actions.showNotification({ title: "Error", message: "Something Went Wrong in Create Customer Process!!", position: 'br', autoDismiss: commonFunctions.notificationTime() }, "error");
                }
            },
        })
    }

    componentDidMount = () => {

        this.props.actions.apiCall({
            urls: ["GETORDERBUNDLEPRICE"], method: "GET", data: {
                bundleId: this.props.bundleEdit.bundleEdit ? this.props.bundleEdit.bundleItem.BundleId : this.props.productCategoryDetail.productId, inWebsiteWidget: false
            }, showNotification: true, onSuccess: (response) => {


                const briefDetails = response[0].breifDetails && JSON.parse(response[0].breifDetails)[0]
                const customfields = response[0] && response[0].customfields && JSON.parse(response[0].customfields)
                const rate = response[0].rate && JSON.parse(response[0].rate)
                const bundleDetails = JSON.parse(response[0].bundleDetails)

                const hasToShowCancellationComponent = briefDetails && briefDetails.CancellationPolicy !== null ? true : false;
                this.setState({ rate: rate, bundleDescription: response[0].info && JSON.parse(response[0].info)[0].BundleDescription, hasToShowCancellationComponent: hasToShowCancellationComponent, briefDetails: briefDetails, bundleDetails: bundleDetails, customfields: customfields });
            },
        })
    }

    onCancelContinueClick = (isAddMoreClicked) => {
        if (isAddMoreClicked) {
            this.props.onAddmoreBtnClick()
        }
        else {
            this.props.next(2)
        }
    }

    render() {
        const { showDate, showCount, bundleDescription, hasToShowCancellationComponent, showCancellationComponent, briefDetails, bundleDetails, rate, customfields } = this.state;
        const { productCategoryDetail, allFunction, bundleEdit, api, currentDateFromApi } = this.props
        return (
            <div>
                {api.isApiLoading &&
                    <Dimmer active inverted> <Loader size='small'></Loader> </Dimmer>
                }
                {showDate &&
                    // !showCount &&
                    <BundleDate
                        currentDateFromApi={currentDateFromApi}
                        customfields={customfields}
                        bundleStateRef={this}
                        rate={rate}
                        productId={this.props.productCategoryDetail.productId}
                        backBtnInventoryView={this.props.backBtnInventoryView} bundleDescription={bundleDescription} handleSubmitShowDate={this.handleSubmitShowDate}
                        onCheckOfSlotAvailability={this.onCheckOfSlotAvailability}
                    />
                }
                {showCount &&
                    //  !showDate &&
                    <BundleCount
                        bundleStateRef={this}
                        allFunction={allFunction}
                        bundleEdit={bundleEdit}
                        next={this.props.next}
                        hasToShowCancellationComponent={hasToShowCancellationComponent}
                        bundleDetails={bundleDetails}
                        productCategoryDetail={productCategoryDetail}
                        handleBundleCountContinue={this.handleBundleCountContinue}
                        handleBundleCountBack={this.handleBundleCountBack} />
                }
                {showCancellationComponent &&
                    <CancelationCommonComponent
                        allFunction={this}
                        showAddon={false}
                        briefDetails={briefDetails}
                        onNext={this.onCancelContinueClick}
                        businessTypeName="Bundle"
                    />
                }
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        api: state.api,
        global: state.global,
        newOrderDetail: state.newOrderDetail,
        auth: state.auth,
        orderState: state.orderState,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        actions: {
            apiCall: bindActionCreators(actions.apiCall, dispatch),
            AddOrderPaymentStatus: bindActionCreators(actions.AddOrderPaymentStatus, dispatch),
            showNotification: bindActionCreators(Notifications.show, dispatch),
            getOrderItems: bindActionCreators(actions.getOrderItems, dispatch),

        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Bundle);
