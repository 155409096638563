import React, { Component } from 'react'
import { Dropdown } from 'semantic-ui-react'

const options = [
  { key: 'Document 1', text: 'Document 1', value: 'Document 1' },
  { key: 'Document 2', text: 'Document 2', value: 'Document 2' },
  { key: 'Document 3', text: 'Document 3', value: 'Document 3' },
]

class DocumentsTagger extends Component {
  state = { options }

  handleAddition = (e, { value }) => {
    this.setState((prevState) => ({
      options: [{ text: value, value }, ...prevState.options],
    }))
  }

  handleChange = (e, { value }) => {
    this.setState({ currentValues: value })
  }

  render() {
    const { currentValues } = this.state
    return (
      <Dropdown options={this.state.options} placeholder='Document' search selection fluid multiple allowAdditions value={currentValues} onAddItem={this.handleAddition} onChange={this.handleChange} className="tagger"/>
    )
  }
}

export default DocumentsTagger;
