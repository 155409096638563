import React, { Component } from "react";
import { Grid, Icon, Popup } from "semantic-ui-react";
import {
  connect,
  bindActionCreators,
  actions,
  Notifications,
  commonFunctions,
} from "../../../../../functional/global-import";

class ViewDocument extends Component {
  state = {
    documentList: [],
    activeDocument: {
      documentDetail: {
        documentId: 0,
        documentName: 0,
        // image: `https://drive.google.com/viewerng/viewer?embedded=true&url=https#toolbar=0&scrollbar=0`,
        // image: `https://drive.google.com/viewerng/viewer?url=https`
        // image: `https://drive.google.com/viewerng/viewer?embedded=true&url=$#toolbar=0&scrollbar=0`
        image: `https://drive.google.com/drive/u/0/my-drive`,
      },
      number: 0,
    },
  };

  getDocumentList = () => {
    this.setState({ loading: true });
    const { documentDetails } = this.props;
    this.props.actions.apiCall({
      urls: ["GETORDERPRODUCTDOCUMENT"],
      method: "GET",
      data: {
        ProductId: documentDetails.productId,
        BusinessCategoryType: documentDetails.typeId,
      },
      onSuccess: (response) => {
        if (response.length === 0) {
          this.props.onNext(3);
        } else {
          const { activeDocument } = this.state;
          activeDocument["documentDetail"] = {
            documentId: response[0].documentId,
            documentName: response[0].documentName,
            image: response[0].image,
          };
          activeDocument["number"] = 0;
          this.setState({ documentList: response, activeDocument });
          this.props.handleActiveDocument(activeDocument.documentDetail);
        }
        return response;
      },
    });
  };

  onSettingState = () => {
    const { getOrderProductDetails } = this.props;
    const { activeDocument } = this.state;
    if (getOrderProductDetails.length > 0) {
      let imgURL = commonFunctions.concatenateImageWithAPIUrl(
        getOrderProductDetails[0].image
      );
      activeDocument["documentDetail"] = {
        documentId: getOrderProductDetails[0].documentId,
        documentName: getOrderProductDetails[0].documentName,
        // image: `https://drive.google.com/viewerng/viewer?embedded=true&url=${imgURL}#toolbar=0&scrollbar=0`
        image: imgURL,
      };
      activeDocument["number"] = 0;
      this.setState(
        {
          activeDocument: activeDocument,
          documentList: getOrderProductDetails,
        },
        () => setTimeout(() => this.onSetChng(), 1000)
      );
      this.props.handleActiveDocument(activeDocument.documentDetail);
    } else if (this.props.documentDetails.productId) {
      this.getDocumentList();
    }
  };

  onSetChng = () => {
    document.getElementById("iframeid").src += "";
  };

  componentDidMount() {
    setTimeout(() => this.onSettingState(), 1000);
  }
  handleChevronRight = () => {
    const { activeDocument, documentList } = this.state;
    let index = activeDocument.number + 1;
    let imgURL = commonFunctions.concatenateImageWithAPIUrl(
      documentList[index].image
    );

    activeDocument["documentDetail"] = {
      documentId: documentList[index].documentId,
      documentName: documentList[index].documentName,
      // image: `https://drive.google.com/viewerng/viewer?embedded=true&url=${commonFunctions.concatenateImageWithAPIUrl(documentList[index].image)}#toolbar=0&scrollbar=0`
      image: imgURL,
    };
    activeDocument["number"] = index;
    this.setState({ activeDocument }, () =>
      setTimeout(() => this.onSetChng(), 1000)
    );
    this.props.handleActiveDocument(activeDocument.documentDetail);
  };
  handleChevronLeft = () => {
    const { activeDocument, documentList } = this.state;
    let index = activeDocument.number - 1;
    let imgURL = commonFunctions.concatenateImageWithAPIUrl(
      documentList[index].image
    );

    activeDocument["documentDetail"] = {
      documentId: documentList[index].documentId,
      documentName: documentList[index].documentName,
      // image: `https://drive.google.com/viewerng/viewer?embedded=true&url=${commonFunctions.concatenateImageWithAPIUrl(documentList[index].image)}#toolbar=0&scrollbar=0`
      image: imgURL,
    };
    activeDocument["number"] = index;
    this.setState({ activeDocument }, () =>
      setTimeout(() => this.onSetChng(), 1000)
    );
    this.props.handleActiveDocument(activeDocument.documentDetail);
  };

  render() {
    const { documentList, activeDocument } = this.state;
    const { guestListToggle } = this.props;
    let number = documentList.length === 0 ? 0 : activeDocument.number + 1;
    return (
      <>
        <Grid.Column width={16}>
          <span>Documents</span>
          <Icon
            name="chevron left"
            disabled={number === 1 ? true : false}
            className="orange-color"
            onClick={() => this.handleChevronLeft()}
          />
          {number}/ {documentList.length}
          <Icon
            name="chevron right"
            disabled={number === documentList.length ? true : false}
            className="orange-color"
            onClick={() => this.handleChevronRight()}
          />
        </Grid.Column>
        <Grid.Column width={11}>
          <div style={{ padding: "0px", width: "100%", height: "300px" }}>
            <iframe
              id="iframeid"
              style={{ overflow: "auto", padding: "2px" }}
              width="100%"
              height="100%"
              frameBorder="0"
              scrolling="auto"
              src={activeDocument.documentDetail.image}
            ></iframe>
          </div>
        {this.props.guestListToggle&&  <div className="guestListon">
            <Popup
              trigger={
                <Icon
                  name="info circle"
                  className="orange-color discount-css"
                />
              }>
              <div>
                <p>
                  <div className="orange-color" >
                    If Guest List feature is On.
                  </div>
                  You must click on the box next to the name(s) you want to save in the guest list.
                  If you don't click on the box, the name(s) will be saved as a separate signature and
                  will not be included in the customer profile.
                </p>
              </div>
            </Popup>
          </div>}

        </Grid.Column>
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
    },
  };
};
export default connect(null, mapDispatchToProps)(ViewDocument);
